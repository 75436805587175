import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

const data = [
  'Collect Automatically',
  'Smart Risk Based Segmentation for effort',
  'Compliance and Security First Solution',
  'Intelligent Dispute Management',
  'Monitoring, Training, and Reconciliation - Real Time',
];

const GetPaid = () => {
  const theme = useTheme();
  const isTab = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: isTab ? 'column' : 'row',
      }}
    >
      <Box sx={{ width: isTab ? '100%' : '48%', display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            pb: '20px',
            '& .get-paid-heading': {
              fontSize: '3.6rem',
              fontWeight: '700',
              background: 'linear-gradient(90deg, #6461F3 57.94%, #E052C2 77.71%, #3A388D 100%);',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',

              ...(isTab && {
                display: 'inline',
              }),
            },
          }}
        >
          <Typography className='get-paid-heading'>Get paid faster with </Typography>
          <Typography className='get-paid-heading'>plug-and-play. </Typography>
          <Typography className='get-paid-heading'>On Time. Every Time.</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          background: 'linear-gradient(231deg, #FFEDFB 5.29%, #C0AAFF 126.69%);',
          borderRadius: '10px',
          boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.16);',
          p: '40px 20px',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ position: 'relative' }}>
            <svg xmlns='http://www.w3.org/2000/svg' width='92' height='92' viewBox='0 0 92 92' fill='none'>
              <circle cx='46' cy='46' r='46' fill='#1F1933' />
            </svg>
            <Box sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate3d(-50%,-50%,0)' }}>
              <svg xmlns='http://www.w3.org/2000/svg' width='45' height='45' viewBox='0 0 45 45' fill='none'>
                <path
                  d='M26.25 15V20.625M18.75 20.625V15M15.0056 22.5506C14.9212 21.5119 15.7987 20.625 16.9125 20.625H28.0875C29.2012 20.625 30.0787 21.5119 29.9944 22.5506L29.7637 25.3875C29.5923 27.4459 28.8666 29.4196 27.6637 31.0987L26.9137 32.1544C26.5479 32.654 26.0683 33.0593 25.5146 33.3368C24.961 33.6143 24.3492 33.7559 23.73 33.75H21.27C20.6507 33.7559 20.039 33.6143 19.4854 33.3368C18.9317 33.0593 18.4521 32.654 18.0862 32.1544L17.3362 31.0987C16.1334 29.4196 15.4077 27.4459 15.2362 25.3875L15.0056 22.5506Z'
                  stroke='white'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M30 39.7687C36.6225 36.87 41.25 30.2475 41.25 22.5413C41.25 12.1631 32.8556 3.75 22.5 3.75C12.1444 3.75 3.75 12.1631 3.75 22.5413C3.75 32.2875 11.1506 40.2994 20.6269 41.2425C21.6581 41.3438 22.5 40.4925 22.5 39.4556V33.8175'
                  stroke='white'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </Box>
          </Box>
        </Box>
        <Box sx={{ pt: '16px' }}>
          {data?.map((value, index) => {
            return (
              <Box key={index} sx={{ py: '10px', display: 'flex', alignItems: 'center', gap: '16px' }}>
                <Box>
                  <svg xmlns='http://www.w3.org/2000/svg' width='10' height='11' viewBox='0 0 10 11' fill='none'>
                    <circle cx='5' cy='5.5' r='5' fill='#1F1933' />
                  </svg>
                </Box>
                <Typography sx={{ color: '#3E3E3E', fontSize: '1.5rem', fontWeight: 500 }}>{value}</Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default GetPaid;
