import WhyUsIcon from '@/@core/icons/WhyUsIcon';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CenterAlignBox } from '@/@core/styles/common';
import BookDemoButton from '@/@core/components/BookDemoButton';

const WhyUs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTab = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box sx={{ background: 'rgba(1, 0, 52, 0.90);', position: 'relative' }}>
      <Box sx={{ display: 'flex', flexDirection: isTab ? 'column' : 'row', ...CenterAlignBox }}>
        <Box
          sx={{
            pl: isTab ? '20px' : '0',
            width: isTab ? '100%' : '50%',
            py: '78px',
          }}
        >
          <Typography
            sx={{
              color: '#6461F3',
              fontSize: '3.6rem',
              fontWeight: '700',
            }}
          >
            Get Your Time Back:
          </Typography>
          <Typography
            sx={{
              color: '#FFF',
              fontSize: '3.6rem',
              fontWeight: '400',
            }}
          >
            Let Our AI Handle Your
          </Typography>
          <Typography
            sx={{
              color: '#FFF',
              fontSize: '3.6rem',
              fontWeight: '400',
            }}
          >
            Finances!
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              gap: '20px',
              pt: '50px',
            }}
          >
            {/* use Book a demo */}
            <BookDemoButton variant='secondary'></BookDemoButton>
            {/* <CommonButton
              text='Get a Demo'
              type='secondary'
              onClick={() => {
                console.log('Try it for free click');
              }}
            /> */}
            {/* TODO: Rajat, add it back when pricing page is read */}
            {/* <Button
              sx={{
                fontSize: '1.2rem',
                color: `${theme.palette.common.white} !important`,
                fontWeight: 600,
                borderRadius: '18px',
                background: 'transparent',
                padding: '0',
                display: 'flex',
                gap: '5px',
                transition: '0.2s',
                '&:hover': {
                  // fontWeight: 700,
                  fontSize: '1.3rem',
                  gap: '10px',
                },
              }}
            >
              See plans and pricing
              <ArrowRightAlt />
            </Button> */}
          </Box>
        </Box>
        <Box
          sx={{
            height: '100%',
            width: isTab ? '100%' : '50%',
            overflowX: isTab ? 'hidden' : 'visible',
            position: 'absolute',
            left: '40%',
            '@media (max-width:1000px)': {
              display: 'none',
            },
          }}
        >
          <WhyUsIcon />
        </Box>
      </Box>
    </Box>
  );
};

export default WhyUs;
