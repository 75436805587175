import React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

interface HeadingProps {
  children: React.ReactNode;
  type: 'hero' | 'primary' | 'secondary';
  color?: any;
}

const StyledTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'type',
})<{ type: 'hero' | 'primary' | 'secondary'; color: 'string' }>(({ theme, type, color }) => {
  switch (type) {
    case 'hero':
      return {
        fontSize: '4.8rem',
        fontWeight: 700,
        color: color ? color : '#fff',
        [theme.breakpoints.down('sm')]: {
          fontSize: '2.4rem',
        },
      };
    case 'primary':
      return {
        fontSize: '3.2rem',
        fontWeight: 700,
        color: '#6461F3',
        [theme.breakpoints.down('sm')]: {
          fontSize: '1.75rem',
        },
      };
    case 'secondary':
      return {
        fontSize: '1.5rem',
        fontWeight: 500,
        color: theme.palette.text.primary,
        [theme.breakpoints.down('sm')]: {
          fontSize: '1.25rem',
        },
      };
    default:
      return {};
  }
});

const Heading: React.FC<HeadingProps> = ({ children, type, color = '' }) => {
  return (
    <StyledTypography type={type} variant='h1' color={color}>
      {children}
    </StyledTypography>
  );
};

export default Heading;
