import Heading from '../../@core/components/Heading';
import { Box, useMediaQuery, useTheme } from '@mui/material';

const IconLists = ({ list, heading = '' }: { list: any[]; heading?: string }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      sx={{
        width: '1000px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        py: '50px',
        maxWidth: '80vw',
      }}
    >
      <Box
        sx={{
          maxWidth: '500px',
          pb: '40px',
        }}
      >
        <Heading type='primary'>{heading ? heading : 'Integrated with all leading ERP solutions'}</Heading>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '50px',
          pt: '0px',
          flexWrap: 'wrap',
          minHeight: '170px',
          alignItems: 'center',
          ...(heading === 'Compliance' &&
            isMobile && {
              transform: 'scale(0.8)',
            }),
        }}
      >
        {list?.map((value, index) => {
          const Icon = value;
          return (
            <Box key={index}>
              <Icon />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default IconLists;
