import BookDemoButton from '@/@core/components/BookDemoButton';
import Footer from '@/@core/components/Footer';
import ComplianceIcon from '@/@core/icons/ComplianceIcon';
import QbIcon from '@/@core/icons/QBIcons';
import SageIcon from '@/@core/icons/SageIcon';
import SalesforceIcon from '@/@core/icons/SalesforceIcon';
import SquareIcon from '@/@core/icons/SqaureIcon';
import XeroIcon from '@/@core/icons/XeroIcon';
import { CenterAlignBox } from '@/@core/styles/common';
import GenesisOne from '@/components/home/GenesisOne';
import GetPaid from '@/components/home/GetPaid';
import IconLists from '@/components/home/IconLists';
import OneStop from '@/components/home/OneStop';
import WhyUs from '@/components/home/WhyUs';
import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt';
import { Box, Button, Typography, keyframes, useMediaQuery, useTheme } from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useInView } from 'react-intersection-observer';
import banner from '../../public/images/banner/Home.svg';
import NavigationBar from '../components/navigation/NavigationBar';

const laptopOpening = keyframes`
  0% {
    transform: translateX(300px);
    opacity: 0;
  },
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
`;

const stats = [
  {
    heading: '5X',
    description: '5x your team’s output without increasing the workload',
  },
  {
    heading: '24X7',
    description: 'Uninterrupted, AI agents for 24/7 collections',
  },
  {
    heading: '99%',
    description: 'Achieve 99% message accuracy',
  },
];

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTab = useMediaQuery(theme.breakpoints.down('sm'));
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const router = useRouter();
  return (
    <Box
      sx={{
        '& .common-footer-container': {
          mt: '0 !important',
        },
      }}
    >
      <Box
        sx={{
          background: 'linear-gradient(390deg, #E052C2 13.82%, #6461F3 44.45%, #1F1933 90.69%)',
          position: 'relative',
          pb: '120px',
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: '-100%',
            left: 0,
            width: '100%',
            height: '100%',
            background: '#fff',
            transform: 'skewY(-4deg)',
            transformOrigin: 'top left',
            '@media (min-width:2000px)': {
              transform: 'skewY(-2deg)',
            },
            '@media (min-width:4000px)': {
              transform: 'skewY(0deg)',
            },
          },
        }}
      >
        <Box>
          <Box
            sx={{
              maxWidth: '1000px',
              margin: '0 auto',
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <NavigationBar />
            <Box
              sx={{
                display: 'flex',
                pt: isTab ? '30px' : '90px',
                flexDirection: isTab ? 'column' : 'row',
                ...(isTab && {
                  gap: '60px',
                }),
              }}
            >
              <Box
                sx={{
                  maxWidth: isTab ? '100%' : '50%',
                }}
              >
                <Typography
                  sx={{
                    fontSize: isMobile ? '2.4rem' : '3.6rem',
                    fontWeight: '700',
                    color: '#fff',
                    textAlign: 'center',
                    display: 'inline',
                  }}
                >
                  Introducing
                </Typography>
                <Typography
                  sx={{
                    background: 'linear-gradient(90deg, #E052C2 50.07%, #FFA7EC 99.7%);',
                    backgroundClip: 'text',
                    textFillColor: 'transparent',
                    fontSize: isMobile ? '2.4rem' : '3.6rem',
                    fontWeight: '700',
                    display: 'inline',
                  }}
                >
                  {' '}
                  Genesis One
                </Typography>
                <Typography
                  sx={{
                    fontSize: '2.4rem',
                    fontWeight: '400',
                    color: 'rgba(255, 250, 250, 0.93);',
                  }}
                >
                  Fully Autonomous
                </Typography>
                <Typography
                  sx={{
                    fontSize: '2.4rem',
                    fontWeight: '400',
                    color: 'rgba(255, 250, 250, 0.93);',
                  }}
                >
                  Financial Operations Platform
                </Typography>
                <Typography
                  sx={{
                    fontSize: '1.5rem',
                    fontWeight: '400',
                    color: '#fff',
                    py: '26px',
                  }}
                >
                  Modernize your back office financial operations with AI. Reduce your cost of collections, and ensure
                  regulatory compliance, including disputes, payments, payables, cash management, and more.
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    gap: '20px',
                  }}
                >
                  {/* <CommonButton
                    text='sign up'
                    type='secondary'
                    onClick={() => {
                      console.log('sign up click');
                    }}
                  /> */}
                  <BookDemoButton variant='secondary' />
                  <Button
                    onClick={() => {
                      router.push('/products');
                    }}
                    sx={{
                      fontSize: '1.2rem',
                      color: `${theme.palette.common.white} !important`,
                      fontWeight: 600,
                      borderRadius: '18px',
                      background: 'transparent',
                      padding: '0',
                      display: 'flex',
                      gap: '5px',
                      transition: '0.2s',
                      '&:hover': {
                        // fontWeight: 700,
                        fontSize: '1.3rem',
                        gap: '10px',
                      },
                    }}
                  >
                    Explore the AI Advantage
                    <ArrowRightAlt />
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  position: 'relative',
                }}
              >
                <Box
                  ref={ref}
                  sx={{
                    maxWidth: '1000px',
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    visibility: inView || isTab ? 'visible' : 'hidden',
                    margin: '0 auto',
                    zIndex: 3,
                    animation: inView && !isTab ? `${laptopOpening} 0.5s ease-in` : 'none',
                    ...(!isTab && {
                      position: 'relative',
                    }),
                  }}
                >
                  <Image src={banner} alt={'FinanceOps'} layout='responsive' />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            position: 'relative',
            zIndex: 5,
            margin: '0 auto',
          }}
        >
          <Box sx={{ margin: '0 auto', pt: '30px' }}>
            <Typography
              sx={{
                fontSize: isMobile ? '1.6rem' : '3.2rem',
                fontWeight: '700',
                textAlign: 'center',
                color: '#fff',
              }}
            >
              Assign Your Finances to Autopilot.
            </Typography>
            <Typography
              sx={{
                fontSize: isMobile ? '1.6rem' : '3.6rem',
                fontWeight: '700',
                textAlign: 'center',
                color: '#fff',
              }}
            >
              You Run the Business!
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: '30px',
              pt: '30px',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: 'center',
            }}
          >
            {stats?.map((value, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    maxWidth: '300px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: isMobile ? '2.4rem' : '5rem',
                      fontWeight: '500',
                      color: '#fff',
                      textAlign: 'center',
                    }}
                  >
                    {value.heading}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '1.8rem',
                      fontWeight: '500',
                      color: '#fff',
                      textAlign: 'center',
                    }}
                  >
                    {value.description}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          mt: '60px',
          '@media (max-width:1200px)': {
            mt: '40px',
          },
        }}
      >
        <Box
          sx={{
            ...CenterAlignBox,
            position: 'relative',
            '@media (max-width:768px)': {
              px: '20px',
            },
          }}
        >
          <OneStop />
        </Box>
        <Box
          sx={{
            ...CenterAlignBox,
            position: 'relative',
            py: '60px',
            '@media (max-width:768px)': {
              px: '20px',
            },
          }}
        >
          <GenesisOne />
        </Box>
        <Box
          sx={{
            ...CenterAlignBox,
            position: 'relative',
            py: '60px',
            '@media (max-width:768px)': {
              px: '20px',
            },
          }}
        >
          <GetPaid />
        </Box>
        <Box sx={{ ...CenterAlignBox, position: 'relative' }}>
          <IconLists list={[SalesforceIcon, QbIcon, SageIcon, XeroIcon, SquareIcon]} />
        </Box>
        <WhyUs />
        <IconLists list={[ComplianceIcon]} heading='Compliance' />
        <Footer />
      </Box>
    </Box>
  );
};

export default Home;
