const StopOne = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='34' viewBox='0 0 32 34' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.8185 4.98982C25.8622 5.05019 25.8242 5.13521 25.75 5.14286L19.6727 5.76962C19.5986 5.77727 19.544 5.7018 19.5744 5.63379L20.3219 3.96356C17.7529 3.1184 14.9906 3.03065 12.3669 3.71685C9.5729 4.44758 7.07155 6.02055 5.20252 8.22213C3.33349 10.4237 2.18737 13.1472 1.91982 16.0227C1.65226 18.8983 2.27624 21.7865 3.70701 24.2951C3.96138 24.7411 3.80604 25.3088 3.36005 25.5632C2.91405 25.8176 2.3463 25.6622 2.09193 25.2162C0.472046 22.376 -0.234409 19.1061 0.0685078 15.8505C0.371425 12.5949 1.66903 9.5114 3.7851 7.01882C5.90117 4.52624 8.73315 2.74536 11.8964 1.91805C14.929 1.12491 18.1252 1.24503 21.0851 2.2585L22.0703 0.0572674C22.1007 -0.0107494 22.1934 -0.0203055 22.2371 0.0400666L25.8185 4.98982ZM5.09028 28.1893C5.0159 28.194 4.97444 28.2774 5.01565 28.3395L8.39391 33.4301C8.43511 33.4922 8.52808 33.4864 8.56125 33.4197L11.2807 27.9487C11.3139 27.882 11.2624 27.8043 11.188 27.809L5.09028 28.1893Z'
        fill='#6461F3'
      />
      <path
        d='M29.3146 11.1965C30.539 13.9841 30.8757 17.081 30.279 20.0666C29.6823 23.0522 28.1811 25.7818 25.9794 27.8847C23.7776 29.9876 20.982 31.3619 17.9722 31.8209C14.9623 32.2799 11.8841 31.8014 9.15565 30.4503'
        stroke='#6461F3'
        strokeWidth='1.8593'
        strokeLinecap='round'
      />
      <path
        d='M11.0992 11.0356C11.3127 10.1552 12.5649 10.1552 12.7784 11.0356L13.3658 13.4582C13.442 13.7725 13.6874 14.0179 14.0018 14.0942L16.4243 14.6816C17.3047 14.8951 17.3047 16.1472 16.4243 16.3607L14.0018 16.9482C13.6874 17.0244 13.442 17.2698 13.3658 17.5841L12.7784 20.0067C12.5649 20.8871 11.3127 20.8871 11.0992 20.0067L10.5118 17.5841C10.4356 17.2698 10.1902 17.0244 9.87582 16.9482L7.45328 16.3607C6.57286 16.1472 6.57286 14.8951 7.45328 14.6816L9.87582 14.0942C10.1902 14.0179 10.4356 13.7725 10.5118 13.4582L11.0992 11.0356Z'
        fill='#E052C2'
      />
      <path
        d='M18.1513 19.49C18.2827 18.9482 19.0532 18.9482 19.1846 19.49L19.5461 20.9808C19.593 21.1742 19.7441 21.3253 19.9375 21.3722L21.4283 21.7337C21.9701 21.865 21.9701 22.6356 21.4283 22.767L19.9375 23.1285C19.7441 23.1754 19.593 23.3264 19.5461 23.5199L19.1846 25.0106C19.0532 25.5524 18.2827 25.5524 18.1513 25.0106L17.7898 23.5199C17.7429 23.3264 17.5919 23.1754 17.3984 23.1285L15.9076 22.767C15.3659 22.6356 15.3659 21.865 15.9076 21.7337L17.3984 21.3722C17.5919 21.3253 17.7429 21.1742 17.7898 20.9808L18.1513 19.49Z'
        fill='#E052C2'
      />
      <path
        d='M20.7039 10.9389C20.7901 10.5833 21.2958 10.5833 21.382 10.9389L21.6193 11.9172C21.65 12.0441 21.7492 12.1433 21.8761 12.174L22.8544 12.4113C23.21 12.4975 23.21 13.0032 22.8544 13.0894L21.8761 13.3266C21.7492 13.3574 21.65 13.4565 21.6193 13.5835L21.382 14.5618C21.2958 14.9173 20.7901 14.9173 20.7039 14.5618L20.4667 13.5835C20.4359 13.4565 20.3368 13.3574 20.2098 13.3266L19.2315 13.0894C18.876 13.0032 18.876 12.4975 19.2315 12.4113L20.2098 12.174C20.3368 12.1433 20.4359 12.0441 20.4667 11.9172L20.7039 10.9389Z'
        fill='#E052C2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.043 11.1124L20.8493 11.9109C20.772 12.2301 20.5227 12.4793 20.2036 12.5567L19.4051 12.7503L20.2036 12.9439C20.5227 13.0213 20.772 13.2705 20.8493 13.5897L21.043 14.3882L21.2366 13.5897C21.314 13.2705 21.5632 13.0213 21.8824 12.9439L22.6809 12.7503L21.8824 12.5567C21.5632 12.4793 21.314 12.2301 21.2366 11.9109L21.043 11.1124ZM21.4305 10.6801C21.3319 10.2737 20.754 10.2737 20.6555 10.6801L20.3844 11.7982C20.3492 11.9433 20.2359 12.0565 20.0908 12.0917L18.9727 12.3628C18.5664 12.4614 18.5664 13.0393 18.9727 13.1378L20.0908 13.4089C20.2359 13.4441 20.3492 13.5574 20.3844 13.7025L20.6555 14.8206C20.754 15.2269 21.3319 15.2269 21.4305 14.8206L21.7016 13.7025C21.7368 13.5574 21.85 13.4441 21.9951 13.4089L23.1132 13.1378C23.5196 13.0393 23.5196 12.4614 23.1132 12.3628L21.9951 12.0917C21.85 12.0565 21.7368 11.9433 21.7016 11.7982L21.4305 10.6801Z'
        fill='#E052C2'
      />
      <path
        d='M11.543 26.2087C11.1057 26.2087 10.7513 25.8542 10.7513 25.417C10.7513 24.9798 11.1057 24.6253 11.543 24.6253C11.9802 24.6253 12.3346 24.9798 12.3346 25.417C12.3346 25.8542 11.9802 26.2087 11.543 26.2087Z'
        fill='#E052C2'
      />
      <path
        d='M7.58464 23.042C7.14741 23.042 6.79297 22.6876 6.79297 22.2503C6.79297 21.8131 7.14741 21.4587 7.58464 21.4587C8.02186 21.4587 8.3763 21.8131 8.3763 22.2503C8.3763 22.6876 8.02186 23.042 7.58464 23.042Z'
        fill='#E052C2'
      />
      <path
        d='M16.293 10.3753C15.8557 10.3753 15.5013 10.0209 15.5013 9.58366C15.5013 9.14643 15.8557 8.79199 16.293 8.79199C16.7302 8.79199 17.0846 9.14643 17.0846 9.58366C17.0846 10.0209 16.7302 10.3753 16.293 10.3753Z'
        fill='#E052C2'
      />
      <path
        d='M23.418 19.0837C22.9807 19.0837 22.6263 18.7292 22.6263 18.292C22.6263 17.8548 22.9807 17.5003 23.418 17.5003C23.8552 17.5003 24.2096 17.8548 24.2096 18.292C24.2096 18.7292 23.8552 19.0837 23.418 19.0837Z'
        fill='#E052C2'
      />
    </svg>
  );
};

export default StopOne;
