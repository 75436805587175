import Heading from '@/@core/components/Heading';
import StopFour from '@/@core/icons/home/StopFour';
import StopOne from '@/@core/icons/home/StopOne';
import StopSeven from '@/@core/icons/home/StopSeven';
import StopSix from '@/@core/icons/home/StopSix';
import StopThree from '@/@core/icons/home/StopThree';
import StopTwo from '@/@core/icons/home/StopTwo';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import Link from 'next/link';

const data = [
  {
    text: 'AI Automation & Workflow Optimization',
    icon: StopThree,
    description: 'Formulate strategies and segment customers into different worklists for faster collections',
    link: 'ai-automation-and-workflow-optimization',
  },
  {
    text: 'Financial Reporting & Analysis',
    icon: StopFour,
    link: 'financial-reporting-and-analysis',
    description: 'Forecast cash flow and track critical financial metrics to ensure better financial oversight',
  },
  {
    text: 'Autonomous Collections',
    icon: StopOne,
    link: 'autonomus-collections',
    description: 'Begin the recovery process immediately with zero manual intervention using a multi-channel approach',
  },
  {
    text: 'Advanced Risk Management',
    icon: StopTwo,
    link: 'advanced-risk-management',
    description: 'Identify potential delinquencies and risks by understanding customer sentiment and intent',
  },
  // {
  //   text: 'Compliance & Regulatory Reporting ',
  //   icon: StopFive,
  //   link: 'compliance-and-regulatory-reporting',
  //   description: 'Always stay on top of regulations and compliance for audit readiness',
  // },
  {
    text: 'Customer Engagement & Self-service',
    icon: StopSix,
    link: 'customer-engagement-and-self-service',
    description: 'Improve customer experience by allowing them to view invoices and make payments online',
  },
  {
    text: 'Accounts Receivable Management',
    icon: StopSeven,
    link: 'accounts-receivable-management',
    description: 'Automate routine processes and leverage AI to manage receivable processes end-to-end',
  },
];

const OneStop = () => {
  const theme = useTheme();
  const isTab = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box sx={{ minHeight: '650px' }}>
      <Heading type='primary'>The One-Stop Shop for your team !</Heading>
      <Typography
        sx={{
          color: '#3E3E3E',
          fontSize: '1.5rem',
          fontWeight: '400',
          pb: '53px',
          pt: '23px',
        }}
      >
        Get to know about the Genesis One platform
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: '20px',
          justifyContent: 'center',
          flexWrap: 'wrap',
          flexDirection: isTab ? 'column' : 'row',
        }}
      >
        {data.map((value, index) => {
          const Icon = value.icon;
          return (
            <Box
              key={index}
              sx={{
                width: isTab ? '100%' : index < 2 || index > 4 ? '49%' : '32%',
                '& a': {
                  textDecoration: 'none',
                },
              }}
            >
              <Link href={value.link} passHref>
                <Box
                  key={index}
                  sx={{
                    boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.15);',
                    borderRadius: '5px',
                    p: '36px 30px',
                    cursor: 'pointer',
                    pr: '10px',
                    minHeight: '126px',
                    maxHeight: 'fit-content',
                    '& .one-stop-description': {
                      height: '0px',
                      overflow: 'hidden',
                      pt: '0px',
                      transition: '0.2s all',
                      width: '0px',
                    },
                    '&:hover .one-stop-description': {
                      height: 'auto',
                      overflow: 'visible',
                      pt: '16px !important',
                      width: 'auto',
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', gap: '20px', alignItems: 'start' }}>
                    <Box
                      sx={{
                        minHeight: '30px',
                        minWidth: '30px',
                      }}
                    >
                      <Icon />
                    </Box>
                    <Typography
                      sx={{
                        color: '#263238',
                        fontWeight: '700',
                        fontSize: '1.8rem',
                        textDecoration: 'none',
                      }}
                    >
                      {value.text}
                    </Typography>
                  </Box>
                  <Box
                    className='one-stop-description'
                    sx={{
                      color: '#3E3E3E',
                      fontWeight: '300',
                      fontSize: '1.2rem',
                      pt: '16px',
                      textDecoration: 'none',
                    }}
                  >
                    {value.description}
                  </Box>
                </Box>
              </Link>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default OneStop;
