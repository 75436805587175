import featureImageOne from '../../../public/images/home/3.1.svg';
import featureImageTwo from '../../../public/images/home/3.2.svg';
import featureImageThree from '../../../public/images/home/3.3.svg';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { ArrowRightAlt } from '@mui/icons-material';
import Image from 'next/image';

const data = [
  {
    text: 'For the Bottomline',
    icon: featureImageThree,
    description:
      '82% of business failures link back to poor cash flow, often caused by uncollected "small" invoices! Even seemingly insignificant late payments can significantly impact your company’s bottom line. We automate these while you focus on scaling your business.',
  },
  {
    text: 'For the Team',
    icon: featureImageOne,
    description:
      'Empower your finance team streamline operations & gain insights. Focus on strategic initiatives while AI handles the legwork - recover cash faster and improve your bottom line.',
  },
  {
    text: 'For the Leader',
    icon: featureImageTwo,
    description:
      'Our plug-and-play platform automates tasks, generates personalized communication, and provides real-time risk analysis. Increase team productivity by 5x, reduce costs, and ensure smoother cash flow',
  },
];

const GenesisOne = () => {
  const theme = useTheme();
  const isTab = useMediaQuery(theme.breakpoints.down('md'));
  const [activeStep, setActiveStep] = useState(0);
  const [intervalId, setIntervalId] = useState<any>(null);

  useEffect(() => {
    const id = window.setInterval(() => {
      setActiveStep(prevStep => (prevStep === data.length - 1 ? 0 : prevStep + 1));
    }, 5000);

    setIntervalId(id);

    return () => clearInterval(id);
  }, []);

  const handleClick = (index: any) => {
    setActiveStep(index);
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  return (
    <Box>
      <Typography
        sx={{
          fontSize: '3.6rem',
          fontWeight: '700',
          background: 'linear-gradient(90deg, #6461F3 0%, #E052C2 18.44%, #3A388D 35.11%)',
          backgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        Genesis One
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: '20px',
          justifyContent: 'space-between',
          flexDirection: isTab ? 'column' : 'row',
        }}
      >
        <Box sx={{ width: isTab ? '100%' : '49%' }}>
          <Box>
            {data.map((value, index) => {
              return (
                <Box
                  sx={{
                    cursor: 'pointer',
                    borderBottom: `2px solid ${index === activeStep ? '#3E3E3E' : 'rgba(62, 62, 62, 0.50)'}`,
                    py: '30px',
                  }}
                  key={index}
                  onClick={() => handleClick(index)}
                >
                  <Box
                    sx={{
                      maxHeight: 'fit-content',
                      '& .one-stop-description': {
                        height: '0px',
                        overflow: 'hidden',
                        pt: '0px',
                        transition: '0.2s all',
                        width: '0px',
                      },
                      ...(activeStep === index && {
                        '& .one-stop-description': {
                          height: 'auto',
                          overflow: 'visible',
                          pt: '16px !important',
                          width: 'auto',
                        },
                      }),
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography
                        sx={{
                          color: index === activeStep ? '#3E3E3E' : 'rgba(62, 62, 62, 0.50)',
                          fontWeight: '700',
                          fontSize: '2.4rem',
                        }}
                      >
                        {value.text}
                      </Typography>
                      <ArrowRightAlt
                        sx={{
                          color: index === activeStep ? '#3E3E3E' : 'rgba(62, 62, 62, 0.50)',
                          fontSize: '2.4rem',
                        }}
                      />
                    </Box>
                    <Box
                      className='one-stop-description'
                      sx={{
                        color: '#3E3E3E',
                        fontWeight: '300',
                        fontSize: '1.2rem',
                        pt: '16px',
                      }}
                    >
                      {value.description}
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: isTab ? 'center' : 'flex-end' }}>
          <Image
            src={data[activeStep].icon}
            alt='value.heading'
            style={{ maxWidth: isTab ? '70vw' : '470px', height: 'auto', minHeight: '324px' }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default GenesisOne;
