const StopFour = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='35' height='34' viewBox='0 0 35 34' fill='none'>
      <path
        d='M12.5525 12.9639V25.9498H10.9023V12.9639C10.9023 8.01333 17.5031 8.01333 17.5031 12.9639V22.8651C17.5031 24.5153 20.8035 24.5153 20.8035 22.8651V12.9639H19.1533L21.6286 9.66353L24.1039 12.9639H22.4537V22.8651C22.4537 26.9906 15.8529 26.9906 15.8529 22.8651V12.9639C15.8529 10.4886 12.5525 10.9779 12.5525 12.9639Z'
        fill='#E052C2'
        stroke='#E052C2'
        strokeWidth='0.16502'
      />
      <path
        d='M3.16755 25.6775C1.73171 23.16 0.984215 20.3088 1.00025 17.4106C1.01629 14.5125 1.7953 11.6697 3.25891 9.16828C4.72251 6.66684 6.81909 4.59497 9.33771 3.16112C11.8563 1.72727 14.7081 0.982011 17.6063 1.00033C20.5044 1.01865 23.3465 1.79989 25.8468 3.26547C28.3471 4.73104 30.4173 6.82925 31.8492 9.349C33.2811 11.8687 34.0241 14.7212 34.0035 17.6192C33.9829 20.5173 33.1994 23.3589 31.7319 25.858'
        stroke='#6461F3'
        strokeWidth='1.6502'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default StopFour;
