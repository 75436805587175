const StopThree = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='34' height='33' viewBox='0 0 34 33' fill='none'>
      <path
        d='M11.6805 13.8323L11.6967 15.9706L5.81646 16.0153L5.83272 18.1536L11.713 18.1089L11.7292 20.2472L15.9814 17.0072L11.6805 13.8323Z'
        fill='#E052C2'
        stroke='#E052C2'
        strokeWidth='0.247578'
      />
      <path
        d='M22.925 13.8323L22.9087 15.9706L28.789 16.0153L28.7727 18.1536L22.8925 18.1089L22.8762 20.2472L18.6241 17.0072L22.925 13.8323Z'
        fill='#E052C2'
        stroke='#E052C2'
        strokeWidth='0.247578'
      />
      <path
        d='M14.6008 12.1992L13.8008 12.9992L16.2008 14.5992L14.6008 12.1992Z'
        fill='#E052C2'
        stroke='#E052C2'
        strokeWidth='0.205832'
        strokeLinejoin='round'
      />
      <path
        d='M20.2039 12.1992L21.0039 12.9992L18.6039 14.5992L20.2039 12.1992Z'
        fill='#E052C2'
        stroke='#E052C2'
        strokeWidth='0.205832'
        strokeLinejoin='round'
      />
      <path
        d='M16.4869 11.1859L17.6175 11.1439L17.1572 13.9914L16.4869 11.1859Z'
        fill='#E052C2'
        stroke='#E052C2'
        strokeWidth='0.205832'
        strokeLinejoin='round'
      />
      <path
        d='M14.6008 21L13.8008 20.2L16.2008 18.6L14.6008 21Z'
        fill='#E052C2'
        stroke='#E052C2'
        strokeWidth='0.205832'
        strokeLinejoin='round'
      />
      <path
        d='M20.2039 21L21.0039 20.2L18.6039 18.6L20.2039 21Z'
        fill='#E052C2'
        stroke='#E052C2'
        strokeWidth='0.205832'
        strokeLinejoin='round'
      />
      <path
        d='M16.4869 22.0133L17.6175 22.0553L17.1572 19.2078L16.4869 22.0133Z'
        fill='#E052C2'
        stroke='#E052C2'
        strokeWidth='0.205832'
        strokeLinejoin='round'
      />
      <path
        d='M5.68629 28.3137C3.44865 26.0761 1.9248 23.2251 1.30744 20.1214C0.690072 17.0177 1.00693 13.8007 2.21793 10.8771C3.42893 7.95345 5.47969 5.45459 8.11088 3.69649C10.7421 1.93838 13.8355 1 17 1C20.1645 1 23.2579 1.93838 25.8891 3.69649C28.5203 5.45459 30.5711 7.95345 31.7821 10.8771C32.9931 13.8007 33.3099 17.0177 32.6926 20.1214C32.0752 23.2251 30.5513 26.0761 28.3137 28.3137'
        stroke='#6461F3'
        strokeWidth='1.85249'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default StopThree;
