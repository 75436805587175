const StopSix = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='27' height='31' viewBox='0 0 27 31' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.3041 0.753089C12.4161 0.491929 12.2034 0.207429 11.9212 0.24099L7.12651 0.811232C6.84432 0.844792 6.70429 1.17124 6.87444 1.39884L7.82477 2.66998C6.00375 3.65715 4.40596 5.01301 3.13449 6.65312C1.75435 8.4334 0.794376 10.5026 0.326302 12.706C-0.141773 14.9094 -0.105847 17.1901 0.431394 19.3777C0.968635 21.5653 1.9933 23.6032 3.42883 25.3391C4.86435 27.0751 6.67361 28.4641 8.72143 29.4026C10.7692 30.341 13.0027 30.8045 15.2548 30.7585C17.5069 30.7124 19.7195 30.158 21.7273 29.1367C23.735 28.1154 25.486 26.6535 26.8494 24.8604C27.0962 24.5358 27.0331 24.0726 26.7085 23.8258C26.3839 23.579 25.9207 23.6421 25.6739 23.9666C24.4452 25.5827 22.8672 26.9001 21.0578 27.8206C19.2483 28.741 17.2543 29.2407 15.2246 29.2822C13.1949 29.3236 11.1821 28.9059 9.33658 28.0602C7.49104 27.2145 5.86049 25.9626 4.56676 24.3981C3.27303 22.8337 2.34957 20.9971 1.8654 19.0255C1.38122 17.054 1.34885 14.9986 1.77069 13.0128C2.19253 11.027 3.05768 9.16226 4.30149 7.55782C5.4916 6.02267 6.99892 4.76385 8.71938 3.86661L9.76565 5.26608C9.93581 5.49368 10.2885 5.45173 10.4006 5.19057L12.3041 0.753089Z'
        fill='#6461F3'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.8189 22.7736L9.15839 23.6193C9.09385 23.6515 9.01724 23.6476 8.95622 23.6093C8.89519 23.5709 8.85857 23.5035 8.85956 23.4314V11.4716C8.8496 10.9862 9.23489 10.5845 9.72034 10.5742H18.3347C18.8202 10.5845 19.2055 10.9862 19.1955 11.4716V23.4314C19.1965 23.5035 19.1599 23.5709 19.0988 23.6093C19.0378 23.6476 18.9612 23.6515 18.8967 23.6193L17.2362 22.7736C17.1701 22.7398 17.0908 22.7449 17.0294 22.7868L15.6932 23.6945C15.624 23.7416 15.5331 23.7416 15.4639 23.6945L14.1426 22.7971C14.0734 22.7501 13.9825 22.7501 13.9134 22.7971L12.5921 23.6945C12.5229 23.7416 12.432 23.7416 12.3628 23.6945L11.0256 22.7905C10.965 22.7474 10.8857 22.7409 10.8189 22.7736Z'
        stroke='#E052C2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.6796 18.5324C11.4034 18.5324 11.1796 18.7563 11.1796 19.0324C11.1796 19.3086 11.4034 19.5324 11.6796 19.5324V18.5324ZM16.2973 19.5324C16.5735 19.5324 16.7973 19.3086 16.7973 19.0324C16.7973 18.7563 16.5735 18.5324 16.2973 18.5324V19.5324ZM12.5026 15.39C12.3074 15.1947 11.9908 15.1947 11.7955 15.39C11.6003 15.5853 11.6003 15.9018 11.7955 16.0971L12.5026 15.39ZM13.0888 16.6833L12.7352 17.0368C12.9305 17.2321 13.2471 17.2321 13.4423 17.0368L13.0888 16.6833ZM16.2615 14.2177C16.4567 14.0224 16.4567 13.7058 16.2615 13.5106C16.0662 13.3153 15.7496 13.3153 15.5544 13.5106L16.2615 14.2177ZM11.6796 19.5324H16.2973V18.5324H11.6796V19.5324ZM11.7955 16.0971L12.7352 17.0368L13.4423 16.3297L12.5026 15.39L11.7955 16.0971ZM13.4423 17.0368L16.2615 14.2177L15.5544 13.5106L12.7352 16.3297L13.4423 17.0368Z'
        fill='#E052C2'
      />
    </svg>
  );
};

export default StopSix;
