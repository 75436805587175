const StopSeven = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='36' height='35' viewBox='0 0 36 35' fill='none'>
      <path
        d='M17.0838 21.9231L19.0454 23.8846L20.3531 25.1923L26.8915 19.3077L27.5454 19.9615L20.3531 26.5L16.43 22.5769L17.0838 21.9231Z'
        fill='#E052C2'
      />
      <path
        d='M16.43 23.8846H12.5069H11.1992V9.5H22.7651V22.4236L21.0069 23.8846H20.3531L17.0838 20.6154L15.1223 22.5769L16.43 23.8846Z'
        fill='#E052C2'
      />
      <path
        d='M17.0838 21.9231L19.0454 23.8846L20.3531 25.1923L26.8915 19.3077L27.5454 19.9615L20.3531 26.5L16.43 22.5769L17.0838 21.9231Z'
        stroke='#E052C2'
        strokeWidth='0.17'
        strokeLinejoin='round'
      />
      <path
        d='M16.43 23.8846H12.5069H11.1992V9.5H22.7651V22.4236L21.0069 23.8846H20.3531L17.0838 20.6154L15.1223 22.5769L16.43 23.8846Z'
        stroke='#E052C2'
        strokeWidth='0.17'
        strokeLinejoin='round'
      />
      <path d='M20.354 12.1152H12.5078' stroke='white' strokeWidth='0.85' strokeLinecap='round' />
      <path d='M16.4309 13.4229H12.5078' stroke='white' strokeWidth='0.85' strokeLinecap='round' />
      <path d='M17.7386 14.7305H12.5078' stroke='white' strokeWidth='0.85' strokeLinecap='round' />
      <path d='M19.0463 16.0381H12.5078' stroke='white' strokeWidth='0.85' strokeLinecap='round' />
      <path
        d='M5.97918 30.0208C3.60169 27.6433 1.9826 24.6142 1.32665 21.3165C0.670701 18.0189 1.00736 14.6007 2.29405 11.4944C3.58074 8.38804 5.75967 5.733 8.55531 3.86502C11.3509 1.99703 14.6377 1 18 1C21.3623 1 24.6491 1.99703 27.4447 3.86502C30.2403 5.733 32.4193 8.38804 33.706 11.4944C34.9926 14.6007 35.3293 18.0189 34.6733 21.3165C34.0174 24.6142 32.3983 27.6433 30.0208 30.0208'
        stroke='#6461F3'
        strokeWidth='1.7'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default StopSeven;
