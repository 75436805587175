const WhyUsIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 687 572' fill='none'>
      <path
        d='M428.995 309.829L429.053 308.718L429.455 309.171L429.386 310.26L428.995 309.829Z'
        fill='url(#paint0_linear_3150_15389)'
      />
      <path
        d='M428.995 327.023L429.041 325.944L429.432 326.419L429.363 327.486L428.995 327.023Z'
        fill='url(#paint1_linear_3150_15389)'
      />
      <path
        d='M447.336 309.829L447.416 308.718L447.876 309.171L447.784 310.26L447.336 309.829Z'
        fill='url(#paint2_linear_3150_15389)'
      />
      <path
        d='M447.301 327.033L447.393 325.944L447.819 326.419L447.727 327.486L447.301 327.033Z'
        fill='url(#paint3_linear_3150_15389)'
      />
      <path
        d='M410.631 309.861L410.654 308.75L411.011 309.203L410.976 310.303L410.631 309.861Z'
        fill='url(#paint4_linear_3150_15389)'
      />
      <path
        d='M428.926 292.668L428.984 291.535L429.397 291.945L429.34 293.067L428.926 292.668Z'
        fill='url(#paint5_linear_3150_15389)'
      />
      <path
        d='M410.654 327.077L410.677 325.987L411.022 326.462L410.988 327.54L410.654 327.077Z'
        fill='url(#paint6_linear_3150_15389)'
      />
      <path
        d='M447.244 292.668L447.324 291.535L447.784 291.945L447.704 293.067L447.244 292.668Z'
        fill='url(#paint7_linear_3150_15389)'
      />
      <path
        d='M428.915 344.227L428.972 343.17L429.34 343.666L429.271 344.713L428.915 344.227Z'
        fill='url(#paint8_linear_3150_15389)'
      />
      <path
        d='M447.14 344.227L447.232 343.17L447.646 343.677L447.531 344.713L447.14 344.227Z'
        fill='url(#paint9_linear_3150_15389)'
      />
      <path
        d='M465.63 309.872L465.734 308.761L466.24 309.203L466.113 310.303L465.63 309.872Z'
        fill='url(#paint10_linear_3150_15389)'
      />
      <path
        d='M465.561 327.077L465.676 325.998L466.159 326.472L466.021 327.54L465.561 327.077Z'
        fill='url(#paint11_linear_3150_15389)'
      />
      <path
        d='M410.597 292.689L410.62 291.557L410.976 291.966L410.953 293.088L410.597 292.689Z'
        fill='url(#paint12_linear_3150_15389)'
      />
      <path
        d='M410.666 344.281L410.689 343.224L411.022 343.731L410.988 344.766L410.666 344.281Z'
        fill='url(#paint13_linear_3150_15389)'
      />
      <path
        d='M465.515 292.689L465.607 291.557L466.125 291.966L466.021 293.088L465.515 292.689Z'
        fill='url(#paint14_linear_3150_15389)'
      />
      <path
        d='M465.308 344.292L465.435 343.235L465.895 343.742L465.745 344.777L465.308 344.292Z'
        fill='url(#paint15_linear_3150_15389)'
      />
      <path
        d='M392.268 309.937V308.826L392.567 309.268V310.368L392.268 309.937Z'
        fill='url(#paint16_linear_3150_15389)'
      />
      <path
        d='M392.325 327.174L392.314 326.084L392.613 326.559V327.637L392.325 327.174Z'
        fill='url(#paint17_linear_3150_15389)'
      />
      <path
        d='M428.8 275.56L428.834 274.406L429.271 274.773L429.213 275.916L428.8 275.56Z'
        fill='url(#paint18_linear_3150_15389)'
      />
      <path
        d='M447.025 275.56L447.094 274.406L447.577 274.773L447.497 275.916L447.025 275.56Z'
        fill='url(#paint19_linear_3150_15389)'
      />
      <path
        d='M428.765 361.399L428.823 360.364L429.179 360.892L429.099 361.895L428.765 361.399Z'
        fill='url(#paint20_linear_3150_15389)'
      />
      <path
        d='M446.841 361.399L446.945 360.364L447.336 360.892L447.221 361.895L446.841 361.399Z'
        fill='url(#paint21_linear_3150_15389)'
      />
      <path
        d='M483.833 309.948L483.971 308.826L484.523 309.279L484.374 310.379L483.833 309.948Z'
        fill='url(#paint22_linear_3150_15389)'
      />
      <path
        d='M483.741 327.184L483.891 326.095L484.408 326.58L484.247 327.648L483.741 327.184Z'
        fill='url(#paint23_linear_3150_15389)'
      />
      <path d='M392.256 292.732V291.6L392.567 292.01V293.131L392.256 292.732Z' fill='url(#paint24_linear_3150_15389)' />
      <path
        d='M410.539 275.571L410.562 274.406L410.942 274.773L410.907 275.927L410.539 275.571Z'
        fill='url(#paint25_linear_3150_15389)'
      />
      <path
        d='M392.429 344.41L392.406 343.353L392.693 343.85L392.705 344.896L392.429 344.41Z'
        fill='url(#paint26_linear_3150_15389)'
      />
      <path
        d='M465.205 275.571L465.285 274.406L465.826 274.773L465.734 275.927L465.205 275.571Z'
        fill='url(#paint27_linear_3150_15389)'
      />
      <path
        d='M410.666 361.475L410.689 360.439L410.999 360.968L410.965 361.971L410.666 361.475Z'
        fill='url(#paint28_linear_3150_15389)'
      />
      <path
        d='M483.695 292.732L483.81 291.6L484.385 292.01L484.247 293.131L483.695 292.732Z'
        fill='url(#paint29_linear_3150_15389)'
      />
      <path
        d='M464.86 361.475L465.009 360.45L465.435 360.979L465.274 361.982L464.86 361.475Z'
        fill='url(#paint30_linear_3150_15389)'
      />
      <path
        d='M483.396 344.421L483.557 343.353L484.063 343.871L483.868 344.907L483.396 344.421Z'
        fill='url(#paint31_linear_3150_15389)'
      />
      <path
        d='M392.291 275.582V274.428L392.613 274.794L392.601 275.948L392.291 275.582Z'
        fill='url(#paint32_linear_3150_15389)'
      />
      <path
        d='M373.95 310.045L373.904 308.934L374.168 309.376L374.191 310.476L373.95 310.045Z'
        fill='url(#paint33_linear_3150_15389)'
      />
      <path
        d='M374.03 327.325L373.984 326.224L374.237 326.71L374.272 327.778L374.03 327.325Z'
        fill='url(#paint34_linear_3150_15389)'
      />
      <path
        d='M428.593 258.539L428.627 257.352L429.064 257.676L429.018 258.852L428.593 258.539Z'
        fill='url(#paint35_linear_3150_15389)'
      />
      <path
        d='M446.68 258.528L446.738 257.352L447.232 257.676L447.163 258.852L446.68 258.528Z'
        fill='url(#paint36_linear_3150_15389)'
      />
      <path
        d='M392.567 361.626L392.555 360.59L392.831 361.108V362.122L392.567 361.626Z'
        fill='url(#paint37_linear_3150_15389)'
      />
      <path
        d='M483.304 275.582L483.408 274.428L484.006 274.794L483.879 275.948L483.304 275.582Z'
        fill='url(#paint38_linear_3150_15389)'
      />
      <path
        d='M428.535 378.517L428.604 377.514L428.949 378.054L428.857 379.024L428.535 378.517Z'
        fill='url(#paint39_linear_3150_15389)'
      />
      <path
        d='M482.81 361.626L482.982 360.601L483.454 361.13L483.258 362.133L482.81 361.626Z'
        fill='url(#paint40_linear_3150_15389)'
      />
      <path
        d='M446.416 378.517L446.519 377.514L446.899 378.064L446.772 379.024L446.416 378.517Z'
        fill='url(#paint41_linear_3150_15389)'
      />
      <path
        d='M501.932 310.055L502.093 308.934L502.691 309.387L502.507 310.487L501.932 310.055Z'
        fill='url(#paint42_linear_3150_15389)'
      />
      <path
        d='M501.806 327.325L501.99 326.235L502.553 326.721L502.346 327.799L501.806 327.325Z'
        fill='url(#paint43_linear_3150_15389)'
      />
      <path
        d='M373.961 292.797L373.938 291.664L374.191 292.074L374.214 293.196L373.961 292.797Z'
        fill='url(#paint44_linear_3150_15389)'
      />
      <path
        d='M410.482 258.528L410.493 257.352L410.884 257.676L410.85 258.841L410.482 258.528Z'
        fill='url(#paint45_linear_3150_15389)'
      />
      <path
        d='M374.226 344.594L374.168 343.537L374.41 344.033L374.456 345.079L374.226 344.594Z'
        fill='url(#paint46_linear_3150_15389)'
      />
      <path
        d='M464.71 258.528L464.791 257.352L465.343 257.676L465.251 258.841L464.71 258.528Z'
        fill='url(#paint47_linear_3150_15389)'
      />
      <path
        d='M410.643 378.604L410.666 377.601L410.965 378.14L410.93 379.111L410.643 378.604Z'
        fill='url(#paint48_linear_3150_15389)'
      />
      <path
        d='M464.239 378.604L464.389 377.611L464.791 378.151L464.618 379.121L464.239 378.604Z'
        fill='url(#paint49_linear_3150_15389)'
      />
      <path
        d='M501.76 292.808L501.909 291.664L502.53 292.074L502.369 293.207L501.76 292.808Z'
        fill='url(#paint50_linear_3150_15389)'
      />
      <path
        d='M501.369 344.605L501.576 343.548L502.116 344.054L501.886 345.101L501.369 344.605Z'
        fill='url(#paint51_linear_3150_15389)'
      />
      <path
        d='M374.088 275.614L374.065 274.449L374.329 274.816L374.341 275.97L374.088 275.614Z'
        fill='url(#paint52_linear_3150_15389)'
      />
      <path
        d='M392.371 258.517V257.342L392.705 257.665L392.693 258.841L392.371 258.517Z'
        fill='url(#paint53_linear_3150_15389)'
      />
      <path
        d='M374.513 361.841L374.456 360.806L374.697 361.335L374.743 362.348L374.513 361.841Z'
        fill='url(#paint54_linear_3150_15389)'
      />
      <path
        d='M482.672 258.517L482.764 257.342L483.373 257.665L483.27 258.841L482.672 258.517Z'
        fill='url(#paint55_linear_3150_15389)'
      />
      <path
        d='M392.751 378.776L392.739 377.773L393.004 378.312L393.015 379.283L392.751 378.776Z'
        fill='url(#paint56_linear_3150_15389)'
      />
      <path
        d='M481.982 378.787L482.166 377.784L482.614 378.323L482.396 379.294L481.982 378.787Z'
        fill='url(#paint57_linear_3150_15389)'
      />
      <path
        d='M501.288 275.614L501.415 274.449L502.059 274.827L501.909 275.97L501.288 275.614Z'
        fill='url(#paint58_linear_3150_15389)'
      />
      <path
        d='M500.644 361.852L500.863 360.828L501.369 361.356L501.127 362.359L500.644 361.852Z'
        fill='url(#paint59_linear_3150_15389)'
      />
      <path
        d='M355.69 310.185L355.621 309.074L355.828 309.516L355.885 310.616L355.69 310.185Z'
        fill='url(#paint60_linear_3150_15389)'
      />
      <path
        d='M355.805 327.519L355.724 326.419L355.931 326.904L356 327.983L355.805 327.519Z'
        fill='url(#paint61_linear_3150_15389)'
      />
      <path
        d='M428.305 241.626L428.34 240.428L428.788 240.698L428.754 241.885L428.305 241.626Z'
        fill='url(#paint62_linear_3150_15389)'
      />
      <path
        d='M446.197 241.626L446.243 240.428L446.749 240.698L446.703 241.885L446.197 241.626Z'
        fill='url(#paint63_linear_3150_15389)'
      />
      <path
        d='M445.852 395.56L445.979 394.589L446.324 395.139L446.186 396.067L445.852 395.56Z'
        fill='url(#paint64_linear_3150_15389)'
      />
      <path
        d='M428.248 395.56L428.317 394.589L428.639 395.139L428.547 396.067L428.248 395.56Z'
        fill='url(#paint65_linear_3150_15389)'
      />
      <path
        d='M519.721 327.53L519.94 326.44L520.549 326.925L520.319 328.004L519.721 327.53Z'
        fill='url(#paint66_linear_3150_15389)'
      />
      <path
        d='M519.882 310.196L520.078 309.085L520.722 309.538L520.503 310.638L519.882 310.196Z'
        fill='url(#paint67_linear_3150_15389)'
      />
      <path
        d='M355.736 292.894L355.678 291.751L355.885 292.161L355.931 293.293L355.736 292.894Z'
        fill='url(#paint68_linear_3150_15389)'
      />
      <path
        d='M356.081 344.842L356 343.774L356.196 344.281L356.276 345.327L356.081 344.842Z'
        fill='url(#paint69_linear_3150_15389)'
      />
      <path
        d='M410.401 241.604L410.424 240.407L410.815 240.676L410.781 241.874L410.401 241.604Z'
        fill='url(#paint70_linear_3150_15389)'
      />
      <path
        d='M464.032 241.604L464.101 240.407L464.665 240.676L464.596 241.874L464.032 241.604Z'
        fill='url(#paint71_linear_3150_15389)'
      />
      <path
        d='M463.423 395.657L463.584 394.686L463.963 395.237L463.779 396.175L463.423 395.657Z'
        fill='url(#paint72_linear_3150_15389)'
      />
      <path
        d='M410.608 395.657L410.631 394.686L410.919 395.237L410.884 396.164L410.608 395.657Z'
        fill='url(#paint73_linear_3150_15389)'
      />
      <path
        d='M519.204 344.863L519.445 343.796L520.02 344.313L519.756 345.349L519.204 344.863Z'
        fill='url(#paint74_linear_3150_15389)'
      />
      <path
        d='M519.687 292.894L519.859 291.751L520.526 292.171L520.342 293.304L519.687 292.894Z'
        fill='url(#paint75_linear_3150_15389)'
      />
      <path
        d='M374.306 258.507L374.295 257.331L374.559 257.654L374.571 258.83L374.306 258.507Z'
        fill='url(#paint76_linear_3150_15389)'
      />
      <path
        d='M374.904 379.046L374.847 378.043L375.077 378.571L375.123 379.553L374.904 379.046Z'
        fill='url(#paint77_linear_3150_15389)'
      />
      <path
        d='M500.507 258.507L500.621 257.331L501.288 257.644L501.162 258.819L500.507 258.507Z'
        fill='url(#paint78_linear_3150_15389)'
      />
      <path
        d='M499.61 379.046L499.84 378.043L500.311 378.593L500.058 379.564L499.61 379.046Z'
        fill='url(#paint79_linear_3150_15389)'
      />
      <path
        d='M355.943 275.646L355.897 274.481L356.104 274.848L356.138 276.013L355.943 275.646Z'
        fill='url(#paint80_linear_3150_15389)'
      />
      <path
        d='M392.498 241.572L392.509 240.374L392.831 240.644L392.82 241.841L392.498 241.572Z'
        fill='url(#paint81_linear_3150_15389)'
      />
      <path
        d='M481.786 241.572L481.867 240.374L482.488 240.644L482.407 241.841L481.786 241.572Z'
        fill='url(#paint82_linear_3150_15389)'
      />
      <path
        d='M356.518 362.143L356.426 361.108L356.621 361.637L356.713 362.65L356.518 362.143Z'
        fill='url(#paint83_linear_3150_15389)'
      />
      <path
        d='M480.901 395.851L481.108 394.881L481.522 395.431L481.28 396.369L480.901 395.851Z'
        fill='url(#paint84_linear_3150_15389)'
      />
      <path
        d='M392.992 395.851L392.969 394.891L393.222 395.431L393.234 396.369L392.992 395.851Z'
        fill='url(#paint85_linear_3150_15389)'
      />
      <path
        d='M518.33 362.154L518.583 361.119L519.135 361.658L518.847 362.672L518.33 362.154Z'
        fill='url(#paint86_linear_3150_15389)'
      />
      <path
        d='M519.123 275.646L519.284 274.481L519.974 274.859L519.813 276.013L519.123 275.646Z'
        fill='url(#paint87_linear_3150_15389)'
      />
      <path
        d='M337.671 327.767L337.567 326.667L337.717 327.141L337.82 328.231L337.671 327.767Z'
        fill='url(#paint88_linear_3150_15389)'
      />
      <path
        d='M337.521 310.368L337.429 309.246L337.579 309.689L337.671 310.8L337.521 310.368Z'
        fill='url(#paint89_linear_3150_15389)'
      />
      <path
        d='M445.588 224.853L445.622 223.645L446.14 223.86L446.094 225.068L445.588 224.853Z'
        fill='url(#paint90_linear_3150_15389)'
      />
      <path
        d='M427.96 224.853L427.983 223.645L428.443 223.86L428.409 225.068L427.96 224.853Z'
        fill='url(#paint91_linear_3150_15389)'
      />
      <path
        d='M445.174 412.484L445.289 411.546L445.622 412.096L445.473 412.991L445.174 412.484Z'
        fill='url(#paint92_linear_3150_15389)'
      />
      <path
        d='M427.88 412.484L427.949 411.557L428.248 412.096L428.156 412.991L427.88 412.484Z'
        fill='url(#paint93_linear_3150_15389)'
      />
      <path
        d='M537.475 327.778L537.717 326.677L538.372 327.174L538.108 328.252L537.475 327.778Z'
        fill='url(#paint94_linear_3150_15389)'
      />
      <path
        d='M537.659 310.379L537.889 309.257L538.568 309.721L538.326 310.821L537.659 310.379Z'
        fill='url(#paint95_linear_3150_15389)'
      />
      <path
        d='M356.311 258.496L356.276 257.309L356.483 257.633L356.506 258.809L356.311 258.496Z'
        fill='url(#paint96_linear_3150_15389)'
      />
      <path
        d='M374.628 241.518V240.321L374.893 240.59L374.904 241.788L374.628 241.518Z'
        fill='url(#paint97_linear_3150_15389)'
      />
      <path
        d='M499.426 241.518L499.518 240.321L500.208 240.59L500.104 241.788L499.426 241.518Z'
        fill='url(#paint98_linear_3150_15389)'
      />
      <path
        d='M463.17 224.831L463.216 223.612L463.791 223.828L463.733 225.036L463.17 224.831Z'
        fill='url(#paint99_linear_3150_15389)'
      />
      <path
        d='M357.127 379.391L357.024 378.388L357.208 378.927L357.311 379.898L357.127 379.391Z'
        fill='url(#paint100_linear_3150_15389)'
      />
      <path
        d='M338.039 345.155L337.924 344.087L338.073 344.594L338.188 345.64L338.039 345.155Z'
        fill='url(#paint101_linear_3150_15389)'
      />
      <path
        d='M337.602 293.002L337.51 291.859L337.671 292.268L337.74 293.401L337.602 293.002Z'
        fill='url(#paint102_linear_3150_15389)'
      />
      <path
        d='M498.276 396.143L498.517 395.183L498.966 395.733L498.678 396.671L498.276 396.143Z'
        fill='url(#paint103_linear_3150_15389)'
      />
      <path
        d='M410.309 224.82L410.332 223.612L410.723 223.828L410.7 225.036L410.309 224.82Z'
        fill='url(#paint104_linear_3150_15389)'
      />
      <path
        d='M517.099 379.402L517.375 378.388L517.881 378.949L517.571 379.92L517.099 379.402Z'
        fill='url(#paint105_linear_3150_15389)'
      />
      <path
        d='M375.399 396.153L375.33 395.183L375.56 395.722L375.617 396.66L375.399 396.153Z'
        fill='url(#paint106_linear_3150_15389)'
      />
      <path
        d='M518.215 258.496L518.341 257.309L519.054 257.633L518.916 258.809L518.215 258.496Z'
        fill='url(#paint107_linear_3150_15389)'
      />
      <path
        d='M462.411 412.592L462.583 411.654L462.94 412.204L462.744 413.099L462.411 412.592Z'
        fill='url(#paint108_linear_3150_15389)'
      />
      <path
        d='M410.562 412.603L410.597 411.664L410.861 412.204L410.815 413.11L410.562 412.603Z'
        fill='url(#paint109_linear_3150_15389)'
      />
      <path
        d='M536.866 345.176L537.142 344.108L537.751 344.626L537.464 345.672L536.866 345.176Z'
        fill='url(#paint110_linear_3150_15389)'
      />
      <path
        d='M537.441 293.013L537.636 291.859L538.349 292.279L538.142 293.412L537.441 293.013Z'
        fill='url(#paint111_linear_3150_15389)'
      />
      <path
        d='M480.659 224.766L480.728 223.548L481.361 223.763L481.292 224.982L480.659 224.766Z'
        fill='url(#paint112_linear_3150_15389)'
      />
      <path
        d='M338.637 362.521L338.499 361.486L338.648 362.003L338.786 363.028L338.637 362.521Z'
        fill='url(#paint113_linear_3150_15389)'
      />
      <path
        d='M392.67 224.766V223.548L393.015 223.763L392.992 224.982L392.67 224.766Z'
        fill='url(#paint114_linear_3150_15389)'
      />
      <path
        d='M479.579 412.808L479.797 411.869L480.176 412.43L479.935 413.326L479.579 412.808Z'
        fill='url(#paint115_linear_3150_15389)'
      />
      <path
        d='M535.842 362.543L536.141 361.496L536.716 362.046L536.395 363.05L535.842 362.543Z'
        fill='url(#paint116_linear_3150_15389)'
      />
      <path
        d='M393.268 412.819L393.234 411.891L393.486 412.43V413.326L393.268 412.819Z'
        fill='url(#paint117_linear_3150_15389)'
      />
      <path
        d='M536.797 275.7L536.969 274.525L537.717 274.902L537.521 276.056L536.797 275.7Z'
        fill='url(#paint118_linear_3150_15389)'
      />
      <path
        d='M515.501 396.542L515.8 395.571L516.26 396.132L515.938 397.07L515.501 396.542Z'
        fill='url(#paint119_linear_3150_15389)'
      />
      <path
        d='M356.84 241.453L356.817 240.245L357.024 240.525L357.047 241.723L356.84 241.453Z'
        fill='url(#paint120_linear_3150_15389)'
      />
      <path
        d='M516.938 241.453L517.042 240.245L517.778 240.515L517.663 241.723L516.938 241.453Z'
        fill='url(#paint121_linear_3150_15389)'
      />
      <path
        d='M357.886 396.553L357.771 395.582L357.966 396.121L358.07 397.059L357.886 396.553Z'
        fill='url(#paint122_linear_3150_15389)'
      />
      <path
        d='M498.046 224.68L498.115 223.461L498.816 223.677L498.736 224.885L498.046 224.68Z'
        fill='url(#paint123_linear_3150_15389)'
      />
      <path
        d='M496.631 413.131L496.896 412.193L497.298 412.754L497.011 413.649L496.631 413.131Z'
        fill='url(#paint124_linear_3150_15389)'
      />
      <path
        d='M339.442 379.833L339.292 378.819L339.453 379.359L339.591 380.34L339.442 379.833Z'
        fill='url(#paint125_linear_3150_15389)'
      />
      <path
        d='M375.065 224.68V223.461L375.341 223.677L375.33 224.896L375.065 224.68Z'
        fill='url(#paint126_linear_3150_15389)'
      />
      <path
        d='M534.405 379.833L534.727 378.83L535.268 379.391L534.923 380.362L534.405 379.833Z'
        fill='url(#paint127_linear_3150_15389)'
      />
      <path
        d='M444.84 208.263L444.875 207.033L445.404 207.184L445.369 208.414L444.84 208.263Z'
        fill='url(#paint128_linear_3150_15389)'
      />
      <path
        d='M319.675 328.058L319.526 326.958L319.629 327.443L319.779 328.522L319.675 328.058Z'
        fill='url(#paint129_linear_3150_15389)'
      />
      <path
        d='M427.535 208.252L427.558 207.033L428.018 207.184L427.995 208.414L427.535 208.252Z'
        fill='url(#paint130_linear_3150_15389)'
      />
      <path
        d='M535.739 258.474L535.889 257.277L536.647 257.601L536.498 258.787L535.739 258.474Z'
        fill='url(#paint131_linear_3150_15389)'
      />
      <path
        d='M376.008 413.153L375.928 412.215L376.146 412.765L376.204 413.66L376.008 413.153Z'
        fill='url(#paint132_linear_3150_15389)'
      />
      <path
        d='M444.357 429.268L444.484 428.373L444.783 428.912L444.633 429.764L444.357 429.268Z'
        fill='url(#paint133_linear_3150_15389)'
      />
      <path
        d='M427.443 429.279L427.523 428.373L427.799 428.912L427.696 429.775L427.443 429.279Z'
        fill='url(#paint134_linear_3150_15389)'
      />
      <path
        d='M555.023 328.08L555.299 326.979L555.989 327.476L555.69 328.554L555.023 328.08Z'
        fill='url(#paint135_linear_3150_15389)'
      />
      <path
        d='M555.241 310.595L555.483 309.473L556.219 309.937L555.943 311.048L555.241 310.595Z'
        fill='url(#paint136_linear_3150_15389)'
      />
      <path
        d='M462.112 208.22L462.146 206.99L462.733 207.141L462.687 208.371L462.112 208.22Z'
        fill='url(#paint137_linear_3150_15389)'
      />
      <path
        d='M320.135 345.532L319.974 344.454L320.078 344.971L320.239 346.018L320.135 345.532Z'
        fill='url(#paint138_linear_3150_15389)'
      />
      <path
        d='M410.206 208.22L410.217 206.979L410.62 207.141L410.597 208.371L410.206 208.22Z'
        fill='url(#paint139_linear_3150_15389)'
      />
      <path
        d='M461.226 429.376L461.399 428.481L461.732 429.031L461.525 429.883L461.226 429.376Z'
        fill='url(#paint140_linear_3150_15389)'
      />
      <path
        d='M410.505 429.397L410.528 428.502L410.781 429.042L410.746 429.894L410.505 429.397Z'
        fill='url(#paint141_linear_3150_15389)'
      />
      <path
        d='M554.321 345.554L554.632 344.486L555.287 345.004L554.954 346.061L554.321 345.554Z'
        fill='url(#paint142_linear_3150_15389)'
      />
      <path
        d='M554.988 293.142L555.207 291.999L555.966 292.419L555.724 293.552L554.988 293.142Z'
        fill='url(#paint143_linear_3150_15389)'
      />
      <path
        d='M318.376 309.516L318.261 308.373L318.33 308.815L318.468 309.926L318.376 309.516Z'
        fill='url(#paint144_linear_3150_15389)'
      />
      <path
        d='M334.923 272.184L334.9 270.987L334.946 271.299L335.072 272.486L334.923 272.184Z'
        fill='url(#paint145_linear_3150_15389)'
      />
      <path
        d='M479.291 208.134L479.337 206.904L479.981 207.066L479.935 208.295L479.291 208.134Z'
        fill='url(#paint146_linear_3150_15389)'
      />
      <path
        d='M320.871 362.974L320.699 361.928L320.814 362.456L320.986 363.481L320.871 362.974Z'
        fill='url(#paint147_linear_3150_15389)'
      />
      <path
        d='M478.015 429.613L478.245 428.718L478.59 429.268L478.325 430.12L478.015 429.613Z'
        fill='url(#paint148_linear_3150_15389)'
      />
      <path
        d='M392.877 208.134L392.889 206.904L393.234 207.066L393.211 208.295L392.877 208.134Z'
        fill='url(#paint149_linear_3150_15389)'
      />
      <path
        d='M553.16 362.996L553.493 361.949L554.103 362.499L553.735 363.513L553.16 362.996Z'
        fill='url(#paint150_linear_3150_15389)'
      />
      <path
        d='M393.59 429.646L393.555 428.75L393.785 429.279L393.797 430.142L393.59 429.646Z'
        fill='url(#paint151_linear_3150_15389)'
      />
      <path
        d='M554.264 275.754L554.448 274.579L555.241 274.956L555.034 276.121L554.264 275.754Z'
        fill='url(#paint152_linear_3150_15389)'
      />
      <path
        d='M513.546 413.563L513.857 412.635L514.294 413.196L513.949 414.091L513.546 413.563Z'
        fill='url(#paint153_linear_3150_15389)'
      />
      <path
        d='M515.294 224.561L515.375 223.343L516.122 223.558L516.041 224.777L515.294 224.561Z'
        fill='url(#paint154_linear_3150_15389)'
      />
      <path
        d='M532.554 397.038L532.899 396.067L533.393 396.628L533.025 397.566L532.554 397.038Z'
        fill='url(#paint155_linear_3150_15389)'
      />
      <path
        d='M340.477 397.049L340.316 396.078L340.465 396.617L340.626 397.566L340.477 397.049Z'
        fill='url(#paint156_linear_3150_15389)'
      />
      <path
        d='M357.529 224.561L357.518 223.332L357.736 223.558V224.777L357.529 224.561Z'
        fill='url(#paint157_linear_3150_15389)'
      />
      <path
        d='M534.256 241.367L534.382 240.159L535.164 240.428L535.038 241.637L534.256 241.367Z'
        fill='url(#paint158_linear_3150_15389)'
      />
      <path
        d='M358.817 413.595L358.691 412.657L358.875 413.207L358.99 414.102L358.817 413.595Z'
        fill='url(#paint159_linear_3150_15389)'
      />
      <path
        d='M496.367 208.015L496.413 206.785L497.114 206.936L497.057 208.166L496.367 208.015Z'
        fill='url(#paint160_linear_3150_15389)'
      />
      <path
        d='M494.688 429.969L494.976 429.063L495.344 429.613L495.033 430.476L494.688 429.969Z'
        fill='url(#paint161_linear_3150_15389)'
      />
      <path
        d='M321.895 380.362L321.699 379.348L321.814 379.887L322.01 380.869L321.895 380.362Z'
        fill='url(#paint162_linear_3150_15389)'
      />
      <path
        d='M551.515 380.362L551.872 379.359L552.447 379.92L552.056 380.89L551.515 380.362Z'
        fill='url(#paint163_linear_3150_15389)'
      />
      <path
        d='M375.594 208.004L375.606 206.774L375.882 206.936L375.87 208.166L375.594 208.004Z'
        fill='url(#paint164_linear_3150_15389)'
      />
      <path
        d='M553.056 258.453L553.217 257.255L554.045 257.579L553.861 258.766L553.056 258.453Z'
        fill='url(#paint165_linear_3150_15389)'
      />
      <path
        d='M376.71 430.012L376.629 429.106L376.825 429.646L376.894 430.508L376.71 430.012Z'
        fill='url(#paint166_linear_3150_15389)'
      />
      <path
        d='M443.978 191.867L444.001 190.627L444.53 190.724L444.507 191.965L443.978 191.867Z'
        fill='url(#paint167_linear_3150_15389)'
      />
      <path
        d='M427.04 191.867L427.063 190.616L427.523 190.724L427.5 191.965L427.04 191.867Z'
        fill='url(#paint168_linear_3150_15389)'
      />
      <path
        d='M443.403 445.879L443.541 445.016L443.817 445.545L443.656 446.365L443.403 445.879Z'
        fill='url(#paint169_linear_3150_15389)'
      />
      <path
        d='M426.925 445.89L427.006 445.027L427.27 445.556L427.167 446.365L426.925 445.89Z'
        fill='url(#paint170_linear_3150_15389)'
      />
      <path
        d='M572.317 328.425L572.627 327.325L573.363 327.821L573.03 328.91L572.317 328.425Z'
        fill='url(#paint171_linear_3150_15389)'
      />
      <path
        d='M572.57 310.854L572.846 309.721L573.628 310.185L573.317 311.307L572.57 310.854Z'
        fill='url(#paint172_linear_3150_15389)'
      />
      <path
        d='M460.858 191.814L460.881 190.573L461.479 190.67L461.445 191.911L460.858 191.814Z'
        fill='url(#paint173_linear_3150_15389)'
      />
      <path
        d='M410.079 191.803L410.102 190.562L410.505 190.67L410.482 191.911L410.079 191.803Z'
        fill='url(#paint174_linear_3150_15389)'
      />
      <path
        d='M459.835 445.998L460.03 445.135L460.329 445.664L460.111 446.483L459.835 445.998Z'
        fill='url(#paint175_linear_3150_15389)'
      />
      <path
        d='M571.535 345.996L571.88 344.917L572.57 345.446L572.202 346.503L571.535 345.996Z'
        fill='url(#paint176_linear_3150_15389)'
      />
      <path
        d='M410.436 446.03L410.459 445.167L410.689 445.696L410.654 446.505L410.436 446.03Z'
        fill='url(#paint177_linear_3150_15389)'
      />
      <path
        d='M530.289 414.113L530.645 413.175L531.105 413.746L530.703 414.641L530.289 414.113Z'
        fill='url(#paint178_linear_3150_15389)'
      />
      <path
        d='M572.294 293.304L572.535 292.15L573.352 292.57L573.076 293.714L572.294 293.304Z'
        fill='url(#paint179_linear_3150_15389)'
      />
      <path
        d='M532.37 224.421L532.462 223.192L533.267 223.396L533.163 224.626L532.37 224.421Z'
        fill='url(#paint180_linear_3150_15389)'
      />
      <path
        d='M341.73 414.145L341.558 413.207L341.707 413.757L341.868 414.652L341.73 414.145Z'
        fill='url(#paint181_linear_3150_15389)'
      />
      <path
        d='M511.223 430.433L511.568 429.538L511.959 430.099L511.591 430.951L511.223 430.433Z'
        fill='url(#paint182_linear_3150_15389)'
      />
      <path
        d='M513.293 207.853L513.351 206.613L514.11 206.764L514.052 208.004L513.293 207.853Z'
        fill='url(#paint183_linear_3150_15389)'
      />
      <path
        d='M549.4 397.631L549.791 396.66L550.32 397.232L549.894 398.171L549.4 397.631Z'
        fill='url(#paint184_linear_3150_15389)'
      />
      <path
        d='M323.194 397.642L322.987 396.671L323.102 397.21L323.309 398.16L323.194 397.642Z'
        fill='url(#paint185_linear_3150_15389)'
      />
      <path
        d='M477.67 191.716L477.693 190.476L478.348 190.562L478.325 191.814L477.67 191.716Z'
        fill='url(#paint186_linear_3150_15389)'
      />
      <path
        d='M551.378 241.27L551.515 240.051L552.355 240.321L552.217 241.529L551.378 241.27Z'
        fill='url(#paint187_linear_3150_15389)'
      />
      <path
        d='M358.392 207.842V206.602L358.599 206.764V208.004L358.392 207.842Z'
        fill='url(#paint188_linear_3150_15389)'
      />
      <path
        d='M476.198 446.246L476.439 445.383L476.75 445.912L476.485 446.731L476.198 446.246Z'
        fill='url(#paint189_linear_3150_15389)'
      />
      <path
        d='M359.898 430.498L359.772 429.592L359.944 430.131L360.071 430.994L359.898 430.498Z'
        fill='url(#paint190_linear_3150_15389)'
      />
      <path
        d='M393.142 191.706L393.165 190.454L393.498 190.562L393.475 191.803L393.142 191.706Z'
        fill='url(#paint191_linear_3150_15389)'
      />
      <path
        d='M570.224 363.524L570.604 362.478L571.248 363.028L570.845 364.053L570.224 363.524Z'
        fill='url(#paint192_linear_3150_15389)'
      />
      <path
        d='M393.958 446.3L393.923 445.437L394.13 445.955L394.142 446.775L393.958 446.3Z'
        fill='url(#paint193_linear_3150_15389)'
      />
      <path
        d='M571.478 275.819L571.696 274.632L572.535 275.01L572.305 276.186L571.478 275.819Z'
        fill='url(#paint194_linear_3150_15389)'
      />
      <path
        d='M494.366 191.565L494.401 190.314L495.114 190.411L495.079 191.652L494.366 191.565Z'
        fill='url(#paint195_linear_3150_15389)'
      />
      <path
        d='M492.446 446.613L492.745 445.761L493.078 446.289L492.745 447.109L492.446 446.613Z'
        fill='url(#paint196_linear_3150_15389)'
      />
      <path
        d='M568.384 380.977L568.787 379.963L569.385 380.535L568.948 381.516L568.384 380.977Z'
        fill='url(#paint197_linear_3150_15389)'
      />
      <path
        d='M376.238 191.544L376.261 190.293L376.526 190.401L376.514 191.641L376.238 191.544Z'
        fill='url(#paint198_linear_3150_15389)'
      />
      <path
        d='M570.144 258.42L570.316 257.212L571.19 257.547L570.995 258.744L570.144 258.42Z'
        fill='url(#paint199_linear_3150_15389)'
      />
      <path
        d='M377.515 446.688L377.434 445.825L377.618 446.354L377.687 447.163L377.515 446.688Z'
        fill='url(#paint200_linear_3150_15389)'
      />
      <path
        d='M527.598 431.026L527.977 430.131L528.391 430.692L527.977 431.544L527.598 431.026Z'
        fill='url(#paint201_linear_3150_15389)'
      />
      <path
        d='M546.812 414.771L547.226 413.832L547.709 414.404L547.261 415.299L546.812 414.771Z'
        fill='url(#paint202_linear_3150_15389)'
      />
      <path
        d='M530.047 207.648L530.116 206.408L530.933 206.559L530.864 207.799L530.047 207.648Z'
        fill='url(#paint203_linear_3150_15389)'
      />
      <path
        d='M549.227 224.238L549.331 223.008L550.193 223.224L550.09 224.454L549.227 224.238Z'
        fill='url(#paint204_linear_3150_15389)'
      />
      <path
        d='M343.202 431.102L343.018 430.196L343.167 430.735L343.34 431.598L343.202 431.102Z'
        fill='url(#paint205_linear_3150_15389)'
      />
      <path
        d='M442.966 175.709L442.978 174.458L443.518 174.49L443.507 175.742L442.966 175.709Z'
        fill='url(#paint206_linear_3150_15389)'
      />
      <path
        d='M426.465 175.709L426.488 174.447L426.96 174.49L426.937 175.742L426.465 175.709Z'
        fill='url(#paint207_linear_3150_15389)'
      />
      <path
        d='M442.334 462.286L442.472 461.466L442.725 461.973L442.552 462.739L442.334 462.286Z'
        fill='url(#paint208_linear_3150_15389)'
      />
      <path
        d='M426.35 462.307L426.431 461.487L426.661 461.984L426.557 462.76L426.35 462.307Z'
        fill='url(#paint209_linear_3150_15389)'
      />
      <path
        d='M589.347 328.824L589.692 327.713L590.462 328.22L590.094 329.32L589.347 328.824Z'
        fill='url(#paint210_linear_3150_15389)'
      />
      <path
        d='M589.623 311.145L589.933 310.001L590.75 310.476L590.416 311.598L589.623 311.145Z'
        fill='url(#paint211_linear_3150_15389)'
      />
      <path
        d='M459.421 175.655L459.432 174.393L460.03 174.436L460.019 175.688L459.421 175.655Z'
        fill='url(#paint212_linear_3150_15389)'
      />
      <path
        d='M458.271 462.404L458.467 461.584L458.731 462.091L458.513 462.868L458.271 462.404Z'
        fill='url(#paint213_linear_3150_15389)'
      />
      <path
        d='M409.953 175.634L409.976 174.383L410.378 174.426L410.355 175.677L409.953 175.634Z'
        fill='url(#paint214_linear_3150_15389)'
      />
      <path
        d='M508.556 447.12L508.901 446.257L509.257 446.796L508.866 447.616L508.556 447.12Z'
        fill='url(#paint215_linear_3150_15389)'
      />
      <path
        d='M510.924 191.361L510.959 190.109L511.729 190.196L511.695 191.447L510.924 191.361Z'
        fill='url(#paint216_linear_3150_15389)'
      />
      <path
        d='M566.016 398.332L566.441 397.351L566.993 397.933L566.533 398.872L566.016 398.332Z'
        fill='url(#paint217_linear_3150_15389)'
      />
      <path
        d='M588.473 346.503L588.852 345.414L589.577 345.953L589.174 347.01L588.473 346.503Z'
        fill='url(#paint218_linear_3150_15389)'
      />
      <path
        d='M410.344 462.458L410.367 461.638L410.585 462.135L410.539 462.911L410.344 462.458Z'
        fill='url(#paint219_linear_3150_15389)'
      />
      <path
        d='M589.324 293.487L589.588 292.322L590.451 292.754L590.152 293.908L589.324 293.487Z'
        fill='url(#paint220_linear_3150_15389)'
      />
      <path
        d='M568.269 241.151L568.407 239.921L569.304 240.202L569.155 241.41L568.269 241.151Z'
        fill='url(#paint221_linear_3150_15389)'
      />
      <path
        d='M359.415 191.328L359.427 190.077L359.634 190.185L359.622 191.436L359.415 191.328Z'
        fill='url(#paint222_linear_3150_15389)'
      />
      <path
        d='M361.152 447.217L361.014 446.354L361.186 446.872L361.313 447.691L361.152 447.217Z'
        fill='url(#paint223_linear_3150_15389)'
      />
      <path
        d='M475.795 175.537L475.807 174.275L476.462 174.307V175.569L475.795 175.537Z'
        fill='url(#paint224_linear_3150_15389)'
      />
      <path
        d='M474.128 462.663L474.393 461.843L474.669 462.35L474.381 463.116L474.128 462.663Z'
        fill='url(#paint225_linear_3150_15389)'
      />
      <path
        d='M587.024 364.128L587.427 363.082L588.116 363.643L587.668 364.668L587.024 364.128Z'
        fill='url(#paint226_linear_3150_15389)'
      />
      <path
        d='M393.452 175.504L393.463 174.253L393.808 174.285L393.785 175.548L393.452 175.504Z'
        fill='url(#paint227_linear_3150_15389)'
      />
      <path
        d='M394.36 462.739L394.326 461.919L394.533 462.426L394.544 463.192L394.36 462.739Z'
        fill='url(#paint228_linear_3150_15389)'
      />
      <path
        d='M588.427 275.895L588.657 274.697L589.554 275.086L589.301 276.261L588.427 275.895Z'
        fill='url(#paint229_linear_3150_15389)'
      />
      <path
        d='M543.754 431.738L544.191 430.843L544.628 431.404L544.156 432.256L543.754 431.738Z'
        fill='url(#paint230_linear_3150_15389)'
      />
      <path
        d='M546.605 207.4L546.674 206.16L547.548 206.311L547.479 207.551L546.605 207.4Z'
        fill='url(#paint231_linear_3150_15389)'
      />
      <path
        d='M492.066 175.353L492.078 174.091L492.791 174.124V175.386L492.066 175.353Z'
        fill='url(#paint232_linear_3150_15389)'
      />
      <path
        d='M489.893 463.051L490.203 462.232L490.503 462.749L490.158 463.515L489.893 463.051Z'
        fill='url(#paint233_linear_3150_15389)'
      />
      <path
        d='M584.977 381.689L585.426 380.664L586.047 381.246L585.575 382.228L584.977 381.689Z'
        fill='url(#paint234_linear_3150_15389)'
      />
      <path
        d='M524.493 447.745L524.896 446.893L525.264 447.433L524.827 448.242L524.493 447.745Z'
        fill='url(#paint235_linear_3150_15389)'
      />
      <path
        d='M563.106 415.537L563.578 414.598L564.072 415.181L563.578 416.076L563.106 415.537Z'
        fill='url(#paint236_linear_3150_15389)'
      />
      <path
        d='M527.31 191.102L527.345 189.85L528.184 189.937L528.15 191.188L527.31 191.102Z'
        fill='url(#paint237_linear_3150_15389)'
      />
      <path
        d='M586.943 258.388L587.139 257.18L588.07 257.503L587.852 258.712L586.943 258.388Z'
        fill='url(#paint238_linear_3150_15389)'
      />
      <path
        d='M378.434 463.159L378.331 462.339L378.515 462.846L378.584 463.612L378.434 463.159Z'
        fill='url(#paint239_linear_3150_15389)'
      />
      <path
        d='M565.855 224.033L565.97 222.792L566.889 223.008L566.763 224.249L565.855 224.033Z'
        fill='url(#paint240_linear_3150_15389)'
      />
      <path
        d='M344.904 447.864L344.697 447.001L344.846 447.53L345.042 448.339L344.904 447.864Z'
        fill='url(#paint241_linear_3150_15389)'
      />
      <path
        d='M505.509 463.58L505.888 462.76L506.198 463.278L505.796 464.044L505.509 463.58Z'
        fill='url(#paint242_linear_3150_15389)'
      />
      <path
        d='M508.199 175.116L508.211 173.854L508.981 173.876V175.138L508.199 175.116Z'
        fill='url(#paint243_linear_3150_15389)'
      />
      <path
        d='M582.344 399.13L582.827 398.149L583.402 398.731L582.896 399.67L582.344 399.13Z'
        fill='url(#paint244_linear_3150_15389)'
      />
      <path
        d='M441.828 159.821L441.839 158.559L442.368 158.537V159.799L441.828 159.821Z'
        fill='url(#paint245_linear_3150_15389)'
      />
      <path
        d='M425.821 159.81L425.833 158.548L426.304 158.526L426.293 159.788L425.821 159.81Z'
        fill='url(#paint246_linear_3150_15389)'
      />
      <path
        d='M441.115 478.455L441.264 477.678L441.494 478.153L441.31 478.875L441.115 478.455Z'
        fill='url(#paint247_linear_3150_15389)'
      />
      <path
        d='M425.683 478.476L425.775 477.7L425.982 478.174L425.867 478.897L425.683 478.476Z'
        fill='url(#paint248_linear_3150_15389)'
      />
      <path
        d='M584.874 241.011L585.035 239.781L585.978 240.051L585.805 241.281L584.874 241.011Z'
        fill='url(#paint249_linear_3150_15389)'
      />
      <path
        d='M606.055 329.277L606.434 328.155L607.251 328.673L606.848 329.773L606.055 329.277Z'
        fill='url(#paint250_linear_3150_15389)'
      />
      <path
        d='M606.377 311.479L606.71 310.325L607.561 310.8L607.205 311.932L606.377 311.479Z'
        fill='url(#paint251_linear_3150_15389)'
      />
      <path
        d='M362.566 463.72L362.417 462.9L362.578 463.397L362.715 464.173L362.566 463.72Z'
        fill='url(#paint252_linear_3150_15389)'
      />
      <path
        d='M376.169 173.908L376.227 172.635L376.434 172.657L376.445 173.919L376.169 173.908Z'
        fill='url(#paint253_linear_3150_15389)'
      />
      <path
        d='M457.788 159.767L457.8 158.494L458.386 158.472L458.398 159.734L457.788 159.767Z'
        fill='url(#paint254_linear_3150_15389)'
      />
      <path
        d='M456.512 478.573L456.719 477.797L456.949 478.271L456.719 478.994L456.512 478.573Z'
        fill='url(#paint255_linear_3150_15389)'
      />
      <path
        d='M605.1 347.075L605.514 345.985L606.285 346.525L605.836 347.592L605.1 347.075Z'
        fill='url(#paint256_linear_3150_15389)'
      />
      <path
        d='M410.24 478.638L410.275 477.861L410.459 478.336L410.413 479.059L410.24 478.638Z'
        fill='url(#paint257_linear_3150_15389)'
      />
      <path
        d='M606.043 293.692L606.331 292.517L607.239 292.959L606.917 294.113L606.043 293.692Z'
        fill='url(#paint258_linear_3150_15389)'
      />
      <path
        d='M540.224 448.511L540.684 447.648L541.075 448.198L540.58 449.007L540.224 448.511Z'
        fill='url(#paint259_linear_3150_15389)'
      />
      <path
        d='M559.68 432.569L560.174 431.673L560.623 432.245L560.094 433.097L559.68 432.569Z'
        fill='url(#paint260_linear_3150_15389)'
      />
      <path
        d='M543.501 190.789L543.535 189.527L544.421 189.613L544.386 190.875L543.501 190.789Z'
        fill='url(#paint261_linear_3150_15389)'
      />
      <path
        d='M473.68 159.627L473.668 158.365L474.335 158.332L474.347 159.594L473.68 159.627Z'
        fill='url(#paint262_linear_3150_15389)'
      />
      <path
        d='M562.922 207.12L562.991 205.868L563.923 206.009L563.842 207.271L562.922 207.12Z'
        fill='url(#paint263_linear_3150_15389)'
      />
      <path
        d='M471.828 478.832L472.093 478.055L472.334 478.541L472.035 479.253L471.828 478.832Z'
        fill='url(#paint264_linear_3150_15389)'
      />
      <path
        d='M603.502 364.819L603.95 363.761L604.663 364.322L604.18 365.358L603.502 364.819Z'
        fill='url(#paint265_linear_3150_15389)'
      />
      <path
        d='M605.066 275.97L605.319 274.773L606.262 275.161L605.986 276.348L605.066 275.97Z'
        fill='url(#paint266_linear_3150_15389)'
      />
      <path
        d='M394.82 478.951L394.786 478.174L394.958 478.638L394.981 479.361L394.82 478.951Z'
        fill='url(#paint267_linear_3150_15389)'
      />
      <path
        d='M520.963 464.238L521.4 463.418L521.722 463.947L521.25 464.713L520.963 464.238Z'
        fill='url(#paint268_linear_3150_15389)'
      />
      <path
        d='M579.136 416.421L579.642 415.472L580.171 416.055L579.619 416.961L579.136 416.421Z'
        fill='url(#paint269_linear_3150_15389)'
      />
      <path
        d='M524.16 174.803V173.541L524.999 173.563V174.836L524.16 174.803Z'
        fill='url(#paint270_linear_3150_15389)'
      />
      <path
        d='M582.206 223.806L582.333 222.555L583.298 222.76L583.172 224.011L582.206 223.806Z'
        fill='url(#paint271_linear_3150_15389)'
      />
      <path
        d='M489.456 159.422L489.445 158.16L490.169 158.127L490.181 159.389L489.456 159.422Z'
        fill='url(#paint272_linear_3150_15389)'
      />
      <path
        d='M487.03 479.231L487.363 478.465L487.628 478.94L487.26 479.663L487.03 479.231Z'
        fill='url(#paint273_linear_3150_15389)'
      />
      <path
        d='M346.824 464.411L346.606 463.591L346.755 464.098L346.951 464.864L346.824 464.411Z'
        fill='url(#paint274_linear_3150_15389)'
      />
      <path
        d='M601.26 382.476L601.743 381.462L602.398 382.034L601.881 383.026L601.26 382.476Z'
        fill='url(#paint275_linear_3150_15389)'
      />
      <path
        d='M603.444 258.356L603.651 257.137L604.629 257.46L604.399 258.679L603.444 258.356Z'
        fill='url(#paint276_linear_3150_15389)'
      />
      <path
        d='M379.446 479.393L379.343 478.616L379.515 479.091L379.584 479.814L379.446 479.393Z'
        fill='url(#paint277_linear_3150_15389)'
      />
      <path
        d='M555.724 449.396L556.242 448.533L556.632 449.094L556.092 449.903L555.724 449.396Z'
        fill='url(#paint278_linear_3150_15389)'
      />
      <path
        d='M502.116 479.771L502.507 479.005L502.772 479.49L502.346 480.202L502.116 479.771Z'
        fill='url(#paint279_linear_3150_15389)'
      />
      <path
        d='M505.106 159.152L505.083 157.879L505.865 157.836L505.888 159.109L505.106 159.152Z'
        fill='url(#paint280_linear_3150_15389)'
      />
      <path
        d='M598.373 400.026L598.891 399.033L599.5 399.627L598.937 400.576L598.373 400.026Z'
        fill='url(#paint281_linear_3150_15389)'
      />
      <path
        d='M559.438 190.433L559.484 189.16L560.427 189.246L560.393 190.519L559.438 190.433Z'
        fill='url(#paint282_linear_3150_15389)'
      />
      <path
        d='M601.168 240.86L601.34 239.619L602.341 239.889L602.157 241.13L601.168 240.86Z'
        fill='url(#paint283_linear_3150_15389)'
      />
      <path
        d='M536.21 465.036L536.705 464.216L537.038 464.745L536.521 465.511L536.21 465.036Z'
        fill='url(#paint284_linear_3150_15389)'
      />
      <path
        d='M575.33 433.529L575.87 432.623L576.342 433.194L575.767 434.057L575.33 433.529Z'
        fill='url(#paint285_linear_3150_15389)'
      />
      <path
        d='M364.153 479.986L363.992 479.199L364.141 479.673L364.279 480.396L364.153 479.986Z'
        fill='url(#paint286_linear_3150_15389)'
      />
      <path
        d='M539.913 174.436V173.164L540.81 173.185V174.458L539.913 174.436Z'
        fill='url(#paint287_linear_3150_15389)'
      />
      <path
        d='M439.781 494.343L439.93 493.61L440.126 494.052L439.942 494.721L439.781 494.343Z'
        fill='url(#paint288_linear_3150_15389)'
      />
      <path
        d='M424.959 494.375L425.051 493.642L425.235 494.084L425.12 494.753L424.959 494.375Z'
        fill='url(#paint289_linear_3150_15389)'
      />
      <path
        d='M622.429 329.784L622.832 328.651L623.682 329.169L623.245 330.28L622.429 329.784Z'
        fill='url(#paint290_linear_3150_15389)'
      />
      <path
        d='M622.763 311.835L623.13 310.681L624.027 311.166L623.636 312.31L622.763 311.835Z'
        fill='url(#paint291_linear_3150_15389)'
      />
      <path
        d='M455.971 144.18L455.96 142.907L456.558 142.81L456.569 144.083L455.971 144.18Z'
        fill='url(#paint292_linear_3150_15389)'
      />
      <path
        d='M454.557 494.462L454.775 493.728L454.982 494.171L454.729 494.839L454.557 494.462Z'
        fill='url(#paint293_linear_3150_15389)'
      />
      <path
        d='M621.383 347.7L621.831 346.611L622.625 347.161L622.153 348.229L621.383 347.7Z'
        fill='url(#paint294_linear_3150_15389)'
      />
      <path
        d='M410.125 494.548L410.16 493.825L410.332 494.257L410.275 494.926L410.125 494.548Z'
        fill='url(#paint295_linear_3150_15389)'
      />
      <path
        d='M622.406 293.919L622.728 292.743L623.671 293.174L623.326 294.35L622.406 293.919Z'
        fill='url(#paint296_linear_3150_15389)'
      />
      <path
        d='M471.311 144.04L471.288 142.756L471.943 142.67L471.978 143.943L471.311 144.04Z'
        fill='url(#paint297_linear_3150_15389)'
      />
      <path
        d='M469.264 494.721L469.552 493.987L469.759 494.44L469.448 495.109L469.264 494.721Z'
        fill='url(#paint298_linear_3150_15389)'
      />
      <path
        d='M517.019 480.461L517.467 479.684L517.755 480.18L517.272 480.892L517.019 480.461Z'
        fill='url(#paint299_linear_3150_15389)'
      />
      <path
        d='M619.635 365.574L620.118 364.517L620.865 365.088L620.348 366.124L619.635 365.574Z'
        fill='url(#paint300_linear_3150_15389)'
      />
      <path
        d='M594.843 417.403L595.395 416.464L595.947 417.058L595.349 417.953L594.843 417.403Z'
        fill='url(#paint301_linear_3150_15389)'
      />
      <path
        d='M520.584 158.807L520.549 157.523L521.388 157.491L521.423 158.764L520.584 158.807Z'
        fill='url(#paint302_linear_3150_15389)'
      />
      <path
        d='M621.348 276.067L621.624 274.859L622.613 275.247L622.314 276.445L621.348 276.067Z'
        fill='url(#paint303_linear_3150_15389)'
      />
      <path
        d='M395.326 494.872L395.28 494.138L395.441 494.58L395.464 495.249L395.326 494.872Z'
        fill='url(#paint304_linear_3150_15389)'
      />
      <path
        d='M578.699 204.401L578.722 203.139L579.78 203.247L579.688 204.509L578.699 204.401Z'
        fill='url(#paint305_linear_3150_15389)'
      />
      <path
        d='M483.868 495.12L484.213 494.397L484.443 494.85L484.063 495.508L483.868 495.12Z'
        fill='url(#paint306_linear_3150_15389)'
      />
      <path
        d='M486.547 143.814L486.512 142.53L487.225 142.433L487.271 143.716L486.547 143.814Z'
        fill='url(#paint307_linear_3150_15389)'
      />
      <path
        d='M617.186 383.361L617.714 382.336L618.404 382.929L617.841 383.921L617.186 383.361Z'
        fill='url(#paint308_linear_3150_15389)'
      />
      <path
        d='M551.228 465.964L551.768 465.155L552.125 465.683L551.55 466.449L551.228 465.964Z'
        fill='url(#paint309_linear_3150_15389)'
      />
      <path
        d='M570.949 450.41L571.512 449.547L571.937 450.108L571.328 450.927L570.949 450.41Z'
        fill='url(#paint310_linear_3150_15389)'
      />
      <path
        d='M406.871 155.506L407.044 154.244L407.205 154.115L407.285 155.409L406.871 155.506Z'
        fill='url(#paint311_linear_3150_15389)'
      />
      <path
        d='M380.562 495.346L380.458 494.613L380.608 495.044L380.7 495.724L380.562 495.346Z'
        fill='url(#paint312_linear_3150_15389)'
      />
      <path
        d='M531.737 481.281L532.243 480.515L532.531 481.011L531.99 481.723L531.737 481.281Z'
        fill='url(#paint313_linear_3150_15389)'
      />
      <path
        d='M590.669 434.597L591.267 433.691L591.75 434.273L591.129 435.136L590.669 434.597Z'
        fill='url(#paint314_linear_3150_15389)'
      />
      <path
        d='M498.345 495.681L498.759 494.947L498.989 495.4L498.552 496.069L498.345 495.681Z'
        fill='url(#paint315_linear_3150_15389)'
      />
      <path
        d='M501.645 143.512L501.599 142.228L502.369 142.131L502.427 143.404L501.645 143.512Z'
        fill='url(#paint316_linear_3150_15389)'
      />
      <path
        d='M614.046 401.018L614.61 400.026L615.242 400.63L614.633 401.579L614.046 401.018Z'
        fill='url(#paint317_linear_3150_15389)'
      />
      <path
        d='M365.889 495.961L365.717 495.228L365.855 495.659L366.004 496.339L365.889 495.961Z'
        fill='url(#paint318_linear_3150_15389)'
      />
      <path
        d='M438.298 509.919L438.459 509.239L438.631 509.638L438.436 510.253L438.298 509.919Z'
        fill='url(#paint319_linear_3150_15389)'
      />
      <path
        d='M424.154 509.962L424.246 509.282L424.407 509.671L424.28 510.296L424.154 509.962Z'
        fill='url(#paint320_linear_3150_15389)'
      />
      <path
        d='M638.401 330.334L638.838 329.202L639.723 329.719L639.252 330.841L638.401 330.334Z'
        fill='url(#paint321_linear_3150_15389)'
      />
      <path
        d='M638.769 312.245L639.16 311.08L640.103 311.565L639.689 312.709L638.769 312.245Z'
        fill='url(#paint322_linear_3150_15389)'
      />
      <path
        d='M452.407 510.027L452.637 509.347L452.809 509.746L452.556 510.361L452.407 510.027Z'
        fill='url(#paint323_linear_3150_15389)'
      />
      <path
        d='M512.661 496.371L513.132 495.648L513.374 496.101L512.868 496.77L512.661 496.371Z'
        fill='url(#paint324_linear_3150_15389)'
      />
      <path
        d='M637.263 348.401L637.746 347.301L638.585 347.862L638.068 348.941L637.263 348.401Z'
        fill='url(#paint325_linear_3150_15389)'
      />
      <path
        d='M610.206 418.503L610.804 417.554L611.379 418.158L610.735 419.064L610.206 418.503Z'
        fill='url(#paint326_linear_3150_15389)'
      />
      <path
        d='M565.97 467.032L566.579 466.223L566.935 466.762L566.303 467.517L565.97 467.032Z'
        fill='url(#paint327_linear_3150_15389)'
      />
      <path
        d='M409.999 510.145L410.022 509.466L410.171 509.865L410.125 510.48L409.999 510.145Z'
        fill='url(#paint328_linear_3150_15389)'
      />
      <path
        d='M638.39 294.178L638.734 292.98L639.723 293.423L639.344 294.609L638.39 294.178Z'
        fill='url(#paint329_linear_3150_15389)'
      />
      <path
        d='M466.458 510.286L466.746 509.606L466.93 510.005L466.608 510.62L466.458 510.286Z'
        fill='url(#paint330_linear_3150_15389)'
      />
      <path
        d='M546.215 482.251L546.778 481.486L547.077 481.982L546.479 482.694L546.215 482.251Z'
        fill='url(#paint331_linear_3150_15389)'
      />
      <path
        d='M585.863 451.553L586.484 450.69L586.921 451.262L586.265 452.071L585.863 451.553Z'
        fill='url(#paint332_linear_3150_15389)'
      />
      <path
        d='M635.377 366.415L635.906 365.347L636.676 365.93L636.113 366.976L635.377 366.415Z'
        fill='url(#paint333_linear_3150_15389)'
      />
      <path
        d='M395.867 510.49L395.821 509.8L395.97 510.199L395.982 510.814L395.867 510.49Z'
        fill='url(#paint334_linear_3150_15389)'
      />
      <path
        d='M604.226 187.941L604.353 186.517L605.043 187.251L604.893 188.588L604.226 187.941Z'
        fill='url(#paint335_linear_3150_15389)'
      />
      <path
        d='M534.624 156.056L534.497 154.773L535.498 154.654L535.521 155.938L534.624 156.056Z'
        fill='url(#paint336_linear_3150_15389)'
      />
      <path
        d='M480.406 510.685L480.763 510.016L480.947 510.415L480.556 511.03L480.406 510.685Z'
        fill='url(#paint337_linear_3150_15389)'
      />
      <path
        d='M632.732 384.331L633.295 383.307L634.008 383.9L633.41 384.903L632.732 384.331Z'
        fill='url(#paint338_linear_3150_15389)'
      />
      <path
        d='M436.538 140.394L436.699 139.143L436.975 138.938L437.079 140.232L436.538 140.394Z'
        fill='url(#paint339_linear_3150_15389)'
      />
      <path
        d='M637.608 274.622L637.884 273.403L638.953 273.78L638.619 274.988L637.608 274.622Z'
        fill='url(#paint340_linear_3150_15389)'
      />
      <path
        d='M526.77 497.212L527.31 496.5L527.552 496.953L526.988 497.611L526.77 497.212Z'
        fill='url(#paint341_linear_3150_15389)'
      />
      <path
        d='M381.792 510.976L381.677 510.286L381.815 510.685L381.896 511.299L381.792 510.976Z'
        fill='url(#paint342_linear_3150_15389)'
      />
      <path
        d='M605.664 435.794L606.308 434.888L606.814 435.481L606.135 436.333L605.664 435.794Z'
        fill='url(#paint343_linear_3150_15389)'
      />
      <path
        d='M494.217 511.246L494.642 510.566L494.838 510.976L494.378 511.58L494.217 511.246Z'
        fill='url(#paint344_linear_3150_15389)'
      />
      <path
        d='M629.328 402.118L629.938 401.126L630.593 401.73L629.938 402.69L629.328 402.118Z'
        fill='url(#paint345_linear_3150_15389)'
      />
      <path
        d='M560.427 483.362L561.048 482.586L561.358 483.093L560.703 483.805L560.427 483.362Z'
        fill='url(#paint346_linear_3150_15389)'
      />
      <path
        d='M580.412 468.24L581.068 467.42L581.447 467.97L580.757 468.725L580.412 468.24Z'
        fill='url(#paint347_linear_3150_15389)'
      />
      <path
        d='M367.798 511.612L367.614 510.922L367.741 511.321L367.902 511.936L367.798 511.612Z'
        fill='url(#paint348_linear_3150_15389)'
      />
      <path
        d='M507.877 511.947L508.372 511.267L508.567 511.677L508.05 512.281L507.877 511.947Z'
        fill='url(#paint349_linear_3150_15389)'
      />
      <path
        d='M540.661 498.205L541.258 497.482L541.512 497.946L540.879 498.604L540.661 498.205Z'
        fill='url(#paint350_linear_3150_15389)'
      />
      <path
        d='M600.432 452.826L601.11 451.963L601.559 452.545L600.846 453.354L600.432 452.826Z'
        fill='url(#paint351_linear_3150_15389)'
      />
      <path
        d='M625.177 419.722L625.833 418.773L626.419 419.377L625.729 420.283L625.177 419.722Z'
        fill='url(#paint352_linear_3150_15389)'
      />
      <path
        d='M614.184 181.458L614.587 180.11L615.288 180.736L615.047 181.836L614.184 181.458Z'
        fill='url(#paint353_linear_3150_15389)'
      />
      <path
        d='M436.688 525.139L436.86 524.513L436.998 524.858L436.803 525.419L436.688 525.139Z'
        fill='url(#paint354_linear_3150_15389)'
      />
      <path
        d='M423.269 525.193L423.372 524.556L423.499 524.901L423.384 525.473L423.269 525.193Z'
        fill='url(#paint355_linear_3150_15389)'
      />
      <path
        d='M653.936 330.938L654.407 329.795L655.327 330.323L654.833 331.445L653.936 330.938Z'
        fill='url(#paint356_linear_3150_15389)'
      />
      <path
        d='M600.455 157.642L600.524 156.218L601.271 156.865L601.179 158.235L600.455 157.642Z'
        fill='url(#paint357_linear_3150_15389)'
      />
      <path
        d='M654.338 312.687L654.752 311.512L655.741 311.997L655.293 313.162L654.338 312.687Z'
        fill='url(#paint358_linear_3150_15389)'
      />
      <path
        d='M450.072 525.236L450.314 524.61L450.452 524.955L450.187 525.516L450.072 525.236Z'
        fill='url(#paint359_linear_3150_15389)'
      />
      <path
        d='M590.485 154.934L590.635 153.543L591.325 154.136L591.256 155.355L590.485 154.934Z'
        fill='url(#paint360_linear_3150_15389)'
      />
      <path
        d='M652.717 349.167L653.235 348.056L654.097 348.617L653.545 349.707L652.717 349.167Z'
        fill='url(#paint361_linear_3150_15389)'
      />
      <path
        d='M409.849 525.397L409.884 524.761L410.01 525.106L409.953 525.667L409.849 525.397Z'
        fill='url(#paint362_linear_3150_15389)'
      />
      <path
        d='M653.924 294.447L654.292 293.25L655.327 293.692L654.925 294.89L653.924 294.447Z'
        fill='url(#paint363_linear_3150_15389)'
      />
      <path
        d='M463.4 525.484L463.699 524.858L463.848 525.203L463.515 525.764L463.4 525.484Z'
        fill='url(#paint364_linear_3150_15389)'
      />
      <path
        d='M611.988 197.153L612.08 195.826L612.793 196.506L612.678 197.768L611.988 197.153Z'
        fill='url(#paint365_linear_3150_15389)'
      />
      <path
        d='M650.682 367.332L651.245 366.253L652.05 366.846L651.452 367.893L650.682 367.332Z'
        fill='url(#paint366_linear_3150_15389)'
      />
      <path
        d='M604.859 192.763L605.054 191.49L605.721 192.116L605.606 193.216L604.859 192.763Z'
        fill='url(#paint367_linear_3150_15389)'
      />
      <path
        d='M396.453 525.743L396.407 525.106L396.534 525.451L396.557 526.023L396.453 525.743Z'
        fill='url(#paint368_linear_3150_15389)'
      />
      <path
        d='M521.342 512.788L521.906 512.119L522.101 512.529L521.515 513.133L521.342 512.788Z'
        fill='url(#paint369_linear_3150_15389)'
      />
      <path
        d='M626.201 181.566L626.316 180.272L627.167 180.833L626.902 182.289L626.201 181.566Z'
        fill='url(#paint370_linear_3150_15389)'
      />
      <path
        d='M476.623 525.883L477.003 525.257L477.152 525.602L476.75 526.163L476.623 525.883Z'
        fill='url(#paint371_linear_3150_15389)'
      />
      <path
        d='M620.279 437.11L620.969 436.193L621.498 436.797L620.762 437.66L620.279 437.11Z'
        fill='url(#paint372_linear_3150_15389)'
      />
      <path
        d='M647.83 385.399L648.451 384.364L649.187 384.968L648.543 385.971L647.83 385.399Z'
        fill='url(#paint373_linear_3150_15389)'
      />
      <path
        d='M574.329 484.603L575.019 483.837L575.341 484.355L574.617 485.056L574.329 484.603Z'
        fill='url(#paint374_linear_3150_15389)'
      />
      <path
        d='M617.692 244.775L617.714 243.449L619.037 243.675L618.738 244.937L617.692 244.775Z'
        fill='url(#paint375_linear_3150_15389)'
      />
      <path
        d='M554.287 499.337L554.942 498.625L555.207 499.089L554.517 499.736L554.287 499.337Z'
        fill='url(#paint376_linear_3150_15389)'
      />
      <path
        d='M383.114 526.25L382.999 525.613L383.114 525.958L383.206 526.519L383.114 526.25Z'
        fill='url(#paint377_linear_3150_15389)'
      />
      <path
        d='M594.51 469.577L595.223 468.758L595.614 469.318L594.866 470.073L594.51 469.577Z'
        fill='url(#paint378_linear_3150_15389)'
      />
      <path
        d='M489.732 526.422L490.169 525.807L490.33 526.153L489.859 526.713L489.732 526.422Z'
        fill='url(#paint379_linear_3150_15389)'
      />
      <path
        d='M644.185 403.316L644.84 402.312L645.507 402.927L644.817 403.898L644.185 403.316Z'
        fill='url(#paint380_linear_3150_15389)'
      />
      <path
        d='M534.589 513.791L535.21 513.122L535.417 513.532L534.762 514.136L534.589 513.791Z'
        fill='url(#paint381_linear_3150_15389)'
      />
      <path
        d='M369.868 526.897L369.684 526.26L369.787 526.606L369.96 527.166L369.868 526.897Z'
        fill='url(#paint382_linear_3150_15389)'
      />
      <path
        d='M614.621 454.228L615.357 453.365L615.817 453.948L615.047 454.757L614.621 454.228Z'
        fill='url(#paint383_linear_3150_15389)'
      />
      <path
        d='M502.68 527.123L503.186 526.498L503.347 526.854L502.806 527.404L502.68 527.123Z'
        fill='url(#paint384_linear_3150_15389)'
      />
      <path
        d='M639.723 421.049L640.413 420.089L641.023 420.703L640.287 421.62L639.723 421.049Z'
        fill='url(#paint385_linear_3150_15389)'
      />
      <path
        d='M543.926 164.427L543.363 163.089L544.789 162.83L544.858 164.049L543.926 164.427Z'
        fill='url(#paint386_linear_3150_15389)'
      />
      <path
        d='M507.981 137.881L507.452 136.587L508.682 136.22L508.797 137.439L507.981 137.881Z'
        fill='url(#paint387_linear_3150_15389)'
      />
      <path
        d='M635.572 157.534L635.791 156.099L636.676 156.671L636.435 158.073L635.572 157.534Z'
        fill='url(#paint388_linear_3150_15389)'
      />
      <path
        d='M567.614 500.61L568.338 499.898L568.603 500.373L567.855 501.02L567.614 500.61Z'
        fill='url(#paint389_linear_3150_15389)'
      />
      <path
        d='M587.909 485.994L588.657 485.228L588.979 485.746L588.197 486.447L587.909 485.994Z'
        fill='url(#paint390_linear_3150_15389)'
      />
      <path
        d='M434.951 539.959L435.124 539.388L435.227 539.668L435.032 540.175L434.951 539.959Z'
        fill='url(#paint391_linear_3150_15389)'
      />
      <path
        d='M422.314 540.024L422.418 539.442L422.521 539.733L422.395 540.24L422.314 540.024Z'
        fill='url(#paint392_linear_3150_15389)'
      />
      <path
        d='M668.988 331.585L669.494 330.431L670.448 330.97L669.919 332.114L668.988 331.585Z'
        fill='url(#paint393_linear_3150_15389)'
      />
      <path
        d='M447.543 540.046L447.796 539.463L447.911 539.754L447.635 540.261L447.543 540.046Z'
        fill='url(#paint394_linear_3150_15389)'
      />
      <path
        d='M649.992 268.042L650.233 266.78L651.418 267.103L651.073 268.355L649.992 268.042Z'
        fill='url(#paint395_linear_3150_15389)'
      />
      <path
        d='M669.425 313.162L669.873 311.975L670.897 312.472L670.414 313.647L669.425 313.162Z'
        fill='url(#paint396_linear_3150_15389)'
      />
      <path
        d='M667.689 349.998L668.241 348.876L669.137 349.448L668.551 350.537L667.689 349.998Z'
        fill='url(#paint397_linear_3150_15389)'
      />
      <path
        d='M409.688 540.24L409.723 539.657L409.826 539.949L409.769 540.455L409.688 540.24Z'
        fill='url(#paint398_linear_3150_15389)'
      />
      <path
        d='M668.988 294.749L669.379 293.541L670.448 293.994L670.034 295.192L668.988 294.749Z'
        fill='url(#paint399_linear_3150_15389)'
      />
      <path
        d='M515.444 527.965L516.018 527.35L516.179 527.706L515.57 528.256L515.444 527.965Z'
        fill='url(#paint400_linear_3150_15389)'
      />
      <path
        d='M460.088 540.272L460.41 539.7L460.525 539.992L460.18 540.499L460.088 540.272Z'
        fill='url(#paint401_linear_3150_15389)'
      />
      <path
        d='M547.571 514.935L548.261 514.266L548.48 514.686L547.755 515.28L547.571 514.935Z'
        fill='url(#paint402_linear_3150_15389)'
      />
      <path
        d='M634.457 438.544L635.193 437.628L635.733 438.242L634.951 439.105L634.457 438.544Z'
        fill='url(#paint403_linear_3150_15389)'
      />
      <path
        d='M665.504 368.324L666.113 367.246L666.941 367.839L666.309 368.896L665.504 368.324Z'
        fill='url(#paint404_linear_3150_15389)'
      />
      <path
        d='M608.239 471.044L608.998 470.235L609.401 470.796L608.596 471.551L608.239 471.044Z'
        fill='url(#paint405_linear_3150_15389)'
      />
      <path
        d='M585.633 206.116L585.173 204.682L586.76 204.66L586.668 205.912L585.633 206.116Z'
        fill='url(#paint406_linear_3150_15389)'
      />
      <path
        d='M397.097 540.607L397.051 540.024L397.143 540.304L397.166 540.822L397.097 540.607Z'
        fill='url(#paint407_linear_3150_15389)'
      />
      <path
        d='M570.799 139.024L570.972 137.73L571.604 138.118L571.662 139.197L570.799 139.024Z'
        fill='url(#paint408_linear_3150_15389)'
      />
      <path
        d='M472.541 540.66L472.932 540.089L473.047 540.38L472.633 540.876L472.541 540.66Z'
        fill='url(#paint409_linear_3150_15389)'
      />
      <path
        d='M662.468 386.553L663.112 385.507L663.883 386.122L663.193 387.136L662.468 386.553Z'
        fill='url(#paint410_linear_3150_15389)'
      />
      <path
        d='M592.371 125.369L592.405 123.966L593.176 124.484L593.141 125.832L592.371 125.369Z'
        fill='url(#paint411_linear_3150_15389)'
      />
      <path
        d='M612.644 195.352L612.827 194.143L613.529 194.704L613.46 195.708L612.644 195.352Z'
        fill='url(#paint412_linear_3150_15389)'
      />
      <path
        d='M384.552 541.113L384.437 540.531L384.529 540.822L384.621 541.329L384.552 541.113Z'
        fill='url(#paint413_linear_3150_15389)'
      />
      <path
        d='M484.88 541.189L485.339 540.617L485.454 540.909L484.972 541.405L484.88 541.189Z'
        fill='url(#paint414_linear_3150_15389)'
      />
      <path
        d='M527.989 528.957L528.633 528.342L528.805 528.709L528.127 529.248L527.989 528.957Z'
        fill='url(#paint415_linear_3150_15389)'
      />
      <path
        d='M658.559 404.61L659.248 403.607L659.95 404.233L659.214 405.203L658.559 404.61Z'
        fill='url(#paint416_linear_3150_15389)'
      />
      <path
        d='M628.385 455.76L629.167 454.897L629.65 455.49L628.822 456.299L628.385 455.76Z'
        fill='url(#paint417_linear_3150_15389)'
      />
      <path
        d='M580.619 502.034L581.39 501.322L581.666 501.807L580.849 502.444L580.619 502.034Z'
        fill='url(#paint418_linear_3150_15389)'
      />
      <path
        d='M570.029 122.521L570.086 121.151L570.73 121.658L570.753 122.866L570.029 122.521Z'
        fill='url(#paint419_linear_3150_15389)'
      />
      <path
        d='M623.36 128.076L623.498 126.544L624.372 127.105L624.292 128.475L623.36 128.076Z'
        fill='url(#paint420_linear_3150_15389)'
      />
      <path
        d='M560.278 516.218L561.025 515.56L561.244 515.981L560.462 516.574L560.278 516.218Z'
        fill='url(#paint421_linear_3150_15389)'
      />
      <path
        d='M601.11 487.515L601.904 486.76L602.249 487.289L601.409 487.99L601.11 487.515Z'
        fill='url(#paint422_linear_3150_15389)'
      />
      <path
        d='M497.068 541.858L497.597 541.297L497.712 541.599L497.16 542.084L497.068 541.858Z'
        fill='url(#paint423_linear_3150_15389)'
      />
      <path
        d='M537.809 150.199L536.693 149.121L538.545 148.43L539.131 149.563L537.809 150.199Z'
        fill='url(#paint424_linear_3150_15389)'
      />
      <path
        d='M603.03 155.549L603.387 154.309L604.226 154.622L604.157 155.657L603.03 155.549Z'
        fill='url(#paint425_linear_3150_15389)'
      />
      <path
        d='M653.786 422.483L654.534 421.523L655.155 422.149L654.373 423.066L653.786 422.483Z'
        fill='url(#paint426_linear_3150_15389)'
      />
      <path
        d='M540.293 530.09L540.994 529.486L541.166 529.852L540.431 530.381L540.293 530.09Z'
        fill='url(#paint427_linear_3150_15389)'
      />
      <path
        d='M621.532 472.662L622.349 471.842L622.763 472.414L621.9 473.169L621.532 472.662Z'
        fill='url(#paint428_linear_3150_15389)'
      />
      <path
        d='M654.407 263.867L654.626 262.562L655.776 262.8L655.557 264.105L654.407 263.867Z'
        fill='url(#paint429_linear_3150_15389)'
      />
      <path
        d='M509.073 542.688L509.671 542.127L509.798 542.429L509.165 542.915L509.073 542.688Z'
        fill='url(#paint430_linear_3150_15389)'
      />
      <path
        d='M549.342 175.58L548.169 174.221L550.078 173.854L550.32 174.976L549.342 175.58Z'
        fill='url(#paint431_linear_3150_15389)'
      />
      <path
        d='M433.077 554.338L433.25 553.82L433.33 554.036L433.123 554.489L433.077 554.338Z'
        fill='url(#paint432_linear_3150_15389)'
      />
      <path
        d='M648.164 440.098L648.945 439.181L649.509 439.796L648.681 440.669L648.164 440.098Z'
        fill='url(#paint433_linear_3150_15389)'
      />
      <path
        d='M444.829 554.402L445.082 553.885L445.162 554.111L444.886 554.553L444.829 554.402Z'
        fill='url(#paint434_linear_3150_15389)'
      />
      <path
        d='M421.291 554.413L421.394 553.896L421.475 554.122L421.348 554.564L421.291 554.413Z'
        fill='url(#paint435_linear_3150_15389)'
      />
      <path
        d='M683.523 332.297L684.051 331.132L685.052 331.682L684.477 332.826L683.523 332.297Z'
        fill='url(#paint436_linear_3150_15389)'
      />
      <path
        d='M683.983 313.68L684.454 312.482L685.512 312.989L685.006 314.165L683.983 313.68Z'
        fill='url(#paint437_linear_3150_15389)'
      />
      <path
        d='M682.131 350.882L682.718 349.761L683.649 350.343L683.017 351.443L682.131 350.882Z'
        fill='url(#paint438_linear_3150_15389)'
      />
      <path
        d='M456.535 554.618L456.857 554.1L456.949 554.327L456.592 554.769L456.535 554.618Z'
        fill='url(#paint439_linear_3150_15389)'
      />
      <path
        d='M409.516 554.64L409.55 554.122L409.619 554.349L409.562 554.791L409.516 554.64Z'
        fill='url(#paint440_linear_3150_15389)'
      />
      <path
        d='M683.523 295.073L683.937 293.843L685.052 294.307L684.603 295.515L683.523 295.073Z'
        fill='url(#paint441_linear_3150_15389)'
      />
      <path
        d='M659.421 135.583L659.754 133.955L660.72 134.559L660.467 136.004L659.421 135.583Z'
        fill='url(#paint442_linear_3150_15389)'
      />
      <path
        d='M679.808 369.403L680.441 368.303L681.315 368.917L680.636 369.975L679.808 369.403Z'
        fill='url(#paint443_linear_3150_15389)'
      />
      <path
        d='M572.65 517.653L573.455 516.995L573.674 517.426L572.834 518.02L572.65 517.653Z'
        fill='url(#paint444_linear_3150_15389)'
      />
      <path
        d='M593.245 503.598L594.084 502.886L594.36 503.371L593.486 504.019L593.245 503.598Z'
        fill='url(#paint445_linear_3150_15389)'
      />
      <path
        d='M468.149 554.974L468.551 554.456L468.643 554.683L468.206 555.125L468.149 554.974Z'
        fill='url(#paint446_linear_3150_15389)'
      />
      <path
        d='M397.776 555.017L397.73 554.489L397.799 554.715L397.822 555.168L397.776 555.017Z'
        fill='url(#paint447_linear_3150_15389)'
      />
      <path
        d='M623.935 175.073L624.349 173.919L625.246 174.275L625.005 175.289L623.935 175.073Z'
        fill='url(#paint448_linear_3150_15389)'
      />
      <path
        d='M676.566 387.794L677.256 386.737L678.049 387.362L677.325 388.387L676.566 387.794Z'
        fill='url(#paint449_linear_3150_15389)'
      />
      <path
        d='M520.871 543.659L521.538 543.098L521.664 543.4L520.963 543.886L520.871 543.659Z'
        fill='url(#paint450_linear_3150_15389)'
      />
      <path
        d='M669.632 158.203L670.069 156.725L671.081 157.264L670.644 158.667L669.632 158.203Z'
        fill='url(#paint451_linear_3150_15389)'
      />
      <path
        d='M641.69 457.421L642.518 456.547L643.012 457.151L642.127 457.96L641.69 457.421Z'
        fill='url(#paint452_linear_3150_15389)'
      />
      <path
        d='M552.309 531.373L553.079 530.769L553.252 531.147L552.447 531.675L552.309 531.373Z'
        fill='url(#paint453_linear_3150_15389)'
      />
      <path
        d='M546.019 162.442L544.846 161.46L546.709 160.662L547.801 161.698L546.019 162.442Z'
        fill='url(#paint454_linear_3150_15389)'
      />
      <path
        d='M613.885 489.187L614.748 488.432L615.093 488.961L614.196 489.662L613.885 489.187Z'
        fill='url(#paint455_linear_3150_15389)'
      />
      <path
        d='M479.659 555.47L480.142 554.963L480.223 555.201L479.717 555.632L479.659 555.47Z'
        fill='url(#paint456_linear_3150_15389)'
      />
      <path
        d='M386.093 555.535L385.966 555.006L386.047 555.233L386.139 555.675L386.093 555.535Z'
        fill='url(#paint457_linear_3150_15389)'
      />
      <path
        d='M585.46 189.505L584.563 188.222L586.748 187.952L587.035 189.203L585.46 189.505Z'
        fill='url(#paint458_linear_3150_15389)'
      />
      <path
        d='M672.403 406.012L673.151 405.009L673.875 405.646L673.082 406.616L672.403 406.012Z'
        fill='url(#paint459_linear_3150_15389)'
      />
      <path
        d='M548.779 116.006L548.859 114.755L549.411 115.089L549.434 116.243L548.779 116.006Z'
        fill='url(#paint460_linear_3150_15389)'
      />
      <path
        d='M491.031 556.117L491.583 555.621L491.664 555.848L491.089 556.279L491.031 556.117Z'
        fill='url(#paint461_linear_3150_15389)'
      />
      <path
        d='M672.932 176.572L673.45 175.235L674.427 175.763L673.921 177.036L672.932 176.572Z'
        fill='url(#paint462_linear_3150_15389)'
      />
      <path
        d='M603.364 176.917L603.548 175.839L604.341 176.13L604.284 177.112L603.364 176.917Z'
        fill='url(#paint463_linear_3150_15389)'
      />
      <path
        d='M677.095 200.486L677.704 199.267L678.647 199.828L678.003 201.025L677.095 200.486Z'
        fill='url(#paint464_linear_3150_15389)'
      />
      <path
        d='M667.332 424.026L668.126 423.055L668.77 423.691L667.93 424.619L667.332 424.026Z'
        fill='url(#paint465_linear_3150_15389)'
      />
      <path
        d='M532.427 544.77L533.163 544.22L533.29 544.533L532.531 545.007L532.427 544.77Z'
        fill='url(#paint466_linear_3150_15389)'
      />
      <path
        d='M634.365 474.399L635.239 473.59L635.664 474.161L634.744 474.917L634.365 474.399Z'
        fill='url(#paint467_linear_3150_15389)'
      />
      <path
        d='M669.425 265.507L669.666 264.202L670.851 264.472L670.598 265.766L669.425 265.507Z'
        fill='url(#paint468_linear_3150_15389)'
      />
      <path
        d='M605.871 217.388L605.192 215.911L607.251 215.997L607.228 217.248L605.871 217.388Z'
        fill='url(#paint469_linear_3150_15389)'
      />
      <path
        d='M580.113 98.618L580.182 97.1834L580.872 97.6688L580.895 98.9632L580.113 98.618Z'
        fill='url(#paint470_linear_3150_15389)'
      />
      <path
        d='M584.655 519.228L585.529 518.58L585.748 519.023L584.851 519.594L584.655 519.228Z'
        fill='url(#paint471_linear_3150_15389)'
      />
      <path
        d='M502.231 556.916L502.852 556.419L502.944 556.646L502.289 557.067L502.231 556.916Z'
        fill='url(#paint472_linear_3150_15389)'
      />
      <path
        d='M490.146 143.512L488.674 142.961L489.744 141.807L491.629 142.26L490.146 143.512Z'
        fill='url(#paint473_linear_3150_15389)'
      />
      <path
        d='M564.003 532.808L564.843 532.204L565.015 532.581L564.141 533.11L564.003 532.808Z'
        fill='url(#paint474_linear_3150_15389)'
      />
      <path
        d='M317.341 128.583L317.203 127.429L317.341 127.461V128.799V128.583Z'
        fill='url(#paint475_linear_3150_15389)'
      />
      <path
        d='M605.468 505.302L606.365 504.601L606.641 505.086L605.71 505.723L605.468 505.302Z'
        fill='url(#paint476_linear_3150_15389)'
      />
      <path
        d='M533.531 150.404L532.347 149.584L533.957 148.614L535.314 149.455L533.531 150.404Z'
        fill='url(#paint477_linear_3150_15389)'
      />
      <path
        d='M661.353 441.77L662.192 440.853L662.767 441.478L661.882 442.352L661.353 441.77Z'
        fill='url(#paint478_linear_3150_15389)'
      />
      <path
        d='M664.894 204.499L665.331 203.409L666.274 203.884L665.768 204.984L664.894 204.499Z'
        fill='url(#paint479_linear_3150_15389)'
      />
      <path
        d='M608.423 97.4207V95.9861L609.378 96.3205L609.32 97.7551L608.423 97.4207Z'
        fill='url(#paint480_linear_3150_15389)'
      />
      <path
        d='M431.065 568.22L431.249 567.756L431.306 567.918L431.088 568.295L431.065 568.22Z'
        fill='url(#paint481_linear_3150_15389)'
      />
      <path
        d='M441.92 568.263L442.184 567.81L442.23 567.961L441.943 568.339L441.92 568.263Z'
        fill='url(#paint482_linear_3150_15389)'
      />
      <path
        d='M420.187 568.317L420.302 567.853L420.348 568.004L420.221 568.393L420.187 568.317Z'
        fill='url(#paint483_linear_3150_15389)'
      />
      <path
        d='M697.471 333.052L698.034 331.877L699.058 332.437L698.46 333.592L697.471 333.052Z'
        fill='url(#paint484_linear_3150_15389)'
      />
      <path
        d='M543.719 546.032L544.524 545.493L544.651 545.806L543.811 546.269L543.719 546.032Z'
        fill='url(#paint485_linear_3150_15389)'
      />
      <path
        d='M697.965 314.23L698.46 313.022L699.563 313.539L699.023 314.726L697.965 314.23Z'
        fill='url(#paint486_linear_3150_15389)'
      />
      <path
        d='M541.81 175.17L540.385 174.318L542.167 173.401L543.811 174.275L541.81 175.17Z'
        fill='url(#paint487_linear_3150_15389)'
      />
      <path
        d='M695.987 351.842L696.608 350.699L697.574 351.292L696.907 352.414L695.987 351.842Z'
        fill='url(#paint488_linear_3150_15389)'
      />
      <path
        d='M452.729 568.446L453.062 567.993L453.12 568.155L452.752 568.522L452.729 568.446Z'
        fill='url(#paint489_linear_3150_15389)'
      />
      <path
        d='M626.212 490.989L627.132 490.233L627.489 490.773L626.534 491.474L626.212 490.989Z'
        fill='url(#paint490_linear_3150_15389)'
      />
      <path
        d='M513.236 557.843L513.926 557.358L514.006 557.595L513.293 558.005L513.236 557.843Z'
        fill='url(#paint491_linear_3150_15389)'
      />
      <path
        d='M697.471 295.418L697.908 294.178L699.069 294.641L698.598 295.871L697.471 295.418Z'
        fill='url(#paint492_linear_3150_15389)'
      />
      <path
        d='M409.32 568.554L409.355 568.091L409.412 568.242L409.355 568.63L409.32 568.554Z'
        fill='url(#paint493_linear_3150_15389)'
      />
      <path
        d='M693.527 370.546L694.205 369.446L695.102 370.072L694.378 371.139L693.527 370.546Z'
        fill='url(#paint494_linear_3150_15389)'
      />
      <path
        d='M654.465 459.201L655.35 458.338L655.856 458.942L654.925 459.762L654.465 459.201Z'
        fill='url(#paint495_linear_3150_15389)'
      />
      <path
        d='M463.457 568.77L463.871 568.328L463.929 568.479L463.492 568.856L463.457 568.77Z'
        fill='url(#paint496_linear_3150_15389)'
      />
      <path
        d='M398.5 568.932L398.443 568.468L398.5 568.619L398.523 569.007L398.5 568.932Z'
        fill='url(#paint497_linear_3150_15389)'
      />
      <path
        d='M690.088 389.121L690.824 388.063L691.641 388.7L690.87 389.725L690.088 389.121Z'
        fill='url(#paint498_linear_3150_15389)'
      />
      <path
        d='M474.094 569.245L474.577 568.792L474.634 568.953L474.117 569.32L474.094 569.245Z'
        fill='url(#paint499_linear_3150_15389)'
      />
      <path
        d='M556.713 96.4391L556.782 95.1124L557.334 95.5007V96.7304L556.713 96.4391Z'
        fill='url(#paint500_linear_3150_15389)'
      />
      <path
        d='M341.811 118.239L341.73 117.214L342.006 117.128L341.88 118.347L341.811 118.239Z'
        fill='url(#paint501_linear_3150_15389)'
      />
      <path
        d='M575.353 534.383L576.261 533.789L576.434 534.167L575.502 534.685L575.353 534.383Z'
        fill='url(#paint502_linear_3150_15389)'
      />
      <path
        d='M596.269 520.953L597.201 520.306L597.431 520.748L596.465 521.32L596.269 520.953Z'
        fill='url(#paint503_linear_3150_15389)'
      />
      <path
        d='M387.737 569.45L387.611 568.986L387.657 569.148L387.76 569.525L387.737 569.45Z'
        fill='url(#paint504_linear_3150_15389)'
      />
      <path
        d='M685.684 407.512L686.466 406.498L687.214 407.145L686.386 408.126L685.684 407.512Z'
        fill='url(#paint505_linear_3150_15389)'
      />
      <path
        d='M280.004 153.079L279.924 151.893L279.981 151.979L280.096 153.187L280.004 153.079Z'
        fill='url(#paint506_linear_3150_15389)'
      />
      <path
        d='M693.688 139.974L694.251 138.345L695.378 138.863L694.814 140.427L693.688 139.974Z'
        fill='url(#paint507_linear_3150_15389)'
      />
      <path
        d='M524.01 558.922L524.769 558.437L524.85 558.674L524.068 559.084L524.01 558.922Z'
        fill='url(#paint508_linear_3150_15389)'
      />
      <path
        d='M525.057 138.83L524.102 138.01L525.356 137.115L526.701 137.892L525.057 138.83Z'
        fill='url(#paint509_linear_3150_15389)'
      />
      <path
        d='M582.148 199.278L581.148 198.07L583.218 197.681L584.184 198.933L582.148 199.278Z'
        fill='url(#paint510_linear_3150_15389)'
      />
      <path
        d='M589.68 226.719L588.979 225.068L590.554 225.025L590.796 226.309L589.68 226.719Z'
        fill='url(#paint511_linear_3150_15389)'
      />
      <path
        d='M484.592 569.849L485.156 569.406L485.201 569.568L484.615 569.924L484.592 569.849Z'
        fill='url(#paint512_linear_3150_15389)'
      />
      <path
        d='M646.703 476.276L647.623 475.456L648.049 476.049L647.083 476.793L646.703 476.276Z'
        fill='url(#paint513_linear_3150_15389)'
      />
      <path
        d='M554.701 547.434L555.563 546.906L555.69 547.219L554.793 547.672L554.701 547.434Z'
        fill='url(#paint514_linear_3150_15389)'
      />
      <path
        d='M617.243 507.147L618.198 506.446L618.485 506.942L617.496 507.578L617.243 507.147Z'
        fill='url(#paint515_linear_3150_15389)'
      />
      <path
        d='M550.849 161.493L549.86 160.576L551.504 159.799L552.734 160.738L550.849 161.493Z'
        fill='url(#paint516_linear_3150_15389)'
      />
      <path
        d='M680.303 425.676L681.142 424.705L681.809 425.363L680.924 426.28L680.303 425.676Z'
        fill='url(#paint517_linear_3150_15389)'
      />
      <path
        d='M271.909 129.025L271.828 127.558V127.601L272.001 129.025H271.909Z'
        fill='url(#paint518_linear_3150_15389)'
      />
      <path
        d='M478.314 143.619L476.75 142.605L478.141 141.753L479.004 142.562L478.314 143.619Z'
        fill='url(#paint519_linear_3150_15389)'
      />
      <path
        d='M310.373 100.765L310.281 99.4486L310.407 99.3947L310.396 100.84L310.373 100.765Z'
        fill='url(#paint520_linear_3150_15389)'
      />
      <path
        d='M293.952 163.154L293.964 162.215L294.067 162.323L294.102 163.283L293.952 163.154Z'
        fill='url(#paint521_linear_3150_15389)'
      />
      <path
        d='M275.658 141.505L275.715 140.254L275.83 140.297L275.876 141.527L275.658 141.505Z'
        fill='url(#paint522_linear_3150_15389)'
      />
      <path
        d='M602.444 152.605L602.594 151.494L603.743 151.472L603.801 152.54L602.444 152.605Z'
        fill='url(#paint523_linear_3150_15389)'
      />
      <path
        d='M494.93 570.593L495.573 570.161L495.619 570.323L494.964 570.679L494.93 570.593Z'
        fill='url(#paint524_linear_3150_15389)'
      />
      <path
        d='M337.199 97.3021L337.188 96.1048L337.498 95.8998L337.395 97.2374L337.199 97.3021Z'
        fill='url(#paint525_linear_3150_15389)'
      />
      <path
        d='M698.092 161.18L698.782 159.67L699.862 160.209L699.207 161.611L698.092 161.18Z'
        fill='url(#paint526_linear_3150_15389)'
      />
      <path
        d='M684.787 265.647L685.052 264.331L686.305 264.612L686.006 265.928L684.787 265.647Z'
        fill='url(#paint527_linear_3150_15389)'
      />
      <path
        d='M673.978 443.56L674.864 442.643L675.45 443.28L674.519 444.153L673.978 443.56Z'
        fill='url(#paint528_linear_3150_15389)'
      />
      <path
        d='M534.52 560.141L535.348 559.666L535.429 559.904L534.578 560.303L534.52 560.141Z'
        fill='url(#paint529_linear_3150_15389)'
      />
      <path
        d='M527.701 109.351L527.839 108.175L528.23 108.326L528.288 109.437L527.701 109.351Z'
        fill='url(#paint530_linear_3150_15389)'
      />
      <path
        d='M638.044 492.93L639.022 492.175L639.378 492.725L638.366 493.415L638.044 492.93Z'
        fill='url(#paint531_linear_3150_15389)'
      />
      <path
        d='M567.142 185.201L566.039 184.177L567.947 183.551L569.189 184.641L567.142 185.201Z'
        fill='url(#paint532_linear_3150_15389)'
      />
      <path
        d='M296.31 180.38L296.206 179.614L296.229 179.787L296.332 180.585L296.31 180.38Z'
        fill='url(#paint533_linear_3150_15389)'
      />
      <path
        d='M551.642 129.5L551.527 128.41L552.412 128.119L552.941 129.187L551.642 129.5Z'
        fill='url(#paint534_linear_3150_15389)'
      />
      <path
        d='M292.641 151.903L292.768 150.922L293.032 150.943L292.94 151.968L292.641 151.903Z'
        fill='url(#paint535_linear_3150_15389)'
      />
      <path
        d='M586.323 536.098L587.289 535.515L587.461 535.893L586.461 536.4L586.323 536.098Z'
        fill='url(#paint536_linear_3150_15389)'
      />
      <path
        d='M505.083 571.478L505.796 571.046L505.853 571.208L505.118 571.564L505.083 571.478Z'
        fill='url(#paint537_linear_3150_15389)'
      />
      <path
        d='M529.829 46.3466L529.932 44.7933L530.289 45.5052L530.254 46.9398L529.829 46.3466Z'
        fill='url(#paint538_linear_3150_15389)'
      />
      <path
        d='M562.6 140.157L562.244 139.121L563.509 138.658L564.199 139.682L562.6 140.157Z'
        fill='url(#paint539_linear_3150_15389)'
      />
      <path
        d='M512.925 77.8755L513.04 76.5704L513.201 77.1852L513.178 78.3717L512.925 77.8755Z'
        fill='url(#paint540_linear_3150_15389)'
      />
      <path
        d='M541.454 149.843L540.511 148.991L541.948 148.182L543.11 149.045L541.454 149.843Z'
        fill='url(#paint541_linear_3150_15389)'
      />
      <path
        d='M565.349 548.988L566.28 548.459L566.407 548.772L565.441 549.225L565.349 548.988Z'
        fill='url(#paint542_linear_3150_15389)'
      />
      <path
        d='M264.216 117.376L264.205 115.887L264.251 115.833L264.366 117.311L264.216 117.376Z'
        fill='url(#paint543_linear_3150_15389)'
      />
      <path
        d='M538.499 95.3281L538.625 94.0769L539.051 94.3573L539.039 95.5223L538.499 95.3281Z'
        fill='url(#paint544_linear_3150_15389)'
      />
      <path
        d='M552.792 80.2054L552.849 78.8787L553.401 79.2455L553.332 80.5398L552.792 80.2054Z'
        fill='url(#paint545_linear_3150_15389)'
      />
      <path
        d='M532.542 72.1911L532.565 70.8859L533.002 71.3497L532.887 72.6657L532.542 72.1911Z'
        fill='url(#paint546_linear_3150_15389)'
      />
      <path
        d='M607.446 522.82L608.435 522.183L608.665 522.625L607.641 523.197L607.446 522.82Z'
        fill='url(#paint547_linear_3150_15389)'
      />
      <path
        d='M509.453 52.9479L509.487 51.5241L509.717 52.2899L509.625 53.7029L509.453 52.9479Z'
        fill='url(#paint548_linear_3150_15389)'
      />
      <path
        d='M536.222 118.487L536.326 117.365L536.912 117.268L537.142 118.379L536.222 118.487Z'
        fill='url(#paint549_linear_3150_15389)'
      />
      <path
        d='M312.845 165.991L312.994 165.203L313.178 165.311L313.155 166.088L312.845 165.991Z'
        fill='url(#paint550_linear_3150_15389)'
      />
      <path
        d='M666.7 461.11L667.631 460.247L668.137 460.862L667.16 461.681L666.7 461.11Z'
        fill='url(#paint551_linear_3150_15389)'
      />
      <path
        d='M569.557 76.6351L569.649 75.2113L570.247 75.6535L570.224 76.991L569.557 76.6351Z'
        fill='url(#paint552_linear_3150_15389)'
      />
      <path
        d='M710.775 333.861L711.373 332.675L712.431 333.236L711.81 334.401L710.775 333.861Z'
        fill='url(#paint553_linear_3150_15389)'
      />
      <path
        d='M268.804 99.125L268.735 97.5178L268.758 97.3992L268.873 99.0603L268.804 99.125Z'
        fill='url(#paint554_linear_3150_15389)'
      />
      <path
        d='M711.304 314.812L711.833 313.593L712.96 314.122L712.396 315.319L711.304 314.812Z'
        fill='url(#paint555_linear_3150_15389)'
      />
      <path
        d='M709.223 352.856L709.866 351.713L710.867 352.317L710.165 353.439L709.223 352.856Z'
        fill='url(#paint556_linear_3150_15389)'
      />
      <path
        d='M710.786 295.785L711.258 294.534L712.442 295.008L711.948 296.249L710.786 295.785Z'
        fill='url(#paint557_linear_3150_15389)'
      />
      <path
        d='M706.612 371.776L707.325 370.665L708.257 371.29L707.498 372.38L706.612 371.776Z'
        fill='url(#paint558_linear_3150_15389)'
      />
      <path
        d='M432.134 151.094L430.133 151.148L430.628 149.897L432.87 149.692L432.134 151.094Z'
        fill='url(#paint559_linear_3150_15389)'
      />
      <path
        d='M533.773 83.1825L533.899 81.8989L534.221 82.3196L534.198 83.5169L533.773 83.1825Z'
        fill='url(#paint560_linear_3150_15389)'
      />
      <path
        d='M532.14 58.3088L532.232 56.8958L532.623 57.4243L532.577 58.7726L532.14 58.3088Z'
        fill='url(#paint561_linear_3150_15389)'
      />
      <path
        d='M544.743 561.5L545.64 561.036L545.72 561.273L544.8 561.662L544.743 561.5Z'
        fill='url(#paint562_linear_3150_15389)'
      />
      <path
        d='M265.09 126.318L265.228 124.991L265.389 124.926L265.412 126.232L265.09 126.318Z'
        fill='url(#paint563_linear_3150_15389)'
      />
      <path
        d='M357.725 94.2387L357.702 93.1493L358.162 92.8364L358.047 94.0661L357.725 94.2387Z'
        fill='url(#paint564_linear_3150_15389)'
      />
      <path
        d='M702.99 390.544L703.761 389.477L704.6 390.124L703.784 391.159L702.99 390.544Z'
        fill='url(#paint565_linear_3150_15389)'
      />
      <path
        d='M279.97 140.977L280.154 139.898L280.487 139.855L280.384 140.977H279.97Z'
        fill='url(#paint566_linear_3150_15389)'
      />
      <path
        d='M549.204 53.5088L549.273 52.0418L549.802 52.5488L549.745 53.9618L549.204 53.5088Z'
        fill='url(#paint567_linear_3150_15389)'
      />
      <path
        d='M628.523 509.131L629.535 508.441L629.834 508.948L628.776 509.574L628.523 509.131Z'
        fill='url(#paint568_linear_3150_15389)'
      />
      <path
        d='M515.03 572.502L515.8 572.082L515.857 572.243L515.053 572.578L515.03 572.502Z'
        fill='url(#paint569_linear_3150_15389)'
      />
      <path
        d='M547.709 172.926L546.49 172.096L548.135 171.276L549.561 172.139L547.709 172.926Z'
        fill='url(#paint570_linear_3150_15389)'
      />
      <path
        d='M510.223 64.0257L510.315 62.6881L510.442 63.3893L510.373 64.6513L510.223 64.0257Z'
        fill='url(#paint571_linear_3150_15389)'
      />
      <path d='M492.17 58.5461L492.296 57.1223V59.2149L492.17 58.5461Z' fill='url(#paint572_linear_3150_15389)' />
      <path
        d='M658.478 478.282L659.455 477.462L659.892 478.055L658.869 478.81L658.478 478.282Z'
        fill='url(#paint573_linear_3150_15389)'
      />
      <path
        d='M567.131 210.636L566.142 209.32L567.614 208.91L568.672 210.237L567.131 210.636Z'
        fill='url(#paint574_linear_3150_15389)'
      />
      <path
        d='M698.333 409.119L699.161 408.094L699.92 408.752L699.046 409.734L698.333 409.119Z'
        fill='url(#paint575_linear_3150_15389)'
      />
      <path
        d='M258.294 90.0104L258.271 88.3169L258.317 88.1443L258.409 89.8486L258.294 90.0104Z'
        fill='url(#paint576_linear_3150_15389)'
      />
      <path
        d='M252.372 104.831L252.407 103.289L252.487 103.138L252.568 104.691L252.372 104.831Z'
        fill='url(#paint577_linear_3150_15389)'
      />
      <path
        d='M601.455 160.274L601.501 159.217L602.628 159.174L602.708 160.22L601.455 160.274Z'
        fill='url(#paint578_linear_3150_15389)'
      />
      <path
        d='M575.617 550.67L576.618 550.153L576.744 550.465L575.721 550.908L575.617 550.67Z'
        fill='url(#paint579_linear_3150_15389)'
      />
      <path
        d='M596.867 537.953L597.879 537.381L598.063 537.759L597.005 538.266L596.867 537.953Z'
        fill='url(#paint580_linear_3150_15389)'
      />
      <path
        d='M692.664 427.445L693.538 426.474L694.228 427.132L693.297 428.06L692.664 427.445Z'
        fill='url(#paint581_linear_3150_15389)'
      />
      <path
        d='M423.924 151.936L421.739 151.839L422.475 150.598H424.441L423.924 151.936Z'
        fill='url(#paint582_linear_3150_15389)'
      />
      <path
        d='M450.095 132.002L449.336 130.794L450.256 130.039L450.705 131.032L450.095 132.002Z'
        fill='url(#paint583_linear_3150_15389)'
      />
      <path
        d='M524.723 573.656L525.563 573.247L525.62 573.408L524.746 573.743L524.723 573.656Z'
        fill='url(#paint584_linear_3150_15389)'
      />
      <path
        d='M708.625 183.033L709.579 181.653L710.625 182.246L709.786 183.465L708.625 183.033Z'
        fill='url(#paint585_linear_3150_15389)'
      />
      <path
        d='M549.906 64.565L549.986 63.1843L550.504 63.5726L550.435 64.8994L549.906 64.565Z'
        fill='url(#paint586_linear_3150_15389)'
      />
      <path
        d='M521.963 95.4144L522.09 94.2387L522.4 94.4329L522.354 95.5547L521.963 95.4144Z'
        fill='url(#paint587_linear_3150_15389)'
      />
      <path
        d='M554.643 562.999L555.598 562.535L555.69 562.784L554.701 563.161L554.643 562.999Z'
        fill='url(#paint588_linear_3150_15389)'
      />
      <path
        d='M649.336 495.012L650.36 494.257L650.728 494.818L649.658 495.508L649.336 495.012Z'
        fill='url(#paint589_linear_3150_15389)'
      />
      <path
        d='M496.792 68.1785L496.907 66.9164L496.988 67.4989L496.942 68.6638L496.792 68.1785Z'
        fill='url(#paint590_linear_3150_15389)'
      />
      <path
        d='M425.12 124.355L425.131 123.47L425.373 123.503L425.292 124.473L425.12 124.355Z'
        fill='url(#paint591_linear_3150_15389)'
      />
      <path
        d='M618.151 524.826L619.198 524.189L619.428 524.642L618.347 525.203L618.151 524.826Z'
        fill='url(#paint592_linear_3150_15389)'
      />
      <path
        d='M440.459 122.445L440.609 121.485L440.931 121.442L440.85 122.348L440.459 122.445Z'
        fill='url(#paint593_linear_3150_15389)'
      />
      <path
        d='M454.603 129.77L454.626 128.529L455.063 127.752L455.247 129.09L454.603 129.77Z'
        fill='url(#paint594_linear_3150_15389)'
      />
      <path
        d='M565.165 160.705L564.429 159.778L566.004 159.152L566.935 160.134L565.165 160.705Z'
        fill='url(#paint595_linear_3150_15389)'
      />
      <path
        d='M685.983 445.469L686.915 444.553L687.513 445.2L686.535 446.073L685.983 445.469Z'
        fill='url(#paint596_linear_3150_15389)'
      />
      <path
        d='M500.587 78.5983L500.714 77.4009L500.851 77.8324L500.817 78.9434L500.587 78.5983Z'
        fill='url(#paint597_linear_3150_15389)'
      />
      <path
        d='M557.207 196.56L556.15 195.384L557.587 194.899L558.668 196.074L557.207 196.56Z'
        fill='url(#paint598_linear_3150_15389)'
      />
      <path
        d='M493.78 130.827L492.894 130.093L493.665 129.23L495.079 129.953L493.78 130.827Z'
        fill='url(#paint599_linear_3150_15389)'
      />
      <path
        d='M261.111 127.99L261.307 126.782L261.64 126.685L261.594 127.903L261.111 127.99Z'
        fill='url(#paint600_linear_3150_15389)'
      />
      <path
        d='M452.154 143.792L450.647 143.479L451.165 142.368L453.177 142.584L452.154 143.792Z'
        fill='url(#paint601_linear_3150_15389)'
      />
      <path
        d='M570.029 150.685L569.638 149.692L570.937 149.228L571.673 150.232L570.029 150.685Z'
        fill='url(#paint602_linear_3150_15389)'
      />
      <path
        d='M362.738 103.817L362.601 102.954L363.06 102.674L362.888 103.763L362.738 103.817Z'
        fill='url(#paint603_linear_3150_15389)'
      />
      <path
        d='M428.259 111.476L428.317 110.408L428.535 110.462L428.501 111.497L428.259 111.476Z'
        fill='url(#paint604_linear_3150_15389)'
      />
      <path
        d='M621.544 85.1672L621.394 83.8081L622.602 83.7434L622.613 85.2104L621.544 85.1672Z'
        fill='url(#paint605_linear_3150_15389)'
      />
      <path
        d='M269.483 67.6068L269.506 65.827L269.517 65.6328L269.609 67.3695L269.483 67.6068Z'
        fill='url(#paint606_linear_3150_15389)'
      />
      <path
        d='M588.967 167.555V166.519L589.864 166.498L589.91 167.533L588.967 167.555Z'
        fill='url(#paint607_linear_3150_15389)'
      />
      <path
        d='M248.256 111.098L248.382 109.696L248.578 109.523L248.601 110.936L248.256 111.098Z'
        fill='url(#paint608_linear_3150_15389)'
      />
      <path
        d='M678.314 463.148L679.291 462.275L679.808 462.911L678.785 463.72L678.314 463.148Z'
        fill='url(#paint609_linear_3150_15389)'
      />
      <path
        d='M534.141 574.951L535.061 574.541L535.107 574.713L534.164 575.026L534.141 574.951Z'
        fill='url(#paint610_linear_3150_15389)'
      />
      <path
        d='M312.258 146.683L312.488 145.809L312.879 145.744L312.833 146.618L312.258 146.683Z'
        fill='url(#paint611_linear_3150_15389)'
      />
      <path
        d='M374.858 92.1245L374.789 91.143L375.364 90.7115L375.318 91.8225L374.858 92.1245Z'
        fill='url(#paint612_linear_3150_15389)'
      />
      <path
        d='M245.634 78.1128L245.657 76.3439L245.691 76.0958L245.795 77.8432L245.634 78.1128Z'
        fill='url(#paint613_linear_3150_15389)'
      />
      <path
        d='M639.286 511.256L640.344 510.566L640.643 511.073L639.539 511.699L639.286 511.256Z'
        fill='url(#paint614_linear_3150_15389)'
      />
      <path
        d='M309.867 168.051L309.844 167.393L309.901 167.49L309.936 168.169L309.867 168.051Z'
        fill='url(#paint615_linear_3150_15389)'
      />
      <path
        d='M518.272 83.3767L518.433 82.1686L518.617 82.4814L518.583 83.6032L518.272 83.3767Z'
        fill='url(#paint616_linear_3150_15389)'
      />
      <path
        d='M585.495 552.493L586.541 551.986L586.668 552.31L585.587 552.741L585.495 552.493Z'
        fill='url(#paint617_linear_3150_15389)'
      />
      <path
        d='M236.55 91.294L236.596 89.6652L236.676 89.4279L236.768 91.0675L236.55 91.294Z'
        fill='url(#paint618_linear_3150_15389)'
      />
      <path
        d='M564.187 564.628L565.211 564.186L565.291 564.423L564.245 564.801L564.187 564.628Z'
        fill='url(#paint619_linear_3150_15389)'
      />
      <path
        d='M723.401 334.713L724.022 333.516L725.114 334.099L724.459 335.274L723.401 334.713Z'
        fill='url(#paint620_linear_3150_15389)'
      />
      <path
        d='M606.94 539.949L608.021 539.377L608.205 539.776L607.09 540.261L606.94 539.949Z'
        fill='url(#paint621_linear_3150_15389)'
      />
      <path
        d='M721.756 353.946L722.446 352.781L723.458 353.396L722.734 354.539L721.756 353.946Z'
        fill='url(#paint622_linear_3150_15389)'
      />
      <path
        d='M723.953 315.438L724.516 314.208L725.677 314.737L725.079 315.956L723.953 315.438Z'
        fill='url(#paint623_linear_3150_15389)'
      />
      <path
        d='M543.926 184.144L542.742 183.13L544.133 182.537L545.318 183.53L543.926 184.144Z'
        fill='url(#paint624_linear_3150_15389)'
      />
      <path
        d='M687.536 196.139L688.065 195.082L689.157 195.47L688.536 196.57L687.536 196.139Z'
        fill='url(#paint625_linear_3150_15389)'
      />
      <path
        d='M577.422 163.607L577.319 162.582L578.262 162.442L578.423 163.488L577.422 163.607Z'
        fill='url(#paint626_linear_3150_15389)'
      />
      <path
        d='M669.655 480.418L670.69 479.598L671.127 480.202L670.057 480.957L669.655 480.418Z'
        fill='url(#paint627_linear_3150_15389)'
      />
      <path
        d='M719.02 373.081L719.767 371.959L720.71 372.596L719.917 373.685L719.02 373.081Z'
        fill='url(#paint628_linear_3150_15389)'
      />
      <path
        d='M571.57 224.799L570.926 223.213L572.053 222.728L572.765 224.313L571.57 224.799Z'
        fill='url(#paint629_linear_3150_15389)'
      />
      <path
        d='M508.142 32.2378L508.303 30.7708L508.337 31.5798L508.268 32.9281L508.142 32.2378Z'
        fill='url(#paint630_linear_3150_15389)'
      />
      <path
        d='M706.221 194.262L707.026 193.13L708.176 193.604L707.268 194.78L706.221 194.262Z'
        fill='url(#paint631_linear_3150_15389)'
      />
      <path
        d='M715.202 392.055L716.007 390.976L716.869 391.634L716.018 392.68L715.202 392.055Z'
        fill='url(#paint632_linear_3150_15389)'
      />
      <path
        d='M614.023 170.1L614.035 169.054L615.047 169.065V170.111L614.023 170.1Z'
        fill='url(#paint633_linear_3150_15389)'
      />
      <path
        d='M690.962 258.485L691.135 257.094L692.503 257.32L692.25 258.679L690.962 258.485Z'
        fill='url(#paint634_linear_3150_15389)'
      />
      <path
        d='M543.259 576.375L544.237 575.975L544.294 576.148L543.282 576.45L543.259 576.375Z'
        fill='url(#paint635_linear_3150_15389)'
      />
      <path
        d='M424.809 104.831L424.913 103.774L425.108 103.796L425.131 104.734L424.809 104.831Z'
        fill='url(#paint636_linear_3150_15389)'
      />
      <path
        d='M710.303 410.812L711.177 409.788L711.948 410.456L711.039 411.449L710.303 410.812Z'
        fill='url(#paint637_linear_3150_15389)'
      />
      <path
        d='M628.339 526.962L629.443 526.336L629.673 526.8L628.535 527.35L628.339 526.962Z'
        fill='url(#paint638_linear_3150_15389)'
      />
      <path
        d='M571.202 160.705L570.799 159.734L572.006 159.357L572.604 160.382L571.202 160.705Z'
        fill='url(#paint639_linear_3150_15389)'
      />
      <path
        d='M487.57 80.0005L487.72 78.8787L487.731 79.2346L487.674 80.2809L487.57 80.0005Z'
        fill='url(#paint640_linear_3150_15389)'
      />
      <path
        d='M331.922 153.759L332.082 153.058L332.473 153.004L332.427 153.726L331.922 153.759Z'
        fill='url(#paint641_linear_3150_15389)'
      />
      <path
        d='M660.042 497.223L661.111 496.468L661.479 497.04L660.364 497.719L660.042 497.223Z'
        fill='url(#paint642_linear_3150_15389)'
      />
      <path
        d='M704.347 429.322L705.267 428.34L705.957 429.009L704.991 429.948L704.347 429.322Z'
        fill='url(#paint643_linear_3150_15389)'
      />
      <path
        d='M405.261 107.582L405.215 106.578L405.33 106.805L405.296 107.884L405.261 107.582Z'
        fill='url(#paint644_linear_3150_15389)'
      />
      <path
        d='M535.141 172.376L534.026 171.513L535.268 170.834L536.509 171.708L535.141 172.376Z'
        fill='url(#paint645_linear_3150_15389)'
      />
      <path
        d='M231.962 116.847L232.031 115.542L232.18 115.434L232.226 116.783L231.962 116.847Z'
        fill='url(#paint646_linear_3150_15389)'
      />
      <path
        d='M307.107 64.1443L307.084 62.8068L307.233 62.5587L307.153 64.0149L307.107 64.1443Z'
        fill='url(#paint647_linear_3150_15389)'
      />
      <path
        d='M588.565 67.0027L588.312 65.6436L589.45 65.471L589.508 66.8841L588.565 67.0027Z'
        fill='url(#paint648_linear_3150_15389)'
      />
      <path
        d='M722.734 292.301L723.205 291.028L724.47 291.481L723.941 292.754L722.734 292.301Z'
        fill='url(#paint649_linear_3150_15389)'
      />
      <path
        d='M260.686 55.267L260.755 53.4764L260.789 53.1529L260.858 54.9003L260.686 55.267Z'
        fill='url(#paint650_linear_3150_15389)'
      />
      <path
        d='M586.943 224.184L586.61 222.652L587.633 222.091L588.116 223.763L586.943 224.184Z'
        fill='url(#paint651_linear_3150_15389)'
      />
      <path
        d='M509.188 95.0585L509.384 93.9259L509.602 93.969L509.579 95.0693L509.188 95.0585Z'
        fill='url(#paint652_linear_3150_15389)'
      />
      <path
        d='M328.598 64.2306L328.552 63.0117L328.886 62.6018L328.84 63.9501L328.598 64.2306Z'
        fill='url(#paint653_linear_3150_15389)'
      />
      <path
        d='M607.745 224.766L607.446 223.245L608.584 222.825L608.975 224.4L607.745 224.766Z'
        fill='url(#paint654_linear_3150_15389)'
      />
      <path
        d='M296.114 133.976L296.252 133.059L296.792 132.833L296.7 133.836L296.114 133.976Z'
        fill='url(#paint655_linear_3150_15389)'
      />
      <path
        d='M573.352 566.397L574.433 565.955L574.513 566.203L573.409 566.57L573.352 566.397Z'
        fill='url(#paint656_linear_3150_15389)'
      />
      <path
        d='M594.912 554.456L596.028 553.96L596.154 554.284L595.004 554.704L594.912 554.456Z'
        fill='url(#paint657_linear_3150_15389)'
      />
      <path
        d='M222.613 99.3947L222.625 97.9061L222.705 97.7012L222.774 99.2544L222.613 99.3947Z'
        fill='url(#paint658_linear_3150_15389)'
      />
      <path
        d='M697.321 447.497L698.31 446.57L698.92 447.238L697.896 448.112L697.321 447.497Z'
        fill='url(#paint659_linear_3150_15389)'
      />
      <path
        d='M443.645 109.491L443.783 108.542L444.22 108.315L444.289 109.135L443.645 109.491Z'
        fill='url(#paint660_linear_3150_15389)'
      />
      <path
        d='M505.819 84.7573L505.968 83.614L506.129 83.7866L506.037 84.876L505.819 84.7573Z'
        fill='url(#paint661_linear_3150_15389)'
      />
      <path
        d='M552.044 577.928L553.091 577.54L553.137 577.712L552.067 578.003L552.044 577.928Z'
        fill='url(#paint662_linear_3150_15389)'
      />
      <path
        d='M423.269 91.5313L423.303 90.4203L423.51 90.4311L423.499 91.5313H423.269Z'
        fill='url(#paint663_linear_3150_15389)'
      />
      <path
        d='M649.474 513.511L650.59 512.831L650.889 513.349L649.727 513.964L649.474 513.511Z'
        fill='url(#paint664_linear_3150_15389)'
      />
      <path
        d='M560.427 209.708L559.714 208.231L560.83 207.745L561.543 209.158L560.427 209.708Z'
        fill='url(#paint665_linear_3150_15389)'
      />
      <path
        d='M346.41 66.129L346.295 65.0072L346.778 64.4787L346.813 65.7083L346.41 66.129Z'
        fill='url(#paint666_linear_3150_15389)'
      />
      <path
        d='M616.53 542.084L617.669 541.523L617.841 541.922L616.668 542.397L616.53 542.084Z'
        fill='url(#paint667_linear_3150_15389)'
      />
      <path
        d='M400.317 154.125L398.443 154.384L398.534 153.252L400.857 152.788L400.317 154.125Z'
        fill='url(#paint668_linear_3150_15389)'
      />
      <path
        d='M230.605 63.1951L230.674 61.3506L230.662 61.0378L230.789 62.8068L230.605 63.1951Z'
        fill='url(#paint669_linear_3150_15389)'
      />
      <path
        d='M440.229 144.374L438.884 143.393L439.838 142.444L440.724 143.166L440.229 144.374Z'
        fill='url(#paint670_linear_3150_15389)'
      />
      <path
        d='M562.991 55.3641L563.003 53.9834L563.727 54.1668L563.543 55.5475L562.991 55.3641Z'
        fill='url(#paint671_linear_3150_15389)'
      />
      <path
        d='M689.272 465.306L690.295 464.432L690.824 465.079L689.755 465.888L689.272 465.306Z'
        fill='url(#paint672_linear_3150_15389)'
      />
      <path
        d='M404.985 80.4535L405.02 79.0189L405.054 79.3964L405.077 80.7232L404.985 80.4535Z'
        fill='url(#paint673_linear_3150_15389)'
      />
      <path
        d='M724.734 138.366L725.608 136.759L726.965 137.126L726.045 138.712L724.734 138.366Z'
        fill='url(#paint674_linear_3150_15389)'
      />
      <path
        d='M383.632 89.827L383.436 88.9857L384.057 88.4787L384.092 89.4603L383.632 89.827Z'
        fill='url(#paint675_linear_3150_15389)'
      />
      <path
        d='M276.29 125.455L276.416 124.441L277.026 124.128L276.934 125.25L276.29 125.455Z'
        fill='url(#paint676_linear_3150_15389)'
      />
      <path
        d='M409.47 91.6068L409.493 90.4526L409.573 90.7007V91.8117L409.47 91.6068Z'
        fill='url(#paint677_linear_3150_15389)'
      />
      <path
        d='M545.663 31.4611L545.433 30.1128L546.364 30.0805L546.157 31.6337L545.663 31.4611Z'
        fill='url(#paint678_linear_3150_15389)'
      />
      <path
        d='M413.081 89.3416L413.104 88.2414L413.288 88.2738V89.3416H413.081Z'
        fill='url(#paint679_linear_3150_15389)'
      />
      <path
        d='M413.322 120.633L413.299 119.911L413.483 119.965L413.437 120.73L413.322 120.633Z'
        fill='url(#paint680_linear_3150_15389)'
      />
      <path
        d='M214.771 76.6674L214.805 74.9308L214.76 74.6827L214.909 76.387L214.771 76.6674Z'
        fill='url(#paint681_linear_3150_15389)'
      />
      <path
        d='M625.016 225.241L624.683 223.731L625.936 223.396L626.304 224.939L625.016 225.241Z'
        fill='url(#paint682_linear_3150_15389)'
      />
      <path
        d='M361.405 69.1924L361.209 68.1893L361.807 67.5528L361.922 68.6746L361.405 69.1924Z'
        fill='url(#paint683_linear_3150_15389)'
      />
      <path
        d='M680.188 482.672L681.269 481.863L681.717 482.478L680.59 483.233L680.188 482.672Z'
        fill='url(#paint684_linear_3150_15389)'
      />
      <path
        d='M680.084 182.613L680.395 181.577L681.545 181.825L681.108 182.958L680.084 182.613Z'
        fill='url(#paint685_linear_3150_15389)'
      />
      <path
        d='M637.975 529.237L639.125 528.623L639.367 529.086L638.171 529.637L637.975 529.237Z'
        fill='url(#paint686_linear_3150_15389)'
      />
      <path
        d='M344.478 106.018L344.352 105.209L344.536 105.112L344.329 106.061L344.478 106.018Z'
        fill='url(#paint687_linear_3150_15389)'
      />
      <path
        d='M582.091 568.295L583.229 567.864L583.31 568.112L582.137 568.468L582.091 568.295Z'
        fill='url(#paint688_linear_3150_15389)'
      />
      <path
        d='M413.54 77.347L413.633 76.0095L413.724 76.1821L413.77 77.3578L413.54 77.347Z'
        fill='url(#paint689_linear_3150_15389)'
      />
      <path
        d='M560.634 41.4279L560.358 40.058L561.393 39.8962L561.382 41.3632L560.634 41.4279Z'
        fill='url(#paint690_linear_3150_15389)'
      />
      <path
        d='M560.462 579.6L561.566 579.233L561.612 579.406L560.485 579.686L560.462 579.6Z'
        fill='url(#paint691_linear_3150_15389)'
      />
      <path
        d='M735.268 335.619L735.923 334.411L737.05 334.994L736.348 336.191L735.268 335.619Z'
        fill='url(#paint692_linear_3150_15389)'
      />
      <path
        d='M733.543 355.089L734.267 353.924L735.313 354.55L734.543 355.693L733.543 355.089Z'
        fill='url(#paint693_linear_3150_15389)'
      />
      <path
        d='M735.854 316.107L736.44 314.855L737.625 315.395L737.015 316.624L735.854 316.107Z'
        fill='url(#paint694_linear_3150_15389)'
      />
      <path
        d='M603.87 556.549L605.031 556.064L605.158 556.387L603.962 556.797L603.87 556.549Z'
        fill='url(#paint695_linear_3150_15389)'
      />
      <path
        d='M401.881 63.0009L401.904 61.3722L401.927 61.8036L401.904 63.3784L401.881 63.0009Z'
        fill='url(#paint696_linear_3150_15389)'
      />
      <path
        d='M730.679 374.451L731.461 373.318L732.427 373.976L731.599 375.077L730.679 374.451Z'
        fill='url(#paint697_linear_3150_15389)'
      />
      <path
        d='M402.893 64.4571L402.939 62.8823L402.985 63.1735V64.6513L402.893 64.4571Z'
        fill='url(#paint698_linear_3150_15389)'
      />
      <path
        d='M205.848 106.773L205.813 105.36L205.836 105.241L205.928 106.708L205.848 106.773Z'
        fill='url(#paint699_linear_3150_15389)'
      />
      <path
        d='M423.039 143.35L421.532 143.156L421.854 142.077L423.775 142.185L423.039 143.35Z'
        fill='url(#paint700_linear_3150_15389)'
      />
      <path
        d='M245.542 119.048L245.599 117.915L245.944 117.678L245.91 118.897L245.542 119.048Z'
        fill='url(#paint701_linear_3150_15389)'
      />
      <path
        d='M729.231 162.733L730.312 161.266L731.588 161.741L730.53 163.132L729.231 162.733Z'
        fill='url(#paint702_linear_3150_15389)'
      />
      <path
        d='M726.666 393.651L727.517 392.561L728.391 393.241L727.506 394.287L726.666 393.651Z'
        fill='url(#paint703_linear_3150_15389)'
      />
      <path
        d='M670.103 499.564L671.23 498.82L671.598 499.391L670.437 500.071L670.103 499.564Z'
        fill='url(#paint704_linear_3150_15389)'
      />
      <path
        d='M323.171 139.898L323.309 139.132L323.964 138.863L323.976 139.672L323.171 139.898Z'
        fill='url(#paint705_linear_3150_15389)'
      />
      <path
        d='M445.024 116.071L445.082 115.294L445.484 115.111L445.588 115.79L445.024 116.071Z'
        fill='url(#paint706_linear_3150_15389)'
      />
      <path
        d='M544.812 196.819L543.903 195.47L545.019 194.942L545.881 196.193L544.812 196.819Z'
        fill='url(#paint707_linear_3150_15389)'
      />
      <path
        d='M709.832 187.876L710.545 186.744L711.718 187.154L710.901 188.34L709.832 187.876Z'
        fill='url(#paint708_linear_3150_15389)'
      />
      <path
        d='M250.153 41.5142L250.245 39.7236L250.303 39.2706L250.371 41.0072L250.153 41.5142Z'
        fill='url(#paint709_linear_3150_15389)'
      />
      <path
        d='M721.538 412.614L722.446 411.578L723.24 412.268L722.285 413.261L721.538 412.614Z'
        fill='url(#paint710_linear_3150_15389)'
      />
      <path
        d='M492.25 118.703L491.871 117.786L492.503 117.095L493.297 118.142L492.25 118.703Z'
        fill='url(#paint711_linear_3150_15389)'
      />
      <path
        d='M373.904 72.5578L373.616 71.6733L374.272 70.9722L374.49 71.9538L373.904 72.5578Z'
        fill='url(#paint712_linear_3150_15389)'
      />
      <path
        d='M625.58 544.349L626.764 543.789L626.948 544.198L625.718 544.673L625.58 544.349Z'
        fill='url(#paint713_linear_3150_15389)'
      />
      <path
        d='M618.071 77.2391L617.761 75.8693L618.991 75.578L619.301 76.9479L618.071 77.2391Z'
        fill='url(#paint714_linear_3150_15389)'
      />
      <path
        d='M727.529 183.702L728.564 182.462L729.828 182.925L728.656 184.231L727.529 183.702Z'
        fill='url(#paint715_linear_3150_15389)'
      />
      <path
        d='M715.294 431.296L716.26 430.314L716.961 431.005L715.949 431.932L715.294 431.296Z'
        fill='url(#paint716_linear_3150_15389)'
      />
      <path
        d='M615.438 164.362L615.323 163.294L616.427 163.208L616.473 164.265L615.438 164.362Z'
        fill='url(#paint717_linear_3150_15389)'
      />
      <path
        d='M210.148 119.274L210.01 118.077L210.114 117.958L210.102 119.339L210.148 119.274Z'
        fill='url(#paint718_linear_3150_15389)'
      />
      <path
        d='M507.946 104.443L508.119 103.386L508.498 103.019L508.705 104.216L507.946 104.443Z'
        fill='url(#paint719_linear_3150_15389)'
      />
      <path
        d='M399.88 63.1304L399.949 61.6418L399.995 61.8468V63.249L399.88 63.1304Z'
        fill='url(#paint720_linear_3150_15389)'
      />
      <path
        d='M659.03 515.905L660.203 515.226L660.502 515.754L659.294 516.358L659.03 515.905Z'
        fill='url(#paint721_linear_3150_15389)'
      />
      <path
        d='M707.946 449.644L708.97 448.716L709.591 449.385L708.521 450.259L707.946 449.644Z'
        fill='url(#paint722_linear_3150_15389)'
      />
      <path
        d='M296.068 176.119L295.896 177.672L295.298 177.241L295.413 175.742L296.068 176.119Z'
        fill='url(#paint723_linear_3150_15389)'
      />
      <path
        d='M421.118 91.8225L421.176 90.8517L421.417 90.7223L421.578 91.5313L421.118 91.8225Z'
        fill='url(#paint724_linear_3150_15389)'
      />
      <path
        d='M568.488 581.412L569.649 581.056L569.695 581.229L568.511 581.498L568.488 581.412Z'
        fill='url(#paint725_linear_3150_15389)'
      />
      <path
        d='M391.118 62.8715L391.152 61.4585L391.279 61.4045L391.267 62.8176L391.118 62.8715Z'
        fill='url(#paint726_linear_3150_15389)'
      />
      <path
        d='M590.37 570.323L591.566 569.903L591.658 570.151L590.428 570.485L590.37 570.323Z'
        fill='url(#paint727_linear_3150_15389)'
      />
      <path
        d='M526.873 19.6283L526.827 18.2585L527.402 18.4742L527.069 19.9627L526.873 19.6283Z'
        fill='url(#paint728_linear_3150_15389)'
      />
      <path
        d='M606.825 209.029L606.469 207.594L607.595 207.109L608.055 208.63L606.825 209.029Z'
        fill='url(#paint729_linear_3150_15389)'
      />
      <path
        d='M405.215 83.3659L405.296 82.2872L405.411 82.3196L405.468 83.2796L405.215 83.3659Z'
        fill='url(#paint730_linear_3150_15389)'
      />
      <path
        d='M641.23 225.209L640.862 223.731L642.207 223.472L642.61 224.993L641.23 225.209Z'
        fill='url(#paint731_linear_3150_15389)'
      />
      <path
        d='M568.787 209.525L568.384 207.929L569.362 207.325L569.81 208.889L568.787 209.525Z'
        fill='url(#paint732_linear_3150_15389)'
      />
      <path
        d='M699.517 467.582L700.587 466.708L701.127 467.366L700 468.175L699.517 467.582Z'
        fill='url(#paint733_linear_3150_15389)'
      />
      <path
        d='M584.713 209.104L584.391 207.616L585.38 206.969L585.851 208.597L584.713 209.104Z'
        fill='url(#paint734_linear_3150_15389)'
      />
      <path
        d='M424.821 94.9506L424.752 94.1093L425.2 93.7209L425.488 94.4329L424.821 94.9506Z'
        fill='url(#paint735_linear_3150_15389)'
      />
      <path
        d='M686.708 249.457L686.731 247.99L688.134 248.087L688.076 249.521L686.708 249.457Z'
        fill='url(#paint736_linear_3150_15389)'
      />
      <path
        d='M391.141 62.5803L391.152 61.1996L391.267 61.2319L391.221 62.645L391.141 62.5803Z'
        fill='url(#paint737_linear_3150_15389)'
      />
      <path
        d='M734.29 403.326L734.578 401.935L735.566 402.55L735.199 403.909L734.29 403.326Z'
        fill='url(#paint738_linear_3150_15389)'
      />
      <path
        d='M612.299 558.771L613.529 558.296L613.644 558.631L612.391 559.019L612.299 558.771Z'
        fill='url(#paint739_linear_3150_15389)'
      />
      <path
        d='M647.002 531.654L648.221 531.039L648.451 531.513L647.198 532.042L647.002 531.654Z'
        fill='url(#paint740_linear_3150_15389)'
      />
      <path
        d='M530.714 185.115L529.771 183.972L530.772 183.357L531.783 184.49L530.714 185.115Z'
        fill='url(#paint741_linear_3150_15389)'
      />
      <path
        d='M396.028 58.5353L396.062 57.1223L396.258 56.9389L396.35 58.2333L396.028 58.5353Z'
        fill='url(#paint742_linear_3150_15389)'
      />
      <path
        d='M300.817 130.492L300.852 129.662L301.519 129.284L301.53 130.19L300.817 130.492Z'
        fill='url(#paint743_linear_3150_15389)'
      />
      <path
        d='M295.827 33.0683V31.6122L296.068 31.0728L296.045 32.5937L295.827 33.0683Z'
        fill='url(#paint744_linear_3150_15389)'
      />
      <path
        d='M382.666 75.9771L382.333 75.1897L382.965 74.4778L383.275 75.3407L382.666 75.9771Z'
        fill='url(#paint745_linear_3150_15389)'
      />
      <path
        d='M646.887 79.1268L646.669 77.6598L647.945 77.4333L648.279 78.7924L646.887 79.1268Z'
        fill='url(#paint746_linear_3150_15389)'
      />
      <path
        d='M393.348 61.7389L393.406 60.3259L393.452 60.4661L393.429 61.8468L393.348 61.7389Z'
        fill='url(#paint747_linear_3150_15389)'
      />
      <path
        d='M279.613 125.973L279.602 125.077L280.326 124.646L280.257 125.681L279.613 125.973Z'
        fill='url(#paint748_linear_3150_15389)'
      />
      <path
        d='M250.142 123.761V122.737L250.555 122.456L250.498 123.589L250.142 123.761Z'
        fill='url(#paint749_linear_3150_15389)'
      />
      <path
        d='M406.917 71.5439L406.975 70.3466L407.09 70.4221L407.101 71.5331L406.917 71.5439Z'
        fill='url(#paint750_linear_3150_15389)'
      />
      <path
        d='M690.019 485.067L691.146 484.258L691.606 484.883L690.433 485.628L690.019 485.067Z'
        fill='url(#paint751_linear_3150_15389)'
      />
      <path
        d='M394.176 46.5084L394.199 44.8796H394.326L394.291 46.5299L394.176 46.5084Z'
        fill='url(#paint752_linear_3150_15389)'
      />
      <path
        d='M581.355 63.1735L580.849 61.8683L581.965 61.3829L582.493 62.6665L581.355 63.1735Z'
        fill='url(#paint753_linear_3150_15389)'
      />
      <path
        d='M496.769 85.5016L497.057 84.423L497.08 84.3906L497.045 85.4908L496.769 85.5016Z'
        fill='url(#paint754_linear_3150_15389)'
      />
      <path
        d='M389.761 45.8504L389.795 44.2L389.922 44.1569L389.899 45.7964L389.761 45.8504Z'
        fill='url(#paint755_linear_3150_15389)'
      />
      <path
        d='M428.041 25.5178L427.811 25.5825L427.788 25.507L427.983 25.3452L428.041 25.5178Z'
        fill='url(#paint756_linear_3150_15389)'
      />
      <path
        d='M435.917 25.507L435.779 25.5717L435.756 25.4854L435.871 25.3344L435.917 25.507Z'
        fill='url(#paint757_linear_3150_15389)'
      />
      <path
        d='M420.141 25.4531L419.842 25.5178L419.819 25.4315L420.095 25.2805L420.141 25.4531Z'
        fill='url(#paint758_linear_3150_15389)'
      />
      <path
        d='M560.048 51.8261L559.519 50.5317L560.542 50.014L561.106 51.2868L560.048 51.8261Z'
        fill='url(#paint759_linear_3150_15389)'
      />
      <path
        d='M443.771 25.4099L443.714 25.4854L443.691 25.3991L443.714 25.2373L443.771 25.4099Z'
        fill='url(#paint760_linear_3150_15389)'
      />
      <path
        d='M531.553 171.384L530.921 170.338L531.806 169.755L532.611 170.866L531.553 171.384Z'
        fill='url(#paint761_linear_3150_15389)'
      />
      <path
        d='M412.264 25.3128L411.873 25.3668L411.85 25.2805L412.218 25.1402L412.264 25.3128Z'
        fill='url(#paint762_linear_3150_15389)'
      />
      <path
        d='M679.486 502.034L680.659 501.29L681.039 501.872L679.82 502.552L679.486 502.034Z'
        fill='url(#paint763_linear_3150_15389)'
      />
      <path
        d='M576.077 583.332L577.307 582.997L577.353 583.17L576.1 583.418L576.077 583.332Z'
        fill='url(#paint764_linear_3150_15389)'
      />
      <path
        d='M634.043 546.744L635.296 546.194L635.457 546.604L634.181 547.068L634.043 546.744Z'
        fill='url(#paint765_linear_3150_15389)'
      />
      <path
        d='M387.243 42.938L387.289 41.2877L387.415 41.2014L387.438 42.7654L387.243 42.938Z'
        fill='url(#paint766_linear_3150_15389)'
      />
      <path
        d='M316.835 43.0567L316.605 41.6328L316.686 41.7083L317.065 43.0675L316.835 43.0567Z'
        fill='url(#paint767_linear_3150_15389)'
      />
      <path
        d='M655.983 162.701L656.04 161.644L657.19 161.698L657.064 162.819L655.983 162.701Z'
        fill='url(#paint768_linear_3150_15389)'
      />
      <path
        d='M621.164 208.597L620.578 207.13L621.9 206.785L622.464 208.198L621.164 208.597Z'
        fill='url(#paint769_linear_3150_15389)'
      />
      <path
        d='M404.422 25.0863L403.95 25.1402L403.927 25.0539L404.376 24.9137L404.422 25.0863Z'
        fill='url(#paint770_linear_3150_15389)'
      />
      <path
        d='M437.803 105.155L437.654 104.475L438.263 103.968L438.597 104.572L437.803 105.155Z'
        fill='url(#paint771_linear_3150_15389)'
      />
      <path
        d='M453.154 132.229L452.257 131.56L452.763 130.611L454.074 131.344L453.154 132.229Z'
        fill='url(#paint772_linear_3150_15389)'
      />
      <path
        d='M395.982 44.4697L395.993 42.9164L396.108 42.9704L396.028 44.5991L395.982 44.4697Z'
        fill='url(#paint773_linear_3150_15389)'
      />
      <path
        d='M598.166 572.47L599.431 572.06L599.5 572.319L598.224 572.642L598.166 572.47Z'
        fill='url(#paint774_linear_3150_15389)'
      />
      <path
        d='M699.391 95.1124L699.701 93.365L701.012 93.5591L700.863 94.9722L699.391 95.1124Z'
        fill='url(#paint775_linear_3150_15389)'
      />
      <path
        d='M309.096 34.503L308.947 33.2517L309.338 32.6261L309.234 34.0607L309.096 34.503Z'
        fill='url(#paint776_linear_3150_15389)'
      />
      <path
        d='M283.247 26.1218L283.304 24.5901L283.465 24.0508L283.454 25.5933L283.247 26.1218Z'
        fill='url(#paint777_linear_3150_15389)'
      />
      <path
        d='M336.165 27.7722L335.716 26.2944L335.728 26.2836L336.188 27.729L336.165 27.7722Z'
        fill='url(#paint778_linear_3150_15389)'
      />
      <path
        d='M396.637 24.7735L396.074 24.8274L396.051 24.7411L396.591 24.6009L396.637 24.7735Z'
        fill='url(#paint779_linear_3150_15389)'
      />
      <path
        d='M720.779 278.203L721.112 276.801L722.481 277.156L722.078 278.505L720.779 278.203Z'
        fill='url(#paint780_linear_3150_15389)'
      />
      <path
        d='M667.93 518.419L669.149 517.75L669.448 518.289L668.195 518.882L667.93 518.419Z'
        fill='url(#paint781_linear_3150_15389)'
      />
      <path
        d='M731.967 414.512L732.922 413.466L733.727 414.167L732.726 415.159L731.967 414.512Z'
        fill='url(#paint782_linear_3150_15389)'
      />
      <path
        d='M706.992 177.133L707.509 176.033L708.728 176.313L708.073 177.532L706.992 177.133Z'
        fill='url(#paint783_linear_3150_15389)'
      />
      <path
        d='M388.921 24.396L388.3 24.4391L388.278 24.3528L388.887 24.2234L388.921 24.396Z'
        fill='url(#paint784_linear_3150_15389)'
      />
      <path
        d='M298.138 141.818L298.069 141.063L298.793 140.739L298.816 141.581L298.138 141.818Z'
        fill='url(#paint785_linear_3150_15389)'
      />
      <path
        d='M277.612 139.057L277.589 138.205L278.291 137.859L278.348 138.798L277.612 139.057Z'
        fill='url(#paint786_linear_3150_15389)'
      />
      <path
        d='M620.187 561.122L621.463 560.648L621.578 560.993L620.267 561.371L620.187 561.122Z'
        fill='url(#paint787_linear_3150_15389)'
      />
      <path
        d='M392.486 50.4454L392.44 49.1403L392.682 48.7951L392.889 49.9708L392.486 50.4454Z'
        fill='url(#paint788_linear_3150_15389)'
      />
      <path
        d='M725.447 433.388L726.459 432.396L727.172 433.097L726.114 434.036L725.447 433.388Z'
        fill='url(#paint789_linear_3150_15389)'
      />
      <path
        d='M385.46 38.0517L385.472 36.4769L385.633 36.2719L385.713 37.7605L385.46 38.0517Z'
        fill='url(#paint790_linear_3150_15389)'
      />
      <path
        d='M299.932 133.534L299.863 132.768L300.599 132.347L300.645 133.2L299.932 133.534Z'
        fill='url(#paint791_linear_3150_15389)'
      />
      <path
        d='M243.449 259.262L243.415 257.363L243.679 257.525L243.311 259.143L243.449 259.262Z'
        fill='url(#paint792_linear_3150_15389)'
      />
      <path
        d='M589.657 61.8468L589.128 60.5308L590.267 60.0239L590.83 61.329L589.657 61.8468Z'
        fill='url(#paint793_linear_3150_15389)'
      />
      <path
        d='M399.65 41.3848L399.696 39.8639L399.765 40.0041L399.684 41.5573L399.65 41.3848Z'
        fill='url(#paint794_linear_3150_15389)'
      />
      <path
        d='M397.488 68.3726L397.546 67.2508L397.626 67.3155L397.603 68.3942L397.488 68.3726Z'
        fill='url(#paint795_linear_3150_15389)'
      />
      <path
        d='M567.614 50.6612L567.039 49.3776L568.085 48.8382L568.695 50.111L567.614 50.6612Z'
        fill='url(#paint796_linear_3150_15389)'
      />
      <path
        d='M746.433 313.885L747.031 312.612L748.273 313.151L747.617 314.413L746.433 313.885Z'
        fill='url(#paint797_linear_3150_15389)'
      />
      <path
        d='M385.529 78.0913L385.127 77.4225L385.702 76.743L386.047 77.498L385.529 78.0913Z'
        fill='url(#paint798_linear_3150_15389)'
      />
      <path
        d='M381.332 23.9322L380.619 23.9645L380.596 23.8782L381.286 23.7596L381.332 23.9322Z'
        fill='url(#paint799_linear_3150_15389)'
      />
      <path
        d='M717.789 451.898L718.847 450.96L719.479 451.65L718.364 452.524L717.789 451.898Z'
        fill='url(#paint800_linear_3150_15389)'
      />
      <path
        d='M367.58 89.9996L367.281 89.2985L367.821 88.8454L367.752 89.6976L367.58 89.9996Z'
        fill='url(#paint801_linear_3150_15389)'
      />
      <path
        d='M655.396 534.188L656.65 533.584L656.891 534.059L655.592 534.588L655.396 534.188Z'
        fill='url(#paint802_linear_3150_15389)'
      />
      <path
        d='M415.978 96.5902L415.622 96.0293L416.22 95.3713L416.714 95.889L415.978 96.5902Z'
        fill='url(#paint803_linear_3150_15389)'
      />
      <path
        d='M230.478 246.943L230.386 245.272L230.766 245.401L230.593 246.987L230.478 246.943Z'
        fill='url(#paint804_linear_3150_15389)'
      />
      <path
        d='M547.721 169.237L547.376 168.062L548.261 167.576L548.664 168.752L547.721 169.237Z'
        fill='url(#paint805_linear_3150_15389)'
      />
      <path
        d='M401.179 39.1088L401.271 37.5448L401.26 37.836L401.191 39.3569L401.179 39.1088Z'
        fill='url(#paint806_linear_3150_15389)'
      />
      <path
        d='M647.14 223.612L646.634 222.081L648.06 221.876L648.543 223.353L647.14 223.612Z'
        fill='url(#paint807_linear_3150_15389)'
      />
      <path
        d='M356.61 52.2468L356.081 51.5025L356.69 50.5749L357.173 51.4054L356.61 52.2468Z'
        fill='url(#paint808_linear_3150_15389)'
      />
      <path
        d='M278.601 129.112L278.567 128.249L279.257 127.796L279.314 128.745L278.601 129.112Z'
        fill='url(#paint809_linear_3150_15389)'
      />
      <path
        d='M583.218 585.381L584.506 585.047L584.552 585.23L583.241 585.468L583.218 585.381Z'
        fill='url(#paint810_linear_3150_15389)'
      />
      <path
        d='M550.711 197.196L550.182 195.664L551.102 195.017L551.653 196.484L550.711 197.196Z'
        fill='url(#paint811_linear_3150_15389)'
      />
      <path
        d='M708.993 469.976L710.12 469.103L710.66 469.771L709.487 470.58L708.993 469.976Z'
        fill='url(#paint812_linear_3150_15389)'
      />
      <path
        d='M545.812 40.1227L545.203 38.8607L546.169 38.2998L546.812 39.5295L545.812 40.1227Z'
        fill='url(#paint813_linear_3150_15389)'
      />
      <path
        d='M373.858 23.3928L373.076 23.4252L373.053 23.3389L373.823 23.2202L373.858 23.3928Z'
        fill='url(#paint814_linear_3150_15389)'
      />
      <path
        d='M320.25 135.314L320.089 134.667L320.963 134.181L321.021 134.936L320.25 135.314Z'
        fill='url(#paint815_linear_3150_15389)'
      />
      <path
        d='M366.579 58.3088L365.993 57.6724L366.591 56.7232L367.177 57.4135L366.579 58.3088Z'
        fill='url(#paint816_linear_3150_15389)'
      />
      <path
        d='M605.445 574.735L606.756 574.347L606.825 574.606L605.503 574.908L605.445 574.735Z'
        fill='url(#paint817_linear_3150_15389)'
      />
      <path
        d='M730.208 176.346L731.128 175.116L732.416 175.515L731.369 176.81L730.208 176.346Z'
        fill='url(#paint818_linear_3150_15389)'
      />
      <path
        d='M699.104 487.569L700.276 486.771L700.736 487.407L699.517 488.141L699.104 487.569Z'
        fill='url(#paint819_linear_3150_15389)'
      />
      <path
        d='M738.959 137.309L739.994 135.713L741.465 136.004L740.384 137.579L738.959 137.309Z'
        fill='url(#paint820_linear_3150_15389)'
      />
      <path
        d='M641.897 549.257L643.196 548.729L643.368 549.139L642.035 549.592L641.897 549.257Z'
        fill='url(#paint821_linear_3150_15389)'
      />
      <path
        d='M272.829 17.2337L272.909 15.6913L273.07 15.0549L273.093 16.5758L272.829 17.2337Z'
        fill='url(#paint822_linear_3150_15389)'
      />
      <path
        d='M594.567 157.135L594.303 155.992L595.384 155.754L595.591 156.811L594.567 157.135Z'
        fill='url(#paint823_linear_3150_15389)'
      />
      <path
        d='M342.88 45.3542L342.489 44.4805L342.983 43.6823L343.386 44.5776L342.88 45.3542Z'
        fill='url(#paint824_linear_3150_15389)'
      />
      <path
        d='M492.285 97.2374L492.48 96.2126L492.768 95.7812L492.906 96.9569L492.285 97.2374Z'
        fill='url(#paint825_linear_3150_15389)'
      />
      <path
        d='M398.937 84.6495L398.661 83.9699L399.086 83.4737L399.489 84.0994L398.937 84.6495Z'
        fill='url(#paint826_linear_3150_15389)'
      />
      <path
        d='M394.245 65.8162L394.314 64.7376L394.395 64.7268V65.7623L394.245 65.8162Z'
        fill='url(#paint827_linear_3150_15389)'
      />
      <path
        d='M366.545 22.778L365.682 22.7996L365.659 22.7133L366.499 22.6054L366.545 22.778Z'
        fill='url(#paint828_linear_3150_15389)'
      />
      <path
        d='M740.097 162.323L741.212 160.921L742.604 161.309L741.373 162.744L740.097 162.323Z'
        fill='url(#paint829_linear_3150_15389)'
      />
      <path
        d='M761.278 346.978L762.198 345.845L763.244 346.611L762.336 347.7L761.278 346.978Z'
        fill='url(#paint830_linear_3150_15389)'
      />
      <path
        d='M622.234 207.26L621.417 205.825L622.774 205.448L623.602 206.828L622.234 207.26Z'
        fill='url(#paint831_linear_3150_15389)'
      />
      <path
        d='M740.407 407.706L740.994 406.401L741.879 407.102L741.339 408.223L740.407 407.706Z'
        fill='url(#paint832_linear_3150_15389)'
      />
      <path
        d='M329.139 141.149L329.185 140.545L329.3 140.416L329.334 141.031L329.139 141.149Z'
        fill='url(#paint833_linear_3150_15389)'
      />
      <path
        d='M688.134 504.623L689.352 503.889L689.732 504.482L688.467 505.151L688.134 504.623Z'
        fill='url(#paint834_linear_3150_15389)'
      />
      <path
        d='M315.409 140.739L315.225 140.103L316.019 139.682L316.042 140.437L315.409 140.739Z'
        fill='url(#paint835_linear_3150_15389)'
      />
      <path
        d='M575.215 49.3776L574.617 48.0832L575.698 47.5331L576.33 48.8059L575.215 49.3776Z'
        fill='url(#paint836_linear_3150_15389)'
      />
      <path
        d='M376.399 83.8944L375.997 83.2688L376.572 82.7079L376.687 83.4953L376.399 83.8944Z'
        fill='url(#paint837_linear_3150_15389)'
      />
      <path
        d='M401.237 73.7982L401.271 72.8383L401.421 72.7088L401.582 73.5502L401.237 73.7982Z'
        fill='url(#paint838_linear_3150_15389)'
      />
      <path
        d='M524.482 30.2099L523.849 28.9803L524.723 28.387L525.402 29.5951L524.482 30.2099Z'
        fill='url(#paint839_linear_3150_15389)'
      />
      <path
        d='M333.52 137.46L333.462 136.856L334.06 136.479L334.21 137.126L333.52 137.46Z'
        fill='url(#paint840_linear_3150_15389)'
      />
      <path
        d='M251.36 240.655L251.245 239.091L251.671 239.102L251.625 240.644L251.36 240.655Z'
        fill='url(#paint841_linear_3150_15389)'
      />
      <path
        d='M586.104 195.416L585.334 194.1L586.415 193.442L587.369 194.899L586.104 195.416Z'
        fill='url(#paint842_linear_3150_15389)'
      />
      <path
        d='M397.074 61.0162L396.879 60.0562L397.201 59.6248L397.465 60.5524L397.074 61.0162Z'
        fill='url(#paint843_linear_3150_15389)'
      />
      <path
        d='M597.465 60.4338L596.913 59.1178L598.074 58.6L598.661 59.8944L597.465 60.4338Z'
        fill='url(#paint844_linear_3150_15389)'
      />
      <path
        d='M306.452 148.473L305.543 149.714L305.267 148.808L306.061 147.675L306.452 148.473Z'
        fill='url(#paint845_linear_3150_15389)'
      />
      <path
        d='M394.717 170.737L392.877 170.877L392.9 169.755L395.292 169.464L394.717 170.737Z'
        fill='url(#paint846_linear_3150_15389)'
      />
      <path
        d='M406.147 80.8418L405.986 80.0544L406.296 79.7093L406.63 80.3996L406.147 80.8418Z'
        fill='url(#paint847_linear_3150_15389)'
      />
      <path
        d='M373.846 63.9178L373.225 63.3893L373.789 62.4616L374.398 63.0441L373.846 63.9178Z'
        fill='url(#paint848_linear_3150_15389)'
      />
      <path
        d='M321.159 34.7403L320.917 33.694L321.354 32.982L321.412 34.1578L321.159 34.7403Z'
        fill='url(#paint849_linear_3150_15389)'
      />
      <path
        d='M378.779 31.4288V29.9403L378.929 29.6814L379.055 31.062L378.779 31.4288Z'
        fill='url(#paint850_linear_3150_15389)'
      />
      <path
        d='M252.28 132.995V132.056L252.752 131.679L252.821 132.703L252.28 132.995Z'
        fill='url(#paint851_linear_3150_15389)'
      />
      <path
        d='M553.102 38.9794L552.458 37.7173L553.447 37.1456L554.137 38.3753L553.102 38.9794Z'
        fill='url(#paint852_linear_3150_15389)'
      />
      <path
        d='M430.547 15.4863L430.317 15.4755L430.271 15.3029L430.467 15.2274L430.547 15.4863Z'
        fill='url(#paint853_linear_3150_15389)'
      />
      <path
        d='M423.798 15.4647L423.487 15.4432L423.43 15.2706L423.717 15.2059L423.798 15.4647Z'
        fill='url(#paint854_linear_3150_15389)'
      />
      <path
        d='M437.286 15.4432H437.148L437.09 15.2706L437.205 15.1843L437.286 15.4432Z'
        fill='url(#paint855_linear_3150_15389)'
      />
      <path
        d='M627.477 563.593L628.799 563.14L628.926 563.474L627.569 563.851L627.477 563.593Z'
        fill='url(#paint856_linear_3150_15389)'
      />
      <path
        d='M395.338 100.214L394.682 99.9232L395.303 99.0818L395.97 99.4054L395.338 100.214Z'
        fill='url(#paint857_linear_3150_15389)'
      />
      <path
        d='M417.048 15.3677L416.645 15.3461L416.599 15.1735L416.979 15.098L417.048 15.3677Z'
        fill='url(#paint858_linear_3150_15389)'
      />
      <path
        d='M411.827 104.82L411.275 104.497L411.908 103.698L412.575 103.979L411.827 104.82Z'
        fill='url(#paint859_linear_3150_15389)'
      />
      <path
        d='M538.97 184.684L538.395 183.303L539.292 182.688L539.867 183.983L538.97 184.684Z'
        fill='url(#paint860_linear_3150_15389)'
      />
      <path
        d='M676.129 521.061L677.394 520.403L677.693 520.943L676.382 521.536L676.129 521.061Z'
        fill='url(#paint861_linear_3150_15389)'
      />
      <path
        d='M359.404 22.0877L358.461 22.0985L358.449 22.0122L359.369 21.9151L359.404 22.0877Z'
        fill='url(#paint862_linear_3150_15389)'
      />
      <path
        d='M612.46 192.935L611.724 191.684L612.954 191.199L613.816 192.536L612.46 192.935Z'
        fill='url(#paint863_linear_3150_15389)'
      />
      <path
        d='M589.876 587.539L591.21 587.226L591.256 587.398L589.899 587.625L589.876 587.539Z'
        fill='url(#paint864_linear_3150_15389)'
      />
      <path
        d='M250.257 141.915L250.176 140.977L250.716 140.642V141.678L250.257 141.915Z'
        fill='url(#paint865_linear_3150_15389)'
      />
      <path
        d='M263.653 254.451L263.814 252.671L263.94 252.585L263.664 254.332L263.653 254.451Z'
        fill='url(#paint866_linear_3150_15389)'
      />
      <path
        d='M391.313 55.9034L390.991 55.0081L391.359 54.4257L391.785 55.2562L391.313 55.9034Z'
        fill='url(#paint867_linear_3150_15389)'
      />
      <path
        d='M550.573 169.647L550.067 168.385L551.033 167.911L551.481 169.043L550.573 169.647Z'
        fill='url(#paint868_linear_3150_15389)'
      />
      <path
        d='M712.316 269.628L712.5 268.182L713.88 268.355L713.673 269.811L712.316 269.628Z'
        fill='url(#paint869_linear_3150_15389)'
      />
      <path
        d='M382.195 42.1937L382.045 41.018L382.298 40.5865L382.574 41.676L382.195 42.1937Z'
        fill='url(#paint870_linear_3150_15389)'
      />
      <path
        d='M410.321 15.1951L409.838 15.1735L409.792 14.9901L410.252 14.9254L410.321 15.1951Z'
        fill='url(#paint871_linear_3150_15389)'
      />
      <path
        d='M562.29 197.142L561.899 195.524L562.807 194.812L563.267 196.387L562.29 197.142Z'
        fill='url(#paint872_linear_3150_15389)'
      />
      <path
        d='M472.852 114.485L472.541 113.525L473.093 112.824L473.611 113.849L472.852 114.485Z'
        fill='url(#paint873_linear_3150_15389)'
      />
      <path
        d='M383.701 95.9646L383.08 95.5762L383.678 94.8212L384.253 95.2634L383.701 95.9646Z'
        fill='url(#paint874_linear_3150_15389)'
      />
      <path
        d='M684.971 243.567L684.833 242.046L686.282 242.09L686.42 243.578L684.971 243.567Z'
        fill='url(#paint875_linear_3150_15389)'
      />
      <path
        d='M330.91 39.0225L330.599 38.0948L331.036 37.329L331.335 38.2998L330.91 39.0225Z'
        fill='url(#paint876_linear_3150_15389)'
      />
      <path
        d='M394.015 63.0225L394.004 62.0086L394.176 61.836L394.245 62.8284L394.015 63.0225Z'
        fill='url(#paint877_linear_3150_15389)'
      />
      <path
        d='M403.628 14.947L403.077 14.9254L403.031 14.742L403.571 14.6881L403.628 14.947Z'
        fill='url(#paint878_linear_3150_15389)'
      />
      <path
        d='M253.591 122.316L253.58 121.367L254.028 120.903L254.074 121.928L253.591 122.316Z'
        fill='url(#paint879_linear_3150_15389)'
      />
      <path
        d='M385.426 79.9466L385.035 79.3317L385.495 78.7493L385.886 79.3857L385.426 79.9466Z'
        fill='url(#paint880_linear_3150_15389)'
      />
      <path
        d='M377.308 56.7016L376.71 56.1084L377.181 55.2346L377.859 55.774L377.308 56.7016Z'
        fill='url(#paint881_linear_3150_15389)'
      />
      <path
        d='M388.461 89.9565L387.967 89.4818L388.438 88.8131L389.071 89.2122L388.461 89.9565Z'
        fill='url(#paint882_linear_3150_15389)'
      />
      <path
        d='M570.374 197.282L569.776 195.74L570.765 195.05L571.443 196.581L570.374 197.282Z'
        fill='url(#paint883_linear_3150_15389)'
      />
      <path
        d='M745.271 427.208L745.835 425.849L746.743 426.561L746.157 427.801L745.271 427.208Z'
        fill='url(#paint884_linear_3150_15389)'
      />
      <path
        d='M258.444 223.957L258.26 222.62L258.789 222.534L258.881 223.903L258.444 223.957Z'
        fill='url(#paint885_linear_3150_15389)'
      />
      <path
        d='M531.335 29.2283L530.668 27.9987L531.553 27.4054L532.278 28.6027L531.335 29.2283Z'
        fill='url(#paint886_linear_3150_15389)'
      />
      <path
        d='M663.101 536.842L664.4 536.249L664.63 536.734L663.285 537.252L663.101 536.842Z'
        fill='url(#paint887_linear_3150_15389)'
      />
      <path
        d='M397.017 14.6342L396.373 14.6018L396.338 14.4292L396.959 14.3753L397.017 14.6342Z'
        fill='url(#paint888_linear_3150_15389)'
      />
      <path
        d='M217.807 234.248L217.749 232.63L218.06 232.641L217.795 234.151L217.807 234.248Z'
        fill='url(#paint889_linear_3150_15389)'
      />
      <path
        d='M612.172 577.119L613.529 576.741L613.598 577.011L612.218 577.302L612.172 577.119Z'
        fill='url(#paint890_linear_3150_15389)'
      />
      <path
        d='M352.458 21.3326H351.458L351.435 21.2355L352.424 21.1492L352.458 21.3326Z'
        fill='url(#paint891_linear_3150_15389)'
      />
      <path
        d='M273.576 141.613L273.392 140.815L274.151 140.362L274.197 141.268L273.576 141.613Z'
        fill='url(#paint892_linear_3150_15389)'
      />
      <path
        d='M505.842 20.351L505.221 19.1214L506.014 18.5389L506.67 19.7362L505.842 20.351Z'
        fill='url(#paint893_linear_3150_15389)'
      />
      <path
        d='M734.762 435.567L735.808 434.575L736.532 435.287L735.44 436.236L734.762 435.567Z'
        fill='url(#paint894_linear_3150_15389)'
      />
      <path
        d='M384.034 62.1165L383.528 61.4908L383.954 60.7573L384.529 61.3614L384.034 62.1165Z'
        fill='url(#paint895_linear_3150_15389)'
      />
      <path
        d='M401.087 20.5344L401.122 18.9488L401.248 18.9164L401.202 20.5128L401.087 20.5344Z'
        fill='url(#paint896_linear_3150_15389)'
      />
      <path
        d='M239.034 229.005L238.988 227.571L239.344 227.452L239.356 228.962L239.034 229.005Z'
        fill='url(#paint897_linear_3150_15389)'
      />
      <path
        d='M390.485 14.2566L389.761 14.2135L389.726 14.0301L390.428 13.987L390.485 14.2566Z'
        fill='url(#paint898_linear_3150_15389)'
      />
      <path
        d='M582.367 48.0077L581.735 46.7133L582.838 46.1524L583.505 47.4252L582.367 48.0077Z'
        fill='url(#paint899_linear_3150_15389)'
      />
      <path
        d='M396.143 20.3295L396.189 18.7223L396.304 18.6683L396.281 20.254L396.143 20.3295Z'
        fill='url(#paint900_linear_3150_15389)'
      />
      <path
        d='M648.462 221.638L647.888 220.161L649.279 219.891L649.9 221.401L648.462 221.638Z'
        fill='url(#paint901_linear_3150_15389)'
      />
      <path
        d='M377.158 68.6315L376.572 68.1353L377.032 67.3371L377.664 67.8333L377.158 68.6315Z'
        fill='url(#paint902_linear_3150_15389)'
      />
      <path
        d='M560.002 37.7605L559.323 36.4985L560.335 35.9052L561.048 37.1456L560.002 37.7605Z'
        fill='url(#paint903_linear_3150_15389)'
      />
      <path
        d='M726.781 454.26L727.885 453.322L728.529 454.023L727.368 454.908L726.781 454.26Z'
        fill='url(#paint904_linear_3150_15389)'
      />
      <path
        d='M649.095 551.9L650.429 551.382L650.601 551.803L649.221 552.234L649.095 551.9Z'
        fill='url(#paint905_linear_3150_15389)'
      />
      <path
        d='M765.648 369.629L766.165 368.346L767.338 368.971L766.728 370.277L765.648 369.629Z'
        fill='url(#paint906_linear_3150_15389)'
      />
      <path
        d='M351.999 119.134L351.021 119.663L351.228 118.605L352.148 118.163L351.999 119.134Z'
        fill='url(#paint907_linear_3150_15389)'
      />
      <path
        d='M361.715 49.3452L361.037 48.8274L361.439 47.8998L362.256 48.3097L361.715 49.3452Z'
        fill='url(#paint908_linear_3150_15389)'
      />
      <path
        d='M604.755 58.9344L604.18 57.6185L605.365 57.0899L605.986 58.3843L604.755 58.9344Z'
        fill='url(#paint909_linear_3150_15389)'
      />
      <path
        d='M293.044 142.498L292.894 141.796L293.607 141.333L293.791 142.109L293.044 142.498Z'
        fill='url(#paint910_linear_3150_15389)'
      />
      <path
        d='M384.057 13.8036L383.264 13.7497L383.229 13.5771L384.011 13.5447L384.057 13.8036Z'
        fill='url(#paint911_linear_3150_15389)'
      />
      <path
        d='M717.651 472.49L718.813 471.616L719.365 472.295L718.146 473.104L717.651 472.49Z'
        fill='url(#paint912_linear_3150_15389)'
      />
      <path
        d='M270.909 235.887L270.92 234.377L271.173 234.248L271.035 235.725L270.909 235.887Z'
        fill='url(#paint913_linear_3150_15389)'
      />
      <path
        d='M390.991 18.9811L391.049 17.3955L391.152 17.3092L391.129 18.8733L390.991 18.9811Z'
        fill='url(#paint914_linear_3150_15389)'
      />
      <path
        d='M678.176 240.979L678.003 239.458L679.383 239.339L679.567 240.86L678.176 240.979Z'
        fill='url(#paint915_linear_3150_15389)'
      />
      <path
        d='M366.43 95.1016L366.384 94.325L366.591 94.0877L366.372 94.8535L366.43 95.1016Z'
        fill='url(#paint916_linear_3150_15389)'
      />
      <path
        d='M345.743 20.4913L344.662 20.4805L344.651 20.3942L345.709 20.3187L345.743 20.4913Z'
        fill='url(#paint917_linear_3150_15389)'
      />
      <path
        d='M537.866 28.1821L537.153 26.9524L538.073 26.3375L538.832 27.5349L537.866 28.1821Z'
        fill='url(#paint918_linear_3150_15389)'
      />
      <path
        d='M404.042 18.938L404.123 17.3848L404.215 17.4387L404.1 19.0135L404.042 18.938Z'
        fill='url(#paint919_linear_3150_15389)'
      />
      <path
        d='M596.016 589.804L597.396 589.502L597.431 589.685L596.028 589.89L596.016 589.804Z'
        fill='url(#paint920_linear_3150_15389)'
      />
      <path
        d='M510.143 20.1245L509.441 18.9272L510.246 18.3124L510.993 19.4773L510.143 20.1245Z'
        fill='url(#paint921_linear_3150_15389)'
      />
      <path
        d='M707.383 490.201L708.59 489.403L709.05 490.05L707.797 490.784L707.383 490.201Z'
        fill='url(#paint922_linear_3150_15389)'
      />
      <path
        d='M619.14 205.955L618.278 204.552L619.566 204.11L620.474 205.502L619.14 205.955Z'
        fill='url(#paint923_linear_3150_15389)'
      />
      <path
        d='M584.874 180.887L584.161 179.722L585.184 179.064L586.116 180.401L584.874 180.887Z'
        fill='url(#paint924_linear_3150_15389)'
      />
      <path
        d='M372.271 24.5038L372.214 23.1339L372.375 22.7888L372.57 24.0508L372.271 24.5038Z'
        fill='url(#paint925_linear_3150_15389)'
      />
      <path
        d='M763.969 410.014L764.555 408.677L765.602 409.388L764.923 410.715L763.969 410.014Z'
        fill='url(#paint926_linear_3150_15389)'
      />
      <path
        d='M634.146 566.181L635.515 565.739L635.63 566.084L634.227 566.44L634.146 566.181Z'
        fill='url(#paint927_linear_3150_15389)'
      />
      <path
        d='M372.685 34.9991L372.421 33.942L372.685 33.4135L373.11 34.3304L372.685 34.9991Z'
        fill='url(#paint928_linear_3150_15389)'
      />
      <path
        d='M442.92 127.601L442.322 126.889L442.725 125.973L443.725 126.792L442.92 127.601Z'
        fill='url(#paint929_linear_3150_15389)'
      />
      <path
        d='M377.768 13.2859L376.894 13.2212L376.859 13.0486L377.722 13.0162L377.768 13.2859Z'
        fill='url(#paint930_linear_3150_15389)'
      />
      <path
        d='M391.118 180.218L389.186 180.412L389.255 179.301L391.589 178.924L391.118 180.218Z'
        fill='url(#paint931_linear_3150_15389)'
      />
      <path
        d='M376.008 48.245L375.548 47.4791L375.836 46.8211L376.526 47.3713L376.008 48.245Z'
        fill='url(#paint932_linear_3150_15389)'
      />
      <path
        d='M626.741 191.716L626.143 190.454L627.408 190.055L628.075 191.371L626.741 191.716Z'
        fill='url(#paint933_linear_3150_15389)'
      />
      <path
        d='M695.999 507.341L697.264 506.607L697.643 507.211L696.332 507.869L695.999 507.341Z'
        fill='url(#paint934_linear_3150_15389)'
      />
      <path
        d='M276.922 219.567L276.842 218.294L277.198 218.122V219.395L276.922 219.567Z'
        fill='url(#paint935_linear_3150_15389)'
      />
      <path
        d='M278.992 184.231L278.866 183.303L279.337 183.055L279.429 184.036L278.992 184.231Z'
        fill='url(#paint936_linear_3150_15389)'
      />
      <path
        d='M386.07 17.0396L386.127 15.4755L386.219 15.3569V16.8886L386.07 17.0396Z'
        fill='url(#paint937_linear_3150_15389)'
      />
      <path
        d='M772.535 413.045L773.628 411.977L774.41 412.905L773.34 413.832L772.535 413.045Z'
        fill='url(#paint938_linear_3150_15389)'
      />
      <path
        d='M671.598 63.7236L671.38 62.0301L672.679 61.8144L673.047 63.0764L671.598 63.7236Z'
        fill='url(#paint939_linear_3150_15389)'
      />
      <path
        d='M544.743 186.032L544.191 184.49L545.099 183.842L545.617 185.212L544.743 186.032Z'
        fill='url(#paint940_linear_3150_15389)'
      />
      <path
        d='M587.369 155.193L587.07 154.028L588.116 153.662L588.427 154.783L587.369 155.193Z'
        fill='url(#paint941_linear_3150_15389)'
      />
      <path
        d='M617.048 178.622L616.53 177.435L617.703 176.993L618.312 178.266L617.048 178.622Z'
        fill='url(#paint942_linear_3150_15389)'
      />
      <path
        d='M781.263 385.335L782.194 384.234L783.195 385.065L782.16 386.165L781.263 385.335Z'
        fill='url(#paint943_linear_3150_15389)'
      />
      <path
        d='M389.14 75.3731L388.806 74.7367L389.14 74.2513L389.577 74.8337L389.14 75.3731Z'
        fill='url(#paint944_linear_3150_15389)'
      />
      <path
        d='M566.476 36.4661L565.774 35.2041L566.798 34.6L567.545 35.8297L566.476 36.4661Z'
        fill='url(#paint945_linear_3150_15389)'
      />
      <path
        d='M339.269 19.596L338.131 19.5636L338.119 19.4773L339.246 19.4126L339.269 19.596Z'
        fill='url(#paint946_linear_3150_15389)'
      />
      <path
        d='M371.616 12.7034L370.673 12.6279L370.65 12.4553L371.581 12.4337L371.616 12.7034Z'
        fill='url(#paint947_linear_3150_15389)'
      />
      <path
        d='M204.801 221.541L204.675 219.945L205.031 219.912L204.663 221.326L204.801 221.541Z'
        fill='url(#paint948_linear_3150_15389)'
      />
      <path
        d='M739.419 378.647L740.362 377.504L741.293 378.248L740.281 379.316L739.419 378.647Z'
        fill='url(#paint949_linear_3150_15389)'
      />
      <path
        d='M262.077 186.118L261.997 185.137L262.434 184.867L262.549 185.924L262.077 186.118Z'
        fill='url(#paint950_linear_3150_15389)'
      />
      <path
        d='M349.101 112.975L348.1 113.212L348.445 112.241L349.354 112.069L349.101 112.975Z'
        fill='url(#paint951_linear_3150_15389)'
      />
      <path
        d='M683.557 523.833L684.856 523.165L685.155 523.726L683.81 524.308L683.557 523.833Z'
        fill='url(#paint952_linear_3150_15389)'
      />
      <path
        d='M516.179 19.2508L515.444 18.0535L516.26 17.4387L517.053 18.5928L516.179 19.2508Z'
        fill='url(#paint953_linear_3150_15389)'
      />
      <path
        d='M263.043 5.29305L263.204 3.79372L263.296 3.08181L263.377 4.47328L263.043 5.29305Z'
        fill='url(#paint954_linear_3150_15389)'
      />
      <path
        d='M589.036 46.5623L588.381 45.2679L589.496 44.6854L590.198 45.9582L589.036 46.5623Z'
        fill='url(#paint955_linear_3150_15389)'
      />
      <path
        d='M618.301 579.621L619.704 579.255L619.773 579.524L618.347 579.794L618.301 579.621Z'
        fill='url(#paint956_linear_3150_15389)'
      />
      <path
        d='M641.08 148.872L640.896 147.794L642.081 147.6L642.253 148.7L641.08 148.872Z'
        fill='url(#paint957_linear_3150_15389)'
      />
      <path
        d='M544.041 27.0495L543.294 25.8198L544.225 25.205L545.019 26.4023L544.041 27.0495Z'
        fill='url(#paint958_linear_3150_15389)'
      />
      <path
        d='M384.138 65.1259L383.701 64.5003L384.069 63.9286L384.517 64.5542L384.138 65.1259Z'
        fill='url(#paint959_linear_3150_15389)'
      />
      <path
        d='M343.294 42.2693L342.489 41.9133L342.822 40.9209L343.765 41.1475L343.294 42.2693Z'
        fill='url(#paint960_linear_3150_15389)'
      />
      <path
        d='M370.535 92.2108L370.144 91.6823L370.569 91.1214L370.949 91.6607L370.535 92.2108Z'
        fill='url(#paint961_linear_3150_15389)'
      />
      <path
        d='M507.406 6.59823L507.256 5.16362L507.935 4.9371L507.969 6.31777L507.406 6.59823Z'
        fill='url(#paint962_linear_3150_15389)'
      />
      <path
        d='M243.012 188.329L242.886 187.251L243.403 186.97L243.484 188.114L243.012 188.329Z'
        fill='url(#paint963_linear_3150_15389)'
      />
      <path
        d='M378.526 72.0724L378.067 71.5223L378.423 70.9183L378.963 71.4252L378.526 72.0724Z'
        fill='url(#paint964_linear_3150_15389)'
      />
      <path
        d='M729.714 274.406L730.001 273.004L731.381 273.252L731.082 274.697L729.714 274.406Z'
        fill='url(#paint965_linear_3150_15389)'
      />
      <path
        d='M670.069 539.625L671.414 539.042L671.644 539.539L670.253 540.035L670.069 539.625Z'
        fill='url(#paint966_linear_3150_15389)'
      />
      <path
        d='M646.588 220.473L646.094 218.942L647.416 218.618L647.922 220.117L646.588 220.473Z'
        fill='url(#paint967_linear_3150_15389)'
      />
      <path
        d='M547.514 171.697L546.927 170.359L547.882 169.787L548.422 170.985L547.514 171.697Z'
        fill='url(#paint968_linear_3150_15389)'
      />
      <path
        d='M365.636 12.0562L364.624 11.9699L364.601 11.7865H365.602L365.636 12.0562Z'
        fill='url(#paint969_linear_3150_15389)'
      />
      <path
        d='M560.025 185.201L559.3 183.864L560.22 183.098L561.117 184.543L560.025 185.201Z'
        fill='url(#paint970_linear_3150_15389)'
      />
      <path
        d='M289.353 192.914L289.433 191.965L289.583 191.695L289.606 192.741L289.353 192.914Z'
        fill='url(#paint971_linear_3150_15389)'
      />
      <path
        d='M362.187 28.2791L361.715 27.4162L362.003 26.7151L362.612 27.4486L362.187 28.2791Z'
        fill='url(#paint972_linear_3150_15389)'
      />
      <path
        d='M254.753 165.645L254.626 164.729L255.144 164.34L255.224 165.333L254.753 165.645Z'
        fill='url(#paint973_linear_3150_15389)'
      />
      <path
        d='M489.744 11.6787L489.019 10.5137L489.732 9.88811L490.503 11.0207L489.744 11.6787Z'
        fill='url(#paint974_linear_3150_15389)'
      />
      <path
        d='M283.58 173.703L283.477 172.872L283.902 172.549L284.052 173.433L283.58 173.703Z'
        fill='url(#paint975_linear_3150_15389)'
      />
      <path
        d='M701.127 161.676L701.495 160.565L702.772 160.684L702.312 161.892L701.127 161.676Z'
        fill='url(#paint976_linear_3150_15389)'
      />
      <path
        d='M381.953 13.8144L382.011 12.2935L382.103 12.1209L382.172 13.5447L381.953 13.8144Z'
        fill='url(#paint977_linear_3150_15389)'
      />
      <path
        d='M601.593 592.166L603.019 591.896L603.065 592.069L601.616 592.263L601.593 592.166Z'
        fill='url(#paint978_linear_3150_15389)'
      />
      <path
        d='M333.071 18.6252L331.864 18.582L331.853 18.4958L333.048 18.4418L333.071 18.6252Z'
        fill='url(#paint979_linear_3150_15389)'
      />
      <path
        d='M686.972 241.928L686.857 240.428L688.249 240.288L688.375 241.831L686.972 241.928Z'
        fill='url(#paint980_linear_3150_15389)'
      />
      <path
        d='M240.494 33.6077L241.31 32.6153L241.529 32.9389L240.885 33.9528L240.494 33.6077Z'
        fill='url(#paint981_linear_3150_15389)'
      />
      <path
        d='M368.718 86.1165L368.177 85.6742L368.637 85.027L369.097 85.5016L368.718 86.1165Z'
        fill='url(#paint982_linear_3150_15389)'
      />
      <path
        d='M521.929 18.3124L521.147 17.1151L521.986 16.4895L522.826 17.6436L521.929 18.3124Z'
        fill='url(#paint983_linear_3150_15389)'
      />
      <path
        d='M309.878 150.134L309.131 150.911L308.889 150.296L309.602 149.455L309.878 150.134Z'
        fill='url(#paint984_linear_3150_15389)'
      />
      <path
        d='M655.58 554.661L656.972 554.154L657.133 554.575L655.718 554.996L655.58 554.661Z'
        fill='url(#paint985_linear_3150_15389)'
      />
      <path
        d='M273.68 195.211L273.576 194.219L273.99 193.917L274.174 194.996L273.68 195.211Z'
        fill='url(#paint986_linear_3150_15389)'
      />
      <path
        d='M389.979 67.4126L389.818 66.636L390.048 66.3016L390.37 66.9919L389.979 67.4126Z'
        fill='url(#paint987_linear_3150_15389)'
      />
      <path
        d='M549.193 187.154L548.56 185.654L549.446 184.899L550.159 186.377L549.193 187.154Z'
        fill='url(#paint988_linear_3150_15389)'
      />
      <path
        d='M272.76 164.739L272.564 163.898L273.128 163.499L273.22 164.383L272.76 164.739Z'
        fill='url(#paint989_linear_3150_15389)'
      />
      <path
        d='M359.852 11.3443L358.771 11.2472L358.748 11.0639L359.818 11.0746L359.852 11.3443Z'
        fill='url(#paint990_linear_3150_15389)'
      />
      <path
        d='M313.224 8.14069L312.81 6.59823L312.868 6.57665L313.293 8.08676L313.224 8.14069Z'
        fill='url(#paint991_linear_3150_15389)'
      />
      <path
        d='M572.501 35.0962L571.765 33.8342L572.811 33.2194L573.593 34.449L572.501 35.0962Z'
        fill='url(#paint992_linear_3150_15389)'
      />
      <path
        d='M267.574 174.663L267.447 173.757L267.907 173.401L267.999 174.35L267.574 174.663Z'
        fill='url(#paint993_linear_3150_15389)'
      />
      <path
        d='M400.064 5.50878L399.328 5.37935L399.282 5.10969L399.995 5.15282L400.064 5.50878Z'
        fill='url(#paint994_linear_3150_15389)'
      />
      <path
        d='M221.417 190.605L221.36 189.451L221.808 189.095L221.923 190.357L221.417 190.605Z'
        fill='url(#paint995_linear_3150_15389)'
      />
      <path
        d='M324.424 136.996L324.102 136.5L324.827 135.972L324.953 136.554L324.424 136.996Z'
        fill='url(#paint996_linear_3150_15389)'
      />
      <path
        d='M549.825 25.8629L549.043 24.6333L549.998 23.9969L550.826 25.1942L549.825 25.8629Z'
        fill='url(#paint997_linear_3150_15389)'
      />
      <path
        d='M357.185 41.6436L356.46 41.1906L356.725 40.36L357.587 40.6728L357.185 41.6436Z'
        fill='url(#paint998_linear_3150_15389)'
      />
      <path
        d='M495.182 10.9991L494.412 9.83418L495.148 9.19777L495.953 10.3304L495.182 10.9991Z'
        fill='url(#paint999_linear_3150_15389)'
      />
      <path
        d='M629.662 190.81L629.167 189.505L630.398 189.138L630.927 190.433L629.662 190.81Z'
        fill='url(#paint1000_linear_3150_15389)'
      />
      <path
        d='M640.137 568.878L641.563 568.446L641.678 568.802L640.229 569.137L640.137 568.878Z'
        fill='url(#paint1001_linear_3150_15389)'
      />
      <path
        d='M365.05 17.7299L364.866 16.5218L365.038 16.0688L365.418 17.1043L365.05 17.7299Z'
        fill='url(#paint1002_linear_3150_15389)'
      />
      <path
        d='M249.348 175.936L249.233 174.965L249.716 174.566L249.854 175.612L249.348 175.936Z'
        fill='url(#paint1003_linear_3150_15389)'
      />
      <path
        d='M631.283 178.276L630.846 177.047L632.077 176.712L632.525 177.942L631.283 178.276Z'
        fill='url(#paint1004_linear_3150_15389)'
      />
      <path
        d='M214.012 205.027L213.874 203.711L214.38 203.42L214.369 204.768L214.012 205.027Z'
        fill='url(#paint1005_linear_3150_15389)'
      />
      <path
        d='M266.665 209.029L266.481 207.896L266.964 207.583L267.137 208.77L266.665 209.029Z'
        fill='url(#paint1006_linear_3150_15389)'
      />
      <path
        d='M394.717 5.16362L393.912 5.02339L393.877 4.75373L394.659 4.80766L394.717 5.16362Z'
        fill='url(#paint1007_linear_3150_15389)'
      />
      <path
        d='M583.287 155.344L582.643 154.201L583.724 153.726L584.345 154.783L583.287 155.344Z'
        fill='url(#paint1008_linear_3150_15389)'
      />
      <path
        d='M294.573 182.181L294.665 181.297L294.769 181.005L294.7 181.944L294.573 182.181Z'
        fill='url(#paint1009_linear_3150_15389)'
      />
      <path
        d='M595.188 45.0414L594.498 43.747L595.637 43.1537L596.361 44.4266L595.188 45.0414Z'
        fill='url(#paint1010_linear_3150_15389)'
      />
      <path
        d='M734.888 456.731L736.026 455.792L736.67 456.504L735.474 457.388L734.888 456.731Z'
        fill='url(#paint1011_linear_3150_15389)'
      />
      <path
        d='M731.438 168.137L732.278 166.918L733.612 167.22L732.657 168.493L731.438 168.137Z'
        fill='url(#paint1012_linear_3150_15389)'
      />
      <path
        d='M236.676 201.273L236.561 200.098L237.021 199.763L237.102 200.993L236.676 201.273Z'
        fill='url(#paint1013_linear_3150_15389)'
      />
      <path
        d='M616.116 205.07L615.357 203.603L616.565 203.107L617.37 204.552L616.116 205.07Z'
        fill='url(#paint1014_linear_3150_15389)'
      />
      <path
        d='M228.88 216.946L228.8 215.576L229.202 215.285L229.133 216.666L228.88 216.946Z'
        fill='url(#paint1015_linear_3150_15389)'
      />
      <path
        d='M388.013 108.649L387.415 108.412L387.829 107.819L388.369 108.089L388.013 108.649Z'
        fill='url(#paint1016_linear_3150_15389)'
      />
      <path
        d='M256.581 198.081L256.454 196.991L256.903 196.657L256.972 197.789L256.581 198.081Z'
        fill='url(#paint1017_linear_3150_15389)'
      />
      <path
        d='M327.161 17.5897L325.908 17.5358L325.896 17.4387L327.138 17.4063L327.161 17.5897Z'
        fill='url(#paint1018_linear_3150_15389)'
      />
      <path
        d='M527.368 17.3092L526.54 16.1227L527.402 15.4755L528.265 16.6297L527.368 17.3092Z'
        fill='url(#paint1019_linear_3150_15389)'
      />
      <path
        d='M580.274 167.997L579.584 166.821L580.619 166.228L581.39 167.436L580.274 167.997Z'
        fill='url(#paint1020_linear_3150_15389)'
      />
      <path
        d='M725.424 475.111L726.632 474.237L727.172 474.938L725.919 475.747L725.424 475.111Z'
        fill='url(#paint1021_linear_3150_15389)'
      />
      <path
        d='M354.275 10.5677L353.125 10.4598L353.114 10.2764L354.252 10.298L354.275 10.5677Z'
        fill='url(#paint1022_linear_3150_15389)'
      />
      <path
        d='M284.684 205.707L284.696 204.65L284.937 204.326L285.018 205.469L284.684 205.707Z'
        fill='url(#paint1023_linear_3150_15389)'
      />
      <path
        d='M366.924 89.6976L366.717 89.0827L367.051 88.6081L367.235 89.2337L366.924 89.6976Z'
        fill='url(#paint1024_linear_3150_15389)'
      />
      <path
        d='M623.797 582.221L625.246 581.876L625.315 582.145L623.843 582.404L623.797 582.221Z'
        fill='url(#paint1025_linear_3150_15389)'
      />
      <path
        d='M389.473 4.75373L388.599 4.61349L388.565 4.33305L389.427 4.39777L389.473 4.75373Z'
        fill='url(#paint1026_linear_3150_15389)'
      />
      <path
        d='M741.971 156.639L743.006 155.28L744.467 155.571L743.282 156.995L741.971 156.639Z'
        fill='url(#paint1027_linear_3150_15389)'
      />
      <path
        d='M500.38 10.2549L499.564 9.08991L500.323 8.4535L501.173 9.5753L500.38 10.2549Z'
        fill='url(#paint1028_linear_3150_15389)'
      />
      <path
        d='M714.788 492.941L716.053 492.143L716.513 492.801L715.202 493.534L714.788 492.941Z'
        fill='url(#paint1029_linear_3150_15389)'
      />
      <path
        d='M247.129 144.73L246.956 143.835L247.462 143.339L247.416 144.299L247.129 144.73Z'
        fill='url(#paint1030_linear_3150_15389)'
      />
      <path
        d='M563.405 170.402L562.796 169.183L563.739 168.493L564.486 169.809L563.405 170.402Z'
        fill='url(#paint1031_linear_3150_15389)'
      />
      <path
        d='M349.768 21.311L349.17 20.6207L349.446 19.8009L350.147 20.4158L349.768 21.311Z'
        fill='url(#paint1032_linear_3150_15389)'
      />
      <path
        d='M343.823 35.6787L342.96 35.409L343.225 34.4598L344.145 34.6647L343.823 35.6787Z'
        fill='url(#paint1033_linear_3150_15389)'
      />
      <path
        d='M764.866 314.985L765.636 313.755L766.866 314.413L766.061 315.643L764.866 314.985Z'
        fill='url(#paint1034_linear_3150_15389)'
      />
      <path
        d='M405.894 -0.812119L406.032 -2.48403L406.089 -2.44088L405.986 -0.790543L405.894 -0.812119Z'
        fill='url(#paint1035_linear_3150_15389)'
      />
      <path
        d='M363.578 100.236L363.164 99.7398L363.601 99.1682L363.946 99.6535L363.578 100.236Z'
        fill='url(#paint1036_linear_3150_15389)'
      />
      <path
        d='M551.102 172.495L550.527 171.168L551.435 170.489L552.079 171.794L551.102 172.495Z'
        fill='url(#paint1037_linear_3150_15389)'
      />
      <path
        d='M703.025 510.167L704.336 509.433L704.715 510.059L703.358 510.717L703.025 510.167Z'
        fill='url(#paint1038_linear_3150_15389)'
      />
      <path
        d='M326.46 35.8081L325.563 35.5924L325.908 34.5353L326.793 34.7726L326.46 35.8081Z'
        fill='url(#paint1039_linear_3150_15389)'
      />
      <path
        d='M249.762 213.117L249.693 211.866L250.05 211.531V212.804L249.762 213.117Z'
        fill='url(#paint1040_linear_3150_15389)'
      />
      <path
        d='M384.356 4.2899L383.402 4.13889L383.367 3.85844L384.31 3.93396L384.356 4.2899Z'
        fill='url(#paint1041_linear_3150_15389)'
      />
      <path
        d='M367.614 97.0756L367.442 96.4391L367.775 95.9861L367.844 96.6117L367.614 97.0756Z'
        fill='url(#paint1042_linear_3150_15389)'
      />
      <path
        d='M304.968 140.545L304.635 139.974L305.325 139.434L305.382 140.081L304.968 140.545Z'
        fill='url(#paint1043_linear_3150_15389)'
      />
      <path
        d='M574.157 157.383L573.191 156.38L574.191 155.765L575.146 156.693L574.157 157.383Z'
        fill='url(#paint1044_linear_3150_15389)'
      />
      <path
        d='M555.218 24.6009L554.402 23.3713L555.356 22.7241L556.219 23.9214L555.218 24.6009Z'
        fill='url(#paint1045_linear_3150_15389)'
      />
      <path
        d='M690.169 526.713L691.514 526.055L691.813 526.627L690.422 527.199L690.169 526.713Z'
        fill='url(#paint1046_linear_3150_15389)'
      />
      <path
        d='M606.607 594.636L608.067 594.377L608.101 594.561L606.618 594.733L606.607 594.636Z'
        fill='url(#paint1047_linear_3150_15389)'
      />
      <path
        d='M260.33 154.514L260.18 153.683L260.709 153.155L260.847 154.039L260.33 154.514Z'
        fill='url(#paint1048_linear_3150_15389)'
      />
      <path
        d='M299.161 172.419L299.23 171.589L299.357 171.287L299.265 172.128L299.161 172.419Z'
        fill='url(#paint1049_linear_3150_15389)'
      />
      <path
        d='M288.237 163.812L288.053 163.067L288.536 162.636L288.651 163.413L288.237 163.812Z'
        fill='url(#paint1050_linear_3150_15389)'
      />
      <path
        d='M350.987 107.29L350.124 107.15L350.642 106.363L351.24 106.578L350.987 107.29Z'
        fill='url(#paint1051_linear_3150_15389)'
      />
      <path
        d='M647.186 219.859L646.726 218.273L648.026 217.885L648.509 219.427L647.186 219.859Z'
        fill='url(#paint1052_linear_3150_15389)'
      />
      <path
        d='M348.928 9.73709L347.721 9.60766L347.709 9.42429L348.905 9.45665L348.928 9.73709Z'
        fill='url(#paint1053_linear_3150_15389)'
      />
      <path
        d='M277.75 154.87L277.463 154.147L278.13 153.597L278.302 154.374L277.75 154.87Z'
        fill='url(#paint1054_linear_3150_15389)'
      />
      <path
        d='M741.419 131.948L742.374 130.449L743.995 130.535L742.88 132.164L741.419 131.948Z'
        fill='url(#paint1055_linear_3150_15389)'
      />
      <path
        d='M401.95 -1.01707L402.053 -2.64583L402.122 -2.67818L402.088 -1.09257L401.95 -1.01707Z'
        fill='url(#paint1056_linear_3150_15389)'
      />
      <path
        d='M585.518 167.652L584.644 166.498L585.713 165.937L586.599 167.026L585.518 167.652Z'
        fill='url(#paint1057_linear_3150_15389)'
      />
      <path
        d='M578.055 33.6616L577.296 32.3996L578.342 31.7632L579.159 33.0036L578.055 33.6616Z'
        fill='url(#paint1058_linear_3150_15389)'
      />
      <path
        d='M505.336 9.44587L504.485 8.2917L505.244 7.64451L506.152 8.75553L505.336 9.44587Z'
        fill='url(#paint1059_linear_3150_15389)'
      />
      <path
        d='M730.242 101.638L730.921 99.9771L732.577 99.8153L731.852 101.541L730.242 101.638Z'
        fill='url(#paint1060_linear_3150_15389)'
      />
      <path
        d='M532.462 16.2414L531.599 15.0549L532.473 14.3969L533.382 15.551L532.462 16.2414Z'
        fill='url(#paint1061_linear_3150_15389)'
      />
      <path
        d='M321.573 16.4895L320.262 16.4247L320.25 16.3277L321.561 16.3061L321.573 16.4895Z'
        fill='url(#paint1062_linear_3150_15389)'
      />
      <path
        d='M676.255 542.516L677.647 541.944L677.865 542.44L676.439 542.936L676.255 542.516Z'
        fill='url(#paint1063_linear_3150_15389)'
      />
      <path
        d='M379.366 3.77215L378.342 3.61036L378.319 3.3299L379.331 3.41619L379.366 3.77215Z'
        fill='url(#paint1064_linear_3150_15389)'
      />
      <path
        d='M303.117 143.36L301.875 144.137L301.944 143.242L302.887 142.584L303.117 143.36Z'
        fill='url(#paint1065_linear_3150_15389)'
      />
      <path
        d='M331.68 29.3686L330.772 29.1744L331.002 28.1821L331.968 28.3007L331.68 29.3686Z'
        fill='url(#paint1066_linear_3150_15389)'
      />
      <path
        d='M284.73 177.856L283.27 179.258L283.109 178.471L284.121 177.295L284.73 177.856Z'
        fill='url(#paint1067_linear_3150_15389)'
      />
      <path
        d='M672.576 48.4715L672.277 46.6593L673.553 46.422L673.921 47.6409L672.576 48.4715Z'
        fill='url(#paint1068_linear_3150_15389)'
      />
      <path
        d='M299.127 8.06518L298.54 7.35328L299.023 6.26383L299.46 7.06204L299.127 8.06518Z'
        fill='url(#paint1069_linear_3150_15389)'
      />
      <path
        d='M475.071 3.41619L474.289 2.28362L474.933 1.658L475.761 2.74744L475.071 3.41619Z'
        fill='url(#paint1070_linear_3150_15389)'
      />
      <path
        d='M661.33 557.52L662.756 557.034L662.917 557.466L661.456 557.876L661.33 557.52Z'
        fill='url(#paint1071_linear_3150_15389)'
      />
      <path
        d='M424.453 -4.06966L423.775 -4.0265L423.706 -4.39323L424.326 -4.52269L424.453 -4.06966Z'
        fill='url(#paint1072_linear_3150_15389)'
      />
      <path
        d='M305.762 15.3569L305.083 14.796L305.509 13.7173L306.141 14.2998L305.762 15.3569Z'
        fill='url(#paint1073_linear_3150_15389)'
      />
      <path
        d='M291.744 0.870575L291.388 -0.0678482L291.813 -1.07098L292.043 -0.0678482L291.744 0.870575Z'
        fill='url(#paint1074_linear_3150_15389)'
      />
      <path
        d='M318.525 29.0989L317.709 28.7753L318.077 27.7398L318.859 28.0958L318.525 29.0989Z'
        fill='url(#paint1075_linear_3150_15389)'
      />
      <path
        d='M374.525 3.20046L373.432 3.0171L373.421 2.74744L374.502 2.84451L374.525 3.20046Z'
        fill='url(#paint1076_linear_3150_15389)'
      />
      <path
        d='M229.846 176.756L229.812 175.72L230.191 175.224L230.283 176.335L229.846 176.756Z'
        fill='url(#paint1077_linear_3150_15389)'
      />
      <path
        d='M236.561 164.718L236.607 163.736L236.86 163.251L236.929 164.297L236.561 164.718Z'
        fill='url(#paint1078_linear_3150_15389)'
      />
      <path
        d='M354.735 11.3119L354.321 10.3627L354.517 9.73709L355.092 10.5353L354.735 11.3119Z'
        fill='url(#paint1079_linear_3150_15389)'
      />
      <path
        d='M621.383 143.382L620.888 142.39L622.015 141.947L622.51 142.951L621.383 143.382Z'
        fill='url(#paint1080_linear_3150_15389)'
      />
      <path
        d='M266.125 144.806L265.837 144.072L266.516 143.425L266.642 144.213L266.125 144.806Z'
        fill='url(#paint1081_linear_3150_15389)'
      />
      <path
        d='M338.579 14.4616L337.901 13.8576L338.188 12.9838L338.901 13.5555L338.579 14.4616Z'
        fill='url(#paint1082_linear_3150_15389)'
      />
      <path
        d='M343.834 8.84181L342.569 8.7016L342.558 8.51823L343.823 8.57215L343.834 8.84181Z'
        fill='url(#paint1083_linear_3150_15389)'
      />
      <path
        d='M312.396 22.3142L311.649 21.8611L312.04 20.8256L312.753 21.2895L312.396 22.3142Z'
        fill='url(#paint1084_linear_3150_15389)'
      />
      <path
        d='M397.58 -1.99863L397.672 -3.58425L397.753 -3.65976L397.707 -2.1065L397.58 -1.99863Z'
        fill='url(#paint1085_linear_3150_15389)'
      />
      <path
        d='M428.041 147.761L427.431 147.179L427.627 146.122L428.8 146.855L428.041 147.761Z'
        fill='url(#paint1086_linear_3150_15389)'
      />
      <path
        d='M510.028 8.58295L509.131 7.42878L509.901 6.7708L510.844 7.88181L510.028 8.58295Z'
        fill='url(#paint1087_linear_3150_15389)'
      />
      <path
        d='M199.535 192.633L199.558 191.35L199.845 190.929L199.753 192.277L199.535 192.633Z'
        fill='url(#paint1088_linear_3150_15389)'
      />
      <path
        d='M645.438 571.672L646.899 571.262L647.002 571.618L645.519 571.941L645.438 571.672Z'
        fill='url(#paint1089_linear_3150_15389)'
      />
      <path
        d='M479.613 2.86609L478.774 1.74429L479.441 1.10788L480.314 2.18653L479.613 2.86609Z'
        fill='url(#paint1090_linear_3150_15389)'
      />
      <path
        d='M625.338 189.904L624.798 188.524L626.017 188.114L626.557 189.419L625.338 189.904Z'
        fill='url(#paint1091_linear_3150_15389)'
      />
      <path
        d='M377.181 8.57215L377.296 7.16991L377.331 6.98653L377.4 8.25935L377.181 8.57215Z'
        fill='url(#paint1092_linear_3150_15389)'
      />
      <path
        d='M568.292 158.634L567.257 157.728L568.143 157.038L569.235 157.922L568.292 158.634Z'
        fill='url(#paint1093_linear_3150_15389)'
      />
      <path
        d='M625.223 176.896L624.683 175.623L625.89 175.245L626.431 176.443L625.223 176.896Z'
        fill='url(#paint1094_linear_3150_15389)'
      />
      <path
        d='M560.174 23.2742L559.323 22.0553L560.289 21.3865L561.186 22.5838L560.174 23.2742Z'
        fill='url(#paint1095_linear_3150_15389)'
      />
      <path
        d='M537.199 15.1196L536.314 13.9331L537.188 13.2643L538.131 14.4077L537.199 15.1196Z'
        fill='url(#paint1096_linear_3150_15389)'
      />
      <path
        d='M768.58 317.735L769.201 316.43L770.489 316.98L769.81 318.264L768.58 317.735Z'
        fill='url(#paint1097_linear_3150_15389)'
      />
      <path
        d='M316.329 15.3245L314.949 15.249V15.1627L316.306 15.1412L316.329 15.3245Z'
        fill='url(#paint1098_linear_3150_15389)'
      />
      <path
        d='M369.856 2.57484L368.706 2.3807L368.695 2.11103L369.845 2.21889L369.856 2.57484Z'
        fill='url(#paint1099_linear_3150_15389)'
      />
      <path
        d='M619.301 204.509L618.634 202.999L619.83 202.46L620.543 203.959L619.301 204.509Z'
        fill='url(#paint1100_linear_3150_15389)'
      />
      <path
        d='M628.638 584.917L630.122 584.594L630.191 584.864L628.684 585.101L628.638 584.917Z'
        fill='url(#paint1101_linear_3150_15389)'
      />
      <path
        d='M772.857 320.238L773.639 319.008L774.858 319.677L774.03 320.896L772.857 320.238Z'
        fill='url(#paint1102_linear_3150_15389)'
      />
      <path
        d='M576.261 169.593L575.157 168.493L576.169 167.867L577.25 168.86L576.261 169.593Z'
        fill='url(#paint1103_linear_3150_15389)'
      />
      <path
        d='M580.803 169.766L579.619 168.914L580.516 168.169L581.758 168.989L580.803 169.766Z'
        fill='url(#paint1104_linear_3150_15389)'
      />
      <path
        d='M318.939 -2.73212L318.56 -4.25302L318.583 -4.2638L318.997 -2.79684L318.939 -2.73212Z'
        fill='url(#paint1105_linear_3150_15389)'
      />
      <path
        d='M483.971 2.27283L483.086 1.15103L483.753 0.514618L484.684 1.58249L483.971 2.27283Z'
        fill='url(#paint1106_linear_3150_15389)'
      />
      <path
        d='M583.114 32.1623L582.321 30.9003L583.379 30.2531L584.218 31.4935L583.114 32.1623Z'
        fill='url(#paint1107_linear_3150_15389)'
      />
      <path
        d='M488.789 112.716L489.169 111.68L489.284 110.925V112.306L488.789 112.716Z'
        fill='url(#paint1108_linear_3150_15389)'
      />
      <path
        d='M339.005 7.89261L337.682 7.7416V7.55823L339.005 7.62295V7.89261Z'
        fill='url(#paint1109_linear_3150_15389)'
      />
      <path
        d='M514.432 7.66608L513.5 6.52271L514.282 5.85396L515.26 6.95417L514.432 7.66608Z'
        fill='url(#paint1110_linear_3150_15389)'
      />
      <path
        d='M610.999 597.193L612.494 596.955L612.529 597.139L611.011 597.29L610.999 597.193Z'
        fill='url(#paint1111_linear_3150_15389)'
      />
      <path
        d='M772.708 368.799L773.961 367.957L774.789 368.961L773.501 369.802L772.708 368.799Z'
        fill='url(#paint1112_linear_3150_15389)'
      />
      <path
        d='M282.442 145.938L282.028 145.356L282.821 144.633L283.109 145.259L282.442 145.938Z'
        fill='url(#paint1113_linear_3150_15389)'
      />
      <path
        d='M393.463 -3.24987L393.59 -4.79235L393.647 -4.87865L393.59 -3.3901L393.463 -3.24987Z'
        fill='url(#paint1114_linear_3150_15389)'
      />
      <path
        d='M243.449 153.306L243.426 152.4L243.725 151.85L243.702 152.809L243.449 153.306Z'
        fill='url(#paint1115_linear_3150_15389)'
      />
      <path
        d='M742.834 276.38L743.19 274.956L744.582 275.301L744.191 276.714L742.834 276.38Z'
        fill='url(#paint1116_linear_3150_15389)'
      />
      <path
        d='M327.724 7.77396L327.023 7.22383L327.253 6.32857L328.081 6.72766L327.724 7.77396Z'
        fill='url(#paint1117_linear_3150_15389)'
      />
      <path
        d='M365.372 1.90609L364.164 1.69036V1.42069L365.372 1.53935V1.90609Z'
        fill='url(#paint1118_linear_3150_15389)'
      />
      <path
        d='M593.797 143.015L593.107 142.098L594.073 141.548L594.797 142.454L593.797 143.015Z'
        fill='url(#paint1119_linear_3150_15389)'
      />
      <path
        d='M282.58 -6.53976L282.43 -7.59684L282.729 -8.53527L282.925 -7.51054L282.58 -6.53976Z'
        fill='url(#paint1120_linear_3150_15389)'
      />
      <path
        d='M343.972 5.28227L343.374 4.55956L343.581 3.79372L344.294 4.40855L343.972 5.28227Z'
        fill='url(#paint1121_linear_3150_15389)'
      />
      <path
        d='M488.122 1.62564L487.202 0.514618L487.881 -0.132568L488.858 0.924507L488.122 1.62564Z'
        fill='url(#paint1122_linear_3150_15389)'
      />
      <path
        d='M292.607 155.01L292.377 154.374L292.883 153.823L293.09 154.481L292.607 155.01Z'
        fill='url(#paint1123_linear_3150_15389)'
      />
      <path
        d='M742.017 459.298L743.202 458.359L743.846 459.093L742.615 459.966L742.017 459.298Z'
        fill='url(#paint1124_linear_3150_15389)'
      />
      <path
        d='M306.36 163.574L306.521 162.841L306.544 162.453L306.486 163.24L306.36 163.574Z'
        fill='url(#paint1125_linear_3150_15389)'
      />
      <path
        d='M732.255 477.84L733.497 476.966L734.049 477.678L732.761 478.487L732.255 477.84Z'
        fill='url(#paint1126_linear_3150_15389)'
      />
      <path
        d='M704.508 242.996L704.416 241.485H705.842L705.934 243.017L704.508 242.996Z'
        fill='url(#paint1127_linear_3150_15389)'
      />
      <path
        d='M316.846 23.5115L315.904 23.4144L316.088 22.4221H317.088L316.846 23.5115Z'
        fill='url(#paint1128_linear_3150_15389)'
      />
      <path
        d='M721.285 495.799L722.584 495.001L723.044 495.67L721.699 496.403L721.285 495.799Z'
        fill='url(#paint1129_linear_3150_15389)'
      />
      <path
        d='M541.569 13.9331L540.638 12.7465L541.523 12.067L542.5 13.2104L541.569 13.9331Z'
        fill='url(#paint1130_linear_3150_15389)'
      />
      <path
        d='M304.853 8.23777L303.899 8.06518L304.198 6.90025L305.129 7.07282L304.853 8.23777Z'
        fill='url(#paint1131_linear_3150_15389)'
      />
      <path
        d='M310.085 16.3169L309.096 16.2414L309.338 15.1304L310.304 15.2059L310.085 16.3169Z'
        fill='url(#paint1132_linear_3150_15389)'
      />
      <path
        d='M709.165 513.101L710.51 512.378L710.878 513.015L709.499 513.662L709.165 513.101Z'
        fill='url(#paint1133_linear_3150_15389)'
      />
      <path
        d='M311.442 14.1164L310.016 14.0193V13.9331H311.431L311.442 14.1164Z'
        fill='url(#paint1134_linear_3150_15389)'
      />
      <path
        d='M655.776 219.19L655.189 217.647L656.523 217.281L657.156 218.845L655.776 219.19Z'
        fill='url(#paint1135_linear_3150_15389)'
      />
      <path
        d='M584.218 155.495L583.321 154.676L584.172 153.985L585.173 154.827L584.218 155.495Z'
        fill='url(#paint1136_linear_3150_15389)'
      />
      <path
        d='M316.617 0.665642L315.766 0.309685L315.984 -0.73661L316.915 -0.488525L316.617 0.665642Z'
        fill='url(#paint1137_linear_3150_15389)'
      />
      <path
        d='M564.67 21.8935L563.796 20.6746L564.774 19.9951L565.694 21.1816L564.67 21.8935Z'
        fill='url(#paint1138_linear_3150_15389)'
      />
      <path
        d='M361.094 1.18339L359.829 0.956871V0.676422L361.094 0.81665V1.18339Z'
        fill='url(#paint1139_linear_3150_15389)'
      />
      <path
        d='M695.93 529.701L697.321 529.054L697.609 529.626L696.183 530.197L695.93 529.701Z'
        fill='url(#paint1140_linear_3150_15389)'
      />
      <path
        d='M518.525 6.6953L517.559 5.56271L518.353 4.88316L519.365 5.97261L518.525 6.6953Z'
        fill='url(#paint1141_linear_3150_15389)'
      />
      <path
        d='M334.474 6.90025L333.106 6.72766V6.5443L334.474 6.61979V6.90025Z'
        fill='url(#paint1142_linear_3150_15389)'
      />
      <path
        d='M393.601 -3.45482L392.578 -3.7137L392.555 -4.06966L393.578 -3.89706L393.601 -3.45482Z'
        fill='url(#paint1143_linear_3150_15389)'
      />
      <path
        d='M492.066 0.935303L491.1 -0.17572L491.79 -0.833702L492.802 0.223389L492.066 0.935303Z'
        fill='url(#paint1144_linear_3150_15389)'
      />
      <path
        d='M654.166 30.814L653.603 29.1205L654.81 28.7214L655.316 29.9079L654.166 30.814Z'
        fill='url(#paint1145_linear_3150_15389)'
      />
      <path
        d='M681.614 545.514L683.051 544.953L683.27 545.46L681.809 545.946L681.614 545.514Z'
        fill='url(#paint1146_linear_3150_15389)'
      />
      <path
        d='M751.837 362.413L752.907 361.367L753.815 362.305L752.688 363.287L751.837 362.413Z'
        fill='url(#paint1147_linear_3150_15389)'
      />
      <path
        d='M613.425 176.702L612.517 175.526L613.644 175.019L614.552 176.098L613.425 176.702Z'
        fill='url(#paint1148_linear_3150_15389)'
      />
      <path
        d='M376.284 107.431L376.25 106.751L376.514 106.32L376.376 106.924L376.284 107.431Z'
        fill='url(#paint1149_linear_3150_15389)'
      />
      <path
        d='M624.165 204.089L623.28 202.6L624.51 202.104L625.419 203.539L624.165 204.089Z'
        fill='url(#paint1150_linear_3150_15389)'
      />
      <path
        d='M666.286 560.497L667.758 560.011L667.907 560.454L666.412 560.853L666.286 560.497Z'
        fill='url(#paint1151_linear_3150_15389)'
      />
      <path
        d='M459.846 -3.8L458.996 -4.88943L459.571 -5.50425L460.467 -4.46875L459.846 -3.8Z'
        fill='url(#paint1152_linear_3150_15389)'
      />
      <path
        d='M620.762 189.494L619.922 188.168L621.118 187.704L621.969 188.955L620.762 189.494Z'
        fill='url(#paint1153_linear_3150_15389)'
      />
      <path
        d='M296.493 0.00766754L295.585 -0.24044L295.884 -1.43774L296.815 -1.2328L296.493 0.00766754Z'
        fill='url(#paint1154_linear_3150_15389)'
      />
      <path
        d='M389.726 -3.86471L388.634 -4.13437V-4.50111L389.715 -4.31774L389.726 -3.86471Z'
        fill='url(#paint1155_linear_3150_15389)'
      />
      <path
        d='M389.864 -4.81391L390.014 -6.30246L390.06 -6.42111L389.991 -4.99728L389.864 -4.81391Z'
        fill='url(#paint1156_linear_3150_15389)'
      />
      <path
        d='M649.992 574.573L651.487 574.185L651.59 574.541L650.072 574.854L649.992 574.573Z'
        fill='url(#paint1157_linear_3150_15389)'
      />
      <path
        d='M286.305 4.91552L285.938 3.35148L286.19 3.30833L286.443 4.9371L286.305 4.91552Z'
        fill='url(#paint1158_linear_3150_15389)'
      />
      <path
        d='M758.829 306.086L759.289 304.694L760.645 305.072L760.174 306.442L758.829 306.086Z'
        fill='url(#paint1159_linear_3150_15389)'
      />
      <path
        d='M357.035 0.406761L355.724 0.169456V-0.110985L357.047 0.0400238L357.035 0.406761Z'
        fill='url(#paint1160_linear_3150_15389)'
      />
      <path
        d='M463.48 -4.18829L462.583 -5.26694L463.17 -5.89256L464.113 -4.86784L463.48 -4.18829Z'
        fill='url(#paint1161_linear_3150_15389)'
      />
      <path
        d='M495.78 0.191032L494.769 -0.909195L495.47 -1.57796L496.516 -0.531662L495.78 0.191032Z'
        fill='url(#paint1162_linear_3150_15389)'
      />
      <path
        d='M594.119 166.228L593.153 165.494L593.958 164.707L595.027 165.441L594.119 166.228Z'
        fill='url(#paint1163_linear_3150_15389)'
      />
      <path
        d='M306.796 -7.4674L305.877 -7.73706L306.13 -8.88043L307.061 -8.66471L306.796 -7.4674Z'
        fill='url(#paint1164_linear_3150_15389)'
      />
      <path
        d='M632.79 587.711L634.307 587.398L634.365 587.679L632.836 587.895L632.79 587.711Z'
        fill='url(#paint1165_linear_3150_15389)'
      />
      <path
        d='M385.978 -4.31774L384.828 -4.60898V-4.97572L385.978 -4.77077V-4.31774Z'
        fill='url(#paint1166_linear_3150_15389)'
      />
      <path
        d='M326.517 -7.18696L326.138 -8.66471V-8.70784L326.552 -7.27324L326.517 -7.18696Z'
        fill='url(#paint1167_linear_3150_15389)'
      />
      <path
        d='M211.206 177.834L211.275 176.734L211.505 176.108L211.459 177.273L211.206 177.834Z'
        fill='url(#paint1168_linear_3150_15389)'
      />
      <path
        d='M333.715 -1.16808L333.037 -1.75054L333.244 -2.58111L333.98 -2.05257L333.715 -1.16808Z'
        fill='url(#paint1169_linear_3150_15389)'
      />
      <path
        d='M545.525 12.6926L544.582 11.5169L545.467 10.8265L546.468 11.9591L545.525 12.6926Z'
        fill='url(#paint1170_linear_3150_15389)'
      />
      <path
        d='M522.308 5.68137L521.308 4.54878L522.09 3.85844L523.148 4.94788L522.308 5.68137Z'
        fill='url(#paint1171_linear_3150_15389)'
      />
      <path
        d='M482.419 195.276L481.683 195.05L481.809 194.553L482.603 194.715L482.419 195.276Z'
        fill='url(#paint1172_linear_3150_15389)'
      />
      <path
        d='M330.254 5.85396L328.84 5.67059V5.47643L330.254 5.57349V5.85396Z'
        fill='url(#paint1173_linear_3150_15389)'
      />
      <path
        d='M614.748 599.835L616.277 599.62L616.312 599.803L614.759 599.932L614.748 599.835Z'
        fill='url(#paint1174_linear_3150_15389)'
      />
      <path
        d='M537.418 22.1632L536.762 22.4652L536.487 21.5052L537.234 21.1492L537.418 22.1632Z'
        fill='url(#paint1175_linear_3150_15389)'
      />
      <path
        d='M466.987 -4.61976L466.033 -5.68763L466.631 -6.32404L467.62 -5.3101L466.987 -4.61976Z'
        fill='url(#paint1176_linear_3150_15389)'
      />
      <path
        d='M306.934 12.8436L305.474 12.7357V12.6495L306.923 12.6602L306.934 12.8436Z'
        fill='url(#paint1177_linear_3150_15389)'
      />
      <path
        d='M490.686 1.39912L490.629 0.0184479L491.112 -0.359093L491.319 1.0108L490.686 1.39912Z'
        fill='url(#paint1178_linear_3150_15389)'
      />
      <path
        d='M325.862 -7.60763L325.114 -8.12538L325.31 -9.03144L326.149 -8.63235L325.862 -7.60763Z'
        fill='url(#paint1179_linear_3150_15389)'
      />
      <path
        d='M211.103 22.0445L212.609 21.6562L212.724 22.0877L211.356 22.5515L211.103 22.0445Z'
        fill='url(#paint1180_linear_3150_15389)'
      />
      <path
        d='M740.959 372.531L741.672 371.334L742.661 371.862L741.902 373.027L740.959 372.531Z'
        fill='url(#paint1181_linear_3150_15389)'
      />
      <path
        d='M593.935 142.444L593.268 141.559L594.188 140.998L594.901 141.904L593.935 142.444Z'
        fill='url(#paint1182_linear_3150_15389)'
      />
      <path
        d='M607.918 176.712L606.894 175.785L607.86 175.105L608.952 176.011L607.918 176.712Z'
        fill='url(#paint1183_linear_3150_15389)'
      />
      <path
        d='M382.367 -4.81391L381.16 -5.11593L381.171 -5.48269L382.379 -5.27774L382.367 -4.81391Z'
        fill='url(#paint1184_linear_3150_15389)'
      />
      <path
        d='M568.695 20.4589L567.798 19.2292L568.775 18.5389L569.718 19.7254L568.695 20.4589Z'
        fill='url(#paint1185_linear_3150_15389)'
      />
      <path
        d='M353.217 -0.41301L351.849 -0.67189L351.861 -0.941551L353.229 -0.779762L353.217 -0.41301Z'
        fill='url(#paint1186_linear_3150_15389)'
      />
      <path
        d='M738.062 386.769L739.143 385.561L739.936 386.435L738.878 387.438L738.062 386.769Z'
        fill='url(#paint1187_linear_3150_15389)'
      />
      <path
        d='M499.242 -0.596397L498.195 -1.68583L498.897 -2.36538L499.989 -1.32987L499.242 -0.596397Z'
        fill='url(#paint1188_linear_3150_15389)'
      />
      <path
        d='M470.345 -5.08357L469.345 -6.15144L469.943 -6.78785L470.989 -5.78471L470.345 -5.08357Z'
        fill='url(#paint1189_linear_3150_15389)'
      />
      <path
        d='M477.118 213.753L475.508 213.29L475.772 212.761L477.417 213.095L477.118 213.753Z'
        fill='url(#paint1190_linear_3150_15389)'
      />
      <path
        d='M618.013 188.987L616.944 187.823L618.059 187.272L619.152 188.373L618.013 188.987Z'
        fill='url(#paint1191_linear_3150_15389)'
      />
      <path
        d='M689.088 149.746L689.295 148.635L690.583 148.57L690.341 149.746H689.088Z'
        fill='url(#paint1192_linear_3150_15389)'
      />
      <path
        d='M562.485 6.60901L561.979 6.92181L561.715 5.86474L562.278 5.47643L562.485 6.60901Z'
        fill='url(#paint1193_linear_3150_15389)'
      />
      <path
        d='M800.524 387.093L801.972 386.316L802.697 387.416L801.237 388.107L800.524 387.093Z'
        fill='url(#paint1194_linear_3150_15389)'
      />
      <path
        d='M378.906 -5.35323L377.641 -5.67683L377.664 -6.04357L378.929 -5.80627L378.906 -5.35323Z'
        fill='url(#paint1195_linear_3150_15389)'
      />
      <path
        d='M628.178 202.87L627.109 201.511L628.316 201.025L629.432 202.341L628.178 202.87Z'
        fill='url(#paint1196_linear_3150_15389)'
      />
      <path
        d='M585.391 17.374L584.471 16.1551L585.437 15.4432L586.392 16.6081L585.391 17.374Z'
        fill='url(#paint1197_linear_3150_15389)'
      />
      <path
        d='M372.329 4.71059L372.386 3.52406L372.444 3.23283L372.582 4.31148L372.329 4.71059Z'
        fill='url(#paint1198_linear_3150_15389)'
      />
      <path
        d='M473.542 -5.60134L472.507 -6.64764L473.105 -7.30561L474.197 -6.30246L473.542 -5.60134Z'
        fill='url(#paint1199_linear_3150_15389)'
      />
      <path
        d='M727.356 265.583L727.517 264.029L728.92 264.223L728.771 265.69L727.356 265.583Z'
        fill='url(#paint1200_linear_3150_15389)'
      />
      <path
        d='M770.88 392.076L771.995 391.138L772.788 391.99L771.65 392.853L770.88 392.076Z'
        fill='url(#paint1201_linear_3150_15389)'
      />
      <path
        d='M525.735 4.61349L524.712 3.49171L525.505 2.79057L526.586 3.86922L525.735 4.61349Z'
        fill='url(#paint1202_linear_3150_15389)'
      />
      <path
        d='M715.938 76.5596L716.317 74.8445L717.927 74.3376L717.536 76.085L715.938 76.5596Z'
        fill='url(#paint1203_linear_3150_15389)'
      />
      <path
        d='M527.494 201.586L526.678 201.446L526.885 200.723L527.816 200.766L527.494 201.586Z'
        fill='url(#paint1204_linear_3150_15389)'
      />
      <path
        d='M326.356 4.75373L324.896 4.54878L324.907 4.36541L326.368 4.47328L326.356 4.75373Z'
        fill='url(#paint1205_linear_3150_15389)'
      />
      <path
        d='M788.013 359.177L788.772 357.937L789.991 358.584L789.163 359.878L788.013 359.177Z'
        fill='url(#paint1206_linear_3150_15389)'
      />
      <path
        d='M297.16 147.535L296.93 147.028L297.39 146.381L297.666 146.888L297.16 147.535Z'
        fill='url(#paint1207_linear_3150_15389)'
      />
      <path
        d='M387.139 -6.48582L387.312 -7.89886L387.346 -8.04987L387.254 -6.70155L387.139 -6.48582Z'
        fill='url(#paint1208_linear_3150_15389)'
      />
      <path
        d='M549.078 11.409L548.1 10.2333L548.986 9.53215L550.009 10.6647L549.078 11.409Z'
        fill='url(#paint1209_linear_3150_15389)'
      />
      <path
        d='M349.641 -1.26515L348.238 -1.54559L348.25 -1.82606L349.676 -1.64268L349.641 -1.26515Z'
        fill='url(#paint1210_linear_3150_15389)'
      />
      <path
        d='M502.45 -1.42694L501.369 -2.5056L502.07 -3.19593L503.197 -2.17122L502.45 -1.42694Z'
        fill='url(#paint1211_linear_3150_15389)'
      />
      <path
        d='M514.961 30.2638L514.052 30.3933L513.891 29.4872L514.788 29.2607L514.961 30.2638Z'
        fill='url(#paint1212_linear_3150_15389)'
      />
      <path
        d='M375.606 -5.93571L374.295 -6.2701L374.329 -6.63683L375.64 -6.38875L375.606 -5.93571Z'
        fill='url(#paint1213_linear_3150_15389)'
      />
      <path
        d='M756.391 396.725L757.207 395.56L758.15 396.229L757.276 397.297L756.391 396.725Z'
        fill='url(#paint1214_linear_3150_15389)'
      />
      <path
        d='M302.841 11.5277L301.335 11.409V11.3119L302.841 11.3443V11.5277Z'
        fill='url(#paint1215_linear_3150_15389)'
      />
      <path
        d='M476.566 -6.15144L475.485 -7.18696L476.094 -7.84494L477.221 -6.86336L476.566 -6.15144Z'
        fill='url(#paint1216_linear_3150_15389)'
      />
      <path
        d='M708.027 48.0616L708.303 46.0553L709.591 46.0229L709.349 47.7812L708.027 48.0616Z'
        fill='url(#paint1217_linear_3150_15389)'
      />
      <path
        d='M723.757 99.4702L724.309 97.9061L725.953 97.5609L725.309 99.2868L723.757 99.4702Z'
        fill='url(#paint1218_linear_3150_15389)'
      />
      <path
        d='M734.267 126.555L735.083 125.077L736.67 125.056L735.75 126.62L734.267 126.555Z'
        fill='url(#paint1219_linear_3150_15389)'
      />
      <path
        d='M737.004 149.487L737.935 148.171L739.419 148.333L738.372 149.735L737.004 149.487Z'
        fill='url(#paint1220_linear_3150_15389)'
      />
      <path
        d='M672.783 143.857L672.817 142.8L673.99 142.649L673.932 143.781L672.783 143.857Z'
        fill='url(#paint1221_linear_3150_15389)'
      />
      <path
        d='M313.5 -14.8238L312.649 -15.1474L312.764 -16.0858L313.753 -15.9887L313.5 -14.8238Z'
        fill='url(#paint1222_linear_3150_15389)'
      />
      <path
        d='M223.246 164.588L223.441 163.618L223.522 162.916L223.453 163.952L223.246 164.588Z'
        fill='url(#paint1223_linear_3150_15389)'
      />
      <path
        d='M658.248 217.496L657.501 215.965L658.823 215.673L659.605 217.151L658.248 217.496Z'
        fill='url(#paint1224_linear_3150_15389)'
      />
      <path
        d='M791.98 366.48L793.36 365.703L794.153 366.803L792.75 367.548L791.98 366.48Z'
        fill='url(#paint1225_linear_3150_15389)'
      />
      <path
        d='M714.351 516.153L715.731 515.431L716.099 516.067L714.685 516.714L714.351 516.153Z'
        fill='url(#paint1226_linear_3150_15389)'
      />
      <path
        d='M726.804 498.755L728.138 497.957L728.598 498.647L727.218 499.37L726.804 498.755Z'
        fill='url(#paint1227_linear_3150_15389)'
      />
      <path
        d='M740.35 440.195L740.775 438.803L741.787 439.332L741.304 440.615L740.35 440.195Z'
        fill='url(#paint1228_linear_3150_15389)'
      />
      <path
        d='M700.771 532.786L702.197 532.15L702.484 532.743L701.024 533.293L700.771 532.786Z'
        fill='url(#paint1229_linear_3150_15389)'
      />
      <path
        d='M738.085 480.666L739.361 479.792L739.902 480.515L738.579 481.324L738.085 480.666Z'
        fill='url(#paint1230_linear_3150_15389)'
      />
      <path
        d='M686.11 548.621L687.57 548.06L687.789 548.578L686.294 549.052L686.11 548.621Z'
        fill='url(#paint1231_linear_3150_15389)'
      />
      <path
        d='M405.411 -9.80807L404.33 -10.164L404.341 -10.6171L405.434 -10.3474L405.411 -9.80807Z'
        fill='url(#paint1232_linear_3150_15389)'
      />
      <path
        d='M670.425 563.56L671.92 563.096L672.07 563.539L670.54 563.916L670.425 563.56Z'
        fill='url(#paint1233_linear_3150_15389)'
      />
      <path
        d='M758.541 436.117L759.185 434.812L760.186 435.449L759.473 436.711L758.541 436.117Z'
        fill='url(#paint1234_linear_3150_15389)'
      />
      <path
        d='M372.49 -6.55054L371.133 -6.89572L371.167 -7.26245L372.536 -7.00357L372.49 -6.55054Z'
        fill='url(#paint1235_linear_3150_15389)'
      />
      <path
        d='M653.775 577.572L655.293 577.184L655.396 577.55L653.844 577.841L653.775 577.572Z'
        fill='url(#paint1236_linear_3150_15389)'
      />
      <path
        d='M729.507 162.118L730.334 160.91L731.691 161.147L730.806 162.388L729.507 162.118Z'
        fill='url(#paint1237_linear_3150_15389)'
      />
      <path
        d='M636.216 590.58L637.757 590.289L637.815 590.57L636.251 590.775L636.216 590.58Z'
        fill='url(#paint1238_linear_3150_15389)'
      />
      <path
        d='M479.406 -6.73391L478.291 -7.75864L478.9 -8.4274L480.061 -7.45662L479.406 -6.73391Z'
        fill='url(#paint1239_linear_3150_15389)'
      />
      <path
        d='M444.105 -9.91595L443.208 -10.9514L443.702 -11.5555L444.656 -10.5739L444.105 -9.91595Z'
        fill='url(#paint1240_linear_3150_15389)'
      />
      <path
        d='M617.83 602.553L619.382 602.359L619.416 602.543L617.841 602.651L617.83 602.553Z'
        fill='url(#paint1241_linear_3150_15389)'
      />
      <path
        d='M528.828 3.51328L527.77 2.39148L528.564 1.67957L529.668 2.74744L528.828 3.51328Z'
        fill='url(#paint1242_linear_3150_15389)'
      />
      <path
        d='M603.065 175.213L602.168 174.426L602.973 173.692L603.973 174.48L603.065 175.213Z'
        fill='url(#paint1243_linear_3150_15389)'
      />
      <path
        d='M505.382 -2.28987L504.278 -3.36852L504.968 -4.06966L506.129 -3.04492L505.382 -2.28987Z'
        fill='url(#paint1244_linear_3150_15389)'
      />
      <path
        d='M477.785 -28.0265H477.049L477.072 -28.5874L477.52 -28.6845L477.785 -28.0265Z'
        fill='url(#paint1245_linear_3150_15389)'
      />
      <path
        d='M346.341 -2.17122L344.892 -2.46246L344.915 -2.7429L346.376 -2.53796L346.341 -2.17122Z'
        fill='url(#paint1246_linear_3150_15389)'
      />
      <path
        d='M402.594 -10.0777L401.444 -10.4553L401.467 -10.9083L402.628 -10.6171L402.594 -10.0777Z'
        fill='url(#paint1247_linear_3150_15389)'
      />
      <path
        d='M322.803 3.62114L321.308 3.39463L321.32 3.21124L322.814 3.3407L322.803 3.62114Z'
        fill='url(#paint1248_linear_3150_15389)'
      />
      <path
        d='M540.868 24.8706L539.913 24.9137L539.787 23.889L540.753 23.7488L540.868 24.8706Z'
        fill='url(#paint1249_linear_3150_15389)'
      />
      <path
        d='M446.83 -10.1209L445.875 -11.1564L446.381 -11.7604L447.393 -10.7897L446.83 -10.1209Z'
        fill='url(#paint1250_linear_3150_15389)'
      />
      <path
        d='M595.338 163.898L594.567 163.251L595.234 162.442L596.108 163.1L595.338 163.898Z'
        fill='url(#paint1251_linear_3150_15389)'
      />
      <path
        d='M613.241 187.499L612.218 186.528L613.161 185.881L614.288 186.841L613.241 187.499Z'
        fill='url(#paint1252_linear_3150_15389)'
      />
      <path
        d='M527.977 3.77215L526.896 3.50249L526.873 2.49935L528.173 2.75822L527.977 3.77215Z'
        fill='url(#paint1253_linear_3150_15389)'
      />
      <path
        d='M284.328 -7.67235L283.477 -7.88808L283.741 -9.04222L284.615 -8.90201L284.328 -7.67235Z'
        fill='url(#paint1254_linear_3150_15389)'
      />
      <path
        d='M607.205 151.742L606.549 150.933L607.389 150.285L608.124 151.116L607.205 151.742Z'
        fill='url(#paint1255_linear_3150_15389)'
      />
      <path
        d='M449.474 -10.369L448.463 -11.3829L448.98 -12.0085L450.038 -11.0485L449.474 -10.369Z'
        fill='url(#paint1256_linear_3150_15389)'
      />
      <path
        d='M622.05 201.414L620.877 200.184L621.992 199.666L623.199 200.81L622.05 201.414Z'
        fill='url(#paint1257_linear_3150_15389)'
      />
      <path
        d='M399.845 -10.3798L398.649 -10.7681L398.684 -11.2211L399.903 -10.9191L399.845 -10.3798Z'
        fill='url(#paint1258_linear_3150_15389)'
      />
      <path
        d='M701.472 239.339L701.277 237.764L702.691 237.775L702.898 239.296L701.472 239.339Z'
        fill='url(#paint1259_linear_3150_15389)'
      />
      <path
        d='M546.238 -14.9209L545.364 -15.115L545.318 -16.0966L546.238 -15.9672V-14.9209Z'
        fill='url(#paint1260_linear_3150_15389)'
      />
      <path
        d='M369.557 -7.19774L368.154 -7.56448L368.2 -7.93121L369.615 -7.65077L369.557 -7.19774Z'
        fill='url(#paint1261_linear_3150_15389)'
      />
      <path
        d='M552.182 10.0715L551.182 8.90655L552.067 8.18383L553.114 9.31642L552.182 10.0715Z'
        fill='url(#paint1262_linear_3150_15389)'
      />
      <path
        d='M482.062 -7.34875L480.912 -8.37347L481.522 -9.04222L482.718 -8.08223L482.062 -7.34875Z'
        fill='url(#paint1263_linear_3150_15389)'
      />
      <path
        d='M531.208 218.046L529.461 217.356L529.978 216.741L531.749 217.259L531.208 218.046Z'
        fill='url(#paint1264_linear_3150_15389)'
      />
      <path
        d='M452.039 -10.6494L450.981 -11.6526L451.487 -12.2782L452.602 -11.329L452.039 -10.6494Z'
        fill='url(#paint1265_linear_3150_15389)'
      />
      <path
        d='M359.013 118.368L358.587 117.937L359.024 117.322L359.208 117.678L359.013 118.368Z'
        fill='url(#paint1266_linear_3150_15389)'
      />
      <path
        d='M397.201 -10.7141L395.947 -11.124L396.005 -11.5771L397.27 -11.2535L397.201 -10.7141Z'
        fill='url(#paint1267_linear_3150_15389)'
      />
      <path
        d='M299.173 10.1686L297.632 10.0283V9.93126L299.173 9.9744V10.1686Z'
        fill='url(#paint1268_linear_3150_15389)'
      />
      <path
        d='M654.511 138.194L654.407 137.18L655.488 136.953L655.603 138.021L654.511 138.194Z'
        fill='url(#paint1269_linear_3150_15389)'
      />
      <path
        d='M628.972 13.178L628.236 11.6895L629.351 11.1717L630.007 12.4661L628.972 13.178Z'
        fill='url(#paint1270_linear_3150_15389)'
      />
      <path
        d='M454.5 -10.9514L453.396 -11.9438L453.913 -12.5802L455.063 -11.6418L454.5 -10.9514Z'
        fill='url(#paint1271_linear_3150_15389)'
      />
      <path
        d='M508.015 -3.19593L506.888 -4.2638L507.578 -4.97572L508.763 -3.96178L508.015 -3.19593Z'
        fill='url(#paint1272_linear_3150_15389)'
      />
      <path
        d='M311.454 142.573L311.028 142.217L311.603 141.548L311.798 141.926L311.454 142.573Z'
        fill='url(#paint1273_linear_3150_15389)'
      />
      <path
        d='M277.716 -13.9285L277.796 -14.9101L277.969 -15.8054L278.049 -14.9209L277.716 -13.9285Z'
        fill='url(#paint1274_linear_3150_15389)'
      />
      <path
        d='M343.317 -3.09886L341.834 -3.41167L341.868 -3.69212L343.363 -3.47639L343.317 -3.09886Z'
        fill='url(#paint1275_linear_3150_15389)'
      />
      <path
        d='M735.831 152.162L736.705 150.846L738.016 151.084L737.073 152.41L735.831 152.162Z'
        fill='url(#paint1276_linear_3150_15389)'
      />
      <path
        d='M394.659 -11.0809L393.348 -11.5016L393.417 -11.9546L394.74 -11.6202L394.659 -11.0809Z'
        fill='url(#paint1277_linear_3150_15389)'
      />
      <path
        d='M294.297 -15.5357L293.446 -15.7622L293.665 -16.8409L294.539 -16.6899L294.297 -15.5357Z'
        fill='url(#paint1278_linear_3150_15389)'
      />
      <path
        d='M728.587 126.48L729.254 125.088L730.668 125.077L729.898 126.587L728.587 126.48Z'
        fill='url(#paint1279_linear_3150_15389)'
      />
      <path
        d='M719.411 75.632L719.859 73.8522L721.112 73.9061L720.641 75.7075L719.411 75.632Z'
        fill='url(#paint1280_linear_3150_15389)'
      />
      <path
        d='M366.821 -7.8773L365.383 -8.26561L365.441 -8.63235L366.89 -8.33033L366.821 -7.8773Z'
        fill='url(#paint1281_linear_3150_15389)'
      />
      <path
        d='M484.5 -8.00673L483.327 -9.00987L483.937 -9.7002L485.156 -8.7402L484.5 -8.00673Z'
        fill='url(#paint1282_linear_3150_15389)'
      />
      <path
        d='M531.53 2.35912L530.461 1.24811L531.243 0.525414L532.37 1.59327L531.53 2.35912Z'
        fill='url(#paint1283_linear_3150_15389)'
      />
      <path
        d='M456.845 -11.2858L455.707 -12.2782L456.224 -12.9146L457.42 -11.987L456.845 -11.2858Z'
        fill='url(#paint1284_linear_3150_15389)'
      />
      <path
        d='M331.197 -16.6683L330.852 -18.1137V-18.1245L331.232 -16.7222L331.197 -16.6683Z'
        fill='url(#paint1285_linear_3150_15389)'
      />
      <path
        d='M706.371 371.679L707.095 370.039L707.981 370.611L707.314 372.046L706.371 371.679Z'
        fill='url(#paint1286_linear_3150_15389)'
      />
      <path
        d='M504.98 -36.893L505.244 -38.3169L505.233 -38.0148L504.865 -36.591L504.98 -36.893Z'
        fill='url(#paint1287_linear_3150_15389)'
      />
      <path
        d='M319.618 2.43463L318.088 2.19733L318.1 2.01396L319.641 2.15417L319.618 2.43463Z'
        fill='url(#paint1288_linear_3150_15389)'
      />
      <path
        d='M610.746 199.936L609.585 198.933L610.516 198.296L611.758 199.245L610.746 199.936Z'
        fill='url(#paint1289_linear_3150_15389)'
      />
      <path
        d='M547.997 12.1317L547.054 11.9376L546.95 10.8481L548.158 11.0315L547.997 12.1317Z'
        fill='url(#paint1290_linear_3150_15389)'
      />
      <path
        d='M392.222 -11.4692L390.876 -11.9114L390.957 -12.3645L392.314 -12.0085L392.222 -11.4692Z'
        fill='url(#paint1291_linear_3150_15389)'
      />
      <path
        d='M720.261 71.9214L720.744 70.1093L722.101 69.9582L721.584 71.7812L720.261 71.9214Z'
        fill='url(#paint1292_linear_3150_15389)'
      />
      <path
        d='M463.101 -22.493L461.928 -22.8166L462.031 -23.4638L463.066 -23.2265L463.101 -22.493Z'
        fill='url(#paint1293_linear_3150_15389)'
      />
      <path
        d='M459.088 -11.6526L457.915 -12.6234L458.432 -13.2706L459.663 -12.3645L459.088 -11.6526Z'
        fill='url(#paint1294_linear_3150_15389)'
      />
      <path
        d='M713.799 98.4994L714.179 97.0216L715.627 96.7088L715.19 98.316L713.799 98.4994Z'
        fill='url(#paint1295_linear_3150_15389)'
      />
      <path
        d='M497.137 -28.5227L495.861 -28.9973L496.102 -29.6984L497.091 -29.3748L497.137 -28.5227Z'
        fill='url(#paint1296_linear_3150_15389)'
      />
      <path
        d='M642.345 215.458L641.322 213.937L642.564 213.592L643.598 214.983L642.345 215.458Z'
        fill='url(#paint1297_linear_3150_15389)'
      />
      <path
        d='M756.931 430.012L757.414 428.642L758.518 429.182L757.955 430.552L756.931 430.012Z'
        fill='url(#paint1298_linear_3150_15389)'
      />
      <path
        d='M717.145 63.7021L717.571 61.8575L719.089 61.3722L718.64 63.2382L717.145 63.7021Z'
        fill='url(#paint1299_linear_3150_15389)'
      />
      <path
        d='M730.794 166.174L731.611 164.987L732.853 165.268L731.967 166.476L730.794 166.174Z'
        fill='url(#paint1300_linear_3150_15389)'
      />
      <path
        d='M702.243 254.785L702.105 253.07L703.496 253.059L703.669 254.623L702.243 254.785Z'
        fill='url(#paint1301_linear_3150_15389)'
      />
      <path
        d='M494.435 173.023L494.044 172.711L494.182 172.29L494.585 172.603L494.435 173.023Z'
        fill='url(#paint1302_linear_3150_15389)'
      />
      <path
        d='M486.731 -8.68628L485.535 -9.67864L486.133 -10.3798L487.375 -9.43054L486.731 -8.68628Z'
        fill='url(#paint1303_linear_3150_15389)'
      />
      <path
        d='M389.91 -11.8899L388.519 -12.3429L388.611 -12.7959L390.014 -12.4292L389.91 -11.8899Z'
        fill='url(#paint1304_linear_3150_15389)'
      />
      <path
        d='M510.361 -4.13437L509.211 -5.19144L509.901 -5.91414L511.097 -4.911L510.361 -4.13437Z'
        fill='url(#paint1305_linear_3150_15389)'
      />
      <path
        d='M528.69 28.1497L527.678 28.085L527.598 27.0818L528.633 27.0602L528.69 28.1497Z'
        fill='url(#paint1306_linear_3150_15389)'
      />
      <path
        d='M364.302 -8.58919L362.831 -8.9883L362.899 -9.35504L364.383 -9.04222L364.302 -8.58919Z'
        fill='url(#paint1307_linear_3150_15389)'
      />
      <path
        d='M594.613 198.609L593.555 197.832L594.257 197.11L595.441 197.854L594.613 198.609Z'
        fill='url(#paint1308_linear_3150_15389)'
      />
      <path
        d='M315.904 -12.084L314.857 -11.9007L314.834 -12.742L315.904 -13.044V-12.084Z'
        fill='url(#paint1309_linear_3150_15389)'
      />
      <path
        d='M461.215 -12.0409L459.996 -13.0009L460.513 -13.6589L461.778 -12.7636L461.215 -12.0409Z'
        fill='url(#paint1310_linear_3150_15389)'
      />
      <path
        d='M340.592 -4.06966L339.074 -4.40404L339.12 -4.68448L340.638 -4.44717L340.592 -4.06966Z'
        fill='url(#paint1311_linear_3150_15389)'
      />
      <path
        d='M513.512 -11.0917L514.006 -12.5478V-12.6234L513.834 -11.1025L513.512 -11.0917Z'
        fill='url(#paint1312_linear_3150_15389)'
      />
      <path
        d='M554.839 8.7016L553.815 7.53665L554.689 6.80316L555.759 7.92496L554.839 8.7016Z'
        fill='url(#paint1313_linear_3150_15389)'
      />
      <path
        d='M614.932 141.031L614.426 140.178L615.288 139.607L615.84 140.491L614.932 141.031Z'
        fill='url(#paint1314_linear_3150_15389)'
      />
      <path
        d='M713.65 80.6477L714.018 79.0189L715.512 78.5659L715.098 80.3025L713.65 80.6477Z'
        fill='url(#paint1315_linear_3150_15389)'
      />
      <path
        d='M495.171 223.806L492.871 222.749L493.285 222.199L495.631 223.105L495.171 223.806Z'
        fill='url(#paint1316_linear_3150_15389)'
      />
      <path
        d='M331.404 137.622L331.266 137.201L331.542 136.716L331.623 137.126L331.404 137.622Z'
        fill='url(#paint1317_linear_3150_15389)'
      />
      <path
        d='M321.584 -5.84943L320.607 -5.80627L320.595 -6.56134L321.63 -6.74471L321.584 -5.84943Z'
        fill='url(#paint1318_linear_3150_15389)'
      />
      <path
        d='M619.554 215.22L618.174 213.786L619.301 213.354L620.658 214.584L619.554 215.22Z'
        fill='url(#paint1319_linear_3150_15389)'
      />
      <path
        d='M447.405 215.814L445.576 214.897L445.726 214.347L447.75 215.263L447.405 215.814Z'
        fill='url(#paint1320_linear_3150_15389)'
      />
      <path
        d='M570.167 211.413L568.948 210.744L569.488 210.043L570.822 210.604L570.167 211.413Z'
        fill='url(#paint1321_linear_3150_15389)'
      />
      <path
        d='M620.21 605.347L621.785 605.164L621.808 605.358L620.21 605.444V605.347Z'
        fill='url(#paint1322_linear_3150_15389)'
      />
      <path
        d='M295.953 8.76631L294.378 8.60451L294.389 8.50743L295.953 8.57215V8.76631Z'
        fill='url(#paint1323_linear_3150_15389)'
      />
      <path
        d='M288.697 -11.178L288.571 -12.7097L288.847 -12.6126L288.996 -11.1025L288.697 -11.178Z'
        fill='url(#paint1324_linear_3150_15389)'
      />
      <path
        d='M387.726 -12.3429L386.311 -12.8175L386.403 -13.2598L387.841 -12.8822L387.726 -12.3429Z'
        fill='url(#paint1325_linear_3150_15389)'
      />
      <path
        d='M362.831 2.71507L362.715 1.77666L362.796 1.37754L363.106 2.19733L362.831 2.71507Z'
        fill='url(#paint1326_linear_3150_15389)'
      />
      <path
        d='M638.884 593.536L640.448 593.266L640.494 593.547L638.907 593.73L638.884 593.536Z'
        fill='url(#paint1327_linear_3150_15389)'
      />
      <path
        d='M463.193 -12.4616L461.951 -13.4108L462.457 -14.0687L463.756 -13.1843L463.193 -12.4616Z'
        fill='url(#paint1328_linear_3150_15389)'
      />
      <path
        d='M533.865 1.18339L532.772 0.0723801L533.543 -0.67189L534.693 0.395981L533.865 1.18339Z'
        fill='url(#paint1329_linear_3150_15389)'
      />
      <path
        d='M714.064 50.0787L714.385 48.2558L715.696 48.04L715.328 50.0571L714.064 50.0787Z'
        fill='url(#paint1330_linear_3150_15389)'
      />
      <path
        d='M534.854 179.711L534.681 179.571L534.635 178.848L534.934 178.945L534.854 179.711Z'
        fill='url(#paint1331_linear_3150_15389)'
      />
      <path
        d='M605.468 214.152L604.065 213.009L604.997 212.427L606.492 213.494L605.468 214.152Z'
        fill='url(#paint1332_linear_3150_15389)'
      />
      <path
        d='M608.561 184.522L607.757 183.734L608.492 183.012L609.435 183.81L608.561 184.522Z'
        fill='url(#paint1333_linear_3150_15389)'
      />
      <path
        d='M656.73 580.646L658.283 580.279L658.375 580.646L656.799 580.927L656.73 580.646Z'
        fill='url(#paint1334_linear_3150_15389)'
      />
      <path
        d='M513.753 53.8324L513.834 54.3393L513.558 53.6275L513.523 53.0342L513.753 53.8324Z'
        fill='url(#paint1335_linear_3150_15389)'
      />
      <path
        d='M488.732 -9.39818L487.513 -10.3798L488.099 -11.0809L489.376 -10.1533L488.732 -9.39818Z'
        fill='url(#paint1336_linear_3150_15389)'
      />
      <path
        d='M557.736 -16.3016L557.092 -16.4418L556.977 -17.4665L557.656 -17.4018L557.736 -16.3016Z'
        fill='url(#paint1337_linear_3150_15389)'
      />
      <path
        d='M517.214 -26.84L515.915 -27.5195L516.271 -28.2638L517.306 -27.7568L517.214 -26.84Z'
        fill='url(#paint1338_linear_3150_15389)'
      />
      <path
        d='M316.812 1.22653L315.26 0.967667L315.271 0.784279L316.846 0.946083L316.812 1.22653Z'
        fill='url(#paint1339_linear_3150_15389)'
      />
      <path
        d='M362.003 -9.33347L360.508 -9.75414L360.588 -10.1209L362.095 -9.7865L362.003 -9.33347Z'
        fill='url(#paint1340_linear_3150_15389)'
      />
      <path
        d='M479.383 51.6104L478.923 51.7722L478.693 51.0063L479.165 50.7582L479.383 51.6104Z'
        fill='url(#paint1341_linear_3150_15389)'
      />
      <path
        d='M673.691 566.71L675.209 566.257L675.358 566.71L673.806 567.077L673.691 566.71Z'
        fill='url(#paint1342_linear_3150_15389)'
      />
      <path
        d='M564.82 193.432L564.302 193.14L564.486 192.288L565.188 192.547L564.82 193.432Z'
        fill='url(#paint1343_linear_3150_15389)'
      />
      <path
        d='M721.239 434.877L721.354 433.421L722.481 433.68L722.285 435.093L721.239 434.877Z'
        fill='url(#paint1344_linear_3150_15389)'
      />
      <path
        d='M728.426 99.9232L729.093 98.1542L730.3 98.3592L729.656 99.9556L728.426 99.9232Z'
        fill='url(#paint1345_linear_3150_15389)'
      />
      <path
        d='M385.679 -12.8067L384.23 -13.3029L384.345 -13.7559L385.817 -13.3461L385.679 -12.8067Z'
        fill='url(#paint1346_linear_3150_15389)'
      />
      <path
        d='M716.248 87.4324L716.697 85.7605L718.031 85.6311L717.571 87.2382L716.248 87.4324Z'
        fill='url(#paint1347_linear_3150_15389)'
      />
      <path
        d='M415.932 -14.8454L414.69 -15.3416L414.782 -15.8809L416.036 -15.4602L415.932 -14.8454Z'
        fill='url(#paint1348_linear_3150_15389)'
      />
      <path
        d='M489.169 43.4773L488.766 43.6391L488.502 42.8733L488.973 42.6144L489.169 43.4773Z'
        fill='url(#paint1349_linear_3150_15389)'
      />
      <path
        d='M465.044 -12.9038L463.779 -13.8422L464.274 -14.5002L465.584 -13.6373L465.044 -12.9038Z'
        fill='url(#paint1350_linear_3150_15389)'
      />
      <path
        d='M676.888 232.921L676.359 231.228L677.704 231.109L678.245 232.641L676.888 232.921Z'
        fill='url(#paint1351_linear_3150_15389)'
      />
      <path
        d='M689.686 551.803L691.181 551.264L691.388 551.792L689.858 552.245L689.686 551.803Z'
        fill='url(#paint1352_linear_3150_15389)'
      />
      <path
        d='M731.358 441.079L731.749 439.709L732.772 440.108L732.312 441.435L731.358 441.079Z'
        fill='url(#paint1353_linear_3150_15389)'
      />
      <path
        d='M512.396 -5.10515L511.223 -6.15144L511.902 -6.88492L513.121 -5.89256L512.396 -5.10515Z'
        fill='url(#paint1354_linear_3150_15389)'
      />
      <path
        d='M427.903 -14.8562L426.948 -15.8377L427.374 -16.4094L428.386 -15.4926L427.903 -14.8562Z'
        fill='url(#paint1355_linear_3150_15389)'
      />
      <path
        d='M329.932 -1.02785L329.127 -1.25437L329.15 -1.89077L330.093 -1.84762L329.932 -1.02785Z'
        fill='url(#paint1356_linear_3150_15389)'
      />
      <path
        d='M429.708 -14.8777L428.696 -15.8485L429.121 -16.431L430.191 -15.5249L429.708 -14.8777Z'
        fill='url(#paint1357_linear_3150_15389)'
      />
      <path
        d='M493.987 28.8185L492.86 28.6243L492.871 27.729L493.975 27.8477L493.987 28.8185Z'
        fill='url(#paint1358_linear_3150_15389)'
      />
      <path
        d='M431.502 -14.9317L430.421 -15.8809L430.858 -16.4742L431.985 -15.5789L431.502 -14.9317Z'
        fill='url(#paint1359_linear_3150_15389)'
      />
      <path
        d='M414.115 -14.9856L412.839 -15.4926L412.943 -16.0319L414.253 -15.6004L414.115 -14.9856Z'
        fill='url(#paint1360_linear_3150_15389)'
      />
      <path
        d='M538.625 -8.84808L537.832 -9.09616L537.786 -10.0238L538.614 -9.84044L538.625 -8.84808Z'
        fill='url(#paint1361_linear_3150_15389)'
      />
      <path
        d='M675.6 267.589L676.025 265.906L676.531 266.748L676.002 268.441L675.6 267.589Z'
        fill='url(#paint1362_linear_3150_15389)'
      />
      <path
        d='M704.658 535.979L706.118 535.353L706.394 535.947L704.899 536.497L704.658 535.979Z'
        fill='url(#paint1363_linear_3150_15389)'
      />
      <path
        d='M433.261 -14.9964L432.146 -15.9456L432.583 -16.5389L433.756 -15.6544L433.261 -14.9964Z'
        fill='url(#paint1364_linear_3150_15389)'
      />
      <path
        d='M338.177 -5.07279L336.636 -5.41797L336.682 -5.69841L338.234 -5.43953L338.177 -5.07279Z'
        fill='url(#paint1365_linear_3150_15389)'
      />
      <path
        d='M434.997 -15.0827L433.836 -16.0211L434.273 -16.6144L435.492 -15.7514L434.997 -15.0827Z'
        fill='url(#paint1366_linear_3150_15389)'
      />
      <path
        d='M739.545 361.421L740.384 360.256L741.293 360.925L740.384 362.09L739.545 361.421Z'
        fill='url(#paint1367_linear_3150_15389)'
      />
      <path
        d='M792.704 455.932L794.142 454.994L794.809 456.029L793.348 456.849L792.704 455.932Z'
        fill='url(#paint1368_linear_3150_15389)'
      />
      <path
        d='M412.356 -15.1366L411.022 -15.6759L411.149 -16.2045L412.494 -15.7622L412.356 -15.1366Z'
        fill='url(#paint1369_linear_3150_15389)'
      />
      <path
        d='M593.406 211.337L592.187 210.431L592.9 209.784L594.245 210.604L593.406 211.337Z'
        fill='url(#paint1370_linear_3150_15389)'
      />
      <path
        d='M718.548 519.292L719.951 518.58L720.319 519.228L718.87 519.864L718.548 519.292Z'
        fill='url(#paint1371_linear_3150_15389)'
      />
      <path
        d='M739.315 407.576L740.557 406.465L741.166 407.382L740.017 408.277L739.315 407.576Z'
        fill='url(#paint1372_linear_3150_15389)'
      />
      <path
        d='M584.425 194.899L583.689 194.359L584.115 193.55L585.012 194.068L584.425 194.899Z'
        fill='url(#paint1373_linear_3150_15389)'
      />
      <path
        d='M436.699 -15.1906L435.492 -16.1182L435.929 -16.7222L437.182 -15.8701L436.699 -15.1906Z'
        fill='url(#paint1374_linear_3150_15389)'
      />
      <path
        d='M502.024 33.7155L501.656 33.8881L501.403 33.1115L501.817 32.8418L502.024 33.7155Z'
        fill='url(#paint1375_linear_3150_15389)'
      />
      <path
        d='M660.203 15.2922L659.858 13.5987L661.065 13.329L661.399 14.9254L660.203 15.2922Z'
        fill='url(#paint1376_linear_3150_15389)'
      />
      <path
        d='M726.011 352.964L726.85 351.691L727.747 352.63L726.862 353.849L726.011 352.964Z'
        fill='url(#paint1377_linear_3150_15389)'
      />
      <path
        d='M466.734 -13.3676L465.458 -14.2845L465.941 -14.964L467.275 -14.1011L466.734 -13.3676Z'
        fill='url(#paint1378_linear_3150_15389)'
      />
      <path
        d='M383.781 -13.3029L382.31 -13.8099L382.436 -14.2629L383.931 -13.8422L383.781 -13.3029Z'
        fill='url(#paint1379_linear_3150_15389)'
      />
      <path
        d='M410.631 -15.32L409.274 -15.8701L409.412 -16.3986L410.792 -15.9348L410.631 -15.32Z'
        fill='url(#paint1380_linear_3150_15389)'
      />
      <path
        d='M731.289 501.807L732.657 501.009L733.106 501.71L731.691 502.433L731.289 501.807Z'
        fill='url(#paint1381_linear_3150_15389)'
      />
      <path
        d='M438.367 -15.3308L437.113 -16.2368L437.55 -16.8409L438.838 -15.9995L438.367 -15.3308Z'
        fill='url(#paint1382_linear_3150_15389)'
      />
      <path
        d='M490.503 -10.1317L489.272 -11.1025L489.847 -11.8144L491.123 -10.8975L490.503 -10.1317Z'
        fill='url(#paint1383_linear_3150_15389)'
      />
      <path
        d='M359.944 -10.0993L358.426 -10.5308L358.507 -10.8975L360.048 -10.5523L359.944 -10.0993Z'
        fill='url(#paint1384_linear_3150_15389)'
      />
      <path
        d='M724.275 365.401L724.964 364.096L725.942 364.57L725.148 365.94L724.275 365.401Z'
        fill='url(#paint1385_linear_3150_15389)'
      />
      <path
        d='M742.845 483.589L744.145 482.715L744.697 483.459L743.34 484.258L742.845 483.589Z'
        fill='url(#paint1386_linear_3150_15389)'
      />
      <path
        d='M439.976 -15.4818L438.689 -16.3663L439.125 -16.9811L440.448 -16.1613L439.976 -15.4818Z'
        fill='url(#paint1387_linear_3150_15389)'
      />
      <path
        d='M296.988 -21.1339L296.034 -21.1123L296.103 -22.0831L297.126 -22.2557L296.988 -21.1339Z'
        fill='url(#paint1388_linear_3150_15389)'
      />
      <path
        d='M408.975 -15.5249L407.573 -16.0858L407.734 -16.6144L409.148 -16.129L408.975 -15.5249Z'
        fill='url(#paint1389_linear_3150_15389)'
      />
      <path
        d='M557.012 7.28857L555.977 6.1344L556.851 5.37935L557.932 6.50114L557.012 7.28857Z'
        fill='url(#paint1390_linear_3150_15389)'
      />
      <path
        d='M719.664 74.1434L720.066 72.3636L721.296 72.4607L720.871 74.2513L719.664 74.1434Z'
        fill='url(#paint1391_linear_3150_15389)'
      />
      <path
        d='M482.143 224.443L479.797 223.202L480.13 222.652L482.545 223.763L482.143 224.443Z'
        fill='url(#paint1392_linear_3150_15389)'
      />
      <path
        d='M441.529 -15.6436L440.218 -16.5281L440.643 -17.1429L442 -16.3339L441.529 -15.6436Z'
        fill='url(#paint1393_linear_3150_15389)'
      />
      <path
        d='M412 -20.4759L412.276 -21.9213V-21.9753L412.057 -20.573L412 -20.4759Z'
        fill='url(#paint1394_linear_3150_15389)'
      />
      <path
        d='M345.318 1.57171L344.846 0.978447L344.927 0.449898L345.594 0.881371L345.318 1.57171Z'
        fill='url(#paint1395_linear_3150_15389)'
      />
      <path
        d='M407.366 -15.7407L405.951 -16.3231L406.124 -16.8517L407.561 -16.3447L407.366 -15.7407Z'
        fill='url(#paint1396_linear_3150_15389)'
      />
      <path
        d='M197.132 11.0746L198.949 11.3443L198.891 11.7865L197.143 11.614L197.132 11.0746Z'
        fill='url(#paint1397_linear_3150_15389)'
      />
      <path
        d='M535.785 -0.0354919L534.681 -1.13572L535.452 -1.89077L536.601 -0.833702L535.785 -0.0354919Z'
        fill='url(#paint1398_linear_3150_15389)'
      />
      <path
        d='M593.394 182.365L592.75 181.739L593.256 180.973L594.038 181.599L593.394 182.365Z'
        fill='url(#paint1399_linear_3150_15389)'
      />
      <path
        d='M515.639 23.5762L515.352 23.7596L515.087 22.9506L515.455 22.6809L515.639 23.5762Z'
        fill='url(#paint1400_linear_3150_15389)'
      />
      <path
        d='M708.257 99.1574L708.579 97.7012L709.89 97.4854L709.545 98.974L708.257 99.1574Z'
        fill='url(#paint1401_linear_3150_15389)'
      />
      <path
        d='M468.275 -13.853L466.987 -14.7591L467.47 -15.4386L468.804 -14.5973L468.275 -13.853Z'
        fill='url(#paint1402_linear_3150_15389)'
      />
      <path
        d='M604.663 171.384L604.077 170.704L604.663 169.928L605.376 170.629L604.663 171.384Z'
        fill='url(#paint1403_linear_3150_15389)'
      />
      <path
        d='M304.957 -17.6283L303.899 -17.2939L303.83 -18.1245L304.922 -18.5991L304.957 -17.6283Z'
        fill='url(#paint1404_linear_3150_15389)'
      />
      <path
        d='M443.024 -15.8377L441.701 -16.7007L442.104 -17.3263L443.484 -16.5281L443.024 -15.8377Z'
        fill='url(#paint1405_linear_3150_15389)'
      />
      <path
        d='M382.034 -13.8207L380.55 -14.3492L380.688 -14.8022L382.195 -14.36L382.034 -13.8207Z'
        fill='url(#paint1406_linear_3150_15389)'
      />
      <path
        d='M293.193 7.32092L291.606 7.14834V7.05126L293.205 7.13756L293.193 7.32092Z'
        fill='url(#paint1407_linear_3150_15389)'
      />
      <path
        d='M595.568 160.921L595.085 160.36L595.545 159.508L596.143 160.08L595.568 160.921Z'
        fill='url(#paint1408_linear_3150_15389)'
      />
      <path
        d='M455.787 208.209L454.431 207.325L454.603 206.807L456.04 207.67L455.787 208.209Z'
        fill='url(#paint1409_linear_3150_15389)'
      />
      <path
        d='M275.75 -14.3276L274.979 -14.511L275.267 -15.6544L276.002 -15.5249L275.75 -14.3276Z'
        fill='url(#paint1410_linear_3150_15389)'
      />
      <path
        d='M749.814 449.806L750.32 448.425L751.366 448.953L750.779 450.323L749.814 449.806Z'
        fill='url(#paint1411_linear_3150_15389)'
      />
      <path
        d='M706.233 46.7564L706.428 44.9335L707.67 44.858L707.475 46.7241L706.233 46.7564Z'
        fill='url(#paint1412_linear_3150_15389)'
      />
      <path
        d='M712.81 355.607L713.466 354.194L714.409 354.755L713.684 356.211L712.81 355.607Z'
        fill='url(#paint1413_linear_3150_15389)'
      />
      <path
        d='M514.098 -6.10831L512.925 -7.1438L513.581 -7.8773L514.811 -6.9065L514.098 -6.10831Z'
        fill='url(#paint1414_linear_3150_15389)'
      />
      <path
        d='M405.836 -15.978L404.399 -16.5712L404.583 -17.0998L406.043 -16.582L405.836 -15.978Z'
        fill='url(#paint1415_linear_3150_15389)'
      />
      <path
        d='M314.409 -0.0247116L312.833 -0.294373L312.845 -0.488525L314.432 -0.305153L314.409 -0.0247116Z'
        fill='url(#paint1416_linear_3150_15389)'
      />
      <path
        d='M444.449 -16.0427L443.104 -16.884L443.507 -17.5204L444.898 -16.7438L444.449 -16.0427Z'
        fill='url(#paint1417_linear_3150_15389)'
      />
      <path
        d='M516.386 -2.44088L515.455 -2.81841L515.49 -3.67054L516.478 -3.34695L516.386 -2.44088Z'
        fill='url(#paint1418_linear_3150_15389)'
      />
      <path
        d='M336.073 -6.09751L334.52 -6.46425L334.566 -6.74471L336.142 -6.47504L336.073 -6.09751Z'
        fill='url(#paint1419_linear_3150_15389)'
      />
      <path
        d='M492.032 -10.8867L490.79 -11.8575L491.342 -12.5694L492.641 -11.6634L492.032 -10.8867Z'
        fill='url(#paint1420_linear_3150_15389)'
      />
      <path
        d='M481.993 222.307L479.97 221.174L480.199 220.592L482.43 221.703L481.993 222.307Z'
        fill='url(#paint1421_linear_3150_15389)'
      />
      <path
        d='M728.564 165.991L729.3 164.847L730.472 165.095L729.656 166.303L728.564 165.991Z'
        fill='url(#paint1422_linear_3150_15389)'
      />
      <path
        d='M513.627 30.6414L512.5 30.4148L512.511 29.4333L513.638 29.5735L513.627 30.6414Z'
        fill='url(#paint1423_linear_3150_15389)'
      />
      <path
        d='M404.376 -16.2261L402.916 -16.8409L403.123 -17.3694L404.594 -16.8409L404.376 -16.2261Z'
        fill='url(#paint1424_linear_3150_15389)'
      />
      <path
        d='M445.806 -16.2692L444.449 -17.0998L444.84 -17.7362L446.243 -16.9703L445.806 -16.2692Z'
        fill='url(#paint1425_linear_3150_15389)'
      />
      <path
        d='M427.029 200.529L425.879 199.72L426.063 199.245L427.293 200.044L427.029 200.529Z'
        fill='url(#paint1426_linear_3150_15389)'
      />
      <path
        d='M358.127 -10.8867L356.587 -11.3398L356.679 -11.7065L358.242 -11.3398L358.127 -10.8867Z'
        fill='url(#paint1427_linear_3150_15389)'
      />
      <path
        d='M720.583 123.071L721.124 121.701L722.435 121.669L721.837 123.114L720.583 123.071Z'
        fill='url(#paint1428_linear_3150_15389)'
      />
      <path
        d='M518.376 15.4971L517.996 15.605L517.755 14.7636L518.215 14.591L518.376 15.4971Z'
        fill='url(#paint1429_linear_3150_15389)'
      />
      <path
        d='M469.667 -14.36L468.356 -15.2553L468.827 -15.9348L470.172 -15.115L469.667 -14.36Z'
        fill='url(#paint1430_linear_3150_15389)'
      />
      <path
        d='M445.093 211.736L443.518 210.733L443.656 210.161L445.427 211.219L445.093 211.736Z'
        fill='url(#paint1431_linear_3150_15389)'
      />
      <path
        d='M758.886 296.799L759.312 295.321L760.703 295.666L760.266 297.133L758.886 296.799Z'
        fill='url(#paint1432_linear_3150_15389)'
      />
      <path
        d='M380.458 -14.36L378.963 -14.8993L379.101 -15.3523L380.631 -14.8885L380.458 -14.36Z'
        fill='url(#paint1433_linear_3150_15389)'
      />
      <path
        d='M648.21 229.728L647.221 227.981L648.474 227.733L649.463 229.254L648.21 229.728Z'
        fill='url(#paint1434_linear_3150_15389)'
      />
      <path
        d='M340.914 137.493L340.143 137.406L340.557 136.705L341.017 136.716L340.914 137.493Z'
        fill='url(#paint1435_linear_3150_15389)'
      />
      <path
        d='M734.647 152.691L735.474 151.407L736.716 151.623L735.808 152.95L734.647 152.691Z'
        fill='url(#paint1436_linear_3150_15389)'
      />
      <path
        d='M447.094 -16.5065L445.726 -17.3263L446.105 -17.9627L447.508 -17.2184L447.094 -16.5065Z'
        fill='url(#paint1437_linear_3150_15389)'
      />
      <path
        d='M402.985 -16.4957L401.524 -17.1321L401.743 -17.6607L403.226 -17.0998L402.985 -16.4957Z'
        fill='url(#paint1438_linear_3150_15389)'
      />
      <path
        d='M746.318 353.072L747.295 352.09L748.158 353.072L747.077 354.118L746.318 353.072Z'
        fill='url(#paint1439_linear_3150_15389)'
      />
      <path
        d='M611.264 150.253L610.804 149.52L611.482 148.808L612.023 149.595L611.264 150.253Z'
        fill='url(#paint1440_linear_3150_15389)'
      />
      <path
        d='M621.866 608.195L623.464 608.033L623.475 608.227L621.866 608.292V608.195Z'
        fill='url(#paint1441_linear_3150_15389)'
      />
      <path
        d='M315.122 153.446L314.915 153.004L315.294 152.4L315.26 152.799L315.122 153.446Z'
        fill='url(#paint1442_linear_3150_15389)'
      />
      <path
        d='M425.626 -17.3047L424.211 -18.0166L424.499 -18.6099L425.948 -17.9735L425.626 -17.3047Z'
        fill='url(#paint1443_linear_3150_15389)'
      />
      <path
        d='M426.856 -17.3047L425.442 -18.0058L425.718 -18.5991L427.155 -17.9735L426.856 -17.3047Z'
        fill='url(#paint1444_linear_3150_15389)'
      />
      <path
        d='M428.075 -17.3155L426.684 -18.0058L426.937 -18.5991L428.363 -17.995L428.075 -17.3155Z'
        fill='url(#paint1445_linear_3150_15389)'
      />
      <path
        d='M424.407 -17.3155L422.993 -18.049L423.28 -18.6422L424.74 -17.9843L424.407 -17.3155Z'
        fill='url(#paint1446_linear_3150_15389)'
      />
      <path
        d='M429.282 -17.3479L427.903 -18.0166L428.144 -18.6099L429.558 -18.0274L429.282 -17.3479Z'
        fill='url(#paint1447_linear_3150_15389)'
      />
      <path
        d='M423.177 -17.3479L421.774 -18.1029L422.084 -18.6854L423.533 -18.0058L423.177 -17.3479Z'
        fill='url(#paint1448_linear_3150_15389)'
      />
      <path
        d='M430.478 -17.391L429.121 -18.0382L429.34 -18.6422L430.731 -18.0706L430.478 -17.391Z'
        fill='url(#paint1449_linear_3150_15389)'
      />
      <path
        d='M448.29 -16.7654L446.922 -17.5636L447.29 -18.2108L448.693 -17.4881L448.29 -16.7654Z'
        fill='url(#paint1450_linear_3150_15389)'
      />
      <path
        d='M421.969 -17.391L420.566 -18.1568L420.888 -18.7393L422.326 -18.049L421.969 -17.391Z'
        fill='url(#paint1451_linear_3150_15389)'
      />
      <path
        d='M740.396 109.912L741.247 108.056L742.408 108.401L741.546 110.106L740.396 109.912Z'
        fill='url(#paint1452_linear_3150_15389)'
      />
      <path
        d='M742.328 439.008L742.592 437.531L743.754 437.93L743.42 439.386L742.328 439.008Z'
        fill='url(#paint1453_linear_3150_15389)'
      />
      <path
        d='M431.663 -17.4449L430.329 -18.0813L430.524 -18.6854L431.893 -18.1353L431.663 -17.4449Z'
        fill='url(#paint1454_linear_3150_15389)'
      />
      <path
        d='M489.87 16.7268L488.996 16.511L488.95 15.6913L489.824 15.8207L489.87 16.7268Z'
        fill='url(#paint1455_linear_3150_15389)'
      />
      <path
        d='M793.578 400.576L795.096 400.069L795.602 401.223L794.061 401.665L793.578 400.576Z'
        fill='url(#paint1456_linear_3150_15389)'
      />
      <path
        d='M401.697 -16.787L400.225 -17.4342L400.455 -17.9627L401.95 -17.391L401.697 -16.787Z'
        fill='url(#paint1457_linear_3150_15389)'
      />
      <path
        d='M420.773 -17.4449L419.382 -18.2323L419.715 -18.8148L421.141 -18.1029L420.773 -17.4449Z'
        fill='url(#paint1458_linear_3150_15389)'
      />
      <path
        d='M470.874 -14.8885L469.563 -15.7622L470.023 -16.4526L471.38 -15.6436L470.874 -14.8885Z'
        fill='url(#paint1459_linear_3150_15389)'
      />
      <path
        d='M419.589 -17.5204L418.221 -18.3294L418.554 -18.8903L419.968 -18.1676L419.589 -17.5204Z'
        fill='url(#paint1460_linear_3150_15389)'
      />
      <path
        d='M493.308 -11.6634L492.055 -12.6234L492.595 -13.3461L493.895 -12.4616L493.308 -11.6634Z'
        fill='url(#paint1461_linear_3150_15389)'
      />
      <path
        d='M379.044 -14.9101L377.538 -15.471L377.699 -15.924L379.228 -15.4386L379.044 -14.9101Z'
        fill='url(#paint1462_linear_3150_15389)'
      />
      <path
        d='M298.908 -14.7699L298.816 -16.2584L299.069 -16.1506L299.23 -14.7051L298.908 -14.7699Z'
        fill='url(#paint1463_linear_3150_15389)'
      />
      <path
        d='M640.747 596.556L642.345 596.297L642.391 596.589L640.781 596.75L640.747 596.556Z'
        fill='url(#paint1464_linear_3150_15389)'
      />
      <path
        d='M418.428 -17.6067L417.082 -18.4265L417.427 -18.9982L418.819 -18.2539L418.428 -17.6067Z'
        fill='url(#paint1465_linear_3150_15389)'
      />
      <path
        d='M515.478 -7.13302L514.294 -8.14696L514.938 -8.90201L516.168 -7.93121L515.478 -7.13302Z'
        fill='url(#paint1466_linear_3150_15389)'
      />
      <path
        d='M449.405 -17.035L448.037 -17.8225L448.394 -18.4697L449.796 -17.7577L449.405 -17.035Z'
        fill='url(#paint1467_linear_3150_15389)'
      />
      <path
        d='M555.598 228.628L553.608 227.236L554.321 226.719L556.333 227.927L555.598 228.628Z'
        fill='url(#paint1468_linear_3150_15389)'
      />
      <path
        d='M679.601 248.885L679.095 247.03L680.441 246.89L680.97 248.551L679.601 248.885Z'
        fill='url(#paint1469_linear_3150_15389)'
      />
      <path
        d='M481.223 219.341L479.452 218.219L479.671 217.647L481.579 218.737L481.223 219.341Z'
        fill='url(#paint1470_linear_3150_15389)'
      />
      <path
        d='M537.303 -1.28673L536.188 -2.37617L536.935 -3.14201L538.108 -2.09572L537.303 -1.28673Z'
        fill='url(#paint1471_linear_3150_15389)'
      />
      <path
        d='M283.707 -21.7919L282.925 -21.9213L283.097 -22.9676L283.937 -22.9568L283.707 -21.7919Z'
        fill='url(#paint1472_linear_3150_15389)'
      />
      <path
        d='M356.552 -11.6957L355.011 -12.1703L355.115 -12.5371L356.679 -12.1487L356.552 -11.6957Z'
        fill='url(#paint1473_linear_3150_15389)'
      />
      <path
        d='M417.289 -17.7038L415.978 -18.5452L416.335 -19.106L417.692 -18.3402L417.289 -17.7038Z'
        fill='url(#paint1474_linear_3150_15389)'
      />
      <path
        d='M400.489 -17.089L399.017 -17.7577L399.259 -18.2755L400.754 -17.6822L400.489 -17.089Z'
        fill='url(#paint1475_linear_3150_15389)'
      />
      <path
        d='M558.702 5.84316L557.656 4.68901L558.507 3.93396L559.611 5.04497L558.702 5.84316Z'
        fill='url(#paint1476_linear_3150_15389)'
      />
      <path
        d='M409.734 -19.667L410.033 -21.0368L410.022 -21.1339L409.815 -19.7964L409.734 -19.667Z'
        fill='url(#paint1477_linear_3150_15389)'
      />
      <path
        d='M807.572 420.207L808.86 419.183L809.746 420.164L808.377 421.157L807.572 420.207Z'
        fill='url(#paint1478_linear_3150_15389)'
      />
      <path
        d='M334.313 -7.15458L332.738 -7.53212L332.795 -7.81256L334.382 -7.52134L334.313 -7.15458Z'
        fill='url(#paint1479_linear_3150_15389)'
      />
      <path
        d='M416.185 -17.8225L414.92 -18.6746L415.265 -19.2355L416.588 -18.4589L416.185 -17.8225Z'
        fill='url(#paint1480_linear_3150_15389)'
      />
      <path
        d='M497.574 3.63194L496.562 3.16811L496.654 2.35912L497.735 2.75822L497.574 3.63194Z'
        fill='url(#paint1481_linear_3150_15389)'
      />
      <path
        d='M450.429 -17.3155L449.072 -18.0921L449.405 -18.7501L450.797 -18.049L450.429 -17.3155Z'
        fill='url(#paint1482_linear_3150_15389)'
      />
      <path
        d='M320.733 -16.4094L319.779 -16.2908L319.698 -16.9164L320.756 -17.2508L320.733 -16.4094Z'
        fill='url(#paint1483_linear_3150_15389)'
      />
      <path
        d='M312.408 -1.29751L310.81 -1.58875L310.844 -1.7829L312.442 -1.57796L312.408 -1.29751Z'
        fill='url(#paint1484_linear_3150_15389)'
      />
      <path
        d='M658.834 583.785L660.41 583.44L660.49 583.817L658.903 584.076L658.834 583.785Z'
        fill='url(#paint1485_linear_3150_15389)'
      />
      <path
        d='M415.116 -17.9519L413.885 -18.8148L414.242 -19.3649L415.53 -18.5775L415.116 -17.9519Z'
        fill='url(#paint1486_linear_3150_15389)'
      />
      <path
        d='M471.92 -15.4279L470.609 -16.2908L471.046 -16.9811L472.403 -16.1937L471.92 -15.4279Z'
        fill='url(#paint1487_linear_3150_15389)'
      />
      <path
        d='M588.829 225.176L587.266 223.839L588.093 223.386L589.692 224.518L588.829 225.176Z'
        fill='url(#paint1488_linear_3150_15389)'
      />
      <path
        d='M496.907 -43.2571L495.861 -43.8072L496.056 -44.4328L496.758 -44.0337L496.907 -43.2571Z'
        fill='url(#paint1489_linear_3150_15389)'
      />
      <path
        d='M399.374 -17.4018L397.914 -18.0921L398.155 -18.6099L399.65 -17.995L399.374 -17.4018Z'
        fill='url(#paint1490_linear_3150_15389)'
      />
      <path
        d='M290.928 5.85396L289.318 5.64901L289.33 5.55193L290.94 5.65979L290.928 5.85396Z'
        fill='url(#paint1491_linear_3150_15389)'
      />
      <path
        d='M414.092 -18.0813L412.908 -18.9658L413.265 -19.5159L414.495 -18.707L414.092 -18.0813Z'
        fill='url(#paint1492_linear_3150_15389)'
      />
      <path
        d='M377.814 -15.4818L376.307 -16.0643L376.468 -16.5065L378.009 -16.0103L377.814 -15.4818Z'
        fill='url(#paint1493_linear_3150_15389)'
      />
      <path
        d='M439.827 193.529L438.942 192.784L439.125 192.277L440.103 193.097L439.827 193.529Z'
        fill='url(#paint1494_linear_3150_15389)'
      />
      <path
        d='M413.104 -18.2323L411.977 -19.1276L412.333 -19.6777L413.518 -18.858L413.104 -18.2323Z'
        fill='url(#paint1495_linear_3150_15389)'
      />
      <path
        d='M443.725 177.651L443.127 177.004L443.403 176.659L444.013 177.219L443.725 177.651Z'
        fill='url(#paint1496_linear_3150_15389)'
      />
      <path
        d='M582.045 159.681L581.689 159.195L581.987 158.386L582.436 158.893L582.045 159.681Z'
        fill='url(#paint1497_linear_3150_15389)'
      />
      <path
        d='M634.652 227.636L633.434 226.05L634.56 225.737L635.837 227.193L634.652 227.636Z'
        fill='url(#paint1498_linear_3150_15389)'
      />
      <path
        d='M676.048 569.946L677.589 569.504L677.727 569.967L676.152 570.313L676.048 569.946Z'
        fill='url(#paint1499_linear_3150_15389)'
      />
      <path
        d='M494.32 -12.4616L493.067 -13.4108L493.596 -14.1443L494.895 -13.2598L494.32 -12.4616Z'
        fill='url(#paint1500_linear_3150_15389)'
      />
      <path
        d='M671.334 15.5618L671.115 13.8144L672.334 13.6095L672.564 15.3245L671.334 15.5618Z'
        fill='url(#paint1501_linear_3150_15389)'
      />
      <path
        d='M336.199 164.394L335.187 164.578L335.544 163.812L336.222 163.553L336.199 164.394Z'
        fill='url(#paint1502_linear_3150_15389)'
      />
      <path
        d='M709.901 356.588L710.614 355.143L711.488 356.006L710.74 357.408L709.901 356.588Z'
        fill='url(#paint1503_linear_3150_15389)'
      />
      <path
        d='M398.362 -17.7254L396.913 -18.4373L397.166 -18.9443L398.649 -18.3186L398.362 -17.7254Z'
        fill='url(#paint1504_linear_3150_15389)'
      />
      <path
        d='M758.852 433.097L759.714 431.9L760.611 432.59L759.703 433.658L758.852 433.097Z'
        fill='url(#paint1505_linear_3150_15389)'
      />
      <path
        d='M412.172 -18.3942L411.091 -19.3002L411.448 -19.8503L412.575 -19.009L412.172 -18.3942Z'
        fill='url(#paint1506_linear_3150_15389)'
      />
      <path
        d='M355.253 -12.5155L353.7 -13.0117L353.815 -13.3892L355.379 -12.9793L355.253 -12.5155Z'
        fill='url(#paint1507_linear_3150_15389)'
      />
      <path
        d='M411.287 -18.5667L410.263 -19.4944L410.62 -20.0337L411.689 -19.1816L411.287 -18.5667Z'
        fill='url(#paint1508_linear_3150_15389)'
      />
      <path
        d='M692.296 555.082L693.814 554.553L694.021 555.093L692.469 555.535L692.296 555.082Z'
        fill='url(#paint1509_linear_3150_15389)'
      />
      <path
        d='M516.513 -8.16852L515.317 -9.18246L515.949 -9.94831L517.18 -8.9883L516.513 -8.16852Z'
        fill='url(#paint1510_linear_3150_15389)'
      />
      <path
        d='M397.454 -18.0598L396.028 -18.7932L396.292 -19.3002L397.753 -18.653L397.454 -18.0598Z'
        fill='url(#paint1511_linear_3150_15389)'
      />
      <path
        d='M376.756 -16.0535L375.249 -16.6575L375.422 -17.1106L376.963 -16.5928L376.756 -16.0535Z'
        fill='url(#paint1512_linear_3150_15389)'
      />
      <path
        d='M429.88 183.368L429.167 182.623L429.432 182.278L430.11 182.872L429.88 183.368Z'
        fill='url(#paint1513_linear_3150_15389)'
      />
      <path
        d='M582.333 178.276L581.999 177.791L582.195 176.993L582.677 177.489L582.333 178.276Z'
        fill='url(#paint1514_linear_3150_15389)'
      />
      <path
        d='M783.333 382.368L784.747 381.861L785.311 383.102L783.827 383.566L783.333 382.368Z'
        fill='url(#paint1515_linear_3150_15389)'
      />
      <path
        d='M658.949 138.539L658.869 137.514L659.892 137.288L659.961 138.323L658.949 138.539Z'
        fill='url(#paint1516_linear_3150_15389)'
      />
      <path
        d='M291.652 -21.9429L290.583 -21.3928L290.445 -22.1802L291.549 -22.9029L291.652 -21.9429Z'
        fill='url(#paint1517_linear_3150_15389)'
      />
      <path
        d='M617.657 224.928L616.381 223.504L617.335 223.116L618.703 224.421L617.657 224.928Z'
        fill='url(#paint1518_linear_3150_15389)'
      />
      <path
        d='M332.887 -8.22245L331.312 -8.62155L331.381 -8.91279L332.979 -8.59999L332.887 -8.22245Z'
        fill='url(#paint1519_linear_3150_15389)'
      />
      <path
        d='M608.055 192.687L608.032 192.072L608.895 192.364L608.987 193.011L608.055 192.687Z'
        fill='url(#paint1520_linear_3150_15389)'
      />
      <path
        d='M331.577 -12.5371L330.806 -12.7636V-13.3245L331.715 -13.2921L331.577 -12.5371Z'
        fill='url(#paint1521_linear_3150_15389)'
      />
      <path
        d='M483.695 39.2382L482.936 39.1196L482.775 38.2459L483.58 38.2674L483.695 39.2382Z'
        fill='url(#paint1522_linear_3150_15389)'
      />
      <path
        d='M708.682 46.1093L708.878 44.2216L710.12 44.254L709.912 46.0877L708.682 46.1093Z'
        fill='url(#paint1523_linear_3150_15389)'
      />
      <path
        d='M307.889 -19.7317L306.865 -19.2786L306.716 -19.915L307.785 -20.5622L307.889 -19.7317Z'
        fill='url(#paint1524_linear_3150_15389)'
      />
      <path
        d='M707.532 539.247L709.016 538.633L709.292 539.237L707.774 539.776L707.532 539.247Z'
        fill='url(#paint1525_linear_3150_15389)'
      />
      <path
        d='M538.395 -2.54875L537.268 -3.63818L538.004 -4.41482L539.177 -3.3793L538.395 -2.54875Z'
        fill='url(#paint1526_linear_3150_15389)'
      />
      <path
        d='M592.923 168.687L592.509 168.083L592.911 167.35L593.417 167.986L592.923 168.687Z'
        fill='url(#paint1527_linear_3150_15389)'
      />
      <path
        d='M725.988 350.483L726.77 349.308L727.655 350.138L726.77 351.4L725.988 350.483Z'
        fill='url(#paint1528_linear_3150_15389)'
      />
      <path
        d='M760.347 366.987L761.45 366.124L762.209 367.095L761.013 367.968L760.347 366.987Z'
        fill='url(#paint1529_linear_3150_15389)'
      />
      <path
        d='M396.649 -18.4157L395.246 -19.1492L395.522 -19.667L396.959 -18.9982L396.649 -18.4157Z'
        fill='url(#paint1530_linear_3150_15389)'
      />
      <path
        d='M517.594 7.7308L517.674 7.84946L517.352 7.10518L517.479 6.91103L517.594 7.7308Z'
        fill='url(#paint1531_linear_3150_15389)'
      />
      <path
        d='M530.703 231.076L528.437 229.448L529.024 228.887L531.323 230.343L530.703 231.076Z'
        fill='url(#paint1532_linear_3150_15389)'
      />
      <path
        d='M728.679 442.406L728.828 440.885L730.013 441.176L729.783 442.643L728.679 442.406Z'
        fill='url(#paint1533_linear_3150_15389)'
      />
      <path
        d='M498.931 -43.7317L499.253 -45.2094V-45.0153L498.862 -43.5375L498.931 -43.7317Z'
        fill='url(#paint1534_linear_3150_15389)'
      />
      <path
        d='M791.474 440.13L792.532 438.997L793.498 439.817L792.302 441.004L791.474 440.13Z'
        fill='url(#paint1535_linear_3150_15389)'
      />
      <path
        d='M721.687 522.517L723.125 521.806L723.481 522.474L721.998 523.1L721.687 522.517Z'
        fill='url(#paint1536_linear_3150_15389)'
      />
      <path
        d='M535.912 -0.564034L534.739 -1.53481L535.337 -2.37617L536.59 -1.4593L535.912 -0.564034Z'
        fill='url(#paint1537_linear_3150_15389)'
      />
      <path
        d='M310.833 -2.59189L309.234 -2.90471L309.257 -3.09886L310.879 -2.87235L310.833 -2.59189Z'
        fill='url(#paint1538_linear_3150_15389)'
      />
      <path
        d='M559.887 4.37619L558.84 3.22205L559.68 2.44541L560.772 3.55642L559.887 4.37619Z'
        fill='url(#paint1539_linear_3150_15389)'
      />
      <path
        d='M375.882 -16.6467L374.398 -17.2723L374.582 -17.7146L376.1 -17.1753L375.882 -16.6467Z'
        fill='url(#paint1540_linear_3150_15389)'
      />
      <path
        d='M482.856 40.9857L482.12 40.8994L481.982 40.0256H482.672L482.856 40.9857Z'
        fill='url(#paint1541_linear_3150_15389)'
      />
      <path
        d='M671.771 261.829L672.093 260.578L672.599 261.311L672.242 262.325L671.771 261.829Z'
        fill='url(#paint1542_linear_3150_15389)'
      />
      <path
        d='M354.206 -13.3568L352.665 -13.8746L352.78 -14.2413L354.356 -13.8207L354.206 -13.3568Z'
        fill='url(#paint1543_linear_3150_15389)'
      />
      <path
        d='M395.959 -18.7717L394.59 -19.5267L394.866 -20.0337L396.281 -19.3542L395.959 -18.7717Z'
        fill='url(#paint1544_linear_3150_15389)'
      />
      <path
        d='M506.773 27.5456L505.612 27.1573L505.727 26.1973L506.854 26.5101L506.773 27.5456Z'
        fill='url(#paint1545_linear_3150_15389)'
      />
      <path
        d='M493.814 -52.6414L493.055 -53.1375L493.193 -53.6876L493.527 -53.364L493.814 -52.6414Z'
        fill='url(#paint1546_linear_3150_15389)'
      />
      <path
        d='M622.763 611.096L624.384 610.956L624.395 611.15L622.774 611.193L622.763 611.096Z'
        fill='url(#paint1547_linear_3150_15389)'
      />
      <path
        d='M425.683 196.819L424.66 195.88L424.878 195.373L425.959 196.312L425.683 196.819Z'
        fill='url(#paint1548_linear_3150_15389)'
      />
      <path
        d='M720.284 109.264L720.848 107.668L722.078 107.722L721.538 109.135L720.284 109.264Z'
        fill='url(#paint1549_linear_3150_15389)'
      />
      <path
        d='M734.681 504.935L736.072 504.148L736.521 504.871L735.083 505.583L734.681 504.935Z'
        fill='url(#paint1550_linear_3150_15389)'
      />
      <path
        d='M289.169 4.35463L287.547 4.12811V4.04181L289.18 4.16046L289.169 4.35463Z'
        fill='url(#paint1551_linear_3150_15389)'
      />
      <path
        d='M395.384 -19.1276L394.05 -19.9043L394.326 -20.4112L395.706 -19.7101L395.384 -19.1276Z'
        fill='url(#paint1552_linear_3150_15389)'
      />
      <path
        d='M723.803 73.1834L724.24 71.3497L725.459 71.4791L724.999 73.2589L723.803 73.1834Z'
        fill='url(#paint1553_linear_3150_15389)'
      />
      <path
        d='M564.705 174.954L564.636 174.641L564.532 173.811L564.762 174.156L564.705 174.954Z'
        fill='url(#paint1554_linear_3150_15389)'
      />
      <path
        d='M514.719 -44.7564L513.546 -45.7919L513.972 -46.396L514.961 -45.4899L514.719 -44.7564Z'
        fill='url(#paint1555_linear_3150_15389)'
      />
      <path
        d='M285.248 -26.2144L284.27 -25.9879L284.339 -26.9479L285.305 -27.2715L285.248 -26.2144Z'
        fill='url(#paint1556_linear_3150_15389)'
      />
      <path
        d='M375.215 -17.2508L373.731 -17.898L373.927 -18.3402L375.433 -17.7793L375.215 -17.2508Z'
        fill='url(#paint1557_linear_3150_15389)'
      />
      <path
        d='M505.279 -0.143349L504.957 -0.305153L504.773 -1.0602L505.152 -0.963135L505.279 -0.143349Z'
        fill='url(#paint1558_linear_3150_15389)'
      />
      <path
        d='M331.818 -9.31189L330.243 -9.73258L330.312 -10.013L331.91 -9.67864L331.818 -9.31189Z'
        fill='url(#paint1559_linear_3150_15389)'
      />
      <path
        d='M741.695 382.088L742.546 380.901L743.443 381.516L742.477 382.713L741.695 382.088Z'
        fill='url(#paint1560_linear_3150_15389)'
      />
      <path
        d='M655.35 226.514L655.488 225.414L656.075 226.179L655.822 226.579L655.35 226.514Z'
        fill='url(#paint1561_linear_3150_15389)'
      />
      <path
        d='M641.805 599.63L643.403 599.393L643.449 599.684L641.828 599.824L641.805 599.63Z'
        fill='url(#paint1562_linear_3150_15389)'
      />
      <path
        d='M394.924 -19.5052L393.636 -20.2926L393.912 -20.7888L395.246 -20.0768L394.924 -19.5052Z'
        fill='url(#paint1563_linear_3150_15389)'
      />
      <path
        d='M277.555 -20.0984L277.049 -20.4112L277.417 -21.4899L277.75 -21.1879L277.555 -20.0984Z'
        fill='url(#paint1564_linear_3150_15389)'
      />
      <path
        d='M353.447 -14.209L351.907 -14.7483L352.033 -15.115L353.597 -14.6728L353.447 -14.209Z'
        fill='url(#paint1565_linear_3150_15389)'
      />
      <path
        d='M521.193 231.217L518.928 229.534L519.422 228.952L521.768 230.494L521.193 231.217Z'
        fill='url(#paint1566_linear_3150_15389)'
      />
      <path
        d='M523.7 229.308L521.687 227.83L522.113 227.269L524.229 228.639L523.7 229.308Z'
        fill='url(#paint1567_linear_3150_15389)'
      />
      <path
        d='M559.105 -40.4634L558.219 -41.3479L558.426 -42.2216L559.243 -41.4557L559.105 -40.4634Z'
        fill='url(#paint1568_linear_3150_15389)'
      />
      <path
        d='M390.497 154.816L390.531 154.352L390.796 153.662L390.715 154.018L390.497 154.816Z'
        fill='url(#paint1569_linear_3150_15389)'
      />
      <path
        d='M370.558 -8.92359L370.627 -9.85122L370.673 -10.2072L370.753 -9.36583L370.558 -8.92359Z'
        fill='url(#paint1570_linear_3150_15389)'
      />
      <path
        d='M574.927 0.223389L573.858 -0.909195L574.651 -1.70741L575.698 -0.639534L574.927 0.223389Z'
        fill='url(#paint1571_linear_3150_15389)'
      />
      <path
        d='M704.634 367.709L705.255 366.232L706.164 366.749L705.405 368.4L704.634 367.709Z'
        fill='url(#paint1572_linear_3150_15389)'
      />
      <path
        d='M520.342 -11.6849L519.986 -11.8683L519.802 -12.6773L520.227 -12.5478L520.342 -11.6849Z'
        fill='url(#paint1573_linear_3150_15389)'
      />
      <path
        d='M601.743 149.466L601.432 148.819L601.881 148.063L602.272 148.765L601.743 149.466Z'
        fill='url(#paint1574_linear_3150_15389)'
      />
      <path
        d='M748.008 441.683L748.802 440.464L749.699 441.068L748.825 442.19L748.008 441.683Z'
        fill='url(#paint1575_linear_3150_15389)'
      />
      <path
        d='M516.156 -6.98201L515.857 -7.12223L515.673 -7.90965L516.018 -7.83414L516.156 -6.98201Z'
        fill='url(#paint1576_linear_3150_15389)'
      />
      <path
        d='M292.929 -20.2494L292.722 -21.8458L292.917 -21.7811L293.182 -20.2602L292.929 -20.2494Z'
        fill='url(#paint1577_linear_3150_15389)'
      />
      <path
        d='M374.732 -17.8656L373.283 -18.5236L373.478 -18.9658L374.962 -18.3834L374.732 -17.8656Z'
        fill='url(#paint1578_linear_3150_15389)'
      />
      <path
        d='M394.579 -19.8719L393.337 -20.6809L393.624 -21.1771L394.912 -20.4544L394.579 -19.8719Z'
        fill='url(#paint1579_linear_3150_15389)'
      />
      <path
        d='M503.025 -13.7883L502.323 -14.2629L502.277 -15.018L503.105 -14.5973L503.025 -13.7883Z'
        fill='url(#paint1580_linear_3150_15389)'
      />
      <path
        d='M309.694 -3.90784L308.084 -4.24223L308.119 -4.4256L309.74 -4.18829L309.694 -3.90784Z'
        fill='url(#paint1581_linear_3150_15389)'
      />
      <path
        d='M295.712 -22.8274L294.688 -22.0939L294.493 -22.7303L295.516 -23.6148L295.712 -22.8274Z'
        fill='url(#paint1582_linear_3150_15389)'
      />
      <path
        d='M660.053 586.999L661.64 586.665L661.721 587.053L660.122 587.28L660.053 586.999Z'
        fill='url(#paint1583_linear_3150_15389)'
      />
      <path
        d='M483.5 36.2288L482.844 36.0886L482.718 35.2041L483.316 35.2796L483.5 36.2288Z'
        fill='url(#paint1584_linear_3150_15389)'
      />
      <path
        d='M484.351 26.7798L483.511 26.4346L483.419 25.5717L484.397 25.809L484.351 26.7798Z'
        fill='url(#paint1585_linear_3150_15389)'
      />
      <path
        d='M478.854 20.5776L477.635 19.9843L477.9 19.1322L479.107 19.6715L478.854 20.5776Z'
        fill='url(#paint1586_linear_3150_15389)'
      />
      <path
        d='M394.36 -20.2494L393.165 -21.0692L393.452 -21.5654L394.694 -20.8211L394.36 -20.2494Z'
        fill='url(#paint1587_linear_3150_15389)'
      />
      <path
        d='M665.642 245.196L664.779 243.362L666.021 243.254L666.941 244.959L665.642 245.196Z'
        fill='url(#paint1588_linear_3150_15389)'
      />
      <path
        d='M349.584 -9.74336L349.239 -10.3366L349.285 -10.8004L349.814 -10.3474L349.584 -9.74336Z'
        fill='url(#paint1589_linear_3150_15389)'
      />
      <path
        d='M799.776 459.298L801.26 458.435L801.949 459.47L800.363 460.29L799.776 459.298Z'
        fill='url(#paint1590_linear_3150_15389)'
      />
      <path
        d='M279.613 -26.0742L278.544 -25.2867L278.36 -26.0634L279.429 -27.0018L279.613 -26.0742Z'
        fill='url(#paint1591_linear_3150_15389)'
      />
      <path
        d='M352.953 -15.0719L351.435 -15.622L351.562 -15.9887L353.114 -15.5249L352.953 -15.0719Z'
        fill='url(#paint1592_linear_3150_15389)'
      />
      <path
        d='M699.655 249.672L700.391 247.785L700.909 248.723L700.104 250.557L699.655 249.672Z'
        fill='url(#paint1593_linear_3150_15389)'
      />
      <path
        d='M374.444 -18.4697L373.018 -19.1492L373.225 -19.5915L374.686 -18.9982L374.444 -18.4697Z'
        fill='url(#paint1594_linear_3150_15389)'
      />
      <path
        d='M516.593 227.938L514.708 226.471L515.064 225.899L517.053 227.29L516.593 227.938Z'
        fill='url(#paint1595_linear_3150_15389)'
      />
      <path
        d='M331.117 -10.4121L329.541 -10.8544L329.622 -11.1348L331.22 -10.7789L331.117 -10.4121Z'
        fill='url(#paint1596_linear_3150_15389)'
      />
      <path
        d='M627.282 141.689L627.006 140.826L627.764 140.297L628.052 141.203L627.282 141.689Z'
        fill='url(#paint1597_linear_3150_15389)'
      />
      <path
        d='M677.451 573.247L679.015 572.826L679.153 573.29L677.555 573.624L677.451 573.247Z'
        fill='url(#paint1598_linear_3150_15389)'
      />
      <path
        d='M412.092 83.7974L411.908 83.1933L411.827 82.7942L411.965 83.3875L412.092 83.7974Z'
        fill='url(#paint1599_linear_3150_15389)'
      />
      <path
        d='M394.268 -20.627L393.13 -21.4683L393.417 -21.9537L394.602 -21.1986L394.268 -20.627Z'
        fill='url(#paint1600_linear_3150_15389)'
      />
      <path
        d='M275.508 -26.9047L274.749 -26.8939L274.922 -27.9402L275.704 -28.0481L275.508 -26.9047Z'
        fill='url(#paint1601_linear_3150_15389)'
      />
      <path
        d='M287.915 2.83372L286.294 2.59642V2.49935L287.938 2.63956L287.915 2.83372Z'
        fill='url(#paint1602_linear_3150_15389)'
      />
      <path
        d='M471.012 6.7708L469.885 6.03732L470.172 5.29305L471.357 5.95103L471.012 6.7708Z'
        fill='url(#paint1603_linear_3150_15389)'
      />
      <path
        d='M508.36 13.0809L507.613 12.7465L507.532 11.8513L508.372 12.0993L508.36 13.0809Z'
        fill='url(#paint1604_linear_3150_15389)'
      />
      <path
        d='M428.673 120.709L428.811 120.159L428.892 119.867L428.754 120.385L428.673 120.709Z'
        fill='url(#paint1605_linear_3150_15389)'
      />
      <path
        d='M783.666 444.617L784.954 443.679L785.656 444.606L784.345 445.437L783.666 444.617Z'
        fill='url(#paint1606_linear_3150_15389)'
      />
      <path
        d='M680.464 14.6234L680.291 12.7897L681.51 12.6171L681.671 14.3753L680.464 14.6234Z'
        fill='url(#paint1607_linear_3150_15389)'
      />
      <path
        d='M712.822 120.612L713.293 119.263L714.535 119.156L714.018 120.558L712.822 120.612Z'
        fill='url(#paint1608_linear_3150_15389)'
      />
      <path
        d='M394.291 -21.0045L393.211 -21.8566L393.498 -22.3528L394.625 -21.5762L394.291 -21.0045Z'
        fill='url(#paint1609_linear_3150_15389)'
      />
      <path
        d='M710.545 413.034L710.855 411.546L711.994 411.697L711.568 413.164L710.545 413.034Z'
        fill='url(#paint1610_linear_3150_15389)'
      />
      <path
        d='M374.364 -19.0845L372.972 -19.7856L373.179 -20.2279L374.617 -19.613L374.364 -19.0845Z'
        fill='url(#paint1611_linear_3150_15389)'
      />
      <path
        d='M615.495 239.63L614.081 237.764L615.093 237.505L616.519 239.102L615.495 239.63Z'
        fill='url(#paint1612_linear_3150_15389)'
      />
      <path
        d='M693.906 558.426L695.447 557.908L695.642 558.458L694.079 558.879L693.906 558.426Z'
        fill='url(#paint1613_linear_3150_15389)'
      />
      <path
        d='M461.675 217.637L459.869 216.051L460.076 215.404L461.974 216.946L461.675 217.637Z'
        fill='url(#paint1614_linear_3150_15389)'
      />
      <path d='M622.9 614.03L624.522 613.922V614.117L622.9 614.127V614.03Z' fill='url(#paint1615_linear_3150_15389)' />
      <path
        d='M721.112 160.867L721.768 159.724L722.906 159.929L722.182 161.094L721.112 160.867Z'
        fill='url(#paint1616_linear_3150_15389)'
      />
      <path
        d='M352.757 -15.9348L351.251 -16.5065L351.389 -16.8732L352.918 -16.3879L352.757 -15.9348Z'
        fill='url(#paint1617_linear_3150_15389)'
      />
      <path
        d='M676.071 258.712L676.37 257.536L676.945 258.161L676.589 259.423L676.071 258.712Z'
        fill='url(#paint1618_linear_3150_15389)'
      />
      <path
        d='M505.669 8.22699L504.75 7.7308L504.83 6.84631L505.784 7.27777L505.669 8.22699Z'
        fill='url(#paint1619_linear_3150_15389)'
      />
      <path
        d='M587.852 241.043L586.047 239.08L586.943 238.681L588.726 240.374L587.852 241.043Z'
        fill='url(#paint1620_linear_3150_15389)'
      />
      <path
        d='M750.664 116.103L751.78 114.151L752.907 114.539L751.803 116.319L750.664 116.103Z'
        fill='url(#paint1621_linear_3150_15389)'
      />
      <path
        d='M740.775 129.144L741.799 127.418L742.926 127.763L741.971 129.219L740.775 129.144Z'
        fill='url(#paint1622_linear_3150_15389)'
      />
      <path
        d='M309.004 -5.23458L307.394 -5.59055L307.44 -5.78471L309.062 -5.52583L309.004 -5.23458Z'
        fill='url(#paint1623_linear_3150_15389)'
      />
      <path
        d='M603.755 183.217L603.686 182.526L604.732 182.828L604.79 183.465L603.755 183.217Z'
        fill='url(#paint1624_linear_3150_15389)'
      />
      <path
        d='M315.34 -24.111L315.294 -25.5132L315.536 -25.3407L315.685 -23.9816L315.34 -24.111Z'
        fill='url(#paint1625_linear_3150_15389)'
      />
      <path
        d='M504.692 23.8998L503.657 23.436L503.738 22.4868L504.807 22.8643L504.692 23.8998Z'
        fill='url(#paint1626_linear_3150_15389)'
      />
      <path
        d='M498.667 28.8292L498.276 28.7537L498.103 27.8692H498.414L498.667 28.8292Z'
        fill='url(#paint1627_linear_3150_15389)'
      />
      <path
        d='M726.678 148.107L727.414 146.866L728.621 146.996L727.816 148.301L726.678 148.107Z'
        fill='url(#paint1628_linear_3150_15389)'
      />
      <path
        d='M330.783 -11.5123L329.219 -11.9762L329.3 -12.2566L330.887 -11.8899L330.783 -11.5123Z'
        fill='url(#paint1629_linear_3150_15389)'
      />
      <path
        d='M709.349 542.591L710.855 541.987L711.12 542.602L709.579 543.13L709.349 542.591Z'
        fill='url(#paint1630_linear_3150_15389)'
      />
      <path
        d='M374.479 -19.6993L373.133 -20.422L373.352 -20.8535L374.732 -20.2279L374.479 -19.6993Z'
        fill='url(#paint1631_linear_3150_15389)'
      />
      <path
        d='M283.994 -25.5348L283.005 -24.5748L282.741 -25.1681L283.718 -26.2899L283.994 -25.5348Z'
        fill='url(#paint1632_linear_3150_15389)'
      />
      <path
        d='M587.231 149.045L587.001 148.452L587.289 147.761L587.564 148.387L587.231 149.045Z'
        fill='url(#paint1633_linear_3150_15389)'
      />
      <path
        d='M711.35 44.0274L711.557 42.1721L712.787 42.129L712.569 44.0382L711.35 44.0274Z'
        fill='url(#paint1634_linear_3150_15389)'
      />
      <path
        d='M648.221 241.14L647.209 239.371L648.313 239.231L649.405 240.892L648.221 241.14Z'
        fill='url(#paint1635_linear_3150_15389)'
      />
      <path
        d='M554.942 -40.2692L554.057 -41.3694L554.379 -42.1245L555.23 -41.089L554.942 -40.2692Z'
        fill='url(#paint1636_linear_3150_15389)'
      />
      <path
        d='M438.884 203.053L437.688 201.78L437.895 201.112L439.206 202.471L438.884 203.053Z'
        fill='url(#paint1637_linear_3150_15389)'
      />
      <path
        d='M501.898 25.8198L500.92 25.3991L500.955 24.4391L501.955 24.7843L501.898 25.8198Z'
        fill='url(#paint1638_linear_3150_15389)'
      />
      <path
        d='M728.207 73.9385L728.69 72.0724L729.898 72.2342L729.392 74.0679L728.207 73.9385Z'
        fill='url(#paint1639_linear_3150_15389)'
      />
      <path
        d='M593.348 -4.63054L592.405 -5.79549L593.13 -6.61526L594.084 -5.50425L593.348 -4.63054Z'
        fill='url(#paint1640_linear_3150_15389)'
      />
      <path
        d='M642.012 602.748L643.621 602.532L643.656 602.834L642.035 602.942L642.012 602.748Z'
        fill='url(#paint1641_linear_3150_15389)'
      />
      <path
        d='M668.528 238.972L668.62 237.775L669.39 238.487L669.287 239.716L668.528 238.972Z'
        fill='url(#paint1642_linear_3150_15389)'
      />
      <path
        d='M723.711 525.818L725.171 525.117L725.516 525.797L724.022 526.422L723.711 525.818Z'
        fill='url(#paint1643_linear_3150_15389)'
      />
      <path
        d='M352.838 -16.7977L351.366 -17.391L351.504 -17.7577L353.01 -17.2508L352.838 -16.7977Z'
        fill='url(#paint1644_linear_3150_15389)'
      />
      <path
        d='M574.191 156.736L574.076 156.283L574.099 155.495L574.26 155.992L574.191 156.736Z'
        fill='url(#paint1645_linear_3150_15389)'
      />
      <path
        d='M277.704 -30.8634L276.738 -29.72L276.428 -30.324L277.359 -31.6184L277.704 -30.8634Z'
        fill='url(#paint1646_linear_3150_15389)'
      />
      <path
        d='M287.202 1.29124L285.57 1.03238L285.581 0.935303L287.225 1.0971L287.202 1.29124Z'
        fill='url(#paint1647_linear_3150_15389)'
      />
      <path
        d='M693.366 268.096L694.09 266.478L694.561 267.254L693.745 268.905L693.366 268.096Z'
        fill='url(#paint1648_linear_3150_15389)'
      />
      <path
        d='M712.27 394.751L713.259 393.187L713.914 393.91L713.098 395.226L712.27 394.751Z'
        fill='url(#paint1649_linear_3150_15389)'
      />
      <path
        d='M790.336 420.855L791.244 419.614L792.325 420.348L791.347 421.631L790.336 420.855Z'
        fill='url(#paint1650_linear_3150_15389)'
      />
      <path
        d='M374.812 -20.3142L373.501 -21.0476L373.72 -21.4791L375.065 -20.8319L374.812 -20.3142Z'
        fill='url(#paint1651_linear_3150_15389)'
      />
      <path
        d='M792.049 342.339L792.923 341.034L794.119 341.735L793.233 343.03L792.049 342.339Z'
        fill='url(#paint1652_linear_3150_15389)'
      />
      <path
        d='M504.255 -24.1434L503.462 -24.9631L503.588 -25.6643L504.623 -24.8661L504.255 -24.1434Z'
        fill='url(#paint1653_linear_3150_15389)'
      />
      <path
        d='M277.417 -30.9281L276.497 -30.6045L276.543 -31.5429L277.474 -31.996L277.417 -30.9281Z'
        fill='url(#paint1654_linear_3150_15389)'
      />
      <path
        d='M271.104 -30.8849L270.023 -29.9465L269.839 -30.7447L270.862 -31.7802L271.104 -30.8849Z'
        fill='url(#paint1655_linear_3150_15389)'
      />
      <path
        d='M736.923 508.161L738.338 507.373L738.775 508.107L737.314 508.819L736.923 508.161Z'
        fill='url(#paint1656_linear_3150_15389)'
      />
      <path
        d='M561.726 5.498L560.726 4.63508L561.14 3.68585L562.267 4.50564L561.726 5.498Z'
        fill='url(#paint1657_linear_3150_15389)'
      />
      <path
        d='M571.558 241.572L569.649 239.587L570.408 239.037L572.397 240.925L571.558 241.572Z'
        fill='url(#paint1658_linear_3150_15389)'
      />
      <path
        d='M714.466 423.54L714.753 422.009L715.915 422.171L715.558 423.659L714.466 423.54Z'
        fill='url(#paint1659_linear_3150_15389)'
      />
      <path
        d='M783.206 429.505L784.506 428.675L785.161 429.624L783.804 430.368L783.206 429.505Z'
        fill='url(#paint1660_linear_3150_15389)'
      />
      <path
        d='M776.767 435.718L777.572 434.392L778.653 435.082L777.779 436.376L776.767 435.718Z'
        fill='url(#paint1661_linear_3150_15389)'
      />
      <path
        d='M431.824 176.259L431.341 175.548L431.686 174.976L432.226 175.903L431.824 176.259Z'
        fill='url(#paint1662_linear_3150_15389)'
      />
      <path
        d='M330.818 -12.6234L329.277 -13.1087L329.357 -13.3892L330.933 -13.0009L330.818 -12.6234Z'
        fill='url(#paint1663_linear_3150_15389)'
      />
      <path
        d='M583.264 165.106L583.114 164.545L583.218 163.855L583.448 164.47L583.264 165.106Z'
        fill='url(#paint1664_linear_3150_15389)'
      />
      <path
        d='M308.774 -6.57212L307.176 -6.94965L307.21 -7.13302L308.832 -6.86336L308.774 -6.57212Z'
        fill='url(#paint1665_linear_3150_15389)'
      />
      <path
        d='M660.364 590.257L661.962 589.944L662.031 590.332L660.421 590.548L660.364 590.257Z'
        fill='url(#paint1666_linear_3150_15389)'
      />
      <path
        d='M375.33 -20.9182L374.076 -21.6625L374.295 -22.1047L375.594 -21.4359L375.33 -20.9182Z'
        fill='url(#paint1667_linear_3150_15389)'
      />
      <path
        d='M742.696 436.182L744.087 435.233L744.513 436.161L743.271 436.926L742.696 436.182Z'
        fill='url(#paint1668_linear_3150_15389)'
      />
      <path
        d='M584.253 -2.38695L583.379 -3.40088L583.885 -4.31774L584.954 -3.29302L584.253 -2.38695Z'
        fill='url(#paint1669_linear_3150_15389)'
      />
      <path
        d='M455.442 9.08991L454.304 8.22699L454.684 7.51508L455.879 8.2917L455.442 9.08991Z'
        fill='url(#paint1670_linear_3150_15389)'
      />
      <path
        d='M353.217 -17.6607L351.78 -18.2755L351.918 -18.6422L353.401 -18.1137L353.217 -17.6607Z'
        fill='url(#paint1671_linear_3150_15389)'
      />
      <path
        d='M537.878 -53.4827L536.854 -54.8849L537.372 -55.4027L538.188 -54.1838L537.878 -53.4827Z'
        fill='url(#paint1672_linear_3150_15389)'
      />
      <path
        d='M538.936 16.1659L537.889 15.4863L538.154 14.5047L539.258 15.1088L538.936 16.1659Z'
        fill='url(#paint1673_linear_3150_15389)'
      />
      <path
        d='M543.34 12.0131L542.282 11.2041L542.65 10.2549L543.823 10.9991L543.34 12.0131Z'
        fill='url(#paint1674_linear_3150_15389)'
      />
      <path
        d='M491.491 22.0877L490.295 21.365L490.617 20.4697L491.825 21.1169L491.491 22.0877Z'
        fill='url(#paint1675_linear_3150_15389)'
      />
      <path
        d='M390.163 64.2738L389.968 63.4648L389.979 63.2382L390.221 63.9933L390.163 64.2738Z'
        fill='url(#paint1676_linear_3150_15389)'
      />
      <path
        d='M400.685 73.9277L400.443 73.2158V72.8814L400.604 73.5933L400.685 73.9277Z'
        fill='url(#paint1677_linear_3150_15389)'
      />
      <path
        d='M491.238 -48.6503L491.606 -50.182L491.629 -50.0634L491.204 -48.5533L491.238 -48.6503Z'
        fill='url(#paint1678_linear_3150_15389)'
      />
      <path
        d='M712.419 403.402L712.925 401.946L713.972 402.172L713.339 403.639L712.419 403.402Z'
        fill='url(#paint1679_linear_3150_15389)'
      />
      <path
        d='M551.711 -39.6328L550.918 -40.7546L551.205 -41.4989L552.079 -40.3771L551.711 -39.6328Z'
        fill='url(#paint1680_linear_3150_15389)'
      />
      <path
        d='M376.066 -21.5222L374.87 -22.2773L375.088 -22.7088L376.33 -22.0292L376.066 -21.5222Z'
        fill='url(#paint1681_linear_3150_15389)'
      />
      <path
        d='M336.406 -22.1694L335.751 -22.3744V-22.8382L336.533 -22.795L336.406 -22.1694Z'
        fill='url(#paint1682_linear_3150_15389)'
      />
      <path
        d='M622.245 616.997L623.855 616.91L623.866 617.104L622.245 617.094V616.997Z'
        fill='url(#paint1683_linear_3150_15389)'
      />
      <path
        d='M677.877 576.601L679.452 576.191L679.578 576.666L677.98 576.979L677.877 576.601Z'
        fill='url(#paint1684_linear_3150_15389)'
      />
      <path
        d='M514.961 -27.8755L514.846 -28.2315L514.616 -28.9865L514.903 -28.6521L514.961 -27.8755Z'
        fill='url(#paint1685_linear_3150_15389)'
      />
      <path
        d='M494.838 227.754L492.825 225.856L493.136 225.165L495.229 227.01L494.838 227.754Z'
        fill='url(#paint1686_linear_3150_15389)'
      />
      <path
        d='M532.692 154.978L532.427 154.525L532.841 154.309L532.933 154.848L532.692 154.978Z'
        fill='url(#paint1687_linear_3150_15389)'
      />
      <path
        d='M515.72 -59.7065L514.731 -60.8499L515.053 -61.3892L515.697 -60.4724L515.72 -59.7065Z'
        fill='url(#paint1688_linear_3150_15389)'
      />
      <path
        d='M460.329 214.379L458.766 212.761L458.961 211.963L460.674 213.721L460.329 214.379Z'
        fill='url(#paint1689_linear_3150_15389)'
      />
      <path
        d='M340.546 161.967L340.477 161.719L340.764 161.115L340.649 161.288L340.546 161.967Z'
        fill='url(#paint1690_linear_3150_15389)'
      />
      <path
        d='M268.16 -34.4768L267.252 -33.1393L266.907 -33.711L267.746 -35.178L268.16 -34.4768Z'
        fill='url(#paint1691_linear_3150_15389)'
      />
      <path
        d='M331.232 -13.7344L329.714 -14.2413L329.794 -14.5218L331.347 -14.1119L331.232 -13.7344Z'
        fill='url(#paint1692_linear_3150_15389)'
      />
      <path
        d='M353.884 -18.5236L352.482 -19.1492L352.631 -19.5159L354.068 -18.9766L353.884 -18.5236Z'
        fill='url(#paint1693_linear_3150_15389)'
      />
      <path
        d='M287.041 -0.251221L285.409 -0.531662L285.42 -0.628754L287.064 -0.456161L287.041 -0.251221Z'
        fill='url(#paint1694_linear_3150_15389)'
      />
      <path
        d='M321.837 -23.453L321.009 -23.302L320.94 -23.7766L321.872 -24.111L321.837 -23.453Z'
        fill='url(#paint1695_linear_3150_15389)'
      />
      <path
        d='M757.023 122.985L758.288 120.935L759.381 121.41L758.104 123.298L757.023 122.985Z'
        fill='url(#paint1696_linear_3150_15389)'
      />
      <path
        d='M376.997 -22.1047L375.859 -22.8813L376.077 -23.3128L377.262 -22.6117L376.997 -22.1047Z'
        fill='url(#paint1697_linear_3150_15389)'
      />
      <path
        d='M566.476 240.105L564.67 238.185L565.349 237.602L567.269 239.501L566.476 240.105Z'
        fill='url(#paint1698_linear_3150_15389)'
      />
      <path
        d='M524.505 -33.6355L523.723 -34.671L523.976 -35.3398L524.953 -34.2935L524.505 -33.6355Z'
        fill='url(#paint1699_linear_3150_15389)'
      />
      <path
        d='M541.235 -37.5187L540.431 -38.6404L540.741 -39.32L541.615 -38.2198L541.235 -37.5187Z'
        fill='url(#paint1700_linear_3150_15389)'
      />
      <path
        d='M309.004 -7.90965L307.429 -8.30875L307.463 -8.5029L309.073 -8.20088L309.004 -7.90965Z'
        fill='url(#paint1701_linear_3150_15389)'
      />
      <path
        d='M307.969 -24.9416L307.038 -24.4346L306.9 -24.8984L307.889 -25.5995L307.969 -24.9416Z'
        fill='url(#paint1702_linear_3150_15389)'
      />
      <path
        d='M478.164 8.98204L477.014 8.10834L477.463 7.32092L478.601 8.15147L478.164 8.98204Z'
        fill='url(#paint1703_linear_3150_15389)'
      />
      <path
        d='M353.229 -19.818L352.895 -20.3357L352.953 -20.7564L353.413 -20.3573L353.229 -19.818Z'
        fill='url(#paint1704_linear_3150_15389)'
      />
      <path
        d='M694.469 561.834L696.022 561.327L696.206 561.888L694.63 562.298L694.469 561.834Z'
        fill='url(#paint1705_linear_3150_15389)'
      />
      <path
        d='M522.975 155.183L522.998 154.557L523.424 154.46L523.631 154.924L522.975 155.183Z'
        fill='url(#paint1706_linear_3150_15389)'
      />
      <path
        d='M513.903 155.226L513.868 154.622L514.834 154.352L514.742 154.988L513.903 155.226Z'
        fill='url(#paint1707_linear_3150_15389)'
      />
      <path
        d='M771.075 424.759L771.696 423.346L772.903 423.907L772.236 425.32L771.075 424.759Z'
        fill='url(#paint1708_linear_3150_15389)'
      />
      <path
        d='M307.176 -27.0665L307.038 -28.5443L307.291 -28.3932L307.452 -26.9694L307.176 -27.0665Z'
        fill='url(#paint1709_linear_3150_15389)'
      />
      <path
        d='M402.145 78.4041L401.961 77.6814L401.881 77.3147L402.076 78.005L402.145 78.4041Z'
        fill='url(#paint1710_linear_3150_15389)'
      />
      <path
        d='M518.284 -34.7357L517.996 -35.2643L517.847 -36.0301L518.226 -35.5339L518.284 -34.7357Z'
        fill='url(#paint1711_linear_3150_15389)'
      />
      <path
        d='M736.751 280.144L736.981 278.419L738.246 278.818L738.131 280.36L736.751 280.144Z'
        fill='url(#paint1712_linear_3150_15389)'
      />
      <path
        d='M641.345 605.897L642.955 605.714L642.978 606.005L641.356 606.102L641.345 605.897Z'
        fill='url(#paint1713_linear_3150_15389)'
      />
      <path
        d='M354.839 -19.3649L353.482 -20.0229L353.643 -20.3789L355.034 -19.818L354.839 -19.3649Z'
        fill='url(#paint1714_linear_3150_15389)'
      />
      <path
        d='M549.952 161.352L549.917 160.727L550.998 160.619L550.918 161.223L549.952 161.352Z'
        fill='url(#paint1715_linear_3150_15389)'
      />
      <path
        d='M439.574 182.645L438.999 181.599L439.401 180.995L439.999 182.084L439.574 182.645Z'
        fill='url(#paint1716_linear_3150_15389)'
      />
      <path
        d='M523.7 -39.4386L523.32 -40.0535L523.217 -40.8301L523.665 -40.2584L523.7 -39.4386Z'
        fill='url(#paint1717_linear_3150_15389)'
      />
      <path
        d='M524.873 -43.4836L524.367 -44.2602L524.309 -45.0261L524.988 -44.2494L524.873 -43.4836Z'
        fill='url(#paint1718_linear_3150_15389)'
      />
      <path
        d='M500.185 22.5515L499.437 22.1308L499.391 21.1816L500.139 21.5052L500.185 22.5515Z'
        fill='url(#paint1719_linear_3150_15389)'
      />
      <path
        d='M488.41 22.2602L487.352 21.6346L487.547 20.707L488.571 21.2787L488.41 22.2602Z'
        fill='url(#paint1720_linear_3150_15389)'
      />
      <path
        d='M561.451 239.123L559.657 237.171L560.289 236.685L562.117 238.444L561.451 239.123Z'
        fill='url(#paint1721_linear_3150_15389)'
      />
      <path
        d='M495.861 226.179L494.056 224.378L494.332 223.655L496.252 225.5L495.861 226.179Z'
        fill='url(#paint1722_linear_3150_15389)'
      />
      <path
        d='M281.453 -33.56L280.637 -32.2764L280.326 -32.6971L281.096 -34.1317L281.453 -33.56Z'
        fill='url(#paint1723_linear_3150_15389)'
      />
      <path
        d='M722.63 383.803L723.447 382.573L724.252 383.242L723.24 384.59L722.63 383.803Z'
        fill='url(#paint1724_linear_3150_15389)'
      />
      <path
        d='M656.765 257.547L655.96 255.53L657.098 255.4L657.949 257.234L656.765 257.547Z'
        fill='url(#paint1725_linear_3150_15389)'
      />
      <path
        d='M709.108 261.365L710.43 259.725L710.752 260.524L709.453 261.958L709.108 261.365Z'
        fill='url(#paint1726_linear_3150_15389)'
      />
      <path
        d='M332.025 -14.8346L330.542 -15.3631L330.634 -15.6436L332.151 -15.2121L332.025 -14.8346Z'
        fill='url(#paint1727_linear_3150_15389)'
      />
      <path
        d='M286.145 -28.7276L285.271 -27.6382L284.995 -28.0589L285.846 -29.3101L286.145 -28.7276Z'
        fill='url(#paint1728_linear_3150_15389)'
      />
      <path
        d='M710.062 546.011L711.58 545.406L711.833 546.043L710.292 546.55L710.062 546.011Z'
        fill='url(#paint1729_linear_3150_15389)'
      />
      <path
        d='M719.664 226.007L720.618 223.947L721.204 224.928L720.169 226.978L719.664 226.007Z'
        fill='url(#paint1730_linear_3150_15389)'
      />
      <path
        d='M573.156 158.99L573.145 158.472L573.605 158.429L573.628 158.958L573.156 158.99Z'
        fill='url(#paint1731_linear_3150_15389)'
      />
      <path
        d='M612.126 181.976L612.069 181.221L613.195 181.534L613.241 182.235L612.126 181.976Z'
        fill='url(#paint1732_linear_3150_15389)'
      />
      <path
        d='M193.165 -4.63054L194.705 -3.44404L194.372 -3.09886L192.785 -4.19909L193.165 -4.63054Z'
        fill='url(#paint1733_linear_3150_15389)'
      />
      <path
        d='M375.261 -17.2292L375.307 -17.995L375.387 -18.3618L375.41 -17.6499L375.261 -17.2292Z'
        fill='url(#paint1734_linear_3150_15389)'
      />
      <path
        d='M686.363 12.9191L686.236 11.0854L687.398 10.7618L687.536 12.6171L686.363 12.9191Z'
        fill='url(#paint1735_linear_3150_15389)'
      />
      <path
        d='M502.438 22.4652L501.829 22.1092L501.714 21.1492L502.323 21.4297L502.438 22.4652Z'
        fill='url(#paint1736_linear_3150_15389)'
      />
      <path
        d='M550.435 239.361L548.583 237.387L549.135 236.88L551.021 238.67L550.435 239.361Z'
        fill='url(#paint1737_linear_3150_15389)'
      />
      <path
        d='M376.238 56.2701L376.043 55.3856L376.238 55.2454L376.422 56.1084L376.238 56.2701Z'
        fill='url(#paint1738_linear_3150_15389)'
      />
      <path
        d='M292.756 -24.4993L291.836 -23.6256L291.618 -24.0571L292.549 -25.0818L292.756 -24.4993Z'
        fill='url(#paint1739_linear_3150_15389)'
      />
      <path
        d='M309.706 -9.24717L308.153 -9.66785L308.199 -9.86201L309.775 -9.53841L309.706 -9.24717Z'
        fill='url(#paint1740_linear_3150_15389)'
      />
      <path
        d='M287.421 -1.80448L285.811 -2.1065L285.823 -2.20358L287.455 -1.99863L287.421 -1.80448Z'
        fill='url(#paint1741_linear_3150_15389)'
      />
      <path
        d='M356.092 -20.2063L354.781 -20.875L354.942 -21.231L356.288 -20.6485L356.092 -20.2063Z'
        fill='url(#paint1742_linear_3150_15389)'
      />
      <path
        d='M659.72 593.557L661.318 593.266L661.387 593.655L659.766 593.849L659.72 593.557Z'
        fill='url(#paint1743_linear_3150_15389)'
      />
      <path
        d='M724.585 529.194L726.057 528.504L726.402 529.194L724.884 529.798L724.585 529.194Z'
        fill='url(#paint1744_linear_3150_15389)'
      />
      <path
        d='M488.674 20.1461L487.72 19.5097L487.846 18.5928L488.835 19.1537L488.674 20.1461Z'
        fill='url(#paint1745_linear_3150_15389)'
      />
      <path
        d='M715.765 128.076L716.386 126.641L717.548 126.706L716.904 128.011L715.765 128.076Z'
        fill='url(#paint1746_linear_3150_15389)'
      />
      <path
        d='M618.059 186.161L618.036 185.493L618.715 185.762L618.508 186.215L618.059 186.161Z'
        fill='url(#paint1747_linear_3150_15389)'
      />
      <path
        d='M263.894 -34.4013L262.848 -33.3658L262.675 -34.1317L263.653 -35.2535L263.894 -34.4013Z'
        fill='url(#paint1748_linear_3150_15389)'
      />
      <path
        d='M664.503 144.062L664.595 143.037L665.527 142.864L665.412 143.911L664.503 144.062Z'
        fill='url(#paint1749_linear_3150_15389)'
      />
      <path
        d='M620.773 619.984L622.372 619.92L622.383 620.114L620.773 620.082V619.984Z'
        fill='url(#paint1750_linear_3150_15389)'
      />
      <path
        d='M389.335 64.8238L389.117 63.9933L389.174 63.756L389.393 64.5434L389.335 64.8238Z'
        fill='url(#paint1751_linear_3150_15389)'
      />
      <path
        d='M722.918 235.995L724.263 234.086L724.723 234.938L723.332 236.761L722.918 235.995Z'
        fill='url(#paint1752_linear_3150_15389)'
      />
      <path
        d='M550.124 -56.5784L549.331 -58.0562L549.699 -58.6602L550.504 -57.2256L550.124 -56.5784Z'
        fill='url(#paint1753_linear_3150_15389)'
      />
      <path
        d='M759.611 439.343L760.209 437.919L761.37 438.383L760.772 439.86L759.611 439.343Z'
        fill='url(#paint1754_linear_3150_15389)'
      />
      <path
        d='M534.877 -50.0957L534.313 -51.0773V-51.8647L535.095 -50.8292L534.877 -50.0957Z'
        fill='url(#paint1755_linear_3150_15389)'
      />
      <path
        d='M528.805 13.4369L527.724 12.5955L528.15 11.6571L529.231 12.4553L528.805 13.4369Z'
        fill='url(#paint1756_linear_3150_15389)'
      />
      <path
        d='M333.209 -15.924L331.749 -16.4742L331.853 -16.7546L333.336 -16.3016L333.209 -15.924Z'
        fill='url(#paint1757_linear_3150_15389)'
      />
      <path
        d='M691.836 263.857L691.48 261.904L692.653 262.131L693.113 263.835L691.836 263.857Z'
        fill='url(#paint1758_linear_3150_15389)'
      />
      <path
        d='M505.486 -69.3389L504.727 -70.5146L504.945 -71L505.279 -70.0832L505.486 -69.3389Z'
        fill='url(#paint1759_linear_3150_15389)'
      />
      <path
        d='M382.758 72.6117L382.528 71.7596L382.597 71.4791L382.839 72.2773L382.758 72.6117Z'
        fill='url(#paint1760_linear_3150_15389)'
      />
      <path
        d='M711.258 40.6944L711.453 38.796L712.661 38.6989L712.442 40.5973L711.258 40.6944Z'
        fill='url(#paint1761_linear_3150_15389)'
      />
      <path
        d='M473.22 162.129L473.059 161.396L473.76 160.899L473.852 161.741L473.22 162.129Z'
        fill='url(#paint1762_linear_3150_15389)'
      />
      <path
        d='M445.53 195.157L444.679 193.766L445.036 192.968L445.956 194.478L445.53 195.157Z'
        fill='url(#paint1763_linear_3150_15389)'
      />
      <path
        d='M752.378 145.076L753.861 143.274L754.931 143.716L753.528 145.248L752.378 145.076Z'
        fill='url(#paint1764_linear_3150_15389)'
      />
      <path
        d='M737.958 511.44L739.384 510.663L739.81 511.407L738.338 512.108L737.958 511.44Z'
        fill='url(#paint1765_linear_3150_15389)'
      />
      <path
        d='M270.506 -34.6818L269.816 -33.1825L269.46 -33.56L270.069 -35.1564L270.506 -34.6818Z'
        fill='url(#paint1766_linear_3150_15389)'
      />
      <path
        d='M357.622 -21.0261L356.38 -21.7056L356.541 -22.0723L357.828 -21.4683L357.622 -21.0261Z'
        fill='url(#paint1767_linear_3150_15389)'
      />
      <path
        d='M640.563 255.508L639.608 253.502L640.666 253.2L641.69 255.152L640.563 255.508Z'
        fill='url(#paint1768_linear_3150_15389)'
      />
      <path
        d='M575.79 148.872L575.709 148.301L575.744 147.664L575.847 148.279L575.79 148.872Z'
        fill='url(#paint1769_linear_3150_15389)'
      />
      <path
        d='M514.317 19.7038L513.983 19.4773L513.788 18.4958L514.098 18.6468L514.317 19.7038Z'
        fill='url(#paint1770_linear_3150_15389)'
      />
      <path
        d='M365.349 50.6504L365.28 49.6904L365.533 49.6364L365.705 50.5317L365.349 50.6504Z'
        fill='url(#paint1771_linear_3150_15389)'
      />
      <path
        d='M571.627 166.616L571.604 165.937H572.754L572.731 166.562L571.627 166.616Z'
        fill='url(#paint1772_linear_3150_15389)'
      />
      <path
        d='M677.279 579.999L678.865 579.611L678.981 580.096L677.371 580.387L677.279 579.999Z'
        fill='url(#paint1773_linear_3150_15389)'
      />
      <path
        d='M335.475 -30.4211L335.498 -31.7263L335.705 -31.4351L335.854 -30.1515L335.475 -30.4211Z'
        fill='url(#paint1774_linear_3150_15389)'
      />
      <path
        d='M252.855 -33.6463L252.096 -32.0822L251.694 -32.5676L252.372 -34.2072L252.855 -33.6463Z'
        fill='url(#paint1775_linear_3150_15389)'
      />
      <path
        d='M571.455 158.332L571.432 157.804L571.88 157.761L571.903 158.289L571.455 158.332Z'
        fill='url(#paint1776_linear_3150_15389)'
      />
      <path
        d='M623.314 144.547L623.211 143.706L623.786 143.242L623.889 144.169L623.314 144.547Z'
        fill='url(#paint1777_linear_3150_15389)'
      />
      <path
        d='M310.89 -10.5847L309.361 -11.0162L309.407 -11.2103L310.959 -10.8759L310.89 -10.5847Z'
        fill='url(#paint1778_linear_3150_15389)'
      />
      <path
        d='M730.587 71.8782L731.105 70.0014L732.301 70.0769L731.749 71.9753L730.587 71.8782Z'
        fill='url(#paint1779_linear_3150_15389)'
      />
      <path
        d='M546.536 -55.2733L545.904 -56.5137L546.031 -57.2795L546.858 -55.9528L546.536 -55.2733Z'
        fill='url(#paint1780_linear_3150_15389)'
      />
      <path
        d='M673.829 9.03598L673.645 7.35328L674.726 6.94339L675.025 8.77709L673.829 9.03598Z'
        fill='url(#paint1781_linear_3150_15389)'
      />
      <path
        d='M273.714 -32.9344L272.875 -32.4921L272.967 -33.3982L273.76 -33.9375L273.714 -32.9344Z'
        fill='url(#paint1782_linear_3150_15389)'
      />
      <path
        d='M713.558 250.913L714.627 248.907L715.087 249.899L713.983 251.733L713.558 250.913Z'
        fill='url(#paint1783_linear_3150_15389)'
      />
      <path
        d='M334.773 -17.0027L333.359 -17.5636L333.462 -17.8548L334.9 -17.3802L334.773 -17.0027Z'
        fill='url(#paint1784_linear_3150_15389)'
      />
      <path
        d='M288.375 -3.35774L286.777 -3.67054L286.8 -3.76762L288.41 -3.55189L288.375 -3.35774Z'
        fill='url(#paint1785_linear_3150_15389)'
      />
      <path
        d='M639.781 609.079L641.379 608.907L641.402 609.209L639.792 609.273L639.781 609.079Z'
        fill='url(#paint1786_linear_3150_15389)'
      />
      <path
        d='M708.349 39.8531L708.521 37.9762L709.729 37.987L709.556 39.8962L708.349 39.8531Z'
        fill='url(#paint1787_linear_3150_15389)'
      />
      <path
        d='M672.472 227.625L672.61 226.514L673.438 227.15L673.335 228.391L672.472 227.625Z'
        fill='url(#paint1788_linear_3150_15389)'
      />
      <path
        d='M526.781 12.4877L525.781 11.6355L526.126 10.7079L527.195 11.4845L526.781 12.4877Z'
        fill='url(#paint1789_linear_3150_15389)'
      />
      <path
        d='M520.377 16.2414L519.388 15.4863L519.641 14.5263L520.676 15.2059L520.377 16.2414Z'
        fill='url(#paint1790_linear_3150_15389)'
      />
      <path
        d='M504.37 159.853L504.324 159.098L505.21 158.699V159.476L504.37 159.853Z'
        fill='url(#paint1791_linear_3150_15389)'
      />
      <path
        d='M753.436 398.106L754.482 397.113L755.195 397.922L754.034 398.904L753.436 398.106Z'
        fill='url(#paint1792_linear_3150_15389)'
      />
      <path
        d='M693.952 565.286L695.504 564.801L695.677 565.362L694.09 565.761L693.952 565.286Z'
        fill='url(#paint1793_linear_3150_15389)'
      />
      <path
        d='M534.888 238.994L533.048 236.912L533.497 236.2L535.463 238.325L534.888 238.994Z'
        fill='url(#paint1794_linear_3150_15389)'
      />
      <path
        d='M581.654 167.328L581.666 166.8H582.114L582.103 167.317L581.654 167.328Z'
        fill='url(#paint1795_linear_3150_15389)'
      />
      <path
        d='M451.303 178.794L450.785 177.737L450.728 177.155L451.314 177.974L451.303 178.794Z'
        fill='url(#paint1796_linear_3150_15389)'
      />
      <path
        d='M815.771 414.145L817.208 413.239L818.002 414.307L816.507 415.181L815.771 414.145Z'
        fill='url(#paint1797_linear_3150_15389)'
      />
      <path
        d='M377.756 57.9205L377.584 57.0036L377.756 56.8526L377.963 57.7155L377.756 57.9205Z'
        fill='url(#paint1798_linear_3150_15389)'
      />
      <path
        d='M605.595 168.601L605.652 168.04L606.158 168.126L606.043 168.655L605.595 168.601Z'
        fill='url(#paint1799_linear_3150_15389)'
      />
      <path
        d='M647.876 4.10653L647.531 2.56406L648.577 2.11103L649.083 3.76137L647.876 4.10653Z'
        fill='url(#paint1800_linear_3150_15389)'
      />
      <path
        d='M453.752 176.227L453.419 175.094L454.005 174.436L454.35 175.58L453.752 176.227Z'
        fill='url(#paint1801_linear_3150_15389)'
      />
      <path
        d='M764.084 417.144L765.222 416.216L765.912 417.047L764.682 417.931L764.084 417.144Z'
        fill='url(#paint1802_linear_3150_15389)'
      />
      <path
        d='M300.84 -29.7524L300.633 -31.3164L300.783 -31.187L301.036 -29.7092L300.84 -29.7524Z'
        fill='url(#paint1803_linear_3150_15389)'
      />
      <path
        d='M461.203 209.245L459.938 207.389L460.26 206.494L461.583 208.392L461.203 209.245Z'
        fill='url(#paint1804_linear_3150_15389)'
      />
      <path
        d='M693.642 381.257L694.309 379.693L695.033 380.351L694.251 382.001L693.642 381.257Z'
        fill='url(#paint1805_linear_3150_15389)'
      />
      <path
        d='M714.685 422.861L715.811 421.469L716.363 422.171L715.374 423.4L714.685 422.861Z'
        fill='url(#paint1806_linear_3150_15389)'
      />
      <path
        d='M521.492 15.5834L520.561 14.8823L520.756 13.9007L521.664 14.5371L521.492 15.5834Z'
        fill='url(#paint1807_linear_3150_15389)'
      />
      <path
        d='M698.897 289.194L700.437 287.932L700.621 288.59L699.173 289.626L698.897 289.194Z'
        fill='url(#paint1808_linear_3150_15389)'
      />
      <path
        d='M336.717 -18.0598L335.36 -18.6422L335.463 -18.9227L336.855 -18.4373L336.717 -18.0598Z'
        fill='url(#paint1809_linear_3150_15389)'
      />
      <path
        d='M710.832 394.74L711.534 393.295L712.408 393.683L711.591 395.15L710.832 394.74Z'
        fill='url(#paint1810_linear_3150_15389)'
      />
      <path
        d='M618.485 622.972L620.06 622.929V623.134L618.485 623.069V622.972Z'
        fill='url(#paint1811_linear_3150_15389)'
      />
      <path
        d='M312.546 -11.9007L311.051 -12.3537L311.097 -12.5478L312.626 -12.1919L312.546 -11.9007Z'
        fill='url(#paint1812_linear_3150_15389)'
      />
      <path
        d='M252.74 -30.5937L252.326 -28.9002L251.912 -29.1483L252.223 -30.9065L252.74 -30.5937Z'
        fill='url(#paint1813_linear_3150_15389)'
      />
      <path
        d='M759.381 160.543L761.22 158.645L762.175 159.217L760.427 160.845L759.381 160.543Z'
        fill='url(#paint1814_linear_3150_15389)'
      />
      <path
        d='M711.143 143.911L711.775 142.67L712.914 142.724L712.212 143.986L711.143 143.911Z'
        fill='url(#paint1815_linear_3150_15389)'
      />
      <path
        d='M658.099 596.88L659.697 596.61L659.754 597.009L658.145 597.171L658.099 596.88Z'
        fill='url(#paint1816_linear_3150_15389)'
      />
      <path
        d='M709.636 549.473L711.154 548.891L711.396 549.527L709.843 550.023L709.636 549.473Z'
        fill='url(#paint1817_linear_3150_15389)'
      />
      <path
        d='M685.489 394.579L686.144 392.766L686.926 393.112L686.259 394.891L685.489 394.579Z'
        fill='url(#paint1818_linear_3150_15389)'
      />
      <path
        d='M531.956 -66.0706L531.358 -67.3542L531.45 -68.0337L532.013 -66.8364L531.956 -66.0706Z'
        fill='url(#paint1819_linear_3150_15389)'
      />
      <path
        d='M569.132 157.663L569.109 157.146L569.534 157.103L569.557 157.62L569.132 157.663Z'
        fill='url(#paint1820_linear_3150_15389)'
      />
      <path
        d='M393.165 140.567L392.866 139.467L392.624 138.765L392.992 139.65L393.165 140.567Z'
        fill='url(#paint1821_linear_3150_15389)'
      />
      <path
        d='M368.258 64.058L368.108 63.098L368.35 62.9146L368.545 63.8099L368.258 64.058Z'
        fill='url(#paint1822_linear_3150_15389)'
      />
      <path
        d='M635.227 253.642L634.284 251.7L635.273 251.377L636.262 253.361L635.227 253.642Z'
        fill='url(#paint1823_linear_3150_15389)'
      />
      <path
        d='M695.665 403.003L696.539 401.299L697.183 401.849L696.424 403.402L695.665 403.003Z'
        fill='url(#paint1824_linear_3150_15389)'
      />
      <path
        d='M289.893 -4.90021L288.318 -5.23458L288.341 -5.33167L289.928 -5.09437L289.893 -4.90021Z'
        fill='url(#paint1825_linear_3150_15389)'
      />
      <path
        d='M688.559 398.936L689.123 397.21L690.042 397.415L689.318 399.228L688.559 398.936Z'
        fill='url(#paint1826_linear_3150_15389)'
      />
      <path
        d='M733.911 74.4131L734.463 72.396L735.635 72.6764L735.038 74.5425L733.911 74.4131Z'
        fill='url(#paint1827_linear_3150_15389)'
      />
      <path
        d='M701.415 153.856L701.955 152.756L703.013 152.831L702.415 153.985L701.415 153.856Z'
        fill='url(#paint1828_linear_3150_15389)'
      />
      <path
        d='M761.289 126.771L762.75 124.657L763.819 125.121L762.336 127.094L761.289 126.771Z'
        fill='url(#paint1829_linear_3150_15389)'
      />
      <path
        d='M489.088 222.814L487.524 220.797L487.835 219.88L489.491 222.016L489.088 222.814Z'
        fill='url(#paint1830_linear_3150_15389)'
      />
      <path
        d='M323.297 -29.2454L323.286 -30.5829L323.654 -30.2593L323.677 -28.9541L323.297 -29.2454Z'
        fill='url(#paint1831_linear_3150_15389)'
      />
      <path
        d='M752.481 427.672L753.551 426.679L754.275 427.424L753.079 428.373L752.481 427.672Z'
        fill='url(#paint1832_linear_3150_15389)'
      />
      <path
        d='M703.462 35.9376L703.588 34.0823L704.818 34.147L704.715 36.0346L703.462 35.9376Z'
        fill='url(#paint1833_linear_3150_15389)'
      />
      <path
        d='M516.271 160.328L516.34 159.476L517.375 159.098L517.364 159.853L516.271 160.328Z'
        fill='url(#paint1834_linear_3150_15389)'
      />
      <path
        d='M534.014 237.365L532.335 235.316L532.761 234.647L534.52 236.675L534.014 237.365Z'
        fill='url(#paint1835_linear_3150_15389)'
      />
      <path
        d='M357.736 40.5003L357.782 39.5079L358.254 39.5834V40.5326L357.736 40.5003Z'
        fill='url(#paint1836_linear_3150_15389)'
      />
      <path
        d='M621.44 1.51777L620.9 0.104736L621.831 -0.488525L622.544 0.967667L621.44 1.51777Z'
        fill='url(#paint1837_linear_3150_15389)'
      />
      <path
        d='M739.166 207.281L740.614 205.135L741.212 206.03L739.672 208.134L739.166 207.281Z'
        fill='url(#paint1838_linear_3150_15389)'
      />
      <path
        d='M378.699 -25.6319L378.71 -26.2791L378.779 -26.5919L378.837 -26.031L378.699 -25.6319Z'
        fill='url(#paint1839_linear_3150_15389)'
      />
      <path
        d='M339.028 -19.0953L337.728 -19.6993L337.832 -19.9798L339.177 -19.4728L339.028 -19.0953Z'
        fill='url(#paint1840_linear_3150_15389)'
      />
      <path
        d='M724.252 532.614L725.735 531.934L726.057 532.635L724.539 533.239L724.252 532.614Z'
        fill='url(#paint1841_linear_3150_15389)'
      />
      <path
        d='M400.271 39.9501L400.133 39.0549L400.156 38.9686L400.351 39.8423L400.271 39.9501Z'
        fill='url(#paint1842_linear_3150_15389)'
      />
      <path
        d='M697.586 383.21L698.31 381.667L699.023 382.271L698.241 383.824L697.586 383.21Z'
        fill='url(#paint1843_linear_3150_15389)'
      />
      <path
        d='M386.932 -40.5928L387.001 -41.4018L387.082 -41.6607L387.013 -40.9164L386.932 -40.5928Z'
        fill='url(#paint1844_linear_3150_15389)'
      />
      <path
        d='M203.801 -14.2306L204.767 -12.6234L204.319 -12.4292L203.249 -13.9933L203.801 -14.2306Z'
        fill='url(#paint1845_linear_3150_15389)'
      />
      <path
        d='M238.62 -27.1312L238.505 -25.3515L238.045 -25.4809V-27.3146L238.62 -27.1312Z'
        fill='url(#paint1846_linear_3150_15389)'
      />
      <path
        d='M737.74 198.512L738.89 196.225L739.568 197.261L738.349 199.526L737.74 198.512Z'
        fill='url(#paint1847_linear_3150_15389)'
      />
      <path
        d='M724.792 453.052L725.424 451.618L726.459 452.038L725.516 453.43L724.792 453.052Z'
        fill='url(#paint1848_linear_3150_15389)'
      />
      <path
        d='M385.196 -42.7933L385.276 -43.5699L385.357 -43.8396L385.276 -43.1276L385.196 -42.7933Z'
        fill='url(#paint1849_linear_3150_15389)'
      />
      <path
        d='M703.554 266.392L704.485 264.838L704.933 265.583L703.91 267.168L703.554 266.392Z'
        fill='url(#paint1850_linear_3150_15389)'
      />
      <path
        d='M364.095 -27.4117L363.865 -27.8431L363.923 -28.1991L364.233 -27.8647L364.095 -27.4117Z'
        fill='url(#paint1851_linear_3150_15389)'
      />
      <path
        d='M579.136 166.681L579.147 166.152L579.584 166.142L579.573 166.67L579.136 166.681Z'
        fill='url(#paint1852_linear_3150_15389)'
      />
      <path
        d='M704.117 417.543L704.68 415.979L705.704 416.216L704.853 417.867L704.117 417.543Z'
        fill='url(#paint1853_linear_3150_15389)'
      />
      <path
        d='M314.673 -13.195L313.224 -13.6697L313.282 -13.8638L314.754 -13.4863L314.673 -13.195Z'
        fill='url(#paint1854_linear_3150_15389)'
      />
      <path
        d='M382.666 -45.0261L382.735 -45.7596L382.804 -46.04L382.758 -45.382L382.666 -45.0261Z'
        fill='url(#paint1855_linear_3150_15389)'
      />
      <path
        d='M460.237 188.405L459.663 186.992L460.214 186.129L460.801 187.65L460.237 188.405Z'
        fill='url(#paint1856_linear_3150_15389)'
      />
      <path
        d='M675.634 583.429L677.21 583.062L677.325 583.548L675.726 583.817L675.634 583.429Z'
        fill='url(#paint1857_linear_3150_15389)'
      />
      <path
        d='M418.106 181.124L417.324 179.7L417.439 178.978L418.221 180.218L418.106 181.124Z'
        fill='url(#paint1858_linear_3150_15389)'
      />
      <path
        d='M237.274 -30.3025L236.699 -28.5874L236.239 -28.9326L236.711 -30.7231L237.274 -30.3025Z'
        fill='url(#paint1859_linear_3150_15389)'
      />
      <path
        d='M699.609 250.654L700.207 249.133L700.817 249.888L700.127 251.56L699.609 250.654Z'
        fill='url(#paint1860_linear_3150_15389)'
      />
      <path
        d='M390.221 -38.004L390.301 -38.8238L390.393 -39.0827L390.29 -38.3169L390.221 -38.004Z'
        fill='url(#paint1861_linear_3150_15389)'
      />
      <path
        d='M637.309 612.261L638.895 612.11L638.918 612.412L637.32 612.456L637.309 612.261Z'
        fill='url(#paint1862_linear_3150_15389)'
      />
      <path
        d='M429.225 196.97L428.098 195.244L428.156 194.489L429.248 195.912L429.225 196.97Z'
        fill='url(#paint1863_linear_3150_15389)'
      />
      <path
        d='M378.147 -47.3667V-48.0139L378.216 -48.3052L378.25 -47.7551L378.147 -47.3667Z'
        fill='url(#paint1864_linear_3150_15389)'
      />
      <path
        d='M607.883 145.852L607.826 145.076L608.205 144.666L608.285 145.529L607.883 145.852Z'
        fill='url(#paint1865_linear_3150_15389)'
      />
      <path
        d='M620.118 252.552L619.083 250.395L619.991 250.115L621.095 252.002L620.118 252.552Z'
        fill='url(#paint1866_linear_3150_15389)'
      />
      <path
        d='M601.329 1.72272L600.627 0.406761L601.478 -0.283577L602.283 1.03238L601.329 1.72272Z'
        fill='url(#paint1867_linear_3150_15389)'
      />
      <path
        d='M366.591 49.5394L366.545 48.547L366.878 48.5146L367.016 49.4423L366.591 49.5394Z'
        fill='url(#paint1868_linear_3150_15389)'
      />
      <path
        d='M737.74 514.784L739.177 514.018L739.591 514.773L738.108 515.474L737.74 514.784Z'
        fill='url(#paint1869_linear_3150_15389)'
      />
      <path
        d='M226.546 -23.6795L226.73 -21.8674L226.258 -21.8998L225.959 -23.7227L226.546 -23.6795Z'
        fill='url(#paint1870_linear_3150_15389)'
      />
      <path
        d='M769.224 319.051L769.937 317.563L771.064 318.232L770.443 319.634L769.224 319.051Z'
        fill='url(#paint1871_linear_3150_15389)'
      />
      <path
        d='M392.682 34.8913L392.498 33.9313H392.659L392.785 34.8589L392.682 34.8913Z'
        fill='url(#paint1872_linear_3150_15389)'
      />
      <path
        d='M530.392 -65.0027L530.001 -66.2108L529.955 -66.9659L530.427 -65.7578L530.392 -65.0027Z'
        fill='url(#paint1873_linear_3150_15389)'
      />
      <path
        d='M354.011 -28.3069L353.631 -28.5227L353.677 -28.8894L354.137 -28.7816L354.011 -28.3069Z'
        fill='url(#paint1874_linear_3150_15389)'
      />
      <path
        d='M732.381 398.462L733.37 397.415L733.991 398.224L732.899 399.292L732.381 398.462Z'
        fill='url(#paint1875_linear_3150_15389)'
      />
      <path
        d='M809.274 397.21L810.516 396.143L811.448 397.081L810.171 398.149L809.274 397.21Z'
        fill='url(#paint1876_linear_3150_15389)'
      />
      <path
        d='M291.986 -6.42111L290.445 -6.77707L290.468 -6.87414L292.02 -6.61526L291.986 -6.42111Z'
        fill='url(#paint1877_linear_3150_15389)'
      />
      <path
        d='M727.724 220.495L728.771 218.424L729.392 219.405L728.265 221.487L727.724 220.495Z'
        fill='url(#paint1878_linear_3150_15389)'
      />
      <path
        d='M214.357 -18.8472L214.897 -17.0998L214.426 -17.0135L213.759 -18.7501L214.357 -18.8472Z'
        fill='url(#paint1879_linear_3150_15389)'
      />
      <path
        d='M743.19 301.448L743.558 299.797L744.777 300.175L744.455 301.771L743.19 301.448Z'
        fill='url(#paint1880_linear_3150_15389)'
      />
      <path
        d='M476.497 170.791L476.347 169.701L477.244 168.946L477.325 170.133L476.497 170.791Z'
        fill='url(#paint1881_linear_3150_15389)'
      />
      <path
        d='M353.976 -28.9326L353.965 -30.1946L354.114 -29.8494L354.31 -28.5551L353.976 -28.9326Z'
        fill='url(#paint1882_linear_3150_15389)'
      />
      <path
        d='M398.661 -34.7141L398.856 -35.5771L398.937 -35.8036L398.718 -34.9946L398.661 -34.7141Z'
        fill='url(#paint1883_linear_3150_15389)'
      />
      <path
        d='M615.346 625.949L616.91 625.939V626.133L615.346 626.047V625.949Z'
        fill='url(#paint1884_linear_3150_15389)'
      />
      <path
        d='M516.685 12.9838L515.915 12.2935L516.007 11.3011L516.789 11.9268L516.685 12.9838Z'
        fill='url(#paint1885_linear_3150_15389)'
      />
      <path
        d='M256.834 -33.7218L255.868 -32.5784L255.695 -33.2688L256.592 -34.5092L256.834 -33.7218Z'
        fill='url(#paint1886_linear_3150_15389)'
      />
      <path
        d='M342.88 -30.0975L342.328 -30.0867L342.351 -30.4751L342.972 -30.5937L342.88 -30.0975Z'
        fill='url(#paint1887_linear_3150_15389)'
      />
      <path
        d='M375.111 -49.3407L375.065 -49.8584L375.123 -50.1712L375.215 -49.7398L375.111 -49.3407Z'
        fill='url(#paint1888_linear_3150_15389)'
      />
      <path
        d='M524.746 -61.5079L524.505 -62.5757L524.378 -63.3631L524.746 -62.2629V-61.5079Z'
        fill='url(#paint1889_linear_3150_15389)'
      />
      <path
        d='M701.334 386.392L702.047 384.914L702.795 385.432L701.967 386.963L701.334 386.392Z'
        fill='url(#paint1890_linear_3150_15389)'
      />
      <path
        d='M508.51 12.52L507.578 11.6463L507.866 10.7187L508.832 11.5169L508.51 12.52Z'
        fill='url(#paint1891_linear_3150_15389)'
      />
      <path
        d='M604.215 252.423L602.984 250.244L603.801 249.856L605.089 251.873L604.215 252.423Z'
        fill='url(#paint1892_linear_3150_15389)'
      />
      <path
        d='M692.308 568.781L693.86 568.306L694.021 568.878L692.434 569.255L692.308 568.781Z'
        fill='url(#paint1893_linear_3150_15389)'
      />
      <path
        d='M267.919 1.60406L267.344 -0.0247116L267.746 -0.445381L267.815 1.44226L267.919 1.60406Z'
        fill='url(#paint1894_linear_3150_15389)'
      />
      <path
        d='M317.283 -14.4679L315.881 -14.9533L315.938 -15.1474L317.364 -14.7591L317.283 -14.4679Z'
        fill='url(#paint1895_linear_3150_15389)'
      />
      <path
        d='M334.888 -32.6108L334.233 -32.4166V-32.8157L334.934 -33.107L334.888 -32.6108Z'
        fill='url(#paint1896_linear_3150_15389)'
      />
      <path
        d='M722.895 142.854L723.792 141.44L724.884 141.635L723.953 142.94L722.895 142.854Z'
        fill='url(#paint1897_linear_3150_15389)'
      />
      <path
        d='M329.288 -35.9007L328.575 -35.5771L328.564 -35.9762L329.3 -36.4184L329.288 -35.9007Z'
        fill='url(#paint1898_linear_3150_15389)'
      />
      <path
        d='M594.291 1.9708L593.532 0.762718L594.211 -0.0462723L595.05 1.14024L594.291 1.9708Z'
        fill='url(#paint1899_linear_3150_15389)'
      />
      <path
        d='M338.188 -23.3775L338.246 -24.6288L338.683 -24.1973L338.648 -22.9892L338.188 -23.3775Z'
        fill='url(#paint1900_linear_3150_15389)'
      />
      <path
        d='M533.416 18.7654L532.519 17.9133L532.761 16.9101L533.761 17.676L533.416 18.7654Z'
        fill='url(#paint1901_linear_3150_15389)'
      />
      <path
        d='M356.978 42.28L356.989 41.2337L357.472 41.2769L357.495 42.2585L356.978 42.28Z'
        fill='url(#paint1902_linear_3150_15389)'
      />
      <path
        d='M655.488 600.213L657.075 599.965L657.121 600.364L655.523 600.515L655.488 600.213Z'
        fill='url(#paint1903_linear_3150_15389)'
      />
      <path
        d='M742.063 79.5259L742.765 77.347L743.915 77.8L743.179 79.7848L742.063 79.5259Z'
        fill='url(#paint1904_linear_3150_15389)'
      />
      <path
        d='M794.176 409.248L795.211 408.008L796.269 408.784L795.223 410.036L794.176 409.248Z'
        fill='url(#paint1905_linear_3150_15389)'
      />
      <path
        d='M467.39 187.521L466.861 185.956L467.459 185.18L468.011 186.614L467.39 187.521Z'
        fill='url(#paint1906_linear_3150_15389)'
      />
      <path
        d='M570.903 162.107L570.972 161.363L571.903 161.298L571.984 161.989L570.903 162.107Z'
        fill='url(#paint1907_linear_3150_15389)'
      />
      <path
        d='M511.2 159.939L511.166 159.023L512.143 158.58L512.178 159.443L511.2 159.939Z'
        fill='url(#paint1908_linear_3150_15389)'
      />
      <path
        d='M519.077 25.302L518.238 24.6009L518.33 23.5438L519.215 24.1695L519.077 25.302Z'
        fill='url(#paint1909_linear_3150_15389)'
      />
      <path
        d='M465.607 206.483L464.573 204.423L465.033 203.42L466.079 205.426L465.607 206.483Z'
        fill='url(#paint1910_linear_3150_15389)'
      />
      <path
        d='M359.105 53.7569L359.082 52.7106L359.542 52.6567L359.611 53.6275L359.105 53.7569Z'
        fill='url(#paint1911_linear_3150_15389)'
      />
      <path
        d='M754.482 175.515L756.023 173.002L756.736 174.005L755.126 176.389L754.482 175.515Z'
        fill='url(#paint1912_linear_3150_15389)'
      />
      <path
        d='M539.2 14.2459L538.257 13.2535L538.706 12.3151L539.718 13.2427L539.2 14.2459Z'
        fill='url(#paint1913_linear_3150_15389)'
      />
      <path
        d='M693.343 28.4409L693.389 26.6719L694.607 26.6396L694.642 28.5272L693.343 28.4409Z'
        fill='url(#paint1914_linear_3150_15389)'
      />
      <path
        d='M321.699 -38.1658L320.94 -37.6481L320.871 -38.0256L321.688 -38.6944L321.699 -38.1658Z'
        fill='url(#paint1915_linear_3150_15389)'
      />
      <path
        d='M413.633 198.199L412.391 196.193L412.471 195.276L413.679 197.067L413.633 198.199Z'
        fill='url(#paint1916_linear_3150_15389)'
      />
      <path
        d='M469.218 178.169L468.689 176.81L469.138 176.249L469.736 177.338L469.218 178.169Z'
        fill='url(#paint1917_linear_3150_15389)'
      />
      <path
        d='M492.883 169.572L492.687 168.525L493.561 167.878L493.699 168.978L492.883 169.572Z'
        fill='url(#paint1918_linear_3150_15389)'
      />
      <path
        d='M359.289 28.1173L359.323 27.0926L359.967 27.2975L359.806 28.2899L359.289 28.1173Z'
        fill='url(#paint1919_linear_3150_15389)'
      />
      <path
        d='M708.004 552.979L709.533 552.407L709.763 553.054L708.211 553.54L708.004 552.979Z'
        fill='url(#paint1920_linear_3150_15389)'
      />
      <path
        d='M762.807 130.589L764.348 128.4L765.372 128.907L763.785 130.988L762.807 130.589Z'
        fill='url(#paint1921_linear_3150_15389)'
      />
      <path
        d='M523.55 236.534L521.94 234.28L522.366 233.46L524.033 235.715L523.55 236.534Z'
        fill='url(#paint1922_linear_3150_15389)'
      />
      <path
        d='M376.227 -50.8616L376.204 -51.2822L376.284 -51.5951L376.319 -51.2607L376.227 -50.8616Z'
        fill='url(#paint1923_linear_3150_15389)'
      />
      <path
        d='M593.624 251.873L592.336 249.683L593.061 249.252L594.406 251.323L593.624 251.873Z'
        fill='url(#paint1924_linear_3150_15389)'
      />
      <path
        d='M760.841 169.561L762.899 167.619L763.842 168.202L761.784 169.938L760.841 169.561Z'
        fill='url(#paint1925_linear_3150_15389)'
      />
      <path
        d='M294.642 -7.92043L293.159 -8.29797L293.182 -8.39504L294.688 -8.12538L294.642 -7.92043Z'
        fill='url(#paint1926_linear_3150_15389)'
      />
      <path
        d='M573.018 4.10653L572.259 2.88766L572.926 2.10023L573.869 3.27598L573.018 4.10653Z'
        fill='url(#paint1927_linear_3150_15389)'
      />
      <path
        d='M733.083 460.042L734.566 459.255L734.762 460.204L733.531 460.808L733.083 460.042Z'
        fill='url(#paint1928_linear_3150_15389)'
      />
      <path
        d='M320.354 -15.7083L319.008 -16.2153L319.066 -16.4094L320.446 -15.9995L320.354 -15.7083Z'
        fill='url(#paint1929_linear_3150_15389)'
      />
      <path
        d='M221.475 -24.5317L221.153 -22.698L220.658 -22.9029L220.854 -24.769L221.475 -24.5317Z'
        fill='url(#paint1930_linear_3150_15389)'
      />
      <path
        d='M349.331 34.2872V33.187L349.871 33.2625L349.848 34.3088L349.331 34.2872Z'
        fill='url(#paint1931_linear_3150_15389)'
      />
      <path
        d='M317.548 -28.8571L317.421 -30.2917L317.674 -30.0112L317.824 -28.6306L317.548 -28.8571Z'
        fill='url(#paint1932_linear_3150_15389)'
      />
      <path
        d='M352.206 -14.8562L352.298 -16.0319L352.7 -15.6004V-14.4355L352.206 -14.8562Z'
        fill='url(#paint1933_linear_3150_15389)'
      />
      <path
        d='M540.224 23.2634L539.787 22.8535L539.626 21.7209L540.04 22.0553L540.224 23.2634Z'
        fill='url(#paint1934_linear_3150_15389)'
      />
      <path
        d='M548.273 9.43507L547.399 8.27013L548.031 7.43958L549.043 8.52901L548.273 9.43507Z'
        fill='url(#paint1935_linear_3150_15389)'
      />
      <path
        d='M472.242 163.434L471.966 162.366L471.897 161.924L472.415 162.636L472.242 163.434Z'
        fill='url(#paint1936_linear_3150_15389)'
      />
      <path
        d='M350.837 47.2418L350.768 46.1632L351.286 46.0985L351.309 47.1448L350.837 47.2418Z'
        fill='url(#paint1937_linear_3150_15389)'
      />
      <path
        d='M633.916 615.433L635.48 615.314L635.492 615.616L633.928 615.638L633.916 615.433Z'
        fill='url(#paint1938_linear_3150_15389)'
      />
      <path
        d='M649.049 147.675L649.175 146.704L649.911 146.521L649.727 147.556L649.049 147.675Z'
        fill='url(#paint1939_linear_3150_15389)'
      />
      <path
        d='M431.352 186.172L430.432 184.392L430.662 183.713L431.582 185.05L431.352 186.172Z'
        fill='url(#paint1940_linear_3150_15389)'
      />
      <path
        d='M638.447 191.08L638.458 190.196L639.447 190.595L639.344 191.361L638.447 191.08Z'
        fill='url(#paint1941_linear_3150_15389)'
      />
      <path
        d='M526.988 7.94654L526.402 7.34248V6.31777L526.885 6.86789L526.988 7.94654Z'
        fill='url(#paint1942_linear_3150_15389)'
      />
      <path
        d='M512.879 22.0769L511.89 21.1277L512.247 20.1569L513.282 21.0198L512.879 22.0769Z'
        fill='url(#paint1943_linear_3150_15389)'
      />
      <path
        d='M382.494 142.53L382.034 141.257L381.896 140.405L382.321 141.602L382.494 142.53Z'
        fill='url(#paint1944_linear_3150_15389)'
      />
      <path
        d='M739.591 409.335L740.637 408.364L741.235 409.173L740.074 410.144L739.591 409.335Z'
        fill='url(#paint1945_linear_3150_15389)'
      />
      <path
        d='M456.075 197.077L455.143 195.19L455.58 194.327L456.512 196.02L456.075 197.077Z'
        fill='url(#paint1946_linear_3150_15389)'
      />
      <path
        d='M500.725 160.802L500.472 159.853L501.104 159.422L501.38 160.306L500.725 160.802Z'
        fill='url(#paint1947_linear_3150_15389)'
      />
      <path
        d='M488.387 171.664L488.088 170.456L488.709 169.938L489.088 170.952L488.387 171.664Z'
        fill='url(#paint1948_linear_3150_15389)'
      />
      <path
        d='M672.921 586.881L674.484 586.525L674.576 587.021L672.99 587.269L672.921 586.881Z'
        fill='url(#paint1949_linear_3150_15389)'
      />
      <path
        d='M760.784 175.418L762.842 173.423L763.716 174.016L761.577 175.925L760.784 175.418Z'
        fill='url(#paint1950_linear_3150_15389)'
      />
      <path
        d='M388.898 26.5964L388.806 25.5825L389.048 25.7443L389.151 26.7259L388.898 26.5964Z'
        fill='url(#paint1951_linear_3150_15389)'
      />
      <path
        d='M462.537 190.875L461.916 189.052L462.491 188.254L463.147 189.786L462.537 190.875Z'
        fill='url(#paint1952_linear_3150_15389)'
      />
      <path
        d='M492.457 162.366L492.124 161.396L492.584 160.953L492.963 161.849L492.457 162.366Z'
        fill='url(#paint1953_linear_3150_15389)'
      />
      <path
        d='M745.524 80.8526L746.249 78.6414L747.399 79.1591L746.64 81.3272L745.524 80.8526Z'
        fill='url(#paint1954_linear_3150_15389)'
      />
      <path
        d='M611.379 628.905L612.908 628.916L612.896 629.121L611.379 629.013V628.905Z'
        fill='url(#paint1955_linear_3150_15389)'
      />
      <path
        d='M722.665 536.087L724.16 535.418L724.47 536.13L722.941 536.723L722.665 536.087Z'
        fill='url(#paint1956_linear_3150_15389)'
      />
      <path
        d='M310.775 -38.3708L309.982 -37.5726L309.867 -37.9285L310.695 -38.867L310.775 -38.3708Z'
        fill='url(#paint1957_linear_3150_15389)'
      />
      <path
        d='M783.195 392.561L784.011 391.159L785.219 391.785L784.414 393.176L783.195 392.561Z'
        fill='url(#paint1958_linear_3150_15389)'
      />
      <path
        d='M379.573 -52.3717L379.63 -52.7384L379.722 -53.062L379.665 -52.7708L379.573 -52.3717Z'
        fill='url(#paint1959_linear_3150_15389)'
      />
      <path
        d='M723.504 292.042L723.688 290.284L724.861 290.553L724.758 292.236L723.504 292.042Z'
        fill='url(#paint1960_linear_3150_15389)'
      />
      <path
        d='M755.23 168.504L756.575 165.872L757.357 166.972L755.931 169.561L755.23 168.504Z'
        fill='url(#paint1961_linear_3150_15389)'
      />
      <path
        d='M369.109 -23.8306L369.04 -25.1357L369.132 -24.769L369.339 -23.4422L369.109 -23.8306Z'
        fill='url(#paint1962_linear_3150_15389)'
      />
      <path
        d='M583.977 250.924L582.7 248.766L583.367 248.238L584.701 250.395L583.977 250.924Z'
        fill='url(#paint1963_linear_3150_15389)'
      />
      <path
        d='M297.885 -9.3874L296.436 -9.7865L296.459 -9.88359L297.931 -9.59235L297.885 -9.3874Z'
        fill='url(#paint1964_linear_3150_15389)'
      />
      <path
        d='M794.889 372.628L795.924 371.377L796.97 372.153L795.982 373.405L794.889 372.628Z'
        fill='url(#paint1965_linear_3150_15389)'
      />
      <path
        d='M344.938 38.5371L344.869 37.3829L345.364 37.3506L345.398 38.4508L344.938 38.5371Z'
        fill='url(#paint1966_linear_3150_15389)'
      />
      <path
        d='M444.863 152.292L444.495 151.31L444.668 150.76L445.059 151.655L444.863 152.292Z'
        fill='url(#paint1967_linear_3150_15389)'
      />
      <path
        d='M762.255 133.318L763.555 130.783L764.498 131.646L763.106 134.235L762.255 133.318Z'
        fill='url(#paint1968_linear_3150_15389)'
      />
      <path
        d='M273.703 4.44093L273.749 2.74744L274.439 2.71507L274.347 4.32227L273.703 4.44093Z'
        fill='url(#paint1969_linear_3150_15389)'
      />
      <path
        d='M287.708 -14.9533L287.524 -16.5497L287.869 -16.4849L287.996 -14.964L287.708 -14.9533Z'
        fill='url(#paint1970_linear_3150_15389)'
      />
      <path
        d='M453.154 181.998L452.579 180.326L453.097 179.614L453.718 180.962L453.154 181.998Z'
        fill='url(#paint1971_linear_3150_15389)'
      />
      <path
        d='M332.002 -20.9937L331.922 -22.3312L332.289 -21.9645L332.335 -20.6917L332.002 -20.9937Z'
        fill='url(#paint1972_linear_3150_15389)'
      />
      <path
        d='M489.491 219.751L488.26 217.388L488.755 216.31L490.008 218.618L489.491 219.751Z'
        fill='url(#paint1973_linear_3150_15389)'
      />
      <path
        d='M400.098 63.5079L399.914 62.6665L399.891 62.3861L400.018 63.2167L400.098 63.5079Z'
        fill='url(#paint1974_linear_3150_15389)'
      />
      <path
        d='M736.199 518.181L737.648 517.405L738.05 518.181L736.555 518.872L736.199 518.181Z'
        fill='url(#paint1975_linear_3150_15389)'
      />
      <path
        d='M704.669 152.108L705.393 150.879L706.428 151.041L705.635 152.205L704.669 152.108Z'
        fill='url(#paint1976_linear_3150_15389)'
      />
      <path
        d='M689.502 572.286L691.054 571.833L691.204 572.416L689.628 572.772L689.502 572.286Z'
        fill='url(#paint1977_linear_3150_15389)'
      />
      <path
        d='M651.866 603.546L653.418 603.319L653.465 603.729L651.889 603.848L651.866 603.546Z'
        fill='url(#paint1978_linear_3150_15389)'
      />
      <path
        d='M701.035 240.59L701.622 239.005L702.277 239.867L701.656 241.432L701.035 240.59Z'
        fill='url(#paint1979_linear_3150_15389)'
      />
      <path
        d='M343.662 23.1879L343.696 22.0337L344.294 22.1955L344.237 23.2958L343.662 23.1879Z'
        fill='url(#paint1980_linear_3150_15389)'
      />
      <path
        d='M369.753 -7.28403L369.845 -8.4274L370.201 -7.95279L370.259 -6.79865L369.753 -7.28403Z'
        fill='url(#paint1981_linear_3150_15389)'
      />
      <path
        d='M590.382 149.142L590.336 148.409L590.52 148.096L590.497 148.894L590.382 149.142Z'
        fill='url(#paint1982_linear_3150_15389)'
      />
      <path
        d='M381.493 122.64L381.125 121.378L381.194 120.752L381.643 121.809L381.493 122.64Z'
        fill='url(#paint1983_linear_3150_15389)'
      />
      <path
        d='M410.424 108.973L410.321 107.981L410.079 107.474L410.413 108.218L410.424 108.973Z'
        fill='url(#paint1984_linear_3150_15389)'
      />
      <path
        d='M382.816 18.0535L382.85 17.018L383.229 17.3524L383.275 18.3663L382.816 18.0535Z'
        fill='url(#paint1985_linear_3150_15389)'
      />
      <path
        d='M677.428 220.139L677.67 218.996L678.486 219.632L678.256 220.797L677.428 220.139Z'
        fill='url(#paint1986_linear_3150_15389)'
      />
      <path
        d='M371.133 9.17621L371.225 8.10834L371.685 8.52901V9.56452L371.133 9.17621Z'
        fill='url(#paint1987_linear_3150_15389)'
      />
      <path
        d='M200.581 -10.7465L200.662 -8.89122L200.144 -8.90201L199.914 -10.7141L200.581 -10.7465Z'
        fill='url(#paint1988_linear_3150_15389)'
      />
      <path
        d='M345.916 -11.5447V-12.7851L346.364 -12.3537L346.353 -11.1672L345.916 -11.5447Z'
        fill='url(#paint1989_linear_3150_15389)'
      />
      <path
        d='M675.117 19.0782L675.013 17.4063L676.163 17.1906L676.393 18.9811L675.117 19.0782Z'
        fill='url(#paint1990_linear_3150_15389)'
      />
      <path
        d='M730.484 162.043L731.76 160.587L732.726 160.986L731.484 162.226L730.484 162.043Z'
        fill='url(#paint1991_linear_3150_15389)'
      />
      <path
        d='M359.427 15.5618V14.4508L360.048 14.7852L359.933 15.8207L359.427 15.5618Z'
        fill='url(#paint1992_linear_3150_15389)'
      />
      <path
        d='M470.379 205.858L469.483 203.636L470.046 202.514L470.954 204.65L470.379 205.858Z'
        fill='url(#paint1993_linear_3150_15389)'
      />
      <path
        d='M765.096 137.514L766.728 135.001L767.614 135.778L765.958 138.107L765.096 137.514Z'
        fill='url(#paint1994_linear_3150_15389)'
      />
      <path
        d='M303.864 -6.46425L303.795 -7.94201L304.221 -7.80178L304.29 -6.41033L303.864 -6.46425Z'
        fill='url(#paint1995_linear_3150_15389)'
      />
      <path
        d='M744.559 193.248L745.823 190.864L746.525 191.943L745.203 194.262L744.559 193.248Z'
        fill='url(#paint1996_linear_3150_15389)'
      />
      <path d='M629.581 618.582L631.122 618.485V618.798H629.581V618.582Z' fill='url(#paint1997_linear_3150_15389)' />
      <path
        d='M484.719 162.043L484.431 160.964L485.052 160.446L485.362 161.439L484.719 162.043Z'
        fill='url(#paint1998_linear_3150_15389)'
      />
      <path
        d='M359.737 -1.39458L359.783 -2.54875L360.335 -2.05257L360.255 -0.963135L359.737 -1.39458Z'
        fill='url(#paint1999_linear_3150_15389)'
      />
      <path
        d='M763.888 133.998L765.556 131.668L766.51 132.283L764.831 134.418L763.888 133.998Z'
        fill='url(#paint2000_linear_3150_15389)'
      />
      <path
        d='M309.349 -25.7721L309.131 -27.2715L309.315 -27.0449L309.499 -25.6211L309.349 -25.7721Z'
        fill='url(#paint2001_linear_3150_15389)'
      />
      <path
        d='M301.679 -10.822L300.3 -11.2319L300.323 -11.3398L301.737 -11.027L301.679 -10.822Z'
        fill='url(#paint2002_linear_3150_15389)'
      />
      <path
        d='M383.241 1.14024V-0.013916L383.448 0.363602L383.597 1.53935L383.241 1.14024Z'
        fill='url(#paint2003_linear_3150_15389)'
      />
      <path
        d='M295.091 10.8697L295.286 9.35957L295.999 9.42429L295.896 10.8265L295.091 10.8697Z'
        fill='url(#paint2004_linear_3150_15389)'
      />
      <path
        d='M606.561 631.828L608.055 631.86L608.044 632.065L606.561 631.925V631.828Z'
        fill='url(#paint2005_linear_3150_15389)'
      />
      <path
        d='M682.522 278.483L682.315 276.552L683.454 276.477L683.718 278.343L682.522 278.483Z'
        fill='url(#paint2006_linear_3150_15389)'
      />
      <path
        d='M528.173 2.54249L527.77 1.88451L527.678 0.870575L528.046 1.46384L528.173 2.54249Z'
        fill='url(#paint2007_linear_3150_15389)'
      />
      <path
        d='M576.721 249.964L575.514 247.774L576.123 247.246L577.388 249.392L576.721 249.964Z'
        fill='url(#paint2008_linear_3150_15389)'
      />
      <path
        d='M705.152 556.506L706.67 555.945L706.888 556.603L705.336 557.077L705.152 556.506Z'
        fill='url(#paint2009_linear_3150_15389)'
      />
      <path
        d='M334.624 12.3043L334.601 11.0639L335.187 11.258L335.13 12.4445L334.624 12.3043Z'
        fill='url(#paint2010_linear_3150_15389)'
      />
      <path
        d='M298.253 -35.0701L297.482 -33.9699L297.333 -34.2827L298.092 -35.4908L298.253 -35.0701Z'
        fill='url(#paint2011_linear_3150_15389)'
      />
      <path
        d='M740.419 72.6549L741.028 70.5839L742.213 70.983L741.603 73.2589L740.419 72.6549Z'
        fill='url(#paint2012_linear_3150_15389)'
      />
      <path
        d='M742.845 196.991L744.697 195.427L745.409 196.042L743.616 197.39L742.845 196.991Z'
        fill='url(#paint2013_linear_3150_15389)'
      />
      <path
        d='M682.166 156.455L682.66 155.377L683.569 155.485L682.994 156.563L682.166 156.455Z'
        fill='url(#paint2014_linear_3150_15389)'
      />
      <path
        d='M316.076 19.4234L316.237 18.0535L316.858 18.1398L316.835 19.4018L316.076 19.4234Z'
        fill='url(#paint2015_linear_3150_15389)'
      />
      <path
        d='M700.414 283.467L700.391 281.579L701.461 281.784L701.599 283.553L700.414 283.467Z'
        fill='url(#paint2016_linear_3150_15389)'
      />
      <path
        d='M432.732 205.135L431.329 202.848L431.444 201.845L432.813 203.894L432.732 205.135Z'
        fill='url(#paint2017_linear_3150_15389)'
      />
      <path
        d='M470.586 209.784L469.483 207.422L469.954 206.375L471.058 208.511L470.586 209.784Z'
        fill='url(#paint2018_linear_3150_15389)'
      />
      <path
        d='M320.975 2.24046L320.963 0.881371L321.446 1.07552L321.469 2.34834L320.975 2.24046Z'
        fill='url(#paint2019_linear_3150_15389)'
      />
      <path
        d='M669.091 590.322L670.644 589.987L670.724 590.494L669.16 590.731L669.091 590.322Z'
        fill='url(#paint2020_linear_3150_15389)'
      />
      <path
        d='M757.679 178.082L759.645 175.85L760.381 176.605L758.369 178.676L757.679 178.082Z'
        fill='url(#paint2021_linear_3150_15389)'
      />
      <path
        d='M445.461 172.344L444.955 170.715L445.473 170.014L446.048 171.298L445.461 172.344Z'
        fill='url(#paint2022_linear_3150_15389)'
      />
      <path
        d='M335.923 27.1573L336.015 25.9169L336.579 26.0032L336.59 27.1573H335.923Z'
        fill='url(#paint2023_linear_3150_15389)'
      />
      <path
        d='M324.367 -16.4957L324.217 -17.898L324.493 -17.6175L324.608 -16.28L324.367 -16.4957Z'
        fill='url(#paint2024_linear_3150_15389)'
      />
      <path
        d='M398.419 172.096L397.787 170.273L398.224 169.302L398.833 170.985L398.419 172.096Z'
        fill='url(#paint2025_linear_3150_15389)'
      />
      <path
        d='M758.277 176.13L760.312 174.091L761.163 174.695L759.024 176.648L758.277 176.13Z'
        fill='url(#paint2026_linear_3150_15389)'
      />
      <path
        d='M351.366 4.03103L351.343 2.82294L351.872 3.20046L351.838 4.33305L351.366 4.03103Z'
        fill='url(#paint2027_linear_3150_15389)'
      />
      <path
        d='M382.632 -17.9735L382.563 -19.3218L382.586 -18.9443L382.816 -17.5312L382.632 -17.9735Z'
        fill='url(#paint2028_linear_3150_15389)'
      />
      <path
        d='M368.522 132.552L368.005 131.053L368.2 130.266L368.718 131.625L368.522 132.552Z'
        fill='url(#paint2029_linear_3150_15389)'
      />
      <path
        d='M565.027 157.825L564.946 157.049L565.797 156.919V157.685L565.027 157.825Z'
        fill='url(#paint2030_linear_3150_15389)'
      />
      <path
        d='M451.855 197.412L451.038 195.103L451.59 194.079L452.43 196.042L451.855 197.412Z'
        fill='url(#paint2031_linear_3150_15389)'
      />
      <path
        d='M626.339 186.96L626.638 186.485L626.971 186.55L626.764 187.197L626.339 186.96Z'
        fill='url(#paint2032_linear_3150_15389)'
      />
      <path
        d='M743.777 461.423L744.651 460.053L745.617 460.657L744.57 461.951L743.777 461.423Z'
        fill='url(#paint2033_linear_3150_15389)'
      />
      <path
        d='M743.501 211.003L745.513 209.191L746.111 209.892L744.076 211.564L743.501 211.003Z'
        fill='url(#paint2034_linear_3150_15389)'
      />
      <path
        d='M338.464 -6.5074L338.361 -7.81256L338.775 -7.47818L338.809 -6.24852L338.464 -6.5074Z'
        fill='url(#paint2035_linear_3150_15389)'
      />
      <path
        d='M723.516 254.16L725.01 252.25L725.436 253.124L723.872 254.979L723.516 254.16Z'
        fill='url(#paint2036_linear_3150_15389)'
      />
      <path
        d='M736.981 181.286L738.568 179.851L739.442 180.347L737.866 181.61L736.981 181.286Z'
        fill='url(#paint2037_linear_3150_15389)'
      />
      <path
        d='M306.038 -12.2135L304.715 -12.6449L304.738 -12.742L306.095 -12.4184L306.038 -12.2135Z'
        fill='url(#paint2038_linear_3150_15389)'
      />
      <path
        d='M633.146 152.41L633.284 151.526L633.824 151.418L633.629 152.367L633.146 152.41Z'
        fill='url(#paint2039_linear_3150_15389)'
      />
      <path
        d='M388.025 56.3565L387.933 55.3749L388.105 55.2886L388.3 56.1946L388.025 56.3565Z'
        fill='url(#paint2040_linear_3150_15389)'
      />
      <path
        d='M719.79 539.582L721.273 538.924L721.561 539.646L720.043 540.229L719.79 539.582Z'
        fill='url(#paint2041_linear_3150_15389)'
      />
      <path
        d='M520.032 234.852L518.744 232.414L519.319 231.271L520.63 233.838L520.032 234.852Z'
        fill='url(#paint2042_linear_3150_15389)'
      />
      <path
        d='M383.873 145.755L383.413 144.331L383.218 143.404L383.655 144.72L383.873 145.755Z'
        fill='url(#paint2043_linear_3150_15389)'
      />
      <path
        d='M647.209 606.857L648.738 606.652L648.773 607.062L647.232 607.17L647.209 606.857Z'
        fill='url(#paint2044_linear_3150_15389)'
      />
      <path
        d='M663.457 275.107L663.112 272.971L664.135 272.863L664.618 274.762L663.457 275.107Z'
        fill='url(#paint2045_linear_3150_15389)'
      />
      <path
        d='M363.808 46.3358L363.612 45.2463L363.934 45.1384L364.026 46.1955L363.808 46.3358Z'
        fill='url(#paint2046_linear_3150_15389)'
      />
      <path
        d='M493.78 220.074L492.699 217.626L493.297 216.385L494.389 218.845L493.78 220.074Z'
        fill='url(#paint2047_linear_3150_15389)'
      />
      <path
        d='M374.789 51.0387L374.72 49.9924L375.1 49.96L375.215 50.9416L374.789 51.0387Z'
        fill='url(#paint2048_linear_3150_15389)'
      />
      <path
        d='M471.334 163.111L471.196 161.719L471.943 161.126L472.254 162.205L471.334 163.111Z'
        fill='url(#paint2049_linear_3150_15389)'
      />
      <path
        d='M759.438 120.86L760.519 118.519L761.554 119.242L760.381 121.895L759.438 120.86Z'
        fill='url(#paint2050_linear_3150_15389)'
      />
      <path
        d='M396.269 7.37484L396.235 6.18834L396.269 6.46878L396.488 7.70924L396.269 7.37484Z'
        fill='url(#paint2051_linear_3150_15389)'
      />
      <path
        d='M429.846 197.768L428.662 195.352L428.995 194.294L430.145 196.398L429.846 197.768Z'
        fill='url(#paint2052_linear_3150_15389)'
      />
      <path
        d='M685.523 575.803L687.053 575.361L687.191 575.954L685.638 576.299L685.523 575.803Z'
        fill='url(#paint2053_linear_3150_15389)'
      />
      <path
        d='M654.407 12.1101L654.12 10.5353L655.109 10.0823L655.523 11.7326L654.407 12.1101Z'
        fill='url(#paint2054_linear_3150_15389)'
      />
      <path
        d='M727.333 240.374L729.3 238.972L729.771 239.619L727.793 240.881L727.333 240.374Z'
        fill='url(#paint2055_linear_3150_15389)'
      />
      <path
        d='M395.74 191.673L394.613 189.192L394.889 187.974L395.97 190.293L395.74 191.673Z'
        fill='url(#paint2056_linear_3150_15389)'
      />
      <path
        d='M600.903 634.686L602.341 634.751L602.329 634.956L600.903 634.794V634.686Z'
        fill='url(#paint2057_linear_3150_15389)'
      />
      <path
        d='M548.192 10.902L547.893 10.4059L547.79 9.2517V9.68316L548.192 10.902Z'
        fill='url(#paint2058_linear_3150_15389)'
      />
      <path
        d='M624.315 621.71L625.81 621.635L625.798 621.937L624.303 621.915L624.315 621.71Z'
        fill='url(#paint2059_linear_3150_15389)'
      />
      <path
        d='M762.635 468.973L763.727 467.711L764.613 468.402L763.555 469.674L762.635 468.973Z'
        fill='url(#paint2060_linear_3150_15389)'
      />
      <path
        d='M291.227 -33.2903L290.503 -32.0175L290.342 -32.2872L291.009 -33.6463L291.227 -33.2903Z'
        fill='url(#paint2061_linear_3150_15389)'
      />
      <path
        d='M424.637 206.127L423.2 203.646L423.292 202.525L424.683 204.801L424.637 206.127Z'
        fill='url(#paint2062_linear_3150_15389)'
      />
      <path
        d='M566.165 249.877L564.992 247.58L565.625 246.879L566.832 249.165L566.165 249.877Z'
        fill='url(#paint2063_linear_3150_15389)'
      />
      <path
        d='M742.454 205.415L744.467 203.787L745.133 204.423L743.133 205.879L742.454 205.415Z'
        fill='url(#paint2064_linear_3150_15389)'
      />
      <path
        d='M733.313 521.601L734.75 520.835L735.13 521.622L733.646 522.302L733.313 521.601Z'
        fill='url(#paint2065_linear_3150_15389)'
      />
      <path
        d='M448.003 181.566L447.589 179.603L448.348 178.579L448.796 180.315L448.003 181.566Z'
        fill='url(#paint2066_linear_3150_15389)'
      />
      <path
        d='M745.938 327.562L746.456 325.912L747.663 326.268L747.249 327.853L745.938 327.562Z'
        fill='url(#paint2067_linear_3150_15389)'
      />
      <path
        d='M310.948 -13.551L309.694 -13.9933L309.717 -14.1011L311.005 -13.7559L310.948 -13.551Z'
        fill='url(#paint2068_linear_3150_15389)'
      />
      <path
        d='M635.871 9.11147L635.503 7.54743L636.584 7.14834L637.044 8.72316L635.871 9.11147Z'
        fill='url(#paint2069_linear_3150_15389)'
      />
      <path
        d='M643.323 272.421L642.725 270.21L643.644 270.027L644.334 272.087L643.323 272.421Z'
        fill='url(#paint2070_linear_3150_15389)'
      />
      <path
        d='M437.608 188.125L436.906 185.795L437.504 184.77L438.24 186.658L437.608 188.125Z'
        fill='url(#paint2071_linear_3150_15389)'
      />
      <path
        d='M770.201 346.287L771.017 344.831L772.075 345.468L771.374 346.881L770.201 346.287Z'
        fill='url(#paint2072_linear_3150_15389)'
      />
      <path
        d='M760.036 161.298L761.439 158.623L762.267 159.713L760.784 162.377L760.036 161.298Z'
        fill='url(#paint2073_linear_3150_15389)'
      />
      <path
        d='M664.158 593.752L665.688 593.439L665.757 593.946L664.216 594.161L664.158 593.752Z'
        fill='url(#paint2074_linear_3150_15389)'
      />
      <path
        d='M701.047 560.044L702.553 559.494L702.749 560.162L701.219 560.626L701.047 560.044Z'
        fill='url(#paint2075_linear_3150_15389)'
      />
      <path
        d='M567.096 9.45665L566.372 8.20541L566.993 7.36406L567.844 8.57215L567.096 9.45665Z'
        fill='url(#paint2076_linear_3150_15389)'
      />
      <path
        d='M432.249 145.755L432.019 144.525L432.663 143.878L432.905 145L432.249 145.755Z'
        fill='url(#paint2077_linear_3150_15389)'
      />
      <path
        d='M355.862 37.5232L355.736 36.3474L356.023 36.3259L356.15 37.4261L355.862 37.5232Z'
        fill='url(#paint2078_linear_3150_15389)'
      />
      <path
        d='M736.578 220.678L738.016 218.51L738.556 219.524L737.096 221.52L736.578 220.678Z'
        fill='url(#paint2079_linear_3150_15389)'
      />
      <path
        d='M405.905 12.7897L405.813 11.5169V11.7757L405.963 13.0378L405.905 12.7897Z'
        fill='url(#paint2080_linear_3150_15389)'
      />
      <path
        d='M615.99 5.84316L615.599 4.32227L616.645 3.86922L617.22 5.40092L615.99 5.84316Z'
        fill='url(#paint2081_linear_3150_15389)'
      />
      <path
        d='M407.4 108.099L407.262 106.881L407.308 106.46L407.757 107.344L407.4 108.099Z'
        fill='url(#paint2082_linear_3150_15389)'
      />
      <path
        d='M588.645 5.8108L588.082 4.40855L588.887 3.72901L589.669 5.09888L588.645 5.8108Z'
        fill='url(#paint2083_linear_3150_15389)'
      />
      <path
        d='M783.068 426.507L784.149 425.223L785.138 425.989L784.092 427.273L783.068 426.507Z'
        fill='url(#paint2084_linear_3150_15389)'
      />
      <path
        d='M288.996 -32.751L288.318 -31.3811L288.157 -31.6292L288.766 -33.0638L288.996 -32.751Z'
        fill='url(#paint2085_linear_3150_15389)'
      />
      <path
        d='M498.575 221.692L497.505 219.254L498.115 218.014L499.184 220.484L498.575 221.692Z'
        fill='url(#paint2086_linear_3150_15389)'
      />
      <path
        d='M594.418 637.491L595.798 637.577L595.775 637.782L594.406 637.599L594.418 637.491Z'
        fill='url(#paint2087_linear_3150_15389)'
      />
      <path
        d='M641.517 610.136L643.012 609.953L643.035 610.374L641.54 610.449L641.517 610.136Z'
        fill='url(#paint2088_linear_3150_15389)'
      />
      <path
        d='M450.843 198.447L450.072 195.934L450.751 194.596L451.521 196.948L450.843 198.447Z'
        fill='url(#paint2089_linear_3150_15389)'
      />
      <path
        d='M701.369 169.27L702.3 168.116L703.209 168.45L702.22 169.496L701.369 169.27Z'
        fill='url(#paint2090_linear_3150_15389)'
      />
      <path
        d='M472.001 209.514L471.069 206.979L471.725 205.62L472.645 208.101L472.001 209.514Z'
        fill='url(#paint2091_linear_3150_15389)'
      />
      <path
        d='M655.454 202.794L655.615 201.834L656.454 202.287L656.259 203.161L655.454 202.794Z'
        fill='url(#paint2092_linear_3150_15389)'
      />
      <path
        d='M723.355 247.321L724.711 245.444L725.217 246.285L723.723 248.238L723.355 247.321Z'
        fill='url(#paint2093_linear_3150_15389)'
      />
      <path
        d='M618.094 624.774L619.543 624.72L619.531 625.033L618.094 624.979V624.774Z'
        fill='url(#paint2094_linear_3150_15389)'
      />
      <path
        d='M707.44 260.793L709.142 259.844L709.499 260.437L707.82 261.257L707.44 260.793Z'
        fill='url(#paint2095_linear_3150_15389)'
      />
      <path
        d='M197.557 0.870575L197.603 2.68272L197.155 2.74744L196.913 0.989227L197.557 0.870575Z'
        fill='url(#paint2096_linear_3150_15389)'
      />
      <path
        d='M704.106 237.527L704.796 235.887L705.451 236.772L704.738 238.368L704.106 237.527Z'
        fill='url(#paint2097_linear_3150_15389)'
      />
      <path
        d='M557.518 9.23013L556.724 8.01126L557.334 7.12675L558.116 8.31329L557.518 9.23013Z'
        fill='url(#paint2098_linear_3150_15389)'
      />
      <path
        d='M725.068 56.3133L725.528 54.4257L726.678 54.5659L726.333 56.7555L725.068 56.3133Z'
        fill='url(#paint2099_linear_3150_15389)'
      />
      <path
        d='M715.581 543.098L717.053 542.44L717.329 543.185L715.811 543.745L715.581 543.098Z'
        fill='url(#paint2100_linear_3150_15389)'
      />
      <path
        d='M280.027 -2.31145L279.82 -4.0265L280.073 -4.12357L280.28 -2.53796L280.027 -2.31145Z'
        fill='url(#paint2101_linear_3150_15389)'
      />
      <path
        d='M526.563 234.992L525.517 232.522L526.229 231.303L527.276 233.87L526.563 234.992Z'
        fill='url(#paint2102_linear_3150_15389)'
      />
      <path
        d='M393.82 166.778L393.36 164.707L394.004 163.553L394.452 165.462L393.82 166.778Z'
        fill='url(#paint2103_linear_3150_15389)'
      />
      <path
        d='M631.513 270.857L630.846 268.689L631.674 268.441L632.387 270.609L631.513 270.857Z'
        fill='url(#paint2104_linear_3150_15389)'
      />
      <path
        d='M680.337 579.309L681.855 578.888L681.97 579.481L680.441 579.815L680.337 579.309Z'
        fill='url(#paint2105_linear_3150_15389)'
      />
      <path
        d='M668.126 166.508L668.632 165.548L669.379 165.743L668.77 166.724L668.126 166.508Z'
        fill='url(#paint2106_linear_3150_15389)'
      />
      <path
        d='M723.205 232.619L725.114 231.465L725.677 232.069L723.757 233.094L723.205 232.619Z'
        fill='url(#paint2107_linear_3150_15389)'
      />
      <path
        d='M750.159 190.196L751.619 187.628L752.297 188.793L750.814 191.156L750.159 190.196Z'
        fill='url(#paint2108_linear_3150_15389)'
      />
      <path
        d='M418.589 146.122L418.439 144.623L419.129 143.911L419.405 145.13L418.589 146.122Z'
        fill='url(#paint2109_linear_3150_15389)'
      />
      <path
        d='M304.324 -16.6791L303.692 -15.8162L304.048 -16.4418L304.278 -17.3802L304.324 -16.6791Z'
        fill='url(#paint2110_linear_3150_15389)'
      />
      <path
        d='M385.426 118.379L385.253 116.858L385.725 116.276L386.093 117.451L385.426 118.379Z'
        fill='url(#paint2111_linear_3150_15389)'
      />
      <path
        d='M752.907 105.403L753.769 103.127L754.885 103.85L753.988 106.33L752.907 105.403Z'
        fill='url(#paint2112_linear_3150_15389)'
      />
      <path
        d='M504.094 149.574L504.014 148.506L504.416 148.204L504.773 149.045L504.094 149.574Z'
        fill='url(#paint2113_linear_3150_15389)'
      />
      <path
        d='M563.29 250.32L562.301 247.979L563.083 247.041L564.061 249.5L563.29 250.32Z'
        fill='url(#paint2114_linear_3150_15389)'
      />
      <path
        d='M552.723 8.7879L552.021 7.62295L552.412 6.68451L553.206 7.79552L552.723 8.7879Z'
        fill='url(#paint2115_linear_3150_15389)'
      />
      <path
        d='M698.816 40.7484L699 38.9902L700.207 39.0441L700.184 41.0072L698.816 40.7484Z'
        fill='url(#paint2116_linear_3150_15389)'
      />
      <path
        d='M619.175 161.611L619.301 160.802L619.704 160.845L619.462 161.698L619.175 161.611Z'
        fill='url(#paint2117_linear_3150_15389)'
      />
      <path
        d='M735.992 323.894L736.348 322.201L737.625 322.481L737.211 324.164L735.992 323.894Z'
        fill='url(#paint2118_linear_3150_15389)'
      />
      <path
        d='M720.296 219.05L722.021 217.96L722.653 218.575L720.974 219.481L720.296 219.05Z'
        fill='url(#paint2119_linear_3150_15389)'
      />
      <path
        d='M439.815 132.757L439.712 131.603L440.264 131.139L440.517 132.099L439.815 132.757Z'
        fill='url(#paint2120_linear_3150_15389)'
      />
      <path
        d='M729.024 525.031L730.461 524.276L730.817 525.074L729.346 525.753L729.024 525.031Z'
        fill='url(#paint2121_linear_3150_15389)'
      />
      <path
        d='M720.825 235.628L721.94 233.719L722.515 234.625L721.319 236.61L720.825 235.628Z'
        fill='url(#paint2122_linear_3150_15389)'
      />
      <path
        d='M587.104 640.209L588.427 640.317L588.392 640.522L587.093 640.317L587.104 640.209Z'
        fill='url(#paint2123_linear_3150_15389)'
      />
      <path
        d='M658.099 597.149L659.593 596.858L659.639 597.365L658.145 597.57L658.099 597.149Z'
        fill='url(#paint2124_linear_3150_15389)'
      />
      <path
        d='M682.143 221.412L682.499 220.161L683.27 220.862L682.879 222.016L682.143 221.412Z'
        fill='url(#paint2125_linear_3150_15389)'
      />
      <path
        d='M711.66 196.322L712.994 195.254L713.776 195.772L712.419 196.7L711.66 196.322Z'
        fill='url(#paint2126_linear_3150_15389)'
      />
      <path
        d='M344.731 26.8661L344.674 25.6041L345.145 25.7335L345.203 26.9092L344.731 26.8661Z'
        fill='url(#paint2127_linear_3150_15389)'
      />
      <path
        d='M373.087 149.433L372.559 147.535L372.375 146.607L372.995 148.117L373.087 149.433Z'
        fill='url(#paint2128_linear_3150_15389)'
      />
      <path
        d='M390.508 186.496L389.531 183.864L389.956 182.375L390.865 184.986L390.508 186.496Z'
        fill='url(#paint2129_linear_3150_15389)'
      />
      <path
        d='M571.512 159.217L571.478 158.408L572.133 158.321L572.144 159.12L571.512 159.217Z'
        fill='url(#paint2130_linear_3150_15389)'
      />
      <path
        d='M558.978 9.4135L558.53 8.51823V7.40722L559.013 8.25935L558.978 9.4135Z'
        fill='url(#paint2131_linear_3150_15389)'
      />
      <path
        d='M574.559 161.525L574.525 160.684L575.387 160.641L575.376 161.45L574.559 161.525Z'
        fill='url(#paint2132_linear_3150_15389)'
      />
      <path
        d='M411.965 202.363L410.62 199.601L410.838 198.156L412.138 200.874L411.965 202.363Z'
        fill='url(#paint2133_linear_3150_15389)'
      />
      <path
        d='M695.654 563.571L697.137 563.032L697.321 563.722L695.815 564.164L695.654 563.571Z'
        fill='url(#paint2134_linear_3150_15389)'
      />
      <path
        d='M304.06 5.57349L303.979 4.03103L304.416 4.10653L304.508 5.53036L304.06 5.57349Z'
        fill='url(#paint2135_linear_3150_15389)'
      />
      <path
        d='M610.953 627.772L612.345 627.74L612.333 628.053L610.942 627.977L610.953 627.772Z'
        fill='url(#paint2136_linear_3150_15389)'
      />
      <path
        d='M634.79 613.372L636.251 613.2V613.62L634.802 613.685L634.79 613.372Z'
        fill='url(#paint2137_linear_3150_15389)'
      />
      <path
        d='M452.119 200.27L451.257 197.692L451.947 196.236L452.786 198.76L452.119 200.27Z'
        fill='url(#paint2138_linear_3150_15389)'
      />
      <path
        d='M326.172 16.2953L326.149 14.9146L326.621 15.0549L326.69 16.3277L326.172 16.2953Z'
        fill='url(#paint2139_linear_3150_15389)'
      />
      <path
        d='M288.812 -29.569L288.18 -28.1128L288.03 -28.3285L288.582 -29.8279L288.812 -29.569Z'
        fill='url(#paint2140_linear_3150_15389)'
      />
      <path
        d='M373.697 129.252L373.317 127.515L373.582 126.717L374.088 128.141L373.697 129.252Z'
        fill='url(#paint2141_linear_3150_15389)'
      />
      <path
        d='M623.107 269.789L622.464 267.632L623.245 267.33L623.924 269.52L623.107 269.789Z'
        fill='url(#paint2142_linear_3150_15389)'
      />
      <path
        d='M674.852 28.9587L674.841 27.2328H676.117L676.255 29.0234L674.852 28.9587Z'
        fill='url(#paint2143_linear_3150_15389)'
      />
      <path
        d='M402.421 147.136L402.249 145.388L403.054 144.472L403.295 145.971L402.421 147.136Z'
        fill='url(#paint2144_linear_3150_15389)'
      />
      <path
        d='M453.223 204.412L452.096 201.921L452.556 200.583L453.66 203.042L453.223 204.412Z'
        fill='url(#paint2145_linear_3150_15389)'
      />
      <path
        d='M554.873 155.927L554.942 155.021L555.678 154.891L555.839 155.722L554.873 155.927Z'
        fill='url(#paint2146_linear_3150_15389)'
      />
      <path
        d='M471.483 131.247V130.255L471.989 129.953L472.231 130.794L471.483 131.247Z'
        fill='url(#paint2147_linear_3150_15389)'
      />
      <path
        d='M477.129 210.021L476.29 207.432L477.072 205.922L477.877 208.533L477.129 210.021Z'
        fill='url(#paint2148_linear_3150_15389)'
      />
      <path
        d='M452.602 208.554L451.349 205.987L451.613 204.822L452.844 207.141L452.602 208.554Z'
        fill='url(#paint2149_linear_3150_15389)'
      />
      <path
        d='M737.418 501.247L738.706 500.233L739.269 501.042L737.889 501.969L737.418 501.247Z'
        fill='url(#paint2150_linear_3150_15389)'
      />
      <path
        d='M423.568 -40.129L423.832 -41.693L423.924 -41.7577L423.625 -40.2476L423.568 -40.129Z'
        fill='url(#paint2151_linear_3150_15389)'
      />
      <path
        d='M532.646 236.588L531.668 234.172L532.427 232.996L533.37 235.51L532.646 236.588Z'
        fill='url(#paint2152_linear_3150_15389)'
      />
      <path
        d='M578.986 642.83L580.228 642.96L580.205 643.165L578.975 642.938L578.986 642.83Z'
        fill='url(#paint2153_linear_3150_15389)'
      />
      <path
        d='M673.944 582.782L675.416 582.372L675.519 582.976L674.025 583.289L673.944 582.782Z'
        fill='url(#paint2154_linear_3150_15389)'
      />
      <path
        d='M504.129 222.016L503.243 219.503L504.037 218.111L504.888 220.678L504.129 222.016Z'
        fill='url(#paint2155_linear_3150_15389)'
      />
      <path
        d='M388.588 164.707L388.128 162.463L388.852 161.147L389.278 163.262L388.588 164.707Z'
        fill='url(#paint2156_linear_3150_15389)'
      />
      <path
        d='M426.925 131.064L426.833 129.791L427.719 129.144L427.834 130.32L426.925 131.064Z'
        fill='url(#paint2157_linear_3150_15389)'
      />
      <path
        d='M710.004 546.604L711.465 545.957L711.718 546.712L710.223 547.262L710.004 546.604Z'
        fill='url(#paint2158_linear_3150_15389)'
      />
      <path
        d='M421.072 -67.5052L421.038 -67.4081L421.153 -67.764L421.072 -67.5052Z'
        fill='url(#paint2159_linear_3150_15389)'
      />
      <path
        d='M761.6 152.4L762.991 149.757L763.865 150.792L762.393 153.521L761.6 152.4Z'
        fill='url(#paint2160_linear_3150_15389)'
      />
      <path
        d='M615.541 269.94L614.943 267.751L615.806 267.298L616.415 269.509L615.541 269.94Z'
        fill='url(#paint2161_linear_3150_15389)'
      />
      <path
        d='M782.953 395.398L783.931 394.039L784.989 394.848L783.954 396.153L782.953 395.398Z'
        fill='url(#paint2162_linear_3150_15389)'
      />
      <path
        d='M602.881 630.674H604.215L604.192 630.987L602.869 630.89L602.881 630.674Z'
        fill='url(#paint2163_linear_3150_15389)'
      />
      <path
        d='M650.9 600.504L652.349 600.224L652.395 600.741L650.935 600.925L650.9 600.504Z'
        fill='url(#paint2164_linear_3150_15389)'
      />
      <path
        d='M681.223 280.565L682.66 280.058L682.902 280.641L681.499 281.018L681.223 280.565Z'
        fill='url(#paint2165_linear_3150_15389)'
      />
      <path
        d='M427.006 109.62L427.063 108.38L427.569 108.024L427.891 108.984L427.006 109.62Z'
        fill='url(#paint2166_linear_3150_15389)'
      />
      <path
        d='M666.941 186.679L667.585 185.827L668.218 186.226L667.47 187.024L666.941 186.679Z'
        fill='url(#paint2167_linear_3150_15389)'
      />
      <path
        d='M627.04 616.522L628.431 616.382V616.802L627.04 616.846V616.522Z'
        fill='url(#paint2168_linear_3150_15389)'
      />
      <path
        d='M738.556 221.099L740.212 218.791L740.718 219.859L739.051 221.962L738.556 221.099Z'
        fill='url(#paint2169_linear_3150_15389)'
      />
      <path
        d='M571.305 251.69L570.512 249.489L571.443 248.464L572.144 250.934L571.305 251.69Z'
        fill='url(#paint2170_linear_3150_15389)'
      />
      <path
        d='M723.297 528.461L724.723 527.717L725.057 528.526L723.596 529.194L723.297 528.461Z'
        fill='url(#paint2171_linear_3150_15389)'
      />
      <path
        d='M574.433 176.475L574.467 175.914L574.571 176.065L574.444 176.669L574.433 176.475Z'
        fill='url(#paint2172_linear_3150_15389)'
      />
      <path
        d='M693.044 244.182L694.447 243.47L694.895 244.074L693.492 244.657L693.044 244.182Z'
        fill='url(#paint2173_linear_3150_15389)'
      />
      <path
        d='M444.806 210.863L443.507 208.144L443.725 206.861L444.99 209.396L444.806 210.863Z'
        fill='url(#paint2174_linear_3150_15389)'
      />
      <path
        d='M688.962 567.077L690.41 566.548L690.571 567.238L689.1 567.67L688.962 567.077Z'
        fill='url(#paint2175_linear_3150_15389)'
      />
      <path
        d='M570.098 645.333L571.259 645.484L571.225 645.7L570.075 645.441L570.098 645.333Z'
        fill='url(#paint2176_linear_3150_15389)'
      />
      <path
        d='M388.427 182.559L387.53 179.916L388.036 178.287L388.829 180.995L388.427 182.559Z'
        fill='url(#paint2177_linear_3150_15389)'
      />
      <path
        d='M746.847 92.2108L747.606 89.9457L748.767 90.6252L748.008 93.009L746.847 92.2108Z'
        fill='url(#paint2178_linear_3150_15389)'
      />
      <path
        d='M408.056 198.965L406.837 196.225L407.124 194.596L408.251 197.476L408.056 198.965Z'
        fill='url(#paint2179_linear_3150_15389)'
      />
      <path
        d='M303.738 -28.0265L303.071 -26.6027L303.025 -26.8292L303.554 -28.2962L303.738 -28.0265Z'
        fill='url(#paint2180_linear_3150_15389)'
      />
      <path
        d='M657.639 20.1569L657.501 18.4526L658.685 18.2585L658.926 19.9951L657.639 20.1569Z'
        fill='url(#paint2181_linear_3150_15389)'
      />
      <path
        d='M718.479 226.557L719.537 224.659L720.158 225.586L719.043 227.539L718.479 226.557Z'
        fill='url(#paint2182_linear_3150_15389)'
      />
      <path
        d='M393.383 144.536L393.257 142.605L394.28 141.473L394.406 143.231L393.383 144.536Z'
        fill='url(#paint2183_linear_3150_15389)'
      />
      <path
        d='M363.67 157.523L362.945 155.344L362.888 154.125L363.566 156.143L363.67 157.523Z'
        fill='url(#paint2184_linear_3150_15389)'
      />
      <path
        d='M481.637 211.574L480.751 209.05L481.533 207.54L482.35 210.161L481.637 211.574Z'
        fill='url(#paint2185_linear_3150_15389)'
      />
      <path
        d='M593.912 633.489L595.177 633.5L595.142 633.824L593.9 633.694L593.912 633.489Z'
        fill='url(#paint2186_linear_3150_15389)'
      />
      <path
        d='M666.32 586.212L667.758 585.813L667.838 586.428L666.389 586.719L666.32 586.212Z'
        fill='url(#paint2187_linear_3150_15389)'
      />
      <path
        d='M703.669 234.625L704.462 233.007L705.094 233.849L704.267 235.499L703.669 234.625Z'
        fill='url(#paint2188_linear_3150_15389)'
      />
      <path
        d='M677.543 217.011L678.532 216.299L679.061 216.86L678.026 217.464L677.543 217.011Z'
        fill='url(#paint2189_linear_3150_15389)'
      />
      <path
        d='M465.849 670.487L465.573 670.271L465.607 670.163L465.849 670.487Z'
        fill='url(#paint2190_linear_3150_15389)'
      />
      <path
        d='M464.917 670.649L464.676 670.314L464.71 670.217L464.917 670.649Z'
        fill='url(#paint2191_linear_3150_15389)'
      />
      <path
        d='M466.769 670.336L466.47 670.217L466.493 670.109L466.769 670.336Z'
        fill='url(#paint2192_linear_3150_15389)'
      />
      <path
        d='M463.998 670.789L463.779 670.358L463.814 670.26L463.998 670.789Z'
        fill='url(#paint2193_linear_3150_15389)'
      />
      <path
        d='M467.689 670.163L467.355 670.153L467.378 670.056L467.689 670.163Z'
        fill='url(#paint2194_linear_3150_15389)'
      />
      <path
        d='M463.066 670.929L462.894 670.401L462.928 670.293L463.066 670.929Z'
        fill='url(#paint2195_linear_3150_15389)'
      />
      <path
        d='M468.597 670.002L468.229 670.099L468.264 669.991L468.597 670.002Z'
        fill='url(#paint2196_linear_3150_15389)'
      />
      <path
        d='M462.158 671.059L462.008 670.433L462.043 670.336L462.158 671.059Z'
        fill='url(#paint2197_linear_3150_15389)'
      />
      <path
        d='M469.483 669.829L469.103 670.034L469.126 669.926L469.483 669.829Z'
        fill='url(#paint2198_linear_3150_15389)'
      />
      <path
        d='M461.249 671.188L461.146 670.455L461.18 670.368L461.249 671.188Z'
        fill='url(#paint2199_linear_3150_15389)'
      />
      <path
        d='M470.356 669.646L469.954 669.958L469.977 669.851L470.356 669.646Z'
        fill='url(#paint2200_linear_3150_15389)'
      />
      <path
        d='M460.364 671.307L460.295 670.476L460.329 670.39L460.364 671.307Z'
        fill='url(#paint2201_linear_3150_15389)'
      />
      <path
        d='M471.207 669.473L470.782 669.883L470.805 669.786L471.207 669.473Z'
        fill='url(#paint2202_linear_3150_15389)'
      />
      <path
        d='M703.048 550.077L704.474 549.451L704.703 550.217L703.243 550.757L703.048 550.077Z'
        fill='url(#paint2203_linear_3150_15389)'
      />
      <path d='M459.49 671.415L459.456 670.498L459.49 670.411V671.415Z' fill='url(#paint2204_linear_3150_15389)' />
      <path
        d='M618.255 619.596L619.589 619.467L619.577 619.898L618.255 619.909V619.596Z'
        fill='url(#paint2205_linear_3150_15389)'
      />
      <path
        d='M642.575 603.783L643.966 603.524L644.001 604.042L642.598 604.204L642.575 603.783Z'
        fill='url(#paint2206_linear_3150_15389)'
      />
      <path
        d='M560.462 647.717L561.531 647.889L561.497 648.094L560.439 647.824L560.462 647.717Z'
        fill='url(#paint2207_linear_3150_15389)'
      />
      <path
        d='M472.035 669.29L471.587 669.807L471.61 669.71L472.035 669.29Z'
        fill='url(#paint2208_linear_3150_15389)'
      />
      <path d='M458.639 671.512V670.509L458.674 670.422L458.639 671.512Z' fill='url(#paint2209_linear_3150_15389)' />
      <path
        d='M472.84 669.106L472.369 669.732L472.392 669.635L472.84 669.106Z'
        fill='url(#paint2210_linear_3150_15389)'
      />
      <path
        d='M727.471 324.186L727.885 322.438L728.966 322.816L728.598 324.52L727.471 324.186Z'
        fill='url(#paint2211_linear_3150_15389)'
      />
      <path
        d='M457.823 671.598L457.857 670.509L457.892 670.433L457.823 671.598Z'
        fill='url(#paint2212_linear_3150_15389)'
      />
      <path
        d='M473.611 668.923L473.128 669.646L473.139 669.549L473.611 668.923Z'
        fill='url(#paint2213_linear_3150_15389)'
      />
      <path
        d='M457.018 671.674L457.098 670.509L457.133 670.433L457.018 671.674Z'
        fill='url(#paint2214_linear_3150_15389)'
      />
      <path
        d='M421.808 127.386L421.82 125.962L423.004 125.228V126.534L421.808 127.386Z'
        fill='url(#paint2215_linear_3150_15389)'
      />
      <path
        d='M617.301 272.303L616.783 270.232L617.806 269.649L618.198 271.914L617.301 272.303Z'
        fill='url(#paint2216_linear_3150_15389)'
      />
      <path
        d='M474.335 668.74L473.852 669.559L473.864 669.462L474.335 668.74Z'
        fill='url(#paint2217_linear_3150_15389)'
      />
      <path
        d='M456.259 671.738L456.374 670.509L456.408 670.433L456.259 671.738Z'
        fill='url(#paint2218_linear_3150_15389)'
      />
      <path
        d='M475.036 668.556L474.542 669.473L474.554 669.387L475.036 668.556Z'
        fill='url(#paint2219_linear_3150_15389)'
      />
      <path
        d='M510.488 223.548L509.706 221.067L510.626 219.61L511.338 222.21L510.488 223.548Z'
        fill='url(#paint2220_linear_3150_15389)'
      />
      <path
        d='M455.534 671.792L455.684 670.487L455.718 670.422L455.534 671.792Z'
        fill='url(#paint2221_linear_3150_15389)'
      />
      <path
        d='M475.692 668.384L475.186 669.387L475.197 669.3L475.692 668.384Z'
        fill='url(#paint2222_linear_3150_15389)'
      />
      <path
        d='M454.844 671.835L455.028 670.476L455.063 670.411L454.844 671.835Z'
        fill='url(#paint2223_linear_3150_15389)'
      />
      <path d='M476.313 668.2L475.795 669.29L475.807 669.214L476.313 668.2Z' fill='url(#paint2224_linear_3150_15389)' />
      <path
        d='M454.189 671.868L454.419 670.444L454.454 670.401L454.189 671.868Z'
        fill='url(#paint2225_linear_3150_15389)'
      />
      <path
        d='M382.517 161.18L382.149 158.688L383.023 157.21L383.356 159.508L382.517 161.18Z'
        fill='url(#paint2226_linear_3150_15389)'
      />
      <path d='M476.876 668.028L476.37 669.203V669.128L476.876 668.028Z' fill='url(#paint2227_linear_3150_15389)' />
      <path
        d='M540.902 237.797L540.12 235.488L541.086 234.205L541.741 236.75L540.902 237.797Z'
        fill='url(#paint2228_linear_3150_15389)'
      />
      <path
        d='M407.86 115.941L407.929 114.409L408.757 113.827L408.975 115.035L407.86 115.941Z'
        fill='url(#paint2229_linear_3150_15389)'
      />
      <path
        d='M453.58 671.889L453.844 670.422L453.879 670.368L453.58 671.889Z'
        fill='url(#paint2230_linear_3150_15389)'
      />
      <path d='M477.405 667.855L476.888 669.106V669.031L477.405 667.855Z' fill='url(#paint2231_linear_3150_15389)' />
      <path
        d='M453.028 671.911L453.315 670.379L453.35 670.347L453.028 671.911Z'
        fill='url(#paint2232_linear_3150_15389)'
      />
      <path
        d='M767.947 449.148L769.166 447.983L769.914 448.889L768.683 450L767.947 449.148Z'
        fill='url(#paint2233_linear_3150_15389)'
      />
      <path d='M477.877 667.693L477.371 669.02V668.945L477.877 667.693Z' fill='url(#paint2234_linear_3150_15389)' />
      <path
        d='M452.51 671.911L452.844 670.347L452.867 670.304L452.51 671.911Z'
        fill='url(#paint2235_linear_3150_15389)'
      />
      <path
        d='M615.633 190.12L615.806 189.484L616.151 189.894L615.737 190.498L615.633 190.12Z'
        fill='url(#paint2236_linear_3150_15389)'
      />
      <path
        d='M409.516 59.2796L409.389 58.2117L409.504 58.3412L409.527 59.3443L409.516 59.2796Z'
        fill='url(#paint2237_linear_3150_15389)'
      />
      <path d='M478.302 667.531L477.796 668.923V668.858L478.302 667.531Z' fill='url(#paint2238_linear_3150_15389)' />
      <path
        d='M452.05 671.889L452.407 670.293L452.441 670.271L452.05 671.889Z'
        fill='url(#paint2239_linear_3150_15389)'
      />
      <path
        d='M478.682 667.37L478.176 668.826L478.164 668.772L478.682 667.37Z'
        fill='url(#paint2240_linear_3150_15389)'
      />
      <path
        d='M751.63 186.302L753.263 183.864L753.976 184.824L752.228 187.337L751.63 186.302Z'
        fill='url(#paint2241_linear_3150_15389)'
      />
      <path
        d='M379.101 130.535L378.791 128.637L379.297 127.72L379.699 129.306L379.101 130.535Z'
        fill='url(#paint2242_linear_3150_15389)'
      />
      <path
        d='M451.636 671.868L452.027 670.239L452.05 670.217L451.636 671.868Z'
        fill='url(#paint2243_linear_3150_15389)'
      />
      <path d='M479.004 667.219L478.498 668.729V668.686L479.004 667.219Z' fill='url(#paint2244_linear_3150_15389)' />
      <path
        d='M680.947 570.528L682.361 570.021L682.511 570.722L681.062 571.132L680.947 570.528Z'
        fill='url(#paint2245_linear_3150_15389)'
      />
      <path
        d='M584.069 636.175L585.253 636.207L585.207 636.531L584.046 636.391L584.069 636.175Z'
        fill='url(#paint2246_linear_3150_15389)'
      />
      <path
        d='M436.665 210.388L435.411 207.616L435.676 206.106L436.849 208.899L436.665 210.388Z'
        fill='url(#paint2247_linear_3150_15389)'
      />
      <path
        d='M451.28 671.835L451.705 670.185L451.728 670.174L451.28 671.835Z'
        fill='url(#paint2248_linear_3150_15389)'
      />
      <path
        d='M337.395 -26.538L336.832 -25.2652L336.912 -25.5132L337.36 -26.8508L337.395 -26.538Z'
        fill='url(#paint2249_linear_3150_15389)'
      />
      <path
        d='M481.43 214.681L480.441 212.049L481.05 210.733L482.005 213.225L481.43 214.681Z'
        fill='url(#paint2250_linear_3150_15389)'
      />
      <path
        d='M479.268 667.078L478.774 668.643L478.762 668.589L479.268 667.078Z'
        fill='url(#paint2251_linear_3150_15389)'
      />
      <path
        d='M716.122 531.88L717.513 531.136L717.824 531.966L716.398 532.624L716.122 531.88Z'
        fill='url(#paint2252_linear_3150_15389)'
      />
      <path
        d='M369.684 40.7699L369.592 39.5402L369.661 39.7128L369.776 40.8778L369.684 40.7699Z'
        fill='url(#paint2253_linear_3150_15389)'
      />
      <path
        d='M550.113 649.949L551.09 650.144L551.044 650.349L550.09 650.057L550.113 649.949Z'
        fill='url(#paint2254_linear_3150_15389)'
      />
      <path
        d='M450.969 671.792L451.418 670.12L451.452 670.109L450.969 671.792Z'
        fill='url(#paint2255_linear_3150_15389)'
      />
      <path
        d='M446.393 116.211L446.508 114.981L447.485 114.636L447.623 115.661L446.393 116.211Z'
        fill='url(#paint2256_linear_3150_15389)'
      />
      <path
        d='M479.475 666.949L478.992 668.545L478.981 668.502L479.475 666.949Z'
        fill='url(#paint2257_linear_3150_15389)'
      />
      <path
        d='M577.112 254.828L576.526 252.488L577.514 251.603L578.089 253.901L577.112 254.828Z'
        fill='url(#paint2258_linear_3150_15389)'
      />
      <path d='M450.728 671.738L451.199 670.056H451.222L450.728 671.738Z' fill='url(#paint2259_linear_3150_15389)' />
      <path
        d='M479.625 666.82L479.153 668.459L479.142 668.427L479.625 666.82Z'
        fill='url(#paint2260_linear_3150_15389)'
      />
      <path d='M450.544 671.674L451.038 669.991H451.05L450.544 671.674Z' fill='url(#paint2261_linear_3150_15389)' />
      <path
        d='M737.28 217.701L738.936 215.393L739.488 216.396L737.751 218.704L737.28 217.701Z'
        fill='url(#paint2262_linear_3150_15389)'
      />
      <path
        d='M475.876 120.245L475.933 119.209L476.727 119.026L476.865 119.965L475.876 120.245Z'
        fill='url(#paint2263_linear_3150_15389)'
      />
      <path
        d='M479.717 666.701L479.268 668.373L479.257 668.34L479.717 666.701Z'
        fill='url(#paint2264_linear_3150_15389)'
      />
      <path d='M450.406 671.598L450.923 669.915H450.946L450.406 671.598Z' fill='url(#paint2265_linear_3150_15389)' />
      <path
        d='M643.966 14.1704L643.714 12.4769L644.714 12.1101L645.013 13.8144L643.966 14.1704Z'
        fill='url(#paint2266_linear_3150_15389)'
      />
      <path
        d='M479.763 666.593L479.314 668.276L479.303 668.265L479.763 666.593Z'
        fill='url(#paint2267_linear_3150_15389)'
      />
      <path
        d='M450.337 671.512L450.877 669.829L450.889 669.851L450.337 671.512Z'
        fill='url(#paint2268_linear_3150_15389)'
      />
      <path
        d='M651.096 205.556L651.418 204.574L652.119 204.984L651.878 206.03L651.096 205.556Z'
        fill='url(#paint2269_linear_3150_15389)'
      />
      <path
        d='M657.466 589.567L658.857 589.189L658.926 589.815L657.524 590.084L657.466 589.567Z'
        fill='url(#paint2270_linear_3150_15389)'
      />
      <path
        d='M608.481 622.552L609.746 622.455L609.723 622.875H608.458L608.481 622.552Z'
        fill='url(#paint2271_linear_3150_15389)'
      />
      <path
        d='M479.74 666.496L479.314 668.2L479.303 668.179L479.74 666.496Z'
        fill='url(#paint2272_linear_3150_15389)'
      />
      <path
        d='M450.325 671.415L450.877 669.743L450.889 669.764L450.325 671.415Z'
        fill='url(#paint2273_linear_3150_15389)'
      />
      <path
        d='M452.453 97.507L452.545 96.4607L452.568 96.2342L452.924 97.151L452.453 97.507Z'
        fill='url(#paint2274_linear_3150_15389)'
      />
      <path
        d='M383.057 142.519L382.862 140.405L383.919 139.121L384.057 141.085L383.057 142.519Z'
        fill='url(#paint2275_linear_3150_15389)'
      />
      <path
        d='M450.36 671.307L450.935 669.656L450.946 669.689L450.36 671.307Z'
        fill='url(#paint2276_linear_3150_15389)'
      />
      <path
        d='M479.659 666.399L479.257 668.114L479.234 668.103L479.659 666.399Z'
        fill='url(#paint2277_linear_3150_15389)'
      />
      <path
        d='M366.499 146.758L366.004 144.461L366.556 143.166L367.028 145.27L366.499 146.758Z'
        fill='url(#paint2278_linear_3150_15389)'
      />
      <path
        d='M406.791 194.661L405.71 192.062L406.055 190.357L406.975 193.216L406.791 194.661Z'
        fill='url(#paint2279_linear_3150_15389)'
      />
      <path
        d='M633.123 606.965L634.468 606.728L634.48 607.256L633.135 607.397L633.123 606.965Z'
        fill='url(#paint2280_linear_3150_15389)'
      />
      <path d='M450.463 671.188L451.05 669.57V669.602L450.463 671.188Z' fill='url(#paint2281_linear_3150_15389)' />
      <path d='M479.533 666.323L479.142 668.038H479.119L479.533 666.323Z' fill='url(#paint2282_linear_3150_15389)' />
      <path
        d='M500.368 132.941L500.426 132.067L500.461 131.894L500.667 132.725L500.368 132.941Z'
        fill='url(#paint2283_linear_3150_15389)'
      />
      <path
        d='M450.624 671.069L451.211 669.484L451.222 669.516L450.624 671.069Z'
        fill='url(#paint2284_linear_3150_15389)'
      />
      <path d='M479.337 666.259L478.981 667.963H478.958L479.337 666.259Z' fill='url(#paint2285_linear_3150_15389)' />
      <path
        d='M658.949 214.131L659.306 213.106L659.938 213.602L659.536 214.541L658.949 214.131Z'
        fill='url(#paint2286_linear_3150_15389)'
      />
      <path
        d='M703.105 315.028L703.427 313.119L704.324 313.475L704.186 315.254L703.105 315.028Z'
        fill='url(#paint2287_linear_3150_15389)'
      />
      <path
        d='M407.239 125.056V123.427L408.389 122.596L408.435 124.042L407.239 125.056Z'
        fill='url(#paint2288_linear_3150_15389)'
      />
      <path
        d='M728.081 512.54L729.438 511.688L729.828 512.572L728.449 513.338L728.081 512.54Z'
        fill='url(#paint2289_linear_3150_15389)'
      />
      <path d='M450.843 670.929L451.441 669.387V669.43L450.843 670.929Z' fill='url(#paint2290_linear_3150_15389)' />
      <path
        d='M479.096 666.194L478.751 667.887L478.728 667.898L479.096 666.194Z'
        fill='url(#paint2291_linear_3150_15389)'
      />
      <path
        d='M539.097 652.031L539.971 652.236L539.913 652.452L539.074 652.139L539.097 652.031Z'
        fill='url(#paint2292_linear_3150_15389)'
      />
      <path
        d='M677.072 305.277L677.164 303.238L678.026 303.411L678.084 305.342L677.072 305.277Z'
        fill='url(#paint2293_linear_3150_15389)'
      />
      <path
        d='M451.119 670.789L451.717 669.29L451.728 669.344L451.119 670.789Z'
        fill='url(#paint2294_linear_3150_15389)'
      />
      <path
        d='M478.797 666.151L478.475 667.823L478.452 667.844L478.797 666.151Z'
        fill='url(#paint2295_linear_3150_15389)'
      />
      <path
        d='M356.242 32.4319L356.092 31.0728L356.276 31.418L356.334 32.6584L356.242 32.4319Z'
        fill='url(#paint2296_linear_3150_15389)'
      />
      <path
        d='M771.213 423.357L772.328 422.127L773.156 423.044L771.983 424.231L771.213 423.357Z'
        fill='url(#paint2297_linear_3150_15389)'
      />
      <path
        d='M694.688 553.518L696.079 552.903L696.286 553.68L694.872 554.208L694.688 553.518Z'
        fill='url(#paint2298_linear_3150_15389)'
      />
      <path
        d='M573.386 638.721L574.479 638.775L574.433 639.098L573.363 638.936L573.386 638.721Z'
        fill='url(#paint2299_linear_3150_15389)'
      />
      <path d='M451.452 670.649L452.05 669.193V669.247L451.452 670.649Z' fill='url(#paint2300_linear_3150_15389)' />
      <path
        d='M478.44 666.118L478.141 667.758L478.118 667.78L478.44 666.118Z'
        fill='url(#paint2301_linear_3150_15389)'
      />
      <path
        d='M299.633 -4.28539L298.977 -2.73212L299.092 -3.02336L299.575 -4.63054L299.633 -4.28539Z'
        fill='url(#paint2302_linear_3150_15389)'
      />
      <path
        d='M475.554 218.381L474.473 215.63L474.945 214.325L475.991 216.903L475.554 218.381Z'
        fill='url(#paint2303_linear_3150_15389)'
      />
      <path
        d='M363.463 170.327L362.635 167.878L362.509 166.444L363.233 168.817L363.463 170.327Z'
        fill='url(#paint2304_linear_3150_15389)'
      />
      <path d='M451.832 670.487L452.43 669.085V669.149L451.832 670.487Z' fill='url(#paint2305_linear_3150_15389)' />
      <path
        d='M478.038 666.086L477.762 667.693L477.739 667.736L478.038 666.086Z'
        fill='url(#paint2306_linear_3150_15389)'
      />
      <path
        d='M386.875 48.094V46.9182L386.817 47.1124L386.99 48.2881L386.875 48.094Z'
        fill='url(#paint2307_linear_3150_15389)'
      />
      <path d='M452.269 670.336L452.867 668.988V669.052L452.269 670.336Z' fill='url(#paint2308_linear_3150_15389)' />
      <path
        d='M477.578 666.075L477.336 667.639L477.302 667.682L477.578 666.075Z'
        fill='url(#paint2309_linear_3150_15389)'
      />
      <path
        d='M627.109 10.2764L626.845 8.63688L627.695 8.16227L628.11 9.83418L627.109 10.2764Z'
        fill='url(#paint2310_linear_3150_15389)'
      />
      <path
        d='M622.13 276.768L621.808 274.643L622.877 274.136L623.165 276.283L622.13 276.768Z'
        fill='url(#paint2311_linear_3150_15389)'
      />
      <path
        d='M452.763 670.163L453.35 668.891L453.338 668.966L452.763 670.163Z'
        fill='url(#paint2312_linear_3150_15389)'
      />
      <path
        d='M477.072 666.075L476.853 667.596L476.819 667.639L477.072 666.075Z'
        fill='url(#paint2313_linear_3150_15389)'
      />
      <path
        d='M386.829 175.494L386.116 172.862L386.863 171.039L387.392 173.832L386.829 175.494Z'
        fill='url(#paint2314_linear_3150_15389)'
      />
      <path
        d='M776.617 400.188L777.652 398.872L778.584 399.756L777.491 401.029L776.617 400.188Z'
        fill='url(#paint2315_linear_3150_15389)'
      />
      <path
        d='M372.984 160.274L372.49 157.674L373.329 156.013L373.72 158.548L372.984 160.274Z'
        fill='url(#paint2316_linear_3150_15389)'
      />
      <path
        d='M453.292 670.002L453.879 668.793L453.867 668.869L453.292 670.002Z'
        fill='url(#paint2317_linear_3150_15389)'
      />
      <path
        d='M403.778 55.4612L403.686 54.2639L403.812 54.5659L403.835 55.6553L403.778 55.4612Z'
        fill='url(#paint2318_linear_3150_15389)'
      />
      <path
        d='M476.52 666.086L476.324 667.553L476.301 667.607L476.52 666.086Z'
        fill='url(#paint2319_linear_3150_15389)'
      />
      <path
        d='M512.511 226.503L511.764 223.882L512.672 222.534L513.397 225.068L512.511 226.503Z'
        fill='url(#paint2320_linear_3150_15389)'
      />
      <path
        d='M453.879 669.829L454.454 668.686L454.442 668.772L453.879 669.829Z'
        fill='url(#paint2321_linear_3150_15389)'
      />
      <path
        d='M475.922 666.108L475.761 667.51L475.726 667.575L475.922 666.108Z'
        fill='url(#paint2322_linear_3150_15389)'
      />
      <path
        d='M643.058 243.751L643.771 243.416L644.058 244.042L643.277 244.301L643.058 243.751Z'
        fill='url(#paint2323_linear_3150_15389)'
      />
      <path
        d='M454.5 669.646L455.063 668.589L455.051 668.675L454.5 669.646Z'
        fill='url(#paint2324_linear_3150_15389)'
      />
      <path
        d='M475.278 666.151L475.151 667.478L475.117 667.542L475.278 666.151Z'
        fill='url(#paint2325_linear_3150_15389)'
      />
      <path
        d='M605.422 7.53665L605.066 5.97261L605.79 5.36855L606.273 6.95417L605.422 7.53665Z'
        fill='url(#paint2326_linear_3150_15389)'
      />
      <path
        d='M738.867 492.153L740.166 491.193L740.649 492.121L739.315 493.006L738.867 492.153Z'
        fill='url(#paint2327_linear_3150_15389)'
      />
      <path
        d='M667.459 304.144L667.39 302.16L668.413 302.117L668.39 304.177L667.459 304.144Z'
        fill='url(#paint2328_linear_3150_15389)'
      />
      <path
        d='M455.166 669.462L455.718 668.491L455.695 668.578L455.166 669.462Z'
        fill='url(#paint2329_linear_3150_15389)'
      />
      <path
        d='M474.6 666.194L474.496 667.456L474.473 667.521L474.6 666.194Z'
        fill='url(#paint2330_linear_3150_15389)'
      />
      <path
        d='M527.46 653.94L528.219 654.156L528.173 654.372L527.437 654.038L527.46 653.94Z'
        fill='url(#paint2331_linear_3150_15389)'
      />
      <path
        d='M671.61 573.926L672.99 573.43L673.105 574.142L671.713 574.541L671.61 573.926Z'
        fill='url(#paint2332_linear_3150_15389)'
      />
      <path
        d='M597.729 625.388L598.914 625.302L598.879 625.734L597.706 625.712L597.729 625.388Z'
        fill='url(#paint2333_linear_3150_15389)'
      />
      <path
        d='M455.879 669.29L456.408 668.394L456.385 668.491L455.879 669.29Z'
        fill='url(#paint2334_linear_3150_15389)'
      />
      <path
        d='M473.875 666.248L473.818 667.434L473.783 667.51L473.875 666.248Z'
        fill='url(#paint2335_linear_3150_15389)'
      />
      <path
        d='M312.028 8.31329L311.948 6.72766L311.971 7.10518L312.166 8.58295L312.028 8.31329Z'
        fill='url(#paint2336_linear_3150_15389)'
      />
      <path
        d='M456.615 669.106L457.133 668.308L457.11 668.405L456.615 669.106Z'
        fill='url(#paint2337_linear_3150_15389)'
      />
      <path
        d='M473.128 666.313L473.093 667.424L473.059 667.499L473.128 666.313Z'
        fill='url(#paint2338_linear_3150_15389)'
      />
      <path
        d='M714.96 221.671L716.087 219.664L716.674 220.678L715.535 222.587L714.96 221.671Z'
        fill='url(#paint2339_linear_3150_15389)'
      />
      <path
        d='M457.397 668.923L457.88 668.211L457.869 668.308L457.397 668.923Z'
        fill='url(#paint2340_linear_3150_15389)'
      />
      <path
        d='M472.346 666.399L472.334 667.413L472.3 667.499L472.346 666.399Z'
        fill='url(#paint2341_linear_3150_15389)'
      />
      <path
        d='M434.526 208.856L433.388 206.267L433.641 204.693L434.629 207.508L434.526 208.856Z'
        fill='url(#paint2342_linear_3150_15389)'
      />
      <path
        d='M458.202 668.74L458.674 668.125L458.651 668.233L458.202 668.74Z'
        fill='url(#paint2343_linear_3150_15389)'
      />
      <path d='M471.529 666.485L471.564 667.413L471.529 667.499V666.485Z' fill='url(#paint2344_linear_3150_15389)' />
      <path
        d='M682.269 231.627L682.902 230.321L683.477 231.044L682.821 232.241L682.269 231.627Z'
        fill='url(#paint2345_linear_3150_15389)'
      />
      <path
        d='M459.03 668.556L459.479 668.038L459.456 668.146L459.03 668.556Z'
        fill='url(#paint2346_linear_3150_15389)'
      />
      <path
        d='M470.69 666.582L470.747 667.424L470.713 667.51L470.69 666.582Z'
        fill='url(#paint2347_linear_3150_15389)'
      />
      <path
        d='M459.881 668.373L460.306 667.963L460.283 668.071L459.881 668.373Z'
        fill='url(#paint2348_linear_3150_15389)'
      />
      <path
        d='M469.828 666.69L469.92 667.434L469.885 667.531L469.828 666.69Z'
        fill='url(#paint2349_linear_3150_15389)'
      />
      <path
        d='M460.766 668.189L461.157 667.887L461.134 667.995L460.766 668.189Z'
        fill='url(#paint2350_linear_3150_15389)'
      />
      <path
        d='M468.954 666.809L469.08 667.445L469.046 667.553L468.954 666.809Z'
        fill='url(#paint2351_linear_3150_15389)'
      />
      <path
        d='M707.452 535.256L708.82 534.512L709.096 535.364L707.705 536.011L707.452 535.256Z'
        fill='url(#paint2352_linear_3150_15389)'
      />
      <path
        d='M468.057 666.938L468.218 667.478L468.183 667.575L468.057 666.938Z'
        fill='url(#paint2353_linear_3150_15389)'
      />
      <path
        d='M461.652 668.017L462.02 667.823L461.997 667.92L461.652 668.017Z'
        fill='url(#paint2354_linear_3150_15389)'
      />
      <path
        d='M574.352 258.83L573.8 256.392L574.824 255.465L575.364 257.838L574.352 258.83Z'
        fill='url(#paint2355_linear_3150_15389)'
      />
      <path
        d='M338.085 21.4621L338.004 19.9843L338.119 20.4373L338.234 21.818L338.085 21.4621Z'
        fill='url(#paint2356_linear_3150_15389)'
      />
      <path
        d='M647.393 592.835L648.727 592.468L648.773 593.104L647.439 593.363L647.393 592.835Z'
        fill='url(#paint2357_linear_3150_15389)'
      />
      <path
        d='M622.579 610.05L623.855 609.824L623.843 610.363L622.579 610.482V610.05Z'
        fill='url(#paint2358_linear_3150_15389)'
      />
      <path
        d='M467.148 667.068L467.344 667.51L467.309 667.607L467.148 667.068Z'
        fill='url(#paint2359_linear_3150_15389)'
      />
      <path
        d='M462.56 667.844L462.894 667.747L462.871 667.855L462.56 667.844Z'
        fill='url(#paint2360_linear_3150_15389)'
      />
      <path
        d='M586.898 6.73844L586.576 5.34699L586.817 4.47328L587.3 5.87552L586.898 6.73844Z'
        fill='url(#paint2361_linear_3150_15389)'
      />
      <path
        d='M561.91 641.115L562.899 641.191L562.842 641.514L561.864 641.331L561.91 641.115Z'
        fill='url(#paint2362_linear_3150_15389)'
      />
      <path
        d='M466.24 667.208L466.458 667.542L466.424 667.65L466.24 667.208Z'
        fill='url(#paint2363_linear_3150_15389)'
      />
      <path
        d='M463.469 667.682L463.779 667.693L463.756 667.801L463.469 667.682Z'
        fill='url(#paint2364_linear_3150_15389)'
      />
      <path
        d='M465.32 667.359L465.561 667.585L465.538 667.693L465.32 667.359Z'
        fill='url(#paint2365_linear_3150_15389)'
      />
      <path
        d='M464.389 667.521L464.676 667.639L464.642 667.747L464.389 667.521Z'
        fill='url(#paint2366_linear_3150_15389)'
      />
      <path
        d='M362.669 162.906L361.922 160.252L362.371 158.645L363.014 161.223L362.669 162.906Z'
        fill='url(#paint2367_linear_3150_15389)'
      />
      <path
        d='M482.856 111.68L483.074 110.677L483.293 110.569L483.638 111.519L482.856 111.68Z'
        fill='url(#paint2368_linear_3150_15389)'
      />
      <path
        d='M550.423 240.666L549.825 238.357L550.952 237.149L551.424 239.587L550.423 240.666Z'
        fill='url(#paint2369_linear_3150_15389)'
      />
      <path
        d='M748.376 470.818L749.618 469.761L750.205 470.71L748.917 471.702L748.376 470.818Z'
        fill='url(#paint2370_linear_3150_15389)'
      />
      <path
        d='M515.26 655.656L515.915 655.893L515.857 656.109L515.237 655.763L515.26 655.656Z'
        fill='url(#paint2371_linear_3150_15389)'
      />
      <path
        d='M741.063 82.7834L741.856 80.4319L742.96 81.0252L742.144 83.3659L741.063 82.7834Z'
        fill='url(#paint2372_linear_3150_15389)'
      />
      <path
        d='M758.978 144.31L760.542 141.721L761.393 142.595L759.76 145.248L758.978 144.31Z'
        fill='url(#paint2373_linear_3150_15389)'
      />
      <path
        d='M467.838 221.218L466.723 218.381L467.114 217L468.183 219.718L467.838 221.218Z'
        fill='url(#paint2374_linear_3150_15389)'
      />
      <path
        d='M301.645 4.91552L301.185 6.5443L301.254 6.30699L301.668 4.63508L301.645 4.91552Z'
        fill='url(#paint2375_linear_3150_15389)'
      />
      <path
        d='M684.925 556.905L686.271 556.29L686.455 557.077L685.086 557.595L684.925 556.905Z'
        fill='url(#paint2376_linear_3150_15389)'
      />
      <path
        d='M504.957 127.31L505.095 126.361L505.417 126.318L505.601 127.256L504.957 127.31Z'
        fill='url(#paint2377_linear_3150_15389)'
      />
      <path
        d='M586.035 628.085L587.139 628.02L587.093 628.452L586.001 628.409L586.035 628.085Z'
        fill='url(#paint2378_linear_3150_15389)'
      />
      <path
        d='M756.552 448.651L757.736 447.497L758.415 448.446L757.196 449.536L756.552 448.651Z'
        fill='url(#paint2379_linear_3150_15389)'
      />
      <path
        d='M699.655 236.47L700.633 234.679L701.185 235.65L700.184 237.236L699.655 236.47Z'
        fill='url(#paint2380_linear_3150_15389)'
      />
      <path
        d='M719.158 515.797L720.48 514.956L720.836 515.851L719.479 516.617L719.158 515.797Z'
        fill='url(#paint2381_linear_3150_15389)'
      />
      <path
        d='M746.536 178.503L748.135 176.054L748.836 177.014L747.169 179.495L746.536 178.503Z'
        fill='url(#paint2382_linear_3150_15389)'
      />
      <path
        d='M732.577 210.798L734.164 208.511L734.75 209.493L733.071 211.833L732.577 210.798Z'
        fill='url(#paint2383_linear_3150_15389)'
      />
      <path
        d='M549.664 643.337L550.561 643.434L550.492 643.758L549.63 643.564L549.664 643.337Z'
        fill='url(#paint2384_linear_3150_15389)'
      />
      <path
        d='M559.001 152.486L559.116 151.558L559.415 151.537L559.484 152.464L559.001 152.486Z'
        fill='url(#paint2385_linear_3150_15389)'
      />
      <path
        d='M502.565 657.176L503.094 657.424L503.036 657.64L502.53 657.284L502.565 657.176Z'
        fill='url(#paint2386_linear_3150_15389)'
      />
      <path
        d='M508.947 230.278L508.119 227.614L508.912 226.266L509.694 228.887L508.947 230.278Z'
        fill='url(#paint2387_linear_3150_15389)'
      />
      <path
        d='M660.973 577.227L662.296 576.741L662.388 577.464L661.054 577.852L660.973 577.227Z'
        fill='url(#paint2388_linear_3150_15389)'
      />
      <path
        d='M763.336 425.752L764.452 424.511L765.234 425.45L764.084 426.647L763.336 425.752Z'
        fill='url(#paint2389_linear_3150_15389)'
      />
      <path
        d='M610.965 613.006L612.161 612.79L612.138 613.34L610.953 613.437L610.965 613.006Z'
        fill='url(#paint2390_linear_3150_15389)'
      />
      <path
        d='M489.422 658.492L489.824 658.751L489.767 658.967L489.399 658.6L489.422 658.492Z'
        fill='url(#paint2391_linear_3150_15389)'
      />
      <path
        d='M636.136 595.995L637.401 595.65L637.424 596.286L636.159 596.524L636.136 595.995Z'
        fill='url(#paint2392_linear_3150_15389)'
      />
      <path
        d='M611.724 179.679L612.08 178.794L612.494 178.967L612.46 180.002L611.724 179.679Z'
        fill='url(#paint2393_linear_3150_15389)'
      />
      <path
        d='M729.702 495.26L730.978 494.3L731.427 495.238L730.116 496.123L729.702 495.26Z'
        fill='url(#paint2394_linear_3150_15389)'
      />
      <path
        d='M768.683 402.269L769.707 400.932L770.592 401.849L769.523 403.132L768.683 402.269Z'
        fill='url(#paint2395_linear_3150_15389)'
      />
      <path
        d='M536.739 645.376L537.51 645.484L537.441 645.807L536.693 645.602L536.739 645.376Z'
        fill='url(#paint2396_linear_3150_15389)'
      />
      <path
        d='M301.645 17.0504L301.243 18.787V18.6576L301.725 16.8886L301.645 17.0504Z'
        fill='url(#paint2397_linear_3150_15389)'
      />
      <path
        d='M697.31 538.568L698.621 537.845L698.874 538.697L697.528 539.344L697.31 538.568Z'
        fill='url(#paint2398_linear_3150_15389)'
      />
      <path
        d='M633.307 284.707L632.997 282.787L634.342 282.259L634.284 284.437L633.307 284.707Z'
        fill='url(#paint2399_linear_3150_15389)'
      />
      <path
        d='M573.455 630.609L574.456 630.566L574.398 630.997L573.409 630.944L573.455 630.609Z'
        fill='url(#paint2400_linear_3150_15389)'
      />
      <path
        d='M475.922 659.593L476.198 659.862L476.129 660.078L475.887 659.7L475.922 659.593Z'
        fill='url(#paint2401_linear_3150_15389)'
      />
      <path
        d='M383.264 171.891L382.574 169.259L383.31 167.447L383.804 170.187L383.264 171.891Z'
        fill='url(#paint2402_linear_3150_15389)'
      />
      <path
        d='M670.31 311.091L670.333 309.192L671.38 309.225L671.184 311.231L670.31 311.091Z'
        fill='url(#paint2403_linear_3150_15389)'
      />
      <path
        d='M402.617 188.168L401.777 185.514L402.341 183.734L402.985 186.55L402.617 188.168Z'
        fill='url(#paint2404_linear_3150_15389)'
      />
      <path
        d='M462.123 660.466L462.273 660.736L462.204 660.952L462.089 660.574L462.123 660.466Z'
        fill='url(#paint2405_linear_3150_15389)'
      />
      <path
        d='M435.089 204.455L434.158 202.19L434.411 200.637L435.078 203.334L435.089 204.455Z'
        fill='url(#paint2406_linear_3150_15389)'
      />
      <path
        d='M739.028 473.719L740.235 472.662L740.787 473.622L739.534 474.615L739.028 473.719Z'
        fill='url(#paint2407_linear_3150_15389)'
      />
      <path
        d='M448.118 661.113L448.129 661.383L448.06 661.599L448.083 661.221L448.118 661.113Z'
        fill='url(#paint2408_linear_3150_15389)'
      />
      <path
        d='M523.171 647.22L523.826 647.339L523.757 647.663L523.113 647.436L523.171 647.22Z'
        fill='url(#paint2409_linear_3150_15389)'
      />
      <path
        d='M673.76 560.195L675.059 559.591L675.209 560.4L673.898 560.907L673.76 560.195Z'
        fill='url(#paint2410_linear_3150_15389)'
      />
      <path
        d='M598.316 615.799L599.42 615.616L599.385 616.155L598.293 616.242L598.316 615.799Z'
        fill='url(#paint2411_linear_3150_15389)'
      />
      <path
        d='M476.83 218.262L475.818 216.331L476.221 214.778L476.727 217.604L476.83 218.262Z'
        fill='url(#paint2412_linear_3150_15389)'
      />
      <path
        d='M708.659 519.012L709.947 518.171L710.269 519.087L708.958 519.842L708.659 519.012Z'
        fill='url(#paint2413_linear_3150_15389)'
      />
      <path
        d='M649.049 580.419L650.302 579.956L650.371 580.678L649.106 581.056L649.049 580.419Z'
        fill='url(#paint2414_linear_3150_15389)'
      />
      <path
        d='M623.705 599.026L624.89 598.692L624.901 599.339L623.705 599.566V599.026Z'
        fill='url(#paint2415_linear_3150_15389)'
      />
      <path
        d='M560.036 632.961L560.933 632.928L560.864 633.371L559.979 633.295L560.036 632.961Z'
        fill='url(#paint2416_linear_3150_15389)'
      />
      <path
        d='M747.042 451.305L748.192 450.14L748.836 451.111L747.64 452.222L747.042 451.305Z'
        fill='url(#paint2417_linear_3150_15389)'
      />
      <path
        d='M707.429 216.601L708.579 214.681L709.165 215.576L707.981 217.593L707.429 216.601Z'
        fill='url(#paint2418_linear_3150_15389)'
      />
      <path
        d='M725.597 201.36L727.046 199.094L727.655 200.065L726.149 202.427L725.597 201.36Z'
        fill='url(#paint2419_linear_3150_15389)'
      />
      <path
        d='M509.027 648.849L509.568 648.968L509.487 649.302L508.981 649.065L509.027 648.849Z'
        fill='url(#paint2420_linear_3150_15389)'
      />
      <path
        d='M749.584 135.023L751.032 132.498L751.883 133.275L750.366 135.939L749.584 135.023Z'
        fill='url(#paint2421_linear_3150_15389)'
      />
      <path
        d='M564.509 245.142L563.934 243.103L565.268 241.96L565.441 244.387L564.509 245.142Z'
        fill='url(#paint2422_linear_3150_15389)'
      />
      <path
        d='M666.424 225.791L667.102 224.648L667.574 225.187L666.941 226.503L666.424 225.791Z'
        fill='url(#paint2423_linear_3150_15389)'
      />
      <path
        d='M753.7 428.135L754.781 426.873L755.517 427.833L754.39 429.042L753.7 428.135Z'
        fill='url(#paint2424_linear_3150_15389)'
      />
      <path
        d='M739.488 171.977L741.051 169.475L741.741 170.467L740.143 172.948L739.488 171.977Z'
        fill='url(#paint2425_linear_3150_15389)'
      />
      <path
        d='M685.673 541.815L686.938 541.092L687.156 541.966L685.857 542.591L685.673 541.815Z'
        fill='url(#paint2426_linear_3150_15389)'
      />
      <path
        d='M584.678 618.442L585.69 618.259L585.633 618.809L584.644 618.873L584.678 618.442Z'
        fill='url(#paint2427_linear_3150_15389)'
      />
      <path
        d='M729.15 74.8985L729.944 72.5794L730.955 73L730.173 75.4162L729.15 74.8985Z'
        fill='url(#paint2428_linear_3150_15389)'
      />
      <path
        d='M494.412 650.241L494.815 650.381L494.734 650.705L494.355 650.467L494.412 650.241Z'
        fill='url(#paint2429_linear_3150_15389)'
      />
      <path
        d='M718.916 498.312L720.146 497.353L720.56 498.312L719.284 499.197L718.916 498.312Z'
        fill='url(#paint2430_linear_3150_15389)'
      />
      <path
        d='M545.835 635.118L546.617 635.096L546.548 635.539L545.778 635.442L545.835 635.118Z'
        fill='url(#paint2431_linear_3150_15389)'
      />
      <path
        d='M482.085 105.068L482.154 104.033L482.143 103.968L482.269 105.036L482.085 105.068Z'
        fill='url(#paint2432_linear_3150_15389)'
      />
      <path
        d='M758.944 404.33L759.944 402.992L760.784 403.92L759.726 405.214L758.944 404.33Z'
        fill='url(#paint2433_linear_3150_15389)'
      />
      <path
        d='M593.44 264.741L592.727 262.951L594.027 262.045L593.958 264.579L593.44 264.741Z'
        fill='url(#paint2434_linear_3150_15389)'
      />
      <path
        d='M479.372 651.406L479.648 651.557L479.556 651.88L479.303 651.621L479.372 651.406Z'
        fill='url(#paint2435_linear_3150_15389)'
      />
      <path
        d='M661.215 563.388L662.457 562.794L662.572 563.603L661.318 564.099L661.215 563.388Z'
        fill='url(#paint2436_linear_3150_15389)'
      />
      <path
        d='M610.148 601.906L611.241 601.583L611.229 602.23L610.137 602.446L610.148 601.906Z'
        fill='url(#paint2437_linear_3150_15389)'
      />
      <path
        d='M387.783 168.223L386.909 166.325L387.346 164.578L387.622 167.242L387.783 168.223Z'
        fill='url(#paint2438_linear_3150_15389)'
      />
      <path
        d='M635.848 583.494L637.033 583.03L637.067 583.774L635.883 584.13L635.848 583.494Z'
        fill='url(#paint2439_linear_3150_15389)'
      />
      <path
        d='M522.768 229.663L521.825 227.841L522.653 226.503L522.929 229.232L522.768 229.663Z'
        fill='url(#paint2440_linear_3150_15389)'
      />
      <path
        d='M727.977 476.578L729.15 475.51L729.656 476.491L728.437 477.495L727.977 476.578Z'
        fill='url(#paint2441_linear_3150_15389)'
      />
      <path
        d='M464.009 652.323L464.159 652.473L464.055 652.797L463.94 652.538L464.009 652.323Z'
        fill='url(#paint2442_linear_3150_15389)'
      />
      <path
        d='M530.933 637.049H531.599L531.507 637.491L530.864 637.383L530.933 637.049Z'
        fill='url(#paint2443_linear_3150_15389)'
      />
      <path
        d='M696.608 522.151L697.839 521.309L698.126 522.237L696.861 522.992L696.608 522.151Z'
        fill='url(#paint2444_linear_3150_15389)'
      />
      <path
        d='M541.879 149.8L542.144 148.84L542.362 149.002L542.408 150.081L541.879 149.8Z'
        fill='url(#paint2445_linear_3150_15389)'
      />
      <path
        d='M570.121 620.88L571.018 620.729L570.96 621.279L570.063 621.322L570.121 620.88Z'
        fill='url(#paint2446_linear_3150_15389)'
      />
      <path
        d='M448.405 652.991L448.417 653.142L448.313 653.466L448.336 653.207L448.405 652.991Z'
        fill='url(#paint2447_linear_3150_15389)'
      />
      <path
        d='M688.594 329.708L688.697 327.961L689.973 328.166L689.41 330.032L688.594 329.708Z'
        fill='url(#paint2448_linear_3150_15389)'
      />
      <path
        d='M714.248 65.223L714.891 62.9794L715.892 63.3353L715.305 65.7299L714.248 65.223Z'
        fill='url(#paint2449_linear_3150_15389)'
      />
      <path
        d='M739.499 125.444L740.775 122.898L741.638 123.718L740.327 126.329L739.499 125.444Z'
        fill='url(#paint2450_linear_3150_15389)'
      />
      <path
        d='M735.773 453.926L736.9 452.761L737.487 453.743L736.337 454.854L735.773 453.926Z'
        fill='url(#paint2451_linear_3150_15389)'
      />
      <path
        d='M515.386 638.764H515.926L515.835 639.206L515.317 639.098L515.386 638.764Z'
        fill='url(#paint2452_linear_3150_15389)'
      />
      <path
        d='M672.564 544.943L673.771 544.231L673.956 545.115L672.725 545.741L672.564 544.943Z'
        fill='url(#paint2453_linear_3150_15389)'
      />
      <path
        d='M595.51 604.603L596.511 604.301L596.476 604.959L595.476 605.153L595.51 604.603Z'
        fill='url(#paint2454_linear_3150_15389)'
      />
      <path
        d='M706.474 501.3L707.67 500.34L708.038 501.322L706.819 502.196L706.474 501.3Z'
        fill='url(#paint2455_linear_3150_15389)'
      />
      <path
        d='M554.701 623.123L555.483 622.972L555.402 623.533L554.632 623.566L554.701 623.123Z'
        fill='url(#paint2456_linear_3150_15389)'
      />
      <path
        d='M742.259 430.476L743.317 429.214L743.995 430.185L742.903 431.404L742.259 430.476Z'
        fill='url(#paint2457_linear_3150_15389)'
      />
      <path
        d='M647.313 566.451L648.474 565.869L648.566 566.688L647.393 567.174L647.313 566.451Z'
        fill='url(#paint2458_linear_3150_15389)'
      />
      <path
        d='M621.44 586.406L622.533 585.964L622.544 586.708L621.452 587.053L621.44 586.406Z'
        fill='url(#paint2459_linear_3150_15389)'
      />
      <path
        d='M731.703 166.357L733.221 163.769L733.899 164.793L732.347 167.328L731.703 166.357Z'
        fill='url(#paint2460_linear_3150_15389)'
      />
      <path
        d='M499.299 640.231L499.713 640.242L499.61 640.684L499.219 640.565L499.299 640.231Z'
        fill='url(#paint2461_linear_3150_15389)'
      />
      <path
        d='M692.446 52.4086L692.94 50.2836L693.872 50.4778L693.527 52.7969L692.446 52.4086Z'
        fill='url(#paint2462_linear_3150_15389)'
      />
      <path
        d='M514.765 131.981L514.938 130.945L515.041 131.064L515.076 132.186L514.765 131.981Z'
        fill='url(#paint2463_linear_3150_15389)'
      />
      <path
        d='M747.364 406.368L748.342 405.009L749.124 405.958L748.1 407.274L747.364 406.368Z'
        fill='url(#paint2464_linear_3150_15389)'
      />
      <path
        d='M415.093 180.369L414.104 178.762L414.817 176.95L414.805 179.754L415.093 180.369Z'
        fill='url(#paint2465_linear_3150_15389)'
      />
      <path
        d='M482.764 641.439L483.04 641.46L482.925 641.903L482.672 641.773L482.764 641.439Z'
        fill='url(#paint2466_linear_3150_15389)'
      />
      <path
        d='M715.225 479.371L716.363 478.304L716.812 479.307L715.639 480.31L715.225 479.371Z'
        fill='url(#paint2467_linear_3150_15389)'
      />
      <path
        d='M682.994 525.193L684.167 524.351L684.408 525.3L683.212 526.045L682.994 525.193Z'
        fill='url(#paint2468_linear_3150_15389)'
      />
      <path
        d='M579.849 607.116L580.757 606.836L580.7 607.483L579.803 607.666L579.849 607.116Z'
        fill='url(#paint2469_linear_3150_15389)'
      />
      <path
        d='M538.487 625.14L539.166 625L539.062 625.561L538.407 625.583L538.487 625.14Z'
        fill='url(#paint2470_linear_3150_15389)'
      />
      <path
        d='M465.86 642.399L465.998 642.42L465.872 642.863L465.757 642.722L465.86 642.399Z'
        fill='url(#paint2471_linear_3150_15389)'
      />
      <path
        d='M712.028 193.82L713.443 191.598L714.018 192.536L712.557 194.812L712.028 193.82Z'
        fill='url(#paint2472_linear_3150_15389)'
      />
      <path
        d='M496.332 116.448L496.459 115.359L496.378 115.402L496.436 116.567L496.332 116.448Z'
        fill='url(#paint2473_linear_3150_15389)'
      />
      <path
        d='M728.092 116.319L729.242 113.784L730.104 114.582L728.943 117.203L728.092 116.319Z'
        fill='url(#paint2474_linear_3150_15389)'
      />
      <path
        d='M448.693 643.078L448.704 643.1L448.566 643.542L448.589 643.402L448.693 643.078Z'
        fill='url(#paint2475_linear_3150_15389)'
      />
      <path
        d='M658.018 547.963L659.156 547.251L659.294 548.157L658.145 548.772L658.018 547.963Z'
        fill='url(#paint2476_linear_3150_15389)'
      />
      <path
        d='M605.848 589.146L606.848 588.714L606.837 589.469L605.836 589.793L605.848 589.146Z'
        fill='url(#paint2477_linear_3150_15389)'
      />
      <path
        d='M632.1 569.363L633.18 568.792L633.238 569.622L632.146 570.097L632.1 569.363Z'
        fill='url(#paint2478_linear_3150_15389)'
      />
      <path
        d='M722.757 456.493L723.838 455.317L724.367 456.321L723.251 457.432L722.757 456.493Z'
        fill='url(#paint2479_linear_3150_15389)'
      />
      <path
        d='M521.584 626.92L522.136 626.791L522.021 627.352L521.492 627.362L521.584 626.92Z'
        fill='url(#paint2480_linear_3150_15389)'
      />
      <path
        d='M692.423 504.202L693.561 503.242L693.872 504.234L692.71 505.108L692.423 504.202Z'
        fill='url(#paint2481_linear_3150_15389)'
      />
      <path
        d='M563.256 609.424L564.049 609.144L563.969 609.802L563.187 609.975L563.256 609.424Z'
        fill='url(#paint2482_linear_3150_15389)'
      />
      <path
        d='M675.933 317.013L675.738 315.265L677.394 315.384L676.623 317.272L675.933 317.013Z'
        fill='url(#paint2483_linear_3150_15389)'
      />
      <path
        d='M729.012 432.774L730.024 431.501L730.656 432.493L729.599 433.712L729.012 432.774Z'
        fill='url(#paint2484_linear_3150_15389)'
      />
      <path
        d='M504.083 628.441L504.497 628.323L504.37 628.883H503.968L504.083 628.441Z'
        fill='url(#paint2485_linear_3150_15389)'
      />
      <path
        d='M627.764 214.379L628.305 213.236L628.615 213.786L628.086 214.929L627.764 214.379Z'
        fill='url(#paint2486_linear_3150_15389)'
      />
      <path
        d='M667.861 528.116L668.965 527.274L669.16 528.234L668.045 528.989L667.861 528.116Z'
        fill='url(#paint2487_linear_3150_15389)'
      />
      <path
        d='M589.163 591.691L590.06 591.271L590.014 592.026L589.128 592.349L589.163 591.691Z'
        fill='url(#paint2488_linear_3150_15389)'
      />
      <path
        d='M672.185 244.301L673.392 242.747L673.714 243.567L672.484 245.099L672.185 244.301Z'
        fill='url(#paint2489_linear_3150_15389)'
      />
      <path
        d='M656.213 37.9331L656.512 35.916L657.144 35.7866L657.029 37.987L656.213 37.9331Z'
        fill='url(#paint2490_linear_3150_15389)'
      />
      <path
        d='M733.945 408.364L734.888 406.994L735.601 407.965L734.624 409.291L733.945 408.364Z'
        fill='url(#paint2491_linear_3150_15389)'
      />
      <path
        d='M700.771 482.09L701.863 481.022L702.254 482.036L701.139 483.039L700.771 482.09Z'
        fill='url(#paint2492_linear_3150_15389)'
      />
      <path
        d='M545.801 611.495L546.479 611.226L546.376 611.884L545.709 612.046L545.801 611.495Z'
        fill='url(#paint2493_linear_3150_15389)'
      />
      <path
        d='M486.064 629.692L486.351 629.584L486.202 630.135L485.949 630.145L486.064 629.692Z'
        fill='url(#paint2494_linear_3150_15389)'
      />
      <path
        d='M642.069 550.832L643.127 550.131L643.219 551.037L642.15 551.652L642.069 550.832Z'
        fill='url(#paint2495_linear_3150_15389)'
      />
      <path
        d='M615.633 572.103L616.622 571.542L616.634 572.384L615.645 572.847L615.633 572.103Z'
        fill='url(#paint2496_linear_3150_15389)'
      />
      <path
        d='M467.666 630.674L467.815 630.566L467.654 631.116H467.539L467.666 630.674Z'
        fill='url(#paint2497_linear_3150_15389)'
      />
      <path
        d='M707.981 458.985L709.004 457.798L709.487 458.823L708.418 459.945L707.981 458.985Z'
        fill='url(#paint2498_linear_3150_15389)'
      />
      <path
        d='M676.773 506.985L677.842 506.036L678.107 507.039L677.014 507.913L676.773 506.985Z'
        fill='url(#paint2499_linear_3150_15389)'
      />
      <path
        d='M527.575 613.308L528.127 613.049L528 613.717L527.471 613.869L527.575 613.308Z'
        fill='url(#paint2500_linear_3150_15389)'
      />
      <path
        d='M571.455 594.021L572.236 593.611L572.167 594.377L571.386 594.679L571.455 594.021Z'
        fill='url(#paint2501_linear_3150_15389)'
      />
      <path
        d='M449.141 194.866L448.175 193.334L448.98 191.911L448.67 194.543L449.141 194.866Z'
        fill='url(#paint2502_linear_3150_15389)'
      />
      <path
        d='M706.095 365.326L706.543 363.664L707.647 364.333L706.796 365.951L706.095 365.326Z'
        fill='url(#paint2503_linear_3150_15389)'
      />
      <path
        d='M651.245 530.899L652.28 530.068L652.43 531.039L651.383 531.783L651.245 530.899Z'
        fill='url(#paint2504_linear_3150_15389)'
      />
      <path
        d='M597.971 574.649L598.868 574.099L598.845 574.94L597.959 575.393L597.971 574.649Z'
        fill='url(#paint2505_linear_3150_15389)'
      />
      <path
        d='M713.972 435.006L714.937 433.723L715.489 434.737L714.489 435.966L713.972 435.006Z'
        fill='url(#paint2506_linear_3150_15389)'
      />
      <path
        d='M712.891 159.508L714.362 157.059L714.972 157.901L713.466 160.479L712.891 159.508Z'
        fill='url(#paint2507_linear_3150_15389)'
      />
      <path
        d='M676.428 215.587L677.658 213.764L678.026 214.627L676.773 216.374L676.428 215.587Z'
        fill='url(#paint2508_linear_3150_15389)'
      />
      <path
        d='M490.698 212.772L490.02 211.003L490.583 210.032L490.479 212.394L490.698 212.772Z'
        fill='url(#paint2509_linear_3150_15389)'
      />
      <path
        d='M508.694 614.872L509.119 614.624L508.97 615.282L508.579 615.433L508.694 614.872Z'
        fill='url(#paint2510_linear_3150_15389)'
      />
      <path
        d='M624.775 553.529L625.752 552.838L625.798 553.755L624.832 554.359L624.775 553.529Z'
        fill='url(#paint2511_linear_3150_15389)'
      />
      <path
        d='M522.619 155.959L522.906 154.881L522.803 155.096L522.78 156.38L522.619 155.959Z'
        fill='url(#paint2512_linear_3150_15389)'
      />
      <path
        d='M592.198 202.934L592.612 201.877L592.739 202.309L592.417 203.463L592.198 202.934Z'
        fill='url(#paint2513_linear_3150_15389)'
      />
      <path
        d='M602.754 28.3546L602.812 26.4238L602.777 25.8522L602.835 27.9124L602.754 28.3546Z'
        fill='url(#paint2514_linear_3150_15389)'
      />
      <path
        d='M684.661 484.7L685.684 483.632L686.029 484.657L684.971 485.66L684.661 484.7Z'
        fill='url(#paint2515_linear_3150_15389)'
      />
      <path
        d='M552.803 596.125L553.47 595.726L553.378 596.491L552.711 596.783L552.803 596.125Z'
        fill='url(#paint2516_linear_3150_15389)'
      />
      <path
        d='M718.686 410.305L719.595 408.925L720.227 409.906L719.296 411.244L718.686 410.305Z'
        fill='url(#paint2517_linear_3150_15389)'
      />
      <path
        d='M489.272 616.155L489.56 615.907L489.387 616.576L489.123 616.705L489.272 616.155Z'
        fill='url(#paint2518_linear_3150_15389)'
      />
      <path
        d='M608.458 265.518L607.837 263.781L609.493 263.447L608.814 265.572L608.458 265.518Z'
        fill='url(#paint2519_linear_3150_15389)'
      />
      <path
        d='M659.559 509.649L660.548 508.689L660.755 509.714L659.743 510.588L659.559 509.649Z'
        fill='url(#paint2520_linear_3150_15389)'
      />
      <path
        d='M469.414 617.148L469.563 616.9L469.368 617.568L469.253 617.698L469.414 617.148Z'
        fill='url(#paint2521_linear_3150_15389)'
      />
      <path
        d='M579.216 576.979L579.987 576.428L579.941 577.291L579.159 577.734L579.216 576.979Z'
        fill='url(#paint2522_linear_3150_15389)'
      />
      <path
        d='M691.48 461.38L692.446 460.193L692.86 461.239L691.859 462.361L691.48 461.38Z'
        fill='url(#paint2523_linear_3150_15389)'
      />
      <path
        d='M533.324 597.969L533.876 597.57L533.738 598.347L533.209 598.627L533.324 597.969Z'
        fill='url(#paint2524_linear_3150_15389)'
      />
      <path
        d='M702.68 107.183L703.749 104.68L704.462 105.295L703.381 107.873L702.68 107.183Z'
        fill='url(#paint2525_linear_3150_15389)'
      />
      <path
        d='M633.204 533.52L634.158 532.689L634.25 533.671L633.295 534.415L633.204 533.52Z'
        fill='url(#paint2526_linear_3150_15389)'
      />
      <path
        d='M606.216 556.042L607.09 555.352L607.101 556.279L606.227 556.872L606.216 556.042Z'
        fill='url(#paint2527_linear_3150_15389)'
      />
      <path
        d='M697.149 437.153L698.057 435.859L698.552 436.894L697.609 438.135L697.149 437.153Z'
        fill='url(#paint2528_linear_3150_15389)'
      />
      <path
        d='M513.132 599.544L513.546 599.156L513.385 599.922L512.994 600.213L513.132 599.544Z'
        fill='url(#paint2529_linear_3150_15389)'
      />
      <path
        d='M666.93 487.192L667.884 486.124L668.149 487.17L667.183 488.173L666.93 487.192Z'
        fill='url(#paint2530_linear_3150_15389)'
      />
      <path
        d='M559.438 579.071L560.105 578.532L560.013 579.395L559.358 579.837L559.438 579.071Z'
        fill='url(#paint2531_linear_3150_15389)'
      />
      <path
        d='M642.713 300.552L642.518 298.74L643.897 298.924L643.196 300.822L642.713 300.552Z'
        fill='url(#paint2532_linear_3150_15389)'
      />
      <path
        d='M677.371 186.852L678.647 184.835L679.049 185.59L677.762 187.715L677.371 186.852Z'
        fill='url(#paint2533_linear_3150_15389)'
      />
      <path
        d='M701.622 412.171L702.473 410.78L703.025 411.783L702.151 413.131L701.622 412.171Z'
        fill='url(#paint2534_linear_3150_15389)'
      />
      <path
        d='M628.983 237.009L629.903 235.704L630.041 236.405L629.156 237.807L628.983 237.009Z'
        fill='url(#paint2535_linear_3150_15389)'
      />
      <path
        d='M492.342 600.838L492.63 600.45L492.434 601.227L492.181 601.496L492.342 600.838Z'
        fill='url(#paint2536_linear_3150_15389)'
      />
      <path
        d='M640.85 512.152L641.759 511.192L641.908 512.227L640.988 513.101L640.85 512.152Z'
        fill='url(#paint2537_linear_3150_15389)'
      />
      <path
        d='M586.484 558.34L587.242 557.66L587.208 558.598L586.449 559.181L586.484 558.34Z'
        fill='url(#paint2538_linear_3150_15389)'
      />
      <path
        d='M613.828 535.958L614.679 535.127L614.725 536.119L613.862 536.853L613.828 535.958Z'
        fill='url(#paint2539_linear_3150_15389)'
      />
      <path
        d='M673.289 463.666L674.197 462.48L674.542 463.537L673.611 464.659L673.289 463.666Z'
        fill='url(#paint2540_linear_3150_15389)'
      />
      <path
        d='M538.775 580.916L539.315 580.387L539.189 581.25L538.66 581.682L538.775 580.916Z'
        fill='url(#paint2541_linear_3150_15389)'
      />
      <path
        d='M471.081 601.831L471.23 601.453L471.023 602.219L470.897 602.5L471.081 601.831Z'
        fill='url(#paint2542_linear_3150_15389)'
      />
      <path
        d='M647.623 489.543L648.509 488.464L648.715 489.522L647.819 490.525L647.623 489.543Z'
        fill='url(#paint2543_linear_3150_15389)'
      />
      <path
        d='M678.59 439.202L679.44 437.908L679.854 438.954L678.981 440.195L678.59 439.202Z'
        fill='url(#paint2544_linear_3150_15389)'
      />
      <path
        d='M565.659 560.411L566.315 559.731L566.234 560.669L565.59 561.252L565.659 560.411Z'
        fill='url(#paint2545_linear_3150_15389)'
      />
      <path
        d='M517.341 582.49L517.755 581.962L517.582 582.825L517.191 583.256L517.341 582.49Z'
        fill='url(#paint2546_linear_3150_15389)'
      />
      <path
        d='M566.361 245.811L565.74 244.031L567.05 243.697L566.453 245.822L566.361 245.811Z'
        fill='url(#paint2547_linear_3150_15389)'
      />
      <path
        d='M620.716 514.482L621.544 513.522L621.636 514.568L620.796 515.442L620.716 514.482Z'
        fill='url(#paint2548_linear_3150_15389)'
      />
      <path
        d='M593.187 538.19L593.935 537.371L593.923 538.374L593.176 539.096L593.187 538.19Z'
        fill='url(#paint2549_linear_3150_15389)'
      />
      <path
        d='M682.775 413.962L683.569 412.56L684.04 413.574L683.224 414.933L682.775 413.962Z'
        fill='url(#paint2550_linear_3150_15389)'
      />
      <path
        d='M495.252 583.774L495.539 583.256L495.332 584.119L495.079 584.54L495.252 583.774Z'
        fill='url(#paint2551_linear_3150_15389)'
      />
      <path
        d='M653.488 465.824L654.315 464.626L654.58 465.694L653.729 466.827L653.488 465.824Z'
        fill='url(#paint2552_linear_3150_15389)'
      />
      <path
        d='M543.892 562.223L544.421 561.554L544.294 562.492L543.765 563.075L543.892 562.223Z'
        fill='url(#paint2553_linear_3150_15389)'
      />
      <path
        d='M685.799 388.096L686.524 386.597L687.064 387.567L686.317 389.023L685.799 388.096Z'
        fill='url(#paint2554_linear_3150_15389)'
      />
      <path
        d='M569.718 215.975L570.132 214.875L569.983 215.425L569.741 216.698L569.718 215.975Z'
        fill='url(#paint2555_linear_3150_15389)'
      />
      <path
        d='M665.297 147.147L666.401 144.968L666.769 145.529L665.699 147.912L665.297 147.147Z'
        fill='url(#paint2556_linear_3150_15389)'
      />
      <path
        d='M626.845 491.722L627.638 490.643L627.776 491.722L626.971 492.725L626.845 491.722Z'
        fill='url(#paint2557_linear_3150_15389)'
      />
      <path
        d='M571.409 540.197L572.041 539.377L571.984 540.391L571.351 541.113L571.409 540.197Z'
        fill='url(#paint2558_linear_3150_15389)'
      />
      <path
        d='M599.282 516.607L599.995 515.657L600.018 516.714L599.293 517.577L599.282 516.607Z'
        fill='url(#paint2559_linear_3150_15389)'
      />
      <path
        d='M658.363 441.144L659.156 439.828L659.478 440.896L658.673 442.147L658.363 441.144Z'
        fill='url(#paint2560_linear_3150_15389)'
      />
      <path
        d='M608.331 207.206L609.021 205.771L609.056 206.419L608.423 207.972L608.331 207.206Z'
        fill='url(#paint2561_linear_3150_15389)'
      />
      <path
        d='M521.285 563.765L521.687 563.096L521.515 564.046L521.124 564.628L521.285 563.765Z'
        fill='url(#paint2562_linear_3150_15389)'
      />
      <path
        d='M643.966 94.7672L644.622 92.4481L644.886 92.6638L644.3 95.1771L643.966 94.7672Z'
        fill='url(#paint2563_linear_3150_15389)'
      />
      <path
        d='M662.215 415.645L662.951 414.232L663.331 415.256L662.583 416.626L662.215 415.645Z'
        fill='url(#paint2564_linear_3150_15389)'
      />
      <path
        d='M497.988 565.038L498.264 564.369L498.046 565.318L497.793 565.89L497.988 565.038Z'
        fill='url(#paint2565_linear_3150_15389)'
      />
      <path
        d='M632.146 467.83L632.893 466.633L633.077 467.711L632.318 468.855L632.146 467.83Z'
        fill='url(#paint2566_linear_3150_15389)'
      />
      <path
        d='M548.606 541.955L549.124 541.146L549.009 542.16L548.491 542.882L548.606 541.955Z'
        fill='url(#paint2567_linear_3150_15389)'
      />
      <path
        d='M665.009 389.509L665.676 387.999L666.113 388.98L665.423 390.447L665.009 389.509Z'
        fill='url(#paint2568_linear_3150_15389)'
      />
      <path
        d='M604.686 493.728L605.376 492.65L605.445 493.728L604.744 494.731L604.686 493.728Z'
        fill='url(#paint2569_linear_3150_15389)'
      />
      <path
        d='M576.618 518.526L577.238 517.577L577.192 518.634L576.583 519.508L576.618 518.526Z'
        fill='url(#paint2570_linear_3150_15389)'
      />
      <path
        d='M666.7 362.909L667.309 361.313L667.804 362.219L667.171 363.794L666.7 362.909Z'
        fill='url(#paint2571_linear_3150_15389)'
      />
      <path
        d='M636.561 442.945L637.263 441.619L637.493 442.697L636.78 443.959L636.561 442.945Z'
        fill='url(#paint2572_linear_3150_15389)'
      />
      <path
        d='M524.93 543.454L525.321 542.645L525.149 543.67L524.769 544.382L524.93 543.454Z'
        fill='url(#paint2573_linear_3150_15389)'
      />
      <path
        d='M593.141 171.772L593.578 170.09L593.486 170.64L593.13 172.419L593.141 171.772Z'
        fill='url(#paint2574_linear_3150_15389)'
      />
      <path
        d='M609.366 469.674L610.022 468.466L610.125 469.566L609.458 470.699L609.366 469.674Z'
        fill='url(#paint2575_linear_3150_15389)'
      />
      <path
        d='M552.895 520.209L553.401 519.26L553.286 520.328L552.792 521.191L552.895 520.209Z'
        fill='url(#paint2576_linear_3150_15389)'
      />
      <path
        d='M640.045 417.209L640.701 415.785L640.988 416.831L640.31 418.212L640.045 417.209Z'
        fill='url(#paint2577_linear_3150_15389)'
      />
      <path
        d='M500.518 544.684L500.794 543.875L500.552 544.889L500.311 545.611L500.518 544.684Z'
        fill='url(#paint2578_linear_3150_15389)'
      />
      <path
        d='M581.263 495.53L581.85 494.44L581.838 495.54L581.24 496.544L581.263 495.53Z'
        fill='url(#paint2579_linear_3150_15389)'
      />
      <path
        d='M642.564 390.825L643.161 389.293L643.506 390.296L642.886 391.774L642.564 390.825Z'
        fill='url(#paint2580_linear_3150_15389)'
      />
      <path
        d='M613.264 444.596L613.885 443.269L614.023 444.358L613.391 445.62L613.264 444.596Z'
        fill='url(#paint2581_linear_3150_15389)'
      />
      <path
        d='M644.093 363.956L644.645 362.338L645.024 363.265L644.461 364.851L644.093 363.956Z'
        fill='url(#paint2582_linear_3150_15389)'
      />
      <path
        d='M528.242 521.644L528.621 520.695L528.449 521.762L528.081 522.625L528.242 521.644Z'
        fill='url(#paint2583_linear_3150_15389)'
      />
      <path
        d='M585.276 471.325L585.84 470.117L585.851 471.217L585.288 472.36L585.276 471.325Z'
        fill='url(#paint2584_linear_3150_15389)'
      />
      <path
        d='M556.713 497.104L557.196 496.026L557.104 497.126L556.621 498.129L556.713 497.104Z'
        fill='url(#paint2585_linear_3150_15389)'
      />
      <path
        d='M616.346 418.654L616.921 417.219L617.105 418.277L616.519 419.657L616.346 418.654Z'
        fill='url(#paint2586_linear_3150_15389)'
      />
      <path
        d='M495.435 233.04L495.079 231.13L495.47 231.109L495.068 233.115L495.435 233.04Z'
        fill='url(#paint2587_linear_3150_15389)'
      />
      <path
        d='M502.818 522.809L503.082 521.859L502.829 522.938L502.588 523.801L502.818 522.809Z'
        fill='url(#paint2588_linear_3150_15389)'
      />
      <path
        d='M618.577 392.022L619.106 390.49L619.324 391.494L618.784 392.993L618.577 392.022Z'
        fill='url(#paint2589_linear_3150_15389)'
      />
      <path
        d='M588.622 446.073L589.151 444.747L589.186 445.847L588.657 447.109L588.622 446.073Z'
        fill='url(#paint2590_linear_3150_15389)'
      />
      <path
        d='M531.185 498.453L531.553 497.363L531.381 498.474L531.025 499.477L531.185 498.453Z'
        fill='url(#paint2591_linear_3150_15389)'
      />
      <path
        d='M560.013 472.781L560.473 471.562L560.393 472.673L559.933 473.816L560.013 472.781Z'
        fill='url(#paint2592_linear_3150_15389)'
      />
      <path
        d='M619.934 364.905L620.405 363.287L620.67 364.215L620.187 365.811L619.934 364.905Z'
        fill='url(#paint2593_linear_3150_15389)'
      />
      <path
        d='M620.394 337.496L620.819 335.781L621.118 336.633L620.681 338.316L620.394 337.496Z'
        fill='url(#paint2594_linear_3150_15389)'
      />
      <path
        d='M591.267 419.938L591.75 418.503L591.831 419.571L591.336 420.962L591.267 419.938Z'
        fill='url(#paint2595_linear_3150_15389)'
      />
      <path
        d='M504.865 499.542L505.106 498.464L504.865 499.564L504.635 500.578L504.865 499.542Z'
        fill='url(#paint2596_linear_3150_15389)'
      />
      <path
        d='M562.761 447.379L563.198 446.041L563.141 447.152L562.715 448.425L562.761 447.379Z'
        fill='url(#paint2597_linear_3150_15389)'
      />
      <path
        d='M533.738 474.011L534.083 472.792L533.922 473.913L533.577 475.057L533.738 474.011Z'
        fill='url(#paint2598_linear_3150_15389)'
      />
      <path
        d='M593.176 393.112L593.624 391.558L593.728 392.583L593.279 394.082L593.176 393.112Z'
        fill='url(#paint2599_linear_3150_15389)'
      />
      <path
        d='M594.337 365.768L594.74 364.128L594.878 365.077L594.464 366.685L594.337 365.768Z'
        fill='url(#paint2600_linear_3150_15389)'
      />
      <path
        d='M594.36 310.379L594.671 308.588L594.855 309.333L594.544 311.102L594.36 310.379Z'
        fill='url(#paint2601_linear_3150_15389)'
      />
      <path
        d='M594.728 338.122L595.096 336.407L595.257 337.259L594.889 338.952L594.728 338.122Z'
        fill='url(#paint2602_linear_3150_15389)'
      />
      <path
        d='M564.935 421.081L565.337 419.625L565.303 420.703L564.9 422.106L564.935 421.081Z'
        fill='url(#paint2603_linear_3150_15389)'
      />
      <path
        d='M535.866 448.49L536.199 447.141L536.038 448.252L535.716 449.536L535.866 448.49Z'
        fill='url(#paint2604_linear_3150_15389)'
      />
      <path
        d='M566.51 394.061L566.878 392.508L566.867 393.532L566.499 395.042L566.51 394.061Z'
        fill='url(#paint2605_linear_3150_15389)'
      />
      <path
        d='M567.464 366.523L567.798 364.872L567.809 365.832L567.476 367.44L567.464 366.523Z'
        fill='url(#paint2606_linear_3150_15389)'
      />
      <path
        d='M537.544 422.041L537.855 420.585L537.705 421.664L537.406 423.077L537.544 422.041Z'
        fill='url(#paint2607_linear_3150_15389)'
      />
      <path
        d='M567.487 310.724L567.729 308.923L567.775 309.667L567.522 311.458L567.487 310.724Z'
        fill='url(#paint2608_linear_3150_15389)'
      />
      <path
        d='M567.798 338.672L568.085 336.946L568.108 337.809L567.821 339.513L567.798 338.672Z'
        fill='url(#paint2609_linear_3150_15389)'
      />
      <path
        d='M538.763 394.87L539.039 393.306L538.901 394.341L538.637 395.862L538.763 394.87Z'
        fill='url(#paint2610_linear_3150_15389)'
      />
      <path
        d='M539.499 367.17L539.752 365.509L539.626 366.469L539.373 368.098L539.499 367.17Z'
        fill='url(#paint2611_linear_3150_15389)'
      />
      <path
        d='M494.569 354.057L492.216 355.545L493.298 351.732L495.756 350.145L494.569 354.057Z'
        fill='url(#paint2612_linear_3150_15389)'
      />
      <path
        d='M499.694 367.22L497.594 368.964L499.127 365.161L501.427 363.496L499.694 367.22Z'
        fill='url(#paint2613_linear_3150_15389)'
      />
      <path
        d='M480.979 354.353L478.322 356.028L479.068 352.165L481.904 350.51L480.979 354.353Z'
        fill='url(#paint2614_linear_3150_15389)'
      />
      <path
        d='M509.009 352.894L507.119 354.214L508.474 350.411L510.49 349.002L509.009 352.894Z'
        fill='url(#paint2615_linear_3150_15389)'
      />
      <path
        d='M485.674 366.885L483.217 368.738L484.382 364.984L487.018 363.181L485.674 366.885Z'
        fill='url(#paint2616_linear_3150_15389)'
      />
      <path
        d='M514.428 366.698L512.737 368.196L514.628 364.452L516.476 362.866L514.428 366.698Z'
        fill='url(#paint2617_linear_3150_15389)'
      />
      <path
        d='M470.247 354.52L467.348 356.215L468.094 352.372L471.076 350.539L470.247 354.52Z'
        fill='url(#paint2618_linear_3150_15389)'
      />
      <path
        d='M457.203 356.037L454.011 357.919L454.567 354.096L457.823 352.017L457.203 356.037Z'
        fill='url(#paint2619_linear_3150_15389)'
      />
      <path
        d='M472.452 366.994L469.679 368.935L470.572 365.25L473.471 363.249L472.452 366.994Z'
        fill='url(#paint2620_linear_3150_15389)'
      />
      <path
        d='M468.85 341.209L465.878 342.559L466.497 338.578L469.512 337.09L468.85 341.209Z'
        fill='url(#paint2621_linear_3150_15389)'
      />
      <path
        d='M456.51 342.312L453.255 343.849L453.769 339.829L457.077 338.164L456.51 342.312Z'
        fill='url(#paint2622_linear_3150_15389)'
      />
      <path
        d='M478.522 340.44L475.686 341.682L476.222 337.77L479.089 336.41L478.522 340.44Z'
        fill='url(#paint2623_linear_3150_15389)'
      />
      <path
        d='M491.397 339.366L488.951 340.45L489.654 336.558L492.154 335.366L491.397 339.366Z'
        fill='url(#paint2624_linear_3150_15389)'
      />
      <path
        d='M523.46 351.259L522.105 352.362L523.628 348.628L525.109 347.387L523.46 351.259Z'
        fill='url(#paint2625_linear_3150_15389)'
      />
      <path
        d='M457.875 368.087L454.788 370.206L455.26 366.619L458.432 364.353L457.875 368.087Z'
        fill='url(#paint2626_linear_3150_15389)'
      />
      <path
        d='M491.051 376.896L488.824 378.729L490.358 375.142L492.763 373.319L491.051 376.896Z'
        fill='url(#paint2627_linear_3150_15389)'
      />
      <path
        d='M506.552 376.551L504.787 378.226L506.741 374.551L508.705 372.955L506.552 376.551Z'
        fill='url(#paint2628_linear_3150_15389)'
      />
      <path
        d='M528.931 364.619L527.755 365.861L529.792 362.195L531.105 360.777L528.931 364.619Z'
        fill='url(#paint2629_linear_3150_15389)'
      />
      <path
        d='M504.22 338.677L502.204 339.652L503.044 335.8L505.176 334.745L504.22 338.677Z'
        fill='url(#paint2630_linear_3150_15389)'
      />
      <path
        d='M441.209 357.712L437.733 359.88L437.701 356.116L441.272 353.82L441.209 357.712Z'
        fill='url(#paint2631_linear_3150_15389)'
      />
      <path
        d='M476.107 377.31L473.502 379.33L474.584 375.822L477.346 373.783L476.107 377.31Z'
        fill='url(#paint2632_linear_3150_15389)'
      />
      <path
        d='M520.341 376.462L518.986 377.95L521.191 374.295L522.746 372.827L520.341 376.462Z'
        fill='url(#paint2633_linear_3150_15389)'
      />
      <path
        d='M440.862 343.623L437.239 345.406L437.302 341.406L441.009 339.553L440.862 343.623Z'
        fill='url(#paint2634_linear_3150_15389)'
      />
      <path
        d='M517.873 338.292L516.402 339.189L517.39 335.386L519.049 334.44L517.873 338.292Z'
        fill='url(#paint2635_linear_3150_15389)'
      />
      <path
        d='M467.306 328.627L464.292 329.622L464.712 325.513L467.789 324.459L467.306 328.627Z'
        fill='url(#paint2636_linear_3150_15389)'
      />
      <path
        d='M455.481 329.08L452.173 330.174L452.551 326.026L455.901 324.853L455.481 329.08Z'
        fill='url(#paint2637_linear_3150_15389)'
      />
      <path
        d='M477.787 327.977L474.92 328.814L475.361 324.804L478.186 323.819L477.787 327.977Z'
        fill='url(#paint2638_linear_3150_15389)'
      />
      <path
        d='M441.902 369.418L438.584 371.792L438.384 368.344L441.808 365.841L441.902 369.418Z'
        fill='url(#paint2639_linear_3150_15389)'
      />
      <path
        d='M535.925 330.912L534.77 331.996L535.684 328.164L537.207 327.237L535.925 330.912Z'
        fill='url(#paint2640_linear_3150_15389)'
      />
      <path
        d='M513.651 327.809L511.992 328.587L512.653 324.695L514.512 323.897L513.651 327.809Z'
        fill='url(#paint2641_linear_3150_15389)'
      />
      <path
        d='M535.831 349.978L534.949 350.963L536.472 347.308L537.532 346.234L535.831 349.978Z'
        fill='url(#paint2642_linear_3150_15389)'
      />
      <path
        d='M458.884 378.561L455.901 380.788L456.269 377.507L459.346 375.132L458.884 378.561Z'
        fill='url(#paint2643_linear_3150_15389)'
      />
      <path
        d='M529.982 338.519L528.889 339.416L529.982 335.681L531.294 334.765L529.982 338.519Z'
        fill='url(#paint2644_linear_3150_15389)'
      />
      <path
        d='M525.382 328.765L524.058 329.632L524.835 325.799L526.432 324.952L525.382 328.765Z'
        fill='url(#paint2645_linear_3150_15389)'
      />
      <path
        d='M500.366 327.188L498.234 327.937L498.675 323.976L501.007 323.227L500.366 327.188Z'
        fill='url(#paint2646_linear_3150_15389)'
      />
      <path
        d='M547.352 332.893L546.343 334.006L547.53 330.302L548.769 329.188L547.352 332.893Z'
        fill='url(#paint2647_linear_3150_15389)'
      />
      <path
        d='M530.664 320.961L529.131 321.917L529.75 318.065L531.546 317.148L530.664 320.961Z'
        fill='url(#paint2648_linear_3150_15389)'
      />
      <path
        d='M533.668 375.093L532.743 376.324L535.064 372.787L536.136 371.418L533.668 375.093Z'
        fill='url(#paint2649_linear_3150_15389)'
      />
      <path
        d='M489.276 327.001L486.777 327.71L487.228 323.74L489.749 322.932L489.276 327.001Z'
        fill='url(#paint2650_linear_3150_15389)'
      />
      <path
        d='M542.668 322.173L541.439 323.178L542.279 319.424L543.718 318.4L542.668 322.173Z'
        fill='url(#paint2651_linear_3150_15389)'
      />
      <path
        d='M440.957 330.184L437.208 331.464L437.344 327.326L441.104 325.957L440.957 330.184Z'
        fill='url(#paint2652_linear_3150_15389)'
      />
      <path
        d='M454.62 318.567L451.259 319.414L451.606 315.168L454.987 314.242L454.62 318.567Z'
        fill='url(#paint2653_linear_3150_15389)'
      />
      <path
        d='M542.153 362.097L541.523 363.151L543.497 359.555L544.285 358.373L542.153 362.097Z'
        fill='url(#paint2654_linear_3150_15389)'
      />
      <path
        d='M495.43 383.842L493.393 385.527L495.073 382.187L497.247 380.443L495.43 383.842Z'
        fill='url(#paint2655_linear_3150_15389)'
      />
      <path
        d='M426.569 358.422L422.915 360.816L422.369 357.131L426.149 354.678L426.569 358.422Z'
        fill='url(#paint2656_linear_3150_15389)'
      />
      <path
        d='M466.277 317.897L463.2 318.596L463.546 314.419L466.613 313.611L466.277 317.897Z'
        fill='url(#paint2657_linear_3150_15389)'
      />
      <path
        d='M520.635 318.912L519.049 319.71L519.49 315.779L521.412 315.059L520.635 318.912Z'
        fill='url(#paint2658_linear_3150_15389)'
      />
      <path
        d='M479.94 385.084L477.472 387.025L478.711 383.763L481.305 381.753L479.94 385.084Z'
        fill='url(#paint2659_linear_3150_15389)'
      />
      <path
        d='M541.355 339.573L540.567 340.558L541.754 336.863L542.846 335.947L541.355 339.573Z'
        fill='url(#paint2660_linear_3150_15389)'
      />
      <path
        d='M426.475 344.391L422.578 346.372L422.242 342.421L426.223 340.401L426.475 344.391Z'
        fill='url(#paint2661_linear_3150_15389)'
      />
      <path
        d='M440.841 319.513L437.05 320.518L437.26 316.242L441.062 315.158L440.841 319.513Z'
        fill='url(#paint2662_linear_3150_15389)'
      />
      <path
        d='M527.419 310.941L525.644 311.729L526.054 307.837L527.976 307L527.419 310.941Z'
        fill='url(#paint2663_linear_3150_15389)'
      />
      <path
        d='M512.254 382.266L510.826 383.714L512.884 380.265L514.481 378.847L512.254 382.266Z'
        fill='url(#paint2664_linear_3150_15389)'
      />
      <path
        d='M477.209 317.168L474.384 317.72L474.71 313.611L477.493 312.941L477.209 317.168Z'
        fill='url(#paint2665_linear_3150_15389)'
      />
      <path
        d='M538.919 311.808L537.511 312.685L538.036 308.872L539.643 307.955L538.919 311.808Z'
        fill='url(#paint2666_linear_3150_15389)'
      />
      <path
        d='M551.931 341.425L551.279 342.499L552.634 338.873L553.579 337.878L551.931 341.425Z'
        fill='url(#paint2667_linear_3150_15389)'
      />
      <path
        d='M516.896 309.67L514.985 310.379L515.216 306.379L517.411 305.719L516.896 309.67Z'
        fill='url(#paint2668_linear_3150_15389)'
      />
      <path
        d='M547.142 349.416L546.627 350.333L548.139 346.776L548.853 345.79L547.142 349.416Z'
        fill='url(#paint2669_linear_3150_15389)'
      />
      <path
        d='M509.303 317.198L507.455 317.759L507.738 313.808L509.849 313.247L509.303 317.198Z'
        fill='url(#paint2670_linear_3150_15389)'
      />
      <path
        d='M555.365 322.745L554.587 323.75L555.585 320.104L556.551 319.02L555.365 322.745Z'
        fill='url(#paint2671_linear_3150_15389)'
      />
      <path
        d='M487.806 316.734L485.285 317.217L485.569 313.167L488.121 312.606L487.806 316.734Z'
        fill='url(#paint2672_linear_3150_15389)'
      />
      <path
        d='M559.67 333.238L559.061 334.302L560.384 330.716L561.183 329.573L559.67 333.238Z'
        fill='url(#paint2673_linear_3150_15389)'
      />
      <path
        d='M426.853 331.228L422.799 332.726L422.631 328.597L426.748 327.06L426.853 331.228Z'
        fill='url(#paint2674_linear_3150_15389)'
      />
      <path
        d='M441.965 380.177L438.804 382.66L438.321 379.625L441.566 376.945L441.965 380.177Z'
        fill='url(#paint2675_linear_3150_15389)'
      />
      <path
        d='M524.867 381.615L523.817 382.877L526.001 379.497L527.209 378.206L524.867 381.615Z'
        fill='url(#paint2676_linear_3150_15389)'
      />
      <path
        d='M427.094 370.373L423.639 372.965L422.841 369.664L426.401 366.935L427.094 370.373Z'
        fill='url(#paint2677_linear_3150_15389)'
      />
      <path
        d='M497.677 316.675L495.346 317.129L495.619 313.148L497.992 312.616L497.677 316.675Z'
        fill='url(#paint2678_linear_3150_15389)'
      />
      <path
        d='M465.363 307.64L462.307 308.093L462.538 303.837L465.626 303.295L465.363 307.64Z'
        fill='url(#paint2679_linear_3150_15389)'
      />
      <path
        d='M460.207 386.66L457.35 388.936L457.592 385.941L460.564 383.576L460.207 386.66Z'
        fill='url(#paint2680_linear_3150_15389)'
      />
      <path
        d='M453.612 307.807L450.23 308.32L450.461 304.004L453.885 303.433L453.612 307.807Z'
        fill='url(#paint2681_linear_3150_15389)'
      />
      <path
        d='M551.731 312.409L550.828 313.306L551.552 309.63L552.581 308.596L551.731 312.409Z'
        fill='url(#paint2682_linear_3150_15389)'
      />
      <path
        d='M426.653 320.419L422.494 321.602L422.452 317.316L426.674 316.124L426.653 320.419Z'
        fill='url(#paint2683_linear_3150_15389)'
      />
      <path
        d='M476.495 307.394L473.712 307.768L473.933 303.59L476.705 303.118L476.495 307.394Z'
        fill='url(#paint2684_linear_3150_15389)'
      />
      <path
        d='M525.172 300.822L523.281 301.413L523.481 297.472L525.476 296.782L525.172 300.822Z'
        fill='url(#paint2685_linear_3150_15389)'
      />
      <path
        d='M506.3 308.103L504.199 308.586L504.283 304.556L506.657 304.093L506.3 308.103Z'
        fill='url(#paint2686_linear_3150_15389)'
      />
      <path
        d='M514.575 300.231L512.401 300.773L512.517 296.733L514.827 296.142L514.575 300.231Z'
        fill='url(#paint2687_linear_3150_15389)'
      />
      <path
        d='M562.831 341.977L562.401 342.943L563.903 339.514L564.491 338.421L562.831 341.977Z'
        fill='url(#paint2688_linear_3150_15389)'
      />
      <path
        d='M440.6 308.172L436.777 308.753L436.956 304.398L440.81 303.778L440.6 308.172Z'
        fill='url(#paint2689_linear_3150_15389)'
      />
      <path
        d='M556.698 349.673L556.352 350.589L557.906 347.14L558.452 346.175L556.698 349.673Z'
        fill='url(#paint2690_linear_3150_15389)'
      />
      <path
        d='M536.419 380.463L535.705 381.546L537.91 378.256L538.761 377.093L536.419 380.463Z'
        fill='url(#paint2691_linear_3150_15389)'
      />
      <path
        d='M486.735 307.138L484.183 307.423L484.372 303.325L486.892 302.93L486.735 307.138Z'
        fill='url(#paint2692_linear_3150_15389)'
      />
      <path
        d='M535.831 301.265L534.287 301.886L534.571 298.063L536.178 297.304L535.831 301.265Z'
        fill='url(#paint2693_linear_3150_15389)'
      />
      <path
        d='M504.535 299.512L502.162 299.955L502.225 295.846L504.745 295.373L504.535 299.512Z'
        fill='url(#paint2694_linear_3150_15389)'
      />
      <path
        d='M496.155 307.236L493.782 307.542L493.876 303.492L496.344 303.128L496.155 307.236Z'
        fill='url(#paint2695_linear_3150_15389)'
      />
      <path
        d='M496.354 386.049L494.38 387.507L495.798 384.473L497.856 382.886L496.354 386.049Z'
        fill='url(#paint2696_linear_3150_15389)'
      />
      <path
        d='M481.379 388.177L478.984 389.941L480.034 386.965L482.502 385.084L481.379 388.177Z'
        fill='url(#paint2697_linear_3150_15389)'
      />
      <path
        d='M411.121 345.071L407.015 347.249L406.059 343.455L410.27 341.248L411.121 345.071Z'
        fill='url(#paint2698_linear_3150_15389)'
      />
      <path
        d='M566.245 322.606L565.835 323.523L566.906 320.084L567.4 318.951L566.245 322.606Z'
        fill='url(#paint2699_linear_3150_15389)'
      />
      <path
        d='M464.344 297.738L461.257 297.954L461.383 293.639L464.47 293.353L464.344 297.738Z'
        fill='url(#paint2700_linear_3150_15389)'
      />
      <path
        d='M410.69 358.511L406.92 361.131L405.586 357.624L409.524 355.023L410.69 358.511Z'
        fill='url(#paint2701_linear_3150_15389)'
      />
      <path
        d='M547.782 301.915L546.837 302.645L547.183 298.93L548.297 298.093L547.782 301.915Z'
        fill='url(#paint2702_linear_3150_15389)'
      />
      <path
        d='M494.884 298.625L492.427 298.94L492.416 294.792L495.084 294.467L494.884 298.625Z'
        fill='url(#paint2703_linear_3150_15389)'
      />
      <path
        d='M427.514 380.985L424.332 383.744L423.187 380.827L426.517 377.97L427.514 380.985Z'
        fill='url(#paint2704_linear_3150_15389)'
      />
      <path
        d='M475.456 297.748L472.694 297.925L472.778 293.689L475.55 293.423L475.456 297.748Z'
        fill='url(#paint2705_linear_3150_15389)'
      />
      <path
        d='M534.319 292.211L532.754 292.792L532.838 288.87L534.55 288.191L534.319 292.211Z'
        fill='url(#paint2706_linear_3150_15389)'
      />
      <path
        d='M563.136 312.823L562.726 313.749L563.493 310.222L564.06 309.148L563.136 312.823Z'
        fill='url(#paint2707_linear_3150_15389)'
      />
      <path
        d='M523.47 291.304L521.517 291.767V287.737L523.607 287.215L523.47 291.304Z'
        fill='url(#paint2708_linear_3150_15389)'
      />
      <path
        d='M412.266 332.095L407.918 333.79L407.319 329.75L411.741 328.046L412.266 332.095Z'
        fill='url(#paint2709_linear_3150_15389)'
      />
      <path
        d='M485.737 297.965L483.217 298.162L483.248 293.994L485.842 293.728L485.737 297.965Z'
        fill='url(#paint2710_linear_3150_15389)'
      />
      <path
        d='M452.488 297.669L449.054 297.876L449.159 293.501L452.666 293.265L452.488 297.669Z'
        fill='url(#paint2711_linear_3150_15389)'
      />
      <path
        d='M513.21 290.693L510.92 291.038V286.949L513.283 286.515L513.21 290.693Z'
        fill='url(#paint2712_linear_3150_15389)'
      />
      <path
        d='M545.944 293.412L544.873 294.171L545.062 290.358L546.333 289.491L545.944 293.412Z'
        fill='url(#paint2713_linear_3150_15389)'
      />
      <path
        d='M426.968 308.695L422.704 309.374L422.746 304.99L427.063 304.34L426.968 308.695Z'
        fill='url(#paint2714_linear_3150_15389)'
      />
      <path
        d='M511.498 382.719L510.049 383.793L511.718 380.768L513.21 379.497L511.498 382.719Z'
        fill='url(#paint2715_linear_3150_15389)'
      />
      <path
        d='M441.209 388.168L438.216 390.71L437.313 388.118L440.358 385.369L441.209 388.168Z'
        fill='url(#paint2716_linear_3150_15389)'
      />
      <path
        d='M503.118 290.132L500.597 290.407L500.545 286.24L503.202 285.934L503.118 290.132Z'
        fill='url(#paint2717_linear_3150_15389)'
      />
      <path
        d='M412.56 320.902L408.065 322.222L407.687 317.966L412.276 316.724L412.56 320.902Z'
        fill='url(#paint2718_linear_3150_15389)'
      />
      <path
        d='M460.553 390.946L457.728 393.094L457.749 390.453L460.606 388.099L460.553 390.946Z'
        fill='url(#paint2719_linear_3150_15389)'
      />
      <path
        d='M559.313 303.088L558.841 303.936L559.303 300.359L559.964 299.374L559.313 303.088Z'
        fill='url(#paint2720_linear_3150_15389)'
      />
      <path
        d='M410.669 371.171L407.235 374.019L405.502 370.994L409.063 368.019L410.669 371.171Z'
        fill='url(#paint2721_linear_3150_15389)'
      />
      <path
        d='M557.602 294.782L556.992 295.679L557.339 292.023L558.095 290.969L557.602 294.782Z'
        fill='url(#paint2722_linear_3150_15389)'
      />
      <path
        d='M493.655 289.59L490.998 289.767L490.967 285.56L493.718 285.343L493.655 289.59Z'
        fill='url(#paint2723_linear_3150_15389)'
      />
      <path
        d='M484.739 289.048L482.145 289.186L482.103 284.949L484.792 284.742L484.739 289.048Z'
        fill='url(#paint2724_linear_3150_15389)'
      />
      <path
        d='M543.802 284.486L542.594 285.185L542.584 281.294L543.981 280.495L543.802 284.486Z'
        fill='url(#paint2725_linear_3150_15389)'
      />
      <path
        d='M474.395 288.555L471.622 288.604L471.57 284.318L474.426 284.19L474.395 288.555Z'
        fill='url(#paint2726_linear_3150_15389)'
      />
      <path
        d='M521.328 379.586L520.152 380.443L521.748 377.497L522.956 376.443L521.328 379.586Z'
        fill='url(#paint2727_linear_3150_15389)'
      />
      <path
        d='M532.491 283.097L530.822 283.57L530.706 279.579L532.544 279.018L532.491 283.097Z'
        fill='url(#paint2728_linear_3150_15389)'
      />
      <path
        d='M440.096 297.482L436.21 297.58L436.262 293.176L440.264 293.087L440.096 297.482Z'
        fill='url(#paint2729_linear_3150_15389)'
      />
      <path
        d='M522.179 282.161L520.12 282.466L519.994 278.387L522.137 277.983L522.179 282.161Z'
        fill='url(#paint2730_linear_3150_15389)'
      />
      <path
        d='M555.848 286.25L555.176 287.215L555.333 283.451L556.215 282.377L555.848 286.25Z'
        fill='url(#paint2731_linear_3150_15389)'
      />
      <path
        d='M463.263 288.191L460.165 288.161L460.144 283.816L463.294 283.777L463.263 288.191Z'
        fill='url(#paint2732_linear_3150_15389)'
      />
      <path
        d='M512.044 281.441L509.702 281.619L509.534 277.461L512.023 277.224L512.044 281.441Z'
        fill='url(#paint2733_linear_3150_15389)'
      />
      <path
        d='M479.74 390.848L477.262 392.463L477.965 389.783L480.423 387.931L479.74 390.848Z'
        fill='url(#paint2734_linear_3150_15389)'
      />
      <path
        d='M451.606 288.033L448.087 287.934L448.129 283.54L451.679 283.599L451.606 288.033Z'
        fill='url(#paint2735_linear_3150_15389)'
      />
      <path
        d='M413.631 309.256L408.957 310.015L408.81 305.66L413.536 304.98L413.631 309.256Z'
        fill='url(#paint2736_linear_3150_15389)'
      />
      <path
        d='M428.155 389.123L425.193 391.902L423.776 389.429L426.842 386.493L428.155 389.123Z'
        fill='url(#paint2737_linear_3150_15389)'
      />
      <path
        d='M493.992 387.005L491.891 388.256L492.931 385.468L495 384.01L493.992 387.005Z'
        fill='url(#paint2738_linear_3150_15389)'
      />
      <path
        d='M530.412 376.462L529.477 377.122L530.989 374.255L531.945 373.408L530.412 376.462Z'
        fill='url(#paint2739_linear_3150_15389)'
      />
      <path
        d='M567.452 288.388L567.221 289.57L567.547 285.964L567.998 284.643L567.452 288.388Z'
        fill='url(#paint2740_linear_3150_15389)'
      />
      <path
        d='M502.025 280.84L499.368 280.85L499.211 276.653L501.994 276.594L502.025 280.84Z'
        fill='url(#paint2741_linear_3150_15389)'
      />
      <path
        d='M427.955 297.64L423.587 297.689L423.713 293.295L428.082 293.275L427.955 297.64Z'
        fill='url(#paint2742_linear_3150_15389)'
      />
      <path
        d='M552.76 277.175L552.004 278.023L551.857 274.209L552.907 273.264L552.76 277.175Z'
        fill='url(#paint2743_linear_3150_15389)'
      />
      <path
        d='M541.418 275.362L540.105 275.894L539.885 271.973L541.303 271.313L541.418 275.362Z'
        fill='url(#paint2744_linear_3150_15389)'
      />
      <path
        d='M530.969 274.15L529.183 274.485L528.921 270.446L530.801 270.002L530.969 274.15Z'
        fill='url(#paint2745_linear_3150_15389)'
      />
      <path
        d='M552.487 269.322L551.437 270.307L551.269 266.337L552.56 265.253L552.487 269.322Z'
        fill='url(#paint2746_linear_3150_15389)'
      />
      <path
        d='M565.068 279.816L564.732 281.027L564.827 277.323L565.467 276.022L565.068 279.816Z'
        fill='url(#paint2747_linear_3150_15389)'
      />
      <path
        d='M506.426 382.719L504.703 383.576L505.89 380.778L507.528 379.675L506.426 382.719Z'
        fill='url(#paint2748_linear_3150_15389)'
      />
      <path
        d='M492.647 280.358L489.896 280.279L489.749 276.052L492.605 276.091L492.647 280.358Z'
        fill='url(#paint2749_linear_3150_15389)'
      />
      <path
        d='M441.03 392.69L438.195 395.232L436.977 392.956L439.886 390.266L441.03 392.69Z'
        fill='url(#paint2750_linear_3150_15389)'
      />
      <path
        d='M410.89 381.133L407.834 384.128L405.66 381.566L408.873 378.463L410.89 381.133Z'
        fill='url(#paint2751_linear_3150_15389)'
      />
      <path
        d='M483.363 279.865L480.696 279.747L480.507 275.5L483.258 275.549L483.363 279.865Z'
        fill='url(#paint2752_linear_3150_15389)'
      />
      <path
        d='M396.901 333.159L392.333 335.06L391.062 331.208L395.704 329.296L396.901 333.159Z'
        fill='url(#paint2753_linear_3150_15389)'
      />
      <path
        d='M439.623 288.033L435.622 287.826L435.706 283.392L439.802 283.629L439.623 288.033Z'
        fill='url(#paint2754_linear_3150_15389)'
      />
      <path
        d='M459.01 394.316L456.216 396.454L455.859 394.08L458.642 391.764L459.01 394.316Z'
        fill='url(#paint2755_linear_3150_15389)'
      />
      <path
        d='M564.333 271.786L563.735 273.066L563.819 269.283L564.554 267.825L564.333 271.786Z'
        fill='url(#paint2756_linear_3150_15389)'
      />
      <path
        d='M538.404 373.911L537.658 374.462L539.065 371.654L539.853 370.974L538.404 373.911Z'
        fill='url(#paint2757_linear_3150_15389)'
      />
      <path
        d='M541.198 267.194L539.78 267.854L539.433 263.775L541.114 263.036L541.198 267.194Z'
        fill='url(#paint2758_linear_3150_15389)'
      />
      <path
        d='M520.897 273.175L518.776 273.322L518.471 269.184L520.74 268.967L520.897 273.175Z'
        fill='url(#paint2759_linear_3150_15389)'
      />
      <path
        d='M473.061 279.392L470.215 279.185L470.026 274.889L472.935 275.017L473.061 279.392Z'
        fill='url(#paint2760_linear_3150_15389)'
      />
      <path
        d='M398.666 321.602L393.877 323.021L393.068 318.922L397.941 317.542L398.666 321.602Z'
        fill='url(#paint2761_linear_3150_15389)'
      />
      <path
        d='M395.106 345.406L390.915 347.82L389.046 344.273L393.394 341.879L395.106 345.406Z'
        fill='url(#paint2762_linear_3150_15389)'
      />
      <path
        d='M552.088 260.741L550.849 261.746L550.639 257.676L551.962 256.494L552.088 260.741Z'
        fill='url(#paint2763_linear_3150_15389)'
      />
      <path
        d='M462.181 279.008L459.041 278.683L458.883 274.347L462.087 274.594L462.181 279.008Z'
        fill='url(#paint2764_linear_3150_15389)'
      />
      <path
        d='M511.12 272.465L508.642 272.436L508.4 268.248L510.942 268.209L511.12 272.465Z'
        fill='url(#paint2765_linear_3150_15389)'
      />
      <path
        d='M530.496 265.657L528.637 265.992L528.249 261.844L530.275 261.41L530.496 265.657Z'
        fill='url(#paint2766_linear_3150_15389)'
      />
      <path
        d='M540.83 258.701L539.16 259.391L538.75 255.144L540.672 254.395L540.83 258.701Z'
        fill='url(#paint2767_linear_3150_15389)'
      />
      <path
        d='M563.577 263.164L562.936 264.524L562.873 260.642L563.65 259.095L563.577 263.164Z'
        fill='url(#paint2768_linear_3150_15389)'
      />
      <path
        d='M394.213 357.821L390.485 360.658L388.08 357.486L392.039 354.697L394.213 357.821Z'
        fill='url(#paint2769_linear_3150_15389)'
      />
      <path
        d='M515.615 378.719L514.176 379.32L515.289 376.512L516.686 375.723L515.615 378.719Z'
        fill='url(#paint2770_linear_3150_15389)'
      />
      <path
        d='M450.965 278.791L447.405 278.367L447.352 273.973L450.944 274.357L450.965 278.791Z'
        fill='url(#paint2771_linear_3150_15389)'
      />
      <path
        d='M415.49 298.3L410.701 298.428L410.806 294.053L415.605 294.004L415.49 298.3Z'
        fill='url(#paint2772_linear_3150_15389)'
      />
      <path
        d='M501.5 272.012L498.717 271.854L498.539 267.608L501.395 267.726L501.5 272.012Z'
        fill='url(#paint2773_linear_3150_15389)'
      />
      <path
        d='M475.067 394.276L472.431 395.922L472.631 393.488L475.141 391.576L475.067 394.276Z'
        fill='url(#paint2774_linear_3150_15389)'
      />
      <path
        d='M489.728 389.517L487.407 390.729L488.037 388.099L490.232 386.66L489.728 389.517Z'
        fill='url(#paint2775_linear_3150_15389)'
      />
      <path
        d='M520.383 264.603L518.125 264.682L517.747 260.465L520.152 260.317L520.383 264.603Z'
        fill='url(#paint2776_linear_3150_15389)'
      />
      <path
        d='M401.029 309.788L396.051 310.616L395.662 306.349L400.734 305.66L401.029 309.788Z'
        fill='url(#paint2777_linear_3150_15389)'
      />
      <path
        d='M530.286 257.075L528.27 257.42L527.808 253.124L530.044 252.71L530.286 257.075Z'
        fill='url(#paint2778_linear_3150_15389)'
      />
      <path
        d='M549.074 370.866L548.769 371.191L550.103 368.452L550.481 368.008L549.074 370.866Z'
        fill='url(#paint2779_linear_3150_15389)'
      />
      <path
        d='M510.994 263.854L508.453 263.815L508.19 259.548L510.742 259.509L510.994 263.854Z'
        fill='url(#paint2780_linear_3150_15389)'
      />
      <path
        d='M492.028 271.618L489.182 271.342L488.972 267.086L491.975 267.322L492.028 271.618Z'
        fill='url(#paint2781_linear_3150_15389)'
      />
      <path
        d='M428.502 288.121L424.101 287.727L424.248 283.333L428.743 283.776L428.502 288.121Z'
        fill='url(#paint2782_linear_3150_15389)'
      />
      <path
        d='M427.724 393.981L424.941 396.7L423.209 394.67L425.992 391.715L427.724 393.981Z'
        fill='url(#paint2783_linear_3150_15389)'
      />
      <path
        d='M502.897 385.685L501.017 386.542L501.868 383.763L503.706 382.778L502.897 385.685Z'
        fill='url(#paint2784_linear_3150_15389)'
      />
      <path
        d='M525.203 375.605L524.1 375.999L525.182 373.201L526.295 372.689L525.203 375.605Z'
        fill='url(#paint2785_linear_3150_15389)'
      />
      <path
        d='M439.623 278.86L435.506 278.328L435.664 273.904L439.802 274.456L439.623 278.86Z'
        fill='url(#paint2786_linear_3150_15389)'
      />
      <path
        d='M501.364 263.362L498.507 263.164L498.318 258.829L501.259 258.977L501.364 263.362Z'
        fill='url(#paint2787_linear_3150_15389)'
      />
      <path
        d='M562.369 254.237L561.75 255.587L561.498 251.646L562.222 250.079L562.369 254.237Z'
        fill='url(#paint2788_linear_3150_15389)'
      />
      <path
        d='M550.817 251.548L549.61 252.484L549.137 248.326L550.502 247.252L550.817 251.548Z'
        fill='url(#paint2789_linear_3150_15389)'
      />
      <path
        d='M393.383 370.935L390.233 374.068L387.219 371.457L390.59 368.285L393.383 370.935Z'
        fill='url(#paint2790_linear_3150_15389)'
      />
      <path
        d='M411.572 389.35L408.873 392.395L406.384 390.286L409.23 387.133L411.572 389.35Z'
        fill='url(#paint2791_linear_3150_15389)'
      />
      <path
        d='M482.24 271.204L479.488 270.8L479.142 266.563L482.072 266.879L482.24 271.204Z'
        fill='url(#paint2792_linear_3150_15389)'
      />
      <path
        d='M441.335 395.833L438.594 398.326L437.229 396.266L440.001 393.646L441.335 395.833Z'
        fill='url(#paint2793_linear_3150_15389)'
      />
      <path
        d='M520.288 255.962L517.883 256.021L517.474 251.686L520.015 251.568L520.288 255.962Z'
        fill='url(#paint2794_linear_3150_15389)'
      />
      <path
        d='M486.829 397.173L484.435 398.632L484.781 396.129L487.123 394.553L486.829 397.173Z'
        fill='url(#paint2795_linear_3150_15389)'
      />
      <path
        d='M513.021 382.798L511.424 383.389L512.359 380.601L513.903 379.881L513.021 382.798Z'
        fill='url(#paint2796_linear_3150_15389)'
      />
      <path
        d='M456.3 398.139L453.549 400.296L452.761 398.188L455.46 395.823L456.3 398.139Z'
        fill='url(#paint2797_linear_3150_15389)'
      />
      <path
        d='M500.639 393.212L498.549 394.197L499.316 391.586L501.248 390.384L500.639 393.212Z'
        fill='url(#paint2798_linear_3150_15389)'
      />
      <path
        d='M574.415 257.686L574.436 259.509V255.784L574.583 253.755L574.415 257.686Z'
        fill='url(#paint2799_linear_3150_15389)'
      />
      <path
        d='M539.832 249.587L537.973 250.08L537.448 245.784L539.412 245.183L539.832 249.587Z'
        fill='url(#paint2800_linear_3150_15389)'
      />
      <path
        d='M534.025 373.576L533.174 373.842L534.224 371.083L535.127 370.728L534.025 373.576Z'
        fill='url(#paint2801_linear_3150_15389)'
      />
      <path
        d='M491.796 262.997L488.782 262.642L488.604 258.297L491.796 258.632V262.997Z'
        fill='url(#paint2802_linear_3150_15389)'
      />
      <path
        d='M471.864 270.81L468.955 270.268L468.608 266.002L471.57 266.465L471.864 270.81Z'
        fill='url(#paint2803_linear_3150_15389)'
      />
      <path
        d='M510.637 255.134L508.096 255.006L507.676 250.651L510.417 250.73L510.637 255.134Z'
        fill='url(#paint2804_linear_3150_15389)'
      />
      <path
        d='M461.268 270.416L458.054 269.766L457.76 265.45L460.995 266.041L461.268 270.416Z'
        fill='url(#paint2805_linear_3150_15389)'
      />
      <path
        d='M403.528 299.118L398.393 299.305L398.403 294.989L403.57 294.94L403.528 299.118Z'
        fill='url(#paint2806_linear_3150_15389)'
      />
      <path
        d='M469.9 400.06L467.233 401.922L466.854 399.715L469.438 397.666L469.9 400.06Z'
        fill='url(#paint2807_linear_3150_15389)'
      />
      <path
        d='M523.218 379.438L521.979 379.724L522.966 376.935L524.142 376.521L523.218 379.438Z'
        fill='url(#paint2808_linear_3150_15389)'
      />
      <path
        d='M428.838 279.214L424.343 278.505L424.626 274.12L429.216 274.879L428.838 279.214Z'
        fill='url(#paint2809_linear_3150_15389)'
      />
      <path
        d='M529.54 248.198L527.324 248.326L526.715 243.941L529.204 243.774L529.54 248.198Z'
        fill='url(#paint2810_linear_3150_15389)'
      />
      <path
        d='M509.891 388.197L508.022 388.848L508.852 386.128L510.521 385.301L509.891 388.197Z'
        fill='url(#paint2811_linear_3150_15389)'
      />
      <path
        d='M481.956 262.701L479.016 262.189L478.722 257.903L481.872 258.346L481.956 262.701Z'
        fill='url(#paint2812_linear_3150_15389)'
      />
      <path
        d='M484.025 403.804L481.547 405.4L481.631 403.085L484.025 401.341V403.804Z'
        fill='url(#paint2813_linear_3150_15389)'
      />
      <path
        d='M450.493 270.219L446.88 269.391L446.712 265.056L450.367 265.834L450.493 270.219Z'
        fill='url(#paint2814_linear_3150_15389)'
      />
      <path
        d='M501.122 254.602L498.182 254.228L497.951 249.863L500.954 250.198L501.122 254.602Z'
        fill='url(#paint2815_linear_3150_15389)'
      />
      <path
        d='M560.647 245.38L560.153 246.67L559.702 242.729L560.195 241.192L560.647 245.38Z'
        fill='url(#paint2816_linear_3150_15389)'
      />
      <path
        d='M544.631 370.954L544.222 370.964L545.272 368.304L545.724 368.176L544.631 370.954Z'
        fill='url(#paint2817_linear_3150_15389)'
      />
      <path
        d='M417.947 288.496L413.106 287.934L413.41 283.599L418.294 284.249L417.947 288.496Z'
        fill='url(#paint2818_linear_3150_15389)'
      />
      <path
        d='M494.958 399.686L492.595 400.937L492.962 398.484L495.126 397.025L494.958 399.686Z'
        fill='url(#paint2819_linear_3150_15389)'
      />
      <path
        d='M385.108 322.646L380.14 324.262L378.723 320.36L383.816 318.823L385.108 322.646Z'
        fill='url(#paint2820_linear_3150_15389)'
      />
      <path
        d='M383.448 333.642L378.838 335.78L376.853 332.125L381.642 330.085L383.448 333.642Z'
        fill='url(#paint2821_linear_3150_15389)'
      />
      <path
        d='M549.2 242.69L547.982 243.449L547.257 239.261L548.622 238.345L549.2 242.69Z'
        fill='url(#paint2822_linear_3150_15389)'
      />
      <path
        d='M442.522 398.917L439.875 401.41L438.447 399.429L441.188 396.927L442.522 398.917Z'
        fill='url(#paint2823_linear_3150_15389)'
      />
      <path
        d='M571.873 248.897L572.031 250.651L571.768 246.936L571.779 244.966L571.873 248.897Z'
        fill='url(#paint2824_linear_3150_15389)'
      />
      <path
        d='M393.908 380.551L391.272 383.773L387.87 381.714L390.695 378.384L393.908 380.551Z'
        fill='url(#paint2825_linear_3150_15389)'
      />
      <path
        d='M427.693 396.425L425.099 399.134L423.135 397.321L425.782 394.474L427.693 396.425Z'
        fill='url(#paint2826_linear_3150_15389)'
      />
      <path
        d='M491.734 254.316L488.541 253.774L488.425 249.4L491.723 249.922L491.734 254.316Z'
        fill='url(#paint2827_linear_3150_15389)'
      />
      <path
        d='M531.315 376.216L530.317 376.275L531.263 373.526L532.208 373.339L531.315 376.216Z'
        fill='url(#paint2828_linear_3150_15389)'
      />
      <path
        d='M382.104 344.982L377.945 347.564L375.404 344.273L379.783 341.751L382.104 344.982Z'
        fill='url(#paint2829_linear_3150_15389)'
      />
      <path
        d='M412.906 394.72L410.459 397.715L407.886 395.961L410.449 392.828L412.906 394.72Z'
        fill='url(#paint2830_linear_3150_15389)'
      />
      <path
        d='M471.423 262.504L468.451 261.795L468.031 257.548L471.171 258.159L471.423 262.504Z'
        fill='url(#paint2831_linear_3150_15389)'
      />
      <path
        d='M388.888 310.763L383.637 311.67L382.944 307.571L388.258 306.773L388.888 310.763Z'
        fill='url(#paint2832_linear_3150_15389)'
      />
      <path
        d='M519.753 247.282L517.222 247.084L516.696 242.72L519.406 242.858L519.753 247.282Z'
        fill='url(#paint2833_linear_3150_15389)'
      />
      <path
        d='M505.071 394.651L502.908 395.538L503.443 392.897L505.397 391.843L505.071 394.651Z'
        fill='url(#paint2834_linear_3150_15389)'
      />
      <path
        d='M454.221 401.794L451.574 404.05L450.419 402.09L453.076 399.745L454.221 401.794Z'
        fill='url(#paint2835_linear_3150_15389)'
      />
      <path
        d='M519.574 383.359L518.041 383.615L518.902 380.798L520.246 380.403L519.574 383.359Z'
        fill='url(#paint2836_linear_3150_15389)'
      />
      <path
        d='M440.295 270.367L436.126 269.293L436.283 264.968L440.484 266.051L440.295 270.367Z'
        fill='url(#paint2837_linear_3150_15389)'
      />
      <path
        d='M466.623 405.933L464.009 407.992L463.221 405.962L465.815 403.834L466.623 405.933Z'
        fill='url(#paint2838_linear_3150_15389)'
      />
      <path
        d='M481.914 254.149L478.753 253.508L478.564 249.173L481.893 249.774L481.914 254.149Z'
        fill='url(#paint2839_linear_3150_15389)'
      />
      <path
        d='M538.467 240.877L536.598 241.084L535.778 236.778L537.868 236.473L538.467 240.877Z'
        fill='url(#paint2840_linear_3150_15389)'
      />
      <path
        d='M510.248 246.641L507.507 246.227L507.066 241.862L509.933 242.217L510.248 246.641Z'
        fill='url(#paint2841_linear_3150_15389)'
      />
      <path
        d='M460.711 262.356L457.476 261.43L457.077 257.164L460.385 258.011L460.711 262.356Z'
        fill='url(#paint2842_linear_3150_15389)'
      />
      <path
        d='M418.955 279.835L414.072 278.929L414.586 274.623L419.512 275.628L418.955 279.835Z'
        fill='url(#paint2843_linear_3150_15389)'
      />
      <path
        d='M380.686 356.786L377.095 359.752L373.934 356.964L377.746 353.978L380.686 356.786Z'
        fill='url(#paint2844_linear_3150_15389)'
      />
      <path
        d='M445.199 401.449L442.553 403.873L441.261 401.903L443.992 399.508L445.199 401.449Z'
        fill='url(#paint2845_linear_3150_15389)'
      />
      <path
        d='M541.586 372.984L541.113 372.728L541.985 370.038L542.51 370.186L541.586 372.984Z'
        fill='url(#paint2846_linear_3150_15389)'
      />
      <path
        d='M407.498 289.245L402.289 288.674L402.761 284.407L407.939 285.126L407.498 289.245Z'
        fill='url(#paint2847_linear_3150_15389)'
      />
      <path
        d='M392.49 300.162L387.103 300.408L386.987 296.211L392.406 296.132L392.49 300.162Z'
        fill='url(#paint2848_linear_3150_15389)'
      />
      <path
        d='M515.226 389.301L513.304 389.744L514.019 386.916L515.646 386.335L515.226 389.301Z'
        fill='url(#paint2849_linear_3150_15389)'
      />
      <path
        d='M528.543 379.753L527.408 379.694L528.217 376.847L529.309 376.817L528.543 379.753Z'
        fill='url(#paint2850_linear_3150_15389)'
      />
      <path
        d='M557.969 237.143L557.78 238.325L556.95 234.384L557.297 232.995L557.969 237.143Z'
        fill='url(#paint2851_linear_3150_15389)'
      />
      <path
        d='M480.381 405.499L477.797 407.154L477.493 404.848L479.961 403.104L480.381 405.499Z'
        fill='url(#paint2852_linear_3150_15389)'
      />
      <path
        d='M489.749 402.907L487.249 404.346L487.102 401.893L489.486 400.375L489.749 402.907Z'
        fill='url(#paint2853_linear_3150_15389)'
      />
      <path
        d='M500.944 246.227L497.94 245.587L497.625 241.232L500.755 241.833L500.944 246.227Z'
        fill='url(#paint2854_linear_3150_15389)'
      />
      <path
        d='M471.507 254.139L468.346 253.301L468.031 249.035L471.391 249.784L471.507 254.139Z'
        fill='url(#paint2855_linear_3150_15389)'
      />
      <path
        d='M430.455 270.81L425.855 269.579L426.349 265.283L430.948 266.583L430.455 270.81Z'
        fill='url(#paint2856_linear_3150_15389)'
      />
      <path
        d='M500.219 399.942L497.835 401.075L497.982 398.434L500.188 397.223L500.219 399.942Z'
        fill='url(#paint2857_linear_3150_15389)'
      />
      <path
        d='M450.377 262.297L446.701 261.135L446.491 256.868L450.209 257.982L450.377 262.297Z'
        fill='url(#paint2858_linear_3150_15389)'
      />
      <path
        d='M528.91 239.793L526.442 239.557L525.791 235.212L528.364 235.369L528.91 239.793Z'
        fill='url(#paint2859_linear_3150_15389)'
      />
      <path
        d='M528.154 413.598L526.495 414.278L527.545 411.617L529.068 410.819L528.154 413.598Z'
        fill='url(#paint2860_linear_3150_15389)'
      />
      <path
        d='M530.475 425.244L528.889 426.2L530.076 423.717L531.641 422.692L530.475 425.244Z'
        fill='url(#paint2861_linear_3150_15389)'
      />
      <path
        d='M511.729 416.514L509.587 417.776L509.975 415.243L512.139 413.982L511.729 416.514Z'
        fill='url(#paint2862_linear_3150_15389)'
      />
      <path
        d='M511.183 396.562L509.041 397.321L509.461 394.503L511.435 393.665L511.183 396.562Z'
        fill='url(#paint2863_linear_3150_15389)'
      />
      <path
        d='M428.701 398.434L426.181 401.104L424.175 399.41L426.727 396.641L428.701 398.434Z'
        fill='url(#paint2864_linear_3150_15389)'
      />
      <path
        d='M452.603 406.701L450.051 409.036L448.655 407.263L451.196 404.859L452.603 406.701Z'
        fill='url(#paint2865_linear_3150_15389)'
      />
      <path
        d='M394.948 388.296L392.827 391.557L389.182 389.961L391.524 386.611L394.948 388.296Z'
        fill='url(#paint2866_linear_3150_15389)'
      />
      <path
        d='M513.914 427.963L511.792 429.402L512.38 427.116L514.47 425.628L513.914 427.963Z'
        fill='url(#paint2867_linear_3150_15389)'
      />
      <path
        d='M510.017 405.568L507.822 406.612L508.116 403.893L510.259 402.829L510.017 405.568Z'
        fill='url(#paint2868_linear_3150_15389)'
      />
      <path
        d='M547.12 234.552L545.955 235.113L545.031 230.955L546.133 230.216L547.12 234.552Z'
        fill='url(#paint2869_linear_3150_15389)'
      />
      <path
        d='M534.046 439.452L532.449 440.605L533.993 438.496L535.432 437.117L534.046 439.452Z'
        fill='url(#paint2870_linear_3150_15389)'
      />
      <path
        d='M527.23 402.07L525.655 402.395L526.61 399.538L528.018 399.114L527.23 402.07Z'
        fill='url(#paint2871_linear_3150_15389)'
      />
      <path
        d='M519.647 238.976L516.938 238.532L516.392 234.158L519.196 234.532L519.647 238.976Z'
        fill='url(#paint2872_linear_3150_15389)'
      />
      <path
        d='M518.282 443.472L516.287 445.127L517.222 443.206L519.228 441.491L518.282 443.472Z'
        fill='url(#paint2873_linear_3150_15389)'
      />
      <path
        d='M491.849 246.04L488.541 245.193L488.446 240.857L491.818 241.695L491.849 246.04Z'
        fill='url(#paint2874_linear_3150_15389)'
      />
      <path
        d='M413.883 397.321L411.572 400.208L408.989 398.75L411.299 395.626L413.883 397.321Z'
        fill='url(#paint2875_linear_3150_15389)'
      />
      <path
        d='M444.948 407.381L442.364 409.815L440.988 408.08L443.582 405.578L444.948 407.381Z'
        fill='url(#paint2876_linear_3150_15389)'
      />
      <path
        d='M537.186 420.505L535.527 421.184L536.808 418.702L538.152 417.785L537.186 420.505Z'
        fill='url(#paint2877_linear_3150_15389)'
      />
      <path
        d='M534.172 434.88L532.271 436.141L533.342 434.033L535.075 432.604L534.172 434.88Z'
        fill='url(#paint2878_linear_3150_15389)'
      />
      <path
        d='M526.568 392.602L525.109 392.671L525.949 389.715L527.282 389.567L526.568 392.602Z'
        fill='url(#paint2879_linear_3150_15389)'
      />
      <path
        d='M465.867 407.223L463.242 409.262L462.286 407.213L464.87 405.124L465.867 407.223Z'
        fill='url(#paint2880_linear_3150_15389)'
      />
      <path
        d='M379.164 369.24L376.423 372.551L372.463 370.413L375.509 367.063L379.164 369.24Z'
        fill='url(#paint2881_linear_3150_15389)'
      />
      <path
        d='M497.467 406.868L495.01 408.238L494.873 405.657L497.257 404.267L497.467 406.868Z'
        fill='url(#paint2882_linear_3150_15389)'
      />
      <path
        d='M526.337 384.522L524.983 384.404L525.76 381.448L526.967 381.468L526.337 384.522Z'
        fill='url(#paint2883_linear_3150_15389)'
      />
      <path
        d='M409.315 280.732L404.137 279.717L404.851 275.51L410.029 276.663L409.315 280.732Z'
        fill='url(#paint2884_linear_3150_15389)'
      />
      <path
        d='M538.782 376.246L538.131 375.783L538.95 373.004L539.538 373.349L538.782 376.246Z'
        fill='url(#paint2885_linear_3150_15389)'
      />
      <path
        d='M460.742 254.326L457.413 253.213L457.035 248.996L460.511 250.001L460.742 254.326Z'
        fill='url(#paint2886_linear_3150_15389)'
      />
      <path
        d='M420.467 271.49L415.532 270.19L416.309 265.953L421.203 267.372L420.467 271.49Z'
        fill='url(#paint2887_linear_3150_15389)'
      />
      <path
        d='M510.259 238.325L507.392 237.734L506.93 233.34L509.902 233.882L510.259 238.325Z'
        fill='url(#paint2888_linear_3150_15389)'
      />
      <path
        d='M496.48 416.967L494.023 418.603L493.771 416.248L496.207 414.603L496.48 416.967Z'
        fill='url(#paint2889_linear_3150_15389)'
      />
      <path
        d='M551.762 369.378L551.846 368.757L552.613 366.205L552.55 366.688L551.762 369.378Z'
        fill='url(#paint2890_linear_3150_15389)'
      />
      <path
        d='M396.555 290.496L391.104 290.033L391.545 285.865L396.975 286.496L396.555 290.496Z'
        fill='url(#paint2891_linear_3150_15389)'
      />
      <path
        d='M441.135 262.553L436.914 261.105L437.134 256.878L441.387 258.336L441.135 262.553Z'
        fill='url(#paint2892_linear_3150_15389)'
      />
      <path
        d='M537.994 232.709L535.957 232.748L535.096 228.413L537.112 228.255L537.994 232.709Z'
        fill='url(#paint2893_linear_3150_15389)'
      />
      <path
        d='M482.051 245.991L478.701 245.045L478.596 240.739L482.061 241.655L482.051 245.991Z'
        fill='url(#paint2894_linear_3150_15389)'
      />
      <path
        d='M538.971 407.499L537.648 407.637L538.908 404.849L539.916 404.553L538.971 407.499Z'
        fill='url(#paint2895_linear_3150_15389)'
      />
      <path
        d='M496.47 429.146L494.054 431.037L493.792 429.008L496.218 427.126L496.47 429.146Z'
        fill='url(#paint2896_linear_3150_15389)'
      />
      <path
        d='M374.154 323.602L369.176 325.385L367.338 321.7L372.484 320.025L374.154 323.602Z'
        fill='url(#paint2897_linear_3150_15389)'
      />
      <path
        d='M488.509 407.213L486.01 408.741L485.663 406.208L488.163 404.701L488.509 407.213Z'
        fill='url(#paint2898_linear_3150_15389)'
      />
      <path
        d='M542.899 428.21L541.071 429.136L542.321 426.83L543.802 425.678L542.899 428.21Z'
        fill='url(#paint2899_linear_3150_15389)'
      />
      <path
        d='M489.549 429.56L487.081 431.471L486.85 429.471L489.287 427.52L489.549 429.56Z'
        fill='url(#paint2900_linear_3150_15389)'
      />
      <path
        d='M489.465 416.524L486.997 418.199L486.703 415.805L489.202 414.179L489.465 416.524Z'
        fill='url(#paint2901_linear_3150_15389)'
      />
      <path
        d='M377.567 312.389L372.243 313.493L371.172 309.591L376.591 308.616L377.567 312.389Z'
        fill='url(#paint2902_linear_3150_15389)'
      />
      <path
        d='M499.232 443.886L496.932 445.975L496.848 444.319L499.242 442.28L499.232 443.886Z'
        fill='url(#paint2903_linear_3150_15389)'
      />
      <path
        d='M529.162 231.438L526.6 231.113L525.896 226.689L528.511 226.925L529.162 231.438Z'
        fill='url(#paint2904_linear_3150_15389)'
      />
      <path
        d='M530.885 450.783L529.078 452.3L530.433 450.566L532.145 448.861L530.885 450.783Z'
        fill='url(#paint2905_linear_3150_15389)'
      />
      <path
        d='M538.362 396.927L537.291 396.681L538.362 393.754L539.244 393.892L538.362 396.927Z'
        fill='url(#paint2906_linear_3150_15389)'
      />
      <path
        d='M471.717 246.03L468.335 244.976L468.167 240.719L471.654 241.724L471.717 246.03Z'
        fill='url(#paint2907_linear_3150_15389)'
      />
      <path
        d='M372.957 333.602L368.389 335.869L365.984 332.489L370.773 330.312L372.957 333.602Z'
        fill='url(#paint2908_linear_3150_15389)'
      />
      <path
        d='M519.994 230.384L517.19 229.931L516.633 225.438L519.448 225.822L519.994 230.384Z'
        fill='url(#paint2909_linear_3150_15389)'
      />
      <path
        d='M522.756 453.601L520.897 455.276L522.178 453.571L524.037 451.807L522.756 453.601Z'
        fill='url(#paint2910_linear_3150_15389)'
      />
      <path
        d='M539.087 442.063L537.081 443.57L537.963 441.669L539.864 440.063L539.087 442.063Z'
        fill='url(#paint2911_linear_3150_15389)'
      />
      <path
        d='M490.169 439.698L487.879 441.689L488.226 439.639L490.652 437.807L490.169 439.698Z'
        fill='url(#paint2912_linear_3150_15389)'
      />
      <path
        d='M501.238 238.089L498.098 237.192L497.804 232.847L501.133 233.694L501.238 238.089Z'
        fill='url(#paint2913_linear_3150_15389)'
      />
      <path
        d='M544.716 413.095L543.256 413.401L544.527 410.681L545.671 410.228L544.716 413.095Z'
        fill='url(#paint2914_linear_3150_15389)'
      />
      <path
        d='M478.806 407.115L476.191 408.79L475.613 406.346L478.186 404.681L478.806 407.115Z'
        fill='url(#paint2915_linear_3150_15389)'
      />
      <path
        d='M490.494 442.477L488.121 444.625L487.921 442.95L490.368 440.851L490.494 442.477Z'
        fill='url(#paint2916_linear_3150_15389)'
      />
      <path
        d='M526.715 446.674L524.447 448.418L524.972 446.861L527.041 444.92L526.715 446.674Z'
        fill='url(#paint2917_linear_3150_15389)'
      />
      <path
        d='M536.766 380.847L535.989 380.226L536.745 377.32L537.48 377.812L536.766 380.847Z'
        fill='url(#paint2918_linear_3150_15389)'
      />
      <path
        d='M429.741 400.365L427.283 403.016L425.246 401.39L427.745 398.671L429.741 400.365Z'
        fill='url(#paint2919_linear_3150_15389)'
      />
      <path
        d='M450.682 254.671L446.943 253.223L446.743 249.055L450.566 250.424L450.682 254.671Z'
        fill='url(#paint2920_linear_3150_15389)'
      />
      <path
        d='M381.884 301.689L376.36 302.063L376.034 298.034L381.6 297.837L381.884 301.689Z'
        fill='url(#paint2921_linear_3150_15389)'
      />
      <path
        d='M537.249 387.96L536.283 387.448L537.217 384.473L537.963 384.896L537.249 387.96Z'
        fill='url(#paint2922_linear_3150_15389)'
      />
      <path
        d='M480.307 427.638L477.86 429.727L477.335 427.609L479.95 425.707L480.307 427.638Z'
        fill='url(#paint2923_linear_3150_15389)'
      />
      <path
        d='M396.891 393.774L395.084 396.976L391.503 395.715L393.467 392.394L396.891 393.774Z'
        fill='url(#paint2924_linear_3150_15389)'
      />
      <path
        d='M508.001 454.369L505.869 456.35L506.541 454.803L508.746 452.832L508.001 454.369Z'
        fill='url(#paint2925_linear_3150_15389)'
      />
      <path
        d='M410.722 272.525L405.555 271.145L406.531 267.007L411.677 268.524L410.722 272.525Z'
        fill='url(#paint2926_linear_3150_15389)'
      />
      <path
        d='M452.561 407.834L450.009 410.14L448.497 408.356L451.028 405.992L452.561 407.834Z'
        fill='url(#paint2927_linear_3150_15389)'
      />
      <path
        d='M432.188 263.204L427.577 261.499L428.229 257.361L432.849 259.095L432.188 263.204Z'
        fill='url(#paint2928_linear_3150_15389)'
      />
      <path
        d='M564.711 435.254L563.913 435.786L565.751 433.471L566.391 432.752L564.711 435.254Z'
        fill='url(#paint2929_linear_3150_15389)'
      />
      <path
        d='M553.716 230.246L553.779 231.162L552.613 227.3L552.644 226.167L553.716 230.246Z'
        fill='url(#paint2930_linear_3150_15389)'
      />
      <path
        d='M557.78 419.943L556.751 420.17L558.221 417.549L559.072 417.184L557.78 419.943Z'
        fill='url(#paint2931_linear_3150_15389)'
      />
      <path
        d='M503.307 451.975L501.259 453.965L502.078 452.152L504.294 450.329L503.307 451.975Z'
        fill='url(#paint2932_linear_3150_15389)'
      />
      <path
        d='M477.703 415.677L475.109 417.549L474.405 415.263L476.999 413.421L477.703 415.677Z'
        fill='url(#paint2933_linear_3150_15389)'
      />
      <path
        d='M371.896 343.84L367.843 346.559L364.892 343.524L369.229 340.923L371.896 343.84Z'
        fill='url(#paint2934_linear_3150_15389)'
      />
      <path
        d='M399.275 281.944L393.856 280.959L394.685 276.84L400.062 278.013L399.275 281.944Z'
        fill='url(#paint2935_linear_3150_15389)'
      />
      <path
        d='M510.773 229.832L507.791 229.093L507.308 224.62L510.542 225.31L510.773 229.832Z'
        fill='url(#paint2936_linear_3150_15389)'
      />
      <path
        d='M504.651 447.797L502.939 449.462L504.325 447.275L506.184 445.767L504.651 447.797Z'
        fill='url(#paint2937_linear_3150_15389)'
      />
      <path
        d='M380.14 378.472L378.061 381.891L373.818 380.275L376.213 376.807L380.14 378.472Z'
        fill='url(#paint2938_linear_3150_15389)'
      />
      <path
        d='M546.732 371.999L546.48 371.112L547.194 368.432L547.32 369.181L546.732 371.999Z'
        fill='url(#paint2939_linear_3150_15389)'
      />
      <path
        d='M444.569 409.016L442.007 411.42L440.495 409.755L443.015 407.253L444.569 409.016Z'
        fill='url(#paint2940_linear_3150_15389)'
      />
      <path
        d='M544.527 400.918L543.319 400.691L544.485 397.755L545.346 397.883L544.527 400.918Z'
        fill='url(#paint2941_linear_3150_15389)'
      />
      <path
        d='M461.32 246.404L457.812 245.074L457.581 240.906L461.278 242.128L461.32 246.404Z'
        fill='url(#paint2942_linear_3150_15389)'
      />
      <path
        d='M492.311 238.295L488.919 237.113L488.835 232.857L492.385 234.009L492.311 238.295Z'
        fill='url(#paint2943_linear_3150_15389)'
      />
      <path
        d='M571.569 428.328L570.886 428.545L572.651 426.141L573.102 425.717L571.569 428.328Z'
        fill='url(#paint2944_linear_3150_15389)'
      />
      <path
        d='M614.333 334.942L613.745 335.77L614.49 334.371L615.057 333.287L614.333 334.942Z'
        fill='url(#paint2945_linear_3150_15389)'
      />
      <path
        d='M610.92 339.337L610.258 340.125L610.941 338.795L611.592 337.8L610.92 339.337Z'
        fill='url(#paint2946_linear_3150_15389)'
      />
      <path
        d='M414.24 398.681L412.129 401.548L409.451 400.257L411.583 397.213L414.24 398.681Z'
        fill='url(#paint2947_linear_3150_15389)'
      />
      <path
        d='M465.321 407.637L462.633 409.637L461.498 407.459L464.103 405.43L465.321 407.637Z'
        fill='url(#paint2948_linear_3150_15389)'
      />
      <path
        d='M543.665 227.871L542.867 228.226L541.554 224.157L542.279 223.635L543.665 227.871Z'
        fill='url(#paint2949_linear_3150_15389)'
      />
      <path
        d='M536.293 451.679L534.14 453.462L534.781 451.925L536.85 450.053L536.293 451.679Z'
        fill='url(#paint2950_linear_3150_15389)'
      />
      <path
        d='M563.777 445.492L562.737 446.349L564.648 444.27L565.499 443.196L563.777 445.492Z'
        fill='url(#paint2951_linear_3150_15389)'
      />
      <path
        d='M385.99 292.141L380.403 291.826L380.697 287.786L386.284 288.309L385.99 292.141Z'
        fill='url(#paint2952_linear_3150_15389)'
      />
      <path
        d='M487.26 444.9L485.012 446.891L485.674 444.782L488.068 442.989L487.26 444.9Z'
        fill='url(#paint2953_linear_3150_15389)'
      />
      <path
        d='M474.122 435.412L471.622 437.678L471.16 435.678L473.849 433.659L474.122 435.412Z'
        fill='url(#paint2954_linear_3150_15389)'
      />
      <path
        d='M442.039 255.124L437.775 253.361L438.1 249.252L442.375 251.016L442.039 255.124Z'
        fill='url(#paint2955_linear_3150_15389)'
      />
      <path
        d='M544.506 391.399L543.571 390.769L544.59 387.773L545.199 388.335L544.506 391.399Z'
        fill='url(#paint2956_linear_3150_15389)'
      />
      <path
        d='M370.31 355.358L366.971 358.481L363.379 355.969L367.034 352.885L370.31 355.358Z'
        fill='url(#paint2957_linear_3150_15389)'
      />
      <path
        d='M553.159 406.07L552.056 405.844L553.306 402.966L554.115 403.085L553.159 406.07Z'
        fill='url(#paint2958_linear_3150_15389)'
      />
      <path
        d='M607.969 342.697L607.275 343.455L607.895 342.165L608.599 341.268L607.969 342.697Z'
        fill='url(#paint2959_linear_3150_15389)'
      />
      <path
        d='M565.184 414.041L564.375 413.923L565.793 411.243L566.423 411.224L565.184 414.041Z'
        fill='url(#paint2960_linear_3150_15389)'
      />
      <path
        d='M616.349 328.499L615.887 329.356L616.601 327.868L617.063 326.775L616.349 328.499Z'
        fill='url(#paint2961_linear_3150_15389)'
      />
      <path
        d='M422.999 264.041L418.105 262.208L419.102 258.149L423.954 260.09L422.999 264.041Z'
        fill='url(#paint2962_linear_3150_15389)'
      />
      <path
        d='M429.426 405.883L427.168 408.632L424.983 407.154L427.357 404.444L429.426 405.883Z'
        fill='url(#paint2963_linear_3150_15389)'
      />
      <path
        d='M520.782 457.601L518.839 459.374L519.91 457.837L521.853 456.005L520.782 457.601Z'
        fill='url(#paint2964_linear_3150_15389)'
      />
      <path
        d='M543.749 376.807L543.182 375.871L543.917 373.014L544.274 373.851L543.749 376.807Z'
        fill='url(#paint2965_linear_3150_15389)'
      />
      <path
        d='M520.897 455.975L518.724 457.867L519.259 456.527L521.359 454.517L520.897 455.975Z'
        fill='url(#paint2966_linear_3150_15389)'
      />
      <path
        d='M543.707 383.488L543.025 382.621L543.812 379.675L544.359 380.443L543.707 383.488Z'
        fill='url(#paint2967_linear_3150_15389)'
      />
      <path
        d='M516.529 457.591L514.701 459.364L515.951 457.67L517.852 455.926L516.529 457.591Z'
        fill='url(#paint2968_linear_3150_15389)'
      />
      <path
        d='M606.53 346.066L606.141 346.579L606.95 345.229L607.286 344.48L606.53 346.066Z'
        fill='url(#paint2969_linear_3150_15389)'
      />
      <path
        d='M574.604 439.048L574.037 439.56L575.927 437.284L576.421 436.653L574.604 439.048Z'
        fill='url(#paint2970_linear_3150_15389)'
      />
      <path
        d='M535.904 225.664L534.056 225.546L532.953 221.28L534.55 221.299L535.904 225.664Z'
        fill='url(#paint2971_linear_3150_15389)'
      />
      <path
        d='M482.544 238.601L479.058 237.271L479.026 233.083L482.618 234.384L482.544 238.601Z'
        fill='url(#paint2972_linear_3150_15389)'
      />
      <path
        d='M502.088 230.039L498.749 228.876L498.57 224.531L502.12 225.655L502.088 230.039Z'
        fill='url(#paint2973_linear_3150_15389)'
      />
      <path
        d='M612.096 336.844L611.245 337.681L611.728 336.45L612.568 335.455L612.096 336.844Z'
        fill='url(#paint2974_linear_3150_15389)'
      />
      <path
        d='M613.797 332.203L612.988 333.07L613.461 331.78L614.27 330.745L613.797 332.203Z'
        fill='url(#paint2975_linear_3150_15389)'
      />
      <path
        d='M528.574 223.703L526.012 223.25L525.193 218.836L527.619 219.22L528.574 223.703Z'
        fill='url(#paint2976_linear_3150_15389)'
      />
      <path
        d='M401.984 273.953L396.649 272.445L397.825 268.455L403.139 270.12L401.984 273.953Z'
        fill='url(#paint2977_linear_3150_15389)'
      />
      <path
        d='M610.048 340.667L609.176 341.455L609.638 340.273L610.521 339.337L610.048 340.667Z'
        fill='url(#paint2978_linear_3150_15389)'
      />
      <path
        d='M519.753 222.413L516.97 221.762L516.245 217.279L519.101 217.87L519.753 222.413Z'
        fill='url(#paint2979_linear_3150_15389)'
      />
      <path
        d='M600.764 347.347L600.208 347.82L600.869 346.5L601.373 345.869L600.764 347.347Z'
        fill='url(#paint2980_linear_3150_15389)'
      />
      <path
        d='M451.406 247.134L447.552 245.459L447.489 241.37L451.438 242.966L451.406 247.134Z'
        fill='url(#paint2981_linear_3150_15389)'
      />
      <path
        d='M579.162 419.815L578.773 419.559L580.412 417.056L580.548 417.125L579.162 419.815Z'
        fill='url(#paint2982_linear_3150_15389)'
      />
      <path
        d='M603.264 342.598L602.507 343.199L603.022 341.938L603.757 341.199L603.264 342.598Z'
        fill='url(#paint2983_linear_3150_15389)'
      />
      <path
        d='M614.595 326.558L613.818 327.444L614.249 326.085L615.047 325.04L614.595 326.558Z'
        fill='url(#paint2984_linear_3150_15389)'
      />
      <path
        d='M463.452 414.11L460.847 416.307L459.461 414.218L462.076 412.08L463.452 414.11Z'
        fill='url(#paint2985_linear_3150_15389)'
      />
      <path
        d='M557.497 452.802L555.974 454.093L557.581 452.241L558.977 450.812L557.497 452.802Z'
        fill='url(#paint2986_linear_3150_15389)'
      />
      <path
        d='M433.7 255.765L429.079 253.755L429.94 249.745L434.551 251.814L433.7 255.765Z'
        fill='url(#paint2987_linear_3150_15389)'
      />
      <path
        d='M388.951 283.639L383.385 282.762L384.152 278.762L389.687 279.865L388.951 283.639Z'
        fill='url(#paint2988_linear_3150_15389)'
      />
      <path
        d='M466.445 421.835L463.893 424.101L462.685 421.963L465.405 419.924L466.445 421.835Z'
        fill='url(#paint2989_linear_3150_15389)'
      />
      <path
        d='M550.271 395.035L549.284 394.385L550.303 391.36L551.017 391.931L550.271 395.035Z'
        fill='url(#paint2990_linear_3150_15389)'
      />
      <path
        d='M500.314 450.152L498.686 451.718L500.198 449.482L501.952 448.043L500.314 450.152Z'
        fill='url(#paint2991_linear_3150_15389)'
      />
      <path
        d='M515.016 454.172L512.706 456.369L512.107 455.384L514.365 453.108L515.016 454.172Z'
        fill='url(#paint2992_linear_3150_15389)'
      />
      <path
        d='M381.978 385.911L380.424 389.32L376.16 388.128L377.987 384.65L381.978 385.911Z'
        fill='url(#paint2993_linear_3150_15389)'
      />
      <path
        d='M551.521 367.565L551.458 366.383L552.004 363.782L551.899 364.846L551.521 367.565Z'
        fill='url(#paint2994_linear_3150_15389)'
      />
      <path
        d='M472.095 238.897L468.577 237.458L468.461 233.32L472.084 234.699L472.095 238.897Z'
        fill='url(#paint2995_linear_3150_15389)'
      />
      <path
        d='M452.435 408.494L449.852 410.79L448.203 408.928L450.724 406.592L452.435 408.494Z'
        fill='url(#paint2996_linear_3150_15389)'
      />
      <path
        d='M414.083 265.086L408.979 263.135L410.333 259.184L415.385 261.273L414.083 265.086Z'
        fill='url(#paint2997_linear_3150_15389)'
      />
      <path
        d='M397.762 397.814L396.271 400.917L392.732 400.041L394.234 396.71L397.762 397.814Z'
        fill='url(#paint2998_linear_3150_15389)'
      />
      <path
        d='M369.418 366.826L366.96 370.255L362.76 368.354L365.595 364.935L369.418 366.826Z'
        fill='url(#paint2999_linear_3150_15389)'
      />
      <path
        d='M511.351 221.782L508.106 220.777L507.759 216.304L511.057 217.279L511.351 221.782Z'
        fill='url(#paint3000_linear_3150_15389)'
      />
      <path
        d='M614.522 334.824L613.461 335.711L613.776 334.578L614.847 333.563L614.522 334.824Z'
        fill='url(#paint3001_linear_3150_15389)'
      />
      <path
        d='M443.971 409.913L441.44 412.337L439.728 410.662L442.217 408.179L443.971 409.913Z'
        fill='url(#paint3002_linear_3150_15389)'
      />
      <path
        d='M615.624 330.637L614.574 331.563L614.858 330.39L615.939 329.326L615.624 330.637Z'
        fill='url(#paint3003_linear_3150_15389)'
      />
      <path
        d='M572.672 406.859L572.157 406.228L573.501 403.509L573.753 404.011L572.672 406.859Z'
        fill='url(#paint3004_linear_3150_15389)'
      />
      <path
        d='M558.284 399.942L557.276 399.36L558.442 396.375L559.114 396.887L558.284 399.942Z'
        fill='url(#paint3005_linear_3150_15389)'
      />
      <path
        d='M614.511 320.222L613.776 321.089L614.133 319.67L614.889 318.636L614.511 320.222Z'
        fill='url(#paint3006_linear_3150_15389)'
      />
      <path
        d='M598.013 348.421L597.761 348.667L598.443 347.249L598.685 346.884L598.013 348.421Z'
        fill='url(#paint3007_linear_3150_15389)'
      />
      <path
        d='M599.073 343.741L598.181 344.253L598.643 343.031L599.462 342.371L599.073 343.741Z'
        fill='url(#paint3008_linear_3150_15389)'
      />
      <path
        d='M493.235 230.473L489.675 229.044L489.759 224.817L493.456 226.236L493.235 230.473Z'
        fill='url(#paint3009_linear_3150_15389)'
      />
      <path
        d='M429.919 409.371L427.693 412.071L425.508 410.75L427.745 407.972L429.919 409.371Z'
        fill='url(#paint3010_linear_3150_15389)'
      />
      <path
        d='M526.253 458.685L524.604 460.36L525.928 458.625L527.692 457.049L526.253 458.685Z'
        fill='url(#paint3011_linear_3150_15389)'
      />
      <path
        d='M606.089 340.509L605.196 341.15L605.574 339.957L606.488 339.209L606.089 340.509Z'
        fill='url(#paint3012_linear_3150_15389)'
      />
      <path
        d='M595.629 346.983L594.988 347.357L595.503 346.037L596.143 345.564L595.629 346.983Z'
        fill='url(#paint3013_linear_3150_15389)'
      />
      <path
        d='M611.718 337.504L610.552 338.302L610.888 337.228L611.97 336.253L611.718 337.504Z'
        fill='url(#paint3014_linear_3150_15389)'
      />
      <path
        d='M367.874 313.966L362.581 315.276L361.111 311.601L366.572 310.488L367.874 313.966Z'
        fill='url(#paint3015_linear_3150_15389)'
      />
      <path
        d='M365.459 324.095L360.565 326.026L358.433 322.577L363.558 320.843L365.459 324.095Z'
        fill='url(#paint3016_linear_3150_15389)'
      />
      <path
        d='M616.024 325.336L614.973 326.272L615.215 325.04L616.286 323.966L616.024 325.336Z'
        fill='url(#paint3017_linear_3150_15389)'
      />
      <path
        d='M520.708 456.606L519.091 458.202L520.498 456.349L522.199 454.812L520.708 456.606Z'
        fill='url(#paint3018_linear_3150_15389)'
      />
      <path
        d='M548.318 385.842L547.446 384.857L548.307 381.783L548.832 382.748L548.318 385.842Z'
        fill='url(#paint3019_linear_3150_15389)'
      />
      <path
        d='M548.906 372.433L548.612 371.122L549.137 368.353L549.295 369.536L548.906 372.433Z'
        fill='url(#paint3020_linear_3150_15389)'
      />
      <path
        d='M415.07 399.814L413.074 402.671L410.365 401.459L412.381 398.464L415.07 399.814Z'
        fill='url(#paint3021_linear_3150_15389)'
      />
      <path
        d='M526.138 457.532L523.764 459.66L523.544 458.616L525.781 456.389L526.138 457.532Z'
        fill='url(#paint3022_linear_3150_15389)'
      />
      <path
        d='M442.952 247.833L438.678 245.814L439.108 241.833L443.425 243.833L442.952 247.833Z'
        fill='url(#paint3023_linear_3150_15389)'
      />
      <path
        d='M462.265 239.34L458.548 237.675L458.527 233.606L462.349 235.222L462.265 239.34Z'
        fill='url(#paint3024_linear_3150_15389)'
      />
      <path
        d='M590.903 349.574L590.598 349.623L591.292 348.175L591.47 347.939L590.903 349.574Z'
        fill='url(#paint3025_linear_3150_15389)'
      />
      <path
        d='M371.686 303.797L366.131 304.379L365.427 300.566L371.077 300.181L371.686 303.797Z'
        fill='url(#paint3026_linear_3150_15389)'
      />
      <path
        d='M548.759 378.738L548.181 377.526L548.895 374.59L549.158 375.753L548.759 378.738Z'
        fill='url(#paint3027_linear_3150_15389)'
      />
      <path
        d='M424.868 256.838L420.037 254.631L421.245 250.74L426.054 253.025L424.868 256.838Z'
        fill='url(#paint3028_linear_3150_15389)'
      />
      <path
        d='M515.762 460.399L513.546 462.488L513.567 461.404L515.793 459.296L515.762 460.399Z'
        fill='url(#paint3029_linear_3150_15389)'
      />
      <path
        d='M540.452 222.186L540.042 222.452L538.446 218.452L538.729 218.028L540.452 222.186Z'
        fill='url(#paint3030_linear_3150_15389)'
      />
      <path
        d='M614.396 314.241L613.713 315.099L614.018 313.64L614.7 312.606L614.396 314.241Z'
        fill='url(#paint3031_linear_3150_15389)'
      />
      <path
        d='M364.555 333.523L360.124 335.908L357.488 332.784L362.192 330.528L364.555 333.523Z'
        fill='url(#paint3032_linear_3150_15389)'
      />
      <path
        d='M503.202 221.772L499.641 220.393L499.673 216.008L503.412 217.388L503.202 221.772Z'
        fill='url(#paint3033_linear_3150_15389)'
      />
      <path
        d='M470.604 447.255L468.02 449.659L467.842 447.758L470.593 445.639L470.604 447.255Z'
        fill='url(#paint3034_linear_3150_15389)'
      />
      <path
        d='M594.368 344.303L593.297 344.746L593.696 343.515L594.673 342.953L594.368 344.303Z'
        fill='url(#paint3035_linear_3150_15389)'
      />
      <path
        d='M459.031 440.644L456.479 443.275L455.313 441.639L458.012 439.235L459.031 440.644Z'
        fill='url(#paint3036_linear_3150_15389)'
      />
      <path
        d='M607.548 338.036L606.267 338.736L606.54 337.642L607.759 336.815L607.548 338.036Z'
        fill='url(#paint3037_linear_3150_15389)'
      />
      <path
        d='M483.416 231.054L479.814 229.467L479.908 225.339L483.658 226.886L483.416 231.054Z'
        fill='url(#paint3038_linear_3150_15389)'
      />
      <path
        d='M405.261 266.514L400.02 264.504L401.627 260.681L406.794 262.869L405.261 266.514Z'
        fill='url(#paint3039_linear_3150_15389)'
      />
      <path
        d='M548.486 454.803L546.49 456.438L547.467 454.704L549.4 453.019L548.486 454.803Z'
        fill='url(#paint3040_linear_3150_15389)'
      />
      <path
        d='M615.897 319.217L614.879 320.094L615.078 318.813L616.097 317.779L615.897 319.217Z'
        fill='url(#paint3041_linear_3150_15389)'
      />
      <path
        d='M392.921 275.658L387.45 274.17L388.731 270.298L394.118 272.022L392.921 275.658Z'
        fill='url(#paint3042_linear_3150_15389)'
      />
      <path
        d='M617.536 332.055L616.181 332.942L616.412 331.927L617.683 330.873L617.536 332.055Z'
        fill='url(#paint3043_linear_3150_15389)'
      />
      <path
        d='M375.614 294.427L369.953 294.309V290.447L375.645 290.782L375.614 294.427Z'
        fill='url(#paint3044_linear_3150_15389)'
      />
      <path
        d='M554.987 389.911L554.104 388.936L555.018 385.862L555.617 386.798L554.987 389.911Z'
        fill='url(#paint3045_linear_3150_15389)'
      />
      <path
        d='M508.831 454.083L507.77 455.305L509.702 453.039L510.836 451.837L508.831 454.083Z'
        fill='url(#paint3046_linear_3150_15389)'
      />
      <path
        d='M555.438 363.713L555.522 362.363L555.869 359.91L555.732 361.102L555.438 363.713Z'
        fill='url(#paint3047_linear_3150_15389)'
      />
      <path
        d='M620.067 328.479L618.827 329.376L619.08 328.321L620.224 327.228L620.067 328.479Z'
        fill='url(#paint3048_linear_3150_15389)'
      />
      <path
        d='M452.834 411.48L450.293 413.854L448.508 411.864L451.102 409.578L452.834 411.48Z'
        fill='url(#paint3049_linear_3150_15389)'
      />
      <path
        d='M586.356 410.819L586.387 409.962L587.752 407.43L587.521 408.13L586.356 410.819Z'
        fill='url(#paint3050_linear_3150_15389)'
      />
      <path
        d='M601.447 339.79L600.386 340.243L600.764 339.061L601.72 338.47L601.447 339.79Z'
        fill='url(#paint3051_linear_3150_15389)'
      />
      <path
        d='M454.504 429.52L451.984 432.102L450.377 430.348L453.003 427.954L454.504 429.52Z'
        fill='url(#paint3052_linear_3150_15389)'
      />
      <path
        d='M614.417 308.911L613.797 309.798L614.039 308.291L614.679 307.236L614.417 308.911Z'
        fill='url(#paint3053_linear_3150_15389)'
      />
      <path
        d='M532.733 219.753L531.441 219.595L529.897 215.417L530.979 215.477L532.733 219.753Z'
        fill='url(#paint3054_linear_3150_15389)'
      />
      <path
        d='M434.813 248.464L430.203 246.227L431.222 242.326L435.8 244.651L434.813 248.464Z'
        fill='url(#paint3055_linear_3150_15389)'
      />
      <path
        d='M484.603 453.906L482.397 455.877L483.353 453.798L485.684 451.995L484.603 453.906Z'
        fill='url(#paint3056_linear_3150_15389)'
      />
      <path
        d='M564.008 394.641L563.294 393.646L564.249 390.69L564.837 391.577L564.008 394.641Z'
        fill='url(#paint3057_linear_3150_15389)'
      />
      <path
        d='M452.372 239.981L448.424 238.059L448.529 234.079L452.561 235.941L452.372 239.981Z'
        fill='url(#paint3058_linear_3150_15389)'
      />
      <path
        d='M614.763 334.076L613.346 334.893L613.535 333.869L614.91 332.913L614.763 334.076Z'
        fill='url(#paint3059_linear_3150_15389)'
      />
      <path
        d='M384.005 391.695L382.871 395.025L378.723 394.198L380.035 390.739L384.005 391.695Z'
        fill='url(#paint3060_linear_3150_15389)'
      />
      <path
        d='M364.303 342.5L360.386 345.278L357.33 342.49L361.562 339.83L364.303 342.5Z'
        fill='url(#paint3061_linear_3150_15389)'
      />
      <path
        d='M621.926 323.888L620.823 324.774L621.075 323.671L622.104 322.587L621.926 323.888Z'
        fill='url(#paint3062_linear_3150_15389)'
      />
      <path
        d='M370.5 375.743L368.746 379.241L364.345 377.891L366.414 374.324L370.5 375.743Z'
        fill='url(#paint3063_linear_3150_15389)'
      />
      <path
        d='M416.193 257.922L411.163 255.627L412.791 251.823L417.716 254.297L416.193 257.922Z'
        fill='url(#paint3064_linear_3150_15389)'
      />
      <path
        d='M588.498 344.973L587.931 344.992L588.477 343.583L588.887 343.416L588.498 344.973Z'
        fill='url(#paint3065_linear_3150_15389)'
      />
      <path
        d='M455.271 416.13L452.645 418.465L451.007 416.347L453.696 414.13L455.271 416.13Z'
        fill='url(#paint3066_linear_3150_15389)'
      />
      <path
        d='M577.891 399.518L577.618 398.375L578.742 395.666L578.784 396.7L577.891 399.518Z'
        fill='url(#paint3067_linear_3150_15389)'
      />
      <path
        d='M378.891 285.924L373.251 285.195L373.818 281.353L379.447 282.318L378.891 285.924Z'
        fill='url(#paint3068_linear_3150_15389)'
      />
      <path
        d='M526.232 217.437L523.985 216.856L522.777 212.54L524.804 213.082L526.232 217.437Z'
        fill='url(#paint3069_linear_3150_15389)'
      />
      <path
        d='M614.291 303.945L613.713 304.891L613.902 303.334L614.511 302.251L614.291 303.945Z'
        fill='url(#paint3070_linear_3150_15389)'
      />
      <path
        d='M527.619 453.177L526.075 454.586L527.356 452.576L529.015 451.246L527.619 453.177Z'
        fill='url(#paint3071_linear_3150_15389)'
      />
      <path
        d='M472.988 231.763L469.344 230.029L469.365 225.999L473.135 227.664L472.988 231.763Z'
        fill='url(#paint3072_linear_3150_15389)'
      />
      <path
        d='M518.944 215.338L516.14 214.501L515.279 210.087L517.946 210.885L518.944 215.338Z'
        fill='url(#paint3073_linear_3150_15389)'
      />
      <path
        d='M521.853 456.468L519.816 458.359L520.225 456.783L522.409 455L521.853 456.468Z'
        fill='url(#paint3074_linear_3150_15389)'
      />
      <path
        d='M615.698 313.365L614.742 314.202L614.91 312.862L615.845 311.877L615.698 313.365Z'
        fill='url(#paint3075_linear_3150_15389)'
      />
      <path
        d='M400.031 398.385L398.645 401.449L395.274 400.622L396.712 397.39L400.031 398.385Z'
        fill='url(#paint3076_linear_3150_15389)'
      />
      <path
        d='M494.464 222.521L490.767 220.846L491.04 216.639L494.947 218.304L494.464 222.521Z'
        fill='url(#paint3077_linear_3150_15389)'
      />
      <path
        d='M612.421 335.435L610.962 336.144L611.151 335.11L612.589 334.292L612.421 335.435Z'
        fill='url(#paint3078_linear_3150_15389)'
      />
      <path
        d='M444.822 410.11L442.249 412.534L440.411 410.662L442.963 408.258L444.822 410.11Z'
        fill='url(#paint3079_linear_3150_15389)'
      />
      <path
        d='M552.833 368.354L552.634 366.836L553.012 364.156L553.033 365.575L552.833 368.354Z'
        fill='url(#paint3080_linear_3150_15389)'
      />
      <path
        d='M430.623 409.962L428.407 412.652L426.16 411.302L428.376 408.553L430.623 409.962Z'
        fill='url(#paint3081_linear_3150_15389)'
      />
      <path
        d='M425.687 249.271L420.877 246.897L422.274 243.084L427.031 245.577L425.687 249.271Z'
        fill='url(#paint3082_linear_3150_15389)'
      />
      <path
        d='M519.133 451.787L517.463 453.216L518.65 451.167L520.393 449.787L519.133 451.787Z'
        fill='url(#paint3083_linear_3150_15389)'
      />
      <path
        d='M597.236 340.214L595.986 340.578L596.332 339.376L597.456 338.893L597.236 340.214Z'
        fill='url(#paint3084_linear_3150_15389)'
      />
      <path
        d='M614.375 289.117L614.249 290.437L614.427 288.663L614.637 287.186L614.375 289.117Z'
        fill='url(#paint3085_linear_3150_15389)'
      />
      <path
        d='M622.587 318.33L621.621 319.168L621.852 318.005L622.755 316.98L622.587 318.33Z'
        fill='url(#paint3086_linear_3150_15389)'
      />
      <path
        d='M557.854 360.412L557.749 359.151L558.095 356.776L558.043 357.939L557.854 360.412Z'
        fill='url(#paint3087_linear_3150_15389)'
      />
      <path
        d='M511.456 214.215L508.169 213.003L507.749 208.569L511.067 209.752L511.456 214.215Z'
        fill='url(#paint3088_linear_3150_15389)'
      />
      <path
        d='M603.579 336.913L602.245 337.416L602.465 336.273L603.789 335.682L603.579 336.913Z'
        fill='url(#paint3089_linear_3150_15389)'
      />
      <path
        d='M415.164 402.78L413.4 405.696L410.575 404.602L412.434 401.637L415.164 402.78Z'
        fill='url(#paint3090_linear_3150_15389)'
      />
      <path
        d='M506.289 460.103L504.22 462.497L502.813 462.064L504.84 459.601L506.289 460.103Z'
        fill='url(#paint3091_linear_3150_15389)'
      />
      <path
        d='M362.718 353.239L359.578 356.431L355.923 354.145L359.431 351.012L362.718 353.239Z'
        fill='url(#paint3092_linear_3150_15389)'
      />
      <path
        d='M551.321 380.956L550.481 379.645L551.164 376.512L551.668 377.832L551.321 380.956Z'
        fill='url(#paint3093_linear_3150_15389)'
      />
      <path
        d='M519.753 451.61L518.461 452.714L520.12 450.526L521.391 449.374L519.753 451.61Z'
        fill='url(#paint3094_linear_3150_15389)'
      />
      <path
        d='M616.108 308.832L615.204 309.709L615.309 308.33L616.234 307.305L616.108 308.832Z'
        fill='url(#paint3095_linear_3150_15389)'
      />
      <path
        d='M406.742 259.194L401.606 256.868L403.465 253.164L408.506 255.686L406.742 259.194Z'
        fill='url(#paint3096_linear_3150_15389)'
      />
      <path
        d='M588.739 342.638L588.025 342.716L588.393 341.337L589.149 341.189L588.739 342.638Z'
        fill='url(#paint3097_linear_3150_15389)'
      />
      <path
        d='M395.736 268.308L390.411 266.337L392.049 262.613L397.29 264.81L395.736 268.308Z'
        fill='url(#paint3098_linear_3150_15389)'
      />
      <path
        d='M444.202 240.838L439.896 238.562L440.505 234.719L444.864 236.975L444.202 240.838Z'
        fill='url(#paint3099_linear_3150_15389)'
      />
      <path
        d='M615.131 284.801L614.952 286.25L615.141 284.476L615.341 282.831L615.131 284.801Z'
        fill='url(#paint3100_linear_3150_15389)'
      />
      <path
        d='M589.527 342.322L588.372 342.578L588.677 341.278L589.832 340.953L589.527 342.322Z'
        fill='url(#paint3101_linear_3150_15389)'
      />
      <path
        d='M550.996 373.989L550.408 372.502L550.922 369.536L551.174 371.034L550.996 373.989Z'
        fill='url(#paint3102_linear_3150_15389)'
      />
      <path
        d='M546.795 456.497L544.747 458.221L545.556 456.418L547.625 454.714L546.795 456.497Z'
        fill='url(#paint3103_linear_3150_15389)'
      />
      <path
        d='M625.339 328.627L624.131 329.356L624.383 328.39L625.486 327.474L625.339 328.627Z'
        fill='url(#paint3104_linear_3150_15389)'
      />
      <path
        d='M518.828 453.679L517.957 454.576L519.952 452.329L520.771 451.324L518.828 453.679Z'
        fill='url(#paint3105_linear_3150_15389)'
      />
      <path
        d='M558.589 385.281L557.717 384.059L558.505 380.985L559.103 382.177L558.589 385.281Z'
        fill='url(#paint3106_linear_3150_15389)'
      />
      <path
        d='M491.692 458.222L489.801 459.897L491.261 457.808L493.172 456.123L491.692 458.222Z'
        fill='url(#paint3107_linear_3150_15389)'
      />
      <path
        d='M463.126 232.364L459.293 230.443L459.44 226.482L463.336 228.364L463.126 232.364Z'
        fill='url(#paint3108_linear_3150_15389)'
      />
      <path
        d='M509.944 456.221L508.946 457.335L510.9 455.068L511.929 453.935L509.944 456.221Z'
        fill='url(#paint3109_linear_3150_15389)'
      />
      <path
        d='M613.261 298.605L612.747 299.551L612.841 297.964L613.398 296.881L613.261 298.605Z'
        fill='url(#paint3110_linear_3150_15389)'
      />
      <path
        d='M622.818 331.041L621.463 331.721L621.705 330.765L622.955 329.918L622.818 331.041Z'
        fill='url(#paint3111_linear_3150_15389)'
      />
      <path
        d='M627.334 325.287L626.263 326.045L626.525 325.06L627.502 324.104L627.334 325.287Z'
        fill='url(#paint3112_linear_3150_15389)'
      />
      <path
        d='M570.099 389.695L569.637 388.295L570.466 385.458L570.792 386.739L570.099 389.695Z'
        fill='url(#paint3113_linear_3150_15389)'
      />
      <path
        d='M382.587 277.934L377.032 276.594L378.145 272.85L383.637 274.436L382.587 277.934Z'
        fill='url(#paint3114_linear_3150_15389)'
      />
      <path
        d='M610.111 335.612L608.662 336.144L608.84 335.07L610.321 334.46L610.111 335.612Z'
        fill='url(#paint3115_linear_3150_15389)'
      />
      <path
        d='M416.761 250.474L411.825 247.952L413.568 244.276L418.451 246.927L416.761 250.474Z'
        fill='url(#paint3116_linear_3150_15389)'
      />
      <path
        d='M620.791 333.198L619.363 333.829L619.573 332.863L620.949 332.105L620.791 333.198Z'
        fill='url(#paint3117_linear_3150_15389)'
      />
      <path
        d='M484.697 223.536L480.948 221.694L481.284 217.634L485.18 219.457L484.697 223.536Z'
        fill='url(#paint3118_linear_3150_15389)'
      />
      <path
        d='M504.304 214.028L500.555 212.373L500.765 208.028L504.588 209.703L504.304 214.028Z'
        fill='url(#paint3119_linear_3150_15389)'
      />
      <path
        d='M517.663 457.207L515.384 459.561L514.386 458.655L516.623 456.271L517.663 457.207Z'
        fill='url(#paint3120_linear_3150_15389)'
      />
      <path
        d='M616.244 304.192L615.383 305.078L615.467 303.679L616.307 302.625L616.244 304.192Z'
        fill='url(#paint3121_linear_3150_15389)'
      />
      <path
        d='M591.166 402.09L591.46 400.642L592.51 398.188L592.006 399.499L591.166 402.09Z'
        fill='url(#paint3122_linear_3150_15389)'
      />
      <path
        d='M578.973 344.657L578.374 344.46L578.805 342.913L579.351 343.022L578.973 344.657Z'
        fill='url(#paint3123_linear_3150_15389)'
      />
      <path
        d='M372.558 382.68L371.382 386.177L367.002 385.212L368.483 381.655L372.558 382.68Z'
        fill='url(#paint3124_linear_3150_15389)'
      />
      <path
        d='M622.619 312.852L621.779 313.64L621.978 312.428L622.745 311.453L622.619 312.852Z'
        fill='url(#paint3125_linear_3150_15389)'
      />
      <path
        d='M601.636 337.484L600.281 337.819L600.512 336.647L601.878 336.233L601.636 337.484Z'
        fill='url(#paint3126_linear_3150_15389)'
      />
      <path
        d='M628.794 321.346L627.859 322.104L628.122 321.099L628.962 320.124L628.794 321.346Z'
        fill='url(#paint3127_linear_3150_15389)'
      />
      <path
        d='M529.992 214.126L529.225 214.038L527.367 209.909H527.923L529.992 214.126Z'
        fill='url(#paint3128_linear_3150_15389)'
      />
      <path
        d='M435.695 241.567L431.106 239.094L432.23 235.33L436.777 237.892L435.695 241.567Z'
        fill='url(#paint3129_linear_3150_15389)'
      />
      <path
        d='M593.728 340.352L592.331 340.598L592.646 339.347L593.949 339.012L593.728 340.352Z'
        fill='url(#paint3130_linear_3150_15389)'
      />
      <path
        d='M453.118 232.788L449.075 230.709L449.348 226.788L453.423 228.876L453.118 232.788Z'
        fill='url(#paint3131_linear_3150_15389)'
      />
      <path
        d='M553.768 364.777L553.254 363.407L553.621 360.757L553.884 362.087L553.768 364.777Z'
        fill='url(#paint3132_linear_3150_15389)'
      />
      <path
        d='M613.219 294.102L612.736 295.088L612.81 293.492L613.272 292.329L613.219 294.102Z'
        fill='url(#paint3133_linear_3150_15389)'
      />
      <path
        d='M619.132 334.509L617.704 335.021L617.893 334.036L619.342 333.425L619.132 334.509Z'
        fill='url(#paint3134_linear_3150_15389)'
      />
      <path
        d='M623.973 309.098L623.249 309.955L623.448 308.734L624.089 307.679L623.973 309.098Z'
        fill='url(#paint3135_linear_3150_15389)'
      />
      <path
        d='M525.529 452.389L523.544 453.985L524.258 452.024L526.264 450.468L525.529 452.389Z'
        fill='url(#paint3136_linear_3150_15389)'
      />
      <path
        d='M445.462 412.701L442.847 415.164L440.915 413.145L443.519 410.76L445.462 412.701Z'
        fill='url(#paint3137_linear_3150_15389)'
      />
      <path
        d='M581.588 344.519L581.619 344.007L581.955 342.411L581.945 342.825L581.588 344.519Z'
        fill='url(#paint3138_linear_3150_15389)'
      />
      <path
        d='M582.438 392.858V391.222L583.258 388.641L583.142 390.138L582.438 392.858Z'
        fill='url(#paint3139_linear_3150_15389)'
      />
      <path
        d='M614.9 290.407L614.448 291.54L614.543 289.954L614.942 288.604L614.9 290.407Z'
        fill='url(#paint3140_linear_3150_15389)'
      />
      <path
        d='M529.015 450.999L527.556 452.162L528.9 449.915L530.433 448.782L529.015 450.999Z'
        fill='url(#paint3141_linear_3150_15389)'
      />
      <path
        d='M362.088 363.713L359.819 367.191L355.681 365.476L358.328 362.008L362.088 363.713Z'
        fill='url(#paint3142_linear_3150_15389)'
      />
      <path
        d='M396.912 261.105L391.703 258.799L393.593 255.193L398.708 257.706L396.912 261.105Z'
        fill='url(#paint3143_linear_3150_15389)'
      />
      <path
        d='M426.296 242.109L421.518 239.537L423.009 235.832L427.724 238.552L426.296 242.109Z'
        fill='url(#paint3144_linear_3150_15389)'
      />
      <path
        d='M554.251 377.251L553.432 375.793L553.926 372.758L554.619 374.098L554.251 377.251Z'
        fill='url(#paint3145_linear_3150_15389)'
      />
      <path
        d='M362.487 306.477L357.036 307.285L355.944 303.748L361.531 303.118L362.487 306.477Z'
        fill='url(#paint3146_linear_3150_15389)'
      />
      <path
        d='M474.027 224.364L470.247 222.442L470.488 218.462L474.363 220.354L474.027 224.364Z'
        fill='url(#paint3147_linear_3150_15389)'
      />
      <path
        d='M557.013 462.823L555.417 464.35L556.856 462.458L558.536 460.98L557.013 462.823Z'
        fill='url(#paint3148_linear_3150_15389)'
      />
      <path
        d='M433.259 408.996L430.917 411.647L428.68 410.061L431.043 407.42L433.259 408.996Z'
        fill='url(#paint3149_linear_3150_15389)'
      />
      <path
        d='M608.263 335.908L606.761 336.233L606.981 335.139L608.473 334.716L608.263 335.908Z'
        fill='url(#paint3150_linear_3150_15389)'
      />
      <path
        d='M402.299 398.996L400.976 402.04L397.762 401.242L399.149 398.06L402.299 398.996Z'
        fill='url(#paint3151_linear_3150_15389)'
      />
      <path
        d='M415.154 406.878L413.599 409.844L410.701 408.898L412.371 405.913L415.154 406.878Z'
        fill='url(#paint3152_linear_3150_15389)'
      />
      <path
        d='M580.464 342.716L579.666 342.529L580.065 341.031L580.779 341.14L580.464 342.716Z'
        fill='url(#paint3153_linear_3150_15389)'
      />
      <path
        d='M629.235 316.675L628.447 317.414L628.678 316.36L629.382 315.424L629.235 316.675Z'
        fill='url(#paint3154_linear_3150_15389)'
      />
      <path
        d='M365.511 297.462L359.903 297.61L359.494 293.974L365.186 294.033L365.511 297.462Z'
        fill='url(#paint3155_linear_3150_15389)'
      />
      <path
        d='M496.186 214.698L492.301 212.718L492.91 208.55L496.974 210.55L496.186 214.698Z'
        fill='url(#paint3156_linear_3150_15389)'
      />
      <path
        d='M408.075 252.099L403.066 249.459L405.145 245.912L410.06 248.72L408.075 252.099Z'
        fill='url(#paint3157_linear_3150_15389)'
      />
      <path
        d='M385.087 396.887L384.499 400.129L380.413 399.755L381.075 396.326L385.087 396.887Z'
        fill='url(#paint3158_linear_3150_15389)'
      />
      <path
        d='M563.157 381.29L562.506 379.753L563.083 376.847L563.682 378.216L563.157 381.29Z'
        fill='url(#paint3159_linear_3150_15389)'
      />
      <path
        d='M441.765 423.431L439.382 426.18L437.187 424.535L439.644 421.924L441.765 423.431Z'
        fill='url(#paint3160_linear_3150_15389)'
      />
      <path
        d='M616.013 299.344L615.246 300.221L615.288 298.792L616.013 297.748V299.344Z'
        fill='url(#paint3161_linear_3150_15389)'
      />
      <path
        d='M616.37 286.456L616.003 287.757L616.087 286.141L616.412 284.643L616.37 286.456Z'
        fill='url(#paint3162_linear_3150_15389)'
      />
      <path
        d='M360.481 315.434L355.324 316.882L353.602 313.503L358.958 312.251L360.481 315.434Z'
        fill='url(#paint3163_linear_3150_15389)'
      />
      <path
        d='M520.593 449.915L519.049 451.108L520.257 448.773L521.916 447.649L520.593 449.915Z'
        fill='url(#paint3164_linear_3150_15389)'
      />
      <path
        d='M600.533 338.381L599.189 338.548L599.431 337.346L600.827 337.11L600.533 338.381Z'
        fill='url(#paint3165_linear_3150_15389)'
      />
      <path
        d='M558.242 356.708L557.937 355.387L558.211 353.032L558.232 354.333L558.242 356.708Z'
        fill='url(#paint3166_linear_3150_15389)'
      />
      <path
        d='M385.549 270.554L380.151 268.701L381.694 265.085L387.008 267.194L385.549 270.554Z'
        fill='url(#paint3167_linear_3150_15389)'
      />
      <path
        d='M624.761 305.079L624.184 306.005L624.341 304.753L624.877 303.64L624.761 305.079Z'
        fill='url(#paint3168_linear_3150_15389)'
      />
      <path
        d='M505.617 458.221L503.548 460.645L502.088 460.005L504.136 457.561L505.617 458.221Z'
        fill='url(#paint3169_linear_3150_15389)'
      />
      <path
        d='M552.14 370.403L551.363 368.954L551.752 366.038L552.329 367.427L552.14 370.403Z'
        fill='url(#paint3170_linear_3150_15389)'
      />
      <path
        d='M575.045 384.916L574.793 383.202L575.413 380.551L575.623 382.098L575.045 384.916Z'
        fill='url(#paint3171_linear_3150_15389)'
      />
      <path
        d='M523.386 211.811L521.622 211.21L519.952 206.973L521.548 207.515L523.386 211.811Z'
        fill='url(#paint3172_linear_3150_15389)'
      />
      <path
        d='M509.87 454.064L508.305 455.226L509.849 452.97L511.32 451.729L509.87 454.064Z'
        fill='url(#paint3173_linear_3150_15389)'
      />
      <path
        d='M511.362 451.117L509.492 452.388L510.679 450.172L512.412 448.822L511.362 451.117Z'
        fill='url(#paint3174_linear_3150_15389)'
      />
      <path
        d='M445.189 233.438L440.841 231.014L441.681 227.25L446.071 229.684L445.189 233.438Z'
        fill='url(#paint3175_linear_3150_15389)'
      />
      <path
        d='M504.231 457.64L502.708 458.862L504.42 456.675L505.838 455.315L504.231 457.64Z'
        fill='url(#paint3176_linear_3150_15389)'
      />
      <path
        d='M488.31 462.498L486.105 464.281L487.386 462.399L489.465 460.458L488.31 462.498Z'
        fill='url(#paint3177_linear_3150_15389)'
      />
      <path
        d='M617.83 335.001L616.486 335.307L616.654 334.292L618.103 333.908L617.83 335.001Z'
        fill='url(#paint3178_linear_3150_15389)'
      />
      <path
        d='M593.864 395.784L594.18 394.129L594.999 391.784L594.61 393.281L593.864 395.784Z'
        fill='url(#paint3179_linear_3150_15389)'
      />
      <path
        d='M520.834 456.172L518.534 458.478L517.747 457.197L520.12 454.95L520.834 456.172Z'
        fill='url(#paint3180_linear_3150_15389)'
      />
      <path
        d='M607.685 337.554L606.204 337.76L606.446 336.647L607.958 336.361L607.685 337.554Z'
        fill='url(#paint3181_linear_3150_15389)'
      />
      <path
        d='M592.352 341.081L590.987 341.169L591.26 339.879L592.699 339.721L592.352 341.081Z'
        fill='url(#paint3182_linear_3150_15389)'
      />
      <path
        d='M359.483 324.065L354.736 326.065L352.499 322.892L357.519 321.119L359.483 324.065Z'
        fill='url(#paint3183_linear_3150_15389)'
      />
      <path
        d='M417.601 243.212L412.738 240.463L414.681 236.896L419.501 239.774L417.601 243.212Z'
        fill='url(#paint3184_linear_3150_15389)'
      />
      <path
        d='M439.812 434.545L437.596 437.461L435.317 436.19L437.628 433.402L439.812 434.545Z'
        fill='url(#paint3185_linear_3150_15389)'
      />
      <path
        d='M369.071 288.88L363.421 288.368L363.652 284.712L369.334 285.471L369.071 288.88Z'
        fill='url(#paint3186_linear_3150_15389)'
      />
      <path
        d='M600.722 340.332L599.409 340.391L599.704 339.179L601.058 339.051L600.722 340.332Z'
        fill='url(#paint3187_linear_3150_15389)'
      />
      <path
        d='M631.556 330.233L630.548 330.607L630.8 329.73L631.755 329.188L631.556 330.233Z'
        fill='url(#paint3188_linear_3150_15389)'
      />
      <path
        d='M586.797 340.884L585.642 340.854L585.936 339.475L587.154 339.455L586.797 340.884Z'
        fill='url(#paint3189_linear_3150_15389)'
      />
      <path
        d='M630.663 332.459L629.623 332.765L629.865 331.888L630.884 331.445L630.663 332.459Z'
        fill='url(#paint3190_linear_3150_15389)'
      />
      <path
        d='M617.378 282.308L617.116 283.747L617.168 282.131L617.41 280.495L617.378 282.308Z'
        fill='url(#paint3191_linear_3150_15389)'
      />
      <path
        d='M585.473 387.734L585.421 386L586.125 383.537L586.072 385.133L585.473 387.734Z'
        fill='url(#paint3192_linear_3150_15389)'
      />
      <path
        d='M584.077 340.874L583.237 340.647L583.583 339.209L584.402 339.356L584.077 340.874Z'
        fill='url(#paint3193_linear_3150_15389)'
      />
      <path
        d='M633.751 324.893L632.911 325.375L633.163 324.499L633.919 323.819L633.751 324.893Z'
        fill='url(#paint3194_linear_3150_15389)'
      />
      <path
        d='M632.175 327.228L631.22 327.632L631.472 326.755L632.344 326.164L632.175 327.228Z'
        fill='url(#paint3195_linear_3150_15389)'
      />
      <path
        d='M516.613 209.663L514.103 208.688L512.811 204.382L515.184 205.338L516.613 209.663Z'
        fill='url(#paint3196_linear_3150_15389)'
      />
      <path
        d='M464.103 225.181L460.196 223.053L460.511 219.191L464.523 221.27L464.103 225.181Z'
        fill='url(#paint3197_linear_3150_15389)'
      />
      <path
        d='M617.84 336.47L616.559 336.588L616.78 335.573L618.134 335.356L617.84 336.47Z'
        fill='url(#paint3198_linear_3150_15389)'
      />
      <path
        d='M635.19 322.331L634.454 322.902L634.696 322.006L635.368 321.237L635.19 322.331Z'
        fill='url(#paint3199_linear_3150_15389)'
      />
      <path
        d='M607.695 339.386L606.236 339.475L606.509 338.352L608.011 338.174L607.695 339.386Z'
        fill='url(#paint3200_linear_3150_15389)'
      />
      <path
        d='M568.712 377.812L568.25 376.127L568.702 373.457L569.217 374.916L568.712 377.812Z'
        fill='url(#paint3201_linear_3150_15389)'
      />
      <path
        d='M628.815 312.015L628.174 312.744L628.353 311.66L628.941 310.754L628.815 312.015Z'
        fill='url(#paint3202_linear_3150_15389)'
      />
      <path
        d='M418.64 407.588L416.876 410.514L414.177 409.401L416.046 406.504L418.64 407.588Z'
        fill='url(#paint3203_linear_3150_15389)'
      />
      <path
        d='M531.336 457.266L529.561 458.744L530.643 456.694L532.481 455.256L531.336 457.266Z'
        fill='url(#paint3204_linear_3150_15389)'
      />
      <path
        d='M358.811 332.657L354.547 335.12L351.88 332.233L356.458 329.977L358.811 332.657Z'
        fill='url(#paint3205_linear_3150_15389)'
      />
      <path
        d='M624.583 300.851L624.12 301.827L624.225 300.556L624.688 299.403L624.583 300.851Z'
        fill='url(#paint3206_linear_3150_15389)'
      />
      <path
        d='M442.217 441.176L439.875 444.112L437.796 442.851L440.222 440.083L442.217 441.176Z'
        fill='url(#paint3207_linear_3150_15389)'
      />
      <path
        d='M558.399 373.979L557.601 372.452L558.053 369.546L558.725 370.955L558.399 373.979Z'
        fill='url(#paint3208_linear_3150_15389)'
      />
      <path
        d='M446.376 449.66L443.876 452.635L442.164 451.364L444.79 448.645L446.376 449.66Z'
        fill='url(#paint3209_linear_3150_15389)'
      />
      <path
        d='M453.412 457.749L450.682 460.626L449.642 459.315L452.446 456.626L453.412 457.749Z'
        fill='url(#paint3210_linear_3150_15389)'
      />
      <path
        d='M511.813 454.221L509.555 456.537L508.579 455.246L510.889 453L511.813 454.221Z'
        fill='url(#paint3211_linear_3150_15389)'
      />
      <path
        d='M629.34 333.78L628.269 333.937L628.51 333.06L629.581 332.765L629.34 333.78Z'
        fill='url(#paint3212_linear_3150_15389)'
      />
      <path
        d='M615.709 294.812L615.078 295.689L615.089 294.24L615.646 293.176L615.709 294.812Z'
        fill='url(#paint3213_linear_3150_15389)'
      />
      <path
        d='M486.315 216.038L482.439 213.919L483.07 209.929L487.123 212.038L486.315 216.038Z'
        fill='url(#paint3214_linear_3150_15389)'
      />
      <path
        d='M363.243 372.255L361.73 375.842L357.414 374.571L359.347 371.004L363.243 372.255Z'
        fill='url(#paint3215_linear_3150_15389)'
      />
      <path
        d='M527.367 462.537L525.046 464.754L524.762 463.424L527.146 461.276L527.367 462.537Z'
        fill='url(#paint3216_linear_3150_15389)'
      />
      <path
        d='M635.704 319.079L635.053 319.71L635.273 318.794L635.883 317.976L635.704 319.079Z'
        fill='url(#paint3217_linear_3150_15389)'
      />
      <path
        d='M579.172 380.719L578.941 378.945L579.487 376.462L579.676 378.068L579.172 380.719Z'
        fill='url(#paint3218_linear_3150_15389)'
      />
      <path
        d='M374.459 389.044L373.839 392.463L369.554 391.922L370.384 388.365L374.459 389.044Z'
        fill='url(#paint3219_linear_3150_15389)'
      />
      <path
        d='M554.997 361.506L554.346 360.087L554.619 357.476L555.039 358.865L554.997 361.506Z'
        fill='url(#paint3220_linear_3150_15389)'
      />
      <path
        d='M436.315 234.522L431.757 231.852L432.975 228.226L437.533 230.936L436.315 234.522Z'
        fill='url(#paint3221_linear_3150_15389)'
      />
      <path
        d='M536.377 454.97L535.39 455.886L537.102 453.492L538.215 452.635L536.377 454.97Z'
        fill='url(#paint3222_linear_3150_15389)'
      />
      <path
        d='M372.453 280.968L366.866 279.835L367.664 276.229L373.22 277.638L372.453 280.968Z'
        fill='url(#paint3223_linear_3150_15389)'
      />
      <path
        d='M629.203 308.507L628.657 309.295L628.815 308.202L629.309 307.226L629.203 308.507Z'
        fill='url(#paint3224_linear_3150_15389)'
      />
      <path
        d='M387.471 263.548L382.23 261.272L384.1 257.794L389.245 260.307L387.471 263.548Z'
        fill='url(#paint3225_linear_3150_15389)'
      />
      <path
        d='M596.921 390.187L597.183 388.404L597.887 386.187L597.53 387.823L596.921 390.187Z'
        fill='url(#paint3226_linear_3150_15389)'
      />
      <path
        d='M399.149 254.297L394.118 251.587L396.334 248.178L401.249 251.075L399.149 254.297Z'
        fill='url(#paint3227_linear_3150_15389)'
      />
      <path
        d='M617.399 337.573L616.139 337.504L616.401 336.479L617.704 336.45L617.399 337.573Z'
        fill='url(#paint3228_linear_3150_15389)'
      />
      <path
        d='M510.889 208.244L507.591 206.756L507.003 202.48L510.238 203.978L510.889 208.244Z'
        fill='url(#paint3229_linear_3150_15389)'
      />
      <path
        d='M616.948 291.225L616.423 292.221L616.444 290.772L616.864 289.58L616.948 291.225Z'
        fill='url(#paint3230_linear_3150_15389)'
      />
      <path
        d='M404.788 399.932L403.475 402.996L400.377 402.149L401.806 399.045L404.788 399.932Z'
        fill='url(#paint3231_linear_3150_15389)'
      />
      <path
        d='M519.973 460.153L517.705 462.34L517.358 460.892L519.742 458.813L519.973 460.153Z'
        fill='url(#paint3232_linear_3150_15389)'
      />
      <path
        d='M555.27 367.349L554.482 365.831L554.797 363.033L555.448 364.442L555.27 367.349Z'
        fill='url(#paint3233_linear_3150_15389)'
      />
      <path
        d='M600.187 341.16L598.905 341.002L599.22 339.77L600.575 339.839L600.187 341.16Z'
        fill='url(#paint3234_linear_3150_15389)'
      />
      <path
        d='M527.345 454.586L526.169 455.512L527.808 453.078L529.057 452.162L527.345 454.586Z'
        fill='url(#paint3235_linear_3150_15389)'
      />
      <path
        d='M628.038 334.765L626.945 334.755L627.187 333.868L628.29 333.75L628.038 334.765Z'
        fill='url(#paint3236_linear_3150_15389)'
      />
      <path
        d='M592.142 341.839L590.829 341.711L591.145 340.381L592.541 340.44L592.142 341.839Z'
        fill='url(#paint3237_linear_3150_15389)'
      />
      <path
        d='M358.611 341.022L354.894 343.859L351.859 341.317L355.923 338.608L358.611 341.022Z'
        fill='url(#paint3238_linear_3150_15389)'
      />
      <path
        d='M607.391 340.489L605.952 340.391L606.257 339.248L607.758 339.258L607.391 340.489Z'
        fill='url(#paint3239_linear_3150_15389)'
      />
      <path
        d='M409.377 245.143L404.505 242.207L406.836 238.818L411.614 241.902L409.377 245.143Z'
        fill='url(#paint3240_linear_3150_15389)'
      />
      <path
        d='M635.463 315.523L634.874 316.202L635.042 315.266L635.62 314.419L635.463 315.523Z'
        fill='url(#paint3241_linear_3150_15389)'
      />
      <path
        d='M453.853 226.068L449.768 223.753L450.167 219.969L454.305 222.255L453.853 226.068Z'
        fill='url(#paint3242_linear_3150_15389)'
      />
      <path
        d='M459.461 464.547L456.615 467.316L456.132 466.005L458.999 463.335L459.461 464.547Z'
        fill='url(#paint3243_linear_3150_15389)'
      />
      <path
        d='M573.585 374.167L573.165 372.413L573.606 369.92L573.953 371.507L573.585 374.167Z'
        fill='url(#paint3244_linear_3150_15389)'
      />
      <path
        d='M434.33 410.445L431.946 413.135L429.615 411.391L432.009 408.75L434.33 410.445Z'
        fill='url(#paint3245_linear_3150_15389)'
      />
      <path
        d='M629.771 305.138L629.319 305.995L629.476 304.901L629.844 303.827L629.771 305.138Z'
        fill='url(#paint3246_linear_3150_15389)'
      />
      <path
        d='M618.796 287.787L618.397 288.949L618.46 287.511L618.702 286.121L618.796 287.787Z'
        fill='url(#paint3247_linear_3150_15389)'
      />
      <path
        d='M588.32 382.67L588.236 380.818L588.834 378.502L588.813 380.217L588.32 382.67Z'
        fill='url(#paint3248_linear_3150_15389)'
      />
      <path
        d='M511.75 454.428L509.996 455.739L511.099 453.334L512.958 452.103L511.75 454.428Z'
        fill='url(#paint3249_linear_3150_15389)'
      />
      <path
        d='M542.489 466.793L540.914 468.449L542.216 466.527L544.033 465.059L542.489 466.793Z'
        fill='url(#paint3250_linear_3150_15389)'
      />
      <path
        d='M388.51 397.35L387.922 400.523L384.205 400.168L384.845 396.789L388.51 397.35Z'
        fill='url(#paint3251_linear_3150_15389)'
      />
      <path
        d='M427.262 235.596L422.547 232.738L424.154 229.221L428.827 232.167L427.262 235.596Z'
        fill='url(#paint3252_linear_3150_15389)'
      />
      <path
        d='M562.506 370.807L561.76 369.181L562.149 366.442L562.8 367.92L562.506 370.807Z'
        fill='url(#paint3253_linear_3150_15389)'
      />
      <path
        d='M365.795 378.748L364.702 382.335L360.533 381.29L362.046 377.753L365.795 378.748Z'
        fill='url(#paint3254_linear_3150_15389)'
      />
      <path
        d='M422.432 407.292L420.405 410.149L417.853 408.829L419.932 405.981L422.432 407.292Z'
        fill='url(#paint3255_linear_3150_15389)'
      />
      <path
        d='M623.123 296.349L622.797 297.305L622.839 296.014L623.144 294.881L623.123 296.349Z'
        fill='url(#paint3256_linear_3150_15389)'
      />
      <path
        d='M586.261 341.495L585.169 341.189L585.473 339.78L586.681 340.007L586.261 341.495Z'
        fill='url(#paint3257_linear_3150_15389)'
      />
      <path
        d='M491.586 460.635L489.161 462.34L490.085 460.428L492.227 458.487L491.586 460.635Z'
        fill='url(#paint3258_linear_3150_15389)'
      />
      <path
        d='M374.721 273.677L369.26 272.032L370.489 268.524L375.898 270.436L374.721 273.677Z'
        fill='url(#paint3259_linear_3150_15389)'
      />
      <path
        d='M576.83 342.726L576.001 342.263L576.305 340.706L577.208 341.081L576.83 342.726Z'
        fill='url(#paint3260_linear_3150_15389)'
      />
      <path
        d='M616.874 338.598L615.604 338.332L615.898 337.307L617.21 337.455L616.874 338.598Z'
        fill='url(#paint3261_linear_3150_15389)'
      />
      <path
        d='M502.088 455.364L500.009 456.773L501.049 454.487L502.992 453.009L502.088 455.364Z'
        fill='url(#paint3262_linear_3150_15389)'
      />
      <path
        d='M505.375 207.712L501.532 205.682L501.847 201.554L505.722 203.604L505.375 207.712Z'
        fill='url(#paint3263_linear_3150_15389)'
      />
      <path
        d='M626.966 335.583L625.874 335.376L626.116 334.479L627.25 334.558L626.966 335.583Z'
        fill='url(#paint3264_linear_3150_15389)'
      />
      <path
        d='M475.508 217.506L471.633 215.279L472.116 211.437L476.138 213.624L475.508 217.506Z'
        fill='url(#paint3265_linear_3150_15389)'
      />
      <path
        d='M620.718 284.269L620.518 285.609L620.634 284.181L620.645 282.604L620.718 284.269Z'
        fill='url(#paint3266_linear_3150_15389)'
      />
      <path
        d='M634.612 311.906L634.076 312.626L634.192 311.67L634.748 310.793L634.612 311.906Z'
        fill='url(#paint3267_linear_3150_15389)'
      />
      <path
        d='M630.401 301.837L630.075 302.802L630.212 301.699L630.474 300.536L630.401 301.837Z'
        fill='url(#paint3268_linear_3150_15389)'
      />
      <path
        d='M639.138 329.198L638.33 329.277L638.55 328.518L639.338 328.272L639.138 329.198Z'
        fill='url(#paint3269_linear_3150_15389)'
      />
      <path
        d='M582.554 376.265L582.302 374.393L582.785 372.068L582.921 373.802L582.554 376.265Z'
        fill='url(#paint3270_linear_3150_15389)'
      />
      <path
        d='M557.433 353.436L556.95 352.008L557.087 349.663L557.276 351.121L557.433 353.436Z'
        fill='url(#paint3271_linear_3150_15389)'
      />
      <path
        d='M581.871 341.721L580.958 341.268L581.304 339.79L582.207 340.155L581.871 341.721Z'
        fill='url(#paint3272_linear_3150_15389)'
      />
      <path
        d='M476.537 466.715L473.796 468.971L474.132 467.483L476.716 465.05L476.537 466.715Z'
        fill='url(#paint3273_linear_3150_15389)'
      />
      <path
        d='M639.18 326.774L638.393 326.922L638.603 326.164L639.359 325.838L639.18 326.774Z'
        fill='url(#paint3274_linear_3150_15389)'
      />
      <path
        d='M357.645 350.372L354.652 353.604L351.165 351.456L354.568 348.362L357.645 350.372Z'
        fill='url(#paint3275_linear_3150_15389)'
      />
      <path
        d='M607.286 341.583L605.837 341.288L606.204 340.125L607.685 340.322L607.286 341.583Z'
        fill='url(#paint3276_linear_3150_15389)'
      />
      <path
        d='M419.186 236.621L414.419 233.566L416.624 230.187L421.318 233.379L419.186 236.621Z'
        fill='url(#paint3277_linear_3150_15389)'
      />
      <path
        d='M600.344 342.431L599.094 342.066L599.451 340.805L600.775 341.081L600.344 342.431Z'
        fill='url(#paint3278_linear_3150_15389)'
      />
      <path
        d='M638.456 330.824L637.647 330.785L637.857 330.026L638.676 329.918L638.456 330.824Z'
        fill='url(#paint3279_linear_3150_15389)'
      />
      <path
        d='M568.44 345.052L568.324 343.899L568.429 342.086L568.387 343.17L568.44 345.052Z'
        fill='url(#paint3280_linear_3150_15389)'
      />
      <path
        d='M545.22 460.724L544.38 461.769L546.144 459.394L547.289 458.517L545.22 460.724Z'
        fill='url(#paint3281_linear_3150_15389)'
      />
      <path
        d='M592.636 343.327L591.365 342.982L591.743 341.623L593.098 341.879L592.636 343.327Z'
        fill='url(#paint3282_linear_3150_15389)'
      />
      <path
        d='M446.491 226.856L442.122 224.176L443.194 220.58L447.583 223.27L446.491 226.856Z'
        fill='url(#paint3283_linear_3150_15389)'
      />
      <path
        d='M599.336 384.354L599.546 382.413L600.113 380.344L599.788 382.147L599.336 384.354Z'
        fill='url(#paint3284_linear_3150_15389)'
      />
      <path
        d='M497.657 207.693L493.592 205.427L494.495 201.377L498.539 203.712L497.657 207.693Z'
        fill='url(#paint3285_linear_3150_15389)'
      />
      <path
        d='M558.778 364.511L557.99 362.934L558.263 360.284L558.935 361.732L558.778 364.511Z'
        fill='url(#paint3286_linear_3150_15389)'
      />
      <path
        d='M572.042 342.864L571.369 342.234L571.611 340.598L572.399 341.11L572.042 342.864Z'
        fill='url(#paint3287_linear_3150_15389)'
      />
      <path
        d='M406.343 402.868L405.093 405.952L402.058 405.125L403.455 402.05L406.343 402.868Z'
        fill='url(#paint3288_linear_3150_15389)'
      />
      <path
        d='M638.876 324.074L638.109 324.291L638.288 323.523L639.033 323.138L638.876 324.074Z'
        fill='url(#paint3289_linear_3150_15389)'
      />
      <path
        d='M626.399 403.134L626.683 401.942L627.859 400.05L627.355 401.075L626.399 403.134Z'
        fill='url(#paint3290_linear_3150_15389)'
      />
      <path
        d='M618.933 394.976L618.996 393.616L620.035 391.675L619.815 392.887L618.933 394.976Z'
        fill='url(#paint3291_linear_3150_15389)'
      />
      <path
        d='M518.723 207.939L517.631 207.377L515.478 203.229L516.308 203.781L518.723 207.939Z'
        fill='url(#paint3292_linear_3150_15389)'
      />
      <path
        d='M639.17 321.858L638.424 322.173L638.582 321.405L639.317 320.912L639.17 321.858Z'
        fill='url(#paint3293_linear_3150_15389)'
      />
      <path
        d='M613.577 388.355L613.503 386.877L614.438 384.946L614.343 386.276L613.577 388.355Z'
        fill='url(#paint3294_linear_3150_15389)'
      />
      <path
        d='M587.227 343.711L586.146 343.238L586.534 341.79L587.647 342.174L587.227 343.711Z'
        fill='url(#paint3295_linear_3150_15389)'
      />
      <path
        d='M637.794 332.075L636.996 331.868L637.195 331.11L638.036 331.169L637.794 332.075Z'
        fill='url(#paint3296_linear_3150_15389)'
      />
      <path
        d='M626.547 336.548L625.507 336.134L625.748 335.228L626.872 335.524L626.547 336.548Z'
        fill='url(#paint3297_linear_3150_15389)'
      />
      <path
        d='M613.965 392.601L614.343 390.897L615.173 388.976L614.595 390.532L613.965 392.601Z'
        fill='url(#paint3298_linear_3150_15389)'
      />
      <path
        d='M622.829 292.703L622.619 293.718L622.682 292.427L622.787 291.225L622.829 292.703Z'
        fill='url(#paint3299_linear_3150_15389)'
      />
      <path
        d='M390.075 256.908L385.045 254.188L387.292 250.897L392.175 253.843L390.075 256.908Z'
        fill='url(#paint3300_linear_3150_15389)'
      />
      <path
        d='M639.653 319.611L638.939 320.035L639.096 319.266L639.779 318.656L639.653 319.611Z'
        fill='url(#paint3301_linear_3150_15389)'
      />
      <path
        d='M633.782 308.478L633.278 309.237L633.373 308.281L633.877 307.355L633.782 308.478Z'
        fill='url(#paint3302_linear_3150_15389)'
      />
      <path
        d='M400.829 247.627L395.988 244.582L398.487 241.34L403.202 244.562L400.829 247.627Z'
        fill='url(#paint3303_linear_3150_15389)'
      />
      <path
        d='M608.294 386.059L608.546 384.246L609.239 382.315L608.819 384L608.294 386.059Z'
        fill='url(#paint3304_linear_3150_15389)'
      />
      <path
        d='M616.654 339.583L615.435 339.11L615.729 338.076L617.042 338.43L616.654 339.583Z'
        fill='url(#paint3305_linear_3150_15389)'
      />
      <path
        d='M556.551 358.658L555.806 357.17L556.005 354.619L556.52 356.096L556.551 358.658Z'
        fill='url(#paint3306_linear_3150_15389)'
      />
      <path
        d='M367.664 386.118L367.076 389.655L362.97 388.926L363.946 385.409L367.664 386.118Z'
        fill='url(#paint3307_linear_3150_15389)'
      />
      <path
        d='M357.351 359.575L355.104 363.082L351.302 361.309L354.043 357.979L357.351 359.575Z'
        fill='url(#paint3308_linear_3150_15389)'
      />
      <path
        d='M541.366 457.877L540.735 458.517L542.71 455.995L543.434 455.355L541.366 457.877Z'
        fill='url(#paint3309_linear_3150_15389)'
      />
      <path
        d='M593.812 345.564L592.573 345.051L593.003 343.662L594.295 344.066L593.812 345.564Z'
        fill='url(#paint3310_linear_3150_15389)'
      />
      <path
        d='M629.676 298.161L629.529 299.186L629.592 298.063L629.749 296.861L629.676 298.161Z'
        fill='url(#paint3311_linear_3150_15389)'
      />
      <path
        d='M487.785 208.323L483.763 205.978L484.729 202.007L488.856 204.392L487.785 208.323Z'
        fill='url(#paint3312_linear_3150_15389)'
      />
      <path
        d='M576.599 370.009L576.179 368.107L576.536 365.762L576.799 367.565L576.599 370.009Z'
        fill='url(#paint3313_linear_3150_15389)'
      />
      <path
        d='M639.905 317.01L639.243 317.513L639.401 316.744L640.01 316.045L639.905 317.01Z'
        fill='url(#paint3314_linear_3150_15389)'
      />
      <path
        d='M608.011 381.645L607.811 380.039L608.651 378.108L608.62 379.605L608.011 381.645Z'
        fill='url(#paint3315_linear_3150_15389)'
      />
      <path
        d='M377.231 266.702L371.938 264.563L373.577 261.194L378.786 263.588L377.231 266.702Z'
        fill='url(#paint3316_linear_3150_15389)'
      />
      <path
        d='M531.095 459.365L530.128 460.173L532.04 457.67L533.006 456.832L531.095 459.365Z'
        fill='url(#paint3317_linear_3150_15389)'
      />
      <path
        d='M607.78 343.041L606.383 342.549L606.771 341.366L608.252 341.75L607.78 343.041Z'
        fill='url(#paint3318_linear_3150_15389)'
      />
      <path
        d='M637.342 333.179L636.586 332.784L636.786 332.026L637.605 332.282L637.342 333.179Z'
        fill='url(#paint3319_linear_3150_15389)'
      />
      <path
        d='M566.381 367.467L565.709 365.663L566.013 363.102L566.549 364.767L566.381 367.467Z'
        fill='url(#paint3320_linear_3150_15389)'
      />
      <path
        d='M569.353 343.169L569.028 342.164L569.154 340.46L569.469 341.347L569.353 343.169Z'
        fill='url(#paint3321_linear_3150_15389)'
      />
      <path
        d='M582.081 343.938L581.252 343.288L581.567 341.77L582.512 342.312L582.081 343.938Z'
        fill='url(#paint3322_linear_3150_15389)'
      />
      <path
        d='M410.753 238.374L406.049 235.152L408.653 231.931L413.263 235.31L410.753 238.374Z'
        fill='url(#paint3323_linear_3150_15389)'
      />
      <path
        d='M459.64 469.848L456.741 472.617L456.29 471.493L459.136 468.685L459.64 469.848Z'
        fill='url(#paint3324_linear_3150_15389)'
      />
      <path
        d='M600.838 343.997L599.609 343.426L600.019 342.135L601.31 342.608L600.838 343.997Z'
        fill='url(#paint3325_linear_3150_15389)'
      />
      <path
        d='M626.903 337.82L625.937 337.209L626.189 336.302L627.261 336.785L626.903 337.82Z'
        fill='url(#paint3326_linear_3150_15389)'
      />
      <path
        d='M590.367 377.3L590.199 375.27L590.703 373.093L590.682 375.024L590.367 377.3Z'
        fill='url(#paint3327_linear_3150_15389)'
      />
      <path
        d='M465.93 218.954L461.919 216.481L462.528 212.816L466.655 215.23L465.93 218.954Z'
        fill='url(#paint3328_linear_3150_15389)'
      />
      <path
        d='M409.871 403.745L408.369 406.78L405.534 405.804L407.151 402.789L409.871 403.745Z'
        fill='url(#paint3329_linear_3150_15389)'
      />
      <path
        d='M520.614 460.143L519.427 461.128L521.128 458.576L522.431 457.64L520.614 460.143Z'
        fill='url(#paint3330_linear_3150_15389)'
      />
      <path
        d='M437.775 228.108L433.248 225.171L434.708 221.733L439.234 224.689L437.775 228.108Z'
        fill='url(#paint3331_linear_3150_15389)'
      />
      <path
        d='M358.149 368.048L356.49 371.644L352.615 370.196L354.757 366.747L358.149 368.048Z'
        fill='url(#paint3332_linear_3150_15389)'
      />
      <path
        d='M429.951 417.776L427.924 420.732L425.215 419.313L427.294 416.416L429.951 417.776Z'
        fill='url(#paint3333_linear_3150_15389)'
      />
      <path
        d='M640.188 314.389L639.611 314.971L639.758 314.202L640.272 313.414L640.188 314.389Z'
        fill='url(#paint3334_linear_3150_15389)'
      />
      <path
        d='M392.375 396.947L391.692 400.119L388.258 399.627L389.067 396.346L392.375 396.947Z'
        fill='url(#paint3335_linear_3150_15389)'
      />
      <path
        d='M588.215 346.027L587.217 345.357L587.605 343.889L588.708 344.44L588.215 346.027Z'
        fill='url(#paint3336_linear_3150_15389)'
      />
      <path
        d='M513.094 205.161L511.026 204.156L509.23 199.939L511.078 200.953L513.094 205.161Z'
        fill='url(#paint3337_linear_3150_15389)'
      />
      <path
        d='M376.234 394.631L376.223 397.961L372.054 397.813L372.253 394.345L376.234 394.631Z'
        fill='url(#paint3338_linear_3150_15389)'
      />
      <path
        d='M609.481 345.199L608.189 344.539L608.609 343.347L610.017 343.889L609.481 345.199Z'
        fill='url(#paint3339_linear_3150_15389)'
      />
      <path
        d='M356.616 301.206L351.144 301.659L350.252 298.299L355.87 298.053L356.616 301.206Z'
        fill='url(#paint3340_linear_3150_15389)'
      />
      <path
        d='M617.284 340.972L616.128 340.293L616.454 339.238L617.714 339.81L617.284 340.972Z'
        fill='url(#paint3341_linear_3150_15389)'
      />
      <path
        d='M637.227 334.322L636.513 333.731L636.712 332.972L637.5 333.415L637.227 334.322Z'
        fill='url(#paint3342_linear_3150_15389)'
      />
      <path
        d='M599.798 378.729L599.725 376.758L600.334 374.758L600.134 376.65L599.798 378.729Z'
        fill='url(#paint3343_linear_3150_15389)'
      />
      <path
        d='M632.795 305.019L632.365 305.788L632.449 304.822L632.827 303.867L632.795 305.019Z'
        fill='url(#paint3344_linear_3150_15389)'
      />
      <path
        d='M499.935 461.433L497.709 463.079L498.591 460.832L500.744 459.187L499.935 461.433Z'
        fill='url(#paint3345_linear_3150_15389)'
      />
      <path
        d='M359.641 292.772L354.054 292.536L353.822 289.127L359.483 289.59L359.641 292.772Z'
        fill='url(#paint3346_linear_3150_15389)'
      />
      <path
        d='M355.156 309.354L349.926 310.438L348.467 307.197L353.896 306.339L355.156 309.354Z'
        fill='url(#paint3347_linear_3150_15389)'
      />
      <path
        d='M360.407 374.531L359.053 378.117L355.335 376.886L357.078 373.388L360.407 374.531Z'
        fill='url(#paint3348_linear_3150_15389)'
      />
      <path
        d='M611.833 347.574L610.689 346.746L611.13 345.544L612.411 346.244L611.833 347.574Z'
        fill='url(#paint3349_linear_3150_15389)'
      />
      <path
        d='M640.356 311.719L639.863 312.389L639.999 311.601L640.43 310.734L640.356 311.719Z'
        fill='url(#paint3350_linear_3150_15389)'
      />
      <path
        d='M627.597 339.061L626.746 338.243L626.998 337.337L627.985 338.036L627.597 339.061Z'
        fill='url(#paint3351_linear_3150_15389)'
      />
      <path
        d='M584.581 371.398L584.297 369.319L584.644 367.152L584.759 369.122L584.581 371.398Z'
        fill='url(#paint3352_linear_3150_15389)'
      />
      <path
        d='M637.637 335.622L637.006 334.864L637.206 334.105L637.931 334.736L637.637 335.622Z'
        fill='url(#paint3353_linear_3150_15389)'
      />
      <path
        d='M616.191 381.812L616.149 380.058L616.906 378.275L616.895 379.881L616.191 381.812Z'
        fill='url(#paint3354_linear_3150_15389)'
      />
      <path
        d='M602.025 345.987L600.869 345.209L601.3 343.908L602.56 344.559L602.025 345.987Z'
        fill='url(#paint3355_linear_3150_15389)'
      />
      <path
        d='M423.188 408.819L421.192 411.736L418.535 410.356L420.573 407.45L423.188 408.819Z'
        fill='url(#paint3356_linear_3150_15389)'
      />
      <path
        d='M618.596 342.795L617.504 341.938L617.872 340.884L619.038 341.623L618.596 342.795Z'
        fill='url(#paint3357_linear_3150_15389)'
      />
      <path
        d='M558.41 350.924L557.937 349.347L557.895 347.189L558.274 348.628L558.41 350.924Z'
        fill='url(#paint3358_linear_3150_15389)'
      />
      <path
        d='M562.002 361.555L561.277 359.792L561.456 357.279L562.002 358.964V361.555Z'
        fill='url(#paint3359_linear_3150_15389)'
      />
      <path
        d='M369.733 392.72L369.565 396.158L365.595 395.725L366.068 392.257L369.733 392.72Z'
        fill='url(#paint3360_linear_3150_15389)'
      />
      <path
        d='M362.339 284.998L356.773 284.141L357.099 280.742L362.707 281.845L362.339 284.998Z'
        fill='url(#paint3361_linear_3150_15389)'
      />
      <path
        d='M355.293 316.714L350.399 318.301L348.54 315.217L353.707 313.877L355.293 316.714Z'
        fill='url(#paint3362_linear_3150_15389)'
      />
      <path
        d='M621.999 387.094L622.157 385.32L622.965 383.537L622.745 385.163L621.999 387.094Z'
        fill='url(#paint3363_linear_3150_15389)'
      />
      <path
        d='M477.283 209.84L473.292 207.387L474.153 203.604L478.312 206.027L477.283 209.84Z'
        fill='url(#paint3364_linear_3150_15389)'
      />
      <path
        d='M620.865 345.189L619.899 344.194L620.287 343.15L621.348 344.007L620.865 345.189Z'
        fill='url(#paint3365_linear_3150_15389)'
      />
      <path
        d='M614.574 349.938L613.608 348.914L614.049 347.722L615.183 348.598L614.574 349.938Z'
        fill='url(#paint3366_linear_3150_15389)'
      />
      <path
        d='M362.298 381.33L361.342 384.946L357.677 383.911L359.052 380.414L362.298 381.33Z'
        fill='url(#paint3367_linear_3150_15389)'
      />
      <path
        d='M599.924 374.492L599.536 372.708L600.229 370.738L600.46 372.413L599.924 374.492Z'
        fill='url(#paint3368_linear_3150_15389)'
      />
      <path
        d='M623.753 347.702L622.976 346.569L623.354 345.534L624.267 346.53L623.753 347.702Z'
        fill='url(#paint3369_linear_3150_15389)'
      />
      <path
        d='M575.518 344.125L574.772 343.268L575.003 341.672L575.948 342.352L575.518 344.125Z'
        fill='url(#paint3370_linear_3150_15389)'
      />
      <path
        d='M391.556 250.434L386.746 247.38L389.256 244.247L393.929 247.528L391.556 250.434Z'
        fill='url(#paint3371_linear_3150_15389)'
      />
      <path
        d='M455.113 220.186L450.965 217.585L451.553 213.989L455.744 216.57L455.113 220.186Z'
        fill='url(#paint3372_linear_3150_15389)'
      />
      <path
        d='M632.532 302.053L632.207 302.891L632.28 301.925L632.532 300.901V302.053Z'
        fill='url(#paint3373_linear_3150_15389)'
      />
      <path
        d='M429.132 229.694L424.511 226.502L426.349 223.241L430.959 226.472L429.132 229.694Z'
        fill='url(#paint3374_linear_3150_15389)'
      />
      <path
        d='M638.445 337.021L637.92 336.066L638.119 335.327L638.76 336.135L638.445 337.021Z'
        fill='url(#paint3375_linear_3150_15389)'
      />
      <path
        d='M594.253 347.012L593.087 346.214L593.528 344.796L594.841 345.456L594.253 347.012Z'
        fill='url(#paint3376_linear_3150_15389)'
      />
      <path
        d='M379.72 260.11L374.648 257.518L376.664 254.306L381.621 257.164L379.72 260.11Z'
        fill='url(#paint3377_linear_3150_15389)'
      />
      <path
        d='M609.166 375.812L608.851 374.029L609.596 372.206L609.764 373.861L609.166 375.812Z'
        fill='url(#paint3378_linear_3150_15389)'
      />
      <path
        d='M401.743 240.976L397.069 237.665L399.81 234.571L404.347 238.069L401.743 240.976Z'
        fill='url(#paint3379_linear_3150_15389)'
      />
      <path
        d='M629.613 392.828L630.138 391.005L630.915 389.291L630.38 390.956L629.613 392.828Z'
        fill='url(#paint3380_linear_3150_15389)'
      />
      <path
        d='M355.944 323.641L351.397 325.651L349.275 322.735L354.127 321L355.944 323.641Z'
        fill='url(#paint3381_linear_3150_15389)'
      />
      <path
        d='M639.81 308.763L639.432 309.483L639.537 308.684L639.873 307.778L639.81 308.763Z'
        fill='url(#paint3382_linear_3150_15389)'
      />
      <path
        d='M617.525 352.284L616.727 351.062L617.189 349.87L618.134 350.944L617.525 352.284Z'
        fill='url(#paint3383_linear_3150_15389)'
      />
      <path
        d='M628.353 340.164L627.628 339.09L627.88 338.204L628.762 339.14L628.353 340.164Z'
        fill='url(#paint3384_linear_3150_15389)'
      />
      <path
        d='M380.151 396.217L380.035 399.489L376.265 399.262L376.549 395.873L380.151 396.217Z'
        fill='url(#paint3385_linear_3150_15389)'
      />
      <path
        d='M648.79 323.612L648.6 323.474L648.737 322.863L648.947 322.814L648.79 323.612Z'
        fill='url(#paint3386_linear_3150_15389)'
      />
      <path
        d='M509.156 203.249L506.005 201.614L505.06 197.475L508.085 199.15L509.156 203.249Z'
        fill='url(#paint3387_linear_3150_15389)'
      />
      <path
        d='M648.8 325.306L648.611 325.03L648.769 324.42L648.958 324.518L648.8 325.306Z'
        fill='url(#paint3388_linear_3150_15389)'
      />
      <path
        d='M626.473 349.771L625.895 348.451L626.263 347.426L627.008 348.599L626.473 349.771Z'
        fill='url(#paint3389_linear_3150_15389)'
      />
      <path
        d='M648.684 321.809L648.474 321.818L648.611 321.198L648.831 321.011L648.684 321.809Z'
        fill='url(#paint3390_linear_3150_15389)'
      />
      <path
        d='M364.051 277.865L358.548 276.485L359.326 273.126L364.818 274.771L364.051 277.865Z'
        fill='url(#paint3391_linear_3150_15389)'
      />
      <path
        d='M603.967 348.175L602.959 347.17L603.39 345.859L604.566 346.716L603.967 348.175Z'
        fill='url(#paint3392_linear_3150_15389)'
      />
      <path
        d='M648.538 326.814L648.348 326.38L648.506 325.779L648.695 326.026L648.538 326.814Z'
        fill='url(#paint3393_linear_3150_15389)'
      />
      <path
        d='M364.114 388.513L363.537 392.109L359.956 391.301L360.911 387.803L364.114 388.513Z'
        fill='url(#paint3394_linear_3150_15389)'
      />
      <path
        d='M558.599 356.077L557.937 354.342L557.958 351.958L558.473 353.604L558.599 356.077Z'
        fill='url(#paint3395_linear_3150_15389)'
      />
      <path
        d='M582.323 345.997L581.598 345.002L581.871 343.475L582.785 344.293L582.323 345.997Z'
        fill='url(#paint3396_linear_3150_15389)'
      />
      <path
        d='M569.185 364.018L568.566 362.028L568.744 359.644L569.217 361.506L569.185 364.018Z'
        fill='url(#paint3397_linear_3150_15389)'
      />
      <path
        d='M578.616 365.861L578.227 363.762L578.427 361.624L578.7 363.575L578.616 365.861Z'
        fill='url(#paint3398_linear_3150_15389)'
      />
      <path
        d='M355.681 331.08L351.554 333.504L349.128 330.804L353.591 328.647L355.681 331.08Z'
        fill='url(#paint3399_linear_3150_15389)'
      />
      <path
        d='M648.527 319.887L648.306 320.015L648.443 319.405L648.663 319.079L648.527 319.887Z'
        fill='url(#paint3400_linear_3150_15389)'
      />
      <path
        d='M396.187 396.799L395.326 399.942L392.134 399.341L393.121 396.109L396.187 396.799Z'
        fill='url(#paint3401_linear_3150_15389)'
      />
      <path
        d='M425.739 426.574L424.112 429.747L421.119 428.702L422.851 425.658L425.739 426.574Z'
        fill='url(#paint3402_linear_3150_15389)'
      />
      <path
        d='M639.117 338.144L638.687 336.992L638.886 336.253L639.443 337.267L639.117 338.144Z'
        fill='url(#paint3403_linear_3150_15389)'
      />
      <path
        d='M373.377 395.557L373.135 398.897L369.554 398.503L369.974 395.064L373.377 395.557Z'
        fill='url(#paint3404_linear_3150_15389)'
      />
      <path
        d='M648.107 328.144L647.907 327.553L648.075 326.952L648.275 327.365L648.107 328.144Z'
        fill='url(#paint3405_linear_3150_15389)'
      />
      <path
        d='M591.355 372.196L591.071 370.048L591.491 368.019L591.596 370.058L591.355 372.196Z'
        fill='url(#paint3406_linear_3150_15389)'
      />
      <path
        d='M632.658 299.344L632.459 300.28L632.522 299.324L632.648 298.191L632.658 299.344Z'
        fill='url(#paint3407_linear_3150_15389)'
      />
      <path
        d='M476.17 464.646L473.366 467.011L472.862 465.523L475.466 463.03L476.17 464.646Z'
        fill='url(#paint3408_linear_3150_15389)'
      />
      <path
        d='M561.76 348.559L561.382 346.963L561.277 345.022L561.676 346.401L561.76 348.559Z'
        fill='url(#paint3409_linear_3150_15389)'
      />
      <path
        d='M630.212 341.839L629.634 340.568L629.897 339.692L630.611 340.825L630.212 341.839Z'
        fill='url(#paint3410_linear_3150_15389)'
      />
      <path
        d='M648.264 317.828L648.044 318.074L648.17 317.463L648.38 317.01L648.264 317.828Z'
        fill='url(#paint3411_linear_3150_15389)'
      />
      <path
        d='M606.95 350.845L606.089 349.633L606.551 348.322L607.549 349.377L606.95 350.845Z'
        fill='url(#paint3412_linear_3150_15389)'
      />
      <path
        d='M447.531 220.718L443.152 217.831L444.391 214.383L448.76 217.338L447.531 220.718Z'
        fill='url(#paint3413_linear_3150_15389)'
      />
      <path
        d='M588.215 347.879L587.164 346.933L587.637 345.406L588.729 346.224L588.215 347.879Z'
        fill='url(#paint3414_linear_3150_15389)'
      />
      <path
        d='M533.92 463.719L533.184 464.429L535.253 461.808L536.062 461.089L533.92 463.719Z'
        fill='url(#paint3415_linear_3150_15389)'
      />
      <path
        d='M421.497 230.975L416.876 227.576L419.344 224.472L423.87 228.019L421.497 230.975Z'
        fill='url(#paint3416_linear_3150_15389)'
      />
      <path
        d='M620.34 354.54L619.689 353.111L620.172 351.929L620.949 353.2L620.34 354.54Z'
        fill='url(#paint3417_linear_3150_15389)'
      />
      <path
        d='M593.518 368.797L593.014 366.964L593.57 365.023L594.022 366.688L593.518 368.797Z'
        fill='url(#paint3418_linear_3150_15389)'
      />
      <path
        d='M354.694 339.209L351.103 342.027L348.341 339.623L352.31 337.021L354.694 339.209Z'
        fill='url(#paint3419_linear_3150_15389)'
      />
      <path
        d='M633.047 343.997L632.648 342.579L632.921 341.721L633.446 343.002L633.047 343.997Z'
        fill='url(#paint3420_linear_3150_15389)'
      />
      <path
        d='M647.571 329.287L647.361 328.518L647.519 327.927L647.75 328.518L647.571 329.287Z'
        fill='url(#paint3421_linear_3150_15389)'
      />
      <path
        d='M411.331 405.174L409.84 408.198L407.004 407.253L408.569 404.169L411.331 405.174Z'
        fill='url(#paint3422_linear_3150_15389)'
      />
      <path
        d='M524.699 465.996L523.68 466.843L525.781 464.281L526.705 463.365L524.699 465.996Z'
        fill='url(#paint3423_linear_3150_15389)'
      />
      <path
        d='M610.31 353.702L609.607 352.303L610.079 350.993L610.909 352.224L610.31 353.702Z'
        fill='url(#paint3424_linear_3150_15389)'
      />
      <path
        d='M500.996 469.789L498.728 471.582L499.768 469.474L501.931 467.671L500.996 469.789Z'
        fill='url(#paint3425_linear_3150_15389)'
      />
      <path
        d='M505.502 201.88L501.616 199.663L501.889 195.672L505.68 197.968L505.502 201.88Z'
        fill='url(#paint3426_linear_3150_15389)'
      />
      <path
        d='M569.458 344.884L568.733 343.82L568.933 342.105L569.763 342.982L569.458 344.884Z'
        fill='url(#paint3427_linear_3150_15389)'
      />
      <path
        d='M628.731 351.357L628.332 349.781L628.689 348.786L629.277 350.195L628.731 351.357Z'
        fill='url(#paint3428_linear_3150_15389)'
      />
      <path
        d='M640.22 339.337L639.915 337.977L640.104 337.258L640.545 338.479L640.22 339.337Z'
        fill='url(#paint3429_linear_3150_15389)'
      />
      <path
        d='M596.227 349.357L595.198 348.273L595.66 346.835L596.868 347.751L596.227 349.357Z'
        fill='url(#paint3430_linear_3150_15389)'
      />
      <path
        d='M467.243 211.427L463.147 208.816L464.082 205.18L468.272 207.781L467.243 211.427Z'
        fill='url(#paint3431_linear_3150_15389)'
      />
      <path
        d='M618.859 375.467L618.785 373.447L619.447 371.841L619.426 373.704L618.859 375.467Z'
        fill='url(#paint3432_linear_3150_15389)'
      />
      <path
        d='M625.223 380.364L625.412 378.295L626.053 376.719L625.832 378.62L625.223 380.364Z'
        fill='url(#paint3433_linear_3150_15389)'
      />
      <path
        d='M647.613 315.523L647.414 315.877L647.529 315.257L647.718 314.705L647.613 315.523Z'
        fill='url(#paint3434_linear_3150_15389)'
      />
      <path
        d='M602.003 369.881L601.489 368.028L602.161 366.196L602.549 367.92L602.003 369.881Z'
        fill='url(#paint3435_linear_3150_15389)'
      />
      <path
        d='M647.498 330.519L647.309 329.583L647.456 328.991L647.687 329.76L647.498 330.519Z'
        fill='url(#paint3436_linear_3150_15389)'
      />
      <path
        d='M635.578 345.948L635.326 344.362L635.609 343.514L635.956 344.953L635.578 345.948Z'
        fill='url(#paint3437_linear_3150_15389)'
      />
      <path
        d='M638.477 305.709L638.224 306.458L638.277 305.66L638.508 304.723L638.477 305.709Z'
        fill='url(#paint3438_linear_3150_15389)'
      />
      <path
        d='M565.394 346.392L564.827 345.081L564.911 343.239L565.425 344.421L565.394 346.392Z'
        fill='url(#paint3439_linear_3150_15389)'
      />
      <path
        d='M412.57 232.207L408.033 228.699L410.921 225.684L415.322 229.399L412.57 232.207Z'
        fill='url(#paint3440_linear_3150_15389)'
      />
      <path
        d='M585.946 366.935L585.589 364.777L585.862 362.787L586.083 364.816L585.946 366.935Z'
        fill='url(#paint3441_linear_3150_15389)'
      />
      <path
        d='M384.499 396.119L384.089 399.331L380.707 398.966L381.243 395.626L384.499 396.119Z'
        fill='url(#paint3442_linear_3150_15389)'
      />
      <path
        d='M498.266 201.318L494.201 198.825L495.115 194.933L499.179 197.495L498.266 201.318Z'
        fill='url(#paint3443_linear_3150_15389)'
      />
      <path
        d='M599.703 352.49L598.853 351.19L599.325 349.751L600.344 350.865L599.703 352.49Z'
        fill='url(#paint3444_linear_3150_15389)'
      />
      <path
        d='M354.064 347.337L351.05 350.48L348.005 348.372L351.418 345.426L354.064 347.337Z'
        fill='url(#paint3445_linear_3150_15389)'
      />
      <path
        d='M366.32 270.928L360.964 269.027L362.193 265.756L367.486 267.933L366.32 270.928Z'
        fill='url(#paint3446_linear_3150_15389)'
      />
      <path
        d='M648.023 331.888L647.886 330.794L648.033 330.213L648.222 331.149L648.023 331.888Z'
        fill='url(#paint3447_linear_3150_15389)'
      />
      <path
        d='M366.498 393.853L366.12 397.341L362.739 396.769L363.348 393.242L366.498 393.853Z'
        fill='url(#paint3448_linear_3150_15389)'
      />
      <path
        d='M632.333 385.369L632.795 383.261L633.446 381.734L632.911 383.675L632.333 385.369Z'
        fill='url(#paint3449_linear_3150_15389)'
      />
      <path
        d='M613.566 356.471L612.988 354.875L613.482 353.564L614.165 354.993L613.566 356.471Z'
        fill='url(#paint3450_linear_3150_15389)'
      />
      <path
        d='M642.163 340.874L642.005 339.337L642.205 338.627L642.488 340.026L642.163 340.874Z'
        fill='url(#paint3451_linear_3150_15389)'
      />
      <path
        d='M632.175 296.575L632.144 297.6L632.165 296.634L632.154 295.422L632.175 296.575Z'
        fill='url(#paint3452_linear_3150_15389)'
      />
      <path
        d='M603.568 355.742L602.875 354.244L603.358 352.806L604.188 354.116L603.568 355.742Z'
        fill='url(#paint3453_linear_3150_15389)'
      />
      <path
        d='M623.06 356.678L622.545 355.023L623.028 353.85L623.69 355.338L623.06 356.678Z'
        fill='url(#paint3454_linear_3150_15389)'
      />
      <path
        d='M576.032 347.012L575.318 345.83L575.56 344.174L576.4 345.17L576.032 347.012Z'
        fill='url(#paint3455_linear_3150_15389)'
      />
      <path
        d='M488.457 201.278L484.319 198.766L485.453 194.854L489.549 197.475L488.457 201.278Z'
        fill='url(#paint3456_linear_3150_15389)'
      />
      <path
        d='M439.34 221.743L434.866 218.56L436.599 215.309L441.093 218.521L439.34 221.743Z'
        fill='url(#paint3457_linear_3150_15389)'
      />
      <path
        d='M424.931 433.304L423.366 436.526L420.342 435.649L421.99 432.535L424.931 433.304Z'
        fill='url(#paint3458_linear_3150_15389)'
      />
      <path
        d='M588.277 363.683L587.647 361.9L588.151 359.978L588.687 361.624L588.277 363.683Z'
        fill='url(#paint3459_linear_3150_15389)'
      />
      <path
        d='M583.52 348.894L582.795 347.643L583.121 346.056L583.919 347.15L583.52 348.894Z'
        fill='url(#paint3460_linear_3150_15389)'
      />
      <path
        d='M564.281 358.648L563.608 356.697L563.65 354.382L564.186 356.205L564.281 358.648Z'
        fill='url(#paint3461_linear_3150_15389)'
      />
      <path
        d='M354.505 354.579L351.974 357.929L348.803 356.047L351.764 352.914L354.505 354.579Z'
        fill='url(#paint3462_linear_3150_15389)'
      />
      <path
        d='M647.046 313.276L646.868 313.729L646.973 313.108L647.13 312.458L647.046 313.276Z'
        fill='url(#paint3463_linear_3150_15389)'
      />
      <path
        d='M611.917 370.373L611.623 368.363L612.232 366.747L612.495 368.58L611.917 370.373Z'
        fill='url(#paint3464_linear_3150_15389)'
      />
      <path
        d='M648.81 333.238L648.758 331.977L648.894 331.405L649.02 332.509L648.81 333.238Z'
        fill='url(#paint3465_linear_3150_15389)'
      />
      <path
        d='M597.025 365.063L596.469 363.24L597.025 361.456L597.551 363.122L597.025 365.063Z'
        fill='url(#paint3466_linear_3150_15389)'
      />
      <path
        d='M392.154 244.01L387.513 240.67L390.243 237.685L394.727 241.232L392.154 244.01Z'
        fill='url(#paint3467_linear_3150_15389)'
      />
      <path
        d='M597.498 358.166L596.773 356.619L597.299 355.013L598.097 356.372L597.498 358.166Z'
        fill='url(#paint3468_linear_3150_15389)'
      />
      <path
        d='M578.553 350.796L577.87 349.426L578.154 347.722L578.878 348.914L578.553 350.796Z'
        fill='url(#paint3469_linear_3150_15389)'
      />
      <path
        d='M637.668 347.544L637.563 345.771L637.826 344.943L638.057 346.569L637.668 347.544Z'
        fill='url(#paint3470_linear_3150_15389)'
      />
      <path
        d='M354.537 362.531L352.531 366.058L349.244 364.442L351.68 361.122L354.537 362.531Z'
        fill='url(#paint3471_linear_3150_15389)'
      />
      <path
        d='M593.423 354.333L592.573 352.944L593.077 351.357L594.022 352.569L593.423 354.333Z'
        fill='url(#paint3472_linear_3150_15389)'
      />
      <path
        d='M581.063 362.057L580.664 359.959L580.811 358.028L581.168 359.939L581.063 362.057Z'
        fill='url(#paint3473_linear_3150_15389)'
      />
      <path
        d='M355.702 369.418L354.064 373.014L350.798 371.605L352.846 368.186L355.702 369.418Z'
        fill='url(#paint3474_linear_3150_15389)'
      />
      <path
        d='M381.779 253.863L376.937 250.848L379.29 247.823L383.973 251.075L381.779 253.863Z'
        fill='url(#paint3475_linear_3150_15389)'
      />
      <path
        d='M572.052 360.668L571.506 358.579L571.569 356.471L572.094 358.313L572.052 360.668Z'
        fill='url(#paint3476_linear_3150_15389)'
      />
      <path
        d='M601.258 361.9L600.638 360.166L601.195 358.54L601.867 360.087L601.258 361.9Z'
        fill='url(#paint3477_linear_3150_15389)'
      />
      <path
        d='M643.948 342.263L643.927 340.539L644.127 339.859L644.263 341.435L643.948 342.263Z'
        fill='url(#paint3478_linear_3150_15389)'
      />
      <path
        d='M584.486 359.072L583.856 357.358L584.245 355.515L584.822 357.072L584.486 359.072Z'
        fill='url(#paint3479_linear_3150_15389)'
      />
      <path
        d='M460.711 469.493L457.949 472.222L456.783 471.336L459.367 468.419L460.711 469.493Z'
        fill='url(#paint3480_linear_3150_15389)'
      />
      <path
        d='M631.094 352.865L630.852 351.032L631.188 350.066L631.619 351.722L631.094 352.865Z'
        fill='url(#paint3481_linear_3150_15389)'
      />
      <path
        d='M508.999 201.071L507.602 200.155L505.271 196.007L506.468 196.914L508.999 201.071Z'
        fill='url(#paint3482_linear_3150_15389)'
      />
      <path
        d='M627.208 359.19L626.956 357.308L627.397 356.165L627.849 357.87L627.208 359.19Z'
        fill='url(#paint3483_linear_3150_15389)'
      />
      <path
        d='M357.425 375.595L356.049 379.231L352.877 377.999L354.61 374.511L357.425 375.595Z'
        fill='url(#paint3484_linear_3150_15389)'
      />
      <path
        d='M605.732 365.861L605.27 363.939L605.826 362.314L606.372 364.018L605.732 365.861Z'
        fill='url(#paint3485_linear_3150_15389)'
      />
      <path
        d='M649.504 334.391L649.535 332.952L649.672 332.39L649.714 333.671L649.504 334.391Z'
        fill='url(#paint3486_linear_3150_15389)'
      />
      <path
        d='M607.013 358.796L606.488 357.052L606.96 355.613L607.632 357.151L607.013 358.796Z'
        fill='url(#paint3487_linear_3150_15389)'
      />
      <path
        d='M592.153 360.619L591.512 358.895L591.985 357.151L592.709 358.658L592.153 360.619Z'
        fill='url(#paint3488_linear_3150_15389)'
      />
      <path
        d='M588.425 356.51L587.658 354.973L588.141 353.259L588.918 354.619L588.425 356.51Z'
        fill='url(#paint3489_linear_3150_15389)'
      />
      <path
        d='M621.81 370.225L621.716 368.107L622.283 366.669L622.398 368.61L621.81 370.225Z'
        fill='url(#paint3490_linear_3150_15389)'
      />
      <path
        d='M478.386 201.82L474.269 199.219L475.477 195.376L479.688 198.037L478.386 201.82Z'
        fill='url(#paint3491_linear_3150_15389)'
      />
      <path
        d='M589.433 350.579L588.446 349.337L588.918 347.781L590.052 348.825L589.433 350.579Z'
        fill='url(#paint3492_linear_3150_15389)'
      />
      <path
        d='M646.553 311.098L646.427 311.65L646.521 311.029L646.626 310.281L646.553 311.098Z'
        fill='url(#paint3493_linear_3150_15389)'
      />
      <path
        d='M376.759 396.759L376.444 400.011L373.167 399.666L373.587 396.257L376.759 396.759Z'
        fill='url(#paint3494_linear_3150_15389)'
      />
      <path
        d='M628.353 374.453L628.51 372.236L629.067 370.846L628.899 372.886L628.353 374.453Z'
        fill='url(#paint3495_linear_3150_15389)'
      />
      <path
        d='M637.363 303.069L637.206 303.876L637.237 303.069L637.374 302.083L637.363 303.069Z'
        fill='url(#paint3496_linear_3150_15389)'
      />
      <path
        d='M560.868 353.535L560.238 351.692L560.164 349.505L560.679 351.21L560.868 353.535Z'
        fill='url(#paint3497_linear_3150_15389)'
      />
      <path
        d='M402.089 234.423L397.563 230.896L400.493 227.93L404.883 231.664L402.089 234.423Z'
        fill='url(#paint3498_linear_3150_15389)'
      />
      <path
        d='M580.537 354.707L579.886 353.101L580.18 351.328L580.895 352.727L580.537 354.707Z'
        fill='url(#paint3499_linear_3150_15389)'
      />
      <path
        d='M616.107 358.855L615.645 357.003L616.128 355.712L616.738 357.358L616.107 358.855Z'
        fill='url(#paint3500_linear_3150_15389)'
      />
      <path
        d='M646.122 343.721L646.248 341.82L646.437 341.159L646.416 342.923L646.122 343.721Z'
        fill='url(#paint3501_linear_3150_15389)'
      />
      <path
        d='M570.088 348.658L569.426 347.249L569.563 345.485L570.382 346.628L570.088 348.658Z'
        fill='url(#paint3502_linear_3150_15389)'
      />
      <path
        d='M650.354 335.484L650.47 333.878L650.596 333.327L650.564 334.795L650.354 335.484Z'
        fill='url(#paint3503_linear_3150_15389)'
      />
      <path
        d='M456.122 212.905L451.952 210.156L452.771 206.638L457.046 209.348L456.122 212.905Z'
        fill='url(#paint3504_linear_3150_15389)'
      />
      <path
        d='M639.821 348.934L639.873 346.943L640.104 346.155L640.209 347.988L639.821 348.934Z'
        fill='url(#paint3505_linear_3150_15389)'
      />
      <path
        d='M635.084 354.973L635.063 352.924L635.389 351.998L635.557 353.87L635.084 354.973Z'
        fill='url(#paint3506_linear_3150_15389)'
      />
      <path
        d='M615.267 365.802L614.889 363.762L615.498 362.294L615.834 364.166L615.267 365.802Z'
        fill='url(#paint3507_linear_3150_15389)'
      />
      <path
        d='M634.412 378.472L634.811 376.177L635.347 374.817L634.927 376.945L634.412 378.472Z'
        fill='url(#paint3508_linear_3150_15389)'
      />
      <path
        d='M420.184 413.775L418.598 416.908L415.616 415.765L417.306 412.672L420.184 413.775Z'
        fill='url(#paint3509_linear_3150_15389)'
      />
      <path
        d='M610.573 361.979L610.1 360.038L610.636 358.59L611.14 360.343L610.573 361.979Z'
        fill='url(#paint3510_linear_3150_15389)'
      />
      <path
        d='M584.686 352.303L583.825 350.885L584.297 349.19L585.074 350.5L584.686 352.303Z'
        fill='url(#paint3511_linear_3150_15389)'
      />
      <path
        d='M424.542 440.191L422.988 443.491L419.943 442.733L421.591 439.609L424.542 440.191Z'
        fill='url(#paint3512_linear_3150_15389)'
      />
      <path
        d='M631.997 361.545L632.028 359.417L632.406 358.333L632.574 360.284L631.997 361.545Z'
        fill='url(#paint3513_linear_3150_15389)'
      />
      <path
        d='M572.703 352.934L572.178 351.16L572.231 349.387L572.934 350.796L572.703 352.934Z'
        fill='url(#paint3514_linear_3150_15389)'
      />
      <path
        d='M430.266 223.299L425.687 219.93L427.777 216.806L432.303 220.275L430.266 223.299Z'
        fill='url(#paint3515_linear_3150_15389)'
      />
      <path
        d='M368.378 264.357L363.222 261.962L364.839 258.829L369.901 261.48L368.378 264.357Z'
        fill='url(#paint3516_linear_3150_15389)'
      />
      <path
        d='M575.822 357.377L575.308 355.377L575.392 353.495L575.917 355.249L575.822 357.377Z'
        fill='url(#paint3517_linear_3150_15389)'
      />
      <path
        d='M398.466 398.04L397.678 401.193L394.57 400.612L395.494 397.38L398.466 398.04Z'
        fill='url(#paint3518_linear_3150_15389)'
      />
      <path
        d='M651.499 336.607L651.72 334.814L651.835 334.282L651.699 335.928L651.499 336.607Z'
        fill='url(#paint3519_linear_3150_15389)'
      />
      <path
        d='M567.463 355.722L566.822 353.811L566.843 351.751L567.484 353.416L567.463 355.722Z'
        fill='url(#paint3520_linear_3150_15389)'
      />
      <path
        d='M648.401 345.101L648.685 343.022L648.853 342.391L648.674 344.322L648.401 345.101Z'
        fill='url(#paint3521_linear_3150_15389)'
      />
      <path
        d='M619.909 361.575L619.626 359.486L620.088 358.215L620.529 360.106L619.909 361.575Z'
        fill='url(#paint3522_linear_3150_15389)'
      />
      <path
        d='M639.443 357.16L639.663 354.934L639.947 354.047L639.852 356.106L639.443 357.16Z'
        fill='url(#paint3523_linear_3150_15389)'
      />
      <path
        d='M636.523 300.684L636.45 301.571L636.46 300.763L636.502 299.699L636.523 300.684Z'
        fill='url(#paint3524_linear_3150_15389)'
      />
      <path
        d='M564.617 350.914L563.966 349.229L563.976 347.288L564.669 348.707L564.617 350.914Z'
        fill='url(#paint3525_linear_3150_15389)'
      />
      <path
        d='M641.018 382.719L641.659 380.364L642.205 379.053L641.47 381.241L641.018 382.719Z'
        fill='url(#paint3526_linear_3150_15389)'
      />
      <path
        d='M625.727 364.984L625.643 362.747L626.126 361.506L626.242 363.565L625.727 364.984Z'
        fill='url(#paint3527_linear_3150_15389)'
      />
      <path
        d='M369.05 396.779L368.788 400.178L365.616 399.774L366.015 396.267L369.05 396.779Z'
        fill='url(#paint3528_linear_3150_15389)'
      />
      <path
        d='M642.593 350.421L642.803 348.224L643.014 347.475L642.94 349.515L642.593 350.421Z'
        fill='url(#paint3529_linear_3150_15389)'
      />
      <path
        d='M357.897 383.074L357.078 386.729L353.854 385.833L354.978 382.226L357.897 383.074Z'
        fill='url(#paint3530_linear_3150_15389)'
      />
      <path
        d='M424.563 449.384L422.988 452.773L420.006 452.182L421.707 449.058L424.563 449.384Z'
        fill='url(#paint3531_linear_3150_15389)'
      />
      <path
        d='M631.419 368.462L631.566 366.107L632.028 364.905L631.892 367.092L631.419 368.462Z'
        fill='url(#paint3532_linear_3150_15389)'
      />
      <path
        d='M652.843 337.682L653.179 335.711L653.284 335.199L653.043 337.021L652.843 337.682Z'
        fill='url(#paint3533_linear_3150_15389)'
      />
      <path
        d='M447.794 474.213L445.147 477.218L443.603 476.775L446.124 473.582L447.794 474.213Z'
        fill='url(#paint3534_linear_3150_15389)'
      />
      <path
        d='M468.073 203.338L463.924 200.598L465.143 196.933L469.417 199.682L468.073 203.338Z'
        fill='url(#paint3535_linear_3150_15389)'
      />
      <path
        d='M496.48 480.154L493.865 482.312L494.632 480.726L497.016 478.41L496.48 480.154Z'
        fill='url(#paint3536_linear_3150_15389)'
      />
      <path
        d='M650.638 346.293L651.068 344.027L651.215 343.426L650.9 345.554L650.638 346.293Z'
        fill='url(#paint3537_linear_3150_15389)'
      />
      <path
        d='M391.913 237.557L387.418 233.99L390.317 231.103L394.664 234.877L391.913 237.557Z'
        fill='url(#paint3538_linear_3150_15389)'
      />
      <path
        d='M636.114 363.575L636.324 361.19L636.681 360.166L636.597 362.373L636.114 363.575Z'
        fill='url(#paint3539_linear_3150_15389)'
      />
      <path
        d='M422.494 224.748L418.01 221.112L420.657 218.205L425.088 221.94L422.494 224.748Z'
        fill='url(#paint3540_linear_3150_15389)'
      />
      <path
        d='M447.772 213.998L443.393 210.973L444.727 207.614L449.117 210.688L447.772 213.998Z'
        fill='url(#paint3541_linear_3150_15389)'
      />
      <path
        d='M636.723 371.812L637.038 369.348L637.5 368.176L637.153 370.472L636.723 371.812Z'
        fill='url(#paint3542_linear_3150_15389)'
      />
      <path
        d='M382.314 247.695L377.683 244.375L380.266 241.478L384.74 245.025L382.314 247.695Z'
        fill='url(#paint3543_linear_3150_15389)'
      />
      <path
        d='M654.492 338.746L654.954 336.608L655.038 336.115L654.681 338.125L654.492 338.746Z'
        fill='url(#paint3544_linear_3150_15389)'
      />
      <path
        d='M645.765 351.978L646.143 349.603L646.322 348.894L646.059 351.121L645.765 351.978Z'
        fill='url(#paint3545_linear_3150_15389)'
      />
      <path
        d='M387.555 396.444L387.156 399.607L383.984 399.292L384.457 395.952L387.555 396.444Z'
        fill='url(#paint3546_linear_3150_15389)'
      />
      <path
        d='M350.577 304.556L345.347 305.265L344.203 302.181L349.643 301.768L350.577 304.556Z'
        fill='url(#paint3547_linear_3150_15389)'
      />
      <path
        d='M505.669 198.638L502.876 197.101L501.385 192.972L503.884 194.618L505.669 198.638Z'
        fill='url(#paint3548_linear_3150_15389)'
      />
      <path
        d='M642.572 358.747L642.951 356.303L643.182 355.456L642.951 357.742L642.572 358.747Z'
        fill='url(#paint3549_linear_3150_15389)'
      />
      <path
        d='M350.294 311.552L345.326 312.783L343.814 309.827L349.023 308.891L350.294 311.552Z'
        fill='url(#paint3550_linear_3150_15389)'
      />
      <path
        d='M351.701 297.265L346.272 297.354L345.6 294.211L351.155 294.378L351.701 297.265Z'
        fill='url(#paint3551_linear_3150_15389)'
      />
      <path
        d='M352.426 290.279L346.912 289.797L346.681 286.634L352.289 287.333L352.426 290.279Z'
        fill='url(#paint3552_linear_3150_15389)'
      />
      <path
        d='M642.404 375.191L642.95 372.62L643.381 371.496L642.814 373.891L642.404 375.191Z'
        fill='url(#paint3553_linear_3150_15389)'
      />
      <path
        d='M653.095 347.397L653.673 344.953L653.778 344.391L653.316 346.697L653.095 347.397Z'
        fill='url(#paint3554_linear_3150_15389)'
      />
      <path
        d='M351.785 317.434L347.101 319.03L345.421 316.202L350.367 314.912L351.785 317.434Z'
        fill='url(#paint3555_linear_3150_15389)'
      />
      <path
        d='M413.295 226.236L408.874 222.512L411.898 219.664L416.183 223.605L413.295 226.236Z'
        fill='url(#paint3556_linear_3150_15389)'
      />
      <path
        d='M353.644 283.284L348.13 282.249L348.361 279.086L353.927 280.347L353.644 283.284Z'
        fill='url(#paint3557_linear_3150_15389)'
      />
      <path
        d='M411.919 406.385L410.659 409.528L407.666 408.622L409.063 405.449L411.919 406.385Z'
        fill='url(#paint3558_linear_3150_15389)'
      />
      <path
        d='M424.164 459.808L422.631 463.207L419.722 462.961L421.36 459.749L424.164 459.808Z'
        fill='url(#paint3559_linear_3150_15389)'
      />
      <path
        d='M371.014 258.09L366.131 255.223L368.147 252.267L372.904 255.39L371.014 258.09Z'
        fill='url(#paint3560_linear_3150_15389)'
      />
      <path
        d='M640.094 365.624L640.461 363.033L640.787 362.057L640.514 364.471L640.094 365.624Z'
        fill='url(#paint3561_linear_3150_15389)'
      />
      <path
        d='M353.192 323.149L348.792 325.07L346.975 322.39L351.649 320.745L353.192 323.149Z'
        fill='url(#paint3562_linear_3150_15389)'
      />
      <path
        d='M440.789 215.22L436.378 211.811L438.426 208.737L442.858 212.166L440.789 215.22Z'
        fill='url(#paint3563_linear_3150_15389)'
      />
      <path
        d='M648.705 353.407L649.209 350.855L649.377 350.185L648.957 352.589L648.705 353.407Z'
        fill='url(#paint3564_linear_3150_15389)'
      />
      <path
        d='M506.877 195.504L506.016 194.825L503.454 190.657L504.084 191.307L506.877 195.504Z'
        fill='url(#paint3565_linear_3150_15389)'
      />
      <path
        d='M402.089 397.843L401.155 401.016L398.162 400.306L399.254 397.094L402.089 397.843Z'
        fill='url(#paint3566_linear_3150_15389)'
      />
      <path
        d='M353.581 329.326L349.517 331.602L347.49 329.08L351.859 327.09L353.581 329.326Z'
        fill='url(#paint3567_linear_3150_15389)'
      />
      <path
        d='M402.53 228.177L398.151 224.403L401.27 221.595L405.513 225.576L402.53 228.177Z'
        fill='url(#paint3568_linear_3150_15389)'
      />
      <path
        d='M378.754 398.592L378.66 401.814L375.509 401.597L375.708 398.237L378.754 398.592Z'
        fill='url(#paint3569_linear_3150_15389)'
      />
      <path
        d='M503.674 196.096L499.873 193.908L499.747 189.918L503.349 192.233L503.674 196.096Z'
        fill='url(#paint3570_linear_3150_15389)'
      />
      <path
        d='M358.769 389.587L358.506 393.232L355.251 392.621L355.807 389.015L358.769 389.587Z'
        fill='url(#paint3571_linear_3150_15389)'
      />
      <path
        d='M352.457 336.548L348.834 339.219L346.524 336.913L350.483 334.499L352.457 336.548Z'
        fill='url(#paint3572_linear_3150_15389)'
      />
      <path
        d='M498.329 194.697L494.244 192.086L495.105 188.243L499.169 190.943L498.329 194.697Z'
        fill='url(#paint3573_linear_3150_15389)'
      />
      <path
        d='M645.376 360.038L645.859 357.368L646.069 356.58L645.702 359.082L645.376 360.038Z'
        fill='url(#paint3574_linear_3150_15389)'
      />
      <path
        d='M418.199 418.83L416.897 422.121L413.725 421.145L415.143 417.943L418.199 418.83Z'
        fill='url(#paint3575_linear_3150_15389)'
      />
      <path
        d='M457.13 205.18L452.887 202.293L454.042 198.785L458.39 201.662L457.13 205.18Z'
        fill='url(#paint3576_linear_3150_15389)'
      />
      <path
        d='M355.933 276.269L350.525 274.663L351.25 271.559L356.658 273.421L355.933 276.269Z'
        fill='url(#paint3577_linear_3150_15389)'
      />
      <path
        d='M351.68 343.701L348.519 346.697L345.999 344.608L349.506 341.859L351.68 343.701Z'
        fill='url(#paint3578_linear_3150_15389)'
      />
      <path
        d='M488.121 194.48L483.983 191.849L485.012 187.987L489.14 190.716L488.121 194.48Z'
        fill='url(#paint3579_linear_3150_15389)'
      />
      <path
        d='M464.239 471.75L461.688 474.617L459.976 473.74L462.433 470.853L464.239 471.75Z'
        fill='url(#paint3580_linear_3150_15389)'
      />
      <path
        d='M644.557 367.812L645.082 365.043L645.376 364.117L644.893 366.718L644.557 367.812Z'
        fill='url(#paint3581_linear_3150_15389)'
      />
      <path
        d='M352.142 349.85L349.349 353.032L346.734 351.15L349.863 348.175L352.142 349.85Z'
        fill='url(#paint3582_linear_3150_15389)'
      />
      <path
        d='M650.921 354.53L651.646 351.17L651.142 353.761L650.921 354.53Z'
        fill='url(#paint3583_linear_3150_15389)'
      />
      <path
        d='M381.684 241.528L377.189 237.971L379.941 235.163L384.278 238.946L381.684 241.528Z'
        fill='url(#paint3584_linear_3150_15389)'
      />
      <path
        d='M478.827 194.559L474.616 191.79L476.002 187.987L480.224 190.864L478.827 194.559Z'
        fill='url(#paint3585_linear_3150_15389)'
      />
      <path
        d='M351.985 356.599L349.643 359.998L346.902 358.323L349.59 355.141L351.985 356.599Z'
        fill='url(#paint3586_linear_3150_15389)'
      />
      <path
        d='M391.556 231.211L387.198 227.428L390.275 224.649L394.486 228.659L391.556 231.211Z'
        fill='url(#paint3587_linear_3150_15389)'
      />
      <path
        d='M370.605 399.439L370.657 402.75L367.601 402.563L367.632 399.095L370.605 399.439Z'
        fill='url(#paint3588_linear_3150_15389)'
      />
      <path
        d='M352.132 363.161L350.21 366.708L347.406 365.25L349.674 361.88L352.132 363.161Z'
        fill='url(#paint3589_linear_3150_15389)'
      />
      <path
        d='M361.027 393.803L360.932 397.39L357.834 396.927L358.17 393.34L361.027 393.803Z'
        fill='url(#paint3590_linear_3150_15389)'
      />
      <path
        d='M431.432 217.289L426.947 213.683L429.247 210.767L433.71 214.442L431.432 217.289Z'
        fill='url(#paint3591_linear_3150_15389)'
      />
      <path
        d='M647.928 361.269L648.464 358.402L648.663 357.663L648.191 360.372L647.928 361.269Z'
        fill='url(#paint3592_linear_3150_15389)'
      />
      <path
        d='M371.833 252.178L367.16 248.957L369.439 246.129L373.965 249.607L371.833 252.178Z'
        fill='url(#paint3593_linear_3150_15389)'
      />
      <path
        d='M357.687 269.785L352.436 267.677L353.57 264.652L358.779 267.036L357.687 269.785Z'
        fill='url(#paint3594_linear_3150_15389)'
      />
      <path
        d='M649.745 370.186L650.449 367.279L650.701 366.422L649.987 369.171L649.745 370.186Z'
        fill='url(#paint3595_linear_3150_15389)'
      />
      <path
        d='M652.822 355.358L653.473 352.441L653.568 351.86L653.022 354.638L652.822 355.358Z'
        fill='url(#paint3596_linear_3150_15389)'
      />
      <path
        d='M352.604 369.368L351.082 372.994L348.246 371.772L350.073 368.255L352.604 369.368Z'
        fill='url(#paint3597_linear_3150_15389)'
      />
      <path
        d='M469.543 195.781L465.342 192.775L466.981 189.189L471.307 192.224L469.543 195.781Z'
        fill='url(#paint3598_linear_3150_15389)'
      />
      <path
        d='M423.377 218.669L418.997 214.846L421.864 212.097L426.16 216.068L423.377 218.669Z'
        fill='url(#paint3599_linear_3150_15389)'
      />
      <path
        d='M449.505 207.387L445.199 204.037L446.88 200.924L451.322 204.175L449.505 207.387Z'
        fill='url(#paint3600_linear_3150_15389)'
      />
      <path
        d='M390.621 396.405L390.317 399.607L387.261 399.292L387.702 395.971L390.621 396.405Z'
        fill='url(#paint3601_linear_3150_15389)'
      />
      <path
        d='M651.783 362.777L652.444 359.742L652.591 359.062L651.972 361.959L651.783 362.777Z'
        fill='url(#paint3602_linear_3150_15389)'
      />
      <path
        d='M381.527 398.976L381.432 402.168L378.45 401.971L378.66 398.651L381.527 398.976Z'
        fill='url(#paint3603_linear_3150_15389)'
      />
      <path
        d='M502.225 193.416L500.051 192.036L497.94 187.908L499.957 189.297L502.225 193.416Z'
        fill='url(#paint3604_linear_3150_15389)'
      />
      <path
        d='M415.878 424.741L414.86 428.131L411.541 427.392L412.696 424.091L415.878 424.741Z'
        fill='url(#paint3605_linear_3150_15389)'
      />
      <path
        d='M380.823 235.409L376.475 231.625L379.416 228.906L383.595 232.916L380.823 235.409Z'
        fill='url(#paint3606_linear_3150_15389)'
      />
      <path
        d='M413.274 220.255L408.926 216.344L412.024 213.654L416.267 217.762L413.274 220.255Z'
        fill='url(#paint3607_linear_3150_15389)'
      />
      <path
        d='M403.118 400.168L402.373 403.41L399.369 402.789L400.283 399.528L403.118 400.168Z'
        fill='url(#paint3608_linear_3150_15389)'
      />
      <path
        d='M359.62 263.598L354.579 260.987L356.101 258.1L361.058 260.967L359.62 263.598Z'
        fill='url(#paint3609_linear_3150_15389)'
      />
      <path
        d='M352.237 376.66L351.333 380.404L348.372 379.438L349.611 375.832L352.237 376.66Z'
        fill='url(#paint3610_linear_3150_15389)'
      />
      <path
        d='M412.423 408.405L411.383 411.716L408.275 410.839L409.483 407.578L412.423 408.405Z'
        fill='url(#paint3611_linear_3150_15389)'
      />
      <path
        d='M402.709 222.107L398.456 218.117L401.753 215.466L405.87 219.664L402.709 222.107Z'
        fill='url(#paint3612_linear_3150_15389)'
      />
      <path
        d='M371.172 246.375L366.656 242.887L369.134 240.148L373.482 243.882L371.172 246.375Z'
        fill='url(#paint3613_linear_3150_15389)'
      />
      <path
        d='M420.867 468.754L419.743 472.124L416.697 472.39L417.884 469.099L420.867 468.754Z'
        fill='url(#paint3614_linear_3150_15389)'
      />
      <path
        d='M362.392 398.168L362.655 401.676L359.651 401.44L359.567 397.883L362.392 398.168Z'
        fill='url(#paint3615_linear_3150_15389)'
      />
      <path
        d='M373.22 399.981L373.304 403.262L370.405 403.124L370.426 399.705L373.22 399.981Z'
        fill='url(#paint3616_linear_3150_15389)'
      />
      <path
        d='M442.522 209.417L438.184 205.791L440.579 202.933L444.884 206.638L442.522 209.417Z'
        fill='url(#paint3617_linear_3150_15389)'
      />
      <path
        d='M391.314 225.093L387.103 221.073L390.38 218.432L394.444 222.669L391.314 225.093Z'
        fill='url(#paint3618_linear_3150_15389)'
      />
      <path
        d='M352.541 383.428L352.153 387.202L349.16 386.493L349.874 382.818L352.541 383.428Z'
        fill='url(#paint3619_linear_3150_15389)'
      />
      <path
        d='M346.744 307.069L341.735 307.857L340.653 305.088L345.862 304.606L346.744 307.069Z'
        fill='url(#paint3620_linear_3150_15389)'
      />
      <path
        d='M347.29 312.892L342.522 314.114L341.199 311.444L346.188 310.537L347.29 312.892Z'
        fill='url(#paint3621_linear_3150_15389)'
      />
      <path
        d='M459.713 198.599L455.491 195.318L457.172 192.076L461.572 195.288L459.713 198.599Z'
        fill='url(#paint3622_linear_3150_15389)'
      />
      <path
        d='M351.46 327.71L347.385 329.819L345.767 327.484L350.073 325.661L351.46 327.71Z'
        fill='url(#paint3623_linear_3150_15389)'
      />
      <path
        d='M349.464 317.581L344.927 319.109L343.52 316.537L348.288 315.335L349.464 317.581Z'
        fill='url(#paint3624_linear_3150_15389)'
      />
      <path
        d='M395.095 395.084L394.57 398.316L391.65 397.833L392.344 394.542L395.095 395.084Z'
        fill='url(#paint3625_linear_3150_15389)'
      />
      <path
        d='M351.271 322.242L346.944 324.035L345.474 321.562L350.031 320.094L351.271 322.242Z'
        fill='url(#paint3626_linear_3150_15389)'
      />
      <path
        d='M499.894 190.982L496.417 188.962L495.567 184.923L498.78 187.11L499.894 190.982Z'
        fill='url(#paint3627_linear_3150_15389)'
      />
      <path
        d='M350.409 334.006L346.713 336.499L344.843 334.332L348.813 332.095L350.409 334.006Z'
        fill='url(#paint3628_linear_3150_15389)'
      />
      <path
        d='M414.849 429.737L413.914 433.205L410.512 432.565L411.562 429.195L414.849 429.737Z'
        fill='url(#paint3629_linear_3150_15389)'
      />
      <path
        d='M360.68 257.804L355.86 254.78L357.698 252.021L362.403 255.292L360.68 257.804Z'
        fill='url(#paint3630_linear_3150_15389)'
      />
      <path
        d='M497.016 188.411L492.899 185.819L493.487 181.937L497.457 184.686L497.016 188.411Z'
        fill='url(#paint3631_linear_3150_15389)'
      />
      <path
        d='M488.152 187.672L484.004 184.873L484.991 181.06L489.203 183.928L488.152 187.672Z'
        fill='url(#paint3632_linear_3150_15389)'
      />
      <path
        d='M384.898 398.06L384.698 401.242L381.852 401.016L382.167 397.685L384.898 398.06Z'
        fill='url(#paint3633_linear_3150_15389)'
      />
      <path
        d='M349.475 340.391L346.198 343.219L344.108 341.249L347.679 338.637L349.475 340.391Z'
        fill='url(#paint3634_linear_3150_15389)'
      />
      <path
        d='M370.185 240.64L365.826 236.886L368.494 234.236L372.684 238.236L370.185 240.64Z'
        fill='url(#paint3635_linear_3150_15389)'
      />
      <path
        d='M432.975 211.437L428.617 207.584L431.232 204.914L435.569 208.806L432.975 211.437Z'
        fill='url(#paint3636_linear_3150_15389)'
      />
      <path
        d='M500.891 186.056L499.106 185.071L496.806 180.745L498.276 181.79L500.891 186.056Z'
        fill='url(#paint3637_linear_3150_15389)'
      />
      <path
        d='M380.119 229.388L375.96 225.339L379.111 222.738L383.091 227.014L380.119 229.388Z'
        fill='url(#paint3638_linear_3150_15389)'
      />
      <path
        d='M346.629 282.604L341.189 281.353L341.42 278.446L346.912 279.963L346.629 282.604Z'
        fill='url(#paint3639_linear_3150_15389)'
      />
      <path
        d='M353.791 388.581L353.738 392.306L350.819 391.813L351.134 388.099L353.791 388.581Z'
        fill='url(#paint3640_linear_3150_15389)'
      />
      <path
        d='M478.89 187.928L474.636 184.982L476.044 181.267L480.318 184.331L478.89 187.928Z'
        fill='url(#paint3641_linear_3150_15389)'
      />
      <path
        d='M349.779 345.81L346.86 348.874L344.665 347.081L347.868 344.214L349.779 345.81Z'
        fill='url(#paint3642_linear_3150_15389)'
      />
      <path
        d='M442.9 474.371L441.23 477.455L438.415 477.563L440.001 474.302L442.9 474.371Z'
        fill='url(#paint3643_linear_3150_15389)'
      />
      <path
        d='M364.45 400.159L364.839 403.548L362.003 403.489L361.699 399.932L364.45 400.159Z'
        fill='url(#paint3644_linear_3150_15389)'
      />
      <path
        d='M412.948 411.174L411.982 414.593L408.8 413.716L409.924 410.366L412.948 411.174Z'
        fill='url(#paint3645_linear_3150_15389)'
      />
      <path
        d='M404.61 401.962L404.001 405.282L400.987 404.701L401.785 401.38L404.61 401.962Z'
        fill='url(#paint3646_linear_3150_15389)'
      />
      <path
        d='M375.456 400.523L375.593 403.765L372.81 403.696L372.778 400.296L375.456 400.523Z'
        fill='url(#paint3647_linear_3150_15389)'
      />
      <path
        d='M471.003 188.637L466.749 185.366L468.818 181.898L473.145 185.258L471.003 188.637Z'
        fill='url(#paint3648_linear_3150_15389)'
      />
      <path
        d='M349.968 351.209L347.427 354.48L345.137 352.855L347.962 349.761L349.968 351.209Z'
        fill='url(#paint3649_linear_3150_15389)'
      />
      <path
        d='M424.101 213.121L419.816 209.111L422.851 206.559L427.042 210.727L424.101 213.121Z'
        fill='url(#paint3650_linear_3150_15389)'
      />
      <path
        d='M413.064 436.171L412.234 439.708L408.747 439.225L409.672 435.836L413.064 436.171Z'
        fill='url(#paint3651_linear_3150_15389)'
      />
      <path
        d='M348.299 276.269L342.974 274.456L343.615 271.608L348.939 273.707L348.299 276.269Z'
        fill='url(#paint3652_linear_3150_15389)'
      />
      <path
        d='M453.149 201.485L448.959 197.79L451.354 195.002L455.617 198.657L453.149 201.485Z'
        fill='url(#paint3653_linear_3150_15389)'
      />
      <path
        d='M360.985 252.277L356.375 248.907L358.475 246.247L362.938 249.883L360.985 252.277Z'
        fill='url(#paint3654_linear_3150_15389)'
      />
      <path
        d='M349.758 356.904L347.637 360.363L345.232 358.934L347.658 355.643L349.758 356.904Z'
        fill='url(#paint3655_linear_3150_15389)'
      />
      <path
        d='M414.145 214.836L409.945 210.668L413.232 208.215L417.328 212.54L414.145 214.836Z'
        fill='url(#paint3656_linear_3150_15389)'
      />
      <path
        d='M403.969 216.777L399.936 212.501L403.455 210.107L407.351 214.59L403.969 216.777Z'
        fill='url(#paint3657_linear_3150_15389)'
      />
      <path
        d='M349.475 362.678L347.763 366.314L345.284 365.043L347.301 361.604L349.475 362.678Z'
        fill='url(#paint3658_linear_3150_15389)'
      />
      <path
        d='M397.174 396.434L396.754 399.725L393.866 399.272L394.475 395.961L397.174 396.434Z'
        fill='url(#paint3659_linear_3150_15389)'
      />
      <path
        d='M354.789 393.478L355.167 397.173L352.289 396.868L352.163 393.192L354.789 393.478Z'
        fill='url(#paint3660_linear_3150_15389)'
      />
      <path
        d='M392.365 219.555L388.416 215.24L391.944 212.836L395.746 217.378L392.365 219.555Z'
        fill='url(#paint3661_linear_3150_15389)'
      />
      <path
        d='M387.954 397.469L387.723 400.69L384.95 400.415L385.328 397.114L387.954 397.469Z'
        fill='url(#paint3662_linear_3150_15389)'
      />
      <path
        d='M369.932 235.024L365.816 230.955L368.735 228.443L372.642 232.758L369.932 235.024Z'
        fill='url(#paint3663_linear_3150_15389)'
      />
      <path
        d='M348.866 368.965L347.616 372.728L345.064 371.664L346.618 368.078L348.866 368.965Z'
        fill='url(#paint3664_linear_3150_15389)'
      />
      <path
        d='M497.447 183.632L494.38 181.848L492.952 177.641L495.913 179.464L497.447 183.632Z'
        fill='url(#paint3665_linear_3150_15389)'
      />
      <path
        d='M462.202 190.982L458.012 187.415L460.406 184.292L464.712 187.868L462.202 190.982Z'
        fill='url(#paint3666_linear_3150_15389)'
      />
      <path
        d='M407.267 402.809L406.563 406.208L403.559 405.509L404.452 402.159L407.267 402.809Z'
        fill='url(#paint3667_linear_3150_15389)'
      />
      <path
        d='M498.371 179.514L497.331 178.912L494.674 174.489L495.43 175.07L498.371 179.514Z'
        fill='url(#paint3668_linear_3150_15389)'
      />
      <path
        d='M380.707 223.694L376.885 219.309L380.319 216.925L383.932 221.526L380.707 223.694Z'
        fill='url(#paint3669_linear_3150_15389)'
      />
      <path
        d='M444.202 204.067L440.001 200.185L442.69 197.603L446.88 201.535L444.202 204.067Z'
        fill='url(#paint3670_linear_3150_15389)'
      />
      <path
        d='M360.449 246.956L356.038 243.271L358.37 240.71L362.592 244.671L360.449 246.956Z'
        fill='url(#paint3671_linear_3150_15389)'
      />
      <path
        d='M411.415 415.696L410.711 419.224L407.414 418.504L408.275 415.056L411.415 415.696Z'
        fill='url(#paint3672_linear_3150_15389)'
      />
      <path
        d='M366.467 400.967L366.992 404.307L364.282 404.346L363.852 400.839L366.467 400.967Z'
        fill='url(#paint3673_linear_3150_15389)'
      />
      <path
        d='M378.103 399.922L378.208 403.144L375.53 403.094V399.705L378.103 399.922Z'
        fill='url(#paint3674_linear_3150_15389)'
      />
      <path
        d='M356.343 397.351L356.931 401.006L354.159 400.839L353.791 397.203L356.343 397.351Z'
        fill='url(#paint3675_linear_3150_15389)'
      />
      <path
        d='M408.317 446.605L407.96 450.201L404.389 450.191L404.862 446.831L408.317 446.605Z'
        fill='url(#paint3676_linear_3150_15389)'
      />
      <path
        d='M348.099 375.704L347.406 379.566L344.77 378.738L345.778 375.024L348.099 375.704Z'
        fill='url(#paint3677_linear_3150_15389)'
      />
      <path
        d='M361.678 400.799L361.857 404.464L359.41 404.327L359.378 400.651L361.678 400.799Z'
        fill='url(#paint3678_linear_3150_15389)'
      />
      <path
        d='M358.811 399.636L359.273 403.301L356.658 403.154L356.385 399.518L358.811 399.636Z'
        fill='url(#paint3679_linear_3150_15389)'
      />
      <path
        d='M494.464 181.809L490.463 179.326L490.494 175.326L494.39 177.927L494.464 181.809Z'
        fill='url(#paint3680_linear_3150_15389)'
      />
      <path
        d='M487.858 180.548L483.626 177.671L484.592 173.818L488.835 176.804L487.858 180.548Z'
        fill='url(#paint3681_linear_3150_15389)'
      />
      <path
        d='M434.582 206.008L430.371 201.958L433.311 199.505L437.47 203.663L434.582 206.008Z'
        fill='url(#paint3682_linear_3150_15389)'
      />
      <path
        d='M348.193 317.306L343.762 318.705L342.659 316.399L347.28 315.325L348.193 317.306Z'
        fill='url(#paint3683_linear_3150_15389)'
      />
      <path
        d='M350.178 321.158L345.915 322.794L344.759 320.577L349.212 319.227L350.178 321.158Z'
        fill='url(#paint3684_linear_3150_15389)'
      />
      <path
        d='M345.736 313.572L341.147 314.734L340.065 312.34L344.864 311.522L345.736 313.572Z'
        fill='url(#paint3685_linear_3150_15389)'
      />
      <path
        d='M409.315 404.415L408.464 407.883L405.418 407.036L406.437 403.656L409.315 404.415Z'
        fill='url(#paint3686_linear_3150_15389)'
      />
      <path
        d='M350.073 326.045L346.051 328.036L344.738 325.927L348.96 324.213L350.073 326.045Z'
        fill='url(#paint3687_linear_3150_15389)'
      />
      <path
        d='M347.899 381.901L347.731 385.783L345.064 385.192L345.526 381.408L347.899 381.901Z'
        fill='url(#paint3688_linear_3150_15389)'
      />
      <path
        d='M411.005 419.677L410.27 423.274L406.889 422.544L407.75 419.047L411.005 419.677Z'
        fill='url(#paint3689_linear_3150_15389)'
      />
      <path
        d='M363.621 401.646L363.715 405.262L361.363 405.223L361.384 401.508L363.621 401.646Z'
        fill='url(#paint3690_linear_3150_15389)'
      />
      <path
        d='M454.924 194.105L450.881 190.204L453.801 187.533L457.917 191.435L454.924 194.105Z'
        fill='url(#paint3691_linear_3150_15389)'
      />
      <path
        d='M348.992 331.533L345.284 333.898L343.762 331.918L347.689 329.819L348.992 331.533Z'
        fill='url(#paint3692_linear_3150_15389)'
      />
      <path
        d='M398.792 398.158L398.498 401.499L395.631 401.095L396.124 397.745L398.792 398.158Z'
        fill='url(#paint3693_linear_3150_15389)'
      />
      <path
        d='M479.047 180.775L474.773 177.622L476.264 173.956L480.644 177.178L479.047 180.775Z'
        fill='url(#paint3694_linear_3150_15389)'
      />
      <path
        d='M368.935 401.006L369.397 404.366L366.803 404.395L366.456 400.937L368.935 401.006Z'
        fill='url(#paint3695_linear_3150_15389)'
      />
      <path
        d='M347.437 337.484L344.15 340.224L342.396 338.401L345.946 335.898L347.437 337.484Z'
        fill='url(#paint3696_linear_3150_15389)'
      />
      <path
        d='M370.268 229.606L366.509 225.182L369.67 222.866L373.188 227.527L370.268 229.606Z'
        fill='url(#paint3697_linear_3150_15389)'
      />
      <path
        d='M424.931 207.87L420.772 203.633L423.986 201.318L428.071 205.673L424.931 207.87Z'
        fill='url(#paint3698_linear_3150_15389)'
      />
      <path
        d='M389.487 398.681L389.424 401.942L386.672 401.745L386.904 398.424L389.487 398.681Z'
        fill='url(#paint3699_linear_3150_15389)'
      />
      <path
        d='M360.365 241.774L356.248 237.714L358.8 235.3L362.697 239.626L360.365 241.774Z'
        fill='url(#paint3700_linear_3150_15389)'
      />
      <path
        d='M472.505 181.918L468.283 178.331L470.74 175.06L475.046 178.735L472.505 181.918Z'
        fill='url(#paint3701_linear_3150_15389)'
      />
      <path
        d='M348.624 386.749L348.803 390.64L346.177 390.207L346.261 386.404L348.624 386.749Z'
        fill='url(#paint3702_linear_3150_15389)'
      />
      <path
        d='M409.651 424.929L408.863 428.574L405.376 427.885L406.248 424.367L409.651 424.929Z'
        fill='url(#paint3703_linear_3150_15389)'
      />
      <path
        d='M347.395 342.44L344.476 345.436L342.596 343.78L345.789 340.972L347.395 342.44Z'
        fill='url(#paint3704_linear_3150_15389)'
      />
      <path
        d='M380.466 399.627L380.571 402.898L377.945 402.829L377.987 399.479L380.466 399.627Z'
        fill='url(#paint3705_linear_3150_15389)'
      />
      <path
        d='M445.231 483.859L444.286 486.952L441.072 487.603L441.996 484.469L445.231 483.859Z'
        fill='url(#paint3706_linear_3150_15389)'
      />
      <path
        d='M412.644 476.046L412.77 479.218L409.43 480.302L409.315 477.07L412.644 476.046Z'
        fill='url(#paint3707_linear_3150_15389)'
      />
      <path
        d='M371.938 400.326L372.127 403.706L369.617 403.676L369.565 400.218L371.938 400.326Z'
        fill='url(#paint3708_linear_3150_15389)'
      />
      <path
        d='M415.679 209.85L411.73 205.407L415.28 203.259L419.123 207.821L415.679 209.85Z'
        fill='url(#paint3709_linear_3150_15389)'
      />
      <path
        d='M497.583 172.133L497.079 171.936L494.254 167.355L494.475 167.493L497.583 172.133Z'
        fill='url(#paint3710_linear_3150_15389)'
      />
      <path
        d='M408.327 408.455L407.55 411.982L404.431 411.194L405.334 407.755L408.327 408.455Z'
        fill='url(#paint3711_linear_3150_15389)'
      />
      <path
        d='M351.88 393.291L352.153 397.202L349.727 396.927L349.674 393.094L351.88 393.291Z'
        fill='url(#paint3712_linear_3150_15389)'
      />
      <path
        d='M349.863 390.66L350.231 394.552L347.679 394.237L347.574 390.424L349.863 390.66Z'
        fill='url(#paint3713_linear_3150_15389)'
      />
      <path
        d='M405.639 212.008L401.9 207.446L405.67 205.377L409.262 210.126L405.639 212.008Z'
        fill='url(#paint3714_linear_3150_15389)'
      />
      <path
        d='M348.109 346.687L345.505 349.87L343.552 348.352L346.429 345.337L348.109 346.687Z'
        fill='url(#paint3715_linear_3150_15389)'
      />
      <path
        d='M394.969 214.806L391.493 210.107L395.347 208.116L398.634 212.964L394.969 214.806Z'
        fill='url(#paint3716_linear_3150_15389)'
      />
      <path
        d='M365.102 401.814L365.196 405.4L362.886 405.43L362.896 401.725L365.102 401.814Z'
        fill='url(#paint3717_linear_3150_15389)'
      />
      <path
        d='M495.388 177.277L492.605 175.661L490.767 171.335L493.403 172.991L495.388 177.277Z'
        fill='url(#paint3718_linear_3150_15389)'
      />
      <path
        d='M382.955 218.619L379.668 213.87L383.385 211.84L386.473 216.787L382.955 218.619Z'
        fill='url(#paint3719_linear_3150_15389)'
      />
      <path
        d='M402.005 398.306L401.617 401.715L398.75 401.203L399.369 397.813L402.005 398.306Z'
        fill='url(#paint3720_linear_3150_15389)'
      />
      <path
        d='M348.624 350.835L346.303 354.195L344.308 352.786L346.902 349.623L348.624 350.835Z'
        fill='url(#paint3721_linear_3150_15389)'
      />
      <path
        d='M353.339 395.853L353.644 399.735L351.313 399.577L351.187 395.705L353.339 395.853Z'
        fill='url(#paint3722_linear_3150_15389)'
      />
      <path
        d='M445.179 197.652L441.093 193.583L444.097 191.169L448.182 195.268L445.179 197.652Z'
        fill='url(#paint3723_linear_3150_15389)'
      />
      <path
        d='M465.227 184.302L461.215 180.361L464.313 177.543L468.398 181.533L465.227 184.302Z'
        fill='url(#paint3724_linear_3150_15389)'
      />
      <path
        d='M374.123 400.257L374.175 403.647L371.718 403.617L371.802 400.159L374.123 400.257Z'
        fill='url(#paint3725_linear_3150_15389)'
      />
      <path
        d='M348.624 355.229L346.576 358.757L344.528 357.476L346.849 354.146L348.624 355.229Z'
        fill='url(#paint3726_linear_3150_15389)'
      />
      <path
        d='M422.873 480.302L422.705 483.277L419.407 484.479L419.47 481.189L422.873 480.302Z'
        fill='url(#paint3727_linear_3150_15389)'
      />
      <path
        d='M407.949 412.081L407.13 415.677L403.885 414.839L404.851 411.351L407.949 412.081Z'
        fill='url(#paint3728_linear_3150_15389)'
      />
      <path
        d='M382.64 399.784L382.65 403.085L380.056 402.987L380.203 399.646L382.64 399.784Z'
        fill='url(#paint3729_linear_3150_15389)'
      />
      <path
        d='M391.976 398.848L391.86 402.149L389.13 401.912L389.434 398.582L391.976 398.848Z'
        fill='url(#paint3730_linear_3150_15389)'
      />
      <path
        d='M404.872 433.304L404.431 437.018L400.84 436.703L401.354 433.166L404.872 433.304Z'
        fill='url(#paint3731_linear_3150_15389)'
      />
      <path
        d='M367.864 400.336L367.685 403.942H365.406L365.711 400.237L367.864 400.336Z'
        fill='url(#paint3732_linear_3150_15389)'
      />
      <path
        d='M386.788 398.03L386.368 401.37L383.774 401.085L384.373 397.734L386.788 398.03Z'
        fill='url(#paint3733_linear_3150_15389)'
      />
      <path
        d='M347.353 360.786L345.757 364.491L343.615 363.397L345.484 359.86L347.353 360.786Z'
        fill='url(#paint3734_linear_3150_15389)'
      />
      <path
        d='M377.567 398.572L377.284 401.981L374.837 401.863L375.288 398.385L377.567 398.572Z'
        fill='url(#paint3735_linear_3150_15389)'
      />
      <path
        d='M359.998 236.759L356.185 232.354L358.947 230.098L362.529 234.739L359.998 236.759Z'
        fill='url(#paint3736_linear_3150_15389)'
      />
      <path
        d='M402.037 458.586L402.614 462.035L399.044 462.783L398.603 459.502L402.037 458.586Z'
        fill='url(#paint3737_linear_3150_15389)'
      />
      <path
        d='M370.951 224.452L367.633 219.674L371.046 217.604L374.091 222.59L370.951 224.452Z'
        fill='url(#paint3738_linear_3150_15389)'
      />
      <path
        d='M407.393 415.953L406.469 419.618L403.097 418.722L404.127 415.184L407.393 415.953Z'
        fill='url(#paint3739_linear_3150_15389)'
      />
      <path
        d='M406.416 397.764L405.691 401.262L402.824 400.474L403.759 397.055L406.416 397.764Z'
        fill='url(#paint3740_linear_3150_15389)'
      />
      <path
        d='M396.828 397.006L396.292 400.366L393.551 399.863L394.297 396.523L396.828 397.006Z'
        fill='url(#paint3741_linear_3150_15389)'
      />
      <path
        d='M492.175 175.286L488.32 172.882L487.848 168.774L491.628 171.266L492.175 175.286Z'
        fill='url(#paint3742_linear_3150_15389)'
      />
      <path
        d='M435.443 200.411L431.369 196.155L434.561 193.928L438.594 198.244L435.443 200.411Z'
        fill='url(#paint3743_linear_3150_15389)'
      />
      <path
        d='M345.82 366.826L344.78 370.689L342.533 369.792L343.856 366.087L345.82 366.826Z'
        fill='url(#paint3744_linear_3150_15389)'
      />
      <path
        d='M353.896 398.494L354.442 402.326H352.153L351.764 398.454L353.896 398.494Z'
        fill='url(#paint3745_linear_3150_15389)'
      />
      <path
        d='M457.529 187.603L453.738 183.405L457.287 181.021L461.089 185.248L457.529 187.603Z'
        fill='url(#paint3746_linear_3150_15389)'
      />
      <path
        d='M486.588 173.73L482.303 170.823L483.048 166.902L487.312 169.946L486.588 173.73Z'
        fill='url(#paint3747_linear_3150_15389)'
      />
      <path
        d='M369.449 399.883L369.187 403.509L366.887 403.528L367.307 399.834L369.449 399.883Z'
        fill='url(#paint3748_linear_3150_15389)'
      />
      <path
        d='M479.436 173.365L475.067 170.035L476.768 166.37L481.211 169.808L479.436 173.365Z'
        fill='url(#paint3749_linear_3150_15389)'
      />
      <path
        d='M344.686 372.689L344.182 376.66L341.861 375.95L342.659 372.137L344.686 372.689Z'
        fill='url(#paint3750_linear_3150_15389)'
      />
      <path
        d='M355.597 399.39L356.049 403.242L353.822 403.282L353.528 399.41L355.597 399.39Z'
        fill='url(#paint3751_linear_3150_15389)'
      />
      <path
        d='M389.224 397.597L388.71 400.976L386.063 400.631L386.788 397.242L389.224 397.597Z'
        fill='url(#paint3752_linear_3150_15389)'
      />
      <path
        d='M407.834 399.695L406.973 403.252L404.053 402.346L405.061 398.878L407.834 399.695Z'
        fill='url(#paint3753_linear_3150_15389)'
      />
      <path
        d='M498.749 163.798L498.497 163.936L495.535 159.108L495.64 158.823L498.749 163.798Z'
        fill='url(#paint3754_linear_3150_15389)'
      />
      <path
        d='M426.244 202.776L422.305 198.303L425.803 196.283L429.667 200.825L426.244 202.776Z'
        fill='url(#paint3755_linear_3150_15389)'
      />
      <path
        d='M358.16 398.976L358.265 402.848L356.101 402.908L356.143 398.976H358.16Z'
        fill='url(#paint3756_linear_3150_15389)'
      />
      <path
        d='M379.08 398.424L378.775 401.863L376.297 401.754L376.78 398.267L379.08 398.424Z'
        fill='url(#paint3757_linear_3150_15389)'
      />
      <path
        d='M473.376 174.311L469.175 170.468L472.032 167.266L476.306 171.207L473.376 174.311Z'
        fill='url(#paint3758_linear_3150_15389)'
      />
      <path
        d='M344.539 377.487L344.36 381.517L342.05 380.955L342.501 377.083L344.539 377.487Z'
        fill='url(#paint3759_linear_3150_15389)'
      />
      <path
        d='M346.891 317.483L342.627 318.813L341.703 316.813L346.156 315.759L346.891 317.483Z'
        fill='url(#paint3760_linear_3150_15389)'
      />
      <path
        d='M400.724 396.345L399.979 399.794L397.164 399.075L398.151 395.695L400.724 396.345Z'
        fill='url(#paint3761_linear_3150_15389)'
      />
      <path
        d='M417.201 205.032L413.536 200.362L417.369 198.519L420.919 203.308L417.201 205.032Z'
        fill='url(#paint3762_linear_3150_15389)'
      />
      <path
        d='M402.835 422.712L402.236 426.486L398.708 425.904L399.411 422.269L402.835 422.712Z'
        fill='url(#paint3763_linear_3150_15389)'
      />
      <path
        d='M396.418 209.978L393.383 205.032L397.489 203.338L400.335 208.451L396.418 209.978Z'
        fill='url(#paint3764_linear_3150_15389)'
      />
      <path
        d='M348.593 320.922L344.507 322.528L343.51 320.596L347.784 319.256L348.593 320.922Z'
        fill='url(#paint3765_linear_3150_15389)'
      />
      <path
        d='M384.583 213.86L381.779 208.825L385.748 207.121L388.311 212.333L384.583 213.86Z'
        fill='url(#paint3766_linear_3150_15389)'
      />
      <path
        d='M344.77 381.734L344.854 385.783L342.575 385.36L342.743 381.419L344.77 381.734Z'
        fill='url(#paint3767_linear_3150_15389)'
      />
      <path
        d='M493.928 169.926L491.471 168.547L489.276 164.015L491.608 165.394L493.928 169.926Z'
        fill='url(#paint3768_linear_3150_15389)'
      />
      <path
        d='M360.817 231.931L357.456 227.172L360.439 225.152L363.547 230.137L360.817 231.931Z'
        fill='url(#paint3769_linear_3150_15389)'
      />
      <path
        d='M407.12 207.456L403.707 202.638L407.729 200.894L410.985 205.87L407.12 207.456Z'
        fill='url(#paint3770_linear_3150_15389)'
      />
      <path
        d='M359.851 398.74L359.788 402.622L357.624 402.721L357.845 398.77L359.851 398.74Z'
        fill='url(#paint3771_linear_3150_15389)'
      />
      <path
        d='M349.149 324.863L345.305 326.784L344.182 324.932L348.235 323.267L349.149 324.863Z'
        fill='url(#paint3772_linear_3150_15389)'
      />
      <path
        d='M345.631 384.818L345.852 388.867L343.636 388.571L343.646 384.571L345.631 384.818Z'
        fill='url(#paint3773_linear_3150_15389)'
      />
      <path
        d='M370.342 399.972L370.058 403.627L367.716 403.686L368.158 399.981L370.342 399.972Z'
        fill='url(#paint3774_linear_3150_15389)'
      />
      <path
        d='M466.907 176.725L463.095 172.488L466.802 169.828L470.646 174.144L466.907 176.725Z'
        fill='url(#paint3775_linear_3150_15389)'
      />
      <path
        d='M349.307 328.893L345.704 331.139L344.465 329.376L348.277 327.395L349.307 328.893Z'
        fill='url(#paint3776_linear_3150_15389)'
      />
      <path
        d='M397.657 444.625L398.015 448.319L394.402 448.664L394.139 445.206L397.657 444.625Z'
        fill='url(#paint3777_linear_3150_15389)'
      />
      <path
        d='M447.216 191.672L443.383 187.346L446.869 185.218L450.692 189.553L447.216 191.672Z'
        fill='url(#paint3778_linear_3150_15389)'
      />
      <path
        d='M348.015 333.691L344.728 336.292L343.321 334.637L346.828 332.292L348.015 333.691Z'
        fill='url(#paint3779_linear_3150_15389)'
      />
      <path
        d='M372.054 219.625L369.281 214.521L372.904 212.747L375.393 218.038L372.054 219.625Z'
        fill='url(#paint3780_linear_3150_15389)'
      />
      <path
        d='M406.752 403.213L406.007 406.878L402.94 405.992L403.864 402.435L406.752 403.213Z'
        fill='url(#paint3781_linear_3150_15389)'
      />
      <path
        d='M347.007 338.312L344.045 341.209L342.512 339.672L345.715 337.021L347.007 338.312Z'
        fill='url(#paint3782_linear_3150_15389)'
      />
      <path
        d='M348.393 344.884L345.767 348.116L344.182 346.756L347.017 343.751L348.393 344.884Z'
        fill='url(#paint3783_linear_3150_15389)'
      />
      <path
        d='M347.332 341.948L344.591 345.041L343.006 343.603L345.978 340.746L347.332 341.948Z'
        fill='url(#paint3784_linear_3150_15389)'
      />
      <path
        d='M346.345 387.734L346.713 391.823L344.528 391.606L344.392 387.606L346.345 387.734Z'
        fill='url(#paint3785_linear_3150_15389)'
      />
      <path
        d='M348.183 348.608L345.82 352.007L344.171 350.766L346.755 347.564L348.183 348.608Z'
        fill='url(#paint3786_linear_3150_15389)'
      />
      <path
        d='M380.854 397.912L380.508 401.39L377.945 401.262L378.512 397.734L380.854 397.912Z'
        fill='url(#paint3787_linear_3150_15389)'
      />
      <path
        d='M391.083 397.636L390.537 401.105L387.807 400.691L388.563 397.262L391.083 397.636Z'
        fill='url(#paint3788_linear_3150_15389)'
      />
      <path
        d='M360.712 398.829L360.617 402.72L358.433 402.868L358.685 398.917L360.712 398.829Z'
        fill='url(#paint3789_linear_3150_15389)'
      />
      <path
        d='M346.671 353.338L344.728 356.954L342.964 355.86L345.148 352.421L346.671 353.338Z'
        fill='url(#paint3790_linear_3150_15389)'
      />
      <path
        d='M489.602 167.256L485.926 165.148L484.97 160.704L488.467 162.921L489.602 167.256Z'
        fill='url(#paint3791_linear_3150_15389)'
      />
      <path
        d='M346.482 391.183L347.07 395.281L344.927 395.183L344.56 391.173L346.482 391.183Z'
        fill='url(#paint3792_linear_3150_15389)'
      />
      <path
        d='M371.392 399.794L371.046 403.479L368.62 403.548L369.155 399.824L371.392 399.794Z'
        fill='url(#paint3793_linear_3150_15389)'
      />
      <path
        d='M458.547 180.45L454.987 176.035L458.999 173.789L462.549 178.282L458.547 180.45Z'
        fill='url(#paint3794_linear_3150_15389)'
      />
      <path
        d='M436.147 194.893L432.198 190.489L435.653 188.44L439.55 192.962L436.147 194.893Z'
        fill='url(#paint3795_linear_3150_15389)'
      />
      <path
        d='M345.179 358.097L343.657 361.91L341.819 360.954L343.583 357.328L345.179 358.097Z'
        fill='url(#paint3796_linear_3150_15389)'
      />
      <path
        d='M401.596 397.981L400.955 401.587L398.046 400.848L398.928 397.351L401.596 397.981Z'
        fill='url(#paint3797_linear_3150_15389)'
      />
      <path
        d='M347.343 393.34L347.941 397.419L345.852 397.439L345.431 393.36L347.343 393.34Z'
        fill='url(#paint3798_linear_3150_15389)'
      />
      <path
        d='M406.868 405.883L406.122 409.686L402.866 408.691L403.833 405.036L406.868 405.883Z'
        fill='url(#paint3799_linear_3150_15389)'
      />
      <path
        d='M484.792 165.256L480.496 162.448L480.938 158.231L485.264 161.158L484.792 165.256Z'
        fill='url(#paint3800_linear_3150_15389)'
      />
      <path
        d='M343.94 362.659L342.785 366.639L340.884 365.802L342.291 362.028L343.94 362.659Z'
        fill='url(#paint3801_linear_3150_15389)'
      />
      <path
        d='M362.035 227.438L359.199 222.324L362.413 220.58L364.902 225.901L362.035 227.438Z'
        fill='url(#paint3802_linear_3150_15389)'
      />
      <path
        d='M478.659 164.813L474.185 161.404L475.96 157.532L480.528 161.079L478.659 164.813Z'
        fill='url(#paint3803_linear_3150_15389)'
      />
      <path
        d='M396.198 431.106L396.25 434.919L392.648 434.89L392.69 431.205L396.198 431.106Z'
        fill='url(#paint3804_linear_3150_15389)'
      />
      <path
        d='M342.659 367.378L341.892 371.457L339.96 370.787L340.968 366.866L342.659 367.378Z'
        fill='url(#paint3805_linear_3150_15389)'
      />
      <path
        d='M426.632 197.081L422.862 192.44L426.611 190.608L430.308 195.327L426.632 197.081Z'
        fill='url(#paint3806_linear_3150_15389)'
      />
      <path
        d='M384.929 208.993L382.577 203.702L386.767 202.273L388.825 207.692L384.929 208.993Z'
        fill='url(#paint3807_linear_3150_15389)'
      />
      <path
        d='M472.988 165.601L468.755 161.591L471.937 158.241L476.243 162.399L472.988 165.601Z'
        fill='url(#paint3808_linear_3150_15389)'
      />
      <path
        d='M361.363 398.651L361.258 402.543L359.021 402.76L359.294 398.779L361.363 398.651Z'
        fill='url(#paint3809_linear_3150_15389)'
      />
      <path
        d='M348.488 394.267L349.044 398.326L347.007 398.444L346.608 394.316L348.488 394.267Z'
        fill='url(#paint3810_linear_3150_15389)'
      />
      <path
        d='M396.145 204.845L393.362 199.712L397.668 198.234L400.241 203.515L396.145 204.845Z'
        fill='url(#paint3811_linear_3150_15389)'
      />
      <path
        d='M493.34 161.217L491.145 160.202L488.73 155.325L490.704 156.32L493.34 161.217Z'
        fill='url(#paint3812_linear_3150_15389)'
      />
      <path
        d='M373.734 397.942L373.22 401.637L370.636 401.676L371.413 397.883L373.734 397.942Z'
        fill='url(#paint3813_linear_3150_15389)'
      />
      <path
        d='M382.356 397.518L381.999 401.065L379.342 400.907L379.941 397.34L382.356 397.518Z'
        fill='url(#paint3814_linear_3150_15389)'
      />
      <path
        d='M392.627 398.04L392.081 401.617L389.203 401.124L390.012 397.617L392.627 398.04Z'
        fill='url(#paint3815_linear_3150_15389)'
      />
      <path
        d='M498.518 156.635L498.56 156.96L495.378 151.965L495.231 151.462L498.518 156.635Z'
        fill='url(#paint3816_linear_3150_15389)'
      />
      <path
        d='M416.183 199.347L412.539 194.588L416.498 192.815L420.016 197.771L416.183 199.347Z'
        fill='url(#paint3817_linear_3150_15389)'
      />
      <path
        d='M341.777 371.713L341.388 375.881L339.424 375.339L340.065 371.329L341.777 371.713Z'
        fill='url(#paint3818_linear_3150_15389)'
      />
      <path
        d='M406.154 201.751L402.866 196.766L407.057 195.169L410.186 200.263L406.154 201.751Z'
        fill='url(#paint3819_linear_3150_15389)'
      />
      <path
        d='M466.413 167.946L462.696 163.493L466.855 160.724L470.583 165.305L466.413 167.946Z'
        fill='url(#paint3820_linear_3150_15389)'
      />
      <path
        d='M373.125 215.122L370.92 209.732L374.732 208.274L376.612 213.821L373.125 215.122Z'
        fill='url(#paint3821_linear_3150_15389)'
      />
      <path
        d='M341.451 375.369L341.315 379.596L339.361 379.182L339.739 375.083L341.451 375.369Z'
        fill='url(#paint3822_linear_3150_15389)'
      />
      <path
        d='M447.069 184.607L443.33 180.174L447.143 178.065L450.839 182.597L447.069 184.607Z'
        fill='url(#paint3823_linear_3150_15389)'
      />
      <path
        d='M349.842 394.306L350.304 398.366L348.278 398.543L347.984 394.395L349.842 394.306Z'
        fill='url(#paint3824_linear_3150_15389)'
      />
      <path
        d='M404.106 410.386L403.454 414.278L400.083 413.411L400.818 409.617L404.106 410.386Z'
        fill='url(#paint3825_linear_3150_15389)'
      />
      <path
        d='M402.562 399.656L401.921 403.4L398.865 402.553L399.758 398.956L402.562 399.656Z'
        fill='url(#paint3826_linear_3150_15389)'
      />
      <path
        d='M345.852 321.139L341.861 322.745L341.021 321.07L345.19 319.739L345.852 321.139Z'
        fill='url(#paint3827_linear_3150_15389)'
      />
      <path
        d='M341.451 378.443L341.525 382.699L339.582 382.404L339.74 378.236L341.451 378.443Z'
        fill='url(#paint3828_linear_3150_15389)'
      />
      <path
        d='M346.65 324.252L342.848 326.153L341.924 324.538L345.915 322.912L346.65 324.252Z'
        fill='url(#paint3829_linear_3150_15389)'
      />
      <path
        d='M347.563 327.228L343.951 329.385L342.953 327.839L346.765 325.937L347.563 327.228Z'
        fill='url(#paint3830_linear_3150_15389)'
      />
      <path
        d='M385.79 396.06L385.15 399.705L382.304 399.331L383.207 395.745L385.79 396.06Z'
        fill='url(#paint3831_linear_3150_15389)'
      />
      <path
        d='M347.731 330.518L344.339 332.952L343.268 331.484L346.849 329.296L347.731 330.518Z'
        fill='url(#paint3832_linear_3150_15389)'
      />
      <path
        d='M351.187 394.079L351.502 398.188L349.464 398.385L349.328 394.237L351.187 394.079Z'
        fill='url(#paint3833_linear_3150_15389)'
      />
      <path
        d='M347.668 333.799L344.497 336.509L343.342 335.1L346.702 332.656L347.668 333.799Z'
        fill='url(#paint3834_linear_3150_15389)'
      />
      <path
        d='M395.694 468.764L397.626 471.799L394.328 473.375L392.511 470.419L395.694 468.764Z'
        fill='url(#paint3835_linear_3150_15389)'
      />
      <path
        d='M361.678 398.267L361.636 402.158L359.315 402.444L359.567 398.454L361.678 398.267Z'
        fill='url(#paint3836_linear_3150_15389)'
      />
      <path
        d='M347.889 336.785L344.896 339.701L343.688 338.381L346.87 335.701L347.889 336.785Z'
        fill='url(#paint3837_linear_3150_15389)'
      />
      <path
        d='M394.612 398.405L393.929 402.109L390.873 401.43L391.808 397.843L394.612 398.405Z'
        fill='url(#paint3838_linear_3150_15389)'
      />
      <path
        d='M456.899 172.035L453.338 167.483L457.644 165.079L461.173 169.759L456.899 172.035Z'
        fill='url(#paint3839_linear_3150_15389)'
      />
      <path
        d='M348.467 339.465L345.652 342.549L344.392 341.307L347.416 338.43L348.467 339.465Z'
        fill='url(#paint3840_linear_3150_15389)'
      />
      <path
        d='M487.617 158.595L484.235 156.743L482.765 151.965L486.125 153.837L487.617 158.595Z'
        fill='url(#paint3841_linear_3150_15389)'
      />
      <path
        d='M363.054 223.28L360.817 217.831L364.167 216.383L365.963 222.029L363.054 223.28Z'
        fill='url(#paint3842_linear_3150_15389)'
      />
      <path
        d='M341.094 381.507L341.504 385.754L339.582 385.616L339.382 381.399L341.094 381.507Z'
        fill='url(#paint3843_linear_3150_15389)'
      />
      <path
        d='M348.173 342.608L345.631 345.899L344.297 344.756L347.059 341.652L348.173 342.608Z'
        fill='url(#paint3844_linear_3150_15389)'
      />
      <path
        d='M435.254 188.282L431.347 183.73L434.992 181.76L438.877 186.381L435.254 188.282Z'
        fill='url(#paint3845_linear_3150_15389)'
      />
      <path
        d='M375.677 395.932L375.162 399.696L372.369 399.636L373.209 395.833L375.677 395.932Z'
        fill='url(#paint3846_linear_3150_15389)'
      />
      <path
        d='M402.625 401.972L401.963 405.864L398.718 404.898L399.611 401.164L402.625 401.972Z'
        fill='url(#paint3847_linear_3150_15389)'
      />
      <path
        d='M385.486 204.283L383.742 198.746L388.111 197.652L389.529 203.269L385.486 204.283Z'
        fill='url(#paint3848_linear_3150_15389)'
      />
      <path
        d='M347.322 346.007L345.074 349.515L343.678 348.471L346.135 345.15L347.322 346.007Z'
        fill='url(#paint3849_linear_3150_15389)'
      />
      <path
        d='M397.385 417.48L397.237 421.441L393.751 421.037L393.961 417.175L397.385 417.48Z'
        fill='url(#paint3850_linear_3150_15389)'
      />
      <path
        d='M386.221 396.887L385.559 400.671L382.524 400.198L383.438 396.533L386.221 396.887Z'
        fill='url(#paint3851_linear_3150_15389)'
      />
      <path
        d='M421.948 484.056L423.524 486.746L420.068 488.628L418.535 485.829L421.948 484.056Z'
        fill='url(#paint3852_linear_3150_15389)'
      />
      <path
        d='M389.739 457.029L391.43 460.399L388.069 461.64L386.483 458.438L389.739 457.029Z'
        fill='url(#paint3853_linear_3150_15389)'
      />
      <path
        d='M346.618 349.18L344.633 352.865L343.195 351.909L345.379 348.411L346.618 349.18Z'
        fill='url(#paint3854_linear_3150_15389)'
      />
      <path
        d='M350.998 394.828L351.46 398.907L349.412 399.223L349.128 395.055L350.998 394.828Z'
        fill='url(#paint3855_linear_3150_15389)'
      />
      <path
        d='M425.509 190.834L421.77 186.105L425.708 184.312L429.373 189.189L425.509 190.834Z'
        fill='url(#paint3856_linear_3150_15389)'
      />
      <path
        d='M396.45 199.948L394.108 194.588L398.655 193.416L400.745 198.865L396.45 199.948Z'
        fill='url(#paint3857_linear_3150_15389)'
      />
      <path
        d='M374.396 210.934L372.862 205.278L376.811 204.185L377.945 209.949L374.396 210.934Z'
        fill='url(#paint3858_linear_3150_15389)'
      />
      <path
        d='M362.886 396.878L362.77 400.789L360.323 401.085L360.691 397.065L362.886 396.878Z'
        fill='url(#paint3859_linear_3150_15389)'
      />
      <path
        d='M482.943 156.753L478.606 154.034L478.753 149.521L483.101 152.359L482.943 156.753Z'
        fill='url(#paint3860_linear_3150_15389)'
      />
      <path
        d='M345.883 352.264L344.15 356.106L342.659 355.249L344.591 351.584L345.883 352.264Z'
        fill='url(#paint3861_linear_3150_15389)'
      />
      <path
        d='M406.122 196.372L403.161 191.189L407.613 189.839L410.407 195.111L406.122 196.372Z'
        fill='url(#paint3862_linear_3150_15389)'
      />
      <path
        d='M340.853 384.108L341.588 388.345L339.687 388.325L339.162 384.108H340.853Z'
        fill='url(#paint3863_linear_3150_15389)'
      />
      <path
        d='M392.627 401.41L392.049 405.253L388.857 404.632L389.592 400.917L392.627 401.41Z'
        fill='url(#paint3864_linear_3150_15389)'
      />
      <path
        d='M478.06 156.074L473.481 152.517L475.393 148.477L480.108 152.172L478.06 156.074Z'
        fill='url(#paint3865_linear_3150_15389)'
      />
      <path
        d='M344.255 355.968L342.869 359.978L341.325 359.23L342.911 355.407L344.255 355.968Z'
        fill='url(#paint3866_linear_3150_15389)'
      />
      <path
        d='M415.08 193.711L411.51 188.765L415.626 187.159L419.102 192.214L415.08 193.711Z'
        fill='url(#paint3867_linear_3150_15389)'
      />
      <path
        d='M491.082 153.965L489.455 153.206L486.535 148.161L488.016 148.831L491.082 153.965Z'
        fill='url(#paint3868_linear_3150_15389)'
      />
      <path
        d='M444.832 176.636L441.03 172.114L445.031 169.857L448.812 174.508L444.832 176.636Z'
        fill='url(#paint3869_linear_3150_15389)'
      />
      <path
        d='M473.093 157.039L468.881 152.743L472.525 149.373L476.81 153.837L473.093 157.039Z'
        fill='url(#paint3870_linear_3150_15389)'
      />
      <path
        d='M465.51 159.354L461.856 154.674L466.434 151.817L470.068 156.704L465.51 159.354Z'
        fill='url(#paint3871_linear_3150_15389)'
      />
      <path
        d='M342.586 359.713L341.535 363.861L339.95 363.23L341.199 359.25L342.586 359.713Z'
        fill='url(#paint3872_linear_3150_15389)'
      />
      <path
        d='M388.048 441.442L389.151 445.157L385.685 445.866L384.656 442.329L388.048 441.442Z'
        fill='url(#paint3873_linear_3150_15389)'
      />
      <path
        d='M341.273 363.142L340.548 367.398L338.92 366.876L339.866 362.777L341.273 363.142Z'
        fill='url(#paint3874_linear_3150_15389)'
      />
      <path
        d='M340.874 386.079L341.84 390.296L339.97 390.404L339.204 386.168L340.874 386.079Z'
        fill='url(#paint3875_linear_3150_15389)'
      />
      <path
        d='M398.424 406.573L398.088 410.593L394.738 409.903L395.221 405.992L398.424 406.573Z'
        fill='url(#paint3876_linear_3150_15389)'
      />
      <path
        d='M364.356 395.064L364.188 399.015L361.541 399.272L362.024 395.232L364.356 395.064Z'
        fill='url(#paint3877_linear_3150_15389)'
      />
      <path
        d='M375.603 395.794L375.225 399.696L372.264 399.587L372.936 395.745L375.603 395.794Z'
        fill='url(#paint3878_linear_3150_15389)'
      />
      <path
        d='M346.198 325.365L342.512 327.444L341.756 326.114L345.589 324.321L346.198 325.365Z'
        fill='url(#paint3879_linear_3150_15389)'
      />
      <path
        d='M345.778 322.863L341.945 324.676L341.252 323.296L345.232 321.779L345.778 322.863Z'
        fill='url(#paint3880_linear_3150_15389)'
      />
      <path
        d='M346.219 328.036L342.722 330.381L341.882 329.119L345.558 327.031L346.219 328.036Z'
        fill='url(#paint3881_linear_3150_15389)'
      />
      <path
        d='M350.745 394.986L351.407 399.006L349.317 399.449L348.866 395.282L350.745 394.986Z'
        fill='url(#paint3882_linear_3150_15389)'
      />
      <path
        d='M344.686 320.626L340.727 322.183L340.086 320.764L344.182 319.513L344.686 320.626Z'
        fill='url(#paint3883_linear_3150_15389)'
      />
      <path
        d='M454.809 163.453L451.249 158.724L455.88 156.152L459.398 161.059L454.809 163.453Z'
        fill='url(#paint3884_linear_3150_15389)'
      />
      <path
        d='M339.655 366.836L339.351 371.172L337.702 370.797L338.227 366.58L339.655 366.836Z'
        fill='url(#paint3885_linear_3150_15389)'
      />
      <path
        d='M382.734 400.168L382.346 404.09L379.195 403.784L379.773 399.991L382.734 400.168Z'
        fill='url(#paint3886_linear_3150_15389)'
      />
      <path
        d='M346.702 330.42L343.394 332.992L342.501 331.799L345.999 329.454L346.702 330.42Z'
        fill='url(#paint3887_linear_3150_15389)'
      />
      <path
        d='M388.342 200.362L387.618 194.608L392.091 194.036L392.449 199.83L388.342 200.362Z'
        fill='url(#paint3888_linear_3150_15389)'
      />
      <path
        d='M433.585 180.656L429.699 175.986L433.627 173.976L437.491 178.725L433.585 180.656Z'
        fill='url(#paint3889_linear_3150_15389)'
      />
      <path
        d='M347.196 332.745L344.087 335.533L343.142 334.4L346.45 331.819L347.196 332.745Z'
        fill='url(#paint3890_linear_3150_15389)'
      />
      <path
        d='M389.865 425.53L390.401 429.481L386.904 429.639L386.431 425.806L389.865 425.53Z'
        fill='url(#paint3891_linear_3150_15389)'
      />
      <path
        d='M387.964 405.913L387.765 409.883L384.425 409.509L384.814 405.666L387.964 405.913Z'
        fill='url(#paint3892_linear_3150_15389)'
      />
      <path
        d='M341.525 386.887L342.554 391.064L340.716 391.281L339.876 387.015L341.525 386.887Z'
        fill='url(#paint3893_linear_3150_15389)'
      />
      <path
        d='M376.255 207.161L375.572 201.269L379.542 200.618L379.783 206.569L376.255 207.161Z'
        fill='url(#paint3894_linear_3150_15389)'
      />
      <path
        d='M347.353 335.169L344.476 338.184L343.468 337.11L346.555 334.292L347.353 335.169Z'
        fill='url(#paint3895_linear_3150_15389)'
      />
      <path
        d='M400.01 196.135L398.729 190.529L403.528 189.948L404.515 195.564L400.01 196.135Z'
        fill='url(#paint3896_linear_3150_15389)'
      />
      <path
        d='M400.84 483.977L403.696 486.253L400.913 488.578L398.067 486.204L400.84 483.977Z'
        fill='url(#paint3897_linear_3150_15389)'
      />
      <path
        d='M347.521 337.475L344.864 340.697L343.814 339.692L346.681 336.667L347.521 337.475Z'
        fill='url(#paint3898_linear_3150_15389)'
      />
      <path
        d='M342.942 386.374L343.867 390.542L342.018 390.808L341.304 386.522L342.942 386.374Z'
        fill='url(#paint3899_linear_3150_15389)'
      />
      <path
        d='M337.986 370.57L338.206 374.955L336.536 374.728L336.547 370.423L337.986 370.57Z'
        fill='url(#paint3900_linear_3150_15389)'
      />
      <path
        d='M424.091 184.282L420.426 179.365L424.605 177.681L428.218 182.676L424.091 184.282Z'
        fill='url(#paint3901_linear_3150_15389)'
      />
      <path
        d='M485.411 151.393L482.24 149.63L480.297 144.684L483.416 146.467L485.411 151.393Z'
        fill='url(#paint3902_linear_3150_15389)'
      />
      <path
        d='M351.733 393.626L352.384 397.636L350.22 398.119L349.821 393.941L351.733 393.626Z'
        fill='url(#paint3903_linear_3150_15389)'
      />
      <path
        d='M348.046 339.425L345.547 342.815L344.465 341.869L347.164 338.667L348.046 339.425Z'
        fill='url(#paint3904_linear_3150_15389)'
      />
      <path
        d='M348.435 341.297L346.072 344.834L344.959 343.938L347.511 340.588L348.435 341.297Z'
        fill='url(#paint3905_linear_3150_15389)'
      />
      <path
        d='M392.249 412.415L392.343 416.495L388.92 416.199L388.888 412.218L392.249 412.415Z'
        fill='url(#paint3906_linear_3150_15389)'
      />
      <path
        d='M409.041 192.243L406.91 186.795L411.73 185.987L413.641 191.455L409.041 192.243Z'
        fill='url(#paint3907_linear_3150_15389)'
      />
      <path
        d='M348.057 343.455L345.883 347.16L344.738 346.332L347.101 342.815L348.057 343.455Z'
        fill='url(#paint3908_linear_3150_15389)'
      />
      <path
        d='M372.946 397.902L372.778 401.932L369.712 401.922L370.09 397.991L372.946 397.902Z'
        fill='url(#paint3909_linear_3150_15389)'
      />
      <path
        d='M364.492 394.03L364.482 398.05L361.625 398.296L362.025 394.227L364.492 394.03Z'
        fill='url(#paint3910_linear_3150_15389)'
      />
      <path
        d='M416.235 188.322L413.148 183.07L417.685 181.878L420.646 187.081L416.235 188.322Z'
        fill='url(#paint3911_linear_3150_15389)'
      />
      <path
        d='M442.879 168.527L439.098 163.818L443.435 161.483L447.195 166.32L442.879 168.527Z'
        fill='url(#paint3912_linear_3150_15389)'
      />
      <path
        d='M347.175 345.849L345.253 349.702L344.056 348.963L346.177 345.268L347.175 345.849Z'
        fill='url(#paint3913_linear_3150_15389)'
      />
      <path
        d='M377.126 404.868L377.168 408.898L373.965 408.888L374.06 404.996L377.126 404.868Z'
        fill='url(#paint3914_linear_3150_15389)'
      />
      <path
        d='M337.083 373.428L337.744 377.802L336.074 377.733L335.644 373.378L337.083 373.428Z'
        fill='url(#paint3915_linear_3150_15389)'
      />
      <path
        d='M353.528 391.439L354.032 395.488L351.733 395.961L351.533 391.774L353.528 391.439Z'
        fill='url(#paint3916_linear_3150_15389)'
      />
      <path
        d='M343.415 386.453L344.444 390.621L342.596 390.946L341.787 386.69L343.415 386.453Z'
        fill='url(#paint3917_linear_3150_15389)'
      />
      <path
        d='M390.243 196.637L390.432 190.765L394.874 190.657L394.297 196.519L390.243 196.637Z'
        fill='url(#paint3918_linear_3150_15389)'
      />
      <path
        d='M345.946 348.401L344.36 352.411L343.111 351.771L344.906 347.889L345.946 348.401Z'
        fill='url(#paint3919_linear_3150_15389)'
      />
      <path
        d='M377.053 203.702L377.074 197.652L380.959 197.367L380.518 203.436L377.053 203.702Z'
        fill='url(#paint3920_linear_3150_15389)'
      />
      <path
        d='M385.465 465.542L388.09 468.528L385.087 470.341L382.577 467.473L385.465 465.542Z'
        fill='url(#paint3921_linear_3150_15389)'
      />
      <path
        d='M452.036 154.379L448.434 149.452L453.433 146.634L456.993 151.807L452.036 154.379Z'
        fill='url(#paint3922_linear_3150_15389)'
      />
      <path
        d='M344.791 350.835L343.531 354.993L342.239 354.441L343.72 350.411L344.791 350.835Z'
        fill='url(#paint3923_linear_3150_15389)'
      />
      <path
        d='M432.534 173.119L428.817 168.222L433.196 166.271L436.882 171.247L432.534 173.119Z'
        fill='url(#paint3924_linear_3150_15389)'
      />
      <path
        d='M381.054 411.726L381.495 415.756L378.187 415.854L377.861 411.933L381.054 411.726Z'
        fill='url(#paint3925_linear_3150_15389)'
      />
      <path
        d='M381.831 450.822L383.931 454.241L380.728 455.611L378.743 452.32L381.831 450.822Z'
        fill='url(#paint3926_linear_3150_15389)'
      />
      <path
        d='M336.715 375.507L337.713 379.862L336.074 379.911L335.297 375.546L336.715 375.507Z'
        fill='url(#paint3927_linear_3150_15389)'
      />
      <path
        d='M403.192 192.431L403.045 186.726L407.928 186.706L407.771 192.381L403.192 192.431Z'
        fill='url(#paint3928_linear_3150_15389)'
      />
      <path
        d='M391.398 477.119L394.633 479.553L392.007 481.79L388.878 479.455L391.398 477.119Z'
        fill='url(#paint3929_linear_3150_15389)'
      />
      <path
        d='M354.411 389.882L354.946 393.971L352.51 394.444L352.321 390.247L354.411 389.882Z'
        fill='url(#paint3930_linear_3150_15389)'
      />
      <path
        d='M367.738 401.725L368 405.844L364.871 406.08L364.797 402.08L367.738 401.725Z'
        fill='url(#paint3931_linear_3150_15389)'
      />
      <path
        d='M343.037 387.182L344.308 391.32L342.47 391.744L341.42 387.527L343.037 387.182Z'
        fill='url(#paint3932_linear_3150_15389)'
      />
      <path
        d='M347.479 322.734L343.793 324.656L343.258 323.572L347.07 321.927L347.479 322.734Z'
        fill='url(#paint3933_linear_3150_15389)'
      />
      <path
        d='M343.237 353.476L342.365 357.762L341.042 357.318L342.123 353.141L343.237 353.476Z'
        fill='url(#paint3934_linear_3150_15389)'
      />
      <path
        d='M347.311 324.794L343.783 327.001L343.195 325.957L346.86 324.015L347.311 324.794Z'
        fill='url(#paint3935_linear_3150_15389)'
      />
      <path
        d='M347.017 320.912L343.216 322.567L342.733 321.454L346.65 320.084L347.017 320.912Z'
        fill='url(#paint3936_linear_3150_15389)'
      />
      <path
        d='M346.954 326.902L343.594 329.385L342.943 328.38L346.461 326.153L346.954 326.902Z'
        fill='url(#paint3937_linear_3150_15389)'
      />
      <path
        d='M345.768 319.405L341.871 320.843L341.42 319.69L345.432 318.567L345.768 319.405Z'
        fill='url(#paint3938_linear_3150_15389)'
      />
      <path
        d='M346.765 328.893L343.583 331.622L342.869 330.666L346.23 328.183L346.765 328.893Z'
        fill='url(#paint3939_linear_3150_15389)'
      />
      <path
        d='M382.094 434.249L383.585 438.043L380.266 438.87L378.859 435.195L382.094 434.249Z'
        fill='url(#paint3940_linear_3150_15389)'
      />
      <path
        d='M344.518 317.877L340.527 319.069L340.118 317.887L344.213 317.02L344.518 317.877Z'
        fill='url(#paint3941_linear_3150_15389)'
      />
      <path
        d='M412.539 188.204L411.541 182.588L416.645 182.391L417.391 187.948L412.539 188.204Z'
        fill='url(#paint3942_linear_3150_15389)'
      />
      <path
        d='M346.524 330.863L343.531 333.839L342.774 332.932L345.936 330.193L346.524 330.863Z'
        fill='url(#paint3943_linear_3150_15389)'
      />
      <path
        d='M346.744 332.538L343.93 335.701L343.131 334.854L346.135 331.898L346.744 332.538Z'
        fill='url(#paint3944_linear_3150_15389)'
      />
      <path
        d='M418.945 182.784L416.824 177.267L421.906 176.597L423.828 181.996L418.945 182.784Z'
        fill='url(#paint3945_linear_3150_15389)'
      />
      <path
        d='M370.625 410.327L371.193 414.396L367.979 414.751L367.538 410.839L370.625 410.327Z'
        fill='url(#paint3946_linear_3150_15389)'
      />
      <path
        d='M362.35 394.444L362.749 398.552L359.798 398.887L359.777 394.759L362.35 394.444Z'
        fill='url(#paint3947_linear_3150_15389)'
      />
      <path
        d='M384.015 419.825L384.919 423.845L381.569 424.17L380.739 420.249L384.015 419.825Z'
        fill='url(#paint3948_linear_3150_15389)'
      />
      <path
        d='M347.616 333.79L344.917 337.1L344.087 336.292L346.986 333.179L347.616 333.79Z'
        fill='url(#paint3949_linear_3150_15389)'
      />
      <path
        d='M336.662 376.975L337.923 381.3L336.305 381.448L335.266 377.093L336.662 376.975Z'
        fill='url(#paint3950_linear_3150_15389)'
      />
      <path
        d='M408.475 485.573L411.52 487.652L408.863 490.194L405.828 487.918L408.475 485.573Z'
        fill='url(#paint3951_linear_3150_15389)'
      />
      <path
        d='M348.614 334.893L346.03 338.342L345.179 337.583L347.973 334.312L348.614 334.893Z'
        fill='url(#paint3952_linear_3150_15389)'
      />
      <path
        d='M341.105 356.55L340.727 360.944L339.382 360.609L339.97 356.323L341.105 356.55Z'
        fill='url(#paint3953_linear_3150_15389)'
      />
      <path
        d='M343.258 387.074L344.602 391.192L342.743 391.695L341.64 387.468L343.258 387.074Z'
        fill='url(#paint3954_linear_3150_15389)'
      />
      <path
        d='M349.086 336.203L346.671 339.8L345.789 339.09L348.424 335.661L349.086 336.203Z'
        fill='url(#paint3955_linear_3150_15389)'
      />
      <path
        d='M337.114 377.546L338.458 381.852L336.862 382.088L335.738 377.724L337.114 377.546Z'
        fill='url(#paint3956_linear_3150_15389)'
      />
      <path
        d='M376.286 200.461L376.748 194.293L380.592 194.253L379.668 200.431L376.286 200.461Z'
        fill='url(#paint3957_linear_3150_15389)'
      />
      <path
        d='M348.813 337.8L346.629 341.573L345.715 340.923L348.109 337.307L348.813 337.8Z'
        fill='url(#paint3958_linear_3150_15389)'
      />
      <path
        d='M344.402 385.813L345.621 389.931L343.678 390.483L342.743 386.227L344.402 385.813Z'
        fill='url(#paint3959_linear_3150_15389)'
      />
      <path
        d='M337.828 377.526L339.172 381.822L337.566 382.118L336.463 377.743L337.828 377.526Z'
        fill='url(#paint3960_linear_3150_15389)'
      />
      <path
        d='M353.823 389.261L354.6 393.38L352.048 393.902L351.649 389.695L353.823 389.261Z'
        fill='url(#paint3961_linear_3150_15389)'
      />
      <path
        d='M339.025 359.624L339.162 364.078L337.807 363.88L337.881 359.496L339.025 359.624Z'
        fill='url(#paint3962_linear_3150_15389)'
      />
      <path
        d='M348.13 339.514L346.23 343.455L345.274 342.874L347.395 339.071L348.13 339.514Z'
        fill='url(#paint3963_linear_3150_15389)'
      />
      <path
        d='M373.703 418.268L374.837 422.259L371.634 422.83L370.563 418.987L373.703 418.268Z'
        fill='url(#paint3964_linear_3150_15389)'
      />
      <path
        d='M402.331 187.78L402.656 181.957L407.624 182.194L406.92 187.938L402.331 187.78Z'
        fill='url(#paint3965_linear_3150_15389)'
      />
      <path
        d='M432.125 165.414L428.775 160.202L433.784 158.448L437.04 163.719L432.125 165.414Z'
        fill='url(#paint3966_linear_3150_15389)'
      />
      <path
        d='M345.516 384.266L346.681 388.404L344.633 388.985L343.793 384.699L345.516 384.266Z'
        fill='url(#paint3967_linear_3150_15389)'
      />
      <path
        d='M347.437 341.15L345.81 345.239L344.822 344.717L346.671 340.756L347.437 341.15Z'
        fill='url(#paint3968_linear_3150_15389)'
      />
      <path
        d='M337.986 377.812L339.424 382.108L337.828 382.463L336.62 378.108L337.986 377.812Z'
        fill='url(#paint3969_linear_3150_15389)'
      />
      <path
        d='M361.058 406.317L361.982 410.445L358.905 411.046L358.16 407.026L361.058 406.317Z'
        fill='url(#paint3970_linear_3150_15389)'
      />
      <path
        d='M337.629 362.038L338.175 366.531L336.809 366.432L336.484 362.008L337.629 362.038Z'
        fill='url(#paint3971_linear_3150_15389)'
      />
      <path
        d='M411.719 182.765L411.215 177.04L416.498 177.07L416.708 182.745L411.719 182.765Z'
        fill='url(#paint3972_linear_3150_15389)'
      />
      <path
        d='M346.702 342.716L345.368 346.933L344.35 346.48L345.915 342.381L346.702 342.716Z'
        fill='url(#paint3973_linear_3150_15389)'
      />
      <path
        d='M363.6 416.12L364.871 420.13L361.741 420.918L360.607 417.027L363.6 416.12Z'
        fill='url(#paint3974_linear_3150_15389)'
      />
      <path
        d='M336.61 363.959L337.471 368.462H336.116L335.476 364.008L336.61 363.959Z'
        fill='url(#paint3975_linear_3150_15389)'
      />
      <path
        d='M347.427 319.286L343.562 320.823L343.247 319.976L347.217 318.715L347.427 319.286Z'
        fill='url(#paint3976_linear_3150_15389)'
      />
      <path
        d='M347.805 320.527L344.045 322.34L343.688 321.503L347.563 319.976L347.805 320.527Z'
        fill='url(#paint3977_linear_3150_15389)'
      />
      <path
        d='M348.151 321.759L344.518 323.818L344.119 323.02L347.889 321.227L348.151 321.759Z'
        fill='url(#paint3978_linear_3150_15389)'
      />
      <path
        d='M358.58 395.932L359.546 400.09L356.606 400.622L355.975 396.464L358.58 395.932Z'
        fill='url(#paint3979_linear_3150_15389)'
      />
      <path
        d='M346.713 318.153L342.754 319.444L342.481 318.567L346.524 317.582L346.713 318.153Z'
        fill='url(#paint3980_linear_3150_15389)'
      />
      <path
        d='M348.267 323.05L344.77 325.375L344.339 324.597L347.973 322.538L348.267 323.05Z'
        fill='url(#paint3981_linear_3150_15389)'
      />
      <path
        d='M337.734 378.246L339.33 382.512L337.744 382.955L336.379 378.62L337.734 378.246Z'
        fill='url(#paint3982_linear_3150_15389)'
      />
      <path
        d='M345.841 317.089L341.798 318.114L341.556 317.227L345.673 316.507L345.841 317.089Z'
        fill='url(#paint3983_linear_3150_15389)'
      />
      <path
        d='M345.642 344.382L344.633 348.717L343.594 348.342L344.822 344.106L345.642 344.382Z'
        fill='url(#paint3984_linear_3150_15389)'
      />
      <path
        d='M348.078 324.429L344.728 327.011L344.255 326.262L347.752 323.937L348.078 324.429Z'
        fill='url(#paint3985_linear_3150_15389)'
      />
      <path
        d='M344.77 316.094L340.664 316.872L340.454 315.956L344.623 315.502L344.77 316.094Z'
        fill='url(#paint3986_linear_3150_15389)'
      />
      <path
        d='M347.71 325.848L344.539 328.686L344.024 327.966L347.353 325.375L347.71 325.848Z'
        fill='url(#paint3987_linear_3150_15389)'
      />
      <path
        d='M345.883 383.084L347.143 387.222L345.022 387.843L344.108 383.576L345.883 383.084Z'
        fill='url(#paint3988_linear_3150_15389)'
      />
      <path
        d='M347.742 327.04L344.728 330.095L344.182 329.415L347.353 326.587L347.742 327.04Z'
        fill='url(#paint3989_linear_3150_15389)'
      />
      <path
        d='M347.994 328.085L345.148 331.336L344.56 330.696L347.595 327.661L347.994 328.085Z'
        fill='url(#paint3990_linear_3150_15389)'
      />
      <path
        d='M335.906 365.398L337.009 369.89L335.665 369.989L334.783 365.506L335.906 365.398Z'
        fill='url(#paint3991_linear_3150_15389)'
      />
      <path
        d='M351.953 389.37L353.129 393.508L350.546 394.138L349.737 389.931L351.953 389.37Z'
        fill='url(#paint3992_linear_3150_15389)'
      />
      <path
        d='M348.257 329.1L345.579 332.528L344.959 331.927L347.836 328.696L348.257 329.1Z'
        fill='url(#paint3993_linear_3150_15389)'
      />
      <path
        d='M376.696 427.037L378.47 430.88L375.362 431.796L373.64 428.052L376.696 427.037Z'
        fill='url(#paint3994_linear_3150_15389)'
      />
      <path
        d='M344.14 346.224L343.51 350.678L342.459 350.382L343.3 346.027L344.14 346.224Z'
        fill='url(#paint3995_linear_3150_15389)'
      />
      <path
        d='M348.498 330.065L346.009 333.661L345.358 333.1L348.068 329.681L348.498 330.065Z'
        fill='url(#paint3996_linear_3150_15389)'
      />
      <path
        d='M335.602 366.255L336.862 370.718L335.518 370.905L334.488 366.412L335.602 366.255Z'
        fill='url(#paint3997_linear_3150_15389)'
      />
      <path
        d='M337.755 378.127L339.477 382.335L337.87 382.876L336.41 378.531L337.755 378.127Z'
        fill='url(#paint3998_linear_3150_15389)'
      />
      <path
        d='M348.75 330.962L346.44 334.716L345.767 334.203L348.298 330.607L348.75 330.962Z'
        fill='url(#paint3999_linear_3150_15389)'
      />
      <path
        d='M400.22 182.814L400.976 176.873L406.017 177.316L404.799 183.149L400.22 182.814Z'
        fill='url(#paint4000_linear_3150_15389)'
      />
      <path
        d='M342.638 347.987L342.375 352.51L341.304 352.293L341.787 347.85L342.638 347.987Z'
        fill='url(#paint4001_linear_3150_15389)'
      />
      <path
        d='M348.824 331.878L346.723 335.78L346.03 335.307L348.351 331.553L348.824 331.878Z'
        fill='url(#paint4002_linear_3150_15389)'
      />
      <path
        d='M398.403 487.544L402.331 489.13L400.514 491.938L396.618 490.263L398.403 487.544Z'
        fill='url(#paint4003_linear_3150_15389)'
      />
      <path
        d='M375.456 442.782L378.029 446.201L375.194 447.708L372.673 444.408L375.456 442.782Z'
        fill='url(#paint4004_linear_3150_15389)'
      />
      <path
        d='M367.002 424.702L368.819 428.554L365.742 429.579L364.02 425.865L367.002 424.702Z'
        fill='url(#paint4005_linear_3150_15389)'
      />
      <path
        d='M335.602 366.639L337.009 371.073L335.654 371.339L334.499 366.836L335.602 366.639Z'
        fill='url(#paint4006_linear_3150_15389)'
      />
      <path
        d='M348.593 332.864L346.723 336.913L346.009 336.48L348.099 332.568L348.593 332.864Z'
        fill='url(#paint4007_linear_3150_15389)'
      />
      <path
        d='M345.337 382.404L346.828 386.542L344.654 387.232L343.52 382.975L345.337 382.404Z'
        fill='url(#paint4008_linear_3150_15389)'
      />
      <path
        d='M341.367 349.495L341.441 354.067L340.37 353.939L340.517 349.416L341.367 349.495Z'
        fill='url(#paint4009_linear_3150_15389)'
      />
      <path
        d='M338.353 377.162L340.16 381.32L338.511 381.931L337.019 377.595L338.353 377.162Z'
        fill='url(#paint4010_linear_3150_15389)'
      />
      <path
        d='M355.219 410.11L356.816 414.169L353.781 415.115L352.426 411.105L355.219 410.11Z'
        fill='url(#paint4011_linear_3150_15389)'
      />
      <path
        d='M348.257 333.819L346.639 338.007L345.894 337.622L347.752 333.563L348.257 333.819Z'
        fill='url(#paint4012_linear_3150_15389)'
      />
      <path
        d='M377.116 458.714L380.424 461.522L377.935 463.601L374.721 460.813L377.116 458.714Z'
        fill='url(#paint4013_linear_3150_15389)'
      />
      <path
        d='M347.92 334.706L346.555 339.012L345.789 338.677L347.395 334.479L347.92 334.706Z'
        fill='url(#paint4014_linear_3150_15389)'
      />
      <path
        d='M335.633 366.866L337.272 371.241L335.927 371.595L334.562 367.122L335.633 366.866Z'
        fill='url(#paint4015_linear_3150_15389)'
      />
      <path
        d='M340.328 350.805L340.727 355.387L339.645 355.348L339.477 350.786L340.328 350.805Z'
        fill='url(#paint4016_linear_3150_15389)'
      />
      <path
        d='M357.498 421.234L359.399 425.155L356.374 426.308L354.61 422.524L357.498 421.234Z'
        fill='url(#paint4017_linear_3150_15389)'
      />
      <path
        d='M354.358 397.833L355.86 402.001L352.972 402.74L351.754 398.602L354.358 397.833Z'
        fill='url(#paint4018_linear_3150_15389)'
      />
      <path
        d='M381.789 474.331L385.706 476.489L383.732 478.972L379.888 476.893L381.789 474.331Z'
        fill='url(#paint4019_linear_3150_15389)'
      />
      <path
        d='M339.414 375.566L341.231 379.714L339.519 380.364L338.049 376.039L339.414 375.566Z'
        fill='url(#paint4020_linear_3150_15389)'
      />
      <path
        d='M349.737 389.645L351.323 393.783L348.698 394.542L347.49 390.335L349.737 389.645Z'
        fill='url(#paint4021_linear_3150_15389)'
      />
      <path
        d='M347.542 335.553L346.44 339.958L345.662 339.682L347.007 335.356L347.542 335.553Z'
        fill='url(#paint4022_linear_3150_15389)'
      />
      <path
        d='M347.122 318.587L343.184 320.321L342.974 319.749L347.007 318.311L347.122 318.587Z'
        fill='url(#paint4023_linear_3150_15389)'
      />
      <path
        d='M346.86 317.917L342.827 319.375L342.638 318.793L346.765 317.631L346.86 317.917Z'
        fill='url(#paint4024_linear_3150_15389)'
      />
      <path
        d='M347.364 319.247L343.541 321.247L343.3 320.695L347.238 318.981L347.364 319.247Z'
        fill='url(#paint4025_linear_3150_15389)'
      />
      <path
        d='M346.524 317.247L342.407 318.439L342.26 317.848L346.45 316.961L346.524 317.247Z'
        fill='url(#paint4026_linear_3150_15389)'
      />
      <path
        d='M347.595 319.897L343.888 322.144L343.615 321.612L347.458 319.631L347.595 319.897Z'
        fill='url(#paint4027_linear_3150_15389)'
      />
      <path
        d='M346.114 316.606L341.924 317.513L341.798 316.912L346.051 316.311L346.114 316.606Z'
        fill='url(#paint4028_linear_3150_15389)'
      />
      <path
        d='M347.784 320.548L344.203 323.04L343.909 322.528L347.626 320.291L347.784 320.548Z'
        fill='url(#paint4029_linear_3150_15389)'
      />
      <path
        d='M345.6 315.995L341.346 316.616L341.252 316.015L345.558 315.7L345.6 315.995Z'
        fill='url(#paint4030_linear_3150_15389)'
      />
      <path
        d='M347.752 321.247L344.339 323.986L344.014 323.493L347.595 321L347.752 321.247Z'
        fill='url(#paint4031_linear_3150_15389)'
      />
      <path
        d='M344.959 315.434L340.653 315.778L340.58 315.168L344.927 315.128L344.959 315.434Z'
        fill='url(#paint4032_linear_3150_15389)'
      />
      <path
        d='M339.603 351.801L340.317 356.382L339.235 356.422L338.773 351.84L339.603 351.801Z'
        fill='url(#paint4033_linear_3150_15389)'
      />
      <path
        d='M347.595 321.986L344.339 324.962L343.993 324.499L347.406 321.75L347.595 321.986Z'
        fill='url(#paint4034_linear_3150_15389)'
      />
      <path
        d='M347.122 336.351L346.314 340.854L345.515 340.628L346.576 336.204L347.122 336.351Z'
        fill='url(#paint4035_linear_3150_15389)'
      />
      <path
        d='M347.364 322.725L344.297 325.927L343.919 325.484L347.164 322.498L347.364 322.725Z'
        fill='url(#paint4036_linear_3150_15389)'
      />
      <path
        d='M347.08 323.444L344.213 326.873L343.814 326.449L346.87 323.237L347.08 323.444Z'
        fill='url(#paint4037_linear_3150_15389)'
      />
      <path
        d='M344.171 382L345.957 386.128L343.741 386.897L342.323 382.67L344.171 382Z'
        fill='url(#paint4038_linear_3150_15389)'
      />
      <path
        d='M346.944 324.074L344.276 327.7L343.856 327.306L346.723 323.877L346.944 324.074Z'
        fill='url(#paint4039_linear_3150_15389)'
      />
      <path
        d='M389.77 483.514L393.982 485.051L392.49 487.859L388.311 486.273L389.77 483.514Z'
        fill='url(#paint4040_linear_3150_15389)'
      />
      <path
        d='M340.17 374.088L342.039 378.226L340.265 378.906L338.763 374.61L340.17 374.088Z'
        fill='url(#paint4041_linear_3150_15389)'
      />
      <path
        d='M346.912 324.627L344.444 328.42L343.993 328.055L346.681 324.439L346.912 324.627Z'
        fill='url(#paint4042_linear_3150_15389)'
      />
      <path
        d='M397.972 178.055L399.285 171.986L404.316 172.676L402.478 178.627L397.972 178.055Z'
        fill='url(#paint4043_linear_3150_15389)'
      />
      <path
        d='M335.822 366.856L337.692 371.161L336.379 371.595L334.772 367.171L335.822 366.856Z'
        fill='url(#paint4044_linear_3150_15389)'
      />
      <path
        d='M346.65 337.11L346.135 341.682L345.326 341.504L346.104 337.002L346.65 337.11Z'
        fill='url(#paint4045_linear_3150_15389)'
      />
      <path
        d='M346.818 325.168L344.56 329.129L344.098 328.794L346.576 325.001L346.818 325.168Z'
        fill='url(#paint4046_linear_3150_15389)'
      />
      <path
        d='M339.172 352.5L340.17 357.062L339.088 357.18L338.353 352.589L339.172 352.5Z'
        fill='url(#paint4047_linear_3150_15389)'
      />
      <path
        d='M346.66 325.701L344.633 329.809L344.15 329.504L346.419 325.543L346.66 325.701Z'
        fill='url(#paint4048_linear_3150_15389)'
      />
      <path
        d='M370.29 434.161L372.831 437.737L370.069 439.156L367.528 435.727L370.29 434.161Z'
        fill='url(#paint4049_linear_3150_15389)'
      />
      <path
        d='M346.429 326.232L344.644 330.479L344.14 330.213L346.177 326.094L346.429 326.232Z'
        fill='url(#paint4050_linear_3150_15389)'
      />
      <path
        d='M346.041 337.879L345.841 342.5L345.032 342.381L345.494 337.8L346.041 337.879Z'
        fill='url(#paint4051_linear_3150_15389)'
      />
      <path
        d='M346.03 326.804L344.518 331.178L344.014 330.942L345.768 326.686L346.03 326.804Z'
        fill='url(#paint4052_linear_3150_15389)'
      />
      <path
        d='M361.247 430.328L363.715 433.993L360.827 435.432L358.475 431.885L361.247 430.328Z'
        fill='url(#paint4053_linear_3150_15389)'
      />
      <path
        d='M336.137 366.452L338.154 370.708L336.851 371.201L335.108 366.826L336.137 366.452Z'
        fill='url(#paint4054_linear_3150_15389)'
      />
      <path
        d='M345.578 327.366L344.36 331.859L343.835 331.652L345.305 327.267L345.578 327.366Z'
        fill='url(#paint4055_linear_3150_15389)'
      />
      <path
        d='M340.223 372.984L342.249 377.113L340.433 377.842L338.763 373.566L340.223 372.984Z'
        fill='url(#paint4056_linear_3150_15389)'
      />
      <path
        d='M338.805 353.072L340.086 357.594L339.025 357.781L337.996 353.21L338.805 353.072Z'
        fill='url(#paint4057_linear_3150_15389)'
      />
      <path
        d='M345.421 338.568L345.536 343.219L344.728 343.16L344.875 338.539L345.421 338.568Z'
        fill='url(#paint4058_linear_3150_15389)'
      />
      <path
        d='M345.158 327.868L344.224 332.45L343.688 332.282L344.885 327.789L345.158 327.868Z'
        fill='url(#paint4059_linear_3150_15389)'
      />
      <path
        d='M345.852 316.488L341.42 317.631L341.357 317.335L345.852 316.488Z'
        fill='url(#paint4060_linear_3150_15389)'
      />
      <path
        d='M345.599 316.36L341.105 317.217L341.052 316.912L345.599 316.36Z'
        fill='url(#paint4061_linear_3150_15389)'
      />
      <path
        d='M346.062 316.626L341.714 318.054L341.63 317.759L346.062 316.626Z'
        fill='url(#paint4062_linear_3150_15389)'
      />
      <path
        d='M345.326 316.241L340.779 316.803L340.748 316.498L345.326 316.241Z'
        fill='url(#paint4063_linear_3150_15389)'
      />
      <path
        d='M346.23 316.774L341.966 318.478L341.871 318.192L346.23 316.774Z'
        fill='url(#paint4064_linear_3150_15389)'
      />
      <path
        d='M345.053 316.113L340.464 316.379L340.454 316.074L345.053 316.113Z'
        fill='url(#paint4065_linear_3150_15389)'
      />
      <path
        d='M336.4 365.762L338.49 369.989L337.167 370.531L335.36 366.176L336.4 365.762Z'
        fill='url(#paint4066_linear_3150_15389)'
      />
      <path
        d='M346.314 316.941L342.165 318.922L342.05 318.646L346.314 316.941Z'
        fill='url(#paint4067_linear_3150_15389)'
      />
      <path d='M344.791 315.985L340.191 315.946V315.641L344.791 315.985Z' fill='url(#paint4068_linear_3150_15389)' />
      <path
        d='M350.115 412.938L352.363 416.869L349.443 418.14L347.448 414.209L350.115 412.938Z'
        fill='url(#paint4069_linear_3150_15389)'
      />
      <path
        d='M346.324 317.138L342.302 319.385L342.176 319.119L346.324 317.138Z'
        fill='url(#paint4070_linear_3150_15389)'
      />
      <path
        d='M344.57 315.838L339.971 315.503L339.992 315.197L344.57 315.838Z'
        fill='url(#paint4071_linear_3150_15389)'
      />
      <path
        d='M346.261 317.345L342.386 319.858L342.239 319.601L346.261 317.345Z'
        fill='url(#paint4072_linear_3150_15389)'
      />
      <path
        d='M344.77 328.302L344.108 332.952L343.573 332.824L344.486 328.242L344.77 328.302Z'
        fill='url(#paint4073_linear_3150_15389)'
      />
      <path
        d='M346.818 390.188L348.876 394.296L346.314 395.212L344.602 391.055L346.818 390.188Z'
        fill='url(#paint4074_linear_3150_15389)'
      />
      <path
        d='M346.177 317.552L342.47 320.321L342.312 320.064L346.177 317.552Z'
        fill='url(#paint4075_linear_3150_15389)'
      />
      <path
        d='M346.072 317.759L342.543 320.774L342.365 320.537L346.072 317.759Z'
        fill='url(#paint4076_linear_3150_15389)'
      />
      <path
        d='M345.946 317.966L342.606 321.217L342.407 320.981L345.946 317.966Z'
        fill='url(#paint4077_linear_3150_15389)'
      />
      <path
        d='M349.748 399.893L351.827 403.972L349.034 404.987L347.227 400.898L349.748 399.893Z'
        fill='url(#paint4078_linear_3150_15389)'
      />
      <path
        d='M342.407 381.793L344.57 385.882L342.354 386.759L340.559 382.581L342.407 381.793Z'
        fill='url(#paint4079_linear_3150_15389)'
      />
      <path
        d='M345.831 318.163L342.69 321.621L342.491 321.405L345.831 318.163Z'
        fill='url(#paint4080_linear_3150_15389)'
      />
      <path
        d='M344.812 339.169L345.221 343.83L344.413 343.82L344.255 339.169H344.812Z'
        fill='url(#paint4081_linear_3150_15389)'
      />
      <path
        d='M345.694 318.35L342.775 322.015L342.554 321.818L345.694 318.35Z'
        fill='url(#paint4082_linear_3150_15389)'
      />
      <path
        d='M344.318 328.735L343.951 333.435L343.405 333.346L344.035 328.686L344.318 328.735Z'
        fill='url(#paint4083_linear_3150_15389)'
      />
      <path
        d='M351.827 425.815L354.358 429.579L351.491 431.106L349.118 427.441L351.827 425.815Z'
        fill='url(#paint4084_linear_3150_15389)'
      />
      <path
        d='M336.694 364.836L338.857 369.024L337.482 369.625L335.644 365.289L336.694 364.836Z'
        fill='url(#paint4085_linear_3150_15389)'
      />
      <path
        d='M345.495 318.547L342.806 322.42L342.575 322.222L345.495 318.547Z'
        fill='url(#paint4086_linear_3150_15389)'
      />
      <path
        d='M338.385 353.525L339.939 357.998L338.899 358.254L337.597 353.722L338.385 353.525Z'
        fill='url(#paint4087_linear_3150_15389)'
      />
      <path
        d='M345.232 318.774L342.785 322.814L342.554 322.636L345.232 318.774Z'
        fill='url(#paint4088_linear_3150_15389)'
      />
      <path
        d='M344.927 318.99L342.743 323.198L342.501 323.04L344.927 318.99Z'
        fill='url(#paint4089_linear_3150_15389)'
      />
      <path
        d='M343.825 329.129L343.751 333.868L343.205 333.819L343.541 329.109L343.825 329.129Z'
        fill='url(#paint4090_linear_3150_15389)'
      />
      <path
        d='M344.612 319.217L342.701 323.572L342.449 323.434L344.612 319.217Z'
        fill='url(#paint4091_linear_3150_15389)'
      />
      <path
        d='M344.213 339.623L344.896 344.273L344.098 344.332L343.657 339.672L344.213 339.623Z'
        fill='url(#paint4092_linear_3150_15389)'
      />
      <path
        d='M343.751 314.232L340.012 311.276L340.18 311.03L343.751 314.232Z'
        fill='url(#paint4093_linear_3150_15389)'
      />
      <path
        d='M344.297 319.424L342.659 323.917L342.396 323.789L344.297 319.424Z'
        fill='url(#paint4094_linear_3150_15389)'
      />
      <path
        d='M339.676 372.117L341.966 376.206L340.118 376.994L338.206 372.777L339.676 372.117Z'
        fill='url(#paint4095_linear_3150_15389)'
      />
      <path
        d='M337.975 353.673L339.729 358.087L338.689 358.412L337.188 353.919L337.975 353.673Z'
        fill='url(#paint4096_linear_3150_15389)'
      />
      <path
        d='M343.321 329.484L343.552 334.233L342.995 334.223L343.037 329.474L343.321 329.484Z'
        fill='url(#paint4097_linear_3150_15389)'
      />
      <path
        d='M343.982 319.621L342.638 324.222L342.365 324.114L343.982 319.621Z'
        fill='url(#paint4098_linear_3150_15389)'
      />
      <path
        d='M343.688 314.084L340.128 310.872L340.317 310.645L343.688 314.084Z'
        fill='url(#paint4099_linear_3150_15389)'
      />
      <path
        d='M337.019 363.752L339.309 367.89L337.881 368.541L335.959 364.245L337.019 363.752Z'
        fill='url(#paint4100_linear_3150_15389)'
      />
      <path
        d='M343.72 319.789L342.648 324.469L342.375 324.38L343.72 319.789Z'
        fill='url(#paint4101_linear_3150_15389)'
      />
      <path
        d='M343.604 313.956L340.243 310.497L340.443 310.281L343.604 313.956Z'
        fill='url(#paint4102_linear_3150_15389)'
      />
      <path
        d='M343.594 339.967L344.539 344.598L343.741 344.706L343.048 340.056L343.594 339.967Z'
        fill='url(#paint4103_linear_3150_15389)'
      />
      <path
        d='M343.478 319.927L342.69 324.686L342.407 324.607L343.478 319.927Z'
        fill='url(#paint4104_linear_3150_15389)'
      />
      <path
        d='M342.816 329.76L343.331 334.519L342.785 334.538L342.533 329.78L342.816 329.76Z'
        fill='url(#paint4105_linear_3150_15389)'
      />
      <path
        d='M343.478 313.838L340.328 310.163L340.538 309.966L343.478 313.838Z'
        fill='url(#paint4106_linear_3150_15389)'
      />
      <path
        d='M343.247 320.035L342.733 324.843L342.449 324.794L343.247 320.035Z'
        fill='url(#paint4107_linear_3150_15389)'
      />
      <path
        d='M337.65 353.525L339.571 357.89L338.521 358.274L336.872 353.811L337.65 353.525Z'
        fill='url(#paint4108_linear_3150_15389)'
      />
      <path
        d='M371.035 450.152L374.522 453.029L372.348 455.108L368.903 452.3L371.035 450.152Z'
        fill='url(#paint4109_linear_3150_15389)'
      />
      <path
        d='M343.289 313.759L340.349 309.877L340.58 309.689L343.289 313.759Z'
        fill='url(#paint4110_linear_3150_15389)'
      />
      <path
        d='M343.005 320.143L342.774 324.981L342.491 324.951L343.005 320.143Z'
        fill='url(#paint4111_linear_3150_15389)'
      />
      <path
        d='M342.302 329.986L343.1 334.716L342.543 334.775L342.018 330.016L342.302 329.986Z'
        fill='url(#paint4112_linear_3150_15389)'
      />
      <path
        d='M342.753 320.232L342.806 325.09L342.522 325.08L342.753 320.232Z'
        fill='url(#paint4113_linear_3150_15389)'
      />
      <path d='M343.079 313.7L340.37 309.621L340.611 309.453L343.079 313.7Z' fill='url(#paint4114_linear_3150_15389)' />
      <path
        d='M342.932 340.214L344.14 344.795L343.342 344.963L342.386 340.332L342.932 340.214Z'
        fill='url(#paint4115_linear_3150_15389)'
      />
      <path
        d='M342.491 320.301L342.816 325.158L342.533 325.168L342.491 320.301Z'
        fill='url(#paint4116_linear_3150_15389)'
      />
      <path
        d='M342.848 313.651L340.391 309.404L340.632 309.246L342.848 313.651Z'
        fill='url(#paint4117_linear_3150_15389)'
      />
      <path
        d='M341.787 330.144L342.838 334.824L342.291 334.923L341.504 330.193L341.787 330.144Z'
        fill='url(#paint4118_linear_3150_15389)'
      />
      <path
        d='M342.218 320.36L342.827 325.198L342.533 325.218L342.218 320.36Z'
        fill='url(#paint4119_linear_3150_15389)'
      />
      <path
        d='M337.387 353.2L339.477 357.506L338.416 357.949L336.61 353.535L337.387 353.2Z'
        fill='url(#paint4120_linear_3150_15389)'
      />
      <path
        d='M341.913 320.41L342.796 325.208L342.512 325.247L341.913 320.41Z'
        fill='url(#paint4121_linear_3150_15389)'
      />
      <path
        d='M342.606 313.621L340.391 309.217L340.653 309.079L342.606 313.621Z'
        fill='url(#paint4122_linear_3150_15389)'
      />
      <path
        d='M337.219 362.619L339.666 366.698L338.217 367.398L336.137 363.151L337.219 362.619Z'
        fill='url(#paint4123_linear_3150_15389)'
      />
      <path
        d='M341.588 320.459L342.753 325.198L342.47 325.257L341.588 320.459Z'
        fill='url(#paint4124_linear_3150_15389)'
      />
      <path
        d='M341.252 330.242L342.575 334.873L342.029 335.001L340.968 330.311L341.252 330.242Z'
        fill='url(#paint4125_linear_3150_15389)'
      />
      <path
        d='M342.218 340.352L343.667 344.874L342.859 345.091L341.672 340.499L342.218 340.352Z'
        fill='url(#paint4126_linear_3150_15389)'
      />
      <path
        d='M342.344 313.611L340.38 309.059L340.643 308.941L342.344 313.611Z'
        fill='url(#paint4127_linear_3150_15389)'
      />
      <path
        d='M341.241 320.498L342.669 325.159L342.396 325.237L341.241 320.498Z'
        fill='url(#paint4128_linear_3150_15389)'
      />
      <path
        d='M340.317 381.645L342.848 385.665L340.653 386.66L338.479 382.542L340.317 381.645Z'
        fill='url(#paint4129_linear_3150_15389)'
      />
      <path
        d='M342.05 313.611L340.359 308.941L340.622 308.842L342.05 313.611Z'
        fill='url(#paint4130_linear_3150_15389)'
      />
      <path
        d='M340.706 330.282L342.302 334.834L341.756 335.011L340.433 330.37L340.706 330.282Z'
        fill='url(#paint4131_linear_3150_15389)'
      />
      <path
        d='M340.863 320.528L342.575 325.089L342.302 325.188L340.863 320.528Z'
        fill='url(#paint4132_linear_3150_15389)'
      />
      <path
        d='M341.735 313.631L340.317 308.862L340.601 308.773L341.735 313.631Z'
        fill='url(#paint4133_linear_3150_15389)'
      />
      <path
        d='M338.731 371.339L341.294 375.369L339.435 376.246L337.25 372.078L338.731 371.339Z'
        fill='url(#paint4134_linear_3150_15389)'
      />
      <path
        d='M341.483 340.381L343.174 344.834L342.365 345.11L340.947 340.568L341.483 340.381Z'
        fill='url(#paint4135_linear_3150_15389)'
      />
      <path
        d='M337.135 352.756L339.403 356.983L338.332 357.486L336.358 353.131L337.135 352.756Z'
        fill='url(#paint4136_linear_3150_15389)'
      />
      <path
        d='M341.42 313.66L340.275 308.803L340.559 308.744L341.42 313.66Z'
        fill='url(#paint4137_linear_3150_15389)'
      />
      <path
        d='M340.485 320.557L342.47 325.011L342.197 325.119L340.485 320.557Z'
        fill='url(#paint4138_linear_3150_15389)'
      />
      <path
        d='M364.293 441.757L367.528 444.999L365.165 446.841L361.92 443.817L364.293 441.757Z'
        fill='url(#paint4139_linear_3150_15389)'
      />
      <path
        d='M340.191 330.272L342.039 334.735L341.504 334.942L339.918 330.381L340.191 330.272Z'
        fill='url(#paint4140_linear_3150_15389)'
      />
      <path
        d='M341.105 313.71L340.243 308.783L340.538 308.734L341.105 313.71Z'
        fill='url(#paint4141_linear_3150_15389)'
      />
      <path
        d='M343.625 390.799L346.156 394.828L343.667 395.912L341.451 391.833L343.625 390.799Z'
        fill='url(#paint4142_linear_3150_15389)'
      />
      <path
        d='M340.097 320.577L342.344 324.893L342.081 325.031L340.097 320.577Z'
        fill='url(#paint4143_linear_3150_15389)'
      />
      <path
        d='M340.79 313.759L340.233 308.783L340.517 308.763L340.79 313.759Z'
        fill='url(#paint4144_linear_3150_15389)'
      />
      <path
        d='M340.769 340.312L342.712 344.687L341.903 345.012L340.233 340.529L340.769 340.312Z'
        fill='url(#paint4145_linear_3150_15389)'
      />
      <path
        d='M339.697 330.203L341.819 334.548L341.283 334.795L339.435 330.331L339.697 330.203Z'
        fill='url(#paint4146_linear_3150_15389)'
      />
      <path
        d='M337.177 361.476L339.823 365.496L338.343 366.255L336.074 362.067L337.177 361.476Z'
        fill='url(#paint4147_linear_3150_15389)'
      />
      <path
        d='M340.496 313.808L340.233 308.813L340.527 308.803L340.496 313.808Z'
        fill='url(#paint4148_linear_3150_15389)'
      />
      <path
        d='M345.852 414.869L348.603 418.692L345.768 420.229L343.258 416.386L345.852 414.869Z'
        fill='url(#paint4149_linear_3150_15389)'
      />
      <path
        d='M355.597 436.023L358.737 439.452L356.175 441.265L353.119 438.013L355.597 436.023Z'
        fill='url(#paint4150_linear_3150_15389)'
      />
      <path
        d='M345.915 401.124L348.53 405.095L345.768 406.346L343.478 402.307L345.915 401.124Z'
        fill='url(#paint4151_linear_3150_15389)'
      />
      <path
        d='M336.851 352.175L339.319 356.313L338.238 356.875L336.085 352.589L336.851 352.175Z'
        fill='url(#paint4152_linear_3150_15389)'
      />
      <path
        d='M340.212 313.857L340.254 308.852L340.559 308.872L340.212 313.857Z'
        fill='url(#paint4153_linear_3150_15389)'
      />
      <path
        d='M339.277 330.065L341.651 334.292L341.126 334.568L339.025 330.213L339.277 330.065Z'
        fill='url(#paint4154_linear_3150_15389)'
      />
      <path
        d='M339.96 313.907L340.317 308.921L340.622 308.96L339.96 313.907Z'
        fill='url(#paint4155_linear_3150_15389)'
      />
      <path
        d='M340.107 340.145L342.302 344.421L341.493 344.795L339.582 340.401L340.107 340.145Z'
        fill='url(#paint4156_linear_3150_15389)'
      />
      <path
        d='M347.049 429.185L350.105 432.782L347.353 434.634L344.486 431.087L347.049 429.185Z'
        fill='url(#paint4157_linear_3150_15389)'
      />
      <path
        d='M338.857 329.888L341.483 333.967L340.968 334.282L338.605 330.045L338.857 329.888Z'
        fill='url(#paint4158_linear_3150_15389)'
      />
      <path
        d='M336.599 351.446L339.267 355.486L338.175 356.107L335.833 351.899L336.599 351.446Z'
        fill='url(#paint4159_linear_3150_15389)'
      />
      <path
        d='M339.519 339.869L341.955 344.027L341.157 344.451L338.994 340.155L339.519 339.869Z'
        fill='url(#paint4160_linear_3150_15389)'
      />
      <path
        d='M337.492 370.531L340.359 374.482L338.49 375.447L336.011 371.339L337.492 370.531Z'
        fill='url(#paint4161_linear_3150_15389)'
      />
      <path
        d='M338.08 381.359L340.958 385.301L338.763 386.424L336.242 382.364L338.08 381.359Z'
        fill='url(#paint4162_linear_3150_15389)'
      />
      <path
        d='M338.49 329.641L341.357 333.563L340.863 333.908L338.248 329.819L338.49 329.641Z'
        fill='url(#paint4163_linear_3150_15389)'
      />
      <path
        d='M336.946 360.304L339.834 364.245L338.343 365.063L335.843 360.944L336.946 360.304Z'
        fill='url(#paint4164_linear_3150_15389)'
      />
      <path
        d='M425.74 500.067L429.993 500.333L429.898 503.693L425.677 503.22L425.74 500.067Z'
        fill='url(#paint4165_linear_3150_15389)'
      />
      <path
        d='M338.983 339.465L341.682 343.495L340.895 343.968L338.479 339.78L338.983 339.465Z'
        fill='url(#paint4166_linear_3150_15389)'
      />
      <path
        d='M338.175 329.336L341.273 333.08L340.8 333.454L337.944 329.533L338.175 329.336Z'
        fill='url(#paint4167_linear_3150_15389)'
      />
      <path
        d='M336.389 350.579L339.277 354.51L338.185 355.18L335.633 351.062L336.389 350.579Z'
        fill='url(#paint4168_linear_3150_15389)'
      />
      <path
        d='M375.677 465.493L379.878 467.464L378.607 470.065L374.417 468.163L375.677 465.493Z'
        fill='url(#paint4169_linear_3150_15389)'
      />
      <path
        d='M340.496 391.173L343.405 395.124L340.905 396.375L338.332 392.355L340.496 391.173Z'
        fill='url(#paint4170_linear_3150_15389)'
      />
      <path
        d='M337.828 328.991L341.147 332.548L340.695 332.952L337.608 329.198L337.828 328.991Z'
        fill='url(#paint4171_linear_3150_15389)'
      />
      <path
        d='M338.521 338.953L341.451 342.844L340.685 343.357L338.028 339.307L338.521 338.953Z'
        fill='url(#paint4172_linear_3150_15389)'
      />
      <path
        d='M337.398 328.636L340.947 331.977L340.517 332.41L337.198 328.853L337.398 328.636Z'
        fill='url(#paint4173_linear_3150_15389)'
      />
      <path
        d='M336.158 349.584L339.267 353.407L338.175 354.126L335.402 350.116L336.158 349.584Z'
        fill='url(#paint4174_linear_3150_15389)'
      />
      <path
        d='M336.505 359.112L339.655 362.964L338.175 363.841L335.402 359.821L336.505 359.112Z'
        fill='url(#paint4175_linear_3150_15389)'
      />
      <path
        d='M338.101 338.332L341.283 342.056L340.527 342.618L337.629 338.706L338.101 338.332Z'
        fill='url(#paint4176_linear_3150_15389)'
      />
      <path
        d='M336.232 369.516L339.445 373.379L337.587 374.433L334.772 370.413L336.232 369.516Z'
        fill='url(#paint4177_linear_3150_15389)'
      />
      <path
        d='M342.365 401.844L345.39 405.735L342.691 407.184L339.95 403.233L342.365 401.844Z'
        fill='url(#paint4178_linear_3150_15389)'
      />
      <path
        d='M335.791 380.788L339.057 384.621L336.872 385.882L333.984 381.901L335.791 380.788Z'
        fill='url(#paint4179_linear_3150_15389)'
      />
      <path
        d='M337.755 337.583L341.168 341.14L340.433 341.751L337.303 337.987L337.755 337.583Z'
        fill='url(#paint4180_linear_3150_15389)'
      />
      <path
        d='M335.854 348.5L339.214 352.185L338.122 352.963L335.108 349.071L335.854 348.5Z'
        fill='url(#paint4181_linear_3150_15389)'
      />
      <path
        d='M349.968 442.27L353.738 445.432L351.544 447.58L347.847 444.684L349.968 442.27Z'
        fill='url(#paint4182_linear_3150_15389)'
      />
      <path
        d='M337.503 336.726L341.147 340.105L340.433 340.755L337.061 337.159L337.503 336.726Z'
        fill='url(#paint4183_linear_3150_15389)'
      />
      <path
        d='M341.052 416.938L344.392 420.584L341.777 422.397L338.647 418.751L341.052 416.938Z'
        fill='url(#paint4184_linear_3150_15389)'
      />
      <path
        d='M335.749 357.959L339.183 361.693L337.734 362.639L334.657 358.727L335.749 357.959Z'
        fill='url(#paint4185_linear_3150_15389)'
      />
      <path
        d='M335.539 347.308L339.151 350.855L338.07 351.682L334.804 347.919L335.539 347.308Z'
        fill='url(#paint4186_linear_3150_15389)'
      />
      <path
        d='M368.525 456.113L372.631 458.418L371.193 460.882L367.097 458.665L368.525 456.113Z'
        fill='url(#paint4187_linear_3150_15389)'
      />
      <path
        d='M359.388 448.891L363.358 451.63L361.52 453.916L357.593 451.344L359.388 448.891Z'
        fill='url(#paint4188_linear_3150_15389)'
      />
      <path
        d='M337.083 391.38L340.412 395.212L337.975 396.641L334.961 392.73L337.083 391.38Z'
        fill='url(#paint4189_linear_3150_15389)'
      />
      <path
        d='M337.114 335.839L340.989 339.031L340.296 339.721L336.694 336.302L337.114 335.839Z'
        fill='url(#paint4190_linear_3150_15389)'
      />
      <path
        d='M335.024 368.304L338.574 372.048L336.778 373.201L333.606 369.29L335.024 368.304Z'
        fill='url(#paint4191_linear_3150_15389)'
      />
      <path
        d='M336.589 334.943L340.685 337.918L340.033 338.647L336.19 335.425L336.589 334.943Z'
        fill='url(#paint4192_linear_3150_15389)'
      />
      <path
        d='M335.434 345.938L339.298 349.328L338.248 350.204L334.73 346.588L335.434 345.938Z'
        fill='url(#paint4193_linear_3150_15389)'
      />
      <path
        d='M334.72 356.806L338.448 360.402L337.04 361.427L333.659 357.644L334.72 356.806Z'
        fill='url(#paint4194_linear_3150_15389)'
      />
      <path
        d='M333.816 379.704L337.492 383.399L335.36 384.798L332.084 380.926L333.816 379.704Z'
        fill='url(#paint4195_linear_3150_15389)'
      />
      <path
        d='M338.112 402.819L341.609 406.543L339.078 408.218L335.822 404.444L338.112 402.819Z'
        fill='url(#paint4196_linear_3150_15389)'
      />
      <path
        d='M335.35 344.48L339.445 347.702L338.448 348.628L334.667 345.17L335.35 344.48Z'
        fill='url(#paint4197_linear_3150_15389)'
      />
      <path
        d='M384.667 482.006L389.235 482.617L389.361 485.622L384.782 485.002L384.667 482.006Z'
        fill='url(#paint4198_linear_3150_15389)'
      />
      <path
        d='M333.827 355.466L337.839 358.895L336.494 359.998L332.798 356.362L333.827 355.466Z'
        fill='url(#paint4199_linear_3150_15389)'
      />
      <path
        d='M333.711 367.003L337.587 370.6L335.875 371.851L332.346 368.068L333.711 367.003Z'
        fill='url(#paint4200_linear_3150_15389)'
      />
      <path
        d='M333.533 391.36L337.303 395.015L334.972 396.641L331.506 392.877L333.533 391.36Z'
        fill='url(#paint4201_linear_3150_15389)'
      />
      <path
        d='M335.329 342.933L339.634 345.978L338.7 346.953L334.678 343.662L335.329 342.933Z'
        fill='url(#paint4202_linear_3150_15389)'
      />
      <path
        d='M423.419 495.643L427.336 495.406L428.386 498.697L424.5 498.815L423.419 495.643Z'
        fill='url(#paint4203_linear_3150_15389)'
      />
      <path
        d='M336.263 418.869L340.202 422.209L337.912 424.337L334.163 420.958L336.263 418.869Z'
        fill='url(#paint4204_linear_3150_15389)'
      />
      <path
        d='M332.325 378.098L336.389 381.645L334.331 383.162L330.687 379.408L332.325 378.098Z'
        fill='url(#paint4205_linear_3150_15389)'
      />
      <path
        d='M335.392 341.308L339.886 344.145L339.015 345.17L334.772 342.076L335.392 341.308Z'
        fill='url(#paint4206_linear_3150_15389)'
      />
      <path
        d='M416.015 494.076L419.89 493.781L421.14 496.953L417.233 497.249L416.015 494.076Z'
        fill='url(#paint4207_linear_3150_15389)'
      />
      <path
        d='M397.185 489.396L401.407 489.238L402.425 492.332L398.172 492.47L397.185 489.396Z'
        fill='url(#paint4208_linear_3150_15389)'
      />
      <path
        d='M333.848 403.509L337.818 407.006L335.434 408.938L331.716 405.341L333.848 403.509Z'
        fill='url(#paint4209_linear_3150_15389)'
      />
      <path
        d='M344.906 448.191L349.496 450.714L347.973 453.335L343.499 450.901L344.906 448.191Z'
        fill='url(#paint4210_linear_3150_15389)'
      />
      <path
        d='M407.372 493.642L411.373 493.199L412.686 496.342L408.674 496.717L407.372 493.642Z'
        fill='url(#paint4211_linear_3150_15389)'
      />
      <path
        d='M335.287 339.672L339.96 342.283L339.162 343.357L334.709 340.47L335.287 339.672Z'
        fill='url(#paint4212_linear_3150_15389)'
      />
      <path
        d='M355.965 454.674L360.523 456.842L359.357 459.473L354.852 457.443L355.965 454.674Z'
        fill='url(#paint4213_linear_3150_15389)'
      />
      <path
        d='M330.225 390.867L334.425 394.296L332.262 396.119L328.355 392.542L330.225 390.867Z'
        fill='url(#paint4214_linear_3150_15389)'
      />
      <path
        d='M377.83 472.134L382.272 473.06L382.409 475.957L377.914 475.139L377.83 472.134Z'
        fill='url(#paint4215_linear_3150_15389)'
      />
      <path
        d='M367.244 462.31L371.77 463.926L371.256 466.665L366.708 465.217L367.244 462.31Z'
        fill='url(#paint4216_linear_3150_15389)'
      />
      <path
        d='M331.201 376.157L335.56 379.546L333.69 381.172L329.647 377.585L331.201 376.157Z'
        fill='url(#paint4217_linear_3150_15389)'
      />
      <path
        d='M331.905 420.15L336.305 423.214L334.289 425.608L330.067 422.485L331.905 420.15Z'
        fill='url(#paint4218_linear_3150_15389)'
      />
      <path
        d='M329.952 403.617L334.373 406.829L332.304 408.996L328.082 405.686L329.952 403.617Z'
        fill='url(#paint4219_linear_3150_15389)'
      />
      <path
        d='M340.191 292.408L343.468 288.329L344.287 289.284L340.811 293.107L340.191 292.408Z'
        fill='url(#paint4220_linear_3150_15389)'
      />
      <path
        d='M329.5 374.373L334.163 377.566L332.409 379.31L328.051 375.901L329.5 374.373Z'
        fill='url(#paint4221_linear_3150_15389)'
      />
      <path
        d='M341.273 293.688L344.885 289.924L345.642 290.939L341.84 294.437L341.273 293.688Z'
        fill='url(#paint4222_linear_3150_15389)'
      />
      <path
        d='M341.892 295.196L345.778 291.738L346.492 292.812L342.407 295.994L341.892 295.196Z'
        fill='url(#paint4223_linear_3150_15389)'
      />
      <path
        d='M342.26 296.851L346.408 293.708L347.049 294.832L342.732 297.689L342.26 296.851Z'
        fill='url(#paint4224_linear_3150_15389)'
      />
      <path
        d='M342.333 298.674L346.681 295.827L347.259 296.999L342.743 299.541L342.333 298.674Z'
        fill='url(#paint4225_linear_3150_15389)'
      />
      <path
        d='M342.144 300.615L346.671 298.073L347.185 299.285L342.512 301.521L342.144 300.615Z'
        fill='url(#paint4226_linear_3150_15389)'
      />
      <path
        d='M531.158 525.625L528.343 522.6L527.755 521.644L530.717 524.531L531.158 525.625Z'
        fill='url(#paint4227_linear_3150_15389)'
      />
      <path
        d='M341.693 452.477L346.87 454.32L346.24 457.216L341.157 455.482L341.693 452.477Z'
        fill='url(#paint4228_linear_3150_15389)'
      />
      <path
        d='M341.672 302.704L346.335 300.458L346.786 301.699L341.987 303.63L341.672 302.704Z'
        fill='url(#paint4229_linear_3150_15389)'
      />
      <path
        d='M341.094 304.872L345.883 302.921L346.272 304.192L341.357 305.818L341.094 304.872Z'
        fill='url(#paint4230_linear_3150_15389)'
      />
      <path
        d='M340.527 307.088L345.431 305.433L345.757 306.734L340.727 308.044L340.527 307.088Z'
        fill='url(#paint4231_linear_3150_15389)'
      />
      <path
        d='M354.201 460.99L359.221 462.37L359.126 465.217L354.138 464.094L354.201 460.99Z'
        fill='url(#paint4232_linear_3150_15389)'
      />
      <path
        d='M340.653 282.732L343.604 278.308L344.633 279.48L341.493 283.648L340.653 282.732Z'
        fill='url(#paint4233_linear_3150_15389)'
      />
      <path
        d='M341.903 284.446L345.221 280.338L346.177 281.599L342.691 285.432L341.903 284.446Z'
        fill='url(#paint4234_linear_3150_15389)'
      />
      <path
        d='M398.456 485.859L401.69 484.953L403.749 487.889L400.409 488.825L398.456 485.859Z'
        fill='url(#paint4235_linear_3150_15389)'
      />
      <path
        d='M368.693 469.434L373.283 470.016L374.018 472.912L369.376 472.548L368.693 469.434Z'
        fill='url(#paint4236_linear_3150_15389)'
      />
      <path
        d='M343.31 286.259L346.965 282.495L347.836 283.835L344.035 287.313L343.31 286.259Z'
        fill='url(#paint4237_linear_3150_15389)'
      />
      <path
        d='M454.021 499.032L455.701 497.357L458.506 500.323L456.941 501.84L454.021 499.032Z'
        fill='url(#paint4238_linear_3150_15389)'
      />
      <path
        d='M344.35 288.319L348.277 284.9L349.055 286.299L345.001 289.422L344.35 288.319Z'
        fill='url(#paint4239_linear_3150_15389)'
      />
      <path
        d='M385.076 477.76L388.867 477.454L390.453 480.4L386.536 480.804L385.076 477.76Z'
        fill='url(#paint4240_linear_3150_15389)'
      />
      <path
        d='M447.489 492.244L449.085 490.785L451.742 493.81L450.22 495.15L447.489 492.244Z'
        fill='url(#paint4241_linear_3150_15389)'
      />
      <path
        d='M345.19 290.535L349.338 287.461L350.031 288.929L345.768 291.698L345.19 290.535Z'
        fill='url(#paint4242_linear_3150_15389)'
      />
      <path
        d='M345.82 292.9L350.157 290.171L350.777 291.688L346.314 294.112L345.82 292.9Z'
        fill='url(#paint4243_linear_3150_15389)'
      />
      <path
        d='M345.726 295.492L350.21 293.078L350.745 294.644L346.146 296.733L345.726 295.492Z'
        fill='url(#paint4244_linear_3150_15389)'
      />
      <path
        d='M340.926 274.337L343.888 269.746L345.064 271.234L341.966 275.52L340.926 274.337Z'
        fill='url(#paint4245_linear_3150_15389)'
      />
      <path
        d='M427.934 157.896L430.423 155.541L428.848 160.823L426.559 162.882L427.934 157.896Z'
        fill='url(#paint4246_linear_3150_15389)'
      />
      <path
        d='M345.148 298.26L349.727 296.132L350.199 297.748L345.494 299.531L345.148 298.26Z'
        fill='url(#paint4247_linear_3150_15389)'
      />
      <path
        d='M423.093 491.544L424.385 489.672L427.241 492.283L425.771 494.214L423.093 491.544Z'
        fill='url(#paint4248_linear_3150_15389)'
      />
      <path
        d='M344.381 301.137L349.065 299.304L349.454 300.95L344.675 302.438L344.381 301.137Z'
        fill='url(#paint4249_linear_3150_15389)'
      />
      <path
        d='M448.434 492.687L448.97 490.756L451.826 493.406L451.249 495.318L448.434 492.687Z'
        fill='url(#paint4250_linear_3150_15389)'
      />
      <path
        d='M342.743 276.594L346.03 272.357L347.091 273.943L343.699 277.865L342.743 276.594Z'
        fill='url(#paint4251_linear_3150_15389)'
      />
      <path
        d='M344.182 303.955L348.971 302.467L349.286 304.142L344.402 305.285L344.182 303.955Z'
        fill='url(#paint4252_linear_3150_15389)'
      />
      <path
        d='M340.275 316.025L345.537 315.788L345.579 317.522L340.181 317.394L340.275 316.025Z'
        fill='url(#paint4253_linear_3150_15389)'
      />
      <path
        d='M343.982 306.803L348.866 305.66L349.107 307.354L344.108 308.152L343.982 306.803Z'
        fill='url(#paint4254_linear_3150_15389)'
      />
      <path
        d='M439.371 492.027L439.907 489.997L442.784 492.559L442.144 494.598L439.371 492.027Z'
        fill='url(#paint4255_linear_3150_15389)'
      />
      <path
        d='M341.745 312.902L346.86 312.37L346.975 314.094L341.724 314.261L341.745 312.902Z'
        fill='url(#paint4256_linear_3150_15389)'
      />
      <path
        d='M343.121 309.798L348.12 308.97L348.299 310.685L343.174 311.148L343.121 309.798Z'
        fill='url(#paint4257_linear_3150_15389)'
      />
      <path
        d='M400.714 176.469L404.253 172.528L404.148 176.745L400.903 180.331L400.714 176.469Z'
        fill='url(#paint4258_linear_3150_15389)'
      />
      <path
        d='M344.77 278.958L348.362 275.116L349.307 276.791L345.631 280.318L344.77 278.958Z'
        fill='url(#paint4259_linear_3150_15389)'
      />
      <path
        d='M497.069 512.6L495.357 509.594L497.72 510.875L499.526 513.802L497.069 512.6Z'
        fill='url(#paint4260_linear_3150_15389)'
      />
      <path
        d='M346.597 281.53L350.441 278.091L351.271 279.845L347.353 282.969L346.597 281.53Z'
        fill='url(#paint4261_linear_3150_15389)'
      />
      <path
        d='M460.459 483.76L460.973 482.233L463.546 485.248L463.126 486.667L460.459 483.76Z'
        fill='url(#paint4262_linear_3150_15389)'
      />
      <path
        d='M348.509 284.2L352.604 281.185L353.36 283.008L349.128 285.708L348.509 284.2Z'
        fill='url(#paint4263_linear_3150_15389)'
      />
      <path
        d='M349.695 287.117L354.043 284.496L354.705 286.388L350.21 288.683L349.695 287.117Z'
        fill='url(#paint4264_linear_3150_15389)'
      />
      <path
        d='M356.028 467.128L360.964 467.316L362.192 470.291L357.278 470.281L356.028 467.128Z'
        fill='url(#paint4265_linear_3150_15389)'
      />
      <path
        d='M474.657 490.056L474.426 488.155L477.157 490.864L477.346 492.756L474.657 490.056Z'
        fill='url(#paint4266_linear_3150_15389)'
      />
      <path
        d='M402.184 180.903L404.946 177.444L404.326 181.918L401.932 185.002L402.184 180.903Z'
        fill='url(#paint4267_linear_3150_15389)'
      />
      <path
        d='M374.175 202.008L377.284 197.002L378.334 200.175L375.246 204.826L374.175 202.008Z'
        fill='url(#paint4268_linear_3150_15389)'
      />
      <path
        d='M349.244 290.398L353.749 288.082L354.337 290.023L349.674 292.004L349.244 290.398Z'
        fill='url(#paint4269_linear_3150_15389)'
      />
      <path
        d='M417.517 487.179L418.43 485.159L421.266 487.672L420.121 489.771L417.517 487.179Z'
        fill='url(#paint4270_linear_3150_15389)'
      />
      <path
        d='M451.595 146.398L452.278 144.831L450.136 151.029L449.369 152.31L451.595 146.398Z'
        fill='url(#paint4271_linear_3150_15389)'
      />
      <path
        d='M366.939 217.408L369.943 212.521L371.004 215.348L368.053 219.881L366.939 217.408Z'
        fill='url(#paint4272_linear_3150_15389)'
      />
      <path
        d='M464.481 484.686L463.704 482.588L466.182 485.159L466.96 487.228L464.481 484.686Z'
        fill='url(#paint4273_linear_3150_15389)'
      />
      <path
        d='M347.889 293.925L352.51 291.885L353.003 293.876L348.256 295.57L347.889 293.925Z'
        fill='url(#paint4274_linear_3150_15389)'
      />
      <path
        d='M355.597 232.837L358.328 227.733L359.672 230.138L356.868 234.906L355.597 232.837Z'
        fill='url(#paint4275_linear_3150_15389)'
      />
      <path
        d='M346.534 297.59L351.239 295.816L351.649 297.846L346.849 299.265L346.534 297.59Z'
        fill='url(#paint4276_linear_3150_15389)'
      />
      <path
        d='M460.942 487.367L459.43 484.893L461.887 487.051L463.126 489.613L460.942 487.367Z'
        fill='url(#paint4277_linear_3150_15389)'
      />
      <path
        d='M399.033 481.169L401.07 479.632L403.696 482.302L401.438 483.947L399.033 481.169Z'
        fill='url(#paint4278_linear_3150_15389)'
      />
      <path
        d='M348.298 271.204L351.764 267.381L352.772 269.421L349.191 272.919L348.298 271.204Z'
        fill='url(#paint4279_linear_3150_15389)'
      />
      <path
        d='M346.513 301.108L351.323 299.699L351.638 301.758L346.744 302.812L346.513 301.108Z'
        fill='url(#paint4280_linear_3150_15389)'
      />
      <path
        d='M444.59 489.081L443.666 486.509L446.302 488.686L447.069 491.288L444.59 489.081Z'
        fill='url(#paint4281_linear_3150_15389)'
      />
      <path
        d='M347.395 304.527L352.321 303.541L352.51 305.63L347.5 306.251L347.395 304.527Z'
        fill='url(#paint4282_linear_3150_15389)'
      />
      <path
        d='M378.586 475.366L381.989 474.449L384.194 477.238L380.665 478.321L378.586 475.366Z'
        fill='url(#paint4283_linear_3150_15389)'
      />
      <path
        d='M343.205 319.601L348.519 319.946L348.341 322.084L342.964 321.345L343.205 319.601Z'
        fill='url(#paint4284_linear_3150_15389)'
      />
      <path
        d='M472.494 474.932L472.011 473.414L474.426 476.4L474.794 477.907L472.494 474.932Z'
        fill='url(#paint4285_linear_3150_15389)'
      />
      <path
        d='M347.374 308.123L352.384 307.512L352.478 309.63L347.374 309.857V308.123Z'
        fill='url(#paint4286_linear_3150_15389)'
      />
      <path
        d='M345.242 315.66L350.451 315.729L350.357 317.867L345.074 317.404L345.242 315.66Z'
        fill='url(#paint4287_linear_3150_15389)'
      />
      <path
        d='M346.492 311.857L351.607 311.591L351.596 313.729L346.398 313.601L346.492 311.857Z'
        fill='url(#paint4288_linear_3150_15389)'
      />
      <path
        d='M404.326 186.41L406.426 183.405L405.576 187.987L403.748 190.637L404.326 186.41Z'
        fill='url(#paint4289_linear_3150_15389)'
      />
      <path
        d='M375.866 206.205L378.838 201.554L379.552 204.914L376.727 209.18L375.866 206.205Z'
        fill='url(#paint4290_linear_3150_15389)'
      />
      <path
        d='M390.527 195.731L393.236 191.908L393.1 195.928L390.684 199.357L390.527 195.731Z'
        fill='url(#paint4291_linear_3150_15389)'
      />
      <path
        d='M350.01 274.475L353.759 271.047L354.652 273.165L350.798 276.269L350.01 274.475Z'
        fill='url(#paint4292_linear_3150_15389)'
      />
      <path
        d='M490.673 479.139L489.307 477.208L491.46 479.937L492.773 481.878L490.673 479.139Z'
        fill='url(#paint4293_linear_3150_15389)'
      />
      <path
        d='M415.983 177.553L417.464 175.08L416.015 180.193L414.996 182.312L415.983 177.553Z'
        fill='url(#paint4294_linear_3150_15389)'
      />
      <path
        d='M368.368 221.457L371.245 216.905L372.127 219.871L369.26 224.068L368.368 221.457Z'
        fill='url(#paint4295_linear_3150_15389)'
      />
      <path
        d='M352.184 277.806L356.248 274.83L356.984 277.027L352.856 279.678L352.184 277.806Z'
        fill='url(#paint4296_linear_3150_15389)'
      />
      <path
        d='M356.364 236.808L359.052 231.98L360.26 234.512L357.498 239.005L356.364 236.808Z'
        fill='url(#paint4297_linear_3150_15389)'
      />
      <path
        d='M473.271 477.967L471.864 475.868L474.038 478.41L475.361 480.528L473.271 477.967Z'
        fill='url(#paint4298_linear_3150_15389)'
      />
      <path
        d='M354.18 281.363L358.464 278.83L359.031 281.106L354.726 283.304L354.18 281.363Z'
        fill='url(#paint4299_linear_3150_15389)'
      />
      <path
        d='M378.491 210.924L381.295 206.717L381.684 210.235L379.111 214.048L378.491 210.924Z'
        fill='url(#paint4300_linear_3150_15389)'
      />
      <path
        d='M393.152 201.19L395.399 197.85L394.948 201.988L393.047 204.954L393.152 201.19Z'
        fill='url(#paint4301_linear_3150_15389)'
      />
      <path
        d='M355.093 285.205L359.473 283.067L359.924 285.402L355.492 287.205L355.093 285.205Z'
        fill='url(#paint4302_linear_3150_15389)'
      />
      <path
        d='M418.714 184.597L419.449 182.607L417.895 187.731L417.538 189.396L418.714 184.597Z'
        fill='url(#paint4303_linear_3150_15389)'
      />
      <path
        d='M405.986 192.46L407.487 189.8L406.5 194.45L405.25 196.766L405.986 192.46Z'
        fill='url(#paint4304_linear_3150_15389)'
      />
      <path
        d='M349.475 259.726L352.531 255.43L353.644 257.785L350.577 261.706L349.475 259.726Z'
        fill='url(#paint4305_linear_3150_15389)'
      />
      <path
        d='M461.194 484.726L458.852 481.859L460.847 483.711L462.916 486.657L461.194 484.726Z'
        fill='url(#paint4306_linear_3150_15389)'
      />
      <path
        d='M369.449 225.979L372.138 221.733L372.768 224.846L370.205 228.718L369.449 225.979Z'
        fill='url(#paint4307_linear_3150_15389)'
      />
      <path
        d='M357.236 241.143L359.893 236.581L360.953 239.242L358.265 243.439L357.236 241.143Z'
        fill='url(#paint4308_linear_3150_15389)'
      />
      <path
        d='M436.661 486.076L435.506 483.337L438.079 485.396L439.003 488.194L436.661 486.076Z'
        fill='url(#paint4309_linear_3150_15389)'
      />
      <path
        d='M354.211 289.353L358.622 287.52L359.021 289.905L354.505 291.393L354.211 289.353Z'
        fill='url(#paint4310_linear_3150_15389)'
      />
      <path
        d='M352.793 263.352L356.122 259.548L357.036 262.002L353.749 265.431L352.793 263.352Z'
        fill='url(#paint4311_linear_3150_15389)'
      />
      <path
        d='M358.842 471.356L363.264 470.567L365.448 473.503L361.027 474.4L358.842 471.356Z'
        fill='url(#paint4312_linear_3150_15389)'
      />
      <path
        d='M352.394 293.738L356.868 292.151L357.204 294.585L352.615 295.807L352.394 293.738Z'
        fill='url(#paint4313_linear_3150_15389)'
      />
      <path
        d='M356.059 267.214L359.588 263.903L360.292 266.436L356.847 269.382L356.059 267.214Z'
        fill='url(#paint4314_linear_3150_15389)'
      />
      <path
        d='M351.628 298.073L356.217 296.821L356.469 299.295L351.764 300.181L351.628 298.073Z'
        fill='url(#paint4315_linear_3150_15389)'
      />
      <path
        d='M380.497 216.126L382.808 212.313L382.839 216.008L380.896 219.398L380.497 216.126Z'
        fill='url(#paint4316_linear_3150_15389)'
      />
      <path
        d='M358.496 245.725L361.121 241.488L362.046 244.247L359.42 248.119L358.496 245.725Z'
        fill='url(#paint4317_linear_3150_15389)'
      />
      <path
        d='M371.308 230.926L373.745 227.024L374.133 230.265L371.907 233.763L371.308 230.926Z'
        fill='url(#paint4318_linear_3150_15389)'
      />
      <path
        d='M352.352 302.3L357.089 301.482L357.204 303.994L352.363 304.428L352.352 302.3Z'
        fill='url(#paint4319_linear_3150_15389)'
      />
      <path
        d='M338.605 330.893L343.961 331.74L343.573 334.272L338.185 333.021L338.605 330.893Z'
        fill='url(#paint4320_linear_3150_15389)'
      />
      <path
        d='M352.3 306.684L357.152 306.261L357.131 308.803L352.195 308.822L352.3 306.684Z'
        fill='url(#paint4321_linear_3150_15389)'
      />
      <path
        d='M394.349 207.052L395.82 204.027L395.242 208.284L394.024 210.944L394.349 207.052Z'
        fill='url(#paint4322_linear_3150_15389)'
      />
      <path
        d='M359.504 271.234L363.19 268.455L363.705 271.056L360.103 273.5L359.504 271.234Z'
        fill='url(#paint4323_linear_3150_15389)'
      />
      <path
        d='M343.226 325.631L348.425 326.341L348.078 328.893L342.848 327.77L343.226 325.631Z'
        fill='url(#paint4324_linear_3150_15389)'
      />
      <path
        d='M350.914 311.246L355.86 311.158L355.744 313.719L350.724 313.394L350.914 311.246Z'
        fill='url(#paint4325_linear_3150_15389)'
      />
      <path
        d='M346.66 320.666L351.754 321.168L351.46 323.73L346.335 322.823L346.66 320.666Z'
        fill='url(#paint4326_linear_3150_15389)'
      />
      <path
        d='M348.887 315.917L353.917 316.143L353.696 318.695L348.645 318.074L348.887 315.917Z'
        fill='url(#paint4327_linear_3150_15389)'
      />
      <path
        d='M406.867 198.973L407.655 196.529L406.626 201.268L406.08 203.367L406.867 198.973Z'
        fill='url(#paint4328_linear_3150_15389)'
      />
      <path
        d='M417.39 481.75L417.149 479.228L419.911 481.455L419.869 484.066L417.39 481.75Z'
        fill='url(#paint4329_linear_3150_15389)'
      />
      <path
        d='M361.783 275.598L365.522 273.273L365.847 275.943L362.213 277.943L361.783 275.598Z'
        fill='url(#paint4330_linear_3150_15389)'
      />
      <path
        d='M360.607 250.385L363.285 246.523L364.051 249.37L361.415 252.848L360.607 250.385Z'
        fill='url(#paint4331_linear_3150_15389)'
      />
      <path
        d='M450.913 482.597L448.508 479.513L450.44 481.297L452.635 484.42L450.913 482.597Z'
        fill='url(#paint4332_linear_3150_15389)'
      />
      <path
        d='M383.532 221.901L385.286 218.541L384.992 222.373L383.721 225.3L383.532 221.901Z'
        fill='url(#paint4333_linear_3150_15389)'
      />
      <path
        d='M373.808 236.187L375.971 232.699L376.16 236.02L374.238 239.133L373.808 236.187Z'
        fill='url(#paint4334_linear_3150_15389)'
      />
      <path
        d='M389.96 478.922L391.713 476.863L394.57 479.336L392.648 481.484L389.96 478.922Z'
        fill='url(#paint4335_linear_3150_15389)'
      />
      <path
        d='M363.705 280.161L367.433 278.269L367.58 280.998L363.988 282.565L363.705 280.161Z'
        fill='url(#paint4336_linear_3150_15389)'
      />
      <path
        d='M363.379 255.095L366.162 251.676L366.729 254.592L364.062 257.647L363.379 255.095Z'
        fill='url(#paint4337_linear_3150_15389)'
      />
      <path
        d='M364.954 284.91L368.609 283.422L368.641 286.2L365.06 287.363L364.954 284.91Z'
        fill='url(#paint4338_linear_3150_15389)'
      />
      <path
        d='M387.723 228.039L388.993 225.231L388.542 229.083L387.628 231.517L387.723 228.039Z'
        fill='url(#paint4339_linear_3150_15389)'
      />
      <path
        d='M376.99 241.646L378.974 238.611L378.995 241.971L377.242 244.661L376.99 241.646Z'
        fill='url(#paint4340_linear_3150_15389)'
      />
      <path
        d='M395.473 213.683L396.271 210.865L395.63 215.21L395.095 217.654L395.473 213.683Z'
        fill='url(#paint4341_linear_3150_15389)'
      />
      <path
        d='M365.07 289.836L368.662 288.683L368.641 291.521L365.007 292.319L365.07 289.836Z'
        fill='url(#paint4342_linear_3150_15389)'
      />
      <path
        d='M366.551 259.903L369.397 256.957L369.785 259.932L367.044 262.524L366.551 259.903Z'
        fill='url(#paint4343_linear_3150_15389)'
      />
      <path
        d='M391.818 234.364L392.816 232.078L392.301 235.891L391.493 237.872L391.818 234.364Z'
        fill='url(#paint4344_linear_3150_15389)'
      />
      <path
        d='M380.098 247.242L381.957 244.651L381.905 248.03L380.13 250.306L380.098 247.242Z'
        fill='url(#paint4345_linear_3150_15389)'
      />
      <path
        d='M370.059 264.81L373.01 262.376L373.22 265.391L370.363 267.499L370.059 264.81Z'
        fill='url(#paint4346_linear_3150_15389)'
      />
      <path
        d='M364.282 294.91L367.927 294.063L367.853 296.95L364.104 297.413L364.282 294.91Z'
        fill='url(#paint4347_linear_3150_15389)'
      />
      <path
        d='M360.848 472.962L364.703 471.454L367.465 474.39L363.6 475.829L360.848 472.962Z'
        fill='url(#paint4348_linear_3150_15389)'
      />
      <path
        d='M395.452 240.808L396.397 238.966L395.809 242.729L394.98 244.296L395.452 240.808Z'
        fill='url(#paint4349_linear_3150_15389)'
      />
      <path
        d='M363.558 300.053L367.328 299.561L367.149 302.477L363.316 302.596L363.558 300.053Z'
        fill='url(#paint4350_linear_3150_15389)'
      />
      <path
        d='M371.959 270.002L374.91 267.972L374.984 271.037L372.096 272.751L371.959 270.002Z'
        fill='url(#paint4351_linear_3150_15389)'
      />
      <path
        d='M362.907 305.285L366.75 305.147L366.477 308.103L362.55 307.837L362.907 305.285Z'
        fill='url(#paint4352_linear_3150_15389)'
      />
      <path
        d='M382.461 252.986L384.331 250.779L384.184 254.178L382.388 256.08L382.461 252.986Z'
        fill='url(#paint4353_linear_3150_15389)'
      />
      <path
        d='M431.925 481.809L430.024 478.646L432.356 480.41L433.931 483.632L431.925 481.809Z'
        fill='url(#paint4354_linear_3150_15389)'
      />
      <path
        d='M397.783 247.272L398.676 245.774L398.035 249.518L397.227 250.75L397.783 247.272Z'
        fill='url(#paint4355_linear_3150_15389)'
      />
      <path
        d='M360.491 310.665L364.44 310.813L364.125 313.798L360.071 313.237L360.491 310.665Z'
        fill='url(#paint4356_linear_3150_15389)'
      />
      <path
        d='M384.856 258.839L386.746 257.026L386.441 260.435L384.688 261.962L384.856 258.839Z'
        fill='url(#paint4357_linear_3150_15389)'
      />
      <path
        d='M372.337 275.431L375.215 273.726L375.141 276.83L372.432 278.229L372.337 275.431Z'
        fill='url(#paint4358_linear_3150_15389)'
      />
      <path
        d='M356.847 316.222L360.953 316.597L360.628 319.592L356.396 318.794L356.847 316.222Z'
        fill='url(#paint4359_linear_3150_15389)'
      />
      <path
        d='M335.476 340.943L340.464 341.78L340.107 344.736L335.119 343.505L335.476 340.943Z'
        fill='url(#paint4360_linear_3150_15389)'
      />
      <path
        d='M374.69 280.88L377.483 279.618L377.273 282.761L374.585 283.717L374.69 280.88Z'
        fill='url(#paint4361_linear_3150_15389)'
      />
      <path
        d='M352.951 321.888L357.246 322.489L356.868 325.484L352.489 324.469L352.951 321.888Z'
        fill='url(#paint4362_linear_3150_15389)'
      />
      <path
        d='M348.173 327.799L352.667 328.558L352.279 331.543L347.721 330.371L348.173 327.799Z'
        fill='url(#paint4363_linear_3150_15389)'
      />
      <path
        d='M341.714 334.223L346.471 335.031L346.093 337.997L341.336 336.795L341.714 334.223Z'
        fill='url(#paint4364_linear_3150_15389)'
      />
      <path
        d='M376.528 286.486L379.29 285.659L378.943 288.851L376.223 289.363L376.528 286.486Z'
        fill='url(#paint4365_linear_3150_15389)'
      />
      <path
        d='M386.567 264.8L388.363 263.352L387.933 266.78L386.305 267.953L386.567 264.8Z'
        fill='url(#paint4366_linear_3150_15389)'
      />
      <path
        d='M398.771 253.745L399.537 252.503L398.823 256.238L398.245 257.233L398.771 253.745Z'
        fill='url(#paint4367_linear_3150_15389)'
      />
      <path
        d='M377.221 292.26L379.972 291.807L379.531 295.048L376.748 295.156L377.221 292.26Z'
        fill='url(#paint4368_linear_3150_15389)'
      />
      <path
        d='M398.13 481.228L398.592 478.41L401.596 480.529L400.966 483.386L398.13 481.228Z'
        fill='url(#paint4369_linear_3150_15389)'
      />
      <path
        d='M400.105 260.356L400.682 259.41L399.926 263.145L399.527 263.854L400.105 260.356Z'
        fill='url(#paint4370_linear_3150_15389)'
      />
      <path
        d='M386.641 270.899L388.237 269.697L387.849 273.155L386.273 274.071L386.641 270.899Z'
        fill='url(#paint4371_linear_3150_15389)'
      />
      <path
        d='M376.044 298.142L378.849 297.975L378.365 301.275L375.467 301.059L376.044 298.142Z'
        fill='url(#paint4372_linear_3150_15389)'
      />
      <path
        d='M401.743 267.115L402.184 266.495L401.438 270.219L401.039 270.613L401.743 267.115Z'
        fill='url(#paint4373_linear_3150_15389)'
      />
      <path
        d='M388.09 277.126L389.729 276.279L389.224 279.767L387.607 280.328L388.09 277.126Z'
        fill='url(#paint4374_linear_3150_15389)'
      />
      <path
        d='M359.693 473.237L363.337 471.415L366.456 474.331L362.812 476.105L359.693 473.237Z'
        fill='url(#paint4375_linear_3150_15389)'
      />
      <path
        d='M374.595 304.142L377.515 304.29L376.958 307.63L373.955 307.088L374.595 304.142Z'
        fill='url(#paint4376_linear_3150_15389)'
      />
      <path
        d='M441.366 483.08L438.478 479.445L440.222 480.735L442.837 484.391L441.366 483.08Z'
        fill='url(#paint4377_linear_3150_15389)'
      />
      <path
        d='M389.308 283.481L390.978 282.998L390.348 286.516L388.71 286.703L389.308 283.481Z'
        fill='url(#paint4378_linear_3150_15389)'
      />
      <path
        d='M402.782 273.983L403.234 273.638L402.446 277.382L401.995 277.471L402.782 273.983Z'
        fill='url(#paint4379_linear_3150_15389)'
      />
      <path
        d='M390.695 290.023L392.385 289.935L391.629 293.501L389.907 293.265L390.695 290.023Z'
        fill='url(#paint4380_linear_3150_15389)'
      />
      <path
        d='M371.665 310.251L374.69 310.645L374.102 314.025L371.004 313.217L371.665 310.251Z'
        fill='url(#paint4381_linear_3150_15389)'
      />
      <path
        d='M403.391 280.959L403.885 280.9L403.034 284.673L402.499 284.456L403.391 280.959Z'
        fill='url(#paint4382_linear_3150_15389)'
      />
      <path
        d='M389.676 296.575L391.419 296.772L390.6 300.398L388.804 299.846L389.676 296.575Z'
        fill='url(#paint4383_linear_3150_15389)'
      />
      <path
        d='M404.158 288.142L404.736 288.427L403.749 292.24L403.161 291.679L404.158 288.142Z'
        fill='url(#paint4384_linear_3150_15389)'
      />
      <path
        d='M367.055 316.547L370.226 317.109L369.659 320.508L366.435 319.542L367.055 316.547Z'
        fill='url(#paint4385_linear_3150_15389)'
      />
      <path
        d='M387.471 303.216L389.287 303.65L388.426 307.335L386.567 306.537L387.471 303.216Z'
        fill='url(#paint4386_linear_3150_15389)'
      />
      <path
        d='M361.877 323.089L365.207 323.789L364.692 327.198L361.31 326.095L361.877 323.089Z'
        fill='url(#paint4387_linear_3150_15389)'
      />
      <path
        d='M402.877 295.255L403.476 295.767L402.436 299.64L401.837 298.832L402.877 295.255Z'
        fill='url(#paint4388_linear_3150_15389)'
      />
      <path
        d='M403.255 483.189L402.772 479.819L405.744 481.592L406.069 484.962L403.255 483.189Z'
        fill='url(#paint4389_linear_3150_15389)'
      />
      <path
        d='M446.428 484.312L442.994 480.341L444.233 481.248L447.468 485.169L446.428 484.312Z'
        fill='url(#paint4390_linear_3150_15389)'
      />
      <path
        d='M354.789 330.154L358.359 330.863L357.939 334.272L354.379 333.179L354.789 330.154Z'
        fill='url(#paint4391_linear_3150_15389)'
      />
      <path
        d='M383.522 309.955L385.423 310.547L384.583 314.271L382.682 313.315L383.522 309.955Z'
        fill='url(#paint4392_linear_3150_15389)'
      />
      <path
        d='M334.709 353.673L338.952 354.303L338.91 357.673L334.594 356.688L334.709 353.673Z'
        fill='url(#paint4393_linear_3150_15389)'
      />
      <path
        d='M346.083 338.105L349.926 338.687L349.674 342.096L345.873 341.14L346.083 338.105Z'
        fill='url(#paint4394_linear_3150_15389)'
      />
      <path
        d='M399.558 302.31L400.157 302.96L399.128 306.882L398.634 305.975L399.558 302.31Z'
        fill='url(#paint4395_linear_3150_15389)'
      />
      <path
        d='M338.983 346.273L343.048 346.786L342.943 350.185L338.889 349.298L338.983 346.273Z'
        fill='url(#paint4396_linear_3150_15389)'
      />
      <path
        d='M361.037 472.567L364.009 470.262L367.496 473.119L364.492 475.316L361.037 472.567Z'
        fill='url(#paint4397_linear_3150_15389)'
      />
      <path
        d='M377.851 316.971L379.846 317.621L379.122 321.375L377.147 320.38L377.851 316.971Z'
        fill='url(#paint4398_linear_3150_15389)'
      />
      <path
        d='M395.904 309.66L396.418 310.389L395.515 314.389L395.022 313.365L395.904 309.66Z'
        fill='url(#paint4399_linear_3150_15389)'
      />
      <path
        d='M448.602 484.607L444.832 480.41L445.693 481.021L449.295 485.139L448.602 484.607Z'
        fill='url(#paint4400_linear_3150_15389)'
      />
      <path
        d='M372.211 324.351L374.301 325.05L373.724 328.834L371.644 327.789L372.211 324.351Z'
        fill='url(#paint4401_linear_3150_15389)'
      />
      <path
        d='M407.928 483.543L406.574 479.73L409.325 481.198L410.554 484.952L407.928 483.543Z'
        fill='url(#paint4402_linear_3150_15389)'
      />
      <path
        d='M390.275 317.335L390.852 318.025L390.159 322.104L389.529 321.089L390.275 317.335Z'
        fill='url(#paint4403_linear_3150_15389)'
      />
      <path
        d='M331.768 377.438L335.686 377.783L336.158 381.507L332.178 380.857L331.768 377.438Z'
        fill='url(#paint4404_linear_3150_15389)'
      />
      <path
        d='M363.873 332.568L366.173 333.12L365.868 336.933L363.537 336.026L363.873 332.568Z'
        fill='url(#paint4405_linear_3150_15389)'
      />
      <path
        d='M339.151 367.674L342.564 368.068L342.922 371.822L339.424 371.102L339.151 367.674Z'
        fill='url(#paint4406_linear_3150_15389)'
      />
      <path
        d='M362.161 469.641L364.461 466.981L368.095 469.858L365.763 472.321L362.161 469.641Z'
        fill='url(#paint4407_linear_3150_15389)'
      />
      <path
        d='M447.552 484.016L443.645 479.651L444.307 480.124L448.14 484.361L447.552 484.016Z'
        fill='url(#paint4408_linear_3150_15389)'
      />
      <path
        d='M353.497 342.155L356.144 342.421L356.175 346.234L353.508 345.633L353.497 342.155Z'
        fill='url(#paint4409_linear_3150_15389)'
      />
      <path
        d='M342.249 359.87L345.41 360.126L345.757 363.91L342.512 363.329L342.249 359.87Z'
        fill='url(#paint4410_linear_3150_15389)'
      />
      <path
        d='M383.259 325.651L384.036 326.223L383.522 330.341L382.839 329.494L383.259 325.651Z'
        fill='url(#paint4411_linear_3150_15389)'
      />
      <path
        d='M346.429 351.505L349.328 351.672L349.601 355.485L346.618 354.983L346.429 351.505Z'
        fill='url(#paint4412_linear_3150_15389)'
      />
      <path
        d='M409.714 482.302L407.813 478.223L410.365 479.533L412.171 483.504L409.714 482.302Z'
        fill='url(#paint4413_linear_3150_15389)'
      />
      <path
        d='M328.765 408.957L332.64 408.553L334.163 412.485L330.162 412.692L328.765 408.957Z'
        fill='url(#paint4414_linear_3150_15389)'
      />
      <path
        d='M375.425 335.021L376.307 335.327L376.139 339.484L375.341 338.893L375.425 335.021Z'
        fill='url(#paint4415_linear_3150_15389)'
      />
      <path
        d='M444.496 482.558L440.495 478.065L441.156 478.499L445.052 482.854L444.496 482.558Z'
        fill='url(#paint4416_linear_3150_15389)'
      />
      <path
        d='M335.633 398.809L338.679 398.306L340.181 402.257L336.957 402.562L335.633 398.809Z'
        fill='url(#paint4417_linear_3150_15389)'
      />
      <path
        d='M406.857 479.977L404.914 475.78L407.498 477.159L409.42 481.179L406.857 479.977Z'
        fill='url(#paint4418_linear_3150_15389)'
      />
      <path
        d='M365.648 464.32L366.96 461.404L370.542 464.232L369.155 467L365.648 464.32Z'
        fill='url(#paint4419_linear_3150_15389)'
      />
      <path
        d='M366.603 345.682L367.654 345.643L367.969 349.811L366.803 349.554L366.603 345.682Z'
        fill='url(#paint4420_linear_3150_15389)'
      />
      <path
        d='M342.932 387.685L345.347 387.192L346.608 391.222L344.098 391.439L342.932 387.685Z'
        fill='url(#paint4421_linear_3150_15389)'
      />
      <path
        d='M341.199 434.17L343.982 432.505L346.744 436.328L343.898 437.727L341.199 434.17Z'
        fill='url(#paint4422_linear_3150_15389)'
      />
      <path
        d='M349.338 376.374L351.418 376.019L352.331 380.118L350.294 380.148L349.338 376.374Z'
        fill='url(#paint4423_linear_3150_15389)'
      />
      <path
        d='M359.83 356.008L361.205 355.841L361.814 359.979L360.26 359.88L359.83 356.008Z'
        fill='url(#paint4424_linear_3150_15389)'
      />
      <path
        d='M353.98 366.225L355.755 365.969L356.532 370.088L354.673 370.058L353.98 366.225Z'
        fill='url(#paint4425_linear_3150_15389)'
      />
      <path
        d='M406.322 472.774L404.137 468.616L406.532 470.282L408.726 474.242L406.322 472.774Z'
        fill='url(#paint4426_linear_3150_15389)'
      />
      <path
        d='M346.272 422.732L348.351 421.057L350.809 425.027L348.719 426.367L346.272 422.732Z'
        fill='url(#paint4427_linear_3150_15389)'
      />
      <path
        d='M369.397 456.931L369.827 453.817L373.146 456.734L372.673 459.64L369.397 456.931Z'
        fill='url(#paint4428_linear_3150_15389)'
      />
      <path
        d='M353.434 408.849L354.82 407.43L356.868 411.499L355.419 412.662L353.434 408.849Z'
        fill='url(#paint4429_linear_3150_15389)'
      />
      <path
        d='M360.355 395.794L361.142 394.611L362.844 398.769L361.919 399.745L360.355 395.794Z'
        fill='url(#paint4430_linear_3150_15389)'
      />
      <path
        d='M370.815 371.901L370.951 371.113L372.012 375.458L371.791 376.029L370.815 371.901Z'
        fill='url(#paint4431_linear_3150_15389)'
      />
      <path
        d='M405.418 464.448L403.034 460.379L405.282 462.369L407.645 466.242L405.418 464.448Z'
        fill='url(#paint4432_linear_3150_15389)'
      />
      <path
        d='M364.849 384.433L365.333 383.359L366.719 387.635L366.204 388.463L364.849 384.433Z'
        fill='url(#paint4433_linear_3150_15389)'
      />
      <path
        d='M370.93 447.432L370.888 444.329L373.923 447.492L373.965 450.329L370.93 447.432Z'
        fill='url(#paint4434_linear_3150_15389)'
      />
      <path
        d='M373.871 434.801L373.367 431.904L376.003 435.363L376.486 438.013L373.871 434.801Z'
        fill='url(#paint4435_linear_3150_15389)'
      />
      <path
        d='M405.009 454.901L402.415 450.94L404.473 453.275L407.036 457.049L405.009 454.901Z'
        fill='url(#paint4436_linear_3150_15389)'
      />
      <path
        d='M380.172 418.307L379.143 415.834L381.211 419.638L382.199 421.914L380.172 418.307Z'
        fill='url(#paint4437_linear_3150_15389)'
      />
      <path d='M463.369 179.237L463.188 178.246L463.369 179.076L463.388 179.924L463.369 179.237Z' fill='#5179E0' />
      <path d='M445.903 180.603L445.865 179.692L445.96 180.514L446.093 181.379L445.903 180.603Z' fill='#5179E0' />
      <path d='M269.77 326.07L269.656 324.909L270.418 325.695L270.665 326.784L269.77 326.07Z' fill='#5179E0' />
      <path d='M457.369 206.633L457.293 205.597L457.522 207.124L457.369 206.633Z' fill='#5179E0' />
      <path d='M405.617 179.532L405.57 178.684L405.941 179.559L405.884 180.317L405.617 179.532Z' fill='#5179E0' />
      <path d='M218.875 244.562L219.17 243.651L220.094 244.776L219.723 245.677L218.875 244.562Z' fill='#5179E0' />
      <path d='M284.875 341.397L284.627 340.281L285.361 341.075L285.532 342.084L284.875 341.397Z' fill='#5179E0' />
      <path d='M410.341 175.667L410.493 174.756L410.97 175.47L410.846 176.318L410.341 175.667Z' fill='#5179E0' />
      <path d='M329.589 172.944L329.903 172.382L330.208 173.524L330.141 174.113L329.589 172.944Z' fill='#5179E0' />
      <path d='M406.884 203.705L406.922 202.687L407.446 203.142L407.417 204.071L406.884 203.705Z' fill='#5179E0' />
      <path d='M396.579 205.437L396.56 204.499L397.046 205.079L397.027 205.936L396.579 205.437Z' fill='#5179E0' />
      <path d='M212.875 277.206L212.98 276.028L214.228 276.706L214.094 277.857L212.875 277.206Z' fill='#5179E0' />
      <path d='M447.436 135.667L447.484 135.22L447.598 136.256L447.56 136.658L447.436 135.667Z' fill='#5179E0' />
      <path d='M403.103 229.726L403.074 228.833L403.341 229.342L403.465 230.154L403.103 229.726Z' fill='#5179E0' />
      <path d='M404.608 230.502L404.57 229.458L405.103 229.636L405.141 230.565L404.608 230.502Z' fill='#5179E0' />
      <path d='M473.541 232.707L473.379 231.493L473.722 231.386L473.665 232.609L473.541 232.707Z' fill='#5179E0' />
      <path d='M420.77 136.908L420.969 136.452L421.284 137.488L420.969 137.898L420.77 136.908Z' fill='#5179E0' />
      <path d='M225.056 326.739L224.98 325.454L226.056 325.65L226.589 326.739H225.056Z' fill='#5179E0' />
      <path d='M215.323 290.64L215.409 289.462L216.628 290.015L216.532 291.167L215.323 290.64Z' fill='#5179E0' />
      <path d='M367.76 278.447L368.027 277.563L368.284 278.107L368.398 278.866L367.76 278.447Z' fill='#5179E0' />
      <path d='M225.818 204.946L226.751 204.535L227.085 205.99L226.561 206.347L225.818 204.946Z' fill='#5179E0' />
      <path d='M408.046 133.462L408.446 133.194L408.465 134.238L408.427 134.542L408.046 133.462Z' fill='#5179E0' />
      <path d='M482.093 353.465L482.055 352.376L482.15 352.823L482.264 353.849L482.093 353.465Z' fill='#5179E0' />
      <path d='M480.407 215.291L480.341 214.238L480.693 214.434L480.464 215.541L480.407 215.291Z' fill='#5179E0' />
      <path d='M306.951 161.527L307.789 161.17L308.008 162.339L307.694 162.705L306.951 161.527Z' fill='#5179E0' />
      <path d='M266.065 158.304L266.618 158.644L266.446 160.259L266.38 159.759L266.065 158.304Z' fill='#5179E0' />
      <path d='M450.903 273.117L450.827 272.778L450.96 273.501L450.941 273.849L450.903 273.117Z' fill='#5179E0' />
      <path d='M438.16 239.563L438.093 238.456L438.503 238.349L438.417 239.464L438.16 239.563Z' fill='#5179E0' />
      <path d='M448.246 227.556L448.312 226.289L448.75 226.226L448.56 227.503L448.246 227.556Z' fill='#5179E0' />
      <path d='M517.588 348.582V347.368L517.836 347.94L517.76 349.216L517.588 348.582Z' fill='#5179E0' />
      <path d='M227.266 184.209L228.447 184.022L228.751 185.512L227.761 185.709L227.266 184.209Z' fill='#5179E0' />
      <path d='M512.426 321.99L512.493 321.214L512.731 321.937L512.521 322.82L512.426 321.99Z' fill='#5179E0' />
      <path d='M531.121 355.92L531.169 354.724L531.255 355.42L531.331 356.509L531.121 355.92Z' fill='#5179E0' />
      <path d='M443.16 332.408L443.274 330.997L443.627 331.435L443.655 332.675L443.16 332.408Z' fill='#5179E0' />
      <path d='M484.007 323.544L484.064 322.687L484.35 323.329L484.16 324.293L484.007 323.544Z' fill='#5179E0' />
      <path d='M333.208 297.103L333.503 296.121L333.818 296.532L334.065 297.264L333.208 297.103Z' fill='#5179E0' />
      <path d='M465.731 337.085L465.76 336.112L466.045 336.665L465.96 337.683L465.731 337.085Z' fill='#5179E0' />
      <path d='M521.826 243.955L521.864 243.455L521.874 243.99L521.921 244.428L521.826 243.955Z' fill='#5179E0' />
      <path d='M465.455 212.194L465.703 211.105L466.055 211.337L465.665 212.462L465.455 212.194Z' fill='#5179E0' />
      <path d='M423.389 287.936L423.322 287.302L423.874 287.739L423.417 288.721L423.389 287.936Z' fill='#5179E0' />
      <path d='M488.655 326.962L488.874 326.025L489.017 326.855L488.941 327.668L488.655 326.962Z' fill='#5179E0' />
      <path d='M364.427 330.613L363.798 329.676L364.532 328.828L364.846 330.131L364.427 330.613Z' fill='#5179E0' />
      <path d='M271.932 127.74L271.827 128.267L272.094 129.695L271.78 129.249L271.932 127.74Z' fill='#5179E0' />
      <path d='M442.588 217.469L442.893 216.389L443.188 216.63L442.931 217.648L442.588 217.469Z' fill='#5179E0' />
      <path d='M516.817 288.007L516.693 288.096L516.979 288.864L516.674 289.007L516.817 288.007Z' fill='#5179E0' />
      <path d='M502.121 225.869L502.521 225.218L502.541 225.762L502.293 226.334L502.121 225.869Z' fill='#5179E0' />
      <path d='M465.169 375.585L465.103 374.094L465.417 374.389L465.598 375.728L465.169 375.585Z' fill='#5179E0' />
      <path d='M504.502 192.823L504.522 192.448L504.712 192.948L504.445 193.332L504.502 192.823Z' fill='#5179E0' />
      <path d='M497.093 219.88L497.531 219.174L497.626 219.692L497.226 220.353L497.093 219.88Z' fill='#5179E0' />
      <path d='M510.36 227.833L510.636 227.405V227.985L510.493 228.333L510.36 227.833Z' fill='#5179E0' />
      <path d='M451.331 282.794L451.626 282.294L451.922 283.115L451.407 283.812L451.331 282.794Z' fill='#5179E0' />
      <path d='M375.608 304.164L375.351 303.066L376.17 302.78L376.036 304.351L375.608 304.164Z' fill='#5179E0' />
      <path d='M458.084 333.14L458.198 332.131L458.588 332.765L458.36 333.916L458.084 333.14Z' fill='#5179E0' />
      <path d='M346.894 249.159L347.636 248.4L347.789 249.302L347.627 249.784L346.894 249.159Z' fill='#5179E0' />
      <path d='M248.97 161.179L250.104 161.625L249.951 163.098L248.704 162.732L248.97 161.179Z' fill='#5179E0' />
      <path d='M432.76 211.819L433.046 211.069L433.198 211.462L433.074 212.14L432.76 211.819Z' fill='#5179E0' />
      <path d='M221.694 310.038L222.104 309.199L222.342 309.939L222.656 310.779L221.694 310.038Z' fill='#5179E0' />
      <path d='M463.312 204.767L463.807 203.892L464.036 204.294L463.531 205.16L463.312 204.767Z' fill='#5179E0' />
      <path d='M408.855 213.033L408.979 213.265L408.998 214.247L408.922 213.988L408.855 213.033Z' fill='#5179E0' />
      <path d='M461.855 263.691L461.903 263.7L462.15 264.503L461.76 264.673L461.855 263.691Z' fill='#5179E0' />
      <path d='M496.341 196.314L496.607 195.894L496.836 196.376L496.293 196.849L496.341 196.314Z' fill='#5179E0' />
      <path d='M461.769 210.721L462.265 209.828L462.493 210.23L461.969 211.123L461.769 210.721Z' fill='#5179E0' />
      <path d='M238.199 134.31L239.266 134.524L239.389 135.97L238.294 135.818L238.199 134.31Z' fill='#5179E0' />
      <path d='M505.417 230.708L505.541 230.627L505.474 231.261L505.56 231.243L505.417 230.708Z' fill='#5179E0' />
      <path d='M239.247 56.6758L239.599 56.4259L239.913 58.3272L239.627 58.604L239.247 56.6758Z' fill='#5179E0' />
      <path d='M275.361 143.808L275.675 144.629L275.627 146.066L275.075 145.307L275.361 143.808Z' fill='#5179E0' />
      <path d='M325.094 323.124L325.132 322.499L325.37 322.963L325.484 323.57L325.094 323.124Z' fill='#5179E0' />
      <path d='M242.875 117.778L243.466 118.09L243.761 119.536L242.694 119.287L242.875 117.778Z' fill='#5179E0' />
      <path d='M495.76 205.633L496.388 205.142L496.407 205.704L495.893 206.142L495.76 205.633Z' fill='#5179E0' />
      <path d='M454.817 207.989L455.112 207.481L455.179 207.981L455.007 208.445L454.817 207.989Z' fill='#5179E0' />
      <path d='M493.788 199.982L494.341 199.527L494.464 200.045L493.836 200.518L493.788 199.982Z' fill='#5179E0' />
      <path d='M401.846 299.558L402.017 298.326L402.665 298.63L402.255 300.227L401.846 299.558Z' fill='#5179E0' />
      <path d='M449.026 202.419L449.446 201.812L449.55 202.303L449.265 202.857L449.026 202.419Z' fill='#5179E0' />
      <path d='M204.209 209.105L205.894 208.4L206.97 209.534L204.894 210.542L204.209 209.105Z' fill='#5179E0' />
      <path d='M257.065 145.736L258.17 146.352L257.808 147.771L256.675 147.173L257.065 145.736Z' fill='#5179E0' />
      <path d='M192.247 295.121L192.047 294.47L192.675 295.505L192.304 296.139L192.247 295.121Z' fill='#5179E0' />
      <path d='M379.246 217.934L379.855 217.871L379.884 218.898L379.341 218.942L379.246 217.934Z' fill='#5179E0' />
      <path d='M493.398 220.326L493.693 220.237L493.664 220.871L493.493 220.879L493.398 220.326Z' fill='#5179E0' />
      <path d='M214.466 263.95L214.761 263.405L215.075 264.369L214.97 264.914L214.466 263.95Z' fill='#5179E0' />
      <path d='M465.236 206.383L465.845 205.758L465.988 206.258L465.369 206.9L465.236 206.383Z' fill='#5179E0' />
      <path d='M221.447 183.906L223.608 183.691L223.637 185.182L221.78 185.396L221.447 183.906Z' fill='#5179E0' />
      <path d='M191.971 238.429L193.713 236.938L195.113 237.724L193.685 239.152L191.971 238.429Z' fill='#5179E0' />
      <path d='M251.323 64.1116L251.675 64.192L251.704 65.763L251.589 65.7541L251.323 64.1116Z' fill='#5179E0' />
      <path d='M462.226 203.999L462.445 203.767L462.484 204.321L462.322 204.517L462.226 203.999Z' fill='#5179E0' />
      <path d='M343.427 238.456L343.846 238.117L344.217 238.965L343.646 239.411L343.427 238.456Z' fill='#5179E0' />
      <path d='M440.769 105.7L441.503 106.45L441.112 107.387L440.855 106.673L440.769 105.7Z' fill='#5179E0' />
      <path d='M407.56 297.621L408.055 296.157L408.512 296.88L408.074 298.326L407.56 297.621Z' fill='#5179E0' />
      <path d='M473.636 212.408L474.331 211.935L474.284 212.56L473.817 212.926L473.636 212.408Z' fill='#5179E0' />
      <path d='M484.293 214.104L484.798 213.899L484.722 214.55L484.436 214.649L484.293 214.104Z' fill='#5179E0' />
      <path d='M468.874 207.168L469.541 206.677L469.636 207.231L468.941 207.731L468.874 207.168Z' fill='#5179E0' />
      <path d='M339.408 130.373L339.827 131.007L339.856 132.114L338.875 131.561L339.408 130.373Z' fill='#5179E0' />
      <path d='M452.36 382.78L451.607 381.146L452.236 380.861L452.941 382.869L452.36 382.78Z' fill='#5179E0' />
      <path d='M370.903 294.229L371.036 292.765L371.77 292.809L371.532 294.514L370.903 294.229Z' fill='#5179E0' />
      <path d='M261.723 397.027L261.361 396.161L261.904 397L261.751 397.839L261.723 397.027Z' fill='#5179E0' />
      <path d='M186.056 268.726L187.047 266.949L188.866 267.056L188.047 268.77L186.056 268.726Z' fill='#5179E0' />
      <path d='M621.521 365.382L621.464 364.052L621.54 365.168L621.664 366.427L621.521 365.382Z' fill='#5179E0' />
      <path d='M239.056 28.4412L239.589 28.5305L239.742 30.3872L239.323 30.3604L239.056 28.4412Z' fill='#5179E0' />
      <path d='M467.417 204.017L467.607 203.964L467.626 204.535L467.474 204.562L467.417 204.017Z' fill='#5179E0' />
      <path d='M234.837 165.49L237.094 165.705L236.742 167.133L234.532 167.008L234.837 165.49Z' fill='#5179E0' />
      <path d='M395.255 332.372L395.074 330.569L395.77 330.658L395.97 332.506L395.255 332.372Z' fill='#5179E0' />
      <path d='M358.57 218.523L358.77 218.567L359.017 219.46L358.522 219.549L358.57 218.523Z' fill='#5179E0' />
      <path d='M363.808 292.56L363.865 291.988L364.122 292.354L364.094 292.908L363.808 292.56Z' fill='#5179E0' />
      <path d='M448.741 189.074L449.017 188.967L448.96 189.556L448.912 189.61L448.741 189.074Z' fill='#5179E0' />
      <path d='M192.742 278.581L193.647 276.893L195.209 277.17L194.58 278.67L192.742 278.581Z' fill='#5179E0' />
      <path d='M260.056 100.425L260.008 101.21L260.285 102.54L259.675 101.915L260.056 100.425Z' fill='#5179E0' />
      <path d='M235.323 255.095L235.561 254.979L235.666 256.139L235.618 256.184L235.323 255.095Z' fill='#5179E0' />
      <path d='M191.018 305.637L191.352 303.977L192.809 303.959L192.875 305.226L191.018 305.637Z' fill='#5179E0' />
      <path d='M224.97 92.2927L225.628 92.5873L226.028 94.078L224.694 93.7834L224.97 92.2927Z' fill='#5179E0' />
      <path d='M363.284 132.435L363.541 133.346L363.322 134.363L362.855 133.462L363.284 132.435Z' fill='#5179E0' />
      <path d='M221.418 190.743L221.285 190.145L221.57 191.404L221.542 191.859L221.418 190.743Z' fill='#5179E0' />
      <path d='M361.084 337.853L361.179 338.237L361.589 339.933L361.103 340.075L361.084 337.853Z' fill='#5179E0' />
      <path d='M256.713 420.146L256.77 419.129L256.961 419.754L257.332 420.789L256.713 420.146Z' fill='#5179E0' />
      <path d='M359.998 219.942L360.922 219.692L360.97 220.701L360.094 220.969L359.998 219.942Z' fill='#5179E0' />
      <path d='M367.675 306.913L367.713 305.128L368.37 305.217L368.427 306.851L367.675 306.913Z' fill='#5179E0' />
      <path d='M216.094 111.181L217.418 111.378L217.913 112.824L216.018 112.708L216.094 111.181Z' fill='#5179E0' />
      <path d='M208.961 131.07L210.818 130.998L211.456 132.382L209.351 132.578L208.961 131.07Z' fill='#5179E0' />
      <path d='M193.58 340.129L193.132 338.79L194.513 338.183L195.151 339.219L193.58 340.129Z' fill='#5179E0' />
      <path d='M241.437 150.03L243.589 150.476L243.104 151.824L240.837 151.485L241.437 150.03Z' fill='#5179E0' />
      <path d='M409.836 289.051L409.884 288.846L410.036 289.587L409.941 289.819L409.836 289.051Z' fill='#5179E0' />
      <path d='M221.732 140.871L223.885 140.969L224.028 142.344L221.866 142.362L221.732 140.871Z' fill='#5179E0' />
      <path d='M576.112 367.462L576.226 365.971L576.435 367.132L576.369 368.578L576.112 367.462Z' fill='#5179E0' />
      <path d='M406.617 344.887L406.484 343.066L407.15 343.494L407.36 345.181L406.617 344.887Z' fill='#5179E0' />
      <path d='M415.122 305.824L415.474 305.199L415.57 306.28L415.484 306.592L415.122 305.824Z' fill='#5179E0' />
      <path d='M545.74 387.957L545.464 385.877L545.931 386.476L546.226 388.538L545.74 387.957Z' fill='#5179E0' />
      <path d='M220.713 380.53L220.637 379.62L221.208 380.325L221.151 381.262L220.713 380.53Z' fill='#5179E0' />
      <path d='M593.092 362.642L593.283 361.41L593.426 362.847L593.34 363.918L593.092 362.642Z' fill='#5179E0' />
      <path d='M389.474 282.678L389.817 282.16L389.865 283.017L389.836 283.285L389.474 282.678Z' fill='#5179E0' />
      <path d='M249.427 391.885L249.551 391.126L249.656 392.019L249.894 392.742L249.427 391.885Z' fill='#5179E0' />
      <path d='M480.236 362.258L480.207 361.722L480.369 362.597L480.379 363.151L480.236 362.258Z' fill='#5179E0' />
      <path d='M475.569 200.411L475.788 200.705L475.731 201.295L475.588 200.955L475.569 200.411Z' fill='#5179E0' />
      <path d='M252.342 333.55L252.418 332.782L252.856 333.184L253.008 333.907L252.342 333.55Z' fill='#5179E0' />
      <path d='M222.58 332.247L222.323 331.809L222.932 332.666L222.551 333.282L222.58 332.247Z' fill='#5179E0' />
      <path d='M364.846 319.768L364.56 318.107L365.284 317.866L365.551 319.741L364.846 319.768Z' fill='#5179E0' />
      <path d='M394.751 350.341L394.227 348.44L394.941 348.475L395.503 350.305L394.751 350.341Z' fill='#5179E0' />
      <path d='M372.008 201.919L372.084 202.277L372.16 203.187L371.922 202.883L372.008 201.919Z' fill='#5179E0' />
      <path d='M158.371 375.38L158.056 374.157L158.923 374.237L159.714 375.335L158.371 375.38Z' fill='#5179E0' />
      <path d='M438.817 112.413L439.693 113.645L438.912 114.225L438.531 113.047L438.817 112.413Z' fill='#5179E0' />
      <path d='M202.78 225.271L205.39 223.906L206.142 225.021L204.132 226.217L202.78 225.271Z' fill='#5179E0' />
      <path d='M374.779 305.931L374.941 304.2L375.646 304.396L375.436 306.521L374.779 305.931Z' fill='#5179E0' />
      <path d='M484.607 203.714L484.779 204.187L484.684 204.785L484.655 204.214L484.607 203.714Z' fill='#5179E0' />
      <path d='M209.447 206.892L212.818 205.695L213.256 206.945L210.513 208.052L209.447 206.892Z' fill='#5179E0' />
      <path d='M391.189 301.736L391.589 300.754L391.874 301.727L391.646 302.486L391.189 301.736Z' fill='#5179E0' />
      <path d='M217.847 154.475L218.009 154.216L218.218 155.341L218.047 155.591L217.847 154.475Z' fill='#5179E0' />
      <path d='M218.685 187.512L221.828 187.003L222.189 188.226L218.685 189.047V187.512Z' fill='#5179E0' />
      <path d='M382.703 127.213L383.493 128.195L382.56 129.106L382.855 127.919L382.703 127.213Z' fill='#5179E0' />
      <path d='M185.732 259.165L187.19 257.371L188.742 257.719L187.628 259.353L185.732 259.165Z' fill='#5179E0' />
      <path d='M184.342 208.498L184.409 208.213L184.618 209.23L184.494 209.507L184.342 208.498Z' fill='#5179E0' />
      <path d='M378.389 202.741L378.96 203.062L378.865 203.964L377.903 203.794L378.389 202.741Z' fill='#5179E0' />
      <path d='M170.828 227.628L170.856 227.092L171.39 228.092L170.923 228.556L170.828 227.628Z' fill='#5179E0' />
      <path d='M399.455 202.062L400.017 202.598L399.636 203.508L399.017 202.964L399.455 202.062Z' fill='#5179E0' />
      <path d='M388.389 336.3L388.322 334.648L388.998 335.13L389.122 336.737L388.389 336.3Z' fill='#5179E0' />
      <path d='M141.942 365.525L141.295 364.373L142.637 364.24L143.142 365.409L141.942 365.525Z' fill='#5179E0' />
      <path d='M227.389 169.945L230.332 169.918L230.208 171.203L227.008 171.444L227.389 169.945Z' fill='#5179E0' />
      <path d='M195.99 280.982L196.828 279.875L197.532 280.723L197.266 281.482L195.99 280.982Z' fill='#5179E0' />
      <path d='M259.627 348.859L259.237 348.181H260.142L260.094 349.091L259.627 348.859Z' fill='#5179E0' />
      <path d='M300.761 293.158L300.732 292.979L301.351 293.613L300.865 294.238L300.761 293.158Z' fill='#5179E0' />
      <path d='M450.817 333.961L450.998 333.372L451.217 334.523L451.103 334.996L450.817 333.961Z' fill='#5179E0' />
      <path d='M305.846 360.214L306.122 359.303L307.227 360.053L306.732 361.829L305.846 360.214Z' fill='#5179E0' />
      <path d='M386.436 364.552L385.246 362.892L385.808 362.338L387.027 364.016L386.436 364.552Z' fill='#5179E0' />
      <path d='M203.447 59.4163L205.751 59.8805L206.075 61.5051L204.342 61.2998L203.447 59.4163Z' fill='#5179E0' />
      <path d='M440.903 321.553L441.036 321.124L441.303 322.124L441.093 322.669L440.903 321.553Z' fill='#5179E0' />
      <path d='M225.942 156.501L228.418 156.635L228.456 157.876L225.513 157.965L225.942 156.501Z' fill='#5179E0' />
      <path d='M199.018 162.232L199.209 162.071L199.218 163.116L199.313 163.357L199.018 162.232Z' fill='#5179E0' />
      <path d='M380.998 191.574L381.198 192.011L381.236 192.832L380.674 192.511L380.998 191.574Z' fill='#5179E0' />
      <path d='M398.427 247.472L398.636 247.436L398.512 248.284L398.674 248.061L398.427 247.472Z' fill='#5179E0' />
      <path d='M367.255 312.385L367.408 310.984L367.941 311.537L367.894 312.751L367.255 312.385Z' fill='#5179E0' />
      <path d='M204.685 205.231L205.056 204.821L205.494 205.686L205.104 206.106L204.685 205.231Z' fill='#5179E0' />
      <path d='M379.246 331.872L379.113 329.855L379.798 330.354L380.036 331.988L379.246 331.872Z' fill='#5179E0' />
      <path d='M234.989 63.3886L236.761 64.2098L236.123 65.5309L235.085 64.9061L234.989 63.3886Z' fill='#5179E0' />
      <path d='M417.312 203.732L417.284 204.597L417.227 205.392L417.065 204.535L417.312 203.732Z' fill='#5179E0' />
      <path d='M240.989 325.561L241.094 325.507L241.437 326.811L241.237 326.953L240.989 325.561Z' fill='#5179E0' />
      <path d='M191.875 271.636L192.971 269.984L194.666 270.038L193.523 271.903L191.875 271.636Z' fill='#5179E0' />
      <path d='M337.922 380.325L337.608 379.79L338.094 380.209L337.894 380.861L337.922 380.325Z' fill='#5179E0' />
      <path d='M221.98 144.245L224.723 144.379L224.532 145.664L222.047 145.638L221.98 144.245Z' fill='#5179E0' />
      <path d='M367.836 309.85L368.075 310.341L368.046 312.921L367.846 312.171L367.836 309.85Z' fill='#5179E0' />
      <path d='M424.189 274.001L424.398 274.117L424.331 275.331L424.369 274.885L424.189 274.001Z' fill='#5179E0' />
      <path d='M184.523 209.114L185.199 208.543L185.704 209.346L185.475 209.953L184.523 209.114Z' fill='#5179E0' />
      <path d='M206.075 236.233L207.409 235.573L207.428 236.903L207.066 237.045L206.075 236.233Z' fill='#5179E0' />
      <path d='M342.865 260.701L342.989 260.799L343.103 261.843L342.96 261.745L342.865 260.701Z' fill='#5179E0' />
      <path d='M202.542 273.742L203.951 272.037L205.113 272.662L204.151 274.046L202.542 273.742Z' fill='#5179E0' />
      <path d='M254.713 2.16148L255.056 2.91131V4.51809L254.446 3.75041L254.713 2.16148Z' fill='#5179E0' />
      <path d='M373.598 285.24L373.922 285.472L373.789 287.453L373.741 286.73L373.598 285.24Z' fill='#5179E0' />
      <path d='M193.866 268.601L194.656 267.592L195.751 268.217L194.951 269.306L193.866 268.601Z' fill='#5179E0' />
      <path d='M195.075 145.539L195.951 145.45L195.923 146.468L195.809 146.816L195.075 145.539Z' fill='#5179E0' />
      <path d='M348.998 312.885L349.246 313.287L349.446 315.402L348.865 315.554L348.998 312.885Z' fill='#5179E0' />
      <path d='M190.932 307.868L191.361 306.181L192.732 306.208L192.656 307.476L190.932 307.868Z' fill='#5179E0' />
      <path d='M205.77 37.7605L208.142 38.5282L208.494 40.2778L206.151 39.6618L205.77 37.7605Z' fill='#5179E0' />
      <path d='M208.323 136.336L211.513 136.256L211.713 137.532L208.98 137.738L208.323 136.336Z' fill='#5179E0' />
      <path d='M192.075 183.62L192.39 183.218L193.123 184.191L192.323 184.566L192.075 183.62Z' fill='#5179E0' />
      <path d='M195.228 110.03L198.209 110.101L198.942 111.467L195.856 111.565L195.228 110.03Z' fill='#5179E0' />
      <path d='M198.666 125.758L201.761 125.642L202.523 126.919L199.361 127.222L198.666 125.758Z' fill='#5179E0' />
      <path d='M180.513 272.305L181.485 270.779L182.723 271.198L182.218 272.386L180.513 272.305Z' fill='#5179E0' />
      <path d='M342.017 305.53L341.713 303.637L342.446 302.95L342.77 305.28L342.017 305.53Z' fill='#5179E0' />
      <path d='M366.484 270.761L367.189 269.618L367.265 270.752L367.017 271.27L366.484 270.761Z' fill='#5179E0' />
      <path d='M473.712 118.885L474.255 120.652L473.217 120.42L472.817 118.626L473.712 118.885Z' fill='#5179E0' />
      <path d='M386.065 142.772L386.541 144.04L385.37 144.361L385.293 143.004L386.065 142.772Z' fill='#5179E0' />
      <path d='M189.771 435.839L189.104 434.697L189.99 434.536L190.694 435.643L189.771 435.839Z' fill='#5179E0' />
      <path d='M268.742 80.59L269.675 81.7594L268.684 82.8127L268.132 81.5719L268.742 80.59Z' fill='#5179E0' />
      <path d='M403.131 193.725L403.227 194.448L403.093 195.153L402.57 194.51L403.131 193.725Z' fill='#5179E0' />
      <path d='M366.284 334.318L365.56 332.167L366.255 331.783L367.075 334.157L366.284 334.318Z' fill='#5179E0' />
      <path d='M362.789 287.391L363.065 287.703L362.998 289.792L362.76 289.301L362.789 287.391Z' fill='#5179E0' />
      <path d='M353.303 304.932L353.379 302.941L354.036 303.227L354.055 305.039L353.303 304.932Z' fill='#5179E0' />
      <path d='M365.198 105.566L364.979 106.334L365.246 107.182L364.598 106.495L365.198 105.566Z' fill='#5179E0' />
      <path d='M200.809 237.992L203.085 236.51L203.732 237.554L202.275 238.608L200.809 237.992Z' fill='#5179E0' />
      <path d='M347.379 297.246L347.456 294.97L348.189 294.791L348.17 297.246H347.379Z' fill='#5179E0' />
      <path d='M217.123 13.9981L219.094 15.0693L219.104 16.8278L216.732 15.7298L217.123 13.9981Z' fill='#5179E0' />
      <path d='M193.228 371.988L192.313 370.809L193.19 369.917L194.332 370.077L193.228 371.988Z' fill='#5179E0' />
      <path d='M252.675 81.7862L255.227 83.4643L253.666 84.2142L251.037 82.6342L252.675 81.7862Z' fill='#5179E0' />
      <path d='M426.941 131.382L427.293 132.65L426.636 132.989L426.836 131.686L426.941 131.382Z' fill='#5179E0' />
      <path d='M511.845 385.708L511.864 384.253L512.341 385.467L512.398 386.761L511.845 385.708Z' fill='#5179E0' />
      <path d='M231.408 210.328L234.961 209.48L234.504 210.873L231.828 211.524L231.408 210.328Z' fill='#5179E0' />
      <path d='M368.522 339.094L367.751 336.674L368.503 336.728L369.265 338.638L368.522 339.094Z' fill='#5179E0' />
      <path d='M409.093 131.721L409.417 133.06H408.284L407.731 131.569L409.093 131.721Z' fill='#5179E0' />
      <path d='M465.636 121.393L466.369 123.259L464.96 122.759L464.455 120.92L465.636 121.393Z' fill='#5179E0' />
      <path d='M350.265 324.927L349.913 323.026L350.589 323.178L350.998 324.615L350.265 324.927Z' fill='#5179E0' />
      <path d='M349.532 301.379L349.627 298.915L350.284 299.183L350.351 301.147L349.532 301.379Z' fill='#5179E0' />
      <path d='M536.197 389.412L536.188 388.216L536.636 389.448L536.588 390.85L536.197 389.412Z' fill='#5179E0' />
      <path d='M357.989 145.209L357.808 146.423L357.675 147.155L357.417 145.968L357.989 145.209Z' fill='#5179E0' />
      <path d='M211.285 238.474L211.856 238.715L211.78 240.58L211.647 239.795L211.285 238.474Z' fill='#5179E0' />
      <path d='M396.589 390.091L395.112 388.788L395.655 388.216L397.179 389.555L396.589 390.091Z' fill='#5179E0' />
      <path d='M581.369 386.993L581.435 386.065L581.464 387.859L581.674 388.359L581.369 386.993Z' fill='#5179E0' />
      <path d='M333.17 272.011L333.208 272.403L333.56 273.483L333.037 273.742L333.17 272.011Z' fill='#5179E0' />
      <path d='M224.228 150.449L224.475 150.601L224.694 151.592L224.209 151.476L224.228 150.449Z' fill='#5179E0' />
      <path d='M366.903 268.636L367.332 268.735L367.141 270.475L366.827 270.181L366.903 268.636Z' fill='#5179E0' />
      <path d='M352.465 288.855L352.789 289.114L352.703 291.39L352.456 290.908L352.465 288.855Z' fill='#5179E0' />
      <path d='M497.045 397.723L496.045 395.777L496.998 396.027L498.102 398.25L497.045 397.723Z' fill='#5179E0' />
      <path d='M316.418 299.54L316.77 299.442L316.894 301.379L316.741 301.058L316.418 299.54Z' fill='#5179E0' />
      <path d='M230.38 199.036L234.304 198.197L234.142 199.402L230.589 200.322L230.38 199.036Z' fill='#5179E0' />
      <path d='M342.494 283.071L342.837 281.624L343.379 282.026L343.103 283.454L342.494 283.071Z' fill='#5179E0' />
      <path d='M418.512 48.8115L418.646 48.633L418.884 49.9809L418.608 50.0166L418.512 48.8115Z' fill='#5179E0' />
      <path d='M433.636 48.1064L433.808 47.9992L433.95 49.2311L433.741 49.2757L433.636 48.1064Z' fill='#5179E0' />
      <path d='M233.561 223.468L234.027 223.843L233.894 225.637L233.827 224.754L233.561 223.468Z' fill='#5179E0' />
      <path d='M478.141 98.7377L478.341 99.9963L478.026 100.46L478.141 99.2286V98.7377Z' fill='#5179E0' />
      <path d='M394.922 132.346L395.332 133.596L394.208 133.739L393.77 132.4L394.922 132.346Z' fill='#5179E0' />
      <path d='M201.332 292.193L201.39 291.292L201.532 291.899L201.847 293.113L201.332 292.193Z' fill='#5179E0' />
      <path d='M438.722 397.723L437.579 395.902L438.541 396.063L439.655 397.705L438.722 397.723Z' fill='#5179E0' />
      <path d='M315.589 5.61605L315.399 4.57165L315.294 3.12555L315.665 4.08962L315.589 5.61605Z' fill='#5179E0' />
      <path d='M216.056 178.175L218.932 177.693L219.551 178.693L216.104 179.532L216.056 178.175Z' fill='#5179E0' />
      <path d='M414.017 370.524L413.389 368.658L414.303 369.203L414.941 370.872L414.017 370.524Z' fill='#5179E0' />
      <path d='M220.456 162.75L223.466 162.589V163.741L220.551 164.044L220.456 162.75Z' fill='#5179E0' />
      <path d='M414.017 199.902L413.922 200.821V201.518L413.912 200.554L414.017 199.902Z' fill='#5179E0' />
      <path d='M317.218 317.706L318.008 316.804L318.475 318.438L317.475 319.964L317.218 317.706Z' fill='#5179E0' />
      <path d='M508.302 394.706L507.322 392.474L508.474 393.072L509.569 395.554L508.302 394.706Z' fill='#5179E0' />
      <path d='M347.732 348.315L346.484 347.877L346.084 346.163L347.798 347.377L347.732 348.315Z' fill='#5179E0' />
      <path d='M213.999 194.243L217.399 193.207L218.256 194.064L214.523 195.483L213.999 194.243Z' fill='#5179E0' />
      <path d='M361.113 268.297L361.351 268.627L361.284 270.359L360.998 270.002L361.113 268.297Z' fill='#5179E0' />
      <path d='M188.704 138.032L191.047 137.738L191.98 138.862L189.571 139.291L188.704 138.032Z' fill='#5179E0' />
      <path d='M194.256 140.969L194.609 141.148L194.656 142.201L194.409 142.014L194.256 140.969Z' fill='#5179E0' />
      <path d='M220.009 388.243L218.999 387.895L219.837 386.967L220.609 387.547L220.009 388.243Z' fill='#5179E0' />
      <path d='M230.704 73.1988L233.408 74.4307L232.789 75.6001L229.875 74.4843L230.704 73.1988Z' fill='#5179E0' />
      <path d='M350.637 269.19L350.817 269.565L350.856 271.189L350.541 270.957L350.637 269.19Z' fill='#5179E0' />
      <path d='M226.456 148.467L229.418 148.637L229.228 149.771H226.199L226.456 148.467Z' fill='#5179E0' />
      <path d='M330.113 295.916L330.094 294.193L330.732 294.157L330.837 295.719L330.113 295.916Z' fill='#5179E0' />
      <path d='M404.284 357.634L403.617 355.492L404.551 356.179L405.217 357.982L404.284 357.634Z' fill='#5179E0' />
      <path d='M234.18 217.202L236.408 216.729L235.951 218.121L234.475 218.282L234.18 217.202Z' fill='#5179E0' />
      <path d='M224.123 345.869L223.685 345.092L224.723 344.655L225.018 345.663L224.123 345.869Z' fill='#5179E0' />
      <path d='M401.655 48.6241L402.027 48.6687L402.265 50.0166L401.922 49.972L401.655 48.6241Z' fill='#5179E0' />
      <path d='M294.951 2.71493L295.618 4.98227L293.522 4.7234L292.742 2.41143L294.951 2.71493Z' fill='#5179E0' />
      <path d='M193.409 259.433L193.523 258.603L193.466 259.121L193.799 260.361L193.409 259.433Z' fill='#5179E0' />
      <path d='M227.237 256.452L227.161 255.738L227.275 256.282L227.342 256.969L227.237 256.452Z' fill='#5179E0' />
      <path d='M483.426 123.125L483.807 124.991L482.598 124.241L482.274 122.286L483.426 123.125Z' fill='#5179E0' />
      <path d='M205.161 144.379L207.647 144.236L208.342 145.325L205.409 145.691L205.161 144.379Z' fill='#5179E0' />
      <path d='M332.722 290.337L333.227 290.221L333.341 292.006L332.513 292.676L332.722 290.337Z' fill='#5179E0' />
      <path d='M189.685 340.807L189.847 340.174L190.856 340.763L190.371 341.914L189.685 340.807Z' fill='#5179E0' />
      <path d='M305.199 310.618L305.837 309.832L306.322 311.35L305.665 312.296L305.199 310.618Z' fill='#5179E0' />
      <path d='M473.074 125.705L473.274 127.312L472.703 127.097L472.893 125.562L473.074 125.705Z' fill='#5179E0' />
      <path d='M204.275 114.234L207.523 114.573L207.323 115.796L204.894 115.636L204.275 114.234Z' fill='#5179E0' />
      <path d='M433.008 55.194L433.208 55.3369L433.236 56.3812L433.065 56.2295L433.008 55.194Z' fill='#5179E0' />
      <path d='M211.189 65.4863L214.561 66.5664L214.313 67.8965L211.447 67.1377L211.189 65.4863Z' fill='#5179E0' />
      <path d='M456.75 136.354L457.255 138.05L455.693 137.166L455.636 135.586L456.75 136.354Z' fill='#5179E0' />
      <path d='M480.198 105.558L480.722 107.236L479.941 107.2L479.703 105.602L480.198 105.558Z' fill='#5179E0' />
      <path d='M431.665 32.0207L431.684 31.8601L431.788 33.1901L431.655 33.2258L431.665 32.0207Z' fill='#5179E0' />
      <path d='M471.322 120.483L471.788 122.277L470.522 121.643L469.931 119.653L471.322 120.483Z' fill='#5179E0' />
      <path d='M192.009 181.317L192.256 181.719L192.323 182.861L192.151 182.388L192.009 181.317Z' fill='#5179E0' />
      <path d='M209.332 211.703L209.57 212.247L209.77 213.453L209.351 213.069L209.332 211.703Z' fill='#5179E0' />
      <path d='M311.77 283.901L312.199 283.687L312.38 285.329L312.027 285.383L311.77 283.901Z' fill='#5179E0' />
      <path d='M316.703 288.105L317.18 287.873L317.465 289.337L316.789 289.98L316.703 288.105Z' fill='#5179E0' />
      <path d='M258.056 202.491H260.523L259.608 203.687L257.313 203.705L258.056 202.491Z' fill='#5179E0' />
      <path d='M314.151 0.510071L314.275 2.96487L312.17 1.76871L312.046 -0.587887L314.151 0.510071Z' fill='#5179E0' />
      <path d='M199.513 53.9086L203.028 54.953L203.399 56.4973L199.961 55.6939L199.513 53.9086Z' fill='#5179E0' />
      <path d='M253.094 92.3463L255.875 94.0512L254.304 94.6493L251.475 93.0782L253.094 92.3463Z' fill='#5179E0' />
      <path
        d='M333.808 -0.641445L333.979 1.48307L331.751 0.724312L331.951 -1.15919L333.808 -0.641445Z'
        fill='#5179E0'
      />
      <path d='M181.123 156.421L181.018 155.832L181.304 157.055L181.171 157.412L181.123 156.421Z' fill='#5179E0' />
      <path d='M200.913 401.142L201.113 400.071L201.894 400.963L201.875 401.883L200.913 401.142Z' fill='#5179E0' />
      <path d='M416.036 393.269L414.95 391.724L415.912 391.751L417.074 393.367L416.036 393.269Z' fill='#5179E0' />
      <path d='M172.723 262.272L174.133 261.558L174.58 262.763L173.342 263.423L172.723 262.272Z' fill='#5179E0' />
      <path d='M471.388 107.254L471.855 108.878L471.045 108.789L470.493 107.048L471.388 107.254Z' fill='#5179E0' />
      <path d='M418.141 54.9798L418.408 55.2119L418.522 56.3098L418.16 56.0242L418.141 54.9798Z' fill='#5179E0' />
      <path d='M175.056 153.439L176.809 152.949L178.075 153.895L176.104 154.502L175.056 153.439Z' fill='#5179E0' />
      <path d='M419.808 386.77L417.95 384.753L418.941 384.717L420.684 386.502L419.808 386.77Z' fill='#5179E0' />
      <path d='M176.094 218.826L176.656 218.871L177.18 219.755L176.152 220.067L176.094 218.826Z' fill='#5179E0' />
      <path d='M400.884 72.1366L401.208 72.4579L401.293 73.4131L400.855 73.0471L400.884 72.1366Z' fill='#5179E0' />
      <path d='M206.456 225.7L206.866 226.271L207.17 227.423L206.304 227.351L206.456 225.7Z' fill='#5179E0' />
      <path d='M488.103 99.8892L488.388 101.442L487.931 101.585L487.798 100.05L488.103 99.8892Z' fill='#5179E0' />
      <path d='M334.903 370.765L334.789 370.872L335.141 372.282L335.027 372.497L334.903 370.765Z' fill='#5179E0' />
      <path d='M332.57 348.056L332.598 348.011L333.018 349.323L332.846 349.627L332.57 348.056Z' fill='#5179E0' />
      <path d='M226.189 227.128L226.599 227.797L226.637 229.386L226.085 228.851L226.189 227.128Z' fill='#5179E0' />
      <path d='M305.951 458.12L304.751 457.932L305.037 456.308L306.799 457.209L305.951 458.12Z' fill='#5179E0' />
      <path d='M197.828 35.5735L201.18 36.8411L201.551 38.5193L198.047 37.4213L197.828 35.5735Z' fill='#5179E0' />
      <path d='M374.198 251.051L374.113 251.846L374.189 253.131L373.798 252.908L374.198 251.051Z' fill='#5179E0' />
      <path d='M479.083 97.3719L479.455 98.9162L478.931 99.0679L478.398 97.3897L479.083 97.3719Z' fill='#5179E0' />
      <path d='M461.912 107.575L462.722 109.378L461.541 109.084L460.95 107.361L461.912 107.575Z' fill='#5179E0' />
      <path d='M251.685 192.154L254.513 192.002L253.808 193.127L251.323 193.296L251.685 192.154Z' fill='#5179E0' />
      <path d='M230.675 211.917L230.637 212.953L230.894 214.256L230.494 213.72L230.675 211.917Z' fill='#5179E0' />
      <path d='M379.284 390.376L377.741 389.323L378.208 388.689L379.779 389.725L379.284 390.376Z' fill='#5179E0' />
      <path d='M145.885 186.825L146.161 186.236L146.495 187.36L146.19 187.923L145.885 186.825Z' fill='#5179E0' />
      <path d='M149.847 181.754L151.123 181.433L151.266 182.388L150.675 182.71L149.847 181.754Z' fill='#5179E0' />
      <path d='M454.569 123.303L454.931 124.812L453.846 124.419L453.312 122.723L454.569 123.303Z' fill='#5179E0' />
      <path d='M406.846 54.9619L407.208 55.2565L407.189 56.2831L406.97 56.0688L406.846 54.9619Z' fill='#5179E0' />
      <path d='M208.561 241.5L209.456 241.821L209.59 243.053L208.142 243.285L208.561 241.5Z' fill='#5179E0' />
      <path d='M216.38 167.838L218.589 167.642L218.942 168.615L216.447 169.016L216.38 167.838Z' fill='#5179E0' />
      <path d='M183.837 264.673L183.818 263.709L183.847 264.182L184.047 265.262L183.837 264.673Z' fill='#5179E0' />
      <path d='M225.685 152.422L227.818 152.565L227.732 153.591L225.504 153.582L225.685 152.422Z' fill='#5179E0' />
      <path d='M133.561 178.764L133.99 178.282L134.276 179.442L134.009 180.067L133.561 178.764Z' fill='#5179E0' />
      <path d='M251.218 215.05L252.046 215.399L251.58 217.032L251.132 216.291L251.218 215.05Z' fill='#5179E0' />
      <path d='M191.171 266.842L190.99 265.86L191.094 266.369L191.256 267.333L191.171 266.842Z' fill='#5179E0' />
      <path d='M190.961 157.671L193.037 157.269L193.828 158.215L191.809 158.733L190.961 157.671Z' fill='#5179E0' />
      <path d='M470.798 99.0412L471.569 100.835L470.579 100.693L470.122 99.059L470.798 99.0412Z' fill='#5179E0' />
      <path d='M298.399 7.27639L299.199 8.99028L297.627 9.58837L297.656 7.86554L298.399 7.27639Z' fill='#5179E0' />
      <path d='M308.094 405.15L307.999 404.837L308.341 406.078L308.303 406.533L308.094 405.15Z' fill='#5179E0' />
      <path d='M327.341 329.747L326.332 328.569L326.541 327.382L327.665 328.828L327.341 329.747Z' fill='#5179E0' />
      <path d='M156.104 254.131L156.39 253.327L156.504 253.836L156.542 254.863L156.104 254.131Z' fill='#5179E0' />
      <path d='M426.865 403.775L424.798 402.213L425.788 402.008L427.579 403.249L426.865 403.775Z' fill='#5179E0' />
      <path d='M131.58 313.162L132.057 311.752L133.428 311.912L133.037 313.332L131.58 313.162Z' fill='#5179E0' />
      <path d='M229.475 112.842L231.532 113.565L230.875 114.564L228.999 113.948L229.475 112.842Z' fill='#5179E0' />
      <path d='M326.008 370.702L326.218 369.997L326.865 371.657L326.76 372.05L326.008 370.702Z' fill='#5179E0' />
      <path d='M260.142 11.1684L262.094 13.9534L260.189 14.0606L257.98 11.2665L260.142 11.1684Z' fill='#5179E0' />
      <path d='M211.075 252.417L210.951 251.819L211.123 252.31L211.085 252.845L211.075 252.417Z' fill='#5179E0' />
      <path d='M254.256 398.696L253.285 399.276L253.027 397.571L253.77 396.839L254.256 398.696Z' fill='#5179E0' />
      <path d='M406.008 80.1526L406.436 80.7328L406.408 81.5452L405.836 80.9203L406.008 80.1526Z' fill='#5179E0' />
      <path d='M251.056 188.672L251.589 189.137L251.646 190.038L250.685 189.878L251.056 188.672Z' fill='#5179E0' />
      <path d='M279.808 103.201L281.456 104.799L279.627 104.951L278.104 103.451L279.808 103.201Z' fill='#5179E0' />
      <path d='M173.952 309.707L173.752 308.288L174.037 308.761L174.047 310.029L173.952 309.707Z' fill='#5179E0' />
      <path d='M199.894 193.484L202.199 192.52L203.437 193.1L200.78 194.403L199.894 193.484Z' fill='#5179E0' />
      <path d='M168.837 163.812L168.952 163.41L169.352 164.437L168.761 164.58L168.837 163.812Z' fill='#5179E0' />
      <path d='M465.303 89.0702L465.931 90.6324L465.322 90.8555L464.484 89.1416L465.303 89.0702Z' fill='#5179E0' />
      <path d='M468.074 104.12L468.884 106.129L467.636 105.62L467.007 103.701L468.074 104.12Z' fill='#5179E0' />
      <path d='M183.294 229.029L184.361 228.922L184.647 229.984L183.456 230.27L183.294 229.029Z' fill='#5179E0' />
      <path d='M202.894 323.115L202.885 322.053L202.942 322.294L203.132 323.481L202.894 323.115Z' fill='#5179E0' />
      <path d='M232.151 86.7672L235.58 88.1419L234.685 89.0881L231.494 87.9812L232.151 86.7672Z' fill='#5179E0' />
      <path d='M442.522 87.3206L443.627 88.9631L442.95 89.2755L441.769 87.6063L442.522 87.3206Z' fill='#5179E0' />
      <path d='M322.665 365.864L322.799 365.516L323.446 367.15L323.275 367.614L322.665 365.864Z' fill='#5179E0' />
      <path d='M430.56 78.0102L431.56 79.3046L431.274 79.9741L430.017 78.5637L430.56 78.0102Z' fill='#5179E0' />
      <path d='M483.607 24.817H483.636L483.617 25.9864L483.588 25.9418L483.607 24.817Z' fill='#5179E0' />
      <path d='M170.828 254.711L170.961 253.863L171.018 254.327L171.056 255.273L170.828 254.711Z' fill='#5179E0' />
      <path d='M184.771 284.329L184.666 283.285L184.837 283.722L184.856 284.722L184.771 284.329Z' fill='#5179E0' />
      <path d='M401.284 155.859L401.236 157.028L401.122 157.064L401.332 155.778L401.284 155.859Z' fill='#5179E0' />
      <path d='M212.456 199.143L215.228 198.036L215.97 198.786L213.428 199.973L212.456 199.143Z' fill='#5179E0' />
      <path d='M200.361 267.654L200.39 266.967L200.561 267.396L200.466 268.074L200.361 267.654Z' fill='#5179E0' />
      <path d='M283.484 412.586L283.503 411.988L283.894 413.112V413.719L283.484 412.586Z' fill='#5179E0' />
      <path d='M188.552 264.843L188.418 263.995L188.58 264.477L188.59 265.271L188.552 264.843Z' fill='#5179E0' />
      <path d='M186.237 273.144L186.199 272.252L186.294 272.68L186.361 273.573L186.237 273.144Z' fill='#5179E0' />
      <path d='M123.818 195.608L124.209 194.992L124.59 196.081L124.238 196.724L123.818 195.608Z' fill='#5179E0' />
      <path d='M189.275 388.306L189.437 387.065L190.456 387.815L190.447 388.841L189.275 388.306Z' fill='#5179E0' />
      <path d='M265.913 203.857L266.827 204.303L266.227 205.544L265.389 205.026L265.913 203.857Z' fill='#5179E0' />
      <path d='M181.38 242.348L182.59 242.098L183.009 243.08L181.494 243.705L181.38 242.348Z' fill='#5179E0' />
      <path d='M136.123 196.662L136.533 196.117L136.79 197.01L136.476 197.635L136.123 196.662Z' fill='#5179E0' />
      <path d='M398.741 43.5449L399.56 44.2322L399.217 45.1427L399.17 44.9642L398.741 43.5449Z' fill='#5179E0' />
      <path d='M201.58 127.615L202.37 128.088L202.113 129.186L201.723 128.579L201.58 127.615Z' fill='#5179E0' />
      <path d='M196.447 274.278L196.504 273.555L196.685 273.957L196.59 274.68L196.447 274.278Z' fill='#5179E0' />
      <path d='M296.008 401.276L296.084 400.66L296.761 401.856L296.675 402.552L296.008 401.276Z' fill='#5179E0' />
      <path d='M338.913 -4.75658L337.903 -4.36381L337.818 -6.62222L338.703 -6.82754L338.913 -4.75658Z' fill='#5179E0' />
      <path d='M230.161 383.985L229.342 383.2L229.951 382.592L230.789 382.798L230.161 383.985Z' fill='#5179E0' />
      <path d='M292.789 368.685L292.713 366.685L293.894 365.927L294.208 369.033L292.789 368.685Z' fill='#5179E0' />
      <path d='M159.495 305.753L159.456 304.298L159.475 304.584L159.771 306.262L159.495 305.753Z' fill='#5179E0' />
      <path d='M142.628 201.277L142.961 200.688L143.285 201.545L142.876 202.08L142.628 201.277Z' fill='#5179E0' />
      <path d='M358.16 416.612L358.046 415.808L358.979 416.487L359.046 417.576L358.16 416.612Z' fill='#5179E0' />
      <path d='M284.094 8.47255L285.218 11.5254L283.151 10.6953L281.799 7.5799L284.094 8.47255Z' fill='#5179E0' />
      <path d='M480.083 99.175L480.312 100.684L479.826 100.737L479.484 99.1126L480.083 99.175Z' fill='#5179E0' />
      <path d='M143.79 188.271L144.114 187.744L144.552 188.681L143.952 189.056L143.79 188.271Z' fill='#5179E0' />
      <path d='M168.923 312.198L168.885 310.904L169.075 311.207L169.171 312.537L168.923 312.198Z' fill='#5179E0' />
      <path d='M431.398 71.1904L431.922 72.1366L431.884 72.9578L430.817 71.7349L431.398 71.1904Z' fill='#5179E0' />
      <path d='M398.512 376.29L398.084 377.326L397.189 376.978L398.084 376.076L398.512 376.29Z' fill='#5179E0' />
      <path d='M492.026 105.816L492.102 107.396L491.731 107.343L491.541 105.62L492.026 105.816Z' fill='#5179E0' />
      <path d='M128.38 213.488L128.628 212.685L129.018 213.658L128.647 214.345L128.38 213.488Z' fill='#5179E0' />
      <path d='M225.561 232.841L225.599 232.6L225.732 233.073L225.609 233.323L225.561 232.841Z' fill='#5179E0' />
      <path d='M205.094 252.729L205.142 252.256L205.275 252.703L205.19 253.185L205.094 252.729Z' fill='#5179E0' />
      <path d='M130.704 196.581L130.961 195.867L131.247 196.876L130.885 197.492L130.704 196.581Z' fill='#5179E0' />
      <path d='M450.76 119.563L451.046 120.911L450.188 120.697L449.769 119.197L450.76 119.563Z' fill='#5179E0' />
      <path d='M476.426 9.82045L476.693 9.9722L476.541 11.2041L476.588 11.2755L476.426 9.82045Z' fill='#5179E0' />
      <path d='M221.399 239.679L221.504 239.393L221.628 239.857L221.504 240.152L221.399 239.679Z' fill='#5179E0' />
      <path d='M192.361 76.5909L195.542 77.2247L196.504 78.6797L192.894 78.1441L192.361 76.5909Z' fill='#5179E0' />
      <path d='M309.97 140.273L309.865 141.415L308.046 140.558L307.76 139.014L309.97 140.273Z' fill='#5179E0' />
      <path d='M152.837 278.893L152.79 277.599L152.942 278.063L152.98 279.339L152.837 278.893Z' fill='#5179E0' />
      <path d='M384.503 7.20497L384.608 6.87469L384.998 9.24022L384.627 9.15096L384.503 7.20497Z' fill='#5179E0' />
      <path d='M120.752 227.967L121.695 227.19L122.657 227.869L121.599 228.753L120.752 227.967Z' fill='#5179E0' />
      <path d='M123.352 188.378L123.733 187.78L123.914 188.789L123.647 189.476L123.352 188.378Z' fill='#5179E0' />
      <path d='M310.913 9.15096L310.599 8.57073L310.494 6.7765L310.999 7.16035L310.913 9.15096Z' fill='#5179E0' />
      <path d='M321.646 -15.3881L321.741 -13.6831L320.408 -13.3975L319.627 -15.4684L321.646 -15.3881Z' fill='#5179E0' />
      <path d='M216.409 80.9203L219.885 82.0182L219.799 83.1966L216.151 82.2771L216.409 80.9203Z' fill='#5179E0' />
      <path d='M378.017 5.08939L378.303 5.03583L378.236 6.89255L378.379 7.46385L378.017 5.08939Z' fill='#5179E0' />
      <path d='M164.599 316.947L165.275 315.652L166.285 316.25L165.78 317.42L164.599 316.947Z' fill='#5179E0' />
      <path d='M180.456 303.548L180.494 302.45L180.59 302.718L180.685 303.914L180.456 303.548Z' fill='#5179E0' />
      <path d='M245.523 116.091L246.466 116.859L245.885 117.733L244.932 116.984L245.523 116.091Z' fill='#5179E0' />
      <path d='M401.77 6.02668L401.827 5.7321L402.065 7.72271L401.817 7.74949L401.77 6.02668Z' fill='#5179E0' />
      <path d='M318.322 326.891L318.484 326.48L319.189 327.846L318.989 328.632L318.322 326.891Z' fill='#5179E0' />
      <path d='M414.084 63.7992L414.274 64.3169L414.265 65.1739L413.95 64.6115L414.084 63.7992Z' fill='#5179E0' />
      <path d='M150.59 295.978L150.523 294.532L150.695 294.934L150.752 296.38L150.59 295.978Z' fill='#5179E0' />
      <path d='M192.18 320.839L191.942 319.669L192.018 319.991L192.237 321.178L192.18 320.839Z' fill='#5179E0' />
      <path d='M420.293 29.1107L421.093 29.9855L420.874 31.0477L420.484 30.5122L420.293 29.1107Z' fill='#5179E0' />
      <path d='M461.665 426.449L459.588 426.886L459.35 426.091L461.884 425.726L461.665 426.449Z' fill='#5179E0' />
      <path d='M309.265 353.697L309.389 352.805L310.265 354.179L310.189 354.893L309.265 353.697Z' fill='#5179E0' />
      <path d='M172.704 259.853L174.19 259.103L174.894 259.906L173.123 261.093L172.704 259.853Z' fill='#5179E0' />
      <path d='M311.884 -1.90009L311.799 -2.61422L311.522 -4.56911L311.97 -4.38166L311.884 -1.90009Z' fill='#5179E0' />
      <path d='M170.647 295.05L170.494 293.845L170.485 294.157L170.818 295.478L170.647 295.05Z' fill='#5179E0' />
      <path d='M431.807 138.72L432.084 139.996L431.131 139.657L431.189 138.416L431.807 138.72Z' fill='#5179E0' />
      <path d='M195.066 55.9885L198.418 57.1132L198.685 58.5504L195.523 57.6845L195.066 55.9885Z' fill='#5179E0' />
      <path d='M341.76 144.12L341.484 145.111L339.637 143.915L340.056 142.897L341.76 144.12Z' fill='#5179E0' />
      <path d='M297.399 417.718L297.446 417.272L297.98 418.897L297.913 419.379L297.399 417.718Z' fill='#5179E0' />
      <path d='M327.179 0.858204L326.36 1.04567L326.027 -0.882465L327.065 -1.40913L327.179 0.858204Z' fill='#5179E0' />
      <path d='M149.533 267.842L149.628 266.744L149.723 267.172L149.733 268.333L149.533 267.842Z' fill='#5179E0' />
      <path d='M265.58 -16.6735L266.904 -14.8346L265.856 -13.7813L264.161 -15.7719L265.58 -16.6735Z' fill='#5179E0' />
      <path d='M332.379 128.65L332.522 129.927L330.627 129.043L330.741 127.874L332.379 128.65Z' fill='#5179E0' />
      <path d='M161.19 241.187L161.485 240.518L161.571 240.982L161.428 241.723L161.19 241.187Z' fill='#5179E0' />
      <path d='M115.419 217.818L115.78 217.005L116.038 217.898L115.79 218.8L115.419 217.818Z' fill='#5179E0' />
      <path d='M205.542 173.417L206.247 173.935L205.98 175.336L205.723 174.39L205.542 173.417Z' fill='#5179E0' />
      <path d='M190.028 306.253L189.952 305.28L189.999 305.601L190.18 306.628L190.028 306.253Z' fill='#5179E0' />
      <path d='M124.561 220.281L124.847 219.442L125.133 220.272L124.818 221.085L124.561 220.281Z' fill='#5179E0' />
      <path d='M383.513 462.61L383.274 462.021L384.046 463.038L383.874 464.172L383.513 462.61Z' fill='#5179E0' />
      <path d='M189.609 372.488L189.809 371.211L190.913 371.907L190.828 373.05L189.609 372.488Z' fill='#5179E0' />
      <path d='M193.19 118.778L193.647 118.822L193.904 120.474L193.609 120.643L193.19 118.778Z' fill='#5179E0' />
      <path d='M279.846 487.569L279.513 486.372L280.237 486.721L280.38 488.015L279.846 487.569Z' fill='#5179E0' />
      <path d='M153.095 278.063V276.893L153.256 277.313L153.218 278.473L153.095 278.063Z' fill='#5179E0' />
      <path d='M187.009 297.692L186.999 296.764L187.094 297.085L187.161 298.058L187.009 297.692Z' fill='#5179E0' />
      <path d='M317.827 130.338L318.227 131.561L316.246 131.355L316.037 130.088L317.827 130.338Z' fill='#5179E0' />
      <path d='M463.655 88.8203L463.788 90.0432L463.541 90.4003L462.855 88.856L463.655 88.8203Z' fill='#5179E0' />
      <path d='M436.303 122.295L436.598 123.518L435.779 123.393L435.493 122.116L436.303 122.295Z' fill='#5179E0' />
      <path d='M111.133 179.773L111.799 179.389L112.057 180.362L111.609 180.915L111.133 179.773Z' fill='#5179E0' />
      <path d='M374.436 -0.453983L373.189 0.295841L372.17 -1.2306L373.455 -2.41783L374.436 -0.453983Z' fill='#5179E0' />
      <path d='M257.98 94.9171L260.237 96.3543L258.942 96.8899L256.875 95.658L257.98 94.9171Z' fill='#5179E0' />
      <path d='M203.77 255.684L203.961 255.238L204.218 255.666L203.951 256.139L203.77 255.684Z' fill='#5179E0' />
      <path d='M128.333 216.711L128.695 216.006L129.057 216.782L128.638 217.443L128.333 216.711Z' fill='#5179E0' />
      <path d='M310.303 397L309.77 395.224L311.16 395.92L311.608 397.08L310.303 397Z' fill='#5179E0' />
      <path d='M337.132 147.414L336.703 148.262L335.103 147.271L335.341 146.146L337.132 147.414Z' fill='#5179E0' />
      <path d='M311.189 111.342L311.97 112.726L310.16 112.493L309.76 111.262L311.189 111.342Z' fill='#5179E0' />
      <path d='M127.723 224.379L128.038 223.575L128.323 224.298L127.971 225.075L127.723 224.379Z' fill='#5179E0' />
      <path d='M255.542 30.3426L257.58 32.8866L255.923 33.0741L253.608 30.5211L255.542 30.3426Z' fill='#5179E0' />
      <path d='M217.132 240.777L217.266 240.571L217.475 241.053L217.228 241.312L217.132 240.777Z' fill='#5179E0' />
      <path d='M187.228 273.832L187.313 273.171L187.637 273.555L187.352 274.242L187.228 273.832Z' fill='#5179E0' />
      <path d='M190.885 185.459L192.266 185.736L191.952 186.834L190.685 186.521L190.885 185.459Z' fill='#5179E0' />
      <path d='M169.009 161.322L170.837 161.286L170.761 162.312L169.371 162.339L169.009 161.322Z' fill='#5179E0' />
      <path d='M157.856 331.792L157.504 330.087L157.628 330.39L157.904 332.068L157.856 331.792Z' fill='#5179E0' />
      <path d='M353.436 422.396L353.027 421.423L354.303 421.637L354.913 423.173L353.436 422.396Z' fill='#5179E0' />
      <path d='M414.446 -14.5847L414.408 -14.9953L414.484 -13.0047L414.436 -12.7815L414.446 -14.5847Z' fill='#5179E0' />
      <path d='M223.904 113.511L224.304 113.618L224.189 114.538L224.209 114.868L223.904 113.511Z' fill='#5179E0' />
      <path d='M207.761 326.489L207.59 325.543L207.79 325.855L207.809 326.811L207.761 326.489Z' fill='#5179E0' />
      <path d='M141.628 202L142.056 201.509L142.409 202.232L141.828 202.678L141.628 202Z' fill='#5179E0' />
      <path d='M292.484 416.103L292.57 415.442L293.513 416.897L293.399 417.772L292.484 416.103Z' fill='#5179E0' />
      <path d='M163.018 332.095L163.047 330.988L163.18 331.033L163.571 332.354L163.018 332.095Z' fill='#5179E0' />
      <path d='M183.513 141.058L184.409 141.255L185.047 142.629L184.466 142.692L183.513 141.058Z' fill='#5179E0' />
      <path d='M271.523 425.288L271.513 424.681L272.265 425.859L272.142 426.743L271.523 425.288Z' fill='#5179E0' />
      <path d='M134.495 348.859L134.247 347.395L135.676 346.744L136.009 348.091L134.495 348.859Z' fill='#5179E0' />
      <path d='M437.331 67.5662L437.617 68.4499L437.493 69.1551L436.95 68.1553L437.331 67.5662Z' fill='#5179E0' />
      <path d='M298.694 410.104L298.142 408.622L299.456 409.069L299.922 410.006L298.694 410.104Z' fill='#5179E0' />
      <path d='M404.36 478.115L404.189 477.339L404.97 478.508L404.884 479.686L404.36 478.115Z' fill='#5179E0' />
      <path d='M379.179 -20.6547L379.474 -20.7172L379.703 -18.1732H379.408L379.179 -20.6547Z' fill='#5179E0' />
      <path d='M138.028 320.321L137.504 319.339L138.504 319.535L139.237 320.473L138.028 320.321Z' fill='#5179E0' />
      <path d='M153.152 341.12L152.828 339.317L152.933 339.558L153.237 341.379L153.152 341.12Z' fill='#5179E0' />
      <path d='M314.522 364.507L314.399 362.615L315.694 364.15L315.837 365.48L314.522 364.507Z' fill='#5179E0' />
      <path d='M327.608 156.314L327.465 157.394L324.865 156.546L325.284 155.403L327.608 156.314Z' fill='#5179E0' />
      <path d='M152.104 358.214L151.714 356.152L151.809 356.375L152.199 358.455L152.104 358.214Z' fill='#5179E0' />
      <path d='M468.703 436.536L466.245 437L466.626 436.259L468.493 435.732L468.703 436.536Z' fill='#5179E0' />
      <path d='M129.009 221.442L129.418 220.772L129.78 221.442L129.333 222.093L129.009 221.442Z' fill='#5179E0' />
      <path d='M254.646 126.696L255.78 127.919L254.38 128.249L252.999 126.99L254.646 126.696Z' fill='#5179E0' />
      <path d='M146.076 251.06L146.323 250.266L146.428 250.703L146.285 251.542L146.076 251.06Z' fill='#5179E0' />
      <path d='M152.295 232.198L152.723 231.627L152.837 232.1L152.561 232.734L152.295 232.198Z' fill='#5179E0' />
      <path d='M433.15 100.568L433.588 101.657L433.036 101.915L432.75 100.862L433.15 100.568Z' fill='#5179E0' />
      <path d='M443.331 71.5831L443.379 72.3776L443.274 73.0382L443.265 72.2348L443.331 71.5831Z' fill='#5179E0' />
      <path d='M101.085 207.15L101.628 206.517L101.857 207.347L101.504 208.15L101.085 207.15Z' fill='#5179E0' />
      <path d='M182.78 101.951L185.618 102.192L186.875 103.451L183.923 103.344L182.78 101.951Z' fill='#5179E0' />
      <path d='M195.723 364.034L196.094 362.776L197.104 364.097L196.866 364.998L195.723 364.034Z' fill='#5179E0' />
      <path d='M340.132 28.4858L339.551 28.0752L339.398 26.7362L340.322 26.8166L340.132 28.4858Z' fill='#5179E0' />
      <path d='M200.437 108.057L200.79 108.066V109.333L200.675 109.619L200.437 108.057Z' fill='#5179E0' />
      <path d='M297.799 -24.3235L298.389 -22.3865L296.884 -22.0116L295.999 -24.145L297.799 -24.3235Z' fill='#5179E0' />
      <path d='M172.437 123.643L173.247 123.794L174.123 125.553L173.199 125.339L172.437 123.643Z' fill='#5179E0' />
      <path d='M115.057 168.115L115.599 167.784L115.723 168.677L115.257 169.079L115.057 168.115Z' fill='#5179E0' />
      <path d='M180.685 389.805L180.79 388.698L181.971 389.35L181.894 390.698L180.685 389.805Z' fill='#5179E0' />
      <path d='M278.275 26.7005L279.246 28.8786L277.703 28.825L276.113 26.0132L278.275 26.7005Z' fill='#5179E0' />
      <path d='M410.189 111.717L409.884 112.565L410.065 113.011L409.598 112.056L410.189 111.717Z' fill='#5179E0' />
      <path d='M181.39 349.921L181.018 348.422L181.218 348.69L181.361 350.118L181.39 349.921Z' fill='#5179E0' />
      <path d='M144.466 346.297L144.342 344.94L145.733 344.396L145.885 345.869L144.466 346.297Z' fill='#5179E0' />
      <path d='M414.665 32.9759L415.322 33.9399L415.189 35.029L414.408 34.0024L414.665 32.9759Z' fill='#5179E0' />
      <path d='M373.179 416.719L372.846 415.21L374.655 416.781L375.122 418.531L373.179 416.719Z' fill='#5179E0' />
      <path d='M174.066 150.315L176.009 150.431L175.942 151.422L174.104 151.395L174.066 150.315Z' fill='#5179E0' />
      <path d='M195.713 260.531L195.951 260.058L196.275 260.46L195.961 260.995L195.713 260.531Z' fill='#5179E0' />
      <path d='M138.495 215.024L138.961 214.452L139.123 215.024L138.733 215.64L138.495 215.024Z' fill='#5179E0' />
      <path d='M196.237 227.601L198.332 227.039L198.351 228.047L196.285 228.699L196.237 227.601Z' fill='#5179E0' />
      <path d='M453.16 126.053L453.246 127.285L452.512 126.954L452.265 125.553L453.16 126.053Z' fill='#5179E0' />
      <path d='M211.513 468.6L211.209 467.109L211.58 467.252L211.97 468.796L211.513 468.6Z' fill='#5179E0' />
      <path d='M146.085 371.89L145.599 369.578L145.79 369.801L146.152 372.032L146.085 371.89Z' fill='#5179E0' />
      <path d='M323.941 148.798L324.179 149.958L321.741 149.53L322.094 148.351L323.941 148.798Z' fill='#5179E0' />
      <path d='M125.495 212.364L125.961 211.712L126.018 212.283L125.742 213.087L125.495 212.364Z' fill='#5179E0' />
      <path d='M232.123 180.451L233.304 181.183L232.37 182.129L231.132 181.397L232.123 180.451Z' fill='#5179E0' />
      <path d='M206.951 133.194L208.77 133.881L208.066 134.738L206.218 134.122L206.951 133.194Z' fill='#5179E0' />
      <path d='M140.761 231.181L141.228 230.556L141.409 231.038L141.047 231.716L140.761 231.181Z' fill='#5179E0' />
      <path d='M416.427 1.04567L416.979 1.63482L417.169 3.46477L416.427 2.69708V1.04567Z' fill='#5179E0' />
      <path d='M362.455 -19.8246L362.817 -19.7442L363.151 -17.0038L362.675 -17.2716L362.455 -19.8246Z' fill='#5179E0' />
      <path d='M449.217 86.4369L449.179 87.4367L449.122 87.9187L448.912 86.8118L449.217 86.4369Z' fill='#5179E0' />
      <path d='M234.266 420.718L234.161 419.772L235.085 420.271L235.104 421.432L234.266 420.718Z' fill='#5179E0' />
      <path d='M362.503 413.925L362.436 412.952L363.894 414.451L364.036 415.808L362.503 413.925Z' fill='#5179E0' />
      <path d='M123.58 300.37L123.438 299.397L124.257 299.629L124.714 300.593L123.58 300.37Z' fill='#5179E0' />
      <path d='M220.704 95.8187L223.561 96.6846L223.351 97.6843L220.628 97.0238L220.704 95.8187Z' fill='#5179E0' />
      <path d='M209.075 165.24L210.99 165.58L210.142 166.579L208.837 166.097L209.075 165.24Z' fill='#5179E0' />
      <path d='M106.485 219.076L106.99 218.326L107.133 218.978L106.828 219.898L106.485 219.076Z' fill='#5179E0' />
      <path d='M389.417 364.338L388.617 365.007L387.703 364.24L388.77 363.731L389.417 364.338Z' fill='#5179E0' />
      <path d='M473.769 113.966L473.665 115.323L473.436 115.225L473.284 113.627L473.769 113.966Z' fill='#5179E0' />
      <path d='M233.77 4.97334L236.304 7.12464L235.685 8.3922L232.904 6.30339L233.77 4.97334Z' fill='#5179E0' />
      <path d='M135.171 210.98L135.523 210.355L135.676 210.944L135.285 211.551L135.171 210.98Z' fill='#5179E0' />
      <path d='M195.075 74.4128L197.866 75.2698L198.218 76.5731L195.39 75.8768L195.075 74.4128Z' fill='#5179E0' />
      <path d='M388.932 441.276L388.541 439.49L390.722 441.597L391.141 443.329L388.932 441.276Z' fill='#5179E0' />
      <path d='M441.446 20.1663L442.36 21.6927L441.912 22.6122L441.207 21.3446L441.446 20.1663Z' fill='#5179E0' />
      <path d='M478.255 432.313L476.026 433.277L476.017 432.581L478.084 431.599L478.255 432.313Z' fill='#5179E0' />
      <path d='M151.104 360.08L150.914 358.741L152.171 358.384L152.447 359.696L151.104 360.08Z' fill='#5179E0' />
      <path d='M206.466 15.2567L208.037 16.2743L208.323 18.14L206.504 17.0688L206.466 15.2567Z' fill='#5179E0' />
      <path d='M181.428 244.044L183.38 243.098L183.932 243.83L181.828 245.088L181.428 244.044Z' fill='#5179E0' />
      <path d='M143.361 239.643L143.733 239.036L143.847 239.482L143.58 240.134L143.361 239.643Z' fill='#5179E0' />
      <path d='M312.96 381.004L312.275 378.772L313.646 379.379L314.246 380.977L312.96 381.004Z' fill='#5179E0' />
      <path d='M326.989 422.191L325.989 421.271L325.979 418.915L327.713 420.691L326.989 422.191Z' fill='#5179E0' />
      <path d='M119.818 283.749L119.999 282.41L120.076 282.687L120.095 284.169L119.818 283.749Z' fill='#5179E0' />
      <path d='M116.552 262.763L117.161 261.576L118.742 261.522L117.857 263.013L116.552 262.763Z' fill='#5179E0' />
      <path d='M162.066 351.617L162.123 350.323L163.361 350.207L163.352 351.662L162.066 351.617Z' fill='#5179E0' />
      <path d='M135.914 210.453L136.457 209.944L136.876 210.569L136.228 211.069L135.914 210.453Z' fill='#5179E0' />
      <path d='M333.084 29.7802L332.589 29.3785L332.808 27.9413L332.827 28.7893L333.084 29.7802Z' fill='#5179E0' />
      <path d='M155.399 295.434L156.561 293.675L157.942 293.559L156.78 295.648L155.399 295.434Z' fill='#5179E0' />
      <path d='M125.457 285.15L125.514 283.829L125.618 284.142L125.647 285.525L125.457 285.15Z' fill='#5179E0' />
      <path d='M191.609 134.524L191.761 134.926L192.18 135.613L191.161 135.408L191.609 134.524Z' fill='#5179E0' />
      <path d='M135.447 138.907L136.228 138.996L136.266 139.853L135.58 139.827L135.447 138.907Z' fill='#5179E0' />
      <path d='M219.704 176.113L221.199 176.693L220.628 177.461L218.589 177.086L219.704 176.113Z' fill='#5179E0' />
      <path d='M180.104 94.81L180.656 94.9707L180.837 96.4703L180.275 96.3186L180.104 94.81Z' fill='#5179E0' />
      <path d='M132.847 219.157L133.352 218.585L133.704 219.157L133.152 219.737L132.847 219.157Z' fill='#5179E0' />
      <path d='M192.609 327.284L192.58 326.364L192.847 326.659L192.809 327.632L192.609 327.284Z' fill='#5179E0' />
      <path d='M157.142 296.817L157.152 295.871L157.628 296.112L157.447 297.05L157.142 296.817Z' fill='#5179E0' />
      <path d='M199.866 292.176L201.437 290.676L202.104 291.488L200.704 292.979L199.866 292.176Z' fill='#5179E0' />
      <path d='M239.999 128.409L240.589 129.249L240.056 130.498L240.037 129.213L239.999 128.409Z' fill='#5179E0' />
      <path d='M253.504 47.5261L254.904 49.1865L253.846 49.7845L251.827 47.8118L253.504 47.5261Z' fill='#5179E0' />
      <path d='M236.932 -9.58584L238.418 -8.07725L238.17 -6.39013L235.647 -8.59499L236.932 -9.58584Z' fill='#5179E0' />
      <path d='M325.189 411.532L323.932 409.488L325.198 409.22L326.446 410.997L325.189 411.532Z' fill='#5179E0' />
      <path d='M187.856 447.078L187.618 445.712L188.123 445.703L188.59 447.131L187.856 447.078Z' fill='#5179E0' />
      <path d='M308.827 354.394L308.589 352.582L309.76 353.349L310.122 355.179L308.827 354.394Z' fill='#5179E0' />
      <path d='M157.942 235.564L159.485 234.617L160.39 235.144L158.647 236.412L157.942 235.564Z' fill='#5179E0' />
      <path d='M204.932 157.126L207.094 157.501L206.437 158.385L204.513 158.063L204.932 157.126Z' fill='#5179E0' />
      <path d='M170.666 108.566L171.647 108.718L172.656 110.351L171.209 109.949L170.666 108.566Z' fill='#5179E0' />
      <path d='M89.4758 219.451L90.0567 218.701L90.2948 219.424L89.8948 220.317L89.4758 219.451Z' fill='#5179E0' />
      <path d='M276.037 397.83L275.094 396.812L275.799 395.741L276.761 396.679L276.037 397.83Z' fill='#5179E0' />
      <path d='M202.323 349.02L202.809 347.565L203.809 348.752L203.466 349.886L202.323 349.02Z' fill='#5179E0' />
      <path d='M292.075 135.917L292.027 136.934L291.084 137.006L289.989 135.711L292.075 135.917Z' fill='#5179E0' />
      <path d='M443.722 -2.02506L444.093 -1.2663L444.207 0.367256L443.322 -0.828911L443.722 -2.02506Z' fill='#5179E0' />
      <path d='M176.99 346.074L177.294 344.708L178.494 344.815L178.237 346.395L176.99 346.074Z' fill='#5179E0' />
      <path d='M369.741 -3.84607L370.255 -3.34618L369.97 -1.98042L370.065 -1.90901L369.741 -3.84607Z' fill='#5179E0' />
      <path d='M300.532 106.557L301.389 107.825L300.046 107.807L299.322 106.647L300.532 106.557Z' fill='#5179E0' />
      <path d='M345.541 -16.7895L346.256 -16.1111L346.456 -13.5938L345.903 -14.0312L345.541 -16.7895Z' fill='#5179E0' />
      <path d='M567.274 80.5364L567.045 79.251L566.997 78.644L567.312 79.9562L567.274 80.5364Z' fill='#5179E0' />
      <path d='M240.047 169.721L241.361 170.445L240.399 171.168L238.961 170.471L240.047 169.721Z' fill='#5179E0' />
      <path d='M138.99 231.877L139.58 231.27L139.961 231.761L139.485 232.395L138.99 231.877Z' fill='#5179E0' />
      <path d='M187.952 188.369L190.047 188.351L189.99 189.199L187.485 189.494L187.952 188.369Z' fill='#5179E0' />
      <path d='M299.561 408.408L299.246 409.381L298.903 408.274L299.408 407.524L299.561 408.408Z' fill='#5179E0' />
      <path d='M165.399 161.786L166.085 161.839L166.39 162.562L165.342 162.598L165.399 161.786Z' fill='#5179E0' />
      <path d='M223.656 25.0223L225.437 26.3524L225.208 27.7271L223.123 26.3524L223.656 25.0223Z' fill='#5179E0' />
      <path d='M429.941 -15.1292L430.065 -14.8971L430.274 -13.0136L429.693 -13.6742L429.941 -15.1292Z' fill='#5179E0' />
      <path d='M305.094 7.89232L305.113 9.5348L304.713 10.3739L304.703 8.66L305.094 7.89232Z' fill='#5179E0' />
      <path d='M137.514 209.882L137.942 209.427L138.114 209.962L137.618 210.409L137.514 209.882Z' fill='#5179E0' />
      <path d='M124.514 286.338L124.533 285.115L124.685 285.454L124.657 286.695L124.514 286.338Z' fill='#5179E0' />
      <path d='M84.952 197.063L85.7425 196.59L85.971 197.367L85.4282 198.036L84.952 197.063Z' fill='#5179E0' />
      <path d='M182.094 93.5067L182.599 93.6942V94.9707L182.228 94.9617L182.094 93.5067Z' fill='#5179E0' />
      <path d='M159.714 209.168L160.38 208.882L160.504 209.445L160.018 209.721L159.714 209.168Z' fill='#5179E0' />
      <path d='M153.104 203.892L153.628 203.633L153.771 204.16L153.266 204.446L153.104 203.892Z' fill='#5179E0' />
      <path d='M251.694 98.3538L253.742 99.4964L252.713 100.077L251.208 99.2286L251.694 98.3538Z' fill='#5179E0' />
      <path d='M424.255 25.5936L422.808 26.7452L422.846 24.8706L423.408 24.8527L424.255 25.5936Z' fill='#5179E0' />
      <path d='M265.094 140.969L265.57 142.112L264.446 142.763L264.008 141.549L265.094 140.969Z' fill='#5179E0' />
      <path d='M182.313 120.509L185.009 120.634L186.066 121.679L183.666 121.723L182.313 120.509Z' fill='#5179E0' />
      <path d='M155.095 361.446L154.78 359.732L155.142 359.857L155.199 361.463L155.095 361.446Z' fill='#5179E0' />
      <path d='M558.864 70.744L558.731 69.4497L558.702 68.9587L558.836 70.2441L558.864 70.744Z' fill='#5179E0' />
      <path d='M374.884 386.315L374.694 387.243L374.798 386.815L374.846 385.949L374.884 386.315Z' fill='#5179E0' />
      <path d='M438.865 414.442L437.131 413.202L438.646 413.068L440.379 414.282L438.865 414.442Z' fill='#5179E0' />
      <path d='M184.142 377.915L184.085 376.156L185.342 376.371L185.513 377.576L184.142 377.915Z' fill='#5179E0' />
      <path d='M311.322 -29.5188L311.589 -27.7781L310.199 -27.4389L309.57 -29.4206L311.322 -29.5188Z' fill='#5179E0' />
      <path d='M99.8376 328.926L99.7424 326.945L99.9138 327.087L100.104 329.167L99.8376 328.926Z' fill='#5179E0' />
      <path d='M333.179 127.204L333.265 128.177L331.989 127.963L331.827 126.91L333.179 127.204Z' fill='#5179E0' />
      <path d='M452.836 11.8646L453.531 13.4089L453.15 14.4533L452.284 12.8108L452.836 11.8646Z' fill='#5179E0' />
      <path d='M107.2 311.011L107.142 309.315L107.39 309.591L107.361 311.261L107.2 311.011Z' fill='#5179E0' />
      <path d='M429.96 26.1828L430.531 27.3343L430.198 28.2448L429.579 27.0933L429.96 26.1828Z' fill='#5179E0' />
      <path d='M270.989 41.1347L271.808 42.6344L270.675 43.0539L269.418 41.2865L270.989 41.1347Z' fill='#5179E0' />
      <path d='M313.913 342.459L313.818 340.477L315.027 342.066L315.237 344.048L313.913 342.459Z' fill='#5179E0' />
      <path d='M115.285 269.922L115.628 268.842L115.761 269.163L115.58 270.332L115.285 269.922Z' fill='#5179E0' />
      <path d='M200.075 256.461L200.428 256.095L200.761 256.639L200.418 257.041L200.075 256.461Z' fill='#5179E0' />
      <path d='M235.132 129.436L236.637 130.427L235.742 130.971L233.818 129.981L235.132 129.436Z' fill='#5179E0' />
      <path d='M99.971 336.567L99.7995 334.461L99.9805 334.586L100.2 336.764L99.971 336.567Z' fill='#5179E0' />
      <path d='M360.455 446.98L359.446 446.962L359.103 445.034L360.608 445.4L360.455 446.98Z' fill='#5179E0' />
      <path d='M133.38 222.459L133.914 221.942L134.095 222.415L133.59 222.968L133.38 222.459Z' fill='#5179E0' />
      <path
        d='M336.284 -2.09647L336.065 -0.418282L334.932 -0.400425L334.656 -2.20359L336.284 -2.09647Z'
        fill='#5179E0'
      />
      <path d='M177.523 261.834L179.456 260.317L180.504 260.558L178.323 262.629L177.523 261.834Z' fill='#5179E0' />
      <path d='M135.057 215.211L135.542 214.756L135.742 215.256L135.19 215.729L135.057 215.211Z' fill='#5179E0' />
      <path d='M118.199 293.202L118.19 291.908L118.409 292.238L118.323 293.541L118.199 293.202Z' fill='#5179E0' />
      <path d='M181.123 85.2764L181.723 85.455L182.056 86.9457L181.066 86.4726L181.123 85.2764Z' fill='#5179E0' />
      <path d='M233.751 159.019L235.123 159.67L234.323 160.375L232.942 159.751L233.751 159.019Z' fill='#5179E0' />
      <path d='M103.895 272.153L104.266 270.966L104.428 271.27L104.228 272.564L103.895 272.153Z' fill='#5179E0' />
      <path d='M178.828 315.51L179.78 313.671L180.723 314.287L180.113 315.545L178.828 315.51Z' fill='#5179E0' />
      <path d='M146.98 209.927L147.656 209.552L147.876 210.087L147.276 210.489L146.98 209.927Z' fill='#5179E0' />
      <path d='M193.723 34.8683L195.685 36.0377L195.971 37.698L194.123 36.7607L193.723 34.8683Z' fill='#5179E0' />
      <path d='M186.294 270.207L186.59 269.681L187.018 270.091L186.685 270.725L186.294 270.207Z' fill='#5179E0' />
      <path d='M119.923 387.725L119.161 385.163L119.552 385.288L120.018 387.609L119.923 387.725Z' fill='#5179E0' />
      <path d='M336.865 37.5284L336.332 37.1714L336.551 35.8502L336.665 36.5733L336.865 37.5284Z' fill='#5179E0' />
      <path d='M140.542 211.051L141.237 210.605L141.609 211.167L140.904 211.649L140.542 211.051Z' fill='#5179E0' />
      <path d='M144.276 225.771L144.78 225.414L144.914 225.905L144.504 226.271L144.276 225.771Z' fill='#5179E0' />
      <path d='M177.533 385.119L177.209 384.003L178.456 383.226L178.885 384.949L177.533 385.119Z' fill='#5179E0' />
      <path d='M207.351 140.112L209.504 140.71L208.913 141.496L206.647 141.005L207.351 140.112Z' fill='#5179E0' />
      <path d='M263.218 -26.1267L264.094 -24.5735L263.199 -23.4666L261.885 -25.2697L263.218 -26.1267Z' fill='#5179E0' />
      <path d='M95.4948 347.047L95.1996 344.744L95.4281 344.86L95.6948 347.163L95.4948 347.047Z' fill='#5179E0' />
      <path d='M189.751 95.7205L192.285 96.2739L192.952 97.4612L190.247 97.0148L189.751 95.7205Z' fill='#5179E0' />
      <path d='M189.856 153.252L192.19 153.431L191.913 154.314L189.685 154.243L189.856 153.252Z' fill='#5179E0' />
      <path d='M432.341 15.9262L432.998 17.167L432.627 18.1578L432.036 17.0242L432.341 15.9262Z' fill='#5179E0' />
      <path d='M359.036 450.738L358.617 449.318L360.475 450.398L360.903 451.764L359.036 450.738Z' fill='#5179E0' />
      <path d='M143.028 216.854L143.733 216.389L144.095 216.916L143.437 217.425L143.028 216.854Z' fill='#5179E0' />
      <path d='M168.647 307.029L169.723 304.887L171.133 304.396L170.161 306.717L168.647 307.029Z' fill='#5179E0' />
      <path d='M181.075 303.137L182.399 300.959L183.532 301.075L182.504 302.986L181.075 303.137Z' fill='#5179E0' />
      <path d='M227.742 273.716L229.866 272.243L230.008 273.314L228.189 274.608L227.742 273.716Z' fill='#5179E0' />
      <path d='M493.712 4.60736L493.931 5.4286L493.769 6.56226L493.636 5.80351L493.712 4.60736Z' fill='#5179E0' />
      <path d='M379.493 -7.50595L379.589 -8.25578L379.255 -9.35374L379.846 -9.03238L379.493 -7.50595Z' fill='#5179E0' />
      <path d='M226.98 -11.0766L228.151 -9.83578L227.789 -8.28255L226.266 -9.69295L226.98 -11.0766Z' fill='#5179E0' />
      <path d='M177.466 333.389L177.456 332.416L177.866 332.684L177.771 333.747L177.466 333.389Z' fill='#5179E0' />
      <path d='M192.637 350.734L192.961 349.297L194.104 349.627L193.866 351.18L192.637 350.734Z' fill='#5179E0' />
      <path d='M345.198 428.439L343.846 427.386L343.998 425.663L345.703 426.94L345.198 428.439Z' fill='#5179E0' />
      <path d='M335.198 417.942L333.665 416.183L334.418 415.13L335.951 416.683L335.198 417.942Z' fill='#5179E0' />
      <path d='M204.056 224.664L206.323 223.888L206.647 224.557L204.066 225.709L204.056 224.664Z' fill='#5179E0' />
      <path d='M337.427 -13.8616L338.17 -13.0404L338.075 -11.0409L337.703 -11.3265L337.427 -13.8616Z' fill='#5179E0' />
      <path d='M217.151 284.927L218.818 283.481L219.094 284.677L217.789 285.731L217.151 284.927Z' fill='#5179E0' />
      <path d='M330.494 4.54487L329.703 5.13402L328.932 3.8486L329.97 2.65245L330.494 4.54487Z' fill='#5179E0' />
      <path d='M322.656 389.6L321.656 387.047L322.151 385.101L323.189 387.225L322.656 389.6Z' fill='#5179E0' />
      <path d='M223.542 37.1892L225.266 38.5282L224.666 39.528L223.285 38.555L223.542 37.1892Z' fill='#5179E0' />
      <path d='M118.885 144.29L119.78 144.379L119.876 145.2L118.961 145.156L118.885 144.29Z' fill='#5179E0' />
      <path d='M157.475 204.222L158.028 204.115L158.218 204.633L157.609 204.803L157.475 204.222Z' fill='#5179E0' />
      <path d='M201.285 431.269L200.961 430.126L201.961 430.18L202.294 431.421L201.285 431.269Z' fill='#5179E0' />
      <path d='M411.141 17.4348L411.322 18.015L411.208 19.0862L411.065 18.5327L411.141 17.4348Z' fill='#5179E0' />
      <path d='M451.769 -12.8619L452.017 -12.2013L451.96 -10.7552L451.579 -11.5229L451.769 -12.8619Z' fill='#5179E0' />
      <path d='M192.218 78.894L193.228 79.2867L193.704 80.6614L191.99 79.9652L192.218 78.894Z' fill='#5179E0' />
      <path d='M168.285 134.426L169.78 134.515L171.323 135.818L169.294 135.613L168.285 134.426Z' fill='#5179E0' />
      <path d='M176.742 469.341L175.828 468.198L176.504 467.716L177.428 468.876L176.742 469.341Z' fill='#5179E0' />
      <path d='M166.504 343.709L166.685 342.334L167.809 342.414L167.723 343.753L166.504 343.709Z' fill='#5179E0' />
      <path d='M340.675 406.783L339.465 404.873L339.656 402.74L341.037 404.605L340.675 406.783Z' fill='#5179E0' />
      <path d='M223.789 432.17L223.628 431.046L224.828 431.581L225.028 432.858L223.789 432.17Z' fill='#5179E0' />
      <path d='M143.552 218.175L144.123 217.898L144.276 218.398L143.771 218.683L143.552 218.175Z' fill='#5179E0' />
      <path d='M163.333 341.298L163.514 339.834L164.666 339.799L164.59 341.182L163.333 341.298Z' fill='#5179E0' />
      <path d='M225.151 99.7374L225.361 99.9963L225.294 100.728L225.256 100.523L225.151 99.7374Z' fill='#5179E0' />
      <path d='M182.599 449.729L181.99 448.506L182.866 448.265L183.371 449.559L182.599 449.729Z' fill='#5179E0' />
      <path d='M222.009 67.5126L223.751 68.6106L223.466 69.6193L221.189 68.4053L222.009 67.5126Z' fill='#5179E0' />
      <path d='M194.504 144.861L195.513 145.263L195.523 145.977L194.075 145.736L194.504 144.861Z' fill='#5179E0' />
      <path d='M337.16 -14.1741L336.627 -12.6744L335.141 -13.1743L335.398 -14.9953L337.16 -14.1741Z' fill='#5179E0' />
      <path d='M199.475 152.993L200.904 153.323L200.675 154.091L199.142 153.85L199.475 152.993Z' fill='#5179E0' />
      <path d='M149.066 178.612L149.799 178.487L150.295 179.139L149.056 179.38L149.066 178.612Z' fill='#5179E0' />
      <path d='M82.8472 367.596L82.2949 364.972L82.571 365.016L83.0758 367.605L82.8472 367.596Z' fill='#5179E0' />
      <path d='M343.322 27.1469L342.579 27.129L342.217 25.9239L343.265 25.5669L343.322 27.1469Z' fill='#5179E0' />
      <path d='M215.913 168.525L217.218 169.007L216.951 169.659L215.066 169.418L215.913 168.525Z' fill='#5179E0' />
      <path d='M305.313 373.157L304.732 371.122L306.189 371.988L306.818 373.8L305.313 373.157Z' fill='#5179E0' />
      <path d='M214.304 272.51L216.57 270.484L217.132 271.118L215.256 272.903L214.304 272.51Z' fill='#5179E0' />
      <path d='M321.208 384.53L320.084 382.119L321.113 381.709L322.16 383.601L321.208 384.53Z' fill='#5179E0' />
      <path d='M208.38 334.3L208.913 333.113L209.875 333.934L209.361 335.568L208.38 334.3Z' fill='#5179E0' />
      <path d='M301.513 342.004L301.256 340.575L302.237 340.754L302.751 342.736L301.513 342.004Z' fill='#5179E0' />
      <path d='M200.094 178.862L202.009 178.969L201.79 179.728L199.675 179.782L200.094 178.862Z' fill='#5179E0' />
      <path d='M79.1139 202.973L79.9806 202.473L80.1615 203.116L79.5139 203.767L79.1139 202.973Z' fill='#5179E0' />
      <path d='M198.799 11.9271L200.513 13.2572L200.561 14.98L198.618 13.6231L198.799 11.9271Z' fill='#5179E0' />
      <path d='M233.951 54.6495L235.161 55.7564L234.656 56.658L233.008 55.4172L233.951 54.6495Z' fill='#5179E0' />
      <path d='M150.952 220.951L153.152 219.674L153.971 220.21L151.914 221.576L150.952 220.951Z' fill='#5179E0' />
      <path d='M197.532 239.331L199.961 237.893L200.609 238.349L198.085 240.107L197.532 239.331Z' fill='#5179E0' />
      <path d='M465.684 422.244L464.026 423.271L463.626 422.664L465.779 421.557L465.684 422.244Z' fill='#5179E0' />
      <path d='M194.951 124.732L197.332 125.035L197.58 125.91L195.932 125.839L194.951 124.732Z' fill='#5179E0' />
      <path d='M132.895 334.121L132.599 333.068L133.028 333.05L133.495 334.121H132.895Z' fill='#5179E0' />
      <path d='M329.608 443.07L327.694 441.722L328.894 441.159L330.018 441.677L329.608 443.07Z' fill='#5179E0' />
      <path d='M292.237 409.64L291.161 407.828L292.78 408.292L293.361 409.086L292.237 409.64Z' fill='#5179E0' />
      <path d='M186.561 84.5445L186.942 84.7944L186.847 85.7585L186.656 85.6603L186.561 84.5445Z' fill='#5179E0' />
      <path d='M170.628 77.5996L171.047 77.8049L170.999 79.0547L170.656 78.9565L170.628 77.5996Z' fill='#5179E0' />
      <path d='M182.99 279.018L184.885 276.652L186.171 276.34L184.342 279L182.99 279.018Z' fill='#5179E0' />
      <path d='M223.97 161.322L224.942 161.946L224.618 162.58L223.285 162.143L223.97 161.322Z' fill='#5179E0' />
      <path d='M211.866 345.351L212.437 343.798L213.399 345.253L212.932 346.86L211.866 345.351Z' fill='#5179E0' />
      <path d='M254.57 258.451L256.818 257.264L256.713 258.228L254.504 259.513L254.57 258.451Z' fill='#5179E0' />
      <path d='M107.095 252.372L107.657 251.533L107.866 251.917L107.419 252.792L107.095 252.372Z' fill='#5179E0' />
      <path d='M122.247 131.65L123.199 131.926V132.73L122.257 132.516L122.247 131.65Z' fill='#5179E0' />
      <path d='M182.504 72.0741L182.828 72.2258L182.904 73.538L182.323 73.1631L182.504 72.0741Z' fill='#5179E0' />
      <path d='M248.618 480.079L247.97 478.945L249.256 478.696L249.961 480.008L248.618 480.079Z' fill='#5179E0' />
      <path d='M194.694 47.9189L196.599 49.124L196.542 50.4183L194.98 49.5971L194.694 47.9189Z' fill='#5179E0' />
      <path d='M121.352 141.014L122.209 141.29L122.171 142.022L121.428 141.799L121.352 141.014Z' fill='#5179E0' />
      <path d='M252.027 269.315L254.208 268.02L254.065 269.163L251.97 270.502L252.027 269.315Z' fill='#5179E0' />
      <path d='M210.761 126.214L210.656 127.231L210.818 127.981L210.647 127.142L210.761 126.214Z' fill='#5179E0' />
      <path d='M474.084 418.647L473.217 420.013L472.693 419.691L474.026 418.227L474.084 418.647Z' fill='#5179E0' />
      <path d='M75.4187 181.246L76.4758 180.96L76.6854 181.71L75.8092 182.129L75.4187 181.246Z' fill='#5179E0' />
      <path d='M335.637 394.01L334.408 391.823L334.884 390.314L336.198 392.287L335.637 394.01Z' fill='#5179E0' />
      <path d='M304.341 368.658L303.599 367.087L304.494 366.641L305.38 368.257L304.341 368.658Z' fill='#5179E0' />
      <path d='M186.466 100.416L188.275 100.844L189.171 102.085L186.552 101.523L186.466 100.416Z' fill='#5179E0' />
      <path d='M363.751 -1.34663L363.789 -2.12326L363.484 -3.16766L364.103 -2.77489L363.751 -1.34663Z' fill='#5179E0' />
      <path d='M333.503 139.987L333.484 140.907L332.332 140.862L332.218 139.889L333.503 139.987Z' fill='#5179E0' />
      <path d='M361.694 495.486L360.951 494.076L362.684 494.246L363.417 495.54L361.694 495.486Z' fill='#5179E0' />
      <path d='M207.132 64.1384L207.875 64.7186L207.799 65.7898L206.685 65.0578L207.132 64.1384Z' fill='#5179E0' />
      <path d='M240.494 44.7946L241.761 46.1336L240.913 46.8209L239.732 45.6605L240.494 44.7946Z' fill='#5179E0' />
      <path d='M196.618 281.348L198.599 278.768L199.713 278.607L198.028 281.107L196.618 281.348Z' fill='#5179E0' />
      <path d='M466.417 432.572L464.293 431.51L466.179 431.073L468.445 432.17L466.417 432.572Z' fill='#5179E0' />
      <path d='M155.647 347.458L155.599 346.27L156.123 346.386V347.654L155.647 347.458Z' fill='#5179E0' />
      <path d='M168.828 237.51L170.685 236.099L171.571 236.421L169.78 237.974L168.828 237.51Z' fill='#5179E0' />
      <path d='M182.694 313.367L183.447 311.778L184.399 312.118L183.809 313.546L182.694 313.367Z' fill='#5179E0' />
      <path d='M241.799 108.352L243.085 109.021L242.513 109.637L241.666 109.128L241.799 108.352Z' fill='#5179E0' />
      <path d='M175.79 354.081L175.799 352.903L176.913 352.769L176.98 354.269L175.79 354.081Z' fill='#5179E0' />
      <path d='M189.751 272.448L190.19 271.671L190.685 272.225L190.371 272.832L189.751 272.448Z' fill='#5179E0' />
      <path d='M171.38 315.92L171.361 315.028L171.894 315.126L171.942 316.054L171.38 315.92Z' fill='#5179E0' />
      <path d='M191.38 114.297L194.028 114.743L194.551 115.725L192.142 115.466L191.38 114.297Z' fill='#5179E0' />
      <path d='M176.942 196.644L179.009 196.144L179.294 196.831L177.104 197.537L176.942 196.644Z' fill='#5179E0' />
      <path d='M575.131 94.5868L575.083 93.2211L574.969 92.4266L575.131 93.8638V94.5868Z' fill='#5179E0' />
      <path d='M233.408 255.559L235.361 254.149L235.827 254.64L233.885 256.246L233.408 255.559Z' fill='#5179E0' />
      <path d='M155.961 450.077L155.256 448.863L155.952 448.461L156.837 449.649L155.961 450.077Z' fill='#5179E0' />
      <path d='M196.723 253.202L199.056 251.167L199.866 251.426L197.78 253.461L196.723 253.202Z' fill='#5179E0' />
      <path d='M328.827 384.262L327.741 382.137L328.208 380.611L329.132 382.084L328.827 384.262Z' fill='#5179E0' />
      <path d='M342.941 418.62L341.941 417.236L343.713 417.46L345.417 419.495L342.941 418.62Z' fill='#5179E0' />
      <path d='M342.617 -36.6064L342.56 -36.0976L342.17 -36.6064H342.617Z' fill='#5179E0' />
      <path d='M66.5521 389.805L65.5426 387.038L65.8283 386.922L66.8759 389.707L66.5521 389.805Z' fill='#5179E0' />
      <path d='M290.551 -20.9404L290.799 -19.289L289.456 -19.0033L288.932 -20.8333L290.551 -20.9404Z' fill='#5179E0' />
      <path d='M165.952 299.531L166.047 298.71L166.704 298.853L166.599 299.781L165.952 299.531Z' fill='#5179E0' />
      <path d='M588.645 30.6728L587.245 30.6907V29.5481L588.626 29.5213L588.645 30.6728Z' fill='#5179E0' />
      <path d='M436.189 405.525L434.027 406.016V405.355L435.903 404.802L436.189 405.525Z' fill='#5179E0' />
      <path d='M191.628 64.1116L192.237 64.6294L192.113 65.6559L191.39 65.1114L191.628 64.1116Z' fill='#5179E0' />
      <path d='M317.656 483.168L316.094 482.954L316.684 482.088L317.703 481.927L317.656 483.168Z' fill='#5179E0' />
      <path d='M250.227 52.3375L250.875 53.3105L250.285 53.9889L249.408 52.9802L250.227 52.3375Z' fill='#5179E0' />
      <path d='M500.836 6.19628L500.76 7.12464L500.712 8.2226L500.617 7.17819L500.836 6.19628Z' fill='#5179E0' />
      <path d='M144.818 253.113L146.38 251.212L147.676 251.06L146.342 252.935L144.818 253.113Z' fill='#5179E0' />
      <path d='M571.74 86.2405L571.655 84.9908L571.578 84.3124L571.75 85.5978L571.74 86.2405Z' fill='#5179E0' />
      <path d='M227.008 283.57L228.818 281.767L229.428 282.223L227.723 284.16L227.008 283.57Z' fill='#5179E0' />
      <path d='M195.409 368.872L195.732 367.096L196.932 367.819L196.742 369.685L195.409 368.872Z' fill='#5179E0' />
      <path d='M169.037 118.992L169.447 119.974L169.58 120.572L168.685 119.902L169.037 118.992Z' fill='#5179E0' />
      <path d='M226.723 45.0535L227.885 46.1782L227.161 46.9191L226.418 46.1514L226.723 45.0535Z' fill='#5179E0' />
      <path d='M267.665 247.115L269.351 246.284L269.189 247.231L267.589 248.061L267.665 247.115Z' fill='#5179E0' />
      <path d='M116.342 378.763L115.733 376.969L116.276 376.853L116.619 378.486L116.342 378.763Z' fill='#5179E0' />
      <path d='M185.904 377.228L185.59 375.701L186.723 374.96L187.123 376.371L185.904 377.228Z' fill='#5179E0' />
      <path d='M292.522 -13.2189L292.332 -11.5854L290.941 -12.0139L290.961 -14.4508L292.522 -13.2189Z' fill='#5179E0' />
      <path d='M308.36 119.206L308.827 120.054L307.903 120.313L307.627 119.429L308.36 119.206Z' fill='#5179E0' />
      <path d='M403.712 25.6472L404.055 26.5041L403.646 27.3075L403.874 26.6113L403.712 25.6472Z' fill='#5179E0' />
      <path d='M261.151 256.139L262.732 255.273L262.799 256.077L261.008 257.291L261.151 256.139Z' fill='#5179E0' />
      <path d='M196.047 295.157L196.199 294.479L196.828 294.987L196.713 295.871L196.047 295.157Z' fill='#5179E0' />
      <path d='M192.523 32.8598L194.847 34.5559L194.628 35.8324L192.675 34.6094L192.523 32.8598Z' fill='#5179E0' />
      <path d='M161.037 185.673L162.314 185.468L162.666 186.075L161.18 186.459L161.037 185.673Z' fill='#5179E0' />
      <path d='M73.0854 238.732L73.733 237.84L74.1044 238.304L73.5044 239.241L73.0854 238.732Z' fill='#5179E0' />
      <path d='M401.093 -30.5186L400.474 -30.099L400.484 -32.045L401.227 -32.6877L401.093 -30.5186Z' fill='#5179E0' />
      <path d='M159.647 75.9304L160.18 76.3678L159.999 77.3229L159.695 77.064L159.647 75.9304Z' fill='#5179E0' />
      <path d='M117.638 234.26L118.38 233.805L118.609 234.296L117.961 234.742L117.638 234.26Z' fill='#5179E0' />
      <path d='M263.78 104.879L264.484 105.566L263.989 106.057L263.265 105.406L263.78 104.879Z' fill='#5179E0' />
      <path d='M307.189 -3.94426L307.103 -2.30178L306.76 -1.62337L306.551 -3.26585L307.189 -3.94426Z' fill='#5179E0' />
      <path d='M154.856 452.407L153.856 451.193L155.018 450.631L156.047 451.604L154.856 452.407Z' fill='#5179E0' />
      <path d='M213.037 114.547L215.075 115.189L214.809 115.912L213.37 115.555L213.037 114.547Z' fill='#5179E0' />
      <path d='M483.941 410.55L483.474 412.023L483.417 411.854L483.779 410.479L483.941 410.55Z' fill='#5179E0' />
      <path d='M213.551 519.338L212.418 518.285L213.18 517.49L214.399 518.535L213.551 519.338Z' fill='#5179E0' />
      <path d='M194.828 435.518L194.39 434.366L195.637 434.34L196.18 435.75L194.828 435.518Z' fill='#5179E0' />
      <path d='M246.494 -12.121L247.637 -9.88041L246.285 -9.47871L245.132 -11.4693L246.494 -12.121Z' fill='#5179E0' />
      <path d='M356.941 434.331L355.341 432.224L358.008 433.179L360.075 435.616L356.941 434.331Z' fill='#5179E0' />
      <path d='M228.228 233.984L230.761 232.296L231.151 232.743L228.923 234.376L228.228 233.984Z' fill='#5179E0' />
      <path d='M178.19 188.869L179.723 188.637L179.99 189.235L178.228 189.69L178.19 188.869Z' fill='#5179E0' />
      <path d='M296.389 363.963L295.694 362.856L296.389 362.204L297.256 363.436L296.389 363.963Z' fill='#5179E0' />
      <path d='M146.009 67.2091L146.447 67.5216L146.399 68.8159L145.904 68.4678L146.009 67.2091Z' fill='#5179E0' />
      <path d='M324.075 381.503L323.018 379.941L323.522 378.808L324.465 379.95L324.075 381.503Z' fill='#5179E0' />
      <path d='M531.274 1.75086L529.236 2.52748L529.759 1.14386L530.836 0.67968L531.274 1.75086Z' fill='#5179E0' />
      <path d='M190.98 324.597L191.494 323.142L192.59 322.785L192.094 324.784L190.98 324.597Z' fill='#5179E0' />
      <path d='M208.285 40.0725L209.57 41.2508L209.132 42.2773L207.894 41.2776L208.285 40.0725Z' fill='#5179E0' />
      <path d='M141.78 116.127L142.723 116.823L142.58 117.528L141.571 116.903L141.78 116.127Z' fill='#5179E0' />
      <path d='M238.056 239.045L238.704 238.804L239.037 239.366L238.247 240.018L238.056 239.045Z' fill='#5179E0' />
      <path d='M220.504 238.331L221.332 237.635L221.609 238.304L220.98 238.732L220.504 238.331Z' fill='#5179E0' />
      <path d='M160.895 223.673L162.818 222.388L163.675 222.7L161.771 224.173L160.895 223.673Z' fill='#5179E0' />
      <path d='M125.876 130.088L126.971 130.614L126.799 131.337L125.885 130.811L125.876 130.088Z' fill='#5179E0' />
      <path d='M233.17 317.295L234.256 315.536L235.047 316.036L233.961 318.268L233.17 317.295Z' fill='#5179E0' />
      <path d='M198.409 268.413L200.18 266.074L201.142 265.976L199.704 268.092L198.409 268.413Z' fill='#5179E0' />
      <path d='M74.0759 278.268L74.4758 277.054L74.7901 277.349L74.3901 278.616L74.0759 278.268Z' fill='#5179E0' />
      <path d='M492.464 399.49L491.788 400.99L491.417 400.785L492.341 399.241L492.464 399.49Z' fill='#5179E0' />
      <path d='M238.904 325.543L240.027 323.472L240.666 324.695L239.589 327.132L238.904 325.543Z' fill='#5179E0' />
      <path d='M339.894 406.775L339.227 406.658V405.096L339.808 405.078L339.894 406.775Z' fill='#5179E0' />
      <path d='M75.3425 242.883L75.933 241.955L76.3711 242.392L75.7235 243.321L75.3425 242.883Z' fill='#5179E0' />
      <path d='M58.4283 296.291L58.6378 294.675L59.0093 294.889L58.7521 296.514L58.4283 296.291Z' fill='#5179E0' />
      <path d='M239.942 245.686L241.485 244.499L241.866 244.954L240.427 246.177L239.942 245.686Z' fill='#5179E0' />
      <path d='M346.903 -12.1924L347.932 -10.6035L347.027 -9.80007L346.865 -10.3803L346.903 -12.1924Z' fill='#5179E0' />
      <path d='M169.714 378.834L169.209 377.576L170.247 376.942L170.809 378.004L169.714 378.834Z' fill='#5179E0' />
      <path d='M74.0282 186.825L75.2663 186.584L75.3806 187.182L74.4092 187.566L74.0282 186.825Z' fill='#5179E0' />
      <path d='M139.276 329.212L138.923 328.31L139.571 328.212L139.885 329.149L139.276 329.212Z' fill='#5179E0' />
      <path d='M201.39 232.439L203.266 231.118L204.085 231.225L201.951 233.055L201.39 232.439Z' fill='#5179E0' />
      <path d='M222.189 317.697L223.208 315.679L224.094 315.92L223.247 317.875L222.189 317.697Z' fill='#5179E0' />
      <path d='M251.199 107.941V108.664L251.246 109.253L251.132 108.575L251.199 107.941Z' fill='#5179E0' />
      <path d='M476.15 0.911777L475.607 0.956398L475.941 -0.489697L476.026 -0.284389L476.15 0.911777Z' fill='#5179E0' />
      <path d='M208.104 270.234L209.618 268.163L210.532 268.056L209.237 270.056L208.104 270.234Z' fill='#5179E0' />
      <path d='M195.275 293.657L195.628 292.604L196.323 293.059L196.056 293.979L195.275 293.657Z' fill='#5179E0' />
      <path d='M82.6758 266.289L83.2092 265.342L83.4853 265.708L83.0187 266.69L82.6758 266.289Z' fill='#5179E0' />
      <path d='M214.047 236.028L216.266 234.305L217.028 234.403L214.761 236.447L214.047 236.028Z' fill='#5179E0' />
      <path d='M172.894 202.812L174.618 202.187L174.98 202.759L173.294 203.491L172.894 202.812Z' fill='#5179E0' />
      <path d='M238.599 268.012L240.18 266.467L240.694 266.878L239.199 268.494L238.599 268.012Z' fill='#5179E0' />
      <path d='M192.942 29.4321L193.123 29.6552L193.151 31.1727L192.894 30.8781L192.942 29.4321Z' fill='#5179E0' />
      <path d='M169.78 57.7559L170.094 58.1755V59.2645L169.59 58.7557L169.78 57.7559Z' fill='#5179E0' />
      <path d='M238.447 247.427L239.313 246.829L239.618 247.48L238.742 248.239L238.447 247.427Z' fill='#5179E0' />
      <path d='M176.923 80.6436L177.294 81.2149L177.171 81.9647L176.999 81.438L176.923 80.6436Z' fill='#5179E0' />
      <path d='M519.607 41.2686L518.455 41.4561L518.502 40.4831L519.436 40.2956L519.607 41.2686Z' fill='#5179E0' />
      <path d='M334.932 -27.912L336.037 -25.7607L335.398 -24.2253L334.484 -25.7875L334.932 -27.912Z' fill='#5179E0' />
      <path d='M83.933 212.542L84.9139 212.006L85.1329 212.471L84.2472 213.078L83.933 212.542Z' fill='#5179E0' />
      <path d='M65.2092 226.048L66.1045 225.316L66.4378 225.807L65.6759 226.619L65.2092 226.048Z' fill='#5179E0' />
      <path d='M229.208 41.8489L229.932 42.7504L229.447 43.5449L228.78 42.7237L229.208 41.8489Z' fill='#5179E0' />
      <path d='M333.846 11.1327L333.056 12.1592L332.046 11.2933L332.837 10.0883L333.846 11.1327Z' fill='#5179E0' />
      <path d='M378.094 3.33086L378.741 4.39312L378.065 5.18758L378.227 4.45561L378.094 3.33086Z' fill='#5179E0' />
      <path d='M207.961 261.915L208.666 260.915L209.113 261.584L208.599 262.2L207.961 261.915Z' fill='#5179E0' />
      <path d='M226.713 243.803L228.789 241.955L229.304 242.276L227.599 243.91L226.713 243.803Z' fill='#5179E0' />
      <path d='M225.466 54.6852L225.875 55.4797L225.704 56.2295L224.961 55.435L225.466 54.6852Z' fill='#5179E0' />
      <path d='M137.523 253.693L138.409 252.551L139.152 252.801L138.637 253.738L137.523 253.693Z' fill='#5179E0' />
      <path d='M87.8948 257.585L88.4948 256.791L88.7805 257.193L88.2377 258.023L87.8948 257.585Z' fill='#5179E0' />
      <path d='M210.799 324.115L211.532 322.178L212.589 321.785L211.961 323.963L210.799 324.115Z' fill='#5179E0' />
      <path d='M208.561 26.4327L208.799 26.8076V28.1734L208.332 27.6378L208.561 26.4327Z' fill='#5179E0' />
      <path d='M188.894 303.236L188.99 302.388L189.742 302.986L189.723 304.012L188.894 303.236Z' fill='#5179E0' />
      <path d='M113.447 81.688L114.419 82.4824L114.133 83.4911L113.485 83.143L113.447 81.688Z' fill='#5179E0' />
      <path d='M221.628 297.478L222.98 295.264L223.828 295.3L222.723 297.335L221.628 297.478Z' fill='#5179E0' />
      <path d='M178.171 240.402L179.771 238.911L180.675 238.965L179.113 240.652L178.171 240.402Z' fill='#5179E0' />
      <path d='M240.98 227.735L242.475 226.78L242.627 227.36L241.437 228.101L240.98 227.735Z' fill='#5179E0' />
      <path d='M232.332 340.263L233.475 337.737L234.294 338.647L233.313 341.227L232.332 340.263Z' fill='#5179E0' />
      <path d='M511.598 48.0706L510.75 48.2402L510.531 47.3298L511.636 47.0977L511.598 48.0706Z' fill='#5179E0' />
      <path d='M220.275 32.8509L221.056 33.7971L220.551 34.6898L219.837 33.8507L220.275 32.8509Z' fill='#5179E0' />
      <path d='M232.513 297.246L233.589 295.47L234.256 295.943L233.37 297.549L232.513 297.246Z' fill='#5179E0' />
      <path d='M43.0379 313.046L43.0474 311.198L43.4474 311.314L43.4379 313.171L43.0379 313.046Z' fill='#5179E0' />
      <path d='M152.828 410.14L152.447 408.792L153.533 408.881L153.999 410.176L152.828 410.14Z' fill='#5179E0' />
      <path d='M256.294 52.4447L256.818 53.498L256.151 54.0157L255.542 52.9802L256.294 52.4447Z' fill='#5179E0' />
      <path d='M222.151 25.3258L223.037 26.4684L222.494 27.4771L221.294 26.1649L222.151 25.3258Z' fill='#5179E0' />
      <path d='M161.647 245.472L163.275 243.678H164.285L162.923 245.374L161.647 245.472Z' fill='#5179E0' />
      <path d='M508.341 448.988L506.483 450.068L506.712 449.051L509.131 447.756L508.341 448.988Z' fill='#5179E0' />
      <path d='M431.246 17.0509L431.55 16.5243L431.122 15.6584L431.55 15.6406L431.246 17.0509Z' fill='#5179E0' />
      <path d='M155.323 226.86L156.799 225.78L157.371 226.226L156.237 227.164L155.323 226.86Z' fill='#5179E0' />
      <path d='M94.4472 248.418L95.1139 247.802L95.3996 248.248L94.7805 248.891L94.4472 248.418Z' fill='#5179E0' />
      <path d='M83.152 238.197L83.8758 237.358L84.2758 237.742L83.5425 238.608L83.152 238.197Z' fill='#5179E0' />
      <path d='M226.999 250.667L228.628 248.944L229.17 249.248L227.875 250.685L226.999 250.667Z' fill='#5179E0' />
      <path d='M235.542 306.815L236.285 305.396L236.989 306.083L236.247 308.038L235.542 306.815Z' fill='#5179E0' />
      <path d='M229.437 302.557L230.266 300.933L230.961 301.504L230.266 303.039L229.437 302.557Z' fill='#5179E0' />
      <path d='M225.389 237.251L226.456 236.349L226.77 236.867L225.913 237.563L225.389 237.251Z' fill='#5179E0' />
      <path d='M136.933 81.5362L137.695 82.1789L137.476 83.018L136.79 82.4824L136.933 81.5362Z' fill='#5179E0' />
      <path d='M226.761 10.5524L228.228 12.552L227.313 13.3554L225.466 11.1505L226.761 10.5524Z' fill='#5179E0' />
      <path d='M232.866 276.331L234.189 274.573L234.713 275.072L233.694 276.429L232.866 276.331Z' fill='#5179E0' />
      <path d='M187.894 41.7864L187.99 42.197L188.047 43.3128L187.847 42.8576L187.894 41.7864Z' fill='#5179E0' />
      <path d='M101.428 148.574L102.742 148.905L102.704 149.574L101.419 149.324L101.428 148.574Z' fill='#5179E0' />
      <path d='M162.656 45.1874L163.056 45.7051L162.933 46.8299L162.618 46.3925L162.656 45.1874Z' fill='#5179E0' />
      <path d='M176.561 116.189L176.571 117.715L176.761 118.34L176.333 117.198L176.561 116.189Z' fill='#5179E0' />
      <path d='M76.8092 413.353L75.0568 411.282L75.5425 410.997L76.9806 412.916L76.8092 413.353Z' fill='#5179E0' />
      <path d='M175.675 28.9322L175.875 29.2624L175.856 30.6461L175.78 30.4229L175.675 28.9322Z' fill='#5179E0' />
      <path d='M478.379 446.48L477.036 447.756L476.988 447.033L478.455 445.685L478.379 446.48Z' fill='#5179E0' />
      <path d='M190.285 390.305L190.228 388.359L191.532 388.404L191.732 390.323L190.285 390.305Z' fill='#5179E0' />
      <path d='M216.685 21.8624L217.275 22.6747L216.951 23.7994L216.142 22.88L216.685 21.8624Z' fill='#5179E0' />
      <path d='M203.856 40.2331L204.151 40.8402L204.094 41.8221L203.59 41.1704L203.856 40.2331Z' fill='#5179E0' />
      <path d='M181.723 294.372L181.904 293.568L182.609 293.613L182.447 294.755L181.723 294.372Z' fill='#5179E0' />
      <path d='M340.446 397.955L339.865 397.875L339.808 396.375L340.513 396.545L340.446 397.955Z' fill='#5179E0' />
      <path d='M301.046 169.641L300.675 170.873L299.884 171.185L299.513 170.132L301.046 169.641Z' fill='#5179E0' />
      <path d='M180.828 313.76L180.837 312.733L181.704 313.439L181.771 314.555L180.828 313.76Z' fill='#5179E0' />
      <path d='M133.533 221.442L134.428 221.254L134.637 221.933L133.857 221.96L133.533 221.442Z' fill='#5179E0' />
      <path d='M98.3043 241.143L99.1043 240.571L99.3519 241.08L98.6757 241.58L98.3043 241.143Z' fill='#5179E0' />
      <path
        d='M420.284 -35.9013L419.322 -34.2856L418.208 -35.1604L418.989 -36.6064H419.579L420.284 -35.9013Z'
        fill='#5179E0'
      />
      <path d='M106.933 221.736L107.904 221.192L108.171 221.594L107.257 222.192L106.933 221.736Z' fill='#5179E0' />
      <path d='M225.408 303.137L226.399 301.04L227.275 300.906L226.475 302.843L225.408 303.137Z' fill='#5179E0' />
      <path d='M380.036 524.569L378.065 523.676L379.855 522.686L381.693 523.471L380.036 524.569Z' fill='#5179E0' />
      <path d='M320.532 384.628L319.779 384.101L319.951 382.842L320.856 383.458L320.532 384.628Z' fill='#5179E0' />
      <path d='M139.19 121.581L140.418 122.348L140.047 123.036L138.98 122.232L139.19 121.581Z' fill='#5179E0' />
      <path d='M228.742 231.1L230.075 230.083L230.542 230.333L229.228 231.502L228.742 231.1Z' fill='#5179E0' />
      <path d='M354.246 487.979L353.036 488.354L353.103 487.247L354.456 486.89L354.246 487.979Z' fill='#5179E0' />
      <path
        d='M291.256 -1.59659L291.142 -0.168339L289.884 -0.221897L289.799 -2.13218L291.256 -1.59659Z'
        fill='#5179E0'
      />
      <path d='M336.256 -11.4693L337.132 -8.97882L336.122 -8.27363L335.389 -10.0589L336.256 -11.4693Z' fill='#5179E0' />
      <path d='M416.655 22.2194L416.608 22.1569L416.408 21.0411L416.969 20.5323L416.655 22.2194Z' fill='#5179E0' />
      <path d='M164.075 109.11L164.752 110.289L164.609 110.833L163.675 109.869L164.075 109.11Z' fill='#5179E0' />
      <path d='M246.789 0.760014L247.58 2.57211L246.475 3.13448L245.323 0.965332L246.789 0.760014Z' fill='#5179E0' />
      <path d='M40.3617 314.055L40.3522 312.26L40.8093 312.359L40.7808 314.162L40.3617 314.055Z' fill='#5179E0' />
      <path d='M221.599 37.0196L222.085 37.8498L221.808 38.671L221.256 37.8676L221.599 37.0196Z' fill='#5179E0' />
      <path d='M196.742 52.4268L196.961 53.1677V53.9711L196.542 53.248L196.742 52.4268Z' fill='#5179E0' />
      <path d='M226.028 352.555L226.885 349.841L227.866 350.502L227.228 352.93L226.028 352.555Z' fill='#5179E0' />
      <path d='M242.599 316.652L243.599 314.76L244.399 315.063L243.37 317.607L242.599 316.652Z' fill='#5179E0' />
      <path d='M335.132 452.327L333.951 451.978L334.541 450.988L336.341 451.55L335.132 452.327Z' fill='#5179E0' />
      <path d='M401.474 -26.0374L401.312 -25.4929L401.284 -28.8136L401.303 -29.2153L401.474 -26.0374Z' fill='#5179E0' />
      <path d='M155.495 394.483L155.047 393.108L156.199 393.242L156.704 394.474L155.495 394.483Z' fill='#5179E0' />
      <path d='M141.476 291.667L141.533 290.649L142.656 290.14L142.542 291.31L141.476 291.667Z' fill='#5179E0' />
      <path d='M111.761 386.788L111.057 385.404L111.657 385.074L112.304 386.44L111.761 386.788Z' fill='#5179E0' />
      <path d='M161.542 332.782L161.437 331.586L162.333 332.042L162.514 333.202L161.542 332.782Z' fill='#5179E0' />
      <path d='M58.6378 220.353L59.8283 219.719L60.1045 220.183L59.2283 220.942L58.6378 220.353Z' fill='#5179E0' />
      <path d='M165.38 458.709L164.447 457.665L165.437 457.058L166.418 458.218L165.38 458.709Z' fill='#5179E0' />
      <path d='M194.932 305.976L195.132 305.048L195.913 305.003L195.78 306.36L194.932 305.976Z' fill='#5179E0' />
      <path d='M243.732 13.0429L244.294 14.1945L243.608 14.98L242.77 13.7035L243.732 13.0429Z' fill='#5179E0' />
      <path d='M209.885 291.917L210.485 290.328L211.161 290.747L210.77 291.738L209.885 291.917Z' fill='#5179E0' />
      <path d='M119.514 135.827L120.761 136.488L120.609 137.095L119.295 136.533L119.514 135.827Z' fill='#5179E0' />
      <path d='M214.447 296.148L214.532 294.916L215.37 295.889L215.351 297.058L214.447 296.148Z' fill='#5179E0' />
      <path d='M456.198 440.133L454.293 440.749L454.836 439.758L456.769 439.097L456.198 440.133Z' fill='#5179E0' />
      <path d='M433.627 -29.9205L433.331 -29.2064L433.446 -32.1521L433.741 -33.0894L433.627 -29.9205Z' fill='#5179E0' />
      <path d='M206.199 10.1507L206.409 10.6417L206.37 11.9718L206.123 11.4719L206.199 10.1507Z' fill='#5179E0' />
      <path d='M219.866 7.95481L220.237 8.65108L220.066 9.88293L219.418 9.05277L219.866 7.95481Z' fill='#5179E0' />
      <path d='M234.77 48.4009L235.313 49.365L234.885 50.0256L234.485 49.0704L234.77 48.4009Z' fill='#5179E0' />
      <path d='M205.18 307.529L205.199 306.333L206.142 307.315L206.247 308.77L205.18 307.529Z' fill='#5179E0' />
      <path d='M144.114 356.125L143.485 355.304L143.999 355.036L144.695 355.822L144.114 356.125Z' fill='#5179E0' />
      <path d='M158.533 84.1071L159.295 84.9908L159.009 85.6246L158.39 84.7765L158.533 84.1071Z' fill='#5179E0' />
      <path d='M244.675 323.972L245.742 321.464L246.456 322.267L245.58 324.704L244.675 323.972Z' fill='#5179E0' />
      <path d='M148.628 32.4492L149.39 33.3508L148.98 34.3684L148.59 33.8328L148.628 32.4492Z' fill='#5179E0' />
      <path d='M143.866 210.462L144.923 210.382L145.104 210.935L144.095 211.033L143.866 210.462Z' fill='#5179E0' />
      <path d='M219.961 51.061L220.428 52.0519L220.123 52.6767L219.475 51.7394L219.961 51.061Z' fill='#5179E0' />
      <path d='M530.159 21.3625L528.598 22.273L528.15 21.3803L529.779 20.3805L530.159 21.3625Z' fill='#5179E0' />
      <path d='M384.208 482.864L383.16 483.569L383.198 482.596L383.979 481.829L384.208 482.864Z' fill='#5179E0' />
      <path d='M146.314 115.145L147.647 116.046L147.076 116.671L145.923 115.761L146.314 115.145Z' fill='#5179E0' />
      <path d='M146.923 214.72L147.914 214.684L148.104 215.372L147.19 215.309L146.923 214.72Z' fill='#5179E0' />
      <path d='M381.493 28.0217L380.57 28.7179L380.074 27.7449L380.874 27.1201L381.493 28.0217Z' fill='#5179E0' />
      <path d='M533.264 454.148L531.188 455.87L531.721 454.594L533.645 452.88L533.264 454.148Z' fill='#5179E0' />
      <path d='M336.17 -24.1361L336.856 -21.9134L335.979 -20.5565L335.046 -23.0649L336.17 -24.1361Z' fill='#5179E0' />
      <path d='M230.075 297.371L230.637 296.219L231.399 296.175L230.818 298.058L230.075 297.371Z' fill='#5179E0' />
      <path d='M327.579 -10.5945L328.132 -9.5055L327.627 -8.11295L327.037 -9.16629L327.579 -10.5945Z' fill='#5179E0' />
      <path d='M444.16 5.88385L444.141 5.89277L444.055 4.23245L444.122 4.09854L444.16 5.88385Z' fill='#5179E0' />
      <path d='M79.4377 172.98L81.0282 172.962L81.0568 173.569L79.7234 173.685L79.4377 172.98Z' fill='#5179E0' />
      <path d='M435.712 -4.41737L435.427 -3.93534L435.503 -5.98845L435.884 -6.74719L435.712 -4.41737Z' fill='#5179E0' />
      <path d='M141.533 49.3025L142.342 50.2219L141.942 51.07L141.428 50.3737L141.533 49.3025Z' fill='#5179E0' />
      <path d='M187.409 10.481L187.58 10.9809L187.571 12.3199L187.39 11.8289L187.409 10.481Z' fill='#5179E0' />
      <path d='M104.095 99.5232L105.266 100.309L105.019 101.094L103.942 100.487L104.095 99.5232Z' fill='#5179E0' />
      <path d='M169.733 108.164L170.714 109.333L170.028 109.78L168.923 108.655L169.733 108.164Z' fill='#5179E0' />
      <path d='M213.294 293.14L213.466 292.077L214.18 292.461L214.094 293.711L213.294 293.14Z' fill='#5179E0' />
      <path d='M192.837 104.308L193.418 105.62L192.637 105.906L191.866 104.62L192.837 104.308Z' fill='#5179E0' />
      <path d='M227.18 133.507L227.066 134.792L226.323 134.756L226.447 133.426L227.18 133.507Z' fill='#5179E0' />
      <path d='M96.2662 185.62L97.3043 185.602L97.2757 185.887L96.2948 185.995L96.2662 185.62Z' fill='#5179E0' />
      <path d='M543.245 1.77765L540.969 3.36657L540.512 2.42035L542.636 0.795727L543.245 1.77765Z' fill='#5179E0' />
      <path d='M422.465 -13.9152L421.95 -12.7994L422.112 -15.4863L422.693 -17.0127L422.465 -13.9152Z' fill='#5179E0' />
      <path d='M212.618 323.231L212.609 321.383L213.666 322.713L213.78 324.365L212.618 323.231Z' fill='#5179E0' />
      <path d='M210.513 366.346L210.704 363.722L211.866 364.365L211.79 366.043L210.513 366.346Z' fill='#5179E0' />
      <path d='M233.913 333.666L234.513 331.14L235.37 332.184L234.951 334.452L233.913 333.666Z' fill='#5179E0' />
      <path d='M244.094 299.103L244.923 297.317L245.608 297.621L244.904 299.415L244.094 299.103Z' fill='#5179E0' />
      <path d='M90.6662 184.432L91.7139 184.441L91.6853 184.736L90.6758 184.825L90.6662 184.432Z' fill='#5179E0' />
      <path d='M407.941 0.688614L407.684 1.37595L407.76 -1.2306L407.931 -1.93579L407.941 0.688614Z' fill='#5179E0' />
      <path d='M233.523 8.24045L232.561 9.46338L231.866 7.91018L232.618 7.09786L233.523 8.24045Z' fill='#5179E0' />
      <path d='M34.5237 317.714L34.457 315.938L34.9332 316.001L34.9998 317.786L34.5237 317.714Z' fill='#5179E0' />
      <path d='M241.189 302.745L241.761 300.986L242.447 301.7L241.989 303.486L241.189 302.745Z' fill='#5179E0' />
      <path d='M223.399 137.756L223.342 139.086L222.637 139.121L222.561 137.764L223.399 137.756Z' fill='#5179E0' />
      <path d='M112.866 230.52L113.79 230.19L114.047 230.841L113.238 231.029L112.866 230.52Z' fill='#5179E0' />
      <path d='M229.685 311.314L229.942 309.475L230.78 310.52L230.637 312.287L229.685 311.314Z' fill='#5179E0' />
      <path d='M257.599 148.217L257.627 149.44L257.513 150.315L257.418 149.101L257.599 148.217Z' fill='#5179E0' />
      <path d='M193.294 321.883L193.171 320.41L194.237 321.392L194.475 322.928L193.294 321.883Z' fill='#5179E0' />
      <path d='M191.856 340.504L191.675 338.745L192.866 339.772L193.113 341.272L191.856 340.504Z' fill='#5179E0' />
      <path d='M156.399 128.151L157.456 129.231L157.028 129.766L155.933 128.713L156.399 128.151Z' fill='#5179E0' />
      <path d='M427.341 8.36542L426.808 9.21344L426.903 7.37458L427.541 6.21413L427.341 8.36542Z' fill='#5179E0' />
      <path d='M155.456 322.142L155.18 321.223L155.818 321.098L156.152 321.99L155.456 322.142Z' fill='#5179E0' />
      <path d='M521.045 465.172L519.959 466.868L520.15 465.904L521.045 464.279V465.172Z' fill='#5179E0' />
      <path d='M213.418 346.449L213.504 344.012L214.618 345.199L214.628 346.931L213.418 346.449Z' fill='#5179E0' />
      <path d='M259.523 137.979L259.57 139.095L259.484 139.889L259.427 138.729L259.523 137.979Z' fill='#5179E0' />
      <path d='M184.561 123.982L185.294 125.303L184.552 125.526L183.732 124.214L184.561 123.982Z' fill='#5179E0' />
      <path d='M347.989 508.269L346.96 509.01V508.171L347.694 507.341L347.989 508.269Z' fill='#5179E0' />
      <path d='M187.952 126.963L188.694 128.249L187.99 128.677L187.247 127.356L187.952 126.963Z' fill='#5179E0' />
      <path d='M190.761 119.786L191.485 121.045L190.694 121.42L190.037 120.09L190.761 119.786Z' fill='#5179E0' />
      <path d='M159.323 375.532L158.914 373.996L160.218 374.514L160.714 375.978L159.323 375.532Z' fill='#5179E0' />
      <path d='M172.047 359.419L171.714 357.812L172.952 358.446L173.361 359.91L172.047 359.419Z' fill='#5179E0' />
      <path d='M468.617 29.923L467.769 30.6371L467.122 29.9587L468.407 28.9054L468.617 29.923Z' fill='#5179E0' />
      <path d='M80.7139 178.898L81.8663 179.014L81.8568 179.416L80.7139 179.38V178.898Z' fill='#5179E0' />
      <path d='M532.15 114.984L531.721 114.243L531.817 113.484L531.979 113.984L532.15 114.984Z' fill='#5179E0' />
      <path d='M88.7139 179.344L89.8091 179.505L89.7805 179.808L88.6948 179.719L88.7139 179.344Z' fill='#5179E0' />
      <path d='M144.371 349.511L144.056 348.324L144.98 348.395L145.437 349.788L144.371 349.511Z' fill='#5179E0' />
      <path d='M172.075 128.472L173.047 129.695L172.371 130.025L171.304 128.829L172.075 128.472Z' fill='#5179E0' />
      <path d='M171.98 289.542L172.171 288.427L173.171 287.623L172.952 289.042L171.98 289.542Z' fill='#5179E0' />
      <path d='M125.857 208.052L127.057 207.766L127.209 208.231L126.095 208.472L125.857 208.052Z' fill='#5179E0' />
      <path d='M486.207 457.227L484.36 458.129L484.931 456.879L486.96 455.87L486.207 457.227Z' fill='#5179E0' />
      <path d='M166.294 11.3112L166.742 12.1146L166.504 13.2482L166.304 12.6055L166.294 11.3112Z' fill='#5179E0' />
      <path d='M134.114 95.0153L135.19 95.9436L134.809 96.5417L133.723 95.6759L134.114 95.0153Z' fill='#5179E0' />
      <path d='M179.085 481.088L177.733 480.597L178.275 479.731L179.628 480.043L179.085 481.088Z' fill='#5179E0' />
      <path d='M123.609 198.974L124.695 199.072L124.809 199.482L123.771 199.375L123.609 198.974Z' fill='#5179E0' />
      <path d='M186.818 112.592L187.513 113.859L186.771 114.181L186.037 112.904L186.818 112.592Z' fill='#5179E0' />
      <path d='M216.199 125.232L216.285 126.535L215.628 126.517L215.532 125.205L216.199 125.232Z' fill='#5179E0' />
      <path d='M185.685 98.818L186.352 100.103L185.675 100.434L184.866 99.2018L185.685 98.818Z' fill='#5179E0' />
      <path d='M382.522 -30.4114L382.379 -28.9029L381.036 -28.4208L381.093 -30.7417L382.522 -30.4114Z' fill='#5179E0' />
      <path d='M177.818 371.515L177.352 369.578L178.647 370.149L179.009 371.292L177.818 371.515Z' fill='#5179E0' />
      <path d='M108.371 179.933L109.485 180.273V180.54L108.4 180.246L108.371 179.933Z' fill='#5179E0' />
      <path d='M189.847 307.529L189.885 306.387L190.599 306.342L190.637 307.404L189.847 307.529Z' fill='#5179E0' />
      <path d='M123.323 195.707L124.466 195.787L124.561 196.189L123.476 196.081L123.323 195.707Z' fill='#5179E0' />
      <path d='M28.0951 323.936L27.9332 322.142H28.4094L28.638 323.954L28.0951 323.936Z' fill='#5179E0' />
      <path d='M99.2472 157.653L100.914 157.938L100.828 158.545L99.2091 158.331L99.2472 157.653Z' fill='#5179E0' />
      <path d='M84.9806 179.71L86.1615 179.808L86.1996 180.192L84.9615 180.148L84.9806 179.71Z' fill='#5179E0' />
      <path d='M367.798 478.455L364.865 476.83L367.475 476.446L369.97 477.678L367.798 478.455Z' fill='#5179E0' />
      <path d='M198.075 272.939L198.628 271.591L199.494 270.939L198.98 272.546L198.075 272.939Z' fill='#5179E0' />
      <path d='M368.57 9.94543L367.941 11.2755L366.951 10.6149L367.427 9.56158L368.57 9.94543Z' fill='#5179E0' />
      <path d='M128.171 201.402L129.209 201.652L129.352 202.107L128.342 201.866L128.171 201.402Z' fill='#5179E0' />
      <path d='M73.5425 245.651L74.3425 245.035L74.6663 245.472L73.9139 246.168L73.5425 245.651Z' fill='#5179E0' />
      <path d='M149.98 339.906L149.447 339.147L150.076 338.79L150.628 339.629L149.98 339.906Z' fill='#5179E0' />
      <path d='M148.485 207.838L149.599 207.918L149.752 208.454L148.666 208.409L148.485 207.838Z' fill='#5179E0' />
      <path d='M149.818 347.493L149.237 346.654L149.79 346.404L150.456 347.092L149.818 347.493Z' fill='#5179E0' />
      <path d='M323.141 -4.98866L323.57 -4.20314L323.17 -3.1498L323.199 -3.64076L323.141 -4.98866Z' fill='#5179E0' />
      <path d='M62.7807 253.765L63.514 252.935L63.8759 253.283L63.1711 254.256L62.7807 253.765Z' fill='#5179E0' />
      <path d='M197.142 307.11L197.132 306.074L197.837 305.994L197.942 307.145L197.142 307.11Z' fill='#5179E0' />
      <path d='M128.828 456.058L127.733 454.924L128.618 454.281L129.79 455.397L128.828 456.058Z' fill='#5179E0' />
      <path d='M179.818 119.474L180.656 120.795L179.932 121.018L179.066 119.724L179.818 119.474Z' fill='#5179E0' />
      <path d='M88.4472 173.506L89.5805 173.854L89.5329 174.149L88.3996 173.863L88.4472 173.506Z' fill='#5179E0' />
      <path d='M187.866 499.816L186.532 498.995L187.475 498.039L188.894 498.87L187.866 499.816Z' fill='#5179E0' />
      <path d='M259.78 8.37436L259.98 9.57051L259.608 10.1954L258.875 8.99921L259.78 8.37436Z' fill='#5179E0' />
      <path d='M75.5044 195.876L77.0854 195.546L77.2377 196.055L75.8663 196.447L75.5044 195.876Z' fill='#5179E0' />
      <path d='M91.9424 95.7651L93.3996 96.8006L93.0567 97.5326L91.8281 96.8185L91.9424 95.7651Z' fill='#5179E0' />
      <path d='M179.99 337.326L179.713 335.755L180.828 336.398L181.266 338.103L179.99 337.326Z' fill='#5179E0' />
      <path d='M134.161 223.906L135.161 223.807L135.39 224.602L134.485 224.53L134.161 223.906Z' fill='#5179E0' />
      <path d='M178.39 106.575L179.19 107.825L178.447 108.146L177.666 106.896L178.39 106.575Z' fill='#5179E0' />
      <path d='M47.8284 264.869L48.4664 263.7L48.8569 263.968L48.2665 265.244L47.8284 264.869Z' fill='#5179E0' />
      <path d='M391.341 24.6385L391.532 25.9328L390.341 25.8436L390.589 24.7278L391.341 24.6385Z' fill='#5179E0' />
      <path d='M96.5615 221.236L97.771 220.576L97.9805 220.942L96.9424 221.602L96.5615 221.236Z' fill='#5179E0' />
      <path d='M193.266 120.045L193.628 121.795L193.371 122.33L192.79 120.777L193.266 120.045Z' fill='#5179E0' />
      <path d='M136.628 136.425L138.085 137.291L137.676 137.791L136.142 137.006L136.628 136.425Z' fill='#5179E0' />
      <path d='M129.752 205.026L130.742 205.383L130.914 205.919L129.961 205.544L129.752 205.026Z' fill='#5179E0' />
      <path d='M189.18 134.265L189.761 135.56L189.237 135.756L188.418 134.56L189.18 134.265Z' fill='#5179E0' />
      <path d='M175.771 400.704L174.952 398.901L176.218 398.883L176.799 399.794L175.771 400.704Z' fill='#5179E0' />
      <path d='M80.2949 187.869L81.5139 187.824L81.6092 188.217L80.2949 188.289V187.869Z' fill='#5179E0' />
      <path d='M86.1901 237.492L87.0472 237.117L87.352 237.67L86.552 238.063L86.1901 237.492Z' fill='#5179E0' />
      <path d='M106.828 149.556L108.542 150.021L108.352 150.61L106.685 150.226L106.828 149.556Z' fill='#5179E0' />
      <path d='M126.409 209.391L127.352 209.775L127.552 210.346L126.628 209.98L126.409 209.391Z' fill='#5179E0' />
      <path d='M86.9329 167.213L88.1425 167.579L88.0948 167.954L86.8567 167.651L86.9329 167.213Z' fill='#5179E0' />
      <path d='M605.178 473.563L604.312 476.41L604.854 475.107L605.035 472.849L605.178 473.563Z' fill='#5179E0' />
      <path d='M116.104 179.282L117.247 179.871L117.266 180.148L116.133 179.594L116.104 179.282Z' fill='#5179E0' />
      <path d='M487.283 30.5568L486.312 30.7264L486.093 29.6106L487.16 29.4231L487.283 30.5568Z' fill='#5179E0' />
      <path d='M113.285 63.826L114.466 65.0221L113.923 65.7541L112.819 64.7186L113.285 63.826Z' fill='#5179E0' />
      <path d='M156.152 213.926L157.199 214.131L157.371 214.765L156.333 214.658L156.152 213.926Z' fill='#5179E0' />
      <path d='M133.276 190.502L134.504 190.752L134.561 191.181L133.399 190.886L133.276 190.502Z' fill='#5179E0' />
      <path d='M26.257 283.66L26.6475 282.08L27.0475 282.24L26.7523 283.856L26.257 283.66Z' fill='#5179E0' />
      <path d='M346.827 -22.6364L347.217 -21.4224L346.494 -20.3423L345.979 -21.6188L346.827 -22.6364Z' fill='#5179E0' />
      <path d='M235.751 6.63367L235.066 7.73165L234.189 6.71402L234.923 5.30363L235.751 6.63367Z' fill='#5179E0' />
      <path d='M97.4281 230.931L98.3139 230.797L98.6091 231.386L97.7615 231.582L97.4281 230.931Z' fill='#5179E0' />
      <path d='M67.3902 206.392L68.6092 206.061L68.8092 206.463L67.514 206.82L67.3902 206.392Z' fill='#5179E0' />
      <path d='M201.399 420.798L200.542 419.031L201.656 418.272L202.304 419.227L201.399 420.798Z' fill='#5179E0' />
      <path d='M189.018 101.335L189.618 102.755L189.275 103.388L188.837 101.791L189.018 101.335Z' fill='#5179E0' />
      <path d='M139.085 20.0949L140.142 21.541L139.352 22.2908L138.609 21.2107L139.085 20.0949Z' fill='#5179E0' />
      <path d='M471.95 453.192L470.226 453.871L470.76 452.603L473.245 451.63L471.95 453.192Z' fill='#5179E0' />
      <path d='M358.379 -7.78267V-8.79138L358.094 -9.80007L358.675 -8.9699L358.379 -7.78267Z' fill='#5179E0' />
      <path d='M107.419 225.111L108.323 225.209L108.599 225.807L107.723 225.825L107.419 225.111Z' fill='#5179E0' />
      <path d='M129.561 162.268L131.266 162.696L131.028 163.214L129.371 162.83L129.561 162.268Z' fill='#5179E0' />
      <path d='M154.218 220.585L155.218 220.737L155.399 221.549L154.437 221.37L154.218 220.585Z' fill='#5179E0' />
      <path d='M90.0853 167.017L91.2758 167.615L91.2091 167.883L90.0186 167.356L90.0853 167.017Z' fill='#5179E0' />
      <path d='M124.742 214.399L125.638 214.899L125.876 215.523L124.98 215.149L124.742 214.399Z' fill='#5179E0' />
      <path d='M116.704 219.621L117.599 219.951L117.866 220.558L116.971 220.388L116.704 219.621Z' fill='#5179E0' />
      <path d='M120.123 180.978L121.276 181.719L121.323 181.995L120.161 181.335L120.123 180.978Z' fill='#5179E0' />
      <path d='M98.8662 177.845L100.59 177.854L100.609 178.38L98.9615 178.443L98.8662 177.845Z' fill='#5179E0' />
      <path d='M135.857 187.084L137.142 187.342L137.18 187.762L135.942 187.458L135.857 187.084Z' fill='#5179E0' />
      <path d='M92.59 159.974L93.9138 160.393L93.8567 160.822L92.4948 160.482L92.59 159.974Z' fill='#5179E0' />
      <path d='M140.999 38.9567L142.037 40.3313L141.257 40.9384L140.495 39.76L140.999 38.9567Z' fill='#5179E0' />
      <path d='M332.427 -10.416L332.646 -8.6664L331.475 -8.39861L331.265 -9.73759L332.427 -10.416Z' fill='#5179E0' />
      <path d='M172.418 348.734L171.971 347.011L173.199 347.645L173.828 349.52L172.418 348.734Z' fill='#5179E0' />
      <path d='M206.332 13.8285L205.885 15.3013L204.961 14.0784L205.351 12.4091L206.332 13.8285Z' fill='#5179E0' />
      <path d='M15.038 297.335L15.1618 295.577L15.5809 295.657L15.5523 297.442L15.038 297.335Z' fill='#5179E0' />
      <path d='M13.638 342.084L13.0857 340.218L13.5618 340.084L14.219 341.959L13.638 342.084Z' fill='#5179E0' />
      <path d='M173.961 116.876L174.885 118.171L174.247 118.421L173.228 117.153L173.961 116.876Z' fill='#5179E0' />
      <path d='M132.885 184.727L134.237 184.932V185.334L132.942 185.093L132.885 184.727Z' fill='#5179E0' />
      <path d='M87.4663 169.4L88.8282 169.641L88.8758 170.087L87.3901 169.909L87.4663 169.4Z' fill='#5179E0' />
      <path d='M133.218 157.537L134.961 158.046L134.676 158.554L132.98 158.09L133.218 157.537Z' fill='#5179E0' />
      <path d='M204.266 120.965L204.551 122.232L204.009 122.322L203.609 121.036L204.266 120.965Z' fill='#5179E0' />
      <path d='M69.6473 187.789L70.9902 187.994L70.9711 188.199L69.6378 188.074L69.6473 187.789Z' fill='#5179E0' />
      <path d='M177.752 473.75L176.466 472.759L177.59 471.992L178.933 472.92L177.752 473.75Z' fill='#5179E0' />
      <path d='M194.571 321.357L194.371 320.303L195.056 320L195.323 321.008L194.571 321.357Z' fill='#5179E0' />
      <path d='M333.408 -16.7003L333.865 -15.5845L333.227 -14.6025L332.884 -15.5666L333.408 -16.7003Z' fill='#5179E0' />
      <path d='M198.075 120.599L198.409 122.402L198.113 123.027L197.713 121.25L198.075 120.599Z' fill='#5179E0' />
      <path d='M123.257 183.513L124.428 184.361L124.495 184.638L123.314 183.906L123.257 183.513Z' fill='#5179E0' />
      <path d='M118.333 186.093L119.742 186.164L119.752 186.521L118.399 186.468L118.333 186.093Z' fill='#5179E0' />
      <path d='M226.37 63.8706L225.589 64.8615L224.894 64.3616L225.675 63.1386L226.37 63.8706Z' fill='#5179E0' />
      <path d='M211.228 46.6513L210.685 47.9278L209.847 47.3565L210.342 45.9015L211.228 46.6513Z' fill='#5179E0' />
      <path d='M371.274 -16.8699L370.627 -15.6112L369.436 -16.0933L370.236 -17.7268L371.274 -16.8699Z' fill='#5179E0' />
      <path d='M117.142 193.903L118.342 194.457L118.447 194.796L117.257 194.323L117.142 193.903Z' fill='#5179E0' />
      <path d='M66.7711 185.807L68.1425 186.102L68.1045 186.307L66.7521 186.102L66.7711 185.807Z' fill='#5179E0' />
      <path d='M75.3806 186.869L76.7616 187.146L76.752 187.342L75.3806 187.146V186.869Z' fill='#5179E0' />
      <path d='M77.333 203.035L78.6854 202.75L78.8187 203.098L77.4949 203.455L77.333 203.035Z' fill='#5179E0' />
      <path d='M120.733 190.27L121.923 190.967L122.028 191.288L120.828 190.699L120.733 190.27Z' fill='#5179E0' />
      <path d='M115.742 197.331L116.923 197.831L117.047 198.197L115.885 197.769L115.742 197.331Z' fill='#5179E0' />
      <path d='M123.552 186.753L124.733 187.575L124.818 187.887L123.618 187.173L123.552 186.753Z' fill='#5179E0' />
      <path d='M97.3329 192.966L98.7424 192.85L98.8091 193.189L97.4472 193.368L97.3329 192.966Z' fill='#5179E0' />
      <path d='M331.265 -18.9498L331.303 -17.1288L330.056 -16.7806L329.922 -18.5659L331.265 -18.9498Z' fill='#5179E0' />
      <path d='M194.485 44.6696L194.19 46.1961L193.228 45.7944L193.418 44.0983L194.485 44.6696Z' fill='#5179E0' />
      <path d='M115.057 200.821L116.219 201.321L116.361 201.714L115.219 201.295L115.057 200.821Z' fill='#5179E0' />
      <path d='M228.266 27.6378L227.666 26.4774L227.675 25.2723L228.151 26.5756L228.266 27.6378Z' fill='#5179E0' />
      <path d='M592.016 40.733L590.874 42.1524L589.921 41.3847L591.026 40.0636L592.016 40.733Z' fill='#5179E0' />
      <path d='M325.36 -3.76573L325.179 -1.74834L324.046 -1.98042L324.065 -3.49794L325.36 -3.76573Z' fill='#5179E0' />
      <path d='M134.78 109.137L136.19 110.217L135.628 110.69L134.342 109.735L134.78 109.137Z' fill='#5179E0' />
      <path d='M6.12379 309.368L5.95235 307.52L6.39997 307.538L6.63807 309.386L6.12379 309.368Z' fill='#5179E0' />
      <path d='M136.209 142.192L137.561 143.031L137.314 143.433L135.714 142.728L136.209 142.192Z' fill='#5179E0' />
      <path d='M128.018 155.903L129.799 156.403L129.561 156.894L127.752 156.483L128.018 155.903Z' fill='#5179E0' />
      <path d='M246.98 69.6818L245.999 70.6994L245.342 70.1638L246.294 69.1016L246.98 69.6818Z' fill='#5179E0' />
      <path d='M88.6853 230.395L89.971 229.52L90.2472 229.851L89.1805 230.708L88.6853 230.395Z' fill='#5179E0' />
      <path d='M498.998 467.591L498.083 468.939L498.112 467.903L499.312 466.341L498.998 467.591Z' fill='#5179E0' />
      <path d='M65.2092 182.477L66.6187 182.915L66.5711 183.12L65.1807 182.772L65.2092 182.477Z' fill='#5179E0' />
      <path d='M139.304 162.857L140.79 163.428L140.637 163.785L139.009 163.375L139.304 162.857Z' fill='#5179E0' />
      <path d='M82.133 185.611L83.5615 186.03L83.571 186.218L82.133 185.878V185.611Z' fill='#5179E0' />
      <path d='M189.694 12.1949V14.0248L188.428 13.8195L188.361 12.0521L189.694 12.1949Z' fill='#5179E0' />
      <path d='M109.076 152.69L110.323 153.538L110.199 153.814L108.904 153.065L109.076 152.69Z' fill='#5179E0' />
      <path d='M247.646 478.785L246.913 479.249L247.132 477.615L247.713 476.982L247.646 478.785Z' fill='#5179E0' />
      <path d='M592.016 155.403L591.883 154.466L591.702 151.529L592.102 152.815L592.016 155.403Z' fill='#5179E0' />
      <path d='M118.295 163.089L119.761 163.562L119.676 163.937L118.095 163.598L118.295 163.089Z' fill='#5179E0' />
      <path d='M7.31426 309.779L7.11425 307.949L7.57139 307.94L7.83807 309.788L7.31426 309.779Z' fill='#5179E0' />
      <path d='M147.809 142.138L148.876 143.254L148.628 143.522L147.447 142.549L147.809 142.138Z' fill='#5179E0' />
      <path d='M485.75 117.939L485.569 117.546L485.541 116.189L486.112 116.867L485.75 117.939Z' fill='#5179E0' />
      <path d='M97.8377 159.367L99.1043 160.358L99.0091 160.59L97.7329 159.652L97.8377 159.367Z' fill='#5179E0' />
      <path d='M85.9615 186.414L87.4282 186.878L87.4472 187.066L85.9806 186.691L85.9615 186.414Z' fill='#5179E0' />
      <path d='M163.866 116.841L164.952 118.055L164.39 118.367L163.209 117.198L163.866 116.841Z' fill='#5179E0' />
      <path d='M215.961 46.812L215.399 48.1688L214.628 47.5083L215.094 46.1961L215.961 46.812Z' fill='#5179E0' />
      <path d='M205.894 148.807L206.171 150.449L205.809 150.547L205.237 149.146L205.894 148.807Z' fill='#5179E0' />
      <path d='M137.58 340.629L136.714 339.709L137.19 339.147L138.18 339.95L137.58 340.629Z' fill='#5179E0' />
      <path d='M141.933 51.9626L142.799 53.5158L141.914 53.9175L141.009 52.4357L141.933 51.9626Z' fill='#5179E0' />
      <path d='M117.199 204.874L118.333 205.472L118.504 205.901L117.38 205.445L117.199 204.874Z' fill='#5179E0' />
      <path d='M87.0948 188.458L88.5615 188.878L88.5901 189.074L87.1329 188.744L87.0948 188.458Z' fill='#5179E0' />
      <path d='M138.237 405.4L137.333 404.007L138.504 403.739L139.533 405.114L138.237 405.4Z' fill='#5179E0' />
      <path d='M431.208 17.3455L430.646 16.9349L430.836 15.7745L431.15 16.1851L431.208 17.3455Z' fill='#5179E0' />
      <path d='M119.961 154.528L121.704 155.028L121.542 155.51L119.695 155.127L119.961 154.528Z' fill='#5179E0' />
      <path d='M29.5808 446.515L25.9808 445.775L26.1808 445.23L29.7618 445.944L29.5808 446.515Z' fill='#5179E0' />
      <path d='M216.942 16.8903L216.485 18.4524L215.523 17.7115L215.885 16.0958L216.942 16.8903Z' fill='#5179E0' />
      <path d='M124.542 202.759L126.304 202.25L126.418 202.651L124.742 203.214L124.542 202.759Z' fill='#5179E0' />
      <path d='M238.047 152.1L237.742 150.628L237.723 150.351L237.999 151.842L238.047 152.1Z' fill='#5179E0' />
      <path d='M137.885 335.505L136.98 334.577L137.609 333.934L138.647 334.862L137.885 335.505Z' fill='#5179E0' />
      <path d='M237.237 162.75L236.808 164.615L236.389 164.651L236.399 163.035L237.237 162.75Z' fill='#5179E0' />
      <path d='M147.361 32.5474L148.209 34.2881L147.152 34.6273L146.38 33.0919L147.361 32.5474Z' fill='#5179E0' />
      <path d='M89.8282 190.003L91.3044 190.431L91.352 190.636L89.8758 190.297L89.8282 190.003Z' fill='#5179E0' />
      <path d='M146.437 347.252L145.514 346.404L146.152 345.762L147.142 346.654L146.437 347.252Z' fill='#5179E0' />
      <path d='M114.685 209.186L115.799 209.703L115.98 210.176L114.895 209.802L114.685 209.186Z' fill='#5179E0' />
      <path d='M110.647 125.446L111.552 126.526L111.257 126.99L110.285 125.964L110.647 125.446Z' fill='#5179E0' />
      <path d='M122.695 335.755L121.742 334.8L122.152 334.202L123.19 334.978L122.695 335.755Z' fill='#5179E0' />
      <path d='M101.866 84.9997L103.419 86.3119L102.876 86.9278L101.381 85.812L101.866 84.9997Z' fill='#5179E0' />
      <path d='M373.789 30.8871L374.179 32.1368L372.808 32.0743L373.36 30.8424L373.789 30.8871Z' fill='#5179E0' />
      <path d='M104.961 219.058L106.533 218.228L106.752 218.612L105.38 219.398L104.961 219.058Z' fill='#5179E0' />
      <path d='M123.771 175.247L125.485 175.434L125.466 175.827L123.618 175.809L123.771 175.247Z' fill='#5179E0' />
      <path d='M553.121 1.06353L551.912 3.18803L550.093 3.61652L551.645 0.974255L553.121 1.06353Z' fill='#5179E0' />
      <path d='M66.333 177.693L67.8092 178.353L67.7425 178.541L66.2759 177.97L66.333 177.693Z' fill='#5179E0' />
      <path d='M110.133 213.453L111.228 213.836L111.438 214.301L110.361 214.113L110.133 213.453Z' fill='#5179E0' />
      <path d='M500.931 457.807L498.76 459.173L499.617 457.54L501.807 456.067L500.931 457.807Z' fill='#5179E0' />
      <path d='M161.961 46.4103L162.399 48.0974L161.39 48.2402L160.847 46.571L161.961 46.4103Z' fill='#5179E0' />
      <path d='M284.589 458.022L284.313 458.682L284.446 456.79L284.675 456.058L284.589 458.022Z' fill='#5179E0' />
      <path d='M103.58 217.63L104.676 217.8L104.895 218.255L103.828 218.282L103.58 217.63Z' fill='#5179E0' />
      <path d='M318.579 -8.6664V-7.03285L317.36 -6.51511L317.284 -8.56821L318.579 -8.6664Z' fill='#5179E0' />
      <path d='M362.075 -20.4851L362.294 -19.0301L361.198 -18.6373L361.017 -19.9674L362.075 -20.4851Z' fill='#5179E0' />
      <path d='M93.1043 191.636L94.5805 192.091L94.6472 192.297L93.171 191.949L93.1043 191.636Z' fill='#5179E0' />
      <path d='M6.84759 311.332L6.56187 309.511L7.0476 309.484L7.39045 311.323L6.84759 311.332Z' fill='#5179E0' />
      <path d='M292.38 151.824L291.656 151.44L291.742 151.065L292.484 151.413L292.38 151.824Z' fill='#5179E0' />
      <path d='M76.333 419.004L74.6092 418.147L75.0092 417.54L76.5997 418.37L76.333 419.004Z' fill='#5179E0' />
      <path d='M91.9615 194.43L93.4377 194.778L93.5043 194.984L92.0377 194.751L91.9615 194.43Z' fill='#5179E0' />
      <path d='M168.304 464.038L167.028 463.279L167.933 462.333L169.456 463.244L168.304 464.038Z' fill='#5179E0' />
      <path d='M191.894 29.2089L191.828 30.9228L190.799 30.3693L190.761 28.2984L191.894 29.2089Z' fill='#5179E0' />
      <path d='M1.36191 361.205L0.228576 359.437L0.733334 359.187L1.89523 360.919L1.36191 361.205Z' fill='#5179E0' />
      <path d='M213.875 6.99966L213.942 8.32972L213.894 9.09741L213.732 7.82091L213.875 6.99966Z' fill='#5179E0' />
      <path d='M93.5805 126.776L95.6758 127.633L95.39 128.249L93.5805 127.624V126.776Z' fill='#5179E0' />
      <path d='M98.7614 222.085L99.8376 222.076L100.076 222.522L99.0281 222.745L98.7614 222.085Z' fill='#5179E0' />
      <path d='M240.018 153.163L239.656 151.886L239.675 151.574L239.961 152.913L240.018 153.163Z' fill='#5179E0' />
      <path d='M172.618 28.468L172.904 30.4943L171.742 30.2087L171.361 28.3162L172.618 28.468Z' fill='#5179E0' />
      <path d='M129.695 146.396L130.837 147.673L130.685 147.869L129.457 146.753L129.695 146.396Z' fill='#5179E0' />
      <path d='M118.466 115.287L119.209 116.412L118.866 116.859L117.999 115.698L118.466 115.287Z' fill='#5179E0' />
      <path d='M196.266 58.4879L195.98 59.934L195.132 59.7198L195.313 58.1309L196.266 58.4879Z' fill='#5179E0' />
      <path d='M132.409 372.729L131.409 371.916L131.828 370.961L132.952 371.899L132.409 372.729Z' fill='#5179E0' />
      <path d='M252.666 172.712L251.885 174.676L251.351 174.747L251.837 172.935L252.666 172.712Z' fill='#5179E0' />
      <path d='M102.485 155.26L103.771 156.492L103.657 156.707L102.361 155.519L102.485 155.26Z' fill='#5179E0' />
      <path d='M93.5424 196.733L95.0186 197.055L95.1043 197.251L93.6377 197.081L93.5424 196.733Z' fill='#5179E0' />
      <path d='M117.409 126.821L118.409 127.865L118.095 128.258L117.057 127.294L117.409 126.821Z' fill='#5179E0' />
      <path d='M306.037 159.367L305.113 159.019L305.294 158.644L306.18 158.894L306.037 159.367Z' fill='#5179E0' />
      <path d='M207.704 31.5387L207.409 33.0562L206.456 32.592L206.675 30.8781L207.704 31.5387Z' fill='#5179E0' />
      <path d='M170.799 122.197L171.58 123.321L171.123 123.571L170.285 122.473L170.799 122.197Z' fill='#5179E0' />
      <path d='M259.484 532.987L258.627 534.147L258.427 533.496L259.17 532.246L259.484 532.987Z' fill='#5179E0' />
      <path d='M94.6662 226.7L95.7234 226.512L95.9805 226.932L94.9615 227.378L94.6662 226.7Z' fill='#5179E0' />
      <path d='M326.008 6.28554L326.113 7.66916L325.037 7.95481L324.894 6.72295L326.008 6.28554Z' fill='#5179E0' />
      <path d='M118.371 194.35L120.285 193.957L120.409 194.341L118.476 194.868L118.371 194.35Z' fill='#5179E0' />
      <path d='M289.732 145.763L288.999 145.522L289.046 145.129L289.799 145.334L289.732 145.763Z' fill='#5179E0' />
      <path d='M125.123 128.079L126.152 129.07L125.857 129.445L124.752 128.499L125.123 128.079Z' fill='#5179E0' />
      <path d='M579.712 5.51786L578.188 7.36566L576.588 7.13357L578.15 5.1965L579.712 5.51786Z' fill='#5179E0' />
      <path d='M112.047 191.002L113.933 190.726L114.047 191.109L112.123 191.538L112.047 191.002Z' fill='#5179E0' />
      <path d='M173.475 468.564L172.009 468.278L172.561 467.404L173.742 467.243L173.475 468.564Z' fill='#5179E0' />
      <path d='M193.313 5.81244L193.494 7.24961L193.361 8.01729L193.237 6.59797L193.313 5.81244Z' fill='#5179E0' />
      <path d='M566.578 150.556L565.178 150.146L564.569 148.682L565.578 148.807L566.578 150.556Z' fill='#5179E0' />
      <path d='M110.723 212.078L112.533 211.257L112.742 211.623L111.076 212.462L110.723 212.078Z' fill='#5179E0' />
      <path d='M260.161 13.7749L259.532 15.2032L258.437 14.7747L258.942 13.3018L260.161 13.7749Z' fill='#5179E0' />
      <path d='M115.685 203.366L117.599 202.723L117.761 203.098L115.923 203.83L115.685 203.366Z' fill='#5179E0' />
      <path d='M611.454 163.955L610.769 163.25L610.731 160.099L611.445 160.893L611.454 163.955Z' fill='#5179E0' />
      <path d='M325.97 544.172L324.437 544.27L324.903 542.726L326.837 542.627L325.97 544.172Z' fill='#5179E0' />
      <path d='M347.884 6.01775L346.913 5.98204L347.027 4.66092L347.656 4.78589L347.884 6.01775Z' fill='#5179E0' />
      <path d='M237.332 456.763L236.237 456.513L236.827 455.344L237.237 454.737L237.332 456.763Z' fill='#5179E0' />
      <path d='M98.5615 198.893L100.028 199.233L100.123 199.429L98.6662 199.286L98.5615 198.893Z' fill='#5179E0' />
      <path d='M301.322 487.64L300.913 488.693L301.046 487.631L301.246 486.453L301.322 487.64Z' fill='#5179E0' />
      <path d='M590.769 485.801L589.902 488.318L590.388 486.845L590.721 484.703L590.769 485.801Z' fill='#5179E0' />
      <path d='M148.504 121.402L149.942 122.527L149.418 122.902L148.009 121.893L148.504 121.402Z' fill='#5179E0' />
      <path d='M92.7234 231.573L93.7615 231.207L94.0377 231.609L93.0377 232.288L92.7234 231.573Z' fill='#5179E0' />
      <path d='M303.665 69.9942L303.113 69.3961L303.313 68.4767L303.465 69.2444L303.665 69.9942Z' fill='#5179E0' />
      <path d='M634.435 160.697L632.597 161.116L630.369 159.884L632.235 159.599L634.435 160.697Z' fill='#5179E0' />
      <path d='M175.475 361.571L174.856 359.705L175.961 359.723L176.79 361.749L175.475 361.571Z' fill='#5179E0' />
      <path d='M105.761 152.252L107.038 153.645L106.895 153.841L105.609 152.484L105.761 152.252Z' fill='#5179E0' />
      <path d='M117.342 75.6536L118.638 77.1265L117.971 77.6443L116.542 76.216L117.342 75.6536Z' fill='#5179E0' />
      <path d='M145.38 125.678L146.361 126.713L145.99 127.026L144.942 126.044L145.38 125.678Z' fill='#5179E0' />
      <path d='M73.7616 171.525L75.2854 172.48L75.2092 172.658L73.6758 171.766L73.7616 171.525Z' fill='#5179E0' />
      <path d='M167.475 60.1215L167.752 61.7372L166.866 61.7907L166.428 60.0501L167.475 60.1215Z' fill='#5179E0' />
      <path d='M273.256 173.908L272.389 173.256L272.618 172.935L273.446 173.506L273.256 173.908Z' fill='#5179E0' />
      <path d='M85.9996 236.206L87.0853 235.519L87.3615 235.885L86.3329 236.813L85.9996 236.206Z' fill='#5179E0' />
      <path d='M131.171 104.897L131.818 106.156L131.399 106.45L130.657 105.227L131.171 104.897Z' fill='#5179E0' />
      <path
        d='M315.875 -2.79274L315.732 -0.909245L314.56 -0.837833L314.541 -2.23037L315.875 -2.79274Z'
        fill='#5179E0'
      />
      <path d='M211.094 15.587L211.656 17.2563L210.551 17.6133L210.475 15.6941L211.094 15.587Z' fill='#5179E0' />
      <path d='M493.122 262.888L493.169 262.682L492.96 261.156L493.274 261.709L493.122 262.888Z' fill='#5179E0' />
      <path d='M102.619 201.687L104.076 201.982L104.18 202.187L102.733 202.107L102.619 201.687Z' fill='#5179E0' />
      <path d='M328.018 -15.9237L328.056 -14.3526L326.799 -13.7902L326.675 -15.4416L328.018 -15.9237Z' fill='#5179E0' />
      <path d='M516.912 10.3739L516.379 12.5788L514.941 13.3107L515.35 11.2755L516.912 10.3739Z' fill='#5179E0' />
      <path d='M311.218 5.05369L310.713 4.74125V3.38441L311.084 3.75933L311.218 5.05369Z' fill='#5179E0' />
      <path d='M515.407 230.6L515.036 229.895L515.074 228.833L515.217 229.181L515.407 230.6Z' fill='#5179E0' />
      <path d='M150.885 145.95L151.837 147.735L151.685 147.825L150.59 146.325L150.885 145.95Z' fill='#5179E0' />
      <path d='M238.466 24.4153L238.199 26.0667L238.38 26.5756L238.008 25.2098L238.466 24.4153Z' fill='#5179E0' />
      <path d='M77.2282 241.169L78.3425 240.179L78.6377 240.5L77.5997 241.651L77.2282 241.169Z' fill='#5179E0' />
      <path d='M139.152 66.745L140.037 68.3785L139.209 68.682L138.19 67.0574L139.152 66.745Z' fill='#5179E0' />
      <path d='M256.77 192.198L256.065 191.199L256.361 190.931L257.094 191.868L256.77 192.198Z' fill='#5179E0' />
      <path d='M265.608 472.831L265.065 473.572L265.056 472.161L265.77 471.545L265.608 472.831Z' fill='#5179E0' />
      <path d='M92.7424 126.267L95.0472 127.187L94.8091 127.82L92.6282 127.088L92.7424 126.267Z' fill='#5179E0' />
      <path d='M147.961 114.609L148.714 116.046L148.38 116.162L147.628 114.752L147.961 114.609Z' fill='#5179E0' />
      <path d='M300.951 42.4916L299.008 42.8843L298.77 41.7239L300.599 41.3222L300.951 42.4916Z' fill='#5179E0' />
      <path d='M396.779 526.756L393.827 526.952L395.389 525.426L397.008 525.042L396.779 526.756Z' fill='#5179E0' />
      <path d='M110.4 205.088L111.847 205.383L111.952 205.597L110.523 205.553L110.4 205.088Z' fill='#5179E0' />
      <path d='M139.152 32.6813L139.837 34.4488L139.037 35.2522L138.304 33.6722L139.152 32.6813Z' fill='#5179E0' />
      <path d='M71.1901 246.052L72.2568 244.856L72.5711 245.124L71.5901 246.472L71.1901 246.052Z' fill='#5179E0' />
      <path d='M3.39999 317.063L2.8857 315.278L3.39046 315.188L3.97141 316.991L3.39999 317.063Z' fill='#5179E0' />
      <path d='M107.066 109.164L108.114 110.467L107.695 110.94L106.561 109.682L107.066 109.164Z' fill='#5179E0' />
      <path d='M101.685 119.028L102.923 120.188L102.638 120.759L101.2 119.545L101.685 119.028Z' fill='#5179E0' />
      <path d='M236.266 141.469L236.142 140.416L236.075 140.014L236.227 141.014L236.266 141.469Z' fill='#5179E0' />
      <path d='M277.561 54.9798L277.427 56.6223L277.123 56.899L276.999 55.3369L277.561 54.9798Z' fill='#5179E0' />
      <path d='M115.314 150.012L116.533 151.681L116.38 151.842L115.123 150.226L115.314 150.012Z' fill='#5179E0' />
      <path d='M201.456 151.511L201.123 149.708L201.104 149.306L201.361 151.217L201.456 151.511Z' fill='#5179E0' />
      <path d='M81.8949 167.374L83.3996 168.57L83.3044 168.74L81.7711 167.588L81.8949 167.374Z' fill='#5179E0' />
      <path d='M278.17 490.978L277.742 492.219L277.923 491.336L278.037 489.872L278.17 490.978Z' fill='#5179E0' />
      <path d='M239.075 167.267L238.913 169.347L238.437 169.579L238.608 167.427L239.075 167.267Z' fill='#5179E0' />
      <path d='M100.866 338.781L99.6186 338.076L99.8091 337.487L101.095 338.085L100.866 338.781Z' fill='#5179E0' />
      <path d='M162.037 118.572L162.79 120.143L162.485 120.242L161.733 118.671L162.037 118.572Z' fill='#5179E0' />
      <path d='M407.065 8.31187L406.446 9.52588L405.427 9.06169L405.77 7.76735L407.065 8.31187Z' fill='#5179E0' />
      <path d='M545.674 9.51695L544.807 11.3915L543.112 11.7218L544.064 9.65977L545.674 9.51695Z' fill='#5179E0' />
      <path d='M518.817 291.819L518.864 291.649L518.502 289.819L519.074 290.533L518.817 291.819Z' fill='#5179E0' />
      <path d='M248.389 195.617L247.694 194.573L247.989 194.296L248.704 195.269L248.389 195.617Z' fill='#5179E0' />
      <path d='M189.428 15.8101L189.675 17.4526L188.599 17.6758L188.237 15.9887L189.428 15.8101Z' fill='#5179E0' />
      <path d='M194.485 70.6994L194.18 72.1187L193.447 71.9402L193.675 70.4316L194.485 70.6994Z' fill='#5179E0' />
      <path d='M117.78 209.328L119.209 209.561L119.323 209.784L117.885 209.846L117.78 209.328Z' fill='#5179E0' />
      <path d='M44.5427 202.803L46.3046 202.83L46.3141 202.937L44.5617 203.008L44.5427 202.803Z' fill='#5179E0' />
      <path d='M227.732 189.86L226.904 188.771L227.142 188.539L227.932 189.574L227.732 189.86Z' fill='#5179E0' />
      <path d='M39.8284 203.892L41.5903 203.955L41.5998 204.062L39.8474 204.106L39.8284 203.892Z' fill='#5179E0' />
      <path d='M67.9616 250.337L68.9711 248.971L69.3045 249.221L68.3902 250.703L67.9616 250.337Z' fill='#5179E0' />
      <path d='M49.3998 201.83L51.1712 201.839L51.1807 201.946L49.4284 202.036L49.3998 201.83Z' fill='#5179E0' />
      <path d='M299.284 -10.2018L299.237 -8.11295L297.97 -7.52381L297.884 -9.46979L299.284 -10.2018Z' fill='#5179E0' />
      <path d='M142.39 111.663L143.095 113.181L142.742 113.261L142.009 111.806L142.39 111.663Z' fill='#5179E0' />
      <path d='M149.352 362.258L148.59 361.062L149.39 360.598L150.409 362.017L149.352 362.258Z' fill='#5179E0' />
      <path d='M97.39 130.721L98.971 131.793L98.771 132.373L97.0281 131.32L97.39 130.721Z' fill='#5179E0' />
      <path d='M181.313 128.32L182.047 130.07L181.771 130.195L181.047 128.418L181.313 128.32Z' fill='#5179E0' />
      <path d='M273.513 196.439L272.846 195.537L273.218 195.153L273.904 195.983L273.513 196.439Z' fill='#5179E0' />
      <path d='M389.065 15.828L389.189 16.2743L388.989 17.9079L388.865 17.4616L389.065 15.828Z' fill='#5179E0' />
      <path d='M271.123 175.684L270.227 175.14L270.437 174.774L271.351 175.256L271.123 175.684Z' fill='#5179E0' />
      <path d='M120.057 212.854L121.514 212.899L121.628 213.122L120.171 213.372L120.057 212.854Z' fill='#5179E0' />
      <path d='M36.9713 203.892L38.7427 204.008V204.124L36.9903 204.106L36.9713 203.892Z' fill='#5179E0' />
      <path d='M119.247 135.827L121.133 136.693L120.847 137.193L118.942 136.443L119.247 135.827Z' fill='#5179E0' />
      <path d='M118.419 346.779L117.19 346.003L117.714 345.235L119.066 345.967L118.419 346.779Z' fill='#5179E0' />
      <path d='M259.094 183.495L258.342 182.754L258.551 182.406L259.361 183.12L259.094 183.495Z' fill='#5179E0' />
      <path d='M489.312 20.2824L489.331 21.8802L488.264 22.8443L488.198 21.291L489.312 20.2824Z' fill='#5179E0' />
      <path d='M494.474 188.637L494.445 188.485L494.226 185.95L494.998 187.262L494.474 188.637Z' fill='#5179E0' />
      <path d='M176.19 124.312L176.904 126.044L176.628 126.115L175.885 124.41L176.19 124.312Z' fill='#5179E0' />
      <path d='M122.695 216.336L124.199 216.193L124.304 216.425L122.799 216.854L122.695 216.336Z' fill='#5179E0' />
      <path d='M228.589 195.162L227.742 194.082L228.018 193.841L228.885 194.841L228.589 195.162Z' fill='#5179E0' />
      <path d='M59.4759 198.786L61.2664 198.884L61.2854 198.992H59.5045L59.4759 198.786Z' fill='#5179E0' />
      <path d='M241.504 150.726L241.189 149.905L241.199 149.512L241.437 150.369L241.504 150.726Z' fill='#5179E0' />
      <path d='M172.885 32.3957L173.275 34.0828L172.285 34.297L171.828 32.7259L172.885 32.3957Z' fill='#5179E0' />
      <path d='M328.57 552.42L327.532 553.179L327.218 551.84L328.894 551.117L328.57 552.42Z' fill='#5179E0' />
      <path d='M95.6377 339.968L94.3615 339.352L94.5043 338.647L95.8757 339.245L95.6377 339.968Z' fill='#5179E0' />
      <path d='M202.866 71.0029L202.437 72.3776L201.723 72.1544L202.075 70.7351L202.866 71.0029Z' fill='#5179E0' />
      <path d='M253.637 162.634L252.961 162.062L253.085 161.705L253.637 162.268V162.634Z' fill='#5179E0' />
      <path d='M64.6092 198.501L66.4092 198.59L66.4283 198.697L64.6568 198.706L64.6092 198.501Z' fill='#5179E0' />
      <path d='M115.152 326.918L113.914 326.15L114.342 325.552L115.733 326.213L115.152 326.918Z' fill='#5179E0' />
      <path d='M117.266 99.6571L118.076 101.014L117.58 101.416L116.638 100.041L117.266 99.6571Z' fill='#5179E0' />
      <path d='M248.046 158.197L247.532 157.537L247.599 157.171L248.008 157.849L248.046 158.197Z' fill='#5179E0' />
      <path d='M126.209 220.156L127.742 219.835L127.847 220.085L126.304 220.665L126.209 220.156Z' fill='#5179E0' />
      <path d='M538.397 240.045L537.988 239.509L537.721 238.197L538.388 238.795L538.397 240.045Z' fill='#5179E0' />
      <path d='M104.619 117.814L106.866 118.929L106.485 119.51L104.247 118.555L104.619 117.814Z' fill='#5179E0' />
      <path d='M63.5616 254.72L64.4759 253.193L64.8283 253.426L64.0283 255.023L63.5616 254.72Z' fill='#5179E0' />
      <path d='M65.5235 203.196L67.2473 203.008L67.2854 203.116L65.5997 203.401L65.5235 203.196Z' fill='#5179E0' />
      <path d='M519.931 145.102L519.131 143.87L519.588 142.576L519.607 142.853L519.931 145.102Z' fill='#5179E0' />
      <path d='M127.285 225.744L128.942 225.048L129.057 225.289L127.39 226.226L127.285 225.744Z' fill='#5179E0' />
      <path d='M127.018 223.174L128.618 222.665L128.723 222.915L127.133 223.673L127.018 223.174Z' fill='#5179E0' />
      <path d='M67.4854 199.197L69.2664 199.242L69.2949 199.349L67.533 199.402L67.4854 199.197Z' fill='#5179E0' />
      <path d='M67.6187 200.821L69.3806 200.768L69.4092 200.875L67.6854 201.027L67.6187 200.821Z' fill='#5179E0' />
      <path d='M588.15 162.25L587.121 161.572L586.912 158.902L587.835 159.456L588.15 162.25Z' fill='#5179E0' />
      <path d='M127.085 228.28L128.818 227.387L128.923 227.637L127.218 228.717L127.085 228.28Z' fill='#5179E0' />
      <path d='M66.4949 204.526L68.1902 204.258L68.2378 204.365L66.5902 204.731L66.4949 204.526Z' fill='#5179E0' />
      <path d='M88.4948 164.598L89.9329 165.963L89.8186 166.115L88.3329 164.794L88.4948 164.598Z' fill='#5179E0' />
      <path d='M176.409 9.30271L176.866 10.8292L175.952 11.4094L175.352 9.82937L176.409 9.30271Z' fill='#5179E0' />
      <path d='M224.056 154.912L224.266 156.974L223.913 157.171L223.694 155.046L224.056 154.912Z' fill='#5179E0' />
      <path d='M198.999 138.684L199.58 140.63L199.313 140.764L198.713 138.809L198.999 138.684Z' fill='#5179E0' />
      <path d='M448.96 31.2888L448.988 32.351L448.15 33.0562L447.998 31.744L448.96 31.2888Z' fill='#5179E0' />
      <path d='M37.5522 201.643L39.3522 201.893L39.3427 202L37.5522 201.857V201.643Z' fill='#5179E0' />
      <path d='M126.742 57.14L127.609 59.1039L126.838 59.5948L126.009 58.0594L126.742 57.14Z' fill='#5179E0' />
      <path d='M204.18 153.073L203.866 151.502L203.837 151.136L204.113 152.734L204.18 153.073Z' fill='#5179E0' />
      <path d='M70.3521 204.954L72.0187 204.66L72.0759 204.758L70.4568 205.169L70.3521 204.954Z' fill='#5179E0' />
      <path d='M182.818 47.2405L182.952 48.9008L182.018 48.874L181.799 47.3298L182.818 47.2405Z' fill='#5179E0' />
      <path d='M203.618 85.3568L203.19 86.7582L202.551 86.5797L202.885 85.1782L203.618 85.3568Z' fill='#5179E0' />
      <path d='M107.628 133.498L109.38 134.497L109.142 135.024L107.266 134.096L107.628 133.498Z' fill='#5179E0' />
      <path d='M238.161 350.377L238.17 348.136L239.085 347.154L239.208 349.145L238.161 350.377Z' fill='#5179E0' />
      <path d='M120.438 132.346L122.219 133.301L121.923 133.792L120.038 132.917L120.438 132.346Z' fill='#5179E0' />
      <path d='M178.675 155.734L179.237 158.171H179.094L178.294 156.153L178.675 155.734Z' fill='#5179E0' />
      <path d='M124.609 230.868L126.314 229.779L126.418 230.065L124.79 231.261L124.609 230.868Z' fill='#5179E0' />
      <path d='M208.942 360.919L208.647 358.821L209.618 358.286L210.075 360.401L208.942 360.919Z' fill='#5179E0' />
      <path d='M213.351 60.059L213.066 61.4604L212.218 61.3266L212.456 59.8715L213.351 60.059Z' fill='#5179E0' />
      <path d='M222.875 12.3467L222.532 11.5879L222.37 10.0258L222.656 10.8559L222.875 12.3467Z' fill='#5179E0' />
      <path d='M341.541 -22.8774L341.484 -21.3153L340.122 -20.9225L340.113 -22.5115L341.541 -22.8774Z' fill='#5179E0' />
      <path d='M75.0663 205.347L76.7044 205.035L76.7616 205.133L75.1711 205.57L75.0663 205.347Z' fill='#5179E0' />
      <path d='M363.474 55.9081L362.665 56.0063L362.713 55.0423L363.265 54.9441L363.474 55.9081Z' fill='#5179E0' />
      <path d='M471.645 26.4417L471.779 27.8878L470.855 28.7179L470.731 27.495L471.645 26.4417Z' fill='#5179E0' />
      <path d='M558.578 467.127L557.198 469.474L557.683 467.769L558.807 465.52L558.578 467.127Z' fill='#5179E0' />
      <path d='M244.085 153.752L243.713 153.073L243.732 152.681L244.027 153.377L244.085 153.752Z' fill='#5179E0' />
      <path d='M118.266 232.359L119.923 231.109L120.047 231.377L118.485 232.707L118.266 232.359Z' fill='#5179E0' />
      <path d='M90.1996 162.678L91.5805 164.133L91.4472 164.267L90.0091 162.857L90.1996 162.678Z' fill='#5179E0' />
      <path d='M606.321 157.715L605.102 156.876L604.731 154.082L605.759 154.662L606.321 157.715Z' fill='#5179E0' />
      <path d='M261.761 177.264L260.923 176.72L261.104 176.336L261.98 176.845L261.761 177.264Z' fill='#5179E0' />
      <path d='M78.8949 206.195L80.4949 205.838L80.5615 205.936L79.0092 206.427L78.8949 206.195Z' fill='#5179E0' />
      <path d='M221.666 182.031L220.913 181.049L221.056 180.781L221.742 181.745L221.666 182.031Z' fill='#5179E0' />
      <path d='M258.294 312.796L258.265 311.136L258.98 310.252L259.17 312.1L258.294 312.796Z' fill='#5179E0' />
      <path d='M109.523 366.98L108.114 366.435L108.228 365.82L109.609 366.23L109.523 366.98Z' fill='#5179E0' />
      <path d='M506.598 227.646L506.379 227.565L506.188 226.682L506.683 226.824L506.598 227.646Z' fill='#5179E0' />
      <path d='M284.303 9.40091L283.532 9.41876L283.503 8.01729L284.037 8.13334L284.303 9.40091Z' fill='#5179E0' />
      <path d='M55.3521 260.576L56.1045 258.889L56.4759 259.085L55.8474 260.781L55.3521 260.576Z' fill='#5179E0' />
      <path d='M67.3711 454.112L64.6568 454.487L64.7807 453.791L67.2568 453.532L67.3711 454.112Z' fill='#5179E0' />
      <path d='M170.752 119.126L169.418 119.929L169.152 119.563L170.447 118.671L170.752 119.126Z' fill='#5179E0' />
      <path d='M78.152 208.097L79.7044 207.615L79.7711 207.713L78.2854 208.32L78.152 208.097Z' fill='#5179E0' />
      <path d='M112.819 234.082L114.39 232.707L114.542 232.93L113.057 234.412L112.819 234.082Z' fill='#5179E0' />
      <path d='M134.952 151.19L135.98 153.234L135.79 153.332L134.695 151.386L134.952 151.19Z' fill='#5179E0' />
      <path d='M42.6379 197.037L44.4474 197.483L44.4284 197.581L42.6093 197.242L42.6379 197.037Z' fill='#5179E0' />
      <path d='M98.971 118.278L101.266 119.447L100.933 120.054L98.571 119.019L98.971 118.278Z' fill='#5179E0' />
      <path d='M233.17 332.541L233.037 330.72L233.799 329.908L234.027 331.515L233.17 332.541Z' fill='#5179E0' />
      <path d='M153.199 75.8768L153.609 77.3854L152.885 77.5818L152.314 75.9928L153.199 75.8768Z' fill='#5179E0' />
      <path d='M190.742 125.08L189.094 126.24L188.78 125.928L190.38 124.696L190.742 125.08Z' fill='#5179E0' />
      <path d='M399.389 41.3847L399.427 42.1256L399.208 42.9379L398.979 42.1077L399.389 41.3847Z' fill='#5179E0' />
      <path d='M79.6092 209.489L81.1139 208.909L81.1901 209.007L79.7425 209.712L79.6092 209.489Z' fill='#5179E0' />
      <path d='M268.265 135.89L267.713 135.917L267.694 135.488L268.17 135.435L268.265 135.89Z' fill='#5179E0' />
      <path d='M159.999 98.452L160.437 100.005L159.942 99.9963L159.39 98.5859L159.999 98.452Z' fill='#5179E0' />
      <path d='M223.409 198.251L222.542 197.215L222.828 196.938L223.723 197.885L223.409 198.251Z' fill='#5179E0' />
      <path d='M52.8283 263.388L53.4188 261.665L53.8093 261.816L53.3426 263.593L52.8283 263.388Z' fill='#5179E0' />
      <path d='M520.321 251.997L519.798 251.649L519.712 250.623L520.207 250.846L520.321 251.997Z' fill='#5179E0' />
      <path d='M321.151 166.678L319.979 166.49L320.103 166.115L321.313 165.946L321.151 166.678Z' fill='#5179E0' />
      <path d='M110.295 236.188L111.771 234.698L111.942 234.903L110.552 236.51L110.295 236.188Z' fill='#5179E0' />
      <path d='M243.97 323.722L243.837 322.097L244.542 321.214L244.808 322.865L243.97 323.722Z' fill='#5179E0' />
      <path d='M169.094 86.2405L169.19 87.7045L168.533 87.7402L168.323 86.2583L169.094 86.2405Z' fill='#5179E0' />
      <path d='M95.3424 160.679L96.6186 162.259L96.4662 162.375L95.1234 160.848L95.3424 160.679Z' fill='#5179E0' />
      <path d='M84.4187 210.301L85.8758 209.686L85.952 209.784L84.571 210.534L84.4187 210.301Z' fill='#5179E0' />
      <path d='M171.742 123.848L170.913 123.759L170.704 123.33L171.609 123.205L171.742 123.848Z' fill='#5179E0' />
      <path d='M153.371 338.219L152.209 337.514L152.723 336.996L153.961 337.656L153.371 338.219Z' fill='#5179E0' />
      <path d='M527.902 266.619L527.483 266.324L527.331 265.155L527.864 265.414L527.902 266.619Z' fill='#5179E0' />
      <path d='M303.103 -20.6726L302.989 -18.673L301.551 -17.8518L301.58 -19.9674L303.103 -20.6726Z' fill='#5179E0' />
      <path d='M211.513 96.5239L210.123 97.4433L209.742 96.997L211.085 95.9704L211.513 96.5239Z' fill='#5179E0' />
      <path d='M567.274 290.962L567.178 290.81L567.102 289.444L567.359 289.587L567.274 290.962Z' fill='#5179E0' />
      <path d='M237.494 196.965L236.789 195.992L237.047 195.653L237.78 196.564L237.494 196.965Z' fill='#5179E0' />
      <path d='M108.638 238.054L110.057 236.438L110.247 236.626L108.923 238.349L108.638 238.054Z' fill='#5179E0' />
      <path d='M84.2853 212.024L85.6853 211.283L85.771 211.382L84.4472 212.256L84.2853 212.024Z' fill='#5179E0' />
      <path d='M118.752 90.9716L119.828 92.4088L119.295 92.8997L117.942 91.3287L118.752 90.9716Z' fill='#5179E0' />
      <path d='M247.018 342.852L246.799 340.843L247.618 339.968L247.875 341.459L247.018 342.852Z' fill='#5179E0' />
      <path d='M208.151 344.869L207.847 343.36L208.589 342.539L209.094 344.28L208.151 344.869Z' fill='#5179E0' />
      <path d='M144.79 109.637L145.466 111.369L145.076 111.342L144.361 109.699L144.79 109.637Z' fill='#5179E0' />
      <path d='M384.284 19.3629L384.608 20.2645L384.141 21.3L384.179 20.8358L384.284 19.3629Z' fill='#5179E0' />
      <path d='M138.161 23.4334L138.704 25.3258L137.761 25.9864L137.104 24.1654L138.161 23.4334Z' fill='#5179E0' />
      <path d='M111.695 58.595L112.676 60.7999L111.952 61.4872L110.933 59.5412L111.695 58.595Z' fill='#5179E0' />
      <path d='M501.045 197.519L501.121 197.072L500.826 194.51L501.398 195.787L501.045 197.519Z' fill='#5179E0' />
      <path d='M49.5712 192.306L51.3712 192.957L51.3426 193.055L49.5141 192.502L49.5712 192.306Z' fill='#5179E0' />
      <path d='M103.409 111.271L105.39 112.565L105.019 113.163L102.809 111.895L103.409 111.271Z' fill='#5179E0' />
      <path d='M547.902 259.862L547.521 259.612L547.397 258.469L547.874 258.665L547.902 259.862Z' fill='#5179E0' />
      <path d='M606.54 168.65L604.683 168.695L603.083 167.213L604.178 166.847L606.54 168.65Z' fill='#5179E0' />
      <path d='M79.4949 214.604L80.8282 213.685L80.9234 213.774L79.6758 214.818L79.4949 214.604Z' fill='#5179E0' />
      <path d='M414.893 14.9354L414.684 15.096L414.779 16.9438L414.493 16.194L414.893 14.9354Z' fill='#5179E0' />
      <path d='M108.352 239.723L109.733 238.009L109.933 238.161L108.647 240L108.352 239.723Z' fill='#5179E0' />
      <path d='M262.123 198.063L261.418 197.197L261.761 196.769L262.465 197.545L262.123 198.063Z' fill='#5179E0' />
      <path d='M164.971 118.929L163.894 119.528L163.628 119.135L164.714 118.394L164.971 118.929Z' fill='#5179E0' />
      <path d='M483.674 157.073L483.264 156.823L483.588 154.564L484.341 155.51L483.674 157.073Z' fill='#5179E0' />
      <path d='M146.428 152.529L147.276 154.778L147.076 154.85L146.152 152.69L146.428 152.529Z' fill='#5179E0' />
      <path d='M274.056 185.754L273.303 185.191L273.542 184.682L274.38 185.28L274.056 185.754Z' fill='#5179E0' />
      <path d='M259.761 307.797L259.475 305.994L260.189 305.476L260.551 307.012L259.761 307.797Z' fill='#5179E0' />
      <path d='M48.7331 267.753L49.1712 265.941L49.5617 266.066L49.2664 267.895L48.7331 267.753Z' fill='#5179E0' />
      <path d='M103.666 158.965L104.8 160.679L104.628 160.777L103.419 159.108L103.666 158.965Z' fill='#5179E0' />
      <path d='M110.028 102.165L111.628 103.594L111.152 104.12L109.323 102.692L110.028 102.165Z' fill='#5179E0' />
      <path d='M83.1806 215.434L84.4568 214.452L84.552 214.533L83.3711 215.648L83.1806 215.434Z' fill='#5179E0' />
      <path d='M587.616 7.75842L586.835 9.57943L585.045 9.85616L586.007 7.69593L587.616 7.75842Z' fill='#5179E0' />
      <path d='M190.552 164.678L190.885 167.526L190.723 167.57L190.18 165.08L190.552 164.678Z' fill='#5179E0' />
      <path d='M193.399 71.119L193.171 72.5561L192.418 72.449L192.561 70.994L193.399 71.119Z' fill='#5179E0' />
      <path d='M174.018 126.053L173.342 125.794L173.171 125.303L173.894 125.419L174.018 126.053Z' fill='#5179E0' />
      <path d='M193.532 138.746L193.351 137.568L193.218 137.14L193.523 138.104L193.532 138.746Z' fill='#5179E0' />
      <path d='M109.371 241.58L110.657 239.75L110.866 239.893L109.685 241.839L109.371 241.58Z' fill='#5179E0' />
      <path d='M189.666 135.452L189.342 134.569L189.199 134.114L189.628 134.819L189.666 135.452Z' fill='#5179E0' />
      <path d='M90.1615 216.059L91.3615 215.05L91.4567 215.131L90.352 216.309L90.1615 216.059Z' fill='#5179E0' />
      <path d='M212.247 88.4543L211.885 89.9718L211.266 89.7308L211.504 88.3025L212.247 88.4543Z' fill='#5179E0' />
      <path d='M198.694 191.788L197.618 190.868L197.809 190.627L198.885 191.458L198.694 191.788Z' fill='#5179E0' />
      <path d='M109.638 242.544L110.933 240.616L111.152 240.741L109.971 242.749L109.638 242.544Z' fill='#5179E0' />
      <path d='M473.264 11.7486L473.455 13.0876L472.531 14.5694L472.293 13.1411L473.264 11.7486Z' fill='#5179E0' />
      <path d='M150.704 158.965L150.771 160.965L150.533 160.982L150.371 158.983L150.704 158.965Z' fill='#5179E0' />
      <path d='M498.741 13.1054L498.817 14.6319L497.75 15.8012L497.617 14.1766L498.741 13.1054Z' fill='#5179E0' />
      <path d='M556.512 277.947L556.197 277.866L556.102 276.706L556.521 276.742L556.512 277.947Z' fill='#5179E0' />
      <path d='M143.285 159.268L143.599 161.268L143.371 161.295L142.952 159.331L143.285 159.268Z' fill='#5179E0' />
      <path d='M280.189 189.976L279.599 189.342L279.827 188.789L280.532 189.458L280.189 189.976Z' fill='#5179E0' />
      <path d='M556.578 466.127L556.312 467.85L556.15 466.93L557.112 464.538L556.578 466.127Z' fill='#5179E0' />
      <path d='M206.713 154.466L206.504 153.252L206.447 152.868L206.666 154.064L206.713 154.466Z' fill='#5179E0' />
      <path d='M135.657 124.303L136.476 126.312L136.161 126.321L135.342 124.241L135.657 124.303Z' fill='#5179E0' />
      <path d='M58.7807 187.592L60.5331 188.449L60.4854 188.548L58.695 187.78L58.7807 187.592Z' fill='#5179E0' />
      <path d='M331.17 41.3757L330.932 41.3222L330.951 40.0189L330.96 40.2242L331.17 41.3757Z' fill='#5179E0' />
      <path d='M165.237 120.581L164.361 120.911L164.123 120.492L165.037 119.974L165.237 120.581Z' fill='#5179E0' />
      <path d='M390.522 425.172L390.503 425.413L390.57 423.217V422.905L390.522 425.172Z' fill='#5179E0' />
      <path d='M145.028 128.383L145.856 130.552L145.58 130.561L144.752 128.32L145.028 128.383Z' fill='#5179E0' />
      <path d='M429.284 7.63346L429.379 8.0887L429.141 9.82937L429.274 9.6687L429.284 7.63346Z' fill='#5179E0' />
      <path d='M254.104 11.8378L253.666 11.6325L253.618 9.98112L253.808 10.4364L254.104 11.8378Z' fill='#5179E0' />
      <path d='M90.9424 217.657L92.0853 216.505L92.1901 216.586L91.1329 217.889L90.9424 217.657Z' fill='#5179E0' />
      <path d='M156.399 60.059L156.704 61.7283L155.875 61.8622L155.495 60.3714L156.399 60.059Z' fill='#5179E0' />
      <path d='M109.238 243.294L110.476 241.285L110.704 241.393L109.59 243.437L109.238 243.294Z' fill='#5179E0' />
      <path d='M338.703 97.4165L337.332 97.4433L337.408 96.5774L338.722 96.381L338.703 97.4165Z' fill='#5179E0' />
      <path d='M122.304 158.429L123.104 160.313L122.904 160.375L122.009 158.537L122.304 158.429Z' fill='#5179E0' />
      <path d='M250.075 304.503L249.78 303.325L250.266 302.53L250.599 303.539L250.075 304.503Z' fill='#5179E0' />
      <path d='M155.428 153.6L156.104 155.93L155.866 156.01L155.133 153.698L155.428 153.6Z' fill='#5179E0' />
      <path d='M329.122 522.623L327.56 522.989L327.894 521.516L329.741 521.106L329.122 522.623Z' fill='#5179E0' />
      <path d='M240.666 313.921L240.418 312.814L240.875 311.814L241.246 312.974L240.666 313.921Z' fill='#5179E0' />
      <path d='M554.217 3.55402L553.54 5.64283L551.807 6.16057L552.397 4.16103L554.217 3.55402Z' fill='#5179E0' />
      <path d='M175.133 149.931L174.009 149.699L173.933 149.387L175.094 149.512L175.133 149.931Z' fill='#5179E0' />
      <path d='M4.35237 324.998L3.53332 323.294L4.0476 323.115L4.96189 324.82L4.35237 324.998Z' fill='#5179E0' />
      <path d='M217.551 175.925L216.913 175.131L216.999 174.81L217.542 175.604L217.551 175.925Z' fill='#5179E0' />
      <path d='M173.561 147.352L172.38 147.307L172.294 146.986L173.494 146.941L173.561 147.352Z' fill='#5179E0' />
      <path d='M265.532 350.644L265.208 349.234L265.837 347.797L266.237 349.109L265.532 350.644Z' fill='#5179E0' />
      <path d='M88.5424 219.612L89.6663 218.3L89.771 218.371L88.752 219.808L88.5424 219.612Z' fill='#5179E0' />
      <path d='M496.483 295.193L495.522 295.112L495.217 294.139L496.483 294.184V295.193Z' fill='#5179E0' />
      <path d='M166.485 154.903L166.904 157.242L166.628 157.314L166.171 154.93L166.485 154.903Z' fill='#5179E0' />
      <path d='M108.533 244.115L109.647 242.044L109.876 242.133L108.904 244.204L108.533 244.115Z' fill='#5179E0' />
      <path d='M136.695 61.7461L137.095 63.4778L136.295 63.8349L135.79 62.1567L136.695 61.7461Z' fill='#5179E0' />
      <path d='M428.808 67.0217L428.665 67.959L428.408 68.2803L428.169 67.2449L428.808 67.0217Z' fill='#5179E0' />
      <path d='M385.998 438.642L385.836 438.839L385.655 436.58L386.436 436.893L385.998 438.642Z' fill='#5179E0' />
      <path d='M181.675 160.34L182.199 163.33L182.037 163.437L181.418 160.634L181.675 160.34Z' fill='#5179E0' />
      <path
        d='M323.132 -2.43568L323.16 -0.918167L321.932 -0.677147L321.856 -1.95365L323.132 -2.43568Z'
        fill='#5179E0'
      />
      <path d='M69.7711 183.343L71.4282 184.406L71.3616 184.495L69.6568 183.513L69.7711 183.343Z' fill='#5179E0' />
      <path d='M530.493 290.221L529.54 290.06L529.617 289.087L530.169 288.998L530.493 290.221Z' fill='#5179E0' />
      <path d='M157.618 135.256L158.418 137.675L158.171 137.711L157.38 135.22L157.618 135.256Z' fill='#5179E0' />
      <path d='M161.418 70.6905L161.609 72.2169L160.856 72.3151L160.571 70.8422L161.418 70.6905Z' fill='#5179E0' />
      <path d='M171.885 151.833L172.58 154.716L172.409 154.859L171.694 151.984L171.885 151.833Z' fill='#5179E0' />
      <path d='M283.856 60.1482L283.437 59.8983L283.38 58.8807L283.751 59.1395L283.856 60.1482Z' fill='#5179E0' />
      <path d='M175.133 152.333L174.085 151.967L174.018 151.654L175.104 151.913L175.133 152.333Z' fill='#5179E0' />
      <path d='M142.161 156.751L142.333 158.715L142.095 158.751L141.837 156.742L142.161 156.751Z' fill='#5179E0' />
      <path d='M592.159 12.2842L591.026 13.9177L589.178 13.6589L590.493 11.8914L592.159 12.2842Z' fill='#5179E0' />
      <path d='M166.952 152.475L167.285 154.743L166.99 154.805L166.618 152.458L166.952 152.475Z' fill='#5179E0' />
      <path d='M229.485 50.0613L229.589 51.9001L229.237 52.4982L229.399 50.463L229.485 50.0613Z' fill='#5179E0' />
      <path d='M247.389 67.4948L247.237 69.4497L246.399 69.5925L246.761 67.468L247.389 67.4948Z' fill='#5179E0' />
      <path d='M117.895 136.899L118.59 138.702L118.285 138.764L117.533 136.863L117.895 136.899Z' fill='#5179E0' />
      <path d='M248.275 16.0869L248.18 17.899L247.332 17.6758L247.123 16.0601L248.275 16.0869Z' fill='#5179E0' />
      <path d='M88.0472 221.272L89.1139 219.835L89.2282 219.898L88.2663 221.442L88.0472 221.272Z' fill='#5179E0' />
      <path d='M111.876 412.737L110.342 412.371L110.19 411.265L111.895 411.684L111.876 412.737Z' fill='#5179E0' />
      <path d='M268.913 65.5399L268.513 65.2185L268.399 64.2723L268.865 64.5222L268.913 65.5399Z' fill='#5179E0' />
      <path d='M500.779 560.159L500.112 562.016L500.236 560.98L500.741 559.142L500.779 560.159Z' fill='#5179E0' />
      <path d='M130.695 139.71L131.409 141.656L131.123 141.719L130.371 139.666L130.695 139.71Z' fill='#5179E0' />
      <path d='M169.666 147.432L170.399 150.208L170.199 150.333L169.485 147.485L169.666 147.432Z' fill='#5179E0' />
      <path d='M155.056 134.828L153.685 135.792L153.466 135.524L154.809 134.471L155.056 134.828Z' fill='#5179E0' />
      <path d='M94.0662 135.497L94.6567 137.193L94.3234 137.247L93.6567 135.533L94.0662 135.497Z' fill='#5179E0' />
      <path d='M157.199 136.274L155.837 137.077L155.637 136.791L156.98 135.899L157.199 136.274Z' fill='#5179E0' />
      <path d='M184.466 131.971L184.066 131.498L183.913 131.016L184.39 131.328L184.466 131.971Z' fill='#5179E0' />
      <path d='M132.314 122.455L133.076 124.446L132.723 124.428L131.952 122.375L132.314 122.455Z' fill='#5179E0' />
      <path d='M107.409 135.631L108.038 137.372L107.723 137.425L107.019 135.631H107.409Z' fill='#5179E0' />
      <path d='M196.275 195.376L195.171 194.502L195.371 194.252L196.494 195.019L196.275 195.376Z' fill='#5179E0' />
      <path d='M208.932 157.608L208.751 156.555L208.704 156.171L208.875 157.215L208.932 157.608Z' fill='#5179E0' />
      <path d='M166.504 142.388L167.256 144.986L167.028 145.093L166.294 142.362L166.504 142.388Z' fill='#5179E0' />
      <path d='M249.637 368.364L249.256 367.06L249.866 365.525L250.389 366.846L249.637 368.364Z' fill='#5179E0' />
      <path d='M78.8473 180.371L80.3615 181.594L80.2853 181.683L78.7044 180.523L78.8473 180.371Z' fill='#5179E0' />
      <path d='M377.541 93.6406L376.217 93.8548L376.198 92.8104L377.484 92.5605L377.541 93.6406Z' fill='#5179E0' />
      <path d='M145.98 144.611L146.609 146.736L146.333 146.789L145.656 144.575L145.98 144.611Z' fill='#5179E0' />
      <path d='M107.38 244.91L108.314 242.812L108.561 242.874L107.771 244.963L107.38 244.91Z' fill='#5179E0' />
      <path d='M214.256 200.607L213.332 199.679L213.599 199.349L214.542 200.188L214.256 200.607Z' fill='#5179E0' />
      <path d='M43.9808 274.573L44.1998 272.68L44.6093 272.734L44.5331 274.626L43.9808 274.573Z' fill='#5179E0' />
      <path d='M175.542 154.886L174.552 154.439L174.504 154.118L175.513 154.484L175.542 154.886Z' fill='#5179E0' />
      <path d='M88.3805 222.772L89.3901 221.228L89.5044 221.281L88.5996 222.924L88.3805 222.772Z' fill='#5179E0' />
      <path d='M178.171 128.713L177.675 128.472L177.513 127.963L178.056 128.079L178.171 128.713Z' fill='#5179E0' />
      <path d='M147.228 108.771L147.837 110.663L147.418 110.574L146.761 108.744L147.228 108.771Z' fill='#5179E0' />
      <path d='M155.523 134.301L154.104 135.524L153.856 135.283L155.218 133.997L155.523 134.301Z' fill='#5179E0' />
      <path d='M179.533 104.317L178.409 105.656L177.952 105.361L179.009 103.96L179.533 104.317Z' fill='#5179E0' />
      <path d='M135.666 154.93L135.857 156.858L135.618 156.885L135.333 154.912L135.666 154.93Z' fill='#5179E0' />
      <path d='M194.532 186.834L193.466 186.093L193.599 185.834L194.647 186.494L194.532 186.834Z' fill='#5179E0' />
      <path d='M79.933 179.309L81.3615 180.594L81.2758 180.683L79.7711 179.442L79.933 179.309Z' fill='#5179E0' />
      <path d='M273.961 356.188L273.465 354.501L274.218 353.278L274.846 354.938L273.961 356.188Z' fill='#5179E0' />
      <path d='M253.818 339.602L253.313 338.129L253.98 337.237L254.342 338.103L253.818 339.602Z' fill='#5179E0' />
      <path d='M370.017 104.451L368.57 104.727L368.608 103.665L370.027 103.406L370.017 104.451Z' fill='#5179E0' />
      <path d='M149.875 69.173L150.056 70.7797L149.275 70.9226L148.99 69.3961L149.875 69.173Z' fill='#5179E0' />
      <path d='M326.418 -28.2869L325.818 -26.4302L324.284 -26.3856L324.818 -28.3316L326.418 -28.2869Z' fill='#5179E0' />
      <path d='M569.369 364.793L569.455 365.855L569.34 365.043L569.683 363.829L569.369 364.793Z' fill='#5179E0' />
      <path d='M84.552 350.841L83.0377 350.475V349.752L84.6282 350.064L84.552 350.841Z' fill='#5179E0' />
      <path d='M190.018 103.54L190.171 105.468L189.713 105.183L189.39 103.576L190.018 103.54Z' fill='#5179E0' />
      <path d='M294.78 231.627L294.361 230.752L294.77 229.94L295.265 230.824L294.78 231.627Z' fill='#5179E0' />
      <path d='M102.047 203.383L101.99 203.267L103.723 202.553L102.047 203.383Z' fill='#5179E0' />
      <path d='M340.456 523.587L339.732 524.596L339.779 523.391L340.37 522.32L340.456 523.587Z' fill='#5179E0' />
      <path d='M88.8282 224.191L89.771 222.549L89.8853 222.593L89.0567 224.316L88.8282 224.191Z' fill='#5179E0' />
      <path d='M102.276 204.071L102.209 203.964L103.885 203.142L102.276 204.071Z' fill='#5179E0' />
      <path d='M98.7234 203.017L98.6662 202.901L100.447 202.259L98.7234 203.017Z' fill='#5179E0' />
      <path d='M523.445 244.303L522.502 244.517L522.436 243.749L523.35 243.437L523.445 244.303Z' fill='#5179E0' />
      <path d='M269.894 340.816L269.465 339.531L270.065 338.362L270.589 339.584L269.894 340.816Z' fill='#5179E0' />
      <path d='M207.999 5.88385L208.151 7.62452L207.123 7.63346L206.828 5.96418L207.999 5.88385Z' fill='#5179E0' />
      <path d='M167.171 144.397L166.047 144.611L165.933 144.281L167.047 143.977L167.171 144.397Z' fill='#5179E0' />
      <path d='M106.095 245.606L106.857 243.481L107.104 243.535L106.504 245.588L106.095 245.606Z' fill='#5179E0' />
      <path d='M101.123 204.865L101.047 204.758L102.657 203.821L101.123 204.865Z' fill='#5179E0' />
      <path d='M94.7615 202.866L94.7138 202.759L96.5234 202.169L94.7615 202.866Z' fill='#5179E0' />
      <path d='M615.064 175.756L613.692 176.631L611.245 176.086L612.369 175.309L615.064 175.756Z' fill='#5179E0' />
      <path d='M215.809 171.9L215.313 171.194L215.37 170.837L215.732 171.569L215.809 171.9Z' fill='#5179E0' />
      <path d='M225.018 330.908L224.647 329.988L225.066 328.926L225.561 329.917L225.018 330.908Z' fill='#5179E0' />
      <path d='M90.9329 224.7L91.7901 222.995L91.9043 223.04L91.171 224.816L90.9329 224.7Z' fill='#5179E0' />
      <path d='M74.8854 179.675L76.2854 180.978L76.1997 181.067L74.7235 179.808L74.8854 179.675Z' fill='#5179E0' />
      <path d='M100.847 205.553L100.771 205.454L102.304 204.41L100.847 205.553Z' fill='#5179E0' />
      <path d='M446.855 30.8246L446.646 32.0743L445.512 31.985L445.712 30.8424L446.855 30.8246Z' fill='#5179E0' />
      <path d='M1.95237 387.627L0 386.583L0.4381 386.118L2.42856 387.109L1.95237 387.627Z' fill='#5179E0' />
      <path d='M374.874 -16.4236L374.598 -14.9953L373.284 -15.1024L373.455 -16.611L374.874 -16.4236Z' fill='#5179E0' />
      <path d='M232.332 15.7923L232.132 15.337L231.932 13.641L232.113 14.123L232.332 15.7923Z' fill='#5179E0' />
      <path d='M90.6567 202.892L90.6091 202.785L92.4567 202.259L90.6567 202.892Z' fill='#5179E0' />
      <path d='M100.961 206.169L100.876 206.07L102.333 204.928L100.961 206.169Z' fill='#5179E0' />
      <path d='M88.3139 135.756L88.7805 137.47L88.4377 137.506L87.8758 135.792L88.3139 135.756Z' fill='#5179E0' />
      <path d='M333.77 -9.63047L333.351 -8.11295L331.941 -8.1933L332.284 -9.68403L333.77 -9.63047Z' fill='#5179E0' />
      <path d='M88.1139 202.696L88.0758 202.589L89.9615 202.134L88.1139 202.696Z' fill='#5179E0' />
      <path d='M139.809 56.1581L140.057 57.8988L139.18 58.2201L138.828 56.4795L139.809 56.1581Z' fill='#5179E0' />
      <path d='M100.733 206.793L100.638 206.695L102.009 205.454L100.733 206.793Z' fill='#5179E0' />
      <path d='M193.332 199.268L192.209 198.402L192.418 198.135L193.571 198.884L193.332 199.268Z' fill='#5179E0' />
      <path d='M125.018 153.323L125.314 155.234L125.076 155.243L124.695 153.306L125.018 153.323Z' fill='#5179E0' />
      <path d='M87.2282 202.134L87.1901 202.027L89.1043 201.67L87.2282 202.134Z' fill='#5179E0' />
      <path d='M375.455 475.366L375.284 476.16L375.313 474.509L375.589 473.786L375.455 475.366Z' fill='#5179E0' />
      <path d='M653.74 170.4L651.845 170.712L649.245 168.284L651.045 168.133L653.74 170.4Z' fill='#5179E0' />
      <path d='M128.657 120.75L129.333 122.75L128.952 122.688L128.238 120.661L128.657 120.75Z' fill='#5179E0' />
      <path d='M211.818 459.611L210.418 459.12L211.047 457.915L212.494 458.325L211.818 459.611Z' fill='#5179E0' />
      <path d='M168.704 102.933L167.723 104.111L167.333 103.754L168.256 102.496L168.704 102.933Z' fill='#5179E0' />
      <path d='M92.3139 225.593L93.0948 223.816L93.2186 223.861L92.5615 225.691L92.3139 225.593Z' fill='#5179E0' />
      <path d='M576.531 138.291L576.093 137.309L575.978 135.149L576.559 136.158L576.531 138.291Z' fill='#5179E0' />
      <path d='M508.94 278.259L508.179 278.473L508.712 277.724L508.417 277.367L508.94 278.259Z' fill='#5179E0' />
      <path d='M507.95 46.9548L507.493 48.3384L507.598 49.1597L507.398 47.3208L507.95 46.9548Z' fill='#5179E0' />
      <path d='M308.38 450.434L306.332 449.952L307.332 448.818L309.189 449.113L308.38 450.434Z' fill='#5179E0' />
      <path d='M281.018 345.146L280.437 343.485L281.161 342.352L281.894 344.039L281.018 345.146Z' fill='#5179E0' />
      <path d='M226.57 199.822L225.828 198.974L226.047 198.581L226.808 199.358L226.57 199.822Z' fill='#5179E0' />
      <path d='M98.3424 207.686L98.2377 207.606L99.5233 206.249L98.3424 207.686Z' fill='#5179E0' />
      <path d='M260.646 373.871L260.218 372.854L260.742 371.175L261.57 372.791L260.646 373.871Z' fill='#5179E0' />
      <path d='M82.0472 202.696L82.0187 202.58L83.952 202.259L82.0472 202.696Z' fill='#5179E0' />
      <path d='M175.933 157.555L174.98 157.171L174.942 156.832L175.885 157.171L175.933 157.555Z' fill='#5179E0' />
      <path d='M446.027 -12.9868L446.236 -12.4959L445.865 -9.74651L445.684 -10.2375L446.027 -12.9868Z' fill='#5179E0' />
      <path d='M73.8092 179.442L75.133 180.799L75.0473 180.88L73.6378 179.576L73.8092 179.442Z' fill='#5179E0' />
      <path d='M214.704 168.338L214.361 167.615L214.39 167.231L214.618 167.99L214.704 168.338Z' fill='#5179E0' />
      <path d='M415.789 15.2746L416.141 16.5511L415.36 17.3277L415.312 16.4975L415.789 15.2746Z' fill='#5179E0' />
      <path d='M109.19 54.6049L110.085 56.8901L109.295 57.5149L108.247 55.2922L109.19 54.6049Z' fill='#5179E0' />
      <path d='M211.485 161.241L211.313 160.42L211.294 160.027L211.418 160.857L211.485 161.241Z' fill='#5179E0' />
      <path d='M235.704 113.948L234.427 115.314L233.856 115.002L235.275 113.324L235.704 113.948Z' fill='#5179E0' />
      <path d='M93.6662 226.53L94.371 224.691L94.4948 224.718L93.9138 226.601L93.6662 226.53Z' fill='#5179E0' />
      <path d='M77.552 203.249L77.5235 203.133L79.4758 202.866L77.552 203.249Z' fill='#5179E0' />
      <path d='M298.827 221.647L298.294 220.835L298.684 220.156L299.17 220.79L298.827 221.647Z' fill='#5179E0' />
      <path d='M423.627 5.83921L422.398 6.5087L422.789 4.91978L423.084 4.63414L423.627 5.83921Z' fill='#5179E0' />
      <path d='M76.7616 202.705L76.733 202.598L78.7044 202.419L76.7616 202.705Z' fill='#5179E0' />
      <path d='M394.332 506.68L394.532 508.019L394.265 506.743L394.789 505.645L394.332 506.68Z' fill='#5179E0' />
      <path d='M589.607 397.875L589.416 399.633L589.407 398.812L589.874 396.812L589.607 397.875Z' fill='#5179E0' />
      <path d='M115.085 152.252L115.428 154.136H115.199L114.752 152.225L115.085 152.252Z' fill='#5179E0' />
      <path d='M229.104 391.992L228.561 391.117L229.075 389.511L229.847 390.617L229.104 391.992Z' fill='#5179E0' />
      <path d='M482.779 94.6582L481.16 94.4708L481.245 93.471L481.96 93.2657L482.779 94.6582Z' fill='#5179E0' />
      <path d='M478.274 71.5474L477.007 71.9134L476.655 70.5745L477.884 70.2085L478.274 71.5474Z' fill='#5179E0' />
      <path d='M356.084 433.224L355.017 432.858L355.179 431.269L356.608 431.76L356.084 433.224Z' fill='#5179E0' />
      <path d='M94.8377 208.998L94.7329 208.936L95.9138 207.454L94.8377 208.998Z' fill='#5179E0' />
      <path d='M198.885 326.195L197.542 324.99L198.351 324.686L199.418 325.472L198.885 326.195Z' fill='#5179E0' />
      <path d='M73.8568 202.866L73.8378 202.759L75.8282 202.651L73.8568 202.866Z' fill='#5179E0' />
      <path d='M104.723 246.668L105.238 244.57L105.495 244.579L105.142 246.588L104.723 246.668Z' fill='#5179E0' />
      <path d='M531.017 339.799L530.845 340.468L530.35 339.593L531.512 338.763L531.017 339.799Z' fill='#5179E0' />
      <path d='M230.256 134.756L230.266 134.596L230.189 134.06L230.228 134.149L230.256 134.756Z' fill='#5179E0' />
      <path d='M557.607 139.728L556.902 138.693L556.988 136.89L557.436 137.577L557.607 139.728Z' fill='#5179E0' />
      <path d='M146.523 379.245L145.066 378.852L145.485 378.094L146.799 378.424L146.523 379.245Z' fill='#5179E0' />
      <path d='M523.864 320.669L522.779 321.124L522.588 320.196L523.883 319.643L523.864 320.669Z' fill='#5179E0' />
      <path d='M80.952 177.488L82.0949 178.943L81.9996 179.014L80.752 177.613L80.952 177.488Z' fill='#5179E0' />
      <path d='M94.79 227.039L95.39 225.164L95.5139 225.191L95.0377 227.092L94.79 227.039Z' fill='#5179E0' />
      <path d='M355.275 -16.5039L353.951 -15.3613L353.122 -16.37L354.189 -17.468L355.275 -16.5039Z' fill='#5179E0' />
      <path d='M148.266 15.2299L148.514 17.2027L147.39 17.5062L147.018 15.5602L148.266 15.2299Z' fill='#5179E0' />
      <path d='M466.322 -5.64923L466.436 -5.10471L466.074 -2.56064L465.674 -3.73895L466.322 -5.64923Z' fill='#5179E0' />
      <path d='M111.961 321.365L110.38 320.741L110.799 320.134L112.457 320.687L111.961 321.365Z' fill='#5179E0' />
      <path d='M44.5331 279.295L44.5617 277.367L44.9808 277.358L45.0855 279.277L44.5331 279.295Z' fill='#5179E0' />
      <path d='M191.932 183.129L190.894 182.594L190.971 182.317L191.999 182.781L191.932 183.129Z' fill='#5179E0' />
      <path d='M104.466 151.538L104.866 153.413L104.628 153.395L104.123 151.52L104.466 151.538Z' fill='#5179E0' />
      <path d='M145.666 107.379L146.199 109.271L145.733 109.182L145.161 107.245L145.666 107.379Z' fill='#5179E0' />
      <path d='M182.323 420.334L181.152 419.414L181.885 418.406L182.999 419.093L182.323 420.334Z' fill='#5179E0' />
      <path d='M89.4853 175.345L90.4186 176.898L90.3044 176.961L89.2663 175.443L89.4853 175.345Z' fill='#5179E0' />
      <path d='M475.874 0.760014L475.131 2.57211L473.512 2.68815L473.941 1.2242L475.874 0.760014Z' fill='#5179E0' />
      <path d='M66.8092 204.633L66.7902 204.526L68.7806 204.428L66.8092 204.633Z' fill='#5179E0' />
      <path d='M92.7043 210.221L92.59 210.168L93.6567 208.597L92.7043 210.221Z' fill='#5179E0' />
      <path d='M100.247 188.467L100.342 188.396L101.438 189.851L100.247 188.467Z' fill='#5179E0' />
      <path d='M96.0662 227.414L96.5519 225.503L96.6758 225.521L96.3234 227.449L96.0662 227.414Z' fill='#5179E0' />
      <path d='M191.771 86.8832L191.923 88.606L191.323 88.3739L191.075 86.794L191.771 86.8832Z' fill='#5179E0' />
      <path d='M94.9139 173.846L95.6472 175.479L95.5329 175.524L94.6853 173.935L94.9139 173.846Z' fill='#5179E0' />
      <path d='M185.799 129.052L184.732 130.82L184.333 130.552L185.323 128.758L185.799 129.052Z' fill='#5179E0' />
      <path d='M326.037 474.295L324.132 474.107L324.837 472.742L326.999 472.875L326.037 474.295Z' fill='#5179E0' />
      <path d='M144.314 66.7807L144.409 68.4588L143.58 68.6017L143.38 66.986L144.314 66.7807Z' fill='#5179E0' />
      <path d='M97.1234 189.494L97.2186 189.413L98.4567 190.779L97.1234 189.494Z' fill='#5179E0' />
      <path d='M146.218 133.596L145.028 135.14L144.733 134.917L145.828 133.399L146.218 133.596Z' fill='#5179E0' />
      <path d='M151.571 137.639L150.456 138.416L150.256 138.13L151.361 137.247L151.571 137.639Z' fill='#5179E0' />
      <path d='M131.742 199.786L131.666 199.893L130.171 198.947L131.742 199.786Z' fill='#5179E0' />
      <path d='M100.228 188.065L100.333 188.003L101.323 189.521L100.228 188.065Z' fill='#5179E0' />
      <path d='M261.304 192.797L260.894 192.181L260.951 191.645L261.532 192.252L261.304 192.797Z' fill='#5179E0' />
      <path d='M369.236 -28.6529L367.998 -27.5014L366.903 -28.4655L368.208 -29.733L369.236 -28.6529Z' fill='#5179E0' />
      <path d='M92.4186 210.828L92.3043 210.775L93.2567 209.141L92.4186 210.828Z' fill='#5179E0' />
      <path d='M484.512 300.245L482.96 300.656L483.217 299.861L484.179 299.379L484.512 300.245Z' fill='#5179E0' />
      <path d='M130.476 200.643L130.39 200.75L128.971 199.715L130.476 200.643Z' fill='#5179E0' />
      <path d='M92.8567 211.016L92.7329 210.971L93.571 209.293L92.8567 211.016Z' fill='#5179E0' />
      <path d='M451.941 33.4668L451.103 33.5918L450.922 32.36L451.931 32.1725L451.941 33.4668Z' fill='#5179E0' />
      <path d='M96.971 173.015L97.571 174.694L97.4567 174.738L96.7424 173.096L96.971 173.015Z' fill='#5179E0' />
      <path d='M85.2663 135.997L85.5806 137.738L85.2282 137.756L84.7901 136.024L85.2663 135.997Z' fill='#5179E0' />
      <path d='M99.7234 187.798L99.8376 187.735L100.723 189.315L99.7234 187.798Z' fill='#5179E0' />
      <path d='M96.6472 227.744L97.0091 225.816L97.1329 225.825L96.9043 227.753L96.6472 227.744Z' fill='#5179E0' />
      <path d='M59.1426 207.052L59.1331 206.945L61.1235 206.847L59.1426 207.052Z' fill='#5179E0' />
      <path d='M236.342 309.225L235.742 308.351L236.18 307.681L236.837 308.502L236.342 309.225Z' fill='#5179E0' />
      <path d='M130.447 199.268L130.38 199.375L128.78 198.554L130.447 199.268Z' fill='#5179E0' />
      <path d='M269.008 19.6664L267.894 21.2375L266.932 20.4073L267.875 18.9523L269.008 19.6664Z' fill='#5179E0' />
      <path d='M130.857 198.492L130.79 198.599L129.133 197.885L130.857 198.492Z' fill='#5179E0' />
      <path d='M92.7615 211.605L92.6472 211.569L93.3615 209.837L92.7615 211.605Z' fill='#5179E0' />
      <path d='M98.3805 187.708L98.4948 187.655L99.2948 189.279L98.3805 187.708Z' fill='#5179E0' />
      <path d='M116.999 208.329L116.876 208.373L116.504 206.633L116.999 208.329Z' fill='#5179E0' />
      <path d='M130.971 197.742L130.914 197.849L129.18 197.251L130.971 197.742Z' fill='#5179E0' />
      <path d='M210.047 351.448L209.57 350.877L209.913 349.698L210.57 350.43L210.047 351.448Z' fill='#5179E0' />
      <path d='M213.913 165.33L213.723 164.714L213.713 164.321L213.847 164.946L213.913 165.33Z' fill='#5179E0' />
      <path d='M88.0948 191.895L88.1805 191.806L89.6282 193.02L88.0948 191.895Z' fill='#5179E0' />
      <path d='M105.704 151.315L105.942 153.19L105.704 153.154L105.352 151.27L105.704 151.315Z' fill='#5179E0' />
      <path d='M131.133 196.965L131.085 197.081L129.295 196.59L131.133 196.965Z' fill='#5179E0' />
      <path d='M52.7045 209.302L52.695 209.195L54.695 209.114L52.7045 209.302Z' fill='#5179E0' />
      <path d='M333.57 338.058L333.075 336.469L333.694 334.889L333.979 335.719L333.57 338.058Z' fill='#5179E0' />
      <path d='M369.608 16.6492L369.532 16.6046L369.113 15.4442L369.903 15.0693L369.608 16.6492Z' fill='#5179E0' />
      <path d='M97.1424 187.646L97.2567 187.601L97.971 189.262L97.1424 187.646Z' fill='#5179E0' />
      <path d='M92.9996 212.14L92.8853 212.114L93.4948 210.337L92.9996 212.14Z' fill='#5179E0' />
      <path d='M116.961 208.07L116.838 208.132L116.333 206.436L116.961 208.07Z' fill='#5179E0' />
      <path d='M97.7424 228.128L97.99 226.191L98.1138 226.2L98.0091 228.11L97.7424 228.128Z' fill='#5179E0' />
      <path d='M278.208 338.326L277.323 336.71L278.018 335.782L279.056 337.406L278.208 338.326Z' fill='#5179E0' />
      <path d='M130.352 196.331L130.314 196.447L128.466 196.09L130.352 196.331Z' fill='#5179E0' />
      <path d='M104.819 247.748L105.066 245.695L105.323 245.668L105.219 247.614L104.819 247.748Z' fill='#5179E0' />
      <path d='M285.627 488.863L284.256 489.184L284.475 487.917L285.942 487.542L285.627 488.863Z' fill='#5179E0' />
      <path d='M91.8472 173.48L92.4186 175.167L92.3043 175.202L91.6091 173.542L91.8472 173.48Z' fill='#5179E0' />
      <path d='M562.283 258.54L562.388 259.13L562.207 258.353L562.578 257.585L562.283 258.54Z' fill='#5179E0' />
      <path d='M47.5998 211.123L47.5903 211.016L49.5998 210.971L47.5998 211.123Z' fill='#5179E0' />
      <path d='M354.389 36.7072L354.589 37.948L353.532 37.9212L353.798 36.5286L354.389 36.7072Z' fill='#5179E0' />
      <path d='M158.885 142.326L157.895 142.844L157.733 142.522L158.723 141.906L158.885 142.326Z' fill='#5179E0' />
      <path d='M236.875 318.473L236.542 318.036L236.77 316.982L237.113 317.304L236.875 318.473Z' fill='#5179E0' />
      <path d='M113.628 209.23L113.504 209.275L113.2 207.499L113.628 209.23Z' fill='#5179E0' />
      <path d='M97.971 187.28L98.0853 187.235L98.6757 188.94L97.971 187.28Z' fill='#5179E0' />
      <path d='M567.788 339.272L567.407 340.227L567.397 339.281L567.874 338.254L567.788 339.272Z' fill='#5179E0' />
      <path d='M276.989 240.045L276.684 239.268L276.894 238.384L277.399 239.268L276.989 240.045Z' fill='#5179E0' />
      <path d='M44.3426 212.078L44.3331 211.962L46.3522 211.989L44.3426 212.078Z' fill='#5179E0' />
      <path d='M93.4948 212.497L93.371 212.48L93.8662 210.676L93.4948 212.497Z' fill='#5179E0' />
      <path d='M98.171 187.057L98.2853 187.021L98.7614 188.762L98.171 187.057Z' fill='#5179E0' />
      <path d='M44.5141 211.167V211.051L46.5331 211.194L44.5141 211.167Z' fill='#5179E0' />
      <path d='M501.407 5.98204L501.579 7.35674L500.741 8.82961L500.483 7.3121L501.407 5.98204Z' fill='#5179E0' />
      <path d='M345.57 351.216L345.227 349.859L345.722 347.52L346.256 349.029L345.57 351.216Z' fill='#5179E0' />
      <path d='M222.589 131.605L222.561 131.641L222.428 131.141L222.57 130.945L222.589 131.605Z' fill='#5179E0' />
      <path d='M68.0378 475.411L65.2759 477.785L65.133 477.196L67.8283 474.946L68.0378 475.411Z' fill='#5179E0' />
      <path d='M268.742 337.21L268.046 336.058L268.637 335.166L269.256 335.987L268.742 337.21Z' fill='#5179E0' />
      <path d='M116.057 207.954L115.933 208.016L115.276 206.383L116.057 207.954Z' fill='#5179E0' />
      <path d='M45.5998 209.811L45.6093 209.703L47.6188 209.971L45.5998 209.811Z' fill='#5179E0' />
      <path d='M176.285 160.795L175.361 160.572L175.323 160.241L176.228 160.411L176.285 160.795Z' fill='#5179E0' />
      <path d='M233.666 499.985L232.304 500.244L232.589 498.932L233.875 498.566L233.666 499.985Z' fill='#5179E0' />
      <path d='M93.9234 212.694L93.7996 212.685L94.171 210.855L93.9234 212.694Z' fill='#5179E0' />
      <path d='M98.0662 186.932L98.19 186.905L98.5424 188.664L98.0662 186.932Z' fill='#5179E0' />
      <path d='M73.0854 196.394L73.152 196.305L74.8378 197.287L73.0854 196.394Z' fill='#5179E0' />
      <path d='M116.923 207.418L116.809 207.481L116.038 205.91L116.923 207.418Z' fill='#5179E0' />
      <path d='M123.904 202.393L123.818 202.491L122.399 201.446L123.904 202.393Z' fill='#5179E0' />
      <path d='M169.494 192.841L168.018 192.386L168.114 192.181L169.618 192.52L169.494 192.841Z' fill='#5179E0' />
      <path d='M100.247 186.53L100.371 186.512L100.571 188.306L100.247 186.53Z' fill='#5179E0' />
      <path d='M103.009 186.137L103.133 186.128L103.152 187.932L103.009 186.137Z' fill='#5179E0' />
      <path d='M47.7331 207.998L47.7522 207.891L49.7331 208.293L47.7331 207.998Z' fill='#5179E0' />
      <path d='M103.361 151.208L103.504 153.065L103.257 153.02L103.009 151.145L103.361 151.208Z' fill='#5179E0' />
      <path d='M92.8948 212.765H92.7615L92.9805 210.926L92.8948 212.765Z' fill='#5179E0' />
      <path d='M108.952 210.301L108.828 210.346L108.561 208.543L108.952 210.301Z' fill='#5179E0' />
      <path d='M109.6 48.9722L110.285 51.3378L109.399 51.918L108.561 49.5971L109.6 48.9722Z' fill='#5179E0' />
      <path d='M99.1995 228.538L99.3234 226.601H99.4472L99.4567 228.485L99.1995 228.538Z' fill='#5179E0' />
      <path d='M135.047 121.482L135.523 123.562L135.114 123.428L134.58 121.331L135.047 121.482Z' fill='#5179E0' />
      <path d='M106.59 210.864L106.457 210.9L106.285 209.07L106.59 210.864Z' fill='#5179E0' />
      <path d='M81.533 174.997L82.1615 176.675L82.0472 176.72L81.2949 175.06L81.533 174.997Z' fill='#5179E0' />
      <path d='M52.3998 205.151L52.4379 205.044L54.3712 205.615L52.3998 205.151Z' fill='#5179E0' />
      <path d='M103.742 186.048H103.866L103.761 187.86L103.742 186.048Z' fill='#5179E0' />
      <path d='M91.3805 212.89H91.2567L91.3044 211.042L91.3805 212.89Z' fill='#5179E0' />
      <path d='M116.961 207.008L116.847 207.088L115.961 205.588L116.961 207.008Z' fill='#5179E0' />
      <path d='M227.161 331.247L226.894 331.06L227.047 329.738L227.456 330.078L227.161 331.247Z' fill='#5179E0' />
      <path d='M59.9807 201.42L60.0283 201.321L61.8759 202.071L59.9807 201.42Z' fill='#5179E0' />
      <path d='M304.427 167.267L303.141 167.168L303.122 166.704L304.351 166.472L304.427 167.267Z' fill='#5179E0' />
      <path d='M144.047 105.691L144.428 107.521L143.904 107.432L143.485 105.504L144.047 105.691Z' fill='#5179E0' />
      <path d='M103.447 211.471L103.323 211.489L103.219 209.65L103.447 211.471Z' fill='#5179E0' />
      <path d='M343.398 -31.3844L342.684 -29.7062L341.027 -29.8491L341.76 -31.6522L343.398 -31.3844Z' fill='#5179E0' />
      <path d='M104.19 186.048L104.314 186.057L104.076 187.86L104.19 186.048Z' fill='#5179E0' />
      <path d='M59.9807 179.764L60.6569 181.442L60.5426 181.487L59.7521 179.826L59.9807 179.764Z' fill='#5179E0' />
      <path d='M92.4662 212.854L92.3424 212.863L92.2853 211.016L92.4662 212.854Z' fill='#5179E0' />
      <path d='M126.352 196.278L126.323 196.394L124.418 196.215L126.352 196.278Z' fill='#5179E0' />
      <path d='M248.446 16.6671L248.713 18.14L248.361 18.6667L248.418 17.1224L248.446 16.6671Z' fill='#5179E0' />
      <path d='M105.476 186.066L105.59 186.075L105.219 187.869L105.476 186.066Z' fill='#5179E0' />
      <path d='M97.3424 212.346L97.2091 212.364L97.1234 210.516L97.3424 212.346Z' fill='#5179E0' />
      <path d='M170.456 107.316L169.999 108.976L169.456 108.798L169.837 107.013L170.456 107.316Z' fill='#5179E0' />
      <path d='M449.665 -11.3444L447.15 -9.46979L446.36 -10.416L447.541 -11.7371L449.665 -11.3444Z' fill='#5179E0' />
      <path d='M150.39 49.8649L150.428 51.5788L149.466 51.7394L149.323 49.9988L150.39 49.8649Z' fill='#5179E0' />
      <path d='M116.314 206.713L116.209 206.793L115.18 205.383L116.314 206.713Z' fill='#5179E0' />
      <path d='M72.2949 176.809L72.9425 178.478L72.8282 178.523L72.0663 176.863L72.2949 176.809Z' fill='#5179E0' />
      <path d='M348.008 490.711L347.151 491.237L346.894 489.756L348.741 489.363L348.008 490.711Z' fill='#5179E0' />
      <path d='M251.951 237.286L251.608 236.385L251.77 235.742L251.989 236.519L251.951 237.286Z' fill='#5179E0' />
      <path d='M106.438 186.182L106.552 186.2L106.047 187.985L106.438 186.182Z' fill='#5179E0' />
      <path d='M168.152 190.208L166.656 189.895L166.733 189.681L168.247 189.895L168.152 190.208Z' fill='#5179E0' />
      <path d='M203.466 204.606L202.456 203.83L202.694 203.464L203.713 204.133L203.466 204.606Z' fill='#5179E0' />
      <path d='M185.094 106.022L185.399 108.253L184.98 107.896L184.542 105.968L185.094 106.022Z' fill='#5179E0' />
      <path d='M385.074 430.394L384.951 430.921L384.903 429.305L385.274 428.921L385.074 430.394Z' fill='#5179E0' />
      <path d='M244.608 200.09L243.885 199.482L244.085 198.947L244.875 199.536L244.608 200.09Z' fill='#5179E0' />
      <path d='M245.408 25.3169L245.189 25.1652L245.047 23.4959L245.17 23.7458L245.408 25.3169Z' fill='#5179E0' />
      <path d='M221.456 21.6213L221.008 21.7017L220.58 20.4787L221.294 20.0056L221.456 21.6213Z' fill='#5179E0' />
      <path d='M75.4092 382.111L73.2854 382.102L73.0663 381.593L74.9616 381.468L75.4092 382.111Z' fill='#5179E0' />
      <path d='M268.837 520.507L267.761 521.293L267.837 519.999L268.827 519.151L268.837 520.507Z' fill='#5179E0' />
      <path d='M183.885 203.169L182.704 202.428L182.894 202.125L184.113 202.776L183.885 203.169Z' fill='#5179E0' />
      <path d='M106.866 186.343L106.98 186.369L106.361 188.137L106.866 186.343Z' fill='#5179E0' />
      <path d='M219.008 205.187L218.228 204.428L218.437 203.99L219.228 204.669L219.008 205.187Z' fill='#5179E0' />
      <path d='M229.475 8.07084L229.313 9.84723L228.17 9.57943L228.285 7.80306L229.475 8.07084Z' fill='#5179E0' />
      <path d='M115.999 206.311L115.895 206.392L114.752 205.062L115.999 206.311Z' fill='#5179E0' />
      <path d='M100.066 228.913L100.038 227.003L100.171 226.994L100.323 228.842L100.066 228.913Z' fill='#5179E0' />
      <path d='M99.8281 151.333L99.8567 153.145L99.6091 153.109L99.4567 151.244L99.8281 151.333Z' fill='#5179E0' />
      <path d='M49.5426 283.133L49.4093 281.196L49.8188 281.125L50.095 283.026L49.5426 283.133Z' fill='#5179E0' />
      <path d='M177.009 92.2034L176.123 93.4264L175.742 92.9532L176.456 91.7482L177.009 92.2034Z' fill='#5179E0' />
      <path d='M107.18 186.485L107.295 186.512L106.561 188.253L107.18 186.485Z' fill='#5179E0' />
      <path d='M270.561 372.577L269.637 371.131L270.304 369.89L271.551 371.595L270.561 372.577Z' fill='#5179E0' />
      <path d='M152.371 140.541L151.437 141.273L151.237 140.978L152.18 140.13L152.371 140.541Z' fill='#5179E0' />
      <path d='M104.828 248.811L104.819 246.847L105.076 246.784L105.219 248.632L104.828 248.811Z' fill='#5179E0' />
      <path d='M256.266 69.3337L255.523 69.5568L255.389 68.6374L256.056 68.3964L256.266 69.3337Z' fill='#5179E0' />
      <path d='M115.561 205.901L115.466 205.99L114.209 204.749L115.561 205.901Z' fill='#5179E0' />
      <path d='M259.113 341.361L258.532 340.611L258.961 339.593L259.446 340.058L259.113 341.361Z' fill='#5179E0' />
      <path d='M262.932 19.3272L262.742 19.3094L262.513 17.7472L262.78 17.6401L262.932 19.3272Z' fill='#5179E0' />
      <path d='M53.3331 182.772L53.9807 184.468L53.8664 184.504L53.095 182.835L53.3331 182.772Z' fill='#5179E0' />
      <path d='M664.626 173.801L662.864 175.015L659.064 174.363L660.778 173.355L664.626 173.801Z' fill='#5179E0' />
      <path d='M107.409 186.923L107.523 186.959L106.676 188.664L107.409 186.923Z' fill='#5179E0' />
      <path d='M164.885 106.379L164.199 107.53L163.828 107.129L164.475 105.87L164.885 106.379Z' fill='#5179E0' />
      <path d='M116.971 204.446L116.895 204.544L115.457 203.482L116.971 204.446Z' fill='#5179E0' />
      <path d='M266.361 538.084L266.38 540.208L265.951 539.467L266.656 537.852L266.361 538.084Z' fill='#5179E0' />
      <path d='M164.514 202.089L163.085 201.393L163.218 201.196L164.685 201.75L164.514 202.089Z' fill='#5179E0' />
      <path d='M114.885 205.508L114.799 205.606L113.428 204.472L114.885 205.508Z' fill='#5179E0' />
      <path d='M417.341 109.905L415.808 109.815L416.284 108.352H417.77L417.341 109.905Z' fill='#5179E0' />
      <path d='M95.3615 135.069L95.39 136.854L95.0186 136.8L94.8758 135.051L95.3615 135.069Z' fill='#5179E0' />
      <path d='M247.751 405.489L247.218 405.177L247.475 403.427L248.399 404.141L247.751 405.489Z' fill='#5179E0' />
      <path d='M234.618 449.613L233.361 449.211L233.866 447.899L235.18 448.238L234.618 449.613Z' fill='#5179E0' />
      <path d='M549.636 388.047L548.893 389.671L548.969 388.671L549.807 386.904L549.636 388.047Z' fill='#5179E0' />
      <path d='M476.35 23.4959L475.903 24.8706L474.522 24.701L474.883 23.4245L476.35 23.4959Z' fill='#5179E0' />
      <path d='M155.561 9.95435L155.58 11.9182L154.39 12.0967L154.237 10.1061L155.561 9.95435Z' fill='#5179E0' />
      <path d='M99.4853 229.253L99.2948 227.405L99.4186 227.378L99.7424 229.172L99.4853 229.253Z' fill='#5179E0' />
      <path d='M506.502 525.676L506.017 527.327L506.34 526.238L506.426 524.649L506.502 525.676Z' fill='#5179E0' />
      <path d='M139.447 204.222L137.923 203.464L137.99 203.357L139.58 204.008L139.447 204.222Z' fill='#5179E0' />
      <path d='M444.817 -9.2377L445.236 -7.80053L444.427 -6.14019L444.331 -6.78291L444.817 -9.2377Z' fill='#5179E0' />
      <path d='M69.2473 368.007L67.3426 367.926L67.1425 367.373L68.9616 367.31L69.2473 368.007Z' fill='#5179E0' />
      <path d='M164.418 196.314L162.933 195.867L163.037 195.662L164.542 195.983L164.418 196.314Z' fill='#5179E0' />
      <path d='M192.418 87.2492L192.551 89.0256L191.913 88.7578L191.713 87.026L192.418 87.2492Z' fill='#5179E0' />
      <path d='M16.3904 332.541L15.2952 330.997L15.7904 330.72L16.9904 332.22L16.3904 332.541Z' fill='#5179E0' />
      <path d='M119.79 197.063L119.78 197.179L117.838 197.233L119.79 197.063Z' fill='#5179E0' />
      <path d='M117.18 222.37L116.466 220.781L116.58 220.71L117.409 222.227L117.18 222.37Z' fill='#5179E0' />
      <path d='M137.018 206.195L135.542 205.365L135.618 205.258L137.161 205.99L137.018 206.195Z' fill='#5179E0' />
      <path d='M106.676 188.119L106.78 188.155L105.857 189.842L106.676 188.119Z' fill='#5179E0' />
      <path d='M47.0188 186.128L47.6474 187.851L47.5236 187.887L46.7807 186.2L47.0188 186.128Z' fill='#5179E0' />
      <path d='M119.638 221.022L118.828 219.496L118.942 219.424L119.857 220.862L119.638 221.022Z' fill='#5179E0' />
      <path d='M203.647 82.902L202.932 84.2945L202.247 83.9999L202.894 82.4378L203.647 82.902Z' fill='#5179E0' />
      <path d='M101.2 151.77L100.98 153.555L100.733 153.502L100.828 151.681L101.2 151.77Z' fill='#5179E0' />
      <path d='M119.209 195.689V195.796L117.257 196.081L119.209 195.689Z' fill='#5179E0' />
      <path d='M122.676 133.846L122.085 135.613L121.733 135.452L122.209 133.756L122.676 133.846Z' fill='#5179E0' />
      <path d='M118.838 195.019L118.847 195.126L116.904 195.528L118.838 195.019Z' fill='#5179E0' />
      <path d='M138.876 202.776L137.276 202.143L137.342 202.036L138.99 202.562L138.876 202.776Z' fill='#5179E0' />
      <path d='M351.389 524.89L349.684 525.542L350.065 524.051L351.551 523.319L351.389 524.89Z' fill='#5179E0' />
      <path d='M338.027 -14.3794L337.608 -12.8172L336.075 -12.8976L336.427 -14.4419L338.027 -14.3794Z' fill='#5179E0' />
      <path d='M163.028 199.366L161.561 198.822L161.675 198.626L163.171 199.027L163.028 199.366Z' fill='#5179E0' />
      <path d='M187.228 78.8136L186.266 80.1258L185.704 79.7331L186.542 78.3673L187.228 78.8136Z' fill='#5179E0' />
      <path d='M282.437 361.089L281.361 359.312L282.17 358.348L283.17 359.714L282.437 361.089Z' fill='#5179E0' />
      <path d='M99.8472 229.154L99.5043 227.351L99.6281 227.315L100.104 229.065L99.8472 229.154Z' fill='#5179E0' />
      <path d='M112.695 224.164L112.028 222.531L112.142 222.468L112.923 224.03L112.695 224.164Z' fill='#5179E0' />
      <path d='M141.323 195.858L139.542 195.635L139.571 195.519L141.38 195.635L141.323 195.858Z' fill='#5179E0' />
      <path d='M134.218 208.231L132.79 207.329L132.866 207.222L134.371 208.025L134.218 208.231Z' fill='#5179E0' />
      <path d='M78.8282 353.215L77.1616 353.046L77.1139 352.34L78.7996 352.394L78.8282 353.215Z' fill='#5179E0' />
      <path d='M450.76 26.3345L449.655 27.013L448.512 26.0489L450.531 25.1473L450.76 26.3345Z' fill='#5179E0' />
      <path d='M117.857 196.849V196.965L115.904 197.162L117.857 196.849Z' fill='#5179E0' />
      <path d='M452.674 328.685L451.693 329.524L451.988 328.658L452.579 327.909L452.674 328.685Z' fill='#5179E0' />
      <path d='M131.866 210.159L130.485 209.168L130.571 209.07L132.028 209.953L131.866 210.159Z' fill='#5179E0' />
      <path d='M108.419 225.852L107.809 224.173L107.933 224.111L108.657 225.727L108.419 225.852Z' fill='#5179E0' />
      <path d='M148.028 123.794L148.142 125.883L147.714 125.696L147.523 123.571L148.028 123.794Z' fill='#5179E0' />
      <path d='M217.104 327.998L216.799 327.962L216.961 326.784L217.218 326.623L217.104 327.998Z' fill='#5179E0' />
      <path d='M213.409 129.016L213.285 129.374L213.113 128.891L213.351 128.356L213.409 129.016Z' fill='#5179E0' />
      <path d='M257.989 139.907L257.751 140.407L257.637 139.996L257.885 139.434L257.989 139.907Z' fill='#5179E0' />
      <path d='M293.094 213.06L292.542 212.551L292.789 211.881L293.237 212.256L293.094 213.06Z' fill='#5179E0' />
      <path d='M635.53 174.247L633.845 174.845L630.397 172.507L632.33 172.283L635.53 174.247Z' fill='#5179E0' />
      <path d='M116.342 195.028L116.371 195.135L114.447 195.689L116.342 195.028Z' fill='#5179E0' />
      <path d='M119.209 220.344L118.266 218.916L118.371 218.835L119.419 220.174L119.209 220.344Z' fill='#5179E0' />
      <path d='M215.542 353.84L215.218 354.028L215.351 352.653L215.789 352.564L215.542 353.84Z' fill='#5179E0' />
      <path d='M315.275 361.812L314.541 360.142L315.237 358.598L315.96 359.946L315.275 361.812Z' fill='#5179E0' />
      <path d='M202.637 124.053L202.351 124.58L202.151 124.098L202.494 123.411L202.637 124.053Z' fill='#5179E0' />
      <path d='M103.39 227.637L102.819 225.923L102.942 225.869L103.628 227.521L103.39 227.637Z' fill='#5179E0' />
      <path d='M99.4281 228.994L98.9329 227.253L99.0567 227.199L99.6757 228.895L99.4281 228.994Z' fill='#5179E0' />
      <path d='M140.609 438.098L139.085 438.303L138.666 437.437L140.39 437.214L140.609 438.098Z' fill='#5179E0' />
      <path d='M105.752 189.574L105.857 189.61L104.876 191.279L105.752 189.574Z' fill='#5179E0' />
      <path d='M254.818 394.483L254.027 393.786L254.418 392.278L255.789 393.519L254.818 394.483Z' fill='#5179E0' />
      <path d='M104.085 250.221L103.819 248.364L104.076 248.284L104.457 250.007L104.085 250.221Z' fill='#5179E0' />
      <path d='M158.647 205.686L157.209 204.981L157.342 204.776L158.828 205.356L158.647 205.686Z' fill='#5179E0' />
      <path d='M162.875 188.664L161.361 188.583L161.409 188.369L162.923 188.342L162.875 188.664Z' fill='#5179E0' />
      <path d='M140.447 386.467L139.037 386.547V385.922L140.466 385.833L140.447 386.467Z' fill='#5179E0' />
      <path d='M103.523 152.431L103.038 154.18L102.79 154.109L103.152 152.324L103.523 152.431Z' fill='#5179E0' />
      <path d='M271.389 76.8766L270.189 77.2961L270.094 76.4927L271.008 76.0196L271.389 76.8766Z' fill='#5179E0' />
      <path d='M111.076 192.109L111.142 192.19L109.514 193.377L111.076 192.109Z' fill='#5179E0' />
      <path d='M41.695 189.886L42.3046 191.645L42.1808 191.681L41.4665 189.958L41.695 189.886Z' fill='#5179E0' />
      <path d='M138.104 194.805L136.295 194.751L136.314 194.644L138.142 194.582L138.104 194.805Z' fill='#5179E0' />
      <path d='M228.466 321.062L228.228 321.214L228.313 320.062L228.608 319.928L228.466 321.062Z' fill='#5179E0' />
      <path d='M156.504 208.677L155.104 207.882L155.247 207.677L156.704 208.355L156.504 208.677Z' fill='#5179E0' />
      <path d='M312.341 237.884L311.675 236.983L312.18 235.939L312.932 236.903L312.341 237.884Z' fill='#5179E0' />
      <path d='M155.199 409.336L154.114 409.551L154.266 408.524L155.609 408.595L155.199 409.336Z' fill='#5179E0' />
      <path d='M176.599 165.249L175.713 165.267L175.675 164.937L176.523 164.865L176.599 165.249Z' fill='#5179E0' />
      <path d='M109.819 191.922L109.895 192.002L108.352 193.288L109.819 191.922Z' fill='#5179E0' />
      <path d='M343.437 -26.8587L342.741 -25.9928L342.503 -27.8138L343.132 -28.7332L343.437 -26.8587Z' fill='#5179E0' />
      <path d='M238.075 236.01L237.456 235.09L237.808 234.501L238.275 235.207L238.075 236.01Z' fill='#5179E0' />
      <path d='M241.237 322.454L240.799 322.285L241.008 321.41L241.399 321.419L241.237 322.454Z' fill='#5179E0' />
      <path d='M105.571 190.502L105.676 190.547L104.609 192.181L105.571 190.502Z' fill='#5179E0' />
      <path d='M231.894 442.374L230.589 441.927L231.104 440.642L232.618 441.133L231.894 442.374Z' fill='#5179E0' />
      <path d='M106.304 190.743L106.4 190.788L105.238 192.377L106.304 190.743Z' fill='#5179E0' />
      <path d='M136.009 198.554L134.237 198.313L134.266 198.197L136.066 198.34L136.009 198.554Z' fill='#5179E0' />
      <path d='M103.123 327.953L101.4 327.525L101.723 326.837L103.466 327.194L103.123 327.953Z' fill='#5179E0' />
      <path d='M200.009 341.236L199.59 341.156L199.809 339.959L200.209 339.897L200.009 341.236Z' fill='#5179E0' />
      <path d='M400.96 116.635L399.846 117.055L399.893 116.269L400.532 115.877L400.96 116.635Z' fill='#5179E0' />
      <path d='M140.456 161.054L138.971 162.089L138.847 161.92L140.285 160.786L140.456 161.054Z' fill='#5179E0' />
      <path d='M134.437 202.178L132.742 201.741L132.79 201.625L134.523 201.964L134.437 202.178Z' fill='#5179E0' />
      <path d='M106.638 191.279L106.733 191.342L105.476 192.868L106.638 191.279Z' fill='#5179E0' />
      <path d='M191.942 119.992L191.571 120.733L191.304 120.286L191.742 119.367L191.942 119.992Z' fill='#5179E0' />
      <path d='M112.447 195.671L112.485 195.769L110.609 196.501L112.447 195.671Z' fill='#5179E0' />
      <path d='M150.342 64.8793L150.142 66.5664L149.266 66.5307L149.352 64.8436L150.342 64.8793Z' fill='#5179E0' />
      <path d='M56.3235 286.971L55.9902 285.07L56.3997 284.954L56.8569 286.757L56.3235 286.971Z' fill='#5179E0' />
      <path d='M267.608 335.844L266.856 335.094L267.294 334.139L268.084 334.791L267.608 335.844Z' fill='#5179E0' />
      <path d='M155.18 174.524L154.761 176.372L154.275 176.211L154.58 174.328L155.18 174.524Z' fill='#5179E0' />
      <path d='M196.656 86.3655L196.551 88.0258L195.856 87.7669L195.913 85.9906L196.656 86.3655Z' fill='#5179E0' />
      <path d='M214.656 475.955L213.685 476.544L213.837 475.241L214.789 474.589L214.656 475.955Z' fill='#5179E0' />
      <path d='M284.808 167.061L283.446 167.008L283.342 166.463L284.637 166.338L284.808 167.061Z' fill='#5179E0' />
      <path d='M175.218 104.272L175.647 106.584L175.199 106.272L174.733 104.049L175.218 104.272Z' fill='#5179E0' />
      <path d='M256.018 32.5385L255.865 34.5916L255.151 34.2881L255.085 32.4135L256.018 32.5385Z' fill='#5179E0' />
      <path d='M107.2 192.529L107.285 192.591L105.866 193.993L107.2 192.529Z' fill='#5179E0' />
      <path d='M181.304 115.832L180.828 116.778L180.523 116.35L181.018 115.261L181.304 115.832Z' fill='#5179E0' />
      <path d='M277.561 13.8106L277.646 13.6678L277.313 12.0075L277.561 11.5968V13.8106Z' fill='#5179E0' />
      <path d='M171.247 111.842L170.733 112.94L170.39 112.529L170.885 111.297L171.247 111.842Z' fill='#5179E0' />
      <path d='M186.494 182.263L185.513 182.192L185.523 181.888L186.494 181.897V182.263Z' fill='#5179E0' />
      <path d='M106.228 192.332L106.314 192.395L104.99 193.877L106.228 192.332Z' fill='#5179E0' />
      <path d='M253.151 343.271L251.57 341.879L252.446 341.325L253.704 342.209L253.151 343.271Z' fill='#5179E0' />
      <path d='M108.59 194.028L108.657 194.109L107 195.26L108.59 194.028Z' fill='#5179E0' />
      <path d='M258.437 228.512L258.208 227.878L258.285 227.235L258.294 227.744L258.437 228.512Z' fill='#5179E0' />
      <path d='M123.523 213.586L122.114 212.605L122.19 212.506L123.676 213.39L123.523 213.586Z' fill='#5179E0' />
      <path d='M115.38 220.549L114.247 219.255L114.342 219.166L115.571 220.371L115.38 220.549Z' fill='#5179E0' />
      <path d='M213.075 13.3197L212.294 14.2391L211.494 13.3821L212.589 12.0164L213.075 13.3197Z' fill='#5179E0' />
      <path d='M107.095 153.431L106.342 155.144L106.095 155.064L106.742 153.323L107.095 153.431Z' fill='#5179E0' />
      <path d='M269.589 48.2581L269.427 48.2938L269.246 46.9995L269.456 46.8834L269.589 48.2581Z' fill='#5179E0' />
      <path d='M229.608 431.921L228.161 431.26L228.799 430.073L230.504 430.778L229.608 431.921Z' fill='#5179E0' />
      <path d='M420.646 109.289L418.769 109.753L418.417 108.994L419.722 108.477L420.646 109.289Z' fill='#5179E0' />
      <path d='M476.093 46.3389L476.141 47.7404L474.96 47.5708L475.36 46.4014L476.093 46.3389Z' fill='#5179E0' />
      <path d='M131.866 201.223L130.104 200.946L130.142 200.83L131.933 201.009L131.866 201.223Z' fill='#5179E0' />
      <path d='M244.199 145.031L242.961 145.602L242.475 145.147L243.732 144.584L244.199 145.031Z' fill='#5179E0' />
      <path d='M421.427 15.5245L419.836 16.7564L419.636 15.5066L420.808 14.3819L421.427 15.5245Z' fill='#5179E0' />
      <path d='M187.066 298.996L186.675 298.665L186.856 297.781L187.247 297.969L187.066 298.996Z' fill='#5179E0' />
      <path d='M254.37 178.755L253.418 178.737L253.408 178.166L254.551 178.264L254.37 178.755Z' fill='#5179E0' />
      <path d='M228.675 31.2352L228.437 31.2798L228.199 29.7266L228.456 29.6106L228.675 31.2352Z' fill='#5179E0' />
      <path d='M108.276 196.733L108.323 196.831L106.523 197.742L108.276 196.733Z' fill='#5179E0' />
      <path d='M233.78 446.444L233.113 446.837L233.208 445.257L234.151 445.078L233.78 446.444Z' fill='#5179E0' />
      <path d='M139.704 163.625L138.19 164.633L138.076 164.473L139.533 163.357L139.704 163.625Z' fill='#5179E0' />
      <path d='M327.379 504.315L326.818 505.422L327.189 504.35L327.113 502.913L327.379 504.315Z' fill='#5179E0' />
      <path d='M254.351 491.086L253.227 491.505L253.38 490.122L254.561 489.675L254.351 491.086Z' fill='#5179E0' />
      <path d='M177.323 46.8209L176.847 48.4456L175.875 48.2224L176.275 46.5621L177.323 46.8209Z' fill='#5179E0' />
      <path d='M343.456 40.7063L342.284 41.7953L341.56 41.0901L342.57 39.9921L343.456 40.7063Z' fill='#5179E0' />
      <path d='M474.198 104.718L473.893 104.424L473.693 102.933L474.522 103.397L474.198 104.718Z' fill='#5179E0' />
      <path d='M254.475 54.0514L254.227 54.1585L254.037 52.9356L254.313 52.766L254.475 54.0514Z' fill='#5179E0' />
      <path d='M37.7903 194.189L38.3713 195.983L38.257 196.01L37.5427 194.261L37.7903 194.189Z' fill='#5179E0' />
      <path d='M115.457 218.371L114.114 217.282L114.199 217.184L115.619 218.184L115.457 218.371Z' fill='#5179E0' />
      <path d='M113.066 220.228L111.78 219.067L111.876 218.969L113.238 220.04L113.066 220.228Z' fill='#5179E0' />
      <path d='M117.085 216.702L115.666 215.693L115.752 215.595L117.238 216.505L117.085 216.702Z' fill='#5179E0' />
      <path d='M287.189 173.587L285.799 173.488L285.713 172.792L287.075 172.89L287.189 173.587Z' fill='#5179E0' />
      <path d='M105.714 196.385L105.78 196.474L104.114 197.608L105.714 196.385Z' fill='#5179E0' />
      <path d='M198.942 294.961L198.561 294.818L198.723 294.023L199.047 293.961L198.942 294.961Z' fill='#5179E0' />
      <path d='M212.266 316.509L212.047 316.866L212.123 315.733L212.361 315.349L212.266 316.509Z' fill='#5179E0' />
      <path d='M133.504 160.688L132.19 161.902L132.038 161.741L133.304 160.447L133.504 160.688Z' fill='#5179E0' />
      <path d='M459.122 -26.2249L458.865 -23.9575L456.665 -22.0383L456.836 -24.0915L459.122 -26.2249Z' fill='#5179E0' />
      <path d='M101.381 252.515L100.838 250.819L101.076 250.703L101.723 252.265L101.381 252.515Z' fill='#5179E0' />
      <path d='M448.35 -11.3622L447.884 -9.08595L446.027 -8.18436L446.122 -9.65725L448.35 -11.3622Z' fill='#5179E0' />
      <path d='M219.961 308.279L219.742 308.592L219.77 307.52L220.132 307.378L219.961 308.279Z' fill='#5179E0' />
      <path d='M383.427 61.3979L382.741 61.5229L382.341 60.0054L383.922 60.2375L383.427 61.3979Z' fill='#5179E0' />
      <path d='M300.665 524.391L299.199 525.158L299.265 523.989L300.237 523.15L300.665 524.391Z' fill='#5179E0' />
      <path d='M105.438 197.403L105.495 197.492L103.78 198.554L105.438 197.403Z' fill='#5179E0' />
      <path d='M292.675 353.367L291.618 351.957L292.361 351.001L293.075 351.716L292.675 353.367Z' fill='#5179E0' />
      <path d='M159.695 177.684L159.171 179.291L158.685 179.175L159.123 177.327L159.695 177.684Z' fill='#5179E0' />
      <path d='M113.342 358.321L111.79 358.286L111.809 357.777L113.361 357.768L113.342 358.321Z' fill='#5179E0' />
      <path d='M260.408 170.757L259.094 170.819L259.027 170.221L260.418 170.239L260.408 170.757Z' fill='#5179E0' />
      <path d='M108.838 155.207L107.961 156.885L107.733 156.796L108.504 155.082L108.838 155.207Z' fill='#5179E0' />
      <path d='M258.494 37.2695L258.294 37.4124L258.161 35.8413L258.275 35.7431L258.494 37.2695Z' fill='#5179E0' />
      <path d='M170.847 157.93L169.209 159.849L168.628 159.626L170.18 157.546L170.847 157.93Z' fill='#5179E0' />
      <path d='M173.809 309.011L173.38 308.716L173.571 307.744L174.037 307.967L173.809 309.011Z' fill='#5179E0' />
      <path d='M232.37 245.651L231.723 244.722L232.104 244.044L232.742 244.838L232.37 245.651Z' fill='#5179E0' />
      <path d='M354.922 461.262L353.046 461.137L353.551 459.709L355.475 459.718L354.922 461.262Z' fill='#5179E0' />
      <path d='M389.17 -3.81929L388.922 -2.28392L387.684 -2.60528L387.922 -4.09601L389.17 -3.81929Z' fill='#5179E0' />
      <path d='M128.257 195.885L126.428 196.117V196.001L128.257 195.671V195.885Z' fill='#5179E0' />
      <path d='M613.264 175.238L611.502 175.845L607.578 173.64L609.435 173.355L613.264 175.238Z' fill='#5179E0' />
      <path d='M119.933 42.3041L120.295 44.4822L119.285 44.8928L118.742 42.4826L119.933 42.3041Z' fill='#5179E0' />
      <path d='M233.589 207.106L232.932 206.651L233.037 206.07L233.799 206.526L233.589 207.106Z' fill='#5179E0' />
      <path d='M330.018 456.451L328.189 456.433L328.808 455.138L330.351 454.978L330.018 456.451Z' fill='#5179E0' />
      <path d='M457.055 -30.5275V-30.3757L456.836 -27.2782L456.712 -27.7603L457.055 -30.5275Z' fill='#5179E0' />
      <path d='M236.275 491.684L235.551 492.683L235.399 491.38L236.475 490.595L236.275 491.684Z' fill='#5179E0' />
      <path d='M145.323 213.515L143.866 212.783L143.999 212.569L145.514 213.203L145.323 213.515Z' fill='#5179E0' />
      <path d='M171.533 406.364L170.037 406.346L170.247 405.355L171.704 405.328L171.533 406.364Z' fill='#5179E0' />
      <path d='M408.684 544.868L407.579 546.18L407.693 544.93L408.503 543.591L408.684 544.868Z' fill='#5179E0' />
      <path d='M200.275 49.5257L199.313 50.704L198.675 50.0434L199.628 48.758L200.275 49.5257Z' fill='#5179E0' />
      <path d='M196.342 47.7761L195.523 49.2579L194.618 48.8383L195.38 47.3208L196.342 47.7761Z' fill='#5179E0' />
      <path d='M311.675 11.338L311.589 11.82L311.522 9.42768L311.484 9.04385L311.675 11.338Z' fill='#5179E0' />
      <path d='M230.856 323.401L230.494 323.651L230.599 322.704L231.018 322.49L230.856 323.401Z' fill='#5179E0' />
      <path d='M163.828 7.77627L163.504 9.81153L162.294 9.65084L162.456 7.67808L163.828 7.77627Z' fill='#5179E0' />
      <path d='M241.323 428.734L240.37 428.573L240.637 427.163L241.885 427.475L241.323 428.734Z' fill='#5179E0' />
      <path d='M326.76 -12.6655L326.056 -11.4961L325.684 -13.1743L326.313 -14.4151L326.76 -12.6655Z' fill='#5179E0' />
      <path d='M251.665 174.881L250.475 174.943L250.446 174.399L251.732 174.363L251.665 174.881Z' fill='#5179E0' />
      <path d='M192.571 378.692L191.113 378.433L191.542 377.62L192.913 377.763L192.571 378.692Z' fill='#5179E0' />
      <path d='M242.723 452.523L241.904 452.844L242.008 451.389L243.094 451.22L242.723 452.523Z' fill='#5179E0' />
      <path d='M225.208 220.308L224.561 219.701L224.732 219.13L225.399 219.665L225.208 220.308Z' fill='#5179E0' />
      <path d='M199.228 330.087L198.932 330.524L199.047 329.355L199.342 328.846L199.228 330.087Z' fill='#5179E0' />
      <path d='M510.836 186.789L511.083 186.602L510.617 184.754L511.293 185.486L510.836 186.789Z' fill='#5179E0' />
      <path d='M70.114 290.176L69.6187 288.373L69.9997 288.177L70.6187 289.819L70.114 290.176Z' fill='#5179E0' />
      <path d='M449.274 51.4359L448.95 52.6589L448.284 52.5607L448.179 51.311L449.274 51.4359Z' fill='#5179E0' />
      <path d='M476.007 288.801L475.188 290.015L475.284 289.078L476.493 287.382L476.007 288.801Z' fill='#5179E0' />
      <path d='M283.237 388.734L282.218 387.663L282.742 386.172L284.113 387.484L283.237 388.734Z' fill='#5179E0' />
      <path d='M490.398 -1.33771L490.331 -0.534332L490.121 1.492L489.874 0.295841L490.398 -1.33771Z' fill='#5179E0' />
      <path d='M35.0665 198.349L35.6379 200.17L35.5141 200.197L34.8284 198.411L35.0665 198.349Z' fill='#5179E0' />
      <path d='M229.008 213.827L228.351 213.31L228.504 212.747L229.17 213.203L229.008 213.827Z' fill='#5179E0' />
      <path d='M486.217 386.6L485.607 387.94L486.093 386.824L485.912 386.047L486.217 386.6Z' fill='#5179E0' />
      <path d='M469.093 -30.2508L466.845 -27.6531L464.655 -27.6799L466.007 -29.7152L469.093 -30.2508Z' fill='#5179E0' />
      <path d='M263.732 183.049L262.904 182.968L262.932 182.361L263.894 182.46L263.732 183.049Z' fill='#5179E0' />
      <path d='M220.113 305.208L219.732 305.351L219.809 304.485L220.332 304.476L220.113 305.208Z' fill='#5179E0' />
      <path d='M110.971 157.439L110.047 159.09L109.828 158.992L110.657 157.296L110.971 157.439Z' fill='#5179E0' />
      <path d='M406.846 116.421L405.027 116.653L405.503 115.002L407.208 114.904L406.846 116.421Z' fill='#5179E0' />
      <path d='M252.932 201.107L252.523 200.75L252.58 200.152L252.989 200.464L252.932 201.107Z' fill='#5179E0' />
      <path d='M274.884 151.627L273.561 151.895L273.104 151.217L274.37 150.958L274.884 151.627Z' fill='#5179E0' />
      <path d='M159.037 320.428L158.504 320.089L158.752 319.134L159.285 319.294L159.037 320.428Z' fill='#5179E0' />
      <path d='M197.323 113.002L197.332 115.198L196.79 114.921L196.809 112.529L197.323 113.002Z' fill='#5179E0' />
      <path d='M119.161 440.338L117.676 441.677L117.647 440.776L119.247 439.812L119.161 440.338Z' fill='#5179E0' />
      <path d='M314.913 229.654L313.989 228.913L314.313 227.797L315.427 228.824L314.913 229.654Z' fill='#5179E0' />
      <path d='M404.284 -8.61285L403.741 -7.11318L402.36 -7.70234L402.874 -9.17521L404.284 -8.61285Z' fill='#5179E0' />
      <path d='M102.38 254.015L101.628 252.47L101.857 252.345L102.695 253.72L102.38 254.015Z' fill='#5179E0' />
      <path d='M257.275 412.354L255.932 411.47L256.57 410.229L258.027 411.059L257.275 412.354Z' fill='#5179E0' />
      <path d='M168.923 210.114L167.647 209.623L167.78 209.293L169.094 209.703L168.923 210.114Z' fill='#5179E0' />
      <path d='M427.903 -15.031L424.96 -12.2192L424.131 -13.2011L426.131 -15.522L427.903 -15.031Z' fill='#5179E0' />
      <path d='M381.693 342.459L381.351 341.896L381.532 340.022L381.836 340.424L381.693 342.459Z' fill='#5179E0' />
      <path d='M353.932 373.969L353.732 373.63L353.76 371.274L354.436 372.104L353.932 373.969Z' fill='#5179E0' />
      <path d='M125.885 161.348L124.761 162.759L124.58 162.616L125.647 161.143L125.885 161.348Z' fill='#5179E0' />
      <path d='M144.409 328.355L143.828 327.909L144.114 326.918L144.723 327.194L144.409 328.355Z' fill='#5179E0' />
      <path d='M176.199 402.365L175.294 402.936L175.475 402.008L176.342 401.338L176.199 402.365Z' fill='#5179E0' />
      <path d='M493.264 99.8981L491.207 100.014L490.112 98.7109L491.893 98.4877L493.264 99.8981Z' fill='#5179E0' />
      <path d='M33.5903 389.27L31.6951 388.859L32.076 388.225L34.0094 388.636L33.5903 389.27Z' fill='#5179E0' />
      <path d='M185.552 343.967L185.19 344.565L185.313 343.307L185.704 342.709L185.552 343.967Z' fill='#5179E0' />
      <path d='M208.913 213.337L207.999 212.81L208.209 212.328L209.075 212.747L208.913 213.337Z' fill='#5179E0' />
      <path d='M115.085 159.206L114.114 160.804L113.904 160.697L114.799 159.045L115.085 159.206Z' fill='#5179E0' />
      <path d='M206.475 219.255L205.485 218.612L205.751 218.148L206.666 218.63L206.475 219.255Z' fill='#5179E0' />
      <path d='M317.218 2.05437L316.589 3.39335L316.513 1.14386L316.646 0.420815L317.218 2.05437Z' fill='#5179E0' />
      <path d='M408.055 146.396L408.36 145.95L407.874 144.566L408.493 145.29L408.055 146.396Z' fill='#5179E0' />
      <path d='M355.008 4.86622L354.398 5.67854L354.027 4.29493L354.827 3.18803L355.008 4.86622Z' fill='#5179E0' />
      <path d='M175.047 172.73L174.352 173.988L173.856 173.837L174.561 172.319L175.047 172.73Z' fill='#5179E0' />
      <path d='M67.533 361.615L65.6854 361.749L65.4473 361.196L67.2854 360.955L67.533 361.615Z' fill='#5179E0' />
      <path d='M176.999 172.087L176.18 172.444L176.133 172.132L176.913 171.721L176.999 172.087Z' fill='#5179E0' />
      <path d='M120.104 160.697L119.076 162.223L118.876 162.098L119.847 160.518L120.104 160.697Z' fill='#5179E0' />
      <path d='M503.16 105.906L501.874 106.004L500.807 104.219L502.788 104.468L503.16 105.906Z' fill='#5179E0' />
      <path d='M345.513 27.3254L344.446 28.4948L343.513 27.8074L344.808 26.3792L345.513 27.3254Z' fill='#5179E0' />
      <path d='M43.3998 334.38L42.2474 333.041L42.7046 332.657L43.9522 333.889L43.3998 334.38Z' fill='#5179E0' />
      <path d='M465.36 -31.3844L465.655 -29.9026L464.379 -27.2961L464.065 -28.8314L465.36 -31.3844Z' fill='#5179E0' />
      <path d='M465.16 167.445L463.931 167.374L463.303 166.195L465.226 166.356L465.16 167.445Z' fill='#5179E0' />
      <path d='M110.828 355.384L109.104 355.081L109.38 354.313L111.095 354.536L110.828 355.384Z' fill='#5179E0' />
      <path d='M152.304 154.225L151.542 156.439L150.952 156.189L151.552 154.055L152.304 154.225Z' fill='#5179E0' />
      <path d='M164.571 215.041L163.314 214.542L163.447 214.202L164.752 214.622L164.571 215.041Z' fill='#5179E0' />
      <path d='M243.723 222.593L243.294 222.156L243.389 221.504L243.789 221.879L243.723 222.593Z' fill='#5179E0' />
      <path d='M132.742 168.659L131.295 169.757L131.18 169.596L132.542 168.4L132.742 168.659Z' fill='#5179E0' />
      <path d='M150.599 67.0574L150.152 68.807L149.314 68.6195L149.609 66.9146L150.599 67.0574Z' fill='#5179E0' />
      <path d='M118.647 198.072L116.847 198.581L116.828 198.474L118.609 197.867L118.647 198.072Z' fill='#5179E0' />
      <path d='M84.5139 291.542L83.8663 289.917L84.2282 289.658L84.952 291.06L84.5139 291.542Z' fill='#5179E0' />
      <path d='M329.027 21.3625L329.113 22.8621L328.303 22.8353L328.16 21.4071L329.027 21.3625Z' fill='#5179E0' />
      <path d='M145.266 177.541L144.504 180.139L143.952 179.719L144.561 177.238L145.266 177.541Z' fill='#5179E0' />
      <path d='M181.609 156.751L179.999 158.108L179.304 157.885L181.113 156.198L181.609 156.751Z' fill='#5179E0' />
      <path d='M238.742 62.2103L238.323 62.6655L238.037 61.6301L238.494 61.0588L238.742 62.2103Z' fill='#5179E0' />
      <path d='M280.246 157.564L278.837 157.68L278.446 156.948L279.78 156.796L280.246 157.564Z' fill='#5179E0' />
      <path d='M470.474 21.7731L469.903 23.2817L468.512 22.9782L468.817 21.7463L470.474 21.7731Z' fill='#5179E0' />
      <path d='M472.636 127.454L470.76 127.669L468.693 126.196L470.75 126.124L472.636 127.454Z' fill='#5179E0' />
      <path d='M363.465 156.073L362.808 155.028L363.255 154.002L363.122 154.707L363.465 156.073Z' fill='#5179E0' />
      <path d='M152.114 191.163L150.637 191.538L150.618 191.324L152.095 190.859L152.114 191.163Z' fill='#5179E0' />
      <path d='M438.884 99.9963L436.217 100.594L435.293 99.782L437.379 99.1126L438.884 99.9963Z' fill='#5179E0' />
      <path d='M242.485 44.384L242.285 44.6696L242.123 43.1075L242.247 42.8576L242.485 44.384Z' fill='#5179E0' />
      <path d='M251.751 145.236L251.618 146.057L251.466 145.727L251.656 144.799L251.751 145.236Z' fill='#5179E0' />
      <path d='M34.4856 202.312L35.0189 204.151L34.8951 204.178L34.2284 202.366L34.4856 202.312Z' fill='#5179E0' />
      <path d='M123.438 311.805L122.752 310.948L123.076 310.279L123.771 310.859L123.438 311.805Z' fill='#5179E0' />
      <path d='M244.627 250.462L244.199 249.837L244.37 249.016L244.77 249.551L244.627 250.462Z' fill='#5179E0' />
      <path d='M505.093 298.755L503.836 300.701L504.283 299.254L505.388 297.273L505.093 298.755Z' fill='#5179E0' />
      <path d='M129.152 225.86L127.723 225.066L127.857 224.852L129.342 225.548L129.152 225.86Z' fill='#5179E0' />
      <path d='M611.302 180.335L609.883 181.353L606.883 180.683L607.702 179.782L611.302 180.335Z' fill='#5179E0' />
      <path d='M236.751 337.272L236.189 337.505L236.323 336.719L236.894 336.451L236.751 337.272Z' fill='#5179E0' />
      <path d='M454.265 358.687L453.703 360.062L454.026 357.661L454.75 355.741L454.265 358.687Z' fill='#5179E0' />
      <path d='M165.942 383.771L165.152 384.342L165.294 383.28L166.218 382.851L165.942 383.771Z' fill='#5179E0' />
      <path d='M402.732 428.484L401.436 428.198L401.817 426.663L402.684 426.618L402.732 428.484Z' fill='#5179E0' />
      <path d='M104.742 255.407L103.809 254.059L104.028 253.908L105.019 255.077L104.742 255.407Z' fill='#5179E0' />
      <path d='M239.199 230.618L238.666 230.118L238.847 229.458L239.275 229.851L239.199 230.618Z' fill='#5179E0' />
      <path d='M331.97 347.11L330.903 345.994L331.465 344.753L332.303 345.44L331.97 347.11Z' fill='#5179E0' />
      <path d='M417.312 339.54L416.969 339.067L417.208 337.317H417.131L417.312 339.54Z' fill='#5179E0' />
      <path d='M345.96 -9.09488L345.294 -7.94336L345.094 -9.87148L345.57 -10.9516L345.96 -9.09488Z' fill='#5179E0' />
      <path d='M123.314 342.771L122.533 342.325L122.876 341.405L123.685 341.691L123.314 342.771Z' fill='#5179E0' />
      <path d='M136.78 308.101L136.123 307.529L136.399 306.824L137.057 307.163L136.78 308.101Z' fill='#5179E0' />
      <path d='M131.723 220.21L130.19 219.585L130.295 219.353L131.895 219.898L131.723 220.21Z' fill='#5179E0' />
      <path d='M129.276 459.584L128.276 462.708L128.171 461.958L129.276 459.164V459.584Z' fill='#5179E0' />
      <path d='M129.923 223.334L128.438 222.647L128.552 222.424L130.104 223.031L129.923 223.334Z' fill='#5179E0' />
      <path d='M393.217 352.858L392.846 352.376L392.932 350.323L393.551 350.957L393.217 352.858Z' fill='#5179E0' />
      <path d='M181.818 9.31163L181.199 11.0345L180.094 10.5524L180.723 8.55289L181.818 9.31163Z' fill='#5179E0' />
      <path d='M206.866 303.673L206.437 304.066L206.57 303.352L206.923 302.771L206.866 303.673Z' fill='#5179E0' />
      <path d='M311.303 347.359L310.494 346.663L311.037 345.601L311.246 345.574L311.303 347.359Z' fill='#5179E0' />
      <path d='M273.503 160.42L271.989 160.465L271.684 159.768L273.132 159.661L273.503 160.42Z' fill='#5179E0' />
      <path d='M354.303 357.723L353.627 356.947L353.989 355.277L354.598 355.849L354.303 357.723Z' fill='#5179E0' />
      <path d='M289.313 210.114L288.818 209.936L288.951 209.266L289.322 209.346L289.313 210.114Z' fill='#5179E0' />
      <path d='M227.551 328.426L227.037 328.739L227.142 327.953L227.704 327.65L227.551 328.426Z' fill='#5179E0' />
      <path d='M150.78 304.566L150.142 304.253L150.39 303.584L150.952 303.539L150.78 304.566Z' fill='#5179E0' />
      <path d='M320.846 38.7692L320.227 40.0011L319.618 39.0638L320.351 37.5195L320.846 38.7692Z' fill='#5179E0' />
      <path d='M103.19 319.223L102.39 318.152L102.78 317.581L103.6 318.393L103.19 319.223Z' fill='#5179E0' />
      <path d='M475.398 148.878L474.045 149.199L472.017 147.557L473.36 147.396L475.398 148.878Z' fill='#5179E0' />
      <path d='M185.732 213.569L184.561 213.149L184.723 212.73L185.904 213.051L185.732 213.569Z' fill='#5179E0' />
      <path d='M145.142 169.82L144.514 172.283L143.923 171.926L144.409 169.596L145.142 169.82Z' fill='#5179E0' />
      <path d='M433.712 39.8136L433.655 39.894L433.465 38.555L433.893 38.2425L433.712 39.8136Z' fill='#5179E0' />
      <path d='M79.8473 343.057L78.1044 343.039L78.0568 342.378L79.8377 342.262L79.8473 343.057Z' fill='#5179E0' />
      <path d='M94.6091 336.817L92.7996 336.648L92.971 335.889L94.7996 335.987L94.6091 336.817Z' fill='#5179E0' />
      <path d='M98.6091 292.354L97.8567 290.988L98.19 290.676L98.9805 291.729L98.6091 292.354Z' fill='#5179E0' />
      <path d='M171.723 86.4815L171.094 87.9633L170.542 87.5527L171.037 86.0531L171.723 86.4815Z' fill='#5179E0' />
      <path d='M257.799 162.616L256.389 162.821L256.123 162.125L257.665 162.036L257.799 162.616Z' fill='#5179E0' />
      <path d='M363.817 340.638L363.208 340.004L363.57 338.531L363.865 338.736L363.817 340.638Z' fill='#5179E0' />
      <path d='M112.171 199.384L110.419 200.107L110.38 200L112.104 199.197L112.171 199.384Z' fill='#5179E0' />
      <path d='M463.95 228.69L463.484 227.164L463.817 224.745L463.95 225.593V228.69Z' fill='#5179E0' />
      <path d='M158.675 220.915L157.38 220.415L157.523 220.085L158.837 220.478L158.675 220.915Z' fill='#5179E0' />
      <path d='M288.113 247.9L287.475 247.347L287.742 246.275L288.627 247.034L288.113 247.9Z' fill='#5179E0' />
      <path d='M73.5521 328.891L72.5425 327.712L72.9616 327.23L74.0473 328.257L73.5521 328.891Z' fill='#5179E0' />
      <path d='M171.333 371.256L170.933 372.604L170.971 371.238L171.542 370.194L171.333 371.256Z' fill='#5179E0' />
      <path d='M246.951 217.175L246.551 216.916L246.589 216.255L247.046 216.488L246.951 217.175Z' fill='#5179E0' />
      <path d='M288.246 491.452L287.218 492.166L287.561 491.041L287.951 489.97L288.246 491.452Z' fill='#5179E0' />
      <path d='M320.189 391.93L319.713 391.608L319.818 389.582L320.856 390.403L320.189 391.93Z' fill='#5179E0' />
      <path d='M142.695 36.4929L142.609 38.5371H141.514L141.485 36.3412L142.695 36.4929Z' fill='#5179E0' />
      <path d='M371.608 179.371L370.684 178.389L371.351 177.05L372.094 177.577L371.608 179.371Z' fill='#5179E0' />
      <path d='M249.561 210.748L249.104 210.534L249.18 209.9L249.589 210.06L249.561 210.748Z' fill='#5179E0' />
      <path d='M118.695 232.359L117.19 231.609L117.323 231.395L118.876 232.047L118.695 232.359Z' fill='#5179E0' />
      <path d='M181.533 186.075L180.637 186.557L180.599 186.262L181.456 185.709L181.533 186.075Z' fill='#5179E0' />
      <path d='M168.199 105.04L167.761 106.557L167.304 106.182L167.599 104.665L168.199 105.04Z' fill='#5179E0' />
      <path d='M244.285 369.47L243.38 369.435L243.685 368.596L244.342 368.381L244.285 369.47Z' fill='#5179E0' />
      <path d='M306.722 213.265L305.18 212.417L305.37 211.658L306.77 212.203L306.722 213.265Z' fill='#5179E0' />
      <path d='M552.045 312.841L551.388 314.876L551.664 313.394L552.331 311.145L552.045 312.841Z' fill='#5179E0' />
      <path d='M475.712 344.494L474.541 346.842L475.369 343.807L476.274 341.593L475.712 344.494Z' fill='#5179E0' />
      <path d='M178.161 179.907L177.371 180.531L177.313 180.237L178.066 179.559L178.161 179.907Z' fill='#5179E0' />
      <path d='M102.647 259.022L101.504 257.942L101.695 257.746L102.895 258.683L102.647 259.022Z' fill='#5179E0' />
      <path d='M126.295 174.81L124.904 175.988L124.78 175.845L126.085 174.56L126.295 174.81Z' fill='#5179E0' />
      <path d='M162.809 307.949L162.19 308.208L162.39 307.556L162.885 306.922L162.809 307.949Z' fill='#5179E0' />
      <path d='M159.247 408.837L158.78 411.193L158.752 409.943L159.428 407.989L159.247 408.837Z' fill='#5179E0' />
      <path d='M81.4663 369.426L80.133 369.14L80.4949 368.381L81.8853 368.605L81.4663 369.426Z' fill='#5179E0' />
      <path d='M496.15 120.26L495.036 120.572L493.398 118.376L495.893 118.929L496.15 120.26Z' fill='#5179E0' />
      <path d='M179.304 220.585L178.104 220.201L178.266 219.764L179.475 220.067L179.304 220.585Z' fill='#5179E0' />
      <path d='M408.303 96.6578L406.541 97.7111L406.646 96.6578L407.617 95.8276L408.303 96.6578Z' fill='#5179E0' />
      <path d='M151.637 227.244L150.285 226.735L150.447 226.423L151.799 226.789L151.637 227.244Z' fill='#5179E0' />
      <path d='M104.514 201.83L102.857 202.768L102.8 202.669L104.419 201.652L104.514 201.83Z' fill='#5179E0' />
      <path d='M37.7141 206.552L38.2189 208.391L38.0855 208.409L37.457 206.597L37.7141 206.552Z' fill='#5179E0' />
      <path d='M326.16 17.6401L326.075 19.229L324.999 18.9166L325.018 17.3991L326.16 17.6401Z' fill='#5179E0' />
      <path d='M108.095 295.728L107.2 294.773L107.495 294.407L108.38 295.023L108.095 295.728Z' fill='#5179E0' />
      <path d='M327.141 23.728L326.256 25.3615L325.751 24.1029L326.446 22.5051L327.141 23.728Z' fill='#5179E0' />
      <path d='M179.771 184.272L178.933 184.897L178.885 184.611L179.675 183.915L179.771 184.272Z' fill='#5179E0' />
      <path d='M110.599 238.259L109.076 237.527L109.199 237.304L110.79 237.947L110.599 238.259Z' fill='#5179E0' />
      <path d='M370.132 64.3437L369.208 65.3256L367.941 64.7097L368.541 63.6742L370.132 64.3437Z' fill='#5179E0' />
      <path d='M164.266 33.8953L163.78 35.7074L162.714 35.4753L163.142 33.4847L164.266 33.8953Z' fill='#5179E0' />
      <path d='M298.475 221.817L297.065 221.138L297.141 220.174L298.732 220.978L298.475 221.817Z' fill='#5179E0' />
      <path d='M352.589 42.8486L351.408 44.2233L349.656 43.9466L350.541 42.6701L352.589 42.8486Z' fill='#5179E0' />
      <path d='M142.199 236.546L140.837 235.903L141.018 235.573L142.409 236.108L142.199 236.546Z' fill='#5179E0' />
      <path d='M175.475 226.458L174.294 226.048L174.456 225.602L175.666 225.941L175.475 226.458Z' fill='#5179E0' />
      <path d='M465.998 107.771L463.436 108.066L461.455 106.825L464.265 106.548L465.998 107.771Z' fill='#5179E0' />
      <path d='M422.427 -8.61285L419.617 -5.64923L418.769 -6.65793L421.36 -9.74651L422.427 -8.61285Z' fill='#5179E0' />
      <path d='M326.77 41.8221L326.922 43.3039L325.951 43.1789L326.189 41.465L326.77 41.8221Z' fill='#5179E0' />
      <path d='M297.351 55.6314L297.703 57.0061L297.161 57.4078L297.589 55.7742L297.351 55.6314Z' fill='#5179E0' />
      <path d='M145.256 232.796L143.866 232.243L144.037 231.93L145.437 232.35L145.256 232.796Z' fill='#5179E0' />
      <path d='M216.332 232.038L215.561 231.654L215.742 231.047L216.466 231.323L216.332 232.038Z' fill='#5179E0' />
      <path d='M198.456 59.0592L197.866 60.2375L197.342 59.3895L197.885 58.1041L198.456 59.0592Z' fill='#5179E0' />
      <path d='M196.609 324.668L195.809 324.927L195.875 324.275L196.809 324.097L196.609 324.668Z' fill='#5179E0' />
      <path d='M141.257 165.624L141.18 167.883L140.514 167.704L140.447 165.356L141.257 165.624Z' fill='#5179E0' />
      <path d='M329.141 9.99898L328.541 11.5968L328.246 9.57943L328.589 8.17797L329.141 9.99898Z' fill='#5179E0' />
      <path d='M419.979 509.01L419.112 510.17L419.341 508.912L419.779 507.698L419.979 509.01Z' fill='#5179E0' />
      <path d='M193.932 229.806L192.818 229.333L193.037 228.815L194.132 229.172L193.932 229.806Z' fill='#5179E0' />
      <path d='M333.379 16.2119L333.465 17.774L332.475 17.6669L332.437 16.1315L333.379 16.2119Z' fill='#5179E0' />
      <path d='M210.951 239.643L210.009 239.116L210.304 238.554L211.094 238.857L210.951 239.643Z' fill='#5179E0' />
      <path d='M171.209 118.537L169.647 120.634L168.923 120.295L170.085 118.439L171.209 118.537Z' fill='#5179E0' />
      <path d='M254.113 152.574L252.827 153.002L252.399 152.261L254.018 151.993L254.113 152.574Z' fill='#5179E0' />
      <path d='M174.028 311.528L173.504 312.394L173.647 311.716L174.075 310.591L174.028 311.528Z' fill='#5179E0' />
      <path d='M617.007 180.781L616.445 182.299L613.15 182.933L613.873 181.576L617.007 180.781Z' fill='#5179E0' />
      <path d='M476.598 156.867L474.569 156.474L473.322 154.921L474.284 154.421L476.598 156.867Z' fill='#5179E0' />
      <path d='M200.951 156.225L199.609 157.099L198.875 156.689L200.675 155.626L200.951 156.225Z' fill='#5179E0' />
      <path d='M41.295 209.123L41.7046 210.962L41.5808 210.98L41.0284 209.159L41.295 209.123Z' fill='#5179E0' />
      <path d='M153.752 191.949L154.085 193.823L153.428 193.582L153.075 191.886L153.752 191.949Z' fill='#5179E0' />
      <path d='M100.057 246.079L98.4662 245.41L98.5805 245.186L100.228 245.767L100.057 246.079Z' fill='#5179E0' />
      <path d='M233.97 369.087L232.227 368.623L232.97 367.855L233.98 367.891L233.97 369.087Z' fill='#5179E0' />
      <path d='M233.18 41.465L233.075 43.8573L232.789 43.6877L232.57 41.5811L233.18 41.465Z' fill='#5179E0' />
      <path d='M256.923 81.8665L255.427 82.7056L254.961 82.0986L255.989 81.0899L256.923 81.8665Z' fill='#5179E0' />
      <path d='M113.171 402.258L111.266 403.445L110.961 403.106L112.742 402.008L113.171 402.258Z' fill='#5179E0' />
      <path d='M285.884 206.865L284.389 206.508L284.599 205.874L285.77 205.927L285.884 206.865Z' fill='#5179E0' />
      <path d='M155.475 131.561L153.733 134.256L153.047 133.864L154.371 131.516L155.475 131.561Z' fill='#5179E0' />
      <path d='M186.132 37.9301L185.485 39.4833L184.628 38.8763L185.275 37.1178L186.132 37.9301Z' fill='#5179E0' />
      <path d='M114.619 301.066L113.599 300.62L113.847 300.21L114.838 300.37L114.619 301.066Z' fill='#5179E0' />
      <path d='M240.951 151.315L240.875 152.422L240.647 152.234L240.818 150.976L240.951 151.315Z' fill='#5179E0' />
      <path d='M177.266 413.416L175.809 413.648L175.78 412.657L177.323 412.345L177.266 413.416Z' fill='#5179E0' />
      <path d='M521.445 341.093L520.369 343.735L521.112 340.861L521.826 338.469L521.445 341.093Z' fill='#5179E0' />
      <path d='M93.7805 207.784L92.2853 208.989L92.2091 208.9L93.6377 207.632L93.7805 207.784Z' fill='#5179E0' />
      <path d='M95.4948 255.336L93.952 254.657L94.0662 254.425L95.6853 255.041L95.4948 255.336Z' fill='#5179E0' />
      <path d='M43.676 210.837L43.9712 212.676L43.8379 212.694L43.4093 210.864L43.676 210.837Z' fill='#5179E0' />
      <path d='M181.771 368.096L180.713 368.667L180.685 368.069L181.818 367.551L181.771 368.096Z' fill='#5179E0' />
      <path d='M93.352 252.908L91.7329 252.283L91.8377 252.051L93.5329 252.613L93.352 252.908Z' fill='#5179E0' />
      <path d='M318.399 31.0924L317.989 32.7706L317.579 30.8157L317.808 29.2L318.399 31.0924Z' fill='#5179E0' />
      <path d='M93.5234 265.575L92.1234 264.798L92.2662 264.575L93.7424 265.253L93.5234 265.575Z' fill='#5179E0' />
      <path d='M223.456 56.9615L223.161 57.7738L222.666 56.7919L223.142 55.6046L223.456 56.9615Z' fill='#5179E0' />
      <path d='M200.97 248.257L199.713 247.436L200.142 247.007L201.123 247.347L200.97 248.257Z' fill='#5179E0' />
      <path d='M499.302 183.424L499.283 184.415L498.855 186.03L498.893 185.245L499.302 183.424Z' fill='#5179E0' />
      <path d='M529.074 145.218L528.826 144.915L528.902 143.469L528.998 143.683L529.074 145.218Z' fill='#5179E0' />
      <path d='M120.504 183.022L119.218 184.307L119.076 184.165L120.285 182.799L120.504 183.022Z' fill='#5179E0' />
      <path d='M437.255 17.6133L436.169 18.6934L435.484 17.5508L437.503 15.712L437.255 17.6133Z' fill='#5179E0' />
      <path d='M45.5903 212.23L45.7617 214.077L45.6284 214.086L45.3236 212.247L45.5903 212.23Z' fill='#5179E0' />
      <path d='M276.227 210.471L276.342 210.685L276.161 209.98L276.351 209.811L276.227 210.471Z' fill='#5179E0' />
      <path d='M92.7329 264.441L91.2472 263.771L91.3805 263.53L92.9424 264.128L92.7329 264.441Z' fill='#5179E0' />
      <path d='M374.703 380.263L374.636 380.522L374.474 378.611L375.065 378.701L374.703 380.263Z' fill='#5179E0' />
      <path d='M534.674 194.609L534.54 195.805L533.436 196.251L533.502 195.314L534.674 194.609Z' fill='#5179E0' />
      <path d='M422.417 429.26L421.465 429.617L421.95 428.27L422.055 427.6L422.417 429.26Z' fill='#5179E0' />
      <path d='M299.294 533.371L298.741 534.906L298.646 534.219L299.132 532.63L299.294 533.371Z' fill='#5179E0' />
      <path d='M90.9329 262.102L89.3805 261.477L89.4948 261.227L91.1234 261.799L90.9329 262.102Z' fill='#5179E0' />
      <path d='M49.7998 212.783L49.6474 214.613H49.5141L49.5331 212.774L49.7998 212.783Z' fill='#5179E0' />
      <path d='M47.6379 213.015L47.6665 214.854L47.5331 214.863L47.3712 213.015H47.6379Z' fill='#5179E0' />
      <path d='M206.485 328.98L205.951 330.113L206.018 329.524L206.532 328.31L206.485 328.98Z' fill='#5179E0' />
      <path d='M142.371 148.146L140.714 151.27L140.037 150.86L141.428 147.851L142.371 148.146Z' fill='#5179E0' />
      <path d='M392.932 41.6614L392.179 42.8843L390.312 42.1524L391.351 41.0455L392.932 41.6614Z' fill='#5179E0' />
      <path d='M52.3141 213.015L52.0093 214.827L51.876 214.809L52.0569 212.988L52.3141 213.015Z' fill='#5179E0' />
      <path d='M373.094 462.048L371.694 462.646L372.208 461.405L372.674 460.619L373.094 462.048Z' fill='#5179E0' />
      <path d='M78.5711 209.953L77.4377 211.471L77.333 211.408L78.3711 209.846L78.5711 209.953Z' fill='#5179E0' />
      <path d='M174.885 119.046L174.656 120.599L174.171 120.331L174.304 118.715L174.885 119.046Z' fill='#5179E0' />
      <path d='M139.466 200.411L138.114 201.357L138.028 201.161L139.371 200.152L139.466 200.411Z' fill='#5179E0' />
      <path d='M181.38 318.768L180.894 320.187L180.971 319.518L181.418 317.991L181.38 318.768Z' fill='#5179E0' />
      <path d='M86.4472 211.31L85.1044 212.685L85.0187 212.605L86.2758 211.176L86.4472 211.31Z' fill='#5179E0' />
      <path d='M91.7329 320.732L89.9234 320.714L89.952 320.098L91.8281 320L91.7329 320.732Z' fill='#5179E0' />
      <path d='M73.9711 210.471L72.9806 212.069L72.8663 212.015L73.7616 210.373L73.9711 210.471Z' fill='#5179E0' />
      <path d='M82.0758 211.176L80.8377 212.631L80.7425 212.56L81.8853 211.051L82.0758 211.176Z' fill='#5179E0' />
      <path d='M416.112 181.353L415.027 180.362L415.722 178.407L416.684 179.005L416.112 181.353Z' fill='#5179E0' />
      <path d='M146.237 78.4744L145.942 80.3311L145.133 80.0723L145.295 78.2066L146.237 78.4744Z' fill='#5179E0' />
      <path d='M569.283 340.441L568.559 343.119L569.693 337.728L569.283 340.441Z' fill='#5179E0' />
      <path d='M423.855 4.71447L422.017 6.82114L421.198 5.7678L423.789 2.69708L423.855 4.71447Z' fill='#5179E0' />
      <path d='M206.837 70.8333L206.332 72.0652L205.856 71.235L206.285 69.9496L206.837 70.8333Z' fill='#5179E0' />
      <path d='M411.684 375.648L411.674 375.924L411.493 374.005L412.008 374.014L411.684 375.648Z' fill='#5179E0' />
      <path d='M247.666 161.563L246.561 161.955L246.351 161.331L247.589 160.902L247.666 161.563Z' fill='#5179E0' />
      <path d='M177.037 101.808L176.733 103.371L176.18 102.978L176.38 101.353L177.037 101.808Z' fill='#5179E0' />
      <path d='M119.638 188.628L118.361 189.931L118.209 189.788L119.419 188.405L119.638 188.628Z' fill='#5179E0' />
      <path d='M480.398 180.621L479.531 180.71L478.807 179.407L480.893 179.425L480.398 180.621Z' fill='#5179E0' />
      <path d='M119.123 307.199L118.009 307.235L118.209 306.797L119.295 306.53L119.123 307.199Z' fill='#5179E0' />
      <path d='M203.875 48.3742L203.428 49.6506L202.771 48.7669L203.266 47.2048L203.875 48.3742Z' fill='#5179E0' />
      <path d='M490.788 145.611L488.807 145.227L487.807 143.058L489.455 143.201L490.788 145.611Z' fill='#5179E0' />
      <path d='M445.484 81.4202L445.388 81.8754L445.217 80.5811L445.703 79.7152L445.484 81.4202Z' fill='#5179E0' />
      <path d='M56.1331 216.729L55.7426 218.523L55.6188 218.505L55.8664 216.693L56.1331 216.729Z' fill='#5179E0' />
      <path d='M389.427 350.644L388.579 350.332L388.989 348.984L389.284 348.886L389.427 350.644Z' fill='#5179E0' />
      <path d='M129.095 304.325L128.047 304.628L128.218 304.164L129.228 303.655L129.095 304.325Z' fill='#5179E0' />
      <path d='M67.4568 214.943L66.7045 216.648L66.5807 216.613L67.2187 214.872L67.4568 214.943Z' fill='#5179E0' />
      <path d='M450.531 138.122L448.217 137.872L446.798 136.14L448.922 136.301L450.531 138.122Z' fill='#5179E0' />
      <path d='M140.856 159.01L139.276 161.795L138.561 161.509L140.085 158.287L140.856 159.01Z' fill='#5179E0' />
      <path d='M169.694 119.751L168.666 121.706L168.018 121.259L168.704 119.599L169.694 119.751Z' fill='#5179E0' />
      <path d='M76.4568 350.868L74.6282 351.26L74.4187 350.752L76.2282 350.278L76.4568 350.868Z' fill='#5179E0' />
      <path d='M204.742 456.763L203.228 457.102L202.847 455.549L204.485 454.888L204.742 456.763Z' fill='#5179E0' />
      <path d='M222.656 148.905L222.542 150.19L222.228 150.047L222.351 148.637L222.656 148.905Z' fill='#5179E0' />
      <path d='M266.656 393.501L265.923 394.17L266.17 393.51L266.427 392.501L266.656 393.501Z' fill='#5179E0' />
      <path d='M499.398 361.66L498.76 363.668L499.274 360.473L499.683 358.616L499.398 361.66Z' fill='#5179E0' />
      <path d='M436.693 137.916L435.769 138.068L434.846 136.211L437.008 136.747L436.693 137.916Z' fill='#5179E0' />
      <path d='M507.398 149.896L505.95 150.369L503.512 147.762L505.417 147.905L507.398 149.896Z' fill='#5179E0' />
      <path d='M136.59 302.414L135.59 302.959L135.723 302.477L136.714 301.781L136.59 302.414Z' fill='#5179E0' />
      <path d='M296.132 28.6911L296.256 30.4497L295.503 30.2622L295.37 28.5751L296.132 28.6911Z' fill='#5179E0' />
      <path d='M61.3997 217.96L60.8759 219.728L60.7426 219.71L61.1426 217.916L61.3997 217.96Z' fill='#5179E0' />
      <path d='M219.466 178.96L218.342 179.496L218.066 178.978L219.351 178.389L219.466 178.96Z' fill='#5179E0' />
      <path d='M187.523 131.105L187.361 132.605L186.923 132.4L186.98 130.82L187.523 131.105Z' fill='#5179E0' />
      <path d='M551.493 143.879L550.988 143.674L550.95 142.13L551.559 142.29L551.493 143.879Z' fill='#5179E0' />
      <path d='M171.933 262.004L170.342 261.299L170.666 260.79L172.161 261.254L171.933 262.004Z' fill='#5179E0' />
      <path d='M309.446 366.757L308.465 366.685L308.837 365.605L309.408 365.4L309.446 366.757Z' fill='#5179E0' />
      <path d='M117.323 254.577L115.733 254.265L115.838 253.917L117.476 254.149L117.323 254.577Z' fill='#5179E0' />
      <path d='M440.084 35.7431L439.255 36.7161L439.208 35.1093L440.084 33.9667V35.7431Z' fill='#5179E0' />
      <path d='M225.332 85.3925L223.713 86.6243L222.818 86.1245L223.932 84.8569L225.332 85.3925Z' fill='#5179E0' />
      <path d='M202.875 141.005L202.742 142.406L202.342 142.246L202.428 140.728L202.875 141.005Z' fill='#5179E0' />
      <path d='M237.923 190.833L236.97 191.225L236.885 190.69L237.827 190.244L237.923 190.833Z' fill='#5179E0' />
      <path d='M208.123 265.878L207.075 265.342L207.399 264.628L208.361 264.985L208.123 265.878Z' fill='#5179E0' />
      <path d='M141.037 170.311L141.504 172.605L140.685 172.337L140.161 170.203L141.037 170.311Z' fill='#5179E0' />
      <path d='M118.895 196.251L117.666 197.608L117.514 197.465L118.676 196.037L118.895 196.251Z' fill='#5179E0' />
      <path d='M161.342 242.58L160.114 242.562L160.171 242.062L161.504 242.08L161.342 242.58Z' fill='#5179E0' />
      <path d='M234.466 194.162L233.142 194.43L233.027 193.823L234.361 193.493L234.466 194.162Z' fill='#5179E0' />
      <path d='M440.322 219.13L439.455 217.693L440.103 216.166L439.827 216.47L440.322 219.13Z' fill='#5179E0' />
      <path d='M502.417 138.264L500.883 138.354L501.141 137.086L502.007 136.863L502.417 138.264Z' fill='#5179E0' />
      <path d='M191.218 114.716L191.009 116.216L190.494 115.868L190.628 114.261L191.218 114.716Z' fill='#5179E0' />
      <path d='M351.855 55.0244L350.884 56.292L349.008 55.7475L349.741 54.5602L351.855 55.0244Z' fill='#5179E0' />
      <path d='M280.646 210.676L277.932 209.927L277.694 209.016L280.351 209.605L280.646 210.676Z' fill='#5179E0' />
      <path d='M182.742 335.434L182.171 337.38L182.209 336.844L182.761 334.836L182.742 335.434Z' fill='#5179E0' />
      <path d='M283.161 250.025L281.542 249.257L281.561 248.052L283.646 249.177L283.161 250.025Z' fill='#5179E0' />
      <path d='M395.265 124.803L394.512 125.964L394.684 124.526L394.874 123.812L395.265 124.803Z' fill='#5179E0' />
      <path d='M121.276 290.506L119.447 290.256L119.571 289.676L121.476 289.837L121.276 290.506Z' fill='#5179E0' />
      <path d='M130.78 207.106L129.542 208.382L129.409 208.222L130.628 206.892L130.78 207.106Z' fill='#5179E0' />
      <path d='M287.665 220.21L285.151 219.255L284.913 218.184L287.57 219.13L287.665 220.21Z' fill='#5179E0' />
      <path d='M523.569 348.556L522.788 350.939L523.388 347.743L524.102 345.101L523.569 348.556Z' fill='#5179E0' />
      <path d='M300.284 505.868L299.894 507.243L299.608 506.136L300.427 504.913L300.284 505.868Z' fill='#5179E0' />
      <path d='M505.979 151.235L504.75 151.288L504.483 149.949L506.026 149.815L505.979 151.235Z' fill='#5179E0' />
      <path d='M209.323 127.526L209.17 128.936L208.732 128.633L208.828 127.097L209.323 127.526Z' fill='#5179E0' />
      <path d='M247.256 464.19L246.637 465.672L246.342 465.475L246.97 463.958L247.256 464.19Z' fill='#5179E0' />
      <path d='M275.113 261.718L273.551 260.915L273.827 259.924L275.475 260.683L275.113 261.718Z' fill='#5179E0' />
      <path d='M253.427 207.632L250.704 207.552L250.78 206.838L252.923 206.659L253.427 207.632Z' fill='#5179E0' />
      <path d='M233.456 273.519L232.875 273.394L233.018 272.493L233.58 272.528L233.456 273.519Z' fill='#5179E0' />
      <path d='M489.912 159.242L488.283 159.527L485.798 157.189L487.16 156.948L489.912 159.242Z' fill='#5179E0' />
      <path d='M351.084 36.0109L349.656 37.5552L347.417 37.2517L348.541 35.8234L351.084 36.0109Z' fill='#5179E0' />
      <path d='M274.284 241.134L272.056 240.232V239.241L274.465 240.134L274.284 241.134Z' fill='#5179E0' />
      <path d='M226.789 189.021L225.856 189.556L225.618 189.038L226.713 188.449L226.789 189.021Z' fill='#5179E0' />
      <path d='M244.675 202.866L243.894 203.205L243.589 202.518L244.894 202.25L244.675 202.866Z' fill='#5179E0' />
      <path d='M184.799 370.577L183.932 372.273L183.847 371.872L184.771 370.247L184.799 370.577Z' fill='#5179E0' />
      <path d='M227.342 191.154L226.028 191.574L225.885 190.993L227.132 190.494L227.342 191.154Z' fill='#5179E0' />
      <path d='M135.133 312.18L134.009 313.153L134.095 312.689L135.228 311.644L135.133 312.18Z' fill='#5179E0' />
      <path d='M290.075 464.368L289.351 465.074L289.218 463.654L290.361 463.056L290.075 464.368Z' fill='#5179E0' />
      <path d='M119.723 204.571L118.552 205.999L118.39 205.865L119.514 204.383L119.723 204.571Z' fill='#5179E0' />
      <path d='M454.35 94.0869L453.503 95.3188L452.522 94.0869L453.284 93.0782L454.35 94.0869Z' fill='#5179E0' />
      <path d='M207.732 454.496L206.761 455.683L206.266 455.094L207.399 454.032L207.732 454.496Z' fill='#5179E0' />
      <path d='M263.742 232.948L261.246 232.261L261.17 231.341L263.818 231.975L263.742 232.948Z' fill='#5179E0' />
      <path d='M265.351 269.279L265.456 269.672L265.161 268.556L265.675 268.476L265.351 269.279Z' fill='#5179E0' />
      <path d='M554.969 180.96L554.978 182.451L553.464 184.361L553.445 183.263L554.969 180.96Z' fill='#5179E0' />
      <path d='M251.208 310.529L250.142 310.288L250.342 309.323L251.542 309.556L251.208 310.529Z' fill='#5179E0' />
      <path d='M324.703 365.864L323.408 365.721L324.027 364.722L324.36 364.365L324.703 365.864Z' fill='#5179E0' />
      <path d='M180.371 115.314L179.666 116.975L178.98 116.582L179.513 114.957L180.371 115.314Z' fill='#5179E0' />
      <path d='M105.58 332.39L103.676 332.425L103.771 331.711L105.609 331.568L105.58 332.39Z' fill='#5179E0' />
      <path d='M207.342 88.6328L205.351 90.1414L204.218 89.6861L205.685 88.2133L207.342 88.6328Z' fill='#5179E0' />
      <path d='M210.685 87.7402L210.218 89.2041L209.742 88.4543L210.009 87.0617L210.685 87.7402Z' fill='#5179E0' />
      <path d='M570.293 353.581L569.655 356.215L570.34 352.921L570.721 350.341L570.293 353.581Z' fill='#5179E0' />
      <path d='M221.323 143.611L221.18 145.004L220.799 144.79L220.809 143.352L221.323 143.611Z' fill='#5179E0' />
      <path d='M136.695 179.139L135.523 181.665L134.837 181.228L135.933 178.202L136.695 179.139Z' fill='#5179E0' />
      <path d='M168.475 250.194L167.094 250.069L167.237 249.56L168.561 249.543L168.475 250.194Z' fill='#5179E0' />
      <path d='M287.151 222.406L286.78 222.799L286.799 222.147L287.094 221.71L287.151 222.406Z' fill='#5179E0' />
      <path d='M298.913 87.7312L297.837 88.5793L296.875 87.8027L297.627 86.4547L298.913 87.7312Z' fill='#5179E0' />
      <path d='M460.874 162.536L457.969 163.285L458.255 162.143L460.207 161.375L460.874 162.536Z' fill='#5179E0' />
      <path d='M362.608 271.511L362.417 270.957V269.109L362.808 269.743L362.608 271.511Z' fill='#5179E0' />
      <path d='M368.636 359.241L368.046 359.419L368.132 358.08L368.751 357.875L368.636 359.241Z' fill='#5179E0' />
      <path d='M403.036 179.817L401.646 179.103L401.989 177.032L403.379 177.711L403.036 179.817Z' fill='#5179E0' />
      <path d='M157.552 255.443L156.361 255.657L156.39 255.157L157.675 254.934L157.552 255.443Z' fill='#5179E0' />
      <path d='M123.295 210.873L122.152 212.337L121.99 212.203L123.095 210.694L123.295 210.873Z' fill='#5179E0' />
      <path d='M572.807 195.492L571.854 196.572L568.617 195.742L569.026 194.787L572.807 195.492Z' fill='#5179E0' />
      <path d='M203.199 381.977L201.809 382.182L201.856 381.2L203.409 380.941L203.199 381.977Z' fill='#5179E0' />
      <path d='M188.313 431.563L187.047 432.331L186.723 431.59L188.009 430.769L188.313 431.563Z' fill='#5179E0' />
      <path d='M184.675 110.378L184.152 112.011L183.437 111.628L183.847 109.967L184.675 110.378Z' fill='#5179E0' />
      <path d='M163.837 94.1048L163.742 95.9347L162.971 95.5955L162.942 93.6852L163.837 94.1048Z' fill='#5179E0' />
      <path d='M176.904 361.749L176.161 364.222L176.152 363.909L176.866 361.374L176.904 361.749Z' fill='#5179E0' />
      <path d='M340.427 453.255L340.265 454.228L340.037 452.728L340.751 451.961L340.427 453.255Z' fill='#5179E0' />
      <path d='M229.561 187.244L228.323 187.78L228.237 187.209L229.237 186.593L229.561 187.244Z' fill='#5179E0' />
      <path d='M457.341 177.729L455.227 178.398L454.769 177.363L457.646 176.39L457.341 177.729Z' fill='#5179E0' />
      <path d='M128.485 363.472L126.714 363.615L126.799 362.883L128.428 362.606L128.485 363.472Z' fill='#5179E0' />
      <path d='M303.827 69.173L303.961 70.7708L302.827 70.6012L303.522 68.3071L303.827 69.173Z' fill='#5179E0' />
      <path d='M265.399 216.746L265.208 217.264L264.894 216.586L265.637 216.139L265.399 216.746Z' fill='#5179E0' />
      <path d='M363.57 32.6278L362.075 33.7436L360.275 32.7616L362.246 31.6369L363.57 32.6278Z' fill='#5179E0' />
      <path d='M90.0663 282.597L88.2663 282.749L88.2758 282.383L90.1329 282.187L90.0663 282.597Z' fill='#5179E0' />
      <path d='M384.446 257.032L384.055 256.532L384.17 254.791L384.674 255.309L384.446 257.032Z' fill='#5179E0' />
      <path d='M421.56 185.102L419.789 186.325L420.131 185.039L421.37 183.95L421.56 185.102Z' fill='#5179E0' />
      <path d='M190.18 191.949L190.58 193.404L189.894 193.341L189.428 191.931L190.18 191.949Z' fill='#5179E0' />
      <path d='M197.361 102.567L196.894 104.219L196.275 103.665L196.542 102.139L197.361 102.567Z' fill='#5179E0' />
      <path d='M280.818 329.667L280.942 330.64L280.789 329.498L280.98 328.73L280.818 329.667Z' fill='#5179E0' />
      <path d='M242.427 180.683L241.285 181.156L241.027 180.487L242.304 179.951L242.427 180.683Z' fill='#5179E0' />
      <path d='M452.96 23.237L451.798 24.7635L451.674 22.9692L453.141 20.9161L452.96 23.237Z' fill='#5179E0' />
      <path d='M365.198 53.2927L363.941 54.4263L362.103 53.3909L363.398 52.3643L365.198 53.2927Z' fill='#5179E0' />
      <path d='M94.771 330.31L93.0091 330.756L92.8758 330.265L94.6281 329.738L94.771 330.31Z' fill='#5179E0' />
      <path d='M385.446 187.2L384.351 186.503L384.779 184.441L386.027 185.325L385.446 187.2Z' fill='#5179E0' />
      <path d='M334.808 151.949L334.284 151.708L334.294 150.467L334.998 150.708L334.808 151.949Z' fill='#5179E0' />
      <path d='M220.761 169.329L220.789 170.694L220.304 170.659L220.132 169.275L220.761 169.329Z' fill='#5179E0' />
      <path d='M237.704 336.915L236.694 337.014L236.742 336.032L237.98 335.969L237.704 336.915Z' fill='#5179E0' />
      <path d='M341.884 271.225L341.551 270.618L341.703 269.092L341.627 269.386L341.884 271.225Z' fill='#5179E0' />
      <path d='M165.085 383.244L164.171 386.011L164.056 385.851L164.99 383.11L165.085 383.244Z' fill='#5179E0' />
      <path d='M447.122 -23.4309L445.179 -20.7976L444.96 -22.8953L447.198 -26.2338L447.122 -23.4309Z' fill='#5179E0' />
      <path d='M185.761 94.3547L183.209 96.3007L182.075 95.8723L183.961 94.0602L185.761 94.3547Z' fill='#5179E0' />
      <path d='M157.609 161.946L158.094 163.955L157.228 163.696L156.695 161.884L157.609 161.946Z' fill='#5179E0' />
      <path d='M449.512 1.30453L448.027 3.34872L447.855 1.37595L449.693 -1.32879L449.512 1.30453Z' fill='#5179E0' />
      <path d='M246.294 343.95L244.989 343.887L245.275 342.932L246.389 342.834L246.294 343.95Z' fill='#5179E0' />
      <path d='M119.161 336.121L117.714 337.585L117.704 337.192L119.18 335.737L119.161 336.121Z' fill='#5179E0' />
      <path d='M227.485 436.589L226.189 437.321L226.123 436.5L227.104 435.643L227.485 436.589Z' fill='#5179E0' />
      <path d='M243.675 465.85L242.999 467.502L242.904 467.752L243.266 465.654L243.675 465.85Z' fill='#5179E0' />
      <path d='M177.666 109.137L177.637 110.931L176.904 110.592L176.79 108.753L177.666 109.137Z' fill='#5179E0' />
      <path d='M258.865 74.886L257.427 75.975L256.085 75.2073L257.046 73.9576L258.865 74.886Z' fill='#5179E0' />
      <path d='M227.208 317.75L225.837 317.732L225.97 316.831L227.418 316.786L227.208 317.75Z' fill='#5179E0' />
      <path d='M203.123 304.164H201.285L201.409 303.253L203.418 303.155L203.123 304.164Z' fill='#5179E0' />
      <path d='M73.2092 312.43L71.2663 313.01L71.1997 312.653L73.152 312.055L73.2092 312.43Z' fill='#5179E0' />
      <path d='M150.199 394.001L149.066 396.759L148.856 396.688L150.009 393.992L150.199 394.001Z' fill='#5179E0' />
      <path d='M140.285 199.599L139.447 201.732L138.723 201.241L139.456 198.572L140.285 199.599Z' fill='#5179E0' />
      <path d='M364.636 256.711L364.208 256.237L364.427 254.773L364.484 254.979L364.636 256.711Z' fill='#5179E0' />
      <path d='M378.284 432.929L378.274 433.849L377.846 432.438L378.789 431.706L378.284 432.929Z' fill='#5179E0' />
      <path d='M199.532 322.642L197.99 322.785L197.98 321.865L199.837 321.642L199.532 322.642Z' fill='#5179E0' />
      <path d='M228.913 186.548L227.961 187.253L227.723 186.7L228.656 185.923L228.913 186.548Z' fill='#5179E0' />
      <path d='M368.103 186.762L366.56 185.736L367.055 184.218L368.294 184.62L368.103 186.762Z' fill='#5179E0' />
      <path d='M270.78 308.154L270.551 308.734L270.37 307.672L271.037 307.306L270.78 308.154Z' fill='#5179E0' />
      <path d='M277.656 323.32L277.446 324.07L277.456 323.115L277.675 322.356L277.656 323.32Z' fill='#5179E0' />
      <path d='M104.914 345.181L103.228 346.681L103.171 346.324L104.866 344.869L104.914 345.181Z' fill='#5179E0' />
      <path d='M75.0663 328.06L73.1044 328.891L73.0187 328.551L74.9711 327.712L75.0663 328.06Z' fill='#5179E0' />
      <path d='M215.637 366.15L213.828 366.266L213.961 365.061L216.466 364.775L215.637 366.15Z' fill='#5179E0' />
      <path d='M245.57 193.841L244.627 194.35L244.389 193.68L245.494 193.109L245.57 193.841Z' fill='#5179E0' />
      <path d='M87.1044 350.323L85.1996 351.689L85.0853 351.367L86.9901 350.037L87.1044 350.323Z' fill='#5179E0' />
      <path d='M338.703 170.739L336.979 170.168L337.208 168.963L338.579 169.106L338.703 170.739Z' fill='#5179E0' />
      <path d='M151.98 277.304L150.828 278.018L150.78 277.509L152.056 276.831L151.98 277.304Z' fill='#5179E0' />
      <path d='M81.1139 339.361L79.1711 340.459L79.0758 340.12L80.9996 339.058L81.1139 339.361Z' fill='#5179E0' />
      <path d='M116.99 307.547L115.323 307.922L115.276 307.431L116.923 306.967L116.99 307.547Z' fill='#5179E0' />
      <path d='M546.712 323.767L546.397 325.57L546.664 323.463L547.14 321.151L546.712 323.767Z' fill='#5179E0' />
      <path d='M195.809 242.017L194.056 243.196L193.847 242.232L195.447 241.027L195.809 242.017Z' fill='#5179E0' />
      <path d='M141.695 277.572L140.19 277.857L140.171 277.331L141.714 276.983L141.695 277.572Z' fill='#5179E0' />
      <path d='M251.751 335.773L250.646 335.862L250.732 334.836L251.97 334.728L251.751 335.773Z' fill='#5179E0' />
      <path d='M311.98 415.772L311.446 416.763L311.56 415.987L311.808 414.88L311.98 415.772Z' fill='#5179E0' />
      <path d='M134.714 289.658L133.142 289.962V289.471L134.685 289.06L134.714 289.658Z' fill='#5179E0' />
      <path d='M139.514 392.947L138.247 395.572L137.99 395.491L139.266 392.974L139.514 392.947Z' fill='#5179E0' />
      <path d='M191.475 128.249L191.561 129.972L190.837 129.704L190.618 127.945L191.475 128.249Z' fill='#5179E0' />
      <path d='M365.294 379.781L364.703 380.388L364.998 379.325L365.046 378.549L365.294 379.781Z' fill='#5179E0' />
      <path d='M229.113 373.55L228.17 374.086L228.056 373.166L229.228 372.666L229.113 373.55Z' fill='#5179E0' />
      <path d='M428.465 116.269L428.008 117.689L428.398 115.341L428.169 114.752L428.465 116.269Z' fill='#5179E0' />
      <path d='M341.97 97.6932L341.408 99.0769L340.303 97.8629L340.789 96.515L341.97 97.6932Z' fill='#5179E0' />
      <path d='M571.797 314.983L571.121 317.527L571.712 314.84L572.016 312.546L571.797 314.983Z' fill='#5179E0' />
      <path d='M184.418 146.307L184.656 148.048L183.866 147.843L183.504 146.129L184.418 146.307Z' fill='#5179E0' />
      <path d='M160.409 426.904L159.037 427.921L158.685 427.306L160.066 426.243L160.409 426.904Z' fill='#5179E0' />
      <path d='M262.18 208.677L258.685 208.373L258.227 207.365L261.837 207.543L262.18 208.677Z' fill='#5179E0' />
      <path d='M156.79 303.218L155.114 303.137L155.342 302.486L156.799 302.352L156.79 303.218Z' fill='#5179E0' />
      <path d='M250.951 336.719L250.37 337.433L250.656 336.692L250.704 335.576L250.951 336.719Z' fill='#5179E0' />
      <path d='M148.571 113.672L146.009 116.618L145.047 116.144L147.152 113.377L148.571 113.672Z' fill='#5179E0' />
      <path d='M379.589 277.286L379.541 277.081L379.389 275.206L379.827 275.599L379.589 277.286Z' fill='#5179E0' />
      <path d='M244.685 283.142L242.637 282.731L242.618 281.598L245.294 282.16L244.685 283.142Z' fill='#5179E0' />
      <path d='M392.179 181.638L391.065 180.889L391.427 178.951L392.589 179.657L392.179 181.638Z' fill='#5179E0' />
      <path d='M445.712 81.8665L445.474 83.1252L445.493 80.7685L445.807 79.135L445.712 81.8665Z' fill='#5179E0' />
      <path d='M249.561 269.574L246.913 268.904L247.066 267.887L249.846 268.449L249.561 269.574Z' fill='#5179E0' />
      <path d='M160.428 200.331L159.504 201.795L158.809 201.402L159.79 199.358L160.428 200.331Z' fill='#5179E0' />
      <path d='M218.57 303.2L217.761 303.512L217.885 302.655L218.609 302.236L218.57 303.2Z' fill='#5179E0' />
      <path d='M266.894 219.237L263.656 218.71L263.246 217.621L266.732 218.085L266.894 219.237Z' fill='#5179E0' />
      <path d='M398.56 482.623L398.265 483.962L398.227 483.052L398.608 481.686L398.56 482.623Z' fill='#5179E0' />
      <path d='M217.57 216.264L214.18 217.237V216.247L217.209 215.158L217.57 216.264Z' fill='#5179E0' />
      <path d='M275.218 229.788L272.018 228.878L271.808 227.762L275.008 228.512L275.218 229.788Z' fill='#5179E0' />
      <path d='M246.294 259.156L243.494 258.639L243.408 257.567L246.685 258.094L246.294 259.156Z' fill='#5179E0' />
      <path d='M130.409 386.235L129.057 388.636L128.771 388.511L130.133 386.217L130.409 386.235Z' fill='#5179E0' />
      <path d='M400.255 375.585L399.798 376.246L400.036 375.094L400.093 374.327L400.255 375.585Z' fill='#5179E0' />
      <path d='M216.742 353.242L214.209 353.349L214.799 352.091L217.256 351.841L216.742 353.242Z' fill='#5179E0' />
      <path d='M220.208 344.36L218.828 344.601L218.799 343.601L220.447 343.307L220.208 344.36Z' fill='#5179E0' />
      <path d='M289.722 426.208L288.827 427.073L288.77 426.19L289.494 425.252L289.722 426.208Z' fill='#5179E0' />
      <path d='M206.923 430.403L205.209 430.965L205.132 429.385L207.066 428.564L206.923 430.403Z' fill='#5179E0' />
      <path d='M256.189 210.4L255.608 211.016L255.313 210.364L256.199 209.694L256.189 210.4Z' fill='#5179E0' />
      <path d='M252.742 245.847L249.485 245.195L249.456 244.178L252.77 244.669L252.742 245.847Z' fill='#5179E0' />
      <path d='M214.351 191.359L213.247 192.234L212.866 191.627L213.98 190.663L214.351 191.359Z' fill='#5179E0' />
      <path d='M185.028 342.896L183.266 343.262L183.199 342.164L185.361 341.566L185.028 342.896Z' fill='#5179E0' />
      <path d='M472.322 242.089L471.674 241.044L471.893 238.518L472.541 239.411L472.322 242.089Z' fill='#5179E0' />
      <path d='M196.171 351.546L194.447 351.93L194.494 350.877L196.218 350.386L196.171 351.546Z' fill='#5179E0' />
      <path d='M240.256 72.5829L238.466 73.8059L236.875 72.9578L238.38 71.7081L240.256 72.5829Z' fill='#5179E0' />
      <path d='M194.237 191.538L193.218 192.6L192.656 192.082L193.828 190.779L194.237 191.538Z' fill='#5179E0' />
      <path d='M313.322 157.296L312.037 157.269L312.237 156.171L313.16 156.019L313.322 157.296Z' fill='#5179E0' />
      <path d='M146.371 308.502L145.199 309.85L145.104 309.422L146.333 308.101L146.371 308.502Z' fill='#5179E0' />
      <path d='M568.188 280.607L567.712 282.606L568.093 280.527L568.359 278.563L568.188 280.607Z' fill='#5179E0' />
      <path d='M220.523 425.333L219.399 426.011L218.894 424.94L220.58 424.021L220.523 425.333Z' fill='#5179E0' />
      <path d='M409.874 209.721L409.922 210.623L409.189 210.06L409.341 208.873L409.874 209.721Z' fill='#5179E0' />
      <path d='M347.189 44.259L347.656 44.2233L346.884 43.045L347.77 41.956L347.189 44.259Z' fill='#5179E0' />
      <path d='M491.274 200.098L490.522 200.768L490.417 199.474L491.522 198.608L491.274 200.098Z' fill='#5179E0' />
      <path d='M196.256 233.439L194.647 234.662L194.304 233.751L195.828 232.43L196.256 233.439Z' fill='#5179E0' />
      <path d='M233.827 248.498L232.732 249.266L232.561 248.587L233.437 247.811L233.827 248.498Z' fill='#5179E0' />
      <path d='M250.494 363.195L249.361 363.561L249.313 362.499L250.666 362.106L250.494 363.195Z' fill='#5179E0' />
      <path d='M321.646 452.71L321.322 454.165V453.853L321.532 452.318L321.646 452.71Z' fill='#5179E0' />
      <path d='M215.637 369.944L214.685 370.756L214.732 370.015L215.513 369.051L215.637 369.944Z' fill='#5179E0' />
      <path d='M567.331 200.322L567.245 201.893L566.254 203.937L566.312 202.41L567.331 200.322Z' fill='#5179E0' />
      <path d='M131.704 368.863L130.39 371.006L130.161 370.854L131.466 368.712L131.704 368.863Z' fill='#5179E0' />
      <path d='M255.78 445.542L254.98 446.712L254.656 446.042L255.504 444.846L255.78 445.542Z' fill='#5179E0' />
      <path d='M464.388 76.4124L464.284 77.6978L464.245 74.9752L464.703 72.6365L464.388 76.4124Z' fill='#5179E0' />
      <path d='M269.675 381.102L269.008 381.789L268.77 380.727L269.865 380.111L269.675 381.102Z' fill='#5179E0' />
      <path d='M216.799 207.195L213.77 208.311L213.713 207.284L216.228 206.151L216.799 207.195Z' fill='#5179E0' />
      <path d='M342.522 108.378L340.332 108.593L340.379 106.745L342.494 106.165L342.522 108.378Z' fill='#5179E0' />
      <path d='M529.064 178.827L528.55 179.416L528.788 177.898L528.969 177.336L529.064 178.827Z' fill='#5179E0' />
      <path d='M139.523 341.307L138.295 343.164L138.142 342.878L139.38 341.031L139.523 341.307Z' fill='#5179E0' />
      <path d='M316.751 111.395L316.446 113.234L315.494 111.851L315.732 110.271L316.751 111.395Z' fill='#5179E0' />
      <path d='M347.275 151.842L345.875 151.779L345.97 150.503L347.141 150.404L347.275 151.842Z' fill='#5179E0' />
      <path d='M148.618 380.905L147.056 381.637L146.942 381.012L148.314 380.111L148.618 380.905Z' fill='#5179E0' />
      <path d='M391.798 54.7655L391.579 56.3902L389.808 55.6225L390.065 54.2478L391.798 54.7655Z' fill='#5179E0' />
      <path d='M120.733 147.539L118.99 151.235L118.123 150.529L119.638 146.745L120.733 147.539Z' fill='#5179E0' />
      <path d='M489.76 186.128L488.645 186.985L488.722 185.584L489.845 184.602L489.76 186.128Z' fill='#5179E0' />
      <path d='M208.437 210.418L206.723 211.515L206.409 210.65L207.951 209.498L208.437 210.418Z' fill='#5179E0' />
      <path d='M358.122 298.549L357.808 298.326L357.903 296.568L358.141 296.693L358.122 298.549Z' fill='#5179E0' />
      <path d='M244.894 322.963L244.104 323.588L244.008 322.767L244.894 322.124V322.963Z' fill='#5179E0' />
      <path d='M358.589 433.84L358.132 435.081L358.408 434.545L358.341 433.161L358.589 433.84Z' fill='#5179E0' />
      <path d='M246.761 183.254L244.027 183.816L243.951 182.888L245.98 182.201L246.761 183.254Z' fill='#5179E0' />
      <path d='M458.75 175.176L457.084 176.854L457.903 174.854L458.074 174.095L458.75 175.176Z' fill='#5179E0' />
      <path d='M286.303 451.398L285.503 452.541L285.208 451.818L285.961 450.613L286.303 451.398Z' fill='#5179E0' />
      <path d='M334.76 102.299L334.722 103.853L333.694 102.987L333.789 101.255L334.76 102.299Z' fill='#5179E0' />
      <path d='M306.751 233.332L305.57 233.073L305.675 231.716L307.056 232.011L306.751 233.332Z' fill='#5179E0' />
      <path d='M285.361 257.362L285.103 258.415L285.008 258.005L285.275 256.907L285.361 257.362Z' fill='#5179E0' />
      <path d='M213.256 204.642L212.151 205.579L211.647 204.919L212.923 203.794L213.256 204.642Z' fill='#5179E0' />
      <path d='M235.58 312.082L234.666 312.626L234.532 311.787L235.618 311.234L235.58 312.082Z' fill='#5179E0' />
      <path d='M407.893 135.89L406.951 136.301L406.512 135.015L408.093 134.471L407.893 135.89Z' fill='#5179E0' />
      <path d='M150.171 226.896L149.38 228.869L148.542 228.199L149.199 225.423L150.171 226.896Z' fill='#5179E0' />
      <path d='M132.895 218.603L132.237 221.433L131.285 220.469L131.742 217.023L132.895 218.603Z' fill='#5179E0' />
      <path d='M230.199 372.318L228.751 372.836L228.742 371.783L230.123 371.184L230.199 372.318Z' fill='#5179E0' />
      <path d='M123.752 196.305L122.838 199.688L121.923 198.643L122.666 194.975L123.752 196.305Z' fill='#5179E0' />
      <path d='M324.818 100.943L324.903 102.647L324.303 102.085L324.237 100.523L324.818 100.943Z' fill='#5179E0' />
      <path d='M178.247 218.844L177.256 220.21L176.561 219.612L177.647 217.782L178.247 218.844Z' fill='#5179E0' />
      <path d='M227.904 215.372L226.904 216.229L226.504 215.55L227.589 214.568L227.904 215.372Z' fill='#5179E0' />
      <path d='M184.437 386.699L182.704 387.449L182.494 386.074L184.313 385.145L184.437 386.699Z' fill='#5179E0' />
      <path d='M317.903 280.178L316.76 279.741L317.275 278.456L317.818 278.447L317.903 280.178Z' fill='#5179E0' />
      <path d='M116.076 178.452L115.066 182.067L114.133 181.183L114.942 177.139L116.076 178.452Z' fill='#5179E0' />
      <path d='M309.732 442.543L309.494 443.989L309.189 443.445L309.76 442.061L309.732 442.543Z' fill='#5179E0' />
      <path d='M206.866 382.959L205.38 383.619L205.151 382.476L206.818 381.673L206.866 382.959Z' fill='#5179E0' />
      <path d='M371.474 437.312L370.627 438.526L371.017 437.785L370.922 436.518L371.474 437.312Z' fill='#5179E0' />
      <path d='M325.113 94.1048L324.684 95.7473L323.322 94.2744L323.675 92.7747L325.113 94.1048Z' fill='#5179E0' />
      <path d='M272.761 253.417L272.484 254.515L272.227 254.086L272.694 252.961L272.761 253.417Z' fill='#5179E0' />
      <path d='M232.78 74.27L230.618 75.4305L228.904 74.4039L230.742 73.1899L232.78 74.27Z' fill='#5179E0' />
      <path d='M205.38 367.623L202.923 368.23L203.361 366.712L205.485 366.078L205.38 367.623Z' fill='#5179E0' />
      <path d='M424.722 96.6042L424.408 98.2646L423.57 97.5058L423.617 96.14L424.722 96.6042Z' fill='#5179E0' />
      <path d='M180.418 367.105L179.247 368.132L179.113 367.489L180.218 366.373L180.418 367.105Z' fill='#5179E0' />
      <path d='M198.456 214.06L197.075 215.407L196.561 214.55L197.885 213.078L198.456 214.06Z' fill='#5179E0' />
      <path d='M293.932 184.718L291.246 184.486L291.351 183.424L293.227 183.209L293.932 184.718Z' fill='#5179E0' />
      <path d='M347.503 102.264L347.132 104.058L345.922 102.549L346.227 101.05L347.503 102.264Z' fill='#5179E0' />
      <path d='M232.227 243.785L231.428 244.767L230.999 244.16L231.837 243.071L232.227 243.785Z' fill='#5179E0' />
      <path d='M359.532 115.743L358.351 117.457L357.436 115.359L358.484 114.172L359.532 115.743Z' fill='#5179E0' />
      <path d='M221.589 395.027L219.589 395.581L219.666 394.09L222.037 393.313L221.589 395.027Z' fill='#5179E0' />
      <path d='M445.941 241.857L444.007 240.732L444.455 238.902L445.617 239.304L445.941 241.857Z' fill='#5179E0' />
      <path d='M234.085 275.537L233.389 276.626L232.923 276.108L233.57 274.939L234.085 275.537Z' fill='#5179E0' />
      <path d='M264.77 287.48L262.827 287.293L263.075 286.115L264.865 286.123L264.77 287.48Z' fill='#5179E0' />
      <path d='M253.942 72.0384L252.551 72.1187L252.542 70.6726L253.78 70.5209L253.942 72.0384Z' fill='#5179E0' />
      <path d='M381.579 275.903L380.884 276.001L380.446 274.207L382.284 274.465L381.579 275.903Z' fill='#5179E0' />
      <path d='M286.97 257.567L285.608 257.559L285.751 256.318L287.037 256.211L286.97 257.567Z' fill='#5179E0' />
      <path d='M395.293 297.398L395.341 297.755L395.017 296.005L395.722 295.844L395.293 297.398Z' fill='#5179E0' />
      <path d='M233.037 188.976L231.17 190.065L230.98 189.038L232.342 188.012L233.037 188.976Z' fill='#5179E0' />
      <path d='M300.78 110.565L300.58 112.556L299.494 111.021L299.656 109.11L300.78 110.565Z' fill='#5179E0' />
      <path d='M201.056 77.68L198.371 79.0993L197.037 78.2156L199.313 76.7248L201.056 77.68Z' fill='#5179E0' />
      <path d='M460.312 242.562L459.626 244.562L460.141 241.91L459.998 240.902L460.312 242.562Z' fill='#5179E0' />
      <path d='M321.865 274.885L321.294 275.055L321.427 273.689L321.827 273.403L321.865 274.885Z' fill='#5179E0' />
      <path d='M360.113 79.8402L360.008 81.447L359.77 81.5809L359.722 80.108L360.113 79.8402Z' fill='#5179E0' />
      <path d='M259.732 221.228L257.608 221.54L257.17 220.353L259.856 219.978L259.732 221.228Z' fill='#5179E0' />
      <path d='M218.028 333.925L216.132 334.389L216.094 333.166L218.189 332.577L218.028 333.925Z' fill='#5179E0' />
      <path d='M325.17 311.1L323.856 310.939L324.427 309.6L324.865 309.306L325.17 311.1Z' fill='#5179E0' />
      <path d='M341.894 114.493L341.865 116.323L340.998 115.341L341.075 113.529L341.894 114.493Z' fill='#5179E0' />
      <path d='M404.512 477.919L404.322 479.419L403.922 479.142L404.408 477.508L404.512 477.919Z' fill='#5179E0' />
      <path d='M323.418 262.272L323.018 262.557L322.989 261.183L323.494 260.879L323.418 262.272Z' fill='#5179E0' />
      <path d='M242.332 214.675L239.685 215.318L239.427 214.193L242.056 213.39L242.332 214.675Z' fill='#5179E0' />
      <path d='M266.427 237.661L264.904 238.001L264.551 236.786L266.675 236.421L266.427 237.661Z' fill='#5179E0' />
      <path d='M262.97 282.821L261.123 282.955L261.361 281.785L262.818 281.455L262.97 282.821Z' fill='#5179E0' />
      <path d='M322.046 117.546L321.218 119.295L320.122 117.439L320.875 115.653L322.046 117.546Z' fill='#5179E0' />
      <path d='M181.828 237.51L181.047 239.313L180.133 238.402L180.828 236.358L181.828 237.51Z' fill='#5179E0' />
      <path d='M257.018 362.222L254.637 362.668L255.113 361.258L256.789 360.687L257.018 362.222Z' fill='#5179E0' />
      <path d='M326.789 117.992L325.513 119.572L324.446 117.367L325.608 115.761L326.789 117.992Z' fill='#5179E0' />
      <path d='M408.236 251.658L406.865 251.685L407.465 250.132L407.741 249.801L408.236 251.658Z' fill='#5179E0' />
      <path d='M249.085 340.236L247.008 340.736L247.285 339.424L248.827 338.808L249.085 340.236Z' fill='#5179E0' />
      <path d='M320.903 158.117L319.903 158.858L319.865 157.555L320.551 156.814L320.903 158.117Z' fill='#5179E0' />
      <path d='M305.141 259.174L304.599 259.683L304.17 258.335L305.513 257.853L305.141 259.174Z' fill='#5179E0' />
      <path d='M387.865 274.769L385.751 274.858L386.132 273.296L387.57 272.975L387.865 274.769Z' fill='#5179E0' />
      <path d='M350.227 454.478L349.379 455.87L348.656 454.308L349.827 452.461L350.227 454.478Z' fill='#5179E0' />
      <path d='M140.437 112.636L138.342 115.261L137.342 114.341L139.152 111.717L140.437 112.636Z' fill='#5179E0' />
      <path d='M400.208 485.301L399.731 486.881L398.97 485.872L399.693 483.962L400.208 485.301Z' fill='#5179E0' />
      <path d='M318.265 173.015L317.751 173.89L317.722 172.667L318.008 171.801L318.265 173.015Z' fill='#5179E0' />
      <path d='M412.855 276.706L410.693 276.661L410.598 274.84L413.008 274.787L412.855 276.706Z' fill='#5179E0' />
      <path d='M276.77 213.363L274.704 213.89L274.589 212.578L276.494 211.926L276.77 213.363Z' fill='#5179E0' />
      <path d='M177.056 242.749L176.704 244.865L175.409 243.901L175.637 241.625L177.056 242.749Z' fill='#5179E0' />
      <path d='M454.388 277.965L452.855 277.759L453.236 275.813L454.179 275.688L454.388 277.965Z' fill='#5179E0' />
      <path d='M359.675 315.724L357.989 316.402L357.808 314.822L360.036 313.947L359.675 315.724Z' fill='#5179E0' />
      <path d='M368.713 301.843L368.646 302.843L368.56 301.665L368.817 300.629L368.713 301.843Z' fill='#5179E0' />
      <path d='M321.827 207.409L320.703 207.757L320.665 206.267L321.789 205.838L321.827 207.409Z' fill='#5179E0' />
      <path d='M336.541 157.697L335.96 158.724L336.198 157.251L336.113 156.43L336.541 157.697Z' fill='#5179E0' />
      <path d='M279.189 261.165L277.827 261.933L277.675 260.665L278.98 259.808L279.189 261.165Z' fill='#5179E0' />
      <path d='M338.417 307.431L336.36 308.484L336.77 306.851L337.865 305.914L338.417 307.431Z' fill='#5179E0' />
      <path d='M352.141 385.681L351.998 386.94L351.808 385.681L352.313 384.422L352.141 385.681Z' fill='#5179E0' />
      <path d='M294.332 196.742L293.761 197.974L293.599 196.93L293.942 195.707L294.332 196.742Z' fill='#5179E0' />
      <path d='M228.056 229.467L227.294 231.127L226.532 230.19L227.161 228.494L228.056 229.467Z' fill='#5179E0' />
      <path d='M135.209 192.645L134.561 195.457L133.247 194.368L133.695 191.127L135.209 192.645Z' fill='#5179E0' />
      <path d='M124.352 163.446L123.409 166.624L122.161 165.437L122.914 162.17L124.352 163.446Z' fill='#5179E0' />
      <path d='M330.608 326.016L329.827 327.087L329.379 325.82L330.627 324.499L330.608 326.016Z' fill='#5179E0' />
      <path d='M345.598 404.346L344.16 405.81L343.875 403.891L345.732 401.972L345.598 404.346Z' fill='#5179E0' />
      <path d='M377.979 367.873L377.836 368.926L377.008 367.649L378.608 366.15L377.979 367.873Z' fill='#5179E0' />
      <path d='M163.79 232.064L163.618 234.367L162.095 233.448L162.009 230.708L163.79 232.064Z' fill='#5179E0' />
      <path d='M253.789 263.361L252.494 264.396L251.885 263.173L253.704 261.808L253.789 263.361Z' fill='#5179E0' />
      <path d='M327.932 182.656L327.732 183.924L327.837 182.781L327.827 181.487L327.932 182.656Z' fill='#5179E0' />
      <path d='M383.474 215.265L383.151 215.872L383.436 214.211L383.198 213.613L383.474 215.265Z' fill='#5179E0' />
      <path d='M368.046 341.522L366.027 342.655L365.998 340.834L368.37 339.442L368.046 341.522Z' fill='#5179E0' />
      <path d='M344.494 306.699L344.437 307.949L344.179 306.913L344.656 305.583L344.494 306.699Z' fill='#5179E0' />
      <path d='M374.446 293.033L373.408 294.077L373.589 292.56L374.074 291.542L374.446 293.033Z' fill='#5179E0' />
      <path d='M318.789 276.724L318.237 277.724L317.875 276.474L318.808 275.34L318.789 276.724Z' fill='#5179E0' />
      <path d='M338.656 219.585L338.065 220.246L337.684 218.719L338.865 217.925L338.656 219.585Z' fill='#5179E0' />
      <path d='M296.361 197.929L296.084 199.358L295.818 198.447L296.132 196.876L296.361 197.929Z' fill='#5179E0' />
      <path d='M324.798 317.331L323.541 318.679L323.408 317.188L324.351 315.858L324.798 317.331Z' fill='#5179E0' />
      <path d='M294.665 162.571L294.227 163.937L294.332 162.669L294.303 161.384L294.665 162.571Z' fill='#5179E0' />
      <path d='M238.78 222.924L238.218 224.691L237.427 223.843L237.846 222.022L238.78 222.924Z' fill='#5179E0' />
      <path d='M388.874 447.596L387.97 449.363L387.427 447.515L388.636 445.31L388.874 447.596Z' fill='#5179E0' />
      <path d='M354.598 329.667L354.322 331.077L354.122 330.122L354.56 328.614L354.598 329.667Z' fill='#5179E0' />
      <path d='M226.266 113.627L225.304 115.038L224.885 113.6L225.628 112.19L226.266 113.627Z' fill='#5179E0' />
      <path d='M347.475 292.774L346.998 294.006L346.837 292.783L347.351 291.462L347.475 292.774Z' fill='#5179E0' />
      <path d='M338.627 293.425L337.675 294.684L337.418 293.229L338.389 291.863L338.627 293.425Z' fill='#5179E0' />
      <path d='M253.075 204.312L252.57 206.16L252.046 205.374L252.285 203.491L253.075 204.312Z' fill='#5179E0' />
      <path d='M358.532 268.154L357.541 269.44L357.713 267.869L358.036 266.69L358.532 268.154Z' fill='#5179E0' />
      <path d='M296.456 292.72L295.589 294.166L295.141 292.917L296.027 291.337L296.456 292.72Z' fill='#5179E0' />
      <path d='M400.217 378.424L398.827 380.031L398.617 378.049L400.408 376.085L400.217 378.424Z' fill='#5179E0' />
      <path d='M242.456 167.624L242.046 169.364L241.418 168.374L241.904 166.392L242.456 167.624Z' fill='#5179E0' />
      <path d='M344.732 291.56L344.227 293.024L344.056 291.801L344.513 290.247L344.732 291.56Z' fill='#5179E0' />
      <defs>
        <linearGradient
          id='paint0_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint7_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint8_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint9_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint10_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint11_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint12_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint13_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint14_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint15_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint16_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint17_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint18_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint19_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint20_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint21_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint22_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint23_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint24_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint25_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint26_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint27_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint28_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint29_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint30_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint31_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint32_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint33_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint34_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint35_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint36_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint37_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint38_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint39_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint40_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint41_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint42_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint43_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint44_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint45_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint46_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint47_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint48_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint49_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint50_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint51_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint52_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint53_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint54_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint55_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint56_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint57_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint58_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint59_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint60_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint61_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint62_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint63_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint64_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint65_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint66_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint67_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint68_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint69_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint70_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint71_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint72_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint73_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint74_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint75_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint76_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint77_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint78_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint79_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint80_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint81_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint82_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint83_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint84_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint85_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint86_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint87_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint88_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint89_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint90_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint91_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint92_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint93_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint94_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint95_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint96_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint97_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint98_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint99_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint100_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint101_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint102_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint103_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint104_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint105_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint106_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint107_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint108_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint109_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint110_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint111_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint112_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint113_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint114_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint115_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint116_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint117_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint118_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint119_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint120_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint121_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint122_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint123_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint124_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint125_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint126_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint127_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint128_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint129_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint130_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint131_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint132_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint133_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint134_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint135_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint136_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint137_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint138_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint139_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint140_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint141_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint142_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint143_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint144_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint145_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint146_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint147_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint148_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint149_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint150_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint151_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint152_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint153_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint154_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint155_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint156_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint157_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint158_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint159_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint160_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint161_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint162_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint163_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint164_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint165_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint166_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint167_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint168_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint169_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint170_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint171_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint172_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint173_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint174_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint175_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint176_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint177_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint178_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint179_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint180_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint181_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint182_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint183_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint184_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint185_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint186_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint187_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint188_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint189_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint190_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint191_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint192_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint193_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint194_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint195_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint196_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint197_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint198_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint199_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint200_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint201_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint202_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint203_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint204_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint205_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint206_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint207_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint208_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint209_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint210_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint211_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint212_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint213_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint214_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint215_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint216_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint217_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint218_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint219_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint220_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint221_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint222_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint223_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint224_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint225_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint226_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint227_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint228_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint229_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint230_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint231_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint232_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint233_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint234_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint235_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint236_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint237_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint238_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint239_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint240_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint241_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint242_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint243_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint244_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint245_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint246_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint247_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint248_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint249_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint250_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint251_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint252_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint253_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint254_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint255_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint256_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint257_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint258_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint259_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint260_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint261_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint262_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint263_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint264_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint265_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint266_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint267_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint268_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint269_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint270_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint271_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint272_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint273_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint274_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint275_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint276_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint277_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint278_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint279_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint280_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint281_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint282_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint283_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint284_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint285_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint286_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint287_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint288_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint289_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint290_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint291_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint292_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint293_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint294_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint295_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint296_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint297_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint298_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint299_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint300_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint301_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint302_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint303_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint304_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint305_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint306_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint307_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint308_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint309_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint310_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint311_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint312_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint313_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint314_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint315_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint316_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint317_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint318_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint319_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint320_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint321_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint322_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint323_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint324_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint325_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint326_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint327_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint328_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint329_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint330_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint331_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint332_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint333_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint334_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint335_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint336_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint337_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint338_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint339_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint340_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint341_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint342_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint343_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint344_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint345_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint346_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint347_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint348_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint349_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint350_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint351_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint352_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint353_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint354_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint355_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint356_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint357_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint358_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint359_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint360_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint361_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint362_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint363_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint364_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint365_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint366_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint367_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint368_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint369_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint370_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint371_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint372_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint373_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint374_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint375_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint376_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint377_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint378_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint379_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint380_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint381_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint382_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint383_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint384_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint385_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint386_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint387_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint388_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint389_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint390_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint391_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint392_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint393_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint394_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint395_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint396_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint397_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint398_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint399_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint400_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint401_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint402_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint403_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint404_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint405_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint406_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint407_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint408_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint409_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint410_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint411_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint412_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint413_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint414_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint415_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint416_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint417_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint418_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint419_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint420_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint421_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint422_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint423_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint424_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint425_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint426_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint427_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint428_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint429_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint430_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint431_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint432_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint433_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint434_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint435_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint436_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint437_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint438_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint439_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint440_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint441_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint442_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint443_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint444_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint445_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint446_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint447_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint448_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint449_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint450_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint451_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint452_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint453_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint454_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint455_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint456_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint457_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint458_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint459_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint460_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint461_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint462_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint463_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint464_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint465_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint466_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint467_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint468_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint469_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint470_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint471_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint472_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint473_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint474_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint475_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint476_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint477_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint478_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint479_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint480_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint481_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint482_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint483_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint484_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint485_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint486_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint487_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint488_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint489_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint490_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint491_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint492_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint493_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint494_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint495_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint496_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint497_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint498_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint499_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint500_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint501_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint502_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint503_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint504_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint505_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint506_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint507_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint508_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint509_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint510_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint511_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint512_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint513_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint514_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint515_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint516_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint517_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint518_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint519_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint520_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint521_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint522_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint523_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint524_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint525_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint526_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint527_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint528_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint529_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint530_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint531_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint532_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint533_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint534_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint535_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint536_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint537_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint538_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint539_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint540_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint541_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint542_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint543_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint544_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint545_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint546_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint547_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint548_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint549_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint550_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint551_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint552_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint553_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint554_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint555_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint556_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint557_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint558_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint559_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint560_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint561_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint562_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint563_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint564_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint565_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint566_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint567_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint568_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint569_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint570_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint571_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint572_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint573_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint574_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint575_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint576_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint577_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint578_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint579_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint580_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint581_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint582_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint583_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint584_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint585_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint586_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint587_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint588_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint589_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint590_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint591_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint592_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint593_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint594_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint595_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint596_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint597_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint598_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint599_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint600_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint601_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint602_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint603_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint604_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint605_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint606_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint607_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint608_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint609_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint610_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint611_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint612_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint613_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint614_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint615_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint616_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint617_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint618_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint619_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint620_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint621_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint622_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint623_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint624_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint625_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint626_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint627_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint628_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint629_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint630_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint631_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint632_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint633_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint634_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint635_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint636_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint637_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint638_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint639_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint640_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint641_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint642_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint643_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint644_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint645_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint646_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint647_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint648_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint649_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint650_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint651_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint652_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint653_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint654_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint655_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint656_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint657_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint658_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint659_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint660_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint661_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint662_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint663_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint664_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint665_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint666_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint667_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint668_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint669_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint670_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint671_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint672_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint673_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint674_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint675_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint676_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint677_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint678_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint679_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint680_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint681_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint682_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint683_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint684_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint685_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint686_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint687_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint688_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint689_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint690_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint691_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint692_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint693_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint694_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint695_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint696_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint697_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint698_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint699_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint700_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint701_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint702_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint703_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint704_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint705_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint706_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint707_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint708_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint709_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint710_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint711_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint712_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint713_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint714_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint715_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint716_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint717_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint718_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint719_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint720_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint721_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint722_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint723_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint724_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint725_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint726_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint727_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint728_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint729_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint730_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint731_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint732_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint733_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint734_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint735_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint736_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint737_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint738_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint739_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint740_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint741_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint742_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint743_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint744_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint745_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint746_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint747_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint748_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint749_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint750_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint751_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint752_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint753_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint754_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint755_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint756_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint757_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint758_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint759_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint760_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint761_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint762_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint763_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint764_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint765_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint766_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint767_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint768_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint769_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint770_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint771_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint772_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint773_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint774_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint775_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint776_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint777_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint778_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint779_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint780_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint781_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint782_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint783_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint784_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint785_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint786_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint787_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint788_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint789_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint790_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint791_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint792_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint793_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint794_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint795_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint796_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint797_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint798_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint799_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint800_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint801_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint802_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint803_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint804_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint805_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint806_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint807_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint808_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint809_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint810_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint811_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint812_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint813_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint814_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint815_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint816_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint817_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint818_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint819_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint820_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint821_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint822_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint823_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint824_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint825_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint826_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint827_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint828_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint829_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint830_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint831_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint832_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint833_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint834_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint835_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint836_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint837_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint838_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint839_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint840_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint841_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint842_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint843_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint844_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint845_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint846_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint847_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint848_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint849_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint850_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint851_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint852_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint853_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint854_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint855_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint856_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint857_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint858_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint859_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint860_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint861_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint862_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint863_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint864_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint865_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint866_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint867_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint868_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint869_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint870_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint871_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint872_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint873_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint874_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint875_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint876_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint877_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint878_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint879_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint880_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint881_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint882_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint883_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint884_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint885_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint886_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint887_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint888_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint889_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint890_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint891_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint892_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint893_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint894_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint895_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint896_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint897_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint898_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint899_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint900_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint901_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint902_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint903_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint904_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint905_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint906_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint907_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint908_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint909_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint910_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint911_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint912_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint913_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint914_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint915_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint916_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint917_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint918_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint919_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint920_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint921_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint922_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint923_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint924_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint925_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint926_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint927_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint928_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint929_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint930_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint931_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint932_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint933_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint934_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint935_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint936_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint937_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint938_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint939_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint940_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint941_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint942_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint943_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint944_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint945_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint946_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint947_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint948_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint949_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint950_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint951_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint952_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint953_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint954_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint955_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint956_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint957_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint958_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint959_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint960_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint961_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint962_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint963_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint964_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint965_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint966_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint967_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint968_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint969_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint970_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint971_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint972_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint973_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint974_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint975_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint976_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint977_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint978_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint979_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint980_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint981_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint982_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint983_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint984_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint985_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint986_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint987_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint988_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint989_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint990_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint991_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint992_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint993_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint994_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint995_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint996_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint997_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint998_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint999_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1000_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1001_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1002_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1003_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1004_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1005_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1006_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1007_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1008_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1009_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1010_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1011_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1012_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1013_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1014_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1015_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1016_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1017_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1018_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1019_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1020_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1021_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1022_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1023_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1024_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1025_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1026_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1027_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1028_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1029_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1030_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1031_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1032_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1033_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1034_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1035_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1036_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1037_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1038_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1039_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1040_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1041_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1042_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1043_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1044_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1045_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1046_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1047_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1048_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1049_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1050_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1051_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1052_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1053_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1054_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1055_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1056_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1057_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1058_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1059_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1060_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1061_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1062_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1063_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1064_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1065_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1066_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1067_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1068_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1069_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1070_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1071_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1072_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1073_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1074_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1075_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1076_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1077_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1078_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1079_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1080_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1081_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1082_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1083_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1084_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1085_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1086_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1087_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1088_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1089_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1090_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1091_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1092_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1093_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1094_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1095_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1096_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1097_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1098_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1099_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1100_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1101_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1102_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1103_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1104_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1105_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1106_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1107_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1108_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1109_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1110_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1111_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1112_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1113_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1114_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1115_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1116_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1117_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1118_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1119_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1120_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1121_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1122_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1123_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1124_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1125_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1126_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1127_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1128_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1129_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1130_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1131_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1132_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1133_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1134_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1135_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1136_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1137_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1138_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1139_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1140_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1141_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1142_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1143_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1144_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1145_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1146_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1147_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1148_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1149_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1150_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1151_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1152_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1153_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1154_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1155_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1156_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1157_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1158_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1159_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1160_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1161_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1162_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1163_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1164_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1165_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1166_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1167_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1168_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1169_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1170_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1171_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1172_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1173_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1174_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1175_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1176_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1177_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1178_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1179_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1180_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1181_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1182_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1183_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1184_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1185_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1186_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1187_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1188_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1189_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1190_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1191_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1192_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1193_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1194_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1195_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1196_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1197_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1198_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1199_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1200_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1201_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1202_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1203_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1204_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1205_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1206_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1207_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1208_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1209_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1210_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1211_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1212_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1213_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1214_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1215_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1216_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1217_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1218_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1219_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1220_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1221_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1222_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1223_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1224_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1225_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1226_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1227_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1228_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1229_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1230_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1231_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1232_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1233_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1234_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1235_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1236_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1237_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1238_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1239_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1240_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1241_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1242_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1243_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1244_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1245_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1246_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1247_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1248_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1249_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1250_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1251_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1252_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1253_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1254_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1255_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1256_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1257_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1258_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1259_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1260_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1261_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1262_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1263_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1264_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1265_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1266_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1267_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1268_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1269_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1270_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1271_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1272_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1273_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1274_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1275_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1276_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1277_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1278_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1279_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1280_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1281_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1282_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1283_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1284_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1285_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1286_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1287_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1288_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1289_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1290_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1291_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1292_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1293_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1294_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1295_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1296_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1297_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1298_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1299_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1300_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1301_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1302_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1303_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1304_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1305_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1306_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1307_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1308_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1309_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1310_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1311_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1312_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1313_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1314_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1315_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1316_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1317_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1318_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1319_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1320_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1321_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1322_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1323_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1324_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1325_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1326_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1327_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1328_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1329_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1330_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1331_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1332_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1333_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1334_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1335_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1336_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1337_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1338_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1339_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1340_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1341_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1342_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1343_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1344_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1345_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1346_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1347_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1348_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1349_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1350_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1351_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1352_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1353_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1354_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1355_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1356_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1357_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1358_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1359_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1360_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1361_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1362_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1363_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1364_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1365_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1366_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1367_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1368_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1369_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1370_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1371_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1372_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1373_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1374_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1375_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1376_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1377_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1378_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1379_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1380_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1381_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1382_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1383_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1384_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1385_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1386_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1387_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1388_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1389_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1390_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1391_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1392_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1393_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1394_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1395_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1396_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1397_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1398_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1399_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1400_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1401_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1402_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1403_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1404_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1405_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1406_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1407_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1408_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1409_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1410_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1411_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1412_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1413_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1414_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1415_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1416_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1417_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1418_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1419_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1420_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1421_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1422_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1423_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1424_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1425_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1426_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1427_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1428_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1429_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1430_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1431_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1432_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1433_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1434_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1435_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1436_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1437_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1438_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1439_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1440_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1441_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1442_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1443_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1444_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1445_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1446_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1447_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1448_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1449_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1450_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1451_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1452_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1453_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1454_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1455_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1456_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1457_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1458_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1459_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1460_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1461_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1462_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1463_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1464_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1465_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1466_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1467_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1468_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1469_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1470_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1471_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1472_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1473_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1474_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1475_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1476_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1477_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1478_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1479_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1480_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1481_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1482_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1483_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1484_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1485_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1486_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1487_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1488_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1489_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1490_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1491_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1492_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1493_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1494_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1495_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1496_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1497_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1498_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1499_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1500_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1501_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1502_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1503_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1504_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1505_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1506_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1507_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1508_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1509_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1510_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1511_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1512_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1513_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1514_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1515_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1516_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1517_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1518_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1519_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1520_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1521_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1522_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1523_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1524_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1525_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1526_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1527_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1528_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1529_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1530_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1531_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1532_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1533_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1534_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1535_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1536_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1537_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1538_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1539_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1540_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1541_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1542_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1543_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1544_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1545_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1546_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1547_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1548_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1549_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1550_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1551_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1552_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1553_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1554_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1555_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1556_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1557_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1558_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1559_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1560_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1561_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1562_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1563_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1564_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1565_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1566_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1567_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1568_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1569_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1570_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1571_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1572_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1573_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1574_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1575_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1576_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1577_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1578_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1579_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1580_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1581_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1582_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1583_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1584_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1585_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1586_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1587_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1588_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1589_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1590_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1591_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1592_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1593_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1594_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1595_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1596_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1597_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1598_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1599_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1600_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1601_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1602_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1603_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1604_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1605_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1606_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1607_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1608_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1609_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1610_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1611_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1612_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1613_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1614_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1615_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1616_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1617_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1618_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1619_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1620_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1621_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1622_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1623_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1624_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1625_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1626_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1627_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1628_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1629_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1630_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1631_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1632_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1633_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1634_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1635_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1636_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1637_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1638_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1639_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1640_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1641_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1642_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1643_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1644_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1645_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1646_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1647_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1648_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1649_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1650_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1651_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1652_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1653_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1654_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1655_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1656_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1657_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1658_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1659_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1660_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1661_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1662_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1663_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1664_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1665_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1666_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1667_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1668_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1669_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1670_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1671_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1672_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1673_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1674_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1675_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1676_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1677_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1678_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1679_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1680_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1681_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1682_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1683_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1684_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1685_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1686_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1687_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1688_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1689_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1690_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1691_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1692_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1693_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1694_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1695_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1696_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1697_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1698_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1699_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1700_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1701_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1702_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1703_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1704_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1705_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1706_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1707_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1708_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1709_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1710_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1711_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1712_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1713_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1714_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1715_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1716_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1717_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1718_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1719_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1720_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1721_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1722_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1723_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1724_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1725_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1726_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1727_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1728_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1729_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1730_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1731_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1732_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1733_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1734_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1735_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1736_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1737_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1738_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1739_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1740_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1741_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1742_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1743_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1744_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1745_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1746_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1747_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1748_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1749_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1750_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1751_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1752_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1753_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1754_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1755_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1756_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1757_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1758_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1759_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1760_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1761_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1762_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1763_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1764_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1765_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1766_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1767_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1768_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1769_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1770_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1771_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1772_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1773_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1774_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1775_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1776_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1777_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1778_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1779_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1780_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1781_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1782_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1783_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1784_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1785_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1786_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1787_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1788_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1789_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1790_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1791_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1792_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1793_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1794_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1795_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1796_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1797_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1798_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1799_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1800_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1801_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1802_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1803_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1804_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1805_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1806_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1807_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1808_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1809_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1810_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1811_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1812_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1813_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1814_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1815_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1816_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1817_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1818_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1819_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1820_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1821_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1822_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1823_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1824_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1825_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1826_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1827_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1828_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1829_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1830_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1831_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1832_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1833_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1834_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1835_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1836_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1837_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1838_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1839_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1840_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1841_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1842_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1843_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1844_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1845_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1846_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1847_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1848_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1849_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1850_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1851_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1852_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1853_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1854_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1855_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1856_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1857_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1858_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1859_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1860_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1861_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1862_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1863_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1864_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1865_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1866_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1867_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1868_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1869_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1870_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1871_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1872_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1873_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1874_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1875_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1876_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1877_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1878_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1879_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1880_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1881_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1882_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1883_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1884_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1885_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1886_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1887_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1888_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1889_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1890_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1891_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1892_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1893_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1894_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1895_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1896_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1897_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1898_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1899_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1900_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1901_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1902_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1903_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1904_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1905_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1906_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1907_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1908_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1909_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1910_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1911_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1912_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1913_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1914_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1915_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1916_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1917_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1918_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1919_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1920_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1921_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1922_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1923_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1924_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1925_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1926_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1927_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1928_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1929_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1930_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1931_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1932_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1933_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1934_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1935_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1936_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1937_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1938_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1939_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1940_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1941_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1942_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1943_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1944_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1945_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1946_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1947_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1948_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1949_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1950_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1951_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1952_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1953_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1954_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1955_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1956_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1957_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1958_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1959_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1960_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1961_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1962_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1963_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1964_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1965_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1966_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1967_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1968_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1969_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1970_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1971_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1972_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1973_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1974_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1975_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1976_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1977_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1978_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1979_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1980_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1981_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1982_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1983_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1984_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1985_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1986_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1987_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1988_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1989_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1990_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1991_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1992_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1993_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1994_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1995_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1996_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1997_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1998_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint1999_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2000_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2001_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2002_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2003_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2004_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2005_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2006_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2007_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2008_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2009_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2010_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2011_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2012_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2013_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2014_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2015_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2016_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2017_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2018_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2019_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2020_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2021_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2022_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2023_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2024_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2025_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2026_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2027_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2028_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2029_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2030_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2031_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2032_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2033_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2034_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2035_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2036_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2037_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2038_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2039_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2040_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2041_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2042_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2043_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2044_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2045_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2046_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2047_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2048_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2049_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2050_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2051_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2052_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2053_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2054_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2055_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2056_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2057_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2058_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2059_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2060_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2061_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2062_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2063_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2064_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2065_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2066_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2067_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2068_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2069_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2070_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2071_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2072_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2073_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2074_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2075_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2076_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2077_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2078_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2079_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2080_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2081_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2082_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2083_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2084_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2085_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2086_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2087_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2088_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2089_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2090_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2091_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2092_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2093_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2094_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2095_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2096_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2097_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2098_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2099_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2100_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2101_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2102_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2103_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2104_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2105_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2106_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2107_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2108_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2109_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2110_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2111_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2112_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2113_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2114_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2115_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2116_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2117_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2118_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2119_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2120_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2121_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2122_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2123_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2124_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2125_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2126_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2127_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2128_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2129_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2130_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2131_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2132_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2133_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2134_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2135_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2136_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2137_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2138_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2139_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2140_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2141_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2142_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2143_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2144_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2145_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2146_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2147_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2148_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2149_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2150_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2151_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2152_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2153_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2154_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2155_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2156_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2157_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2158_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2159_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2160_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2161_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2162_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2163_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2164_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2165_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2166_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2167_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2168_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2169_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2170_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2171_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2172_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2173_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2174_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2175_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2176_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2177_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2178_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2179_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2180_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2181_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2182_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2183_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2184_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2185_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2186_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2187_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2188_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2189_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2190_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2191_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2192_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2193_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2194_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2195_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2196_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2197_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2198_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2199_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2200_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2201_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2202_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2203_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2204_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2205_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2206_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2207_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2208_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2209_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2210_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2211_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2212_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2213_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2214_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2215_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2216_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2217_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2218_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2219_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2220_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2221_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2222_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2223_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2224_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2225_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2226_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2227_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2228_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2229_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2230_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2231_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2232_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2233_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2234_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2235_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2236_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2237_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2238_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2239_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2240_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2241_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2242_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2243_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2244_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2245_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2246_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2247_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2248_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2249_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2250_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2251_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2252_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2253_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2254_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2255_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2256_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2257_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2258_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2259_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2260_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2261_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2262_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2263_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2264_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2265_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2266_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2267_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2268_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2269_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2270_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2271_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2272_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2273_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2274_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2275_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2276_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2277_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2278_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2279_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2280_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2281_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2282_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2283_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2284_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2285_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2286_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2287_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2288_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2289_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2290_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2291_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2292_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2293_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2294_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2295_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2296_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2297_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2298_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2299_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2300_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2301_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2302_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2303_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2304_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2305_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2306_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2307_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2308_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2309_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2310_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2311_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2312_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2313_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2314_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2315_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2316_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2317_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2318_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2319_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2320_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2321_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2322_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2323_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2324_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2325_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2326_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2327_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2328_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2329_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2330_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2331_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2332_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2333_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2334_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2335_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2336_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2337_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2338_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2339_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2340_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2341_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2342_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2343_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2344_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2345_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2346_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2347_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2348_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2349_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2350_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2351_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2352_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2353_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2354_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2355_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2356_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2357_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2358_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2359_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2360_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2361_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2362_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2363_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2364_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2365_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2366_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2367_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2368_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2369_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2370_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2371_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2372_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2373_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2374_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2375_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2376_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2377_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2378_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2379_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2380_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2381_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2382_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2383_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2384_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2385_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2386_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2387_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2388_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2389_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2390_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2391_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2392_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2393_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2394_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2395_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2396_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2397_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2398_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2399_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2400_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2401_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2402_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2403_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2404_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2405_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2406_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2407_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2408_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2409_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2410_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2411_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2412_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2413_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2414_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2415_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2416_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2417_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2418_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2419_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2420_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2421_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2422_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2423_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2424_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2425_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2426_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2427_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2428_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2429_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2430_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2431_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2432_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2433_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2434_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2435_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2436_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2437_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2438_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2439_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2440_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2441_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2442_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2443_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2444_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2445_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2446_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2447_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2448_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2449_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2450_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2451_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2452_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2453_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2454_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2455_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2456_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2457_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2458_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2459_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2460_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2461_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2462_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2463_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2464_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2465_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2466_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2467_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2468_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2469_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2470_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2471_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2472_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2473_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2474_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2475_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2476_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2477_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2478_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2479_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2480_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2481_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2482_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2483_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2484_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2485_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2486_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2487_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2488_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2489_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2490_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2491_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2492_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2493_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2494_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2495_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2496_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2497_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2498_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2499_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2500_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2501_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2502_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2503_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2504_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2505_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2506_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2507_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2508_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2509_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2510_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2511_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2512_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2513_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2514_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2515_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2516_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2517_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2518_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2519_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2520_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2521_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2522_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2523_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2524_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2525_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2526_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2527_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2528_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2529_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2530_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2531_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2532_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2533_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2534_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2535_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2536_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2537_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2538_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2539_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2540_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2541_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2542_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2543_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2544_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2545_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2546_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2547_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2548_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2549_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2550_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2551_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2552_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2553_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2554_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2555_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2556_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2557_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2558_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2559_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2560_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2561_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2562_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2563_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2564_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2565_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2566_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2567_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2568_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2569_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2570_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2571_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2572_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2573_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2574_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2575_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2576_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2577_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2578_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2579_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2580_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2581_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2582_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2583_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2584_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2585_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2586_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2587_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2588_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2589_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2590_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2591_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2592_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2593_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2594_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2595_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2596_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2597_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2598_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2599_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2600_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2601_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2602_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2603_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2604_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2605_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2606_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2607_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2608_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2609_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2610_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2611_linear_3150_15389'
          x1='505.393'
          y1='-71'
          x2='505.393'
          y2='671.911'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2612_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2613_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2614_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2615_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2616_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2617_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2618_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2619_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2620_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2621_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2622_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2623_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2624_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2625_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2626_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2627_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2628_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2629_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2630_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2631_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2632_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2633_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2634_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2635_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2636_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2637_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2638_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2639_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2640_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2641_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2642_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2643_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2644_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2645_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2646_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2647_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2648_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2649_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2650_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2651_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2652_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2653_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2654_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2655_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2656_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2657_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2658_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2659_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2660_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2661_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2662_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2663_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2664_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2665_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2666_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2667_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2668_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2669_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2670_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2671_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2672_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2673_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2674_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2675_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2676_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2677_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2678_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2679_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2680_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2681_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2682_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2683_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2684_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2685_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2686_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2687_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2688_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2689_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2690_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2691_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2692_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2693_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2694_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2695_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2696_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2697_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2698_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2699_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2700_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2701_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2702_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2703_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2704_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2705_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2706_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2707_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2708_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2709_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2710_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2711_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2712_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2713_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2714_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2715_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2716_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2717_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2718_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2719_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2720_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2721_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2722_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2723_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2724_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2725_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2726_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2727_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2728_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2729_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2730_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2731_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2732_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2733_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2734_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2735_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2736_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2737_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2738_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2739_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2740_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2741_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2742_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2743_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2744_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2745_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2746_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2747_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2748_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2749_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2750_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2751_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2752_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2753_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2754_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2755_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2756_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2757_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2758_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2759_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2760_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2761_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2762_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2763_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2764_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2765_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2766_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2767_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2768_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2769_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2770_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2771_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2772_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2773_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2774_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2775_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2776_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2777_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2778_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2779_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2780_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2781_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2782_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2783_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2784_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2785_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2786_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2787_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2788_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2789_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2790_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2791_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2792_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2793_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2794_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2795_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2796_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2797_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2798_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2799_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2800_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2801_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2802_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2803_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2804_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2805_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2806_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2807_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2808_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2809_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2810_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2811_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2812_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2813_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2814_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2815_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2816_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2817_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2818_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2819_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2820_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2821_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2822_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2823_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2824_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2825_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2826_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2827_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2828_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2829_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2830_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2831_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2832_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2833_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2834_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2835_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2836_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2837_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2838_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2839_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2840_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2841_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2842_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2843_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2844_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2845_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2846_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2847_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2848_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2849_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2850_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2851_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2852_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2853_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2854_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2855_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2856_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2857_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2858_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2859_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2860_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2861_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2862_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2863_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2864_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2865_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2866_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2867_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2868_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2869_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2870_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2871_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2872_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2873_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2874_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2875_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2876_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2877_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2878_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2879_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2880_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2881_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2882_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2883_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2884_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2885_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2886_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2887_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2888_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2889_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2890_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2891_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2892_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2893_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2894_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2895_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2896_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2897_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2898_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2899_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2900_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2901_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2902_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2903_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2904_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2905_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2906_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2907_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2908_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2909_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2910_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2911_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2912_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2913_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2914_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2915_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2916_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2917_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2918_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2919_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2920_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2921_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2922_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2923_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2924_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2925_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2926_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2927_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2928_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2929_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2930_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2931_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2932_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2933_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2934_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2935_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2936_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2937_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2938_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2939_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2940_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2941_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2942_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2943_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2944_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2945_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2946_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2947_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2948_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2949_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2950_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2951_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2952_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2953_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2954_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2955_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2956_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2957_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2958_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2959_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2960_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2961_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2962_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2963_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2964_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2965_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2966_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2967_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2968_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2969_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2970_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2971_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2972_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2973_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2974_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2975_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2976_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2977_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2978_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2979_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2980_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2981_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2982_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2983_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2984_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2985_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2986_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2987_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2988_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2989_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2990_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2991_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2992_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2993_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2994_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2995_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2996_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2997_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2998_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint2999_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3000_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3001_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3002_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3003_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3004_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3005_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3006_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3007_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3008_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3009_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3010_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3011_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3012_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3013_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3014_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3015_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3016_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3017_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3018_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3019_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3020_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3021_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3022_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3023_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3024_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3025_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3026_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3027_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3028_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3029_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3030_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3031_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3032_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3033_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3034_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3035_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3036_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3037_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3038_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3039_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3040_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3041_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3042_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3043_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3044_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3045_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3046_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3047_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3048_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3049_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3050_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3051_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3052_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3053_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3054_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3055_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3056_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3057_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3058_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3059_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3060_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3061_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3062_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3063_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3064_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3065_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3066_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3067_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3068_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3069_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3070_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3071_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3072_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3073_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3074_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3075_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3076_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3077_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3078_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3079_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3080_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3081_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3082_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3083_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3084_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3085_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3086_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3087_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3088_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3089_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3090_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3091_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3092_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3093_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3094_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3095_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3096_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3097_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3098_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3099_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3100_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3101_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3102_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3103_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3104_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3105_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3106_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3107_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3108_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3109_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3110_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3111_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3112_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3113_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3114_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3115_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3116_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3117_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3118_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3119_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3120_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3121_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3122_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3123_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3124_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3125_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3126_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3127_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3128_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3129_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3130_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3131_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3132_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3133_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3134_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3135_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3136_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3137_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3138_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3139_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3140_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3141_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3142_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3143_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3144_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3145_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3146_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3147_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3148_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3149_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3150_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3151_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3152_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3153_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3154_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3155_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3156_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3157_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3158_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3159_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3160_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3161_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3162_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3163_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3164_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3165_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3166_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3167_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3168_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3169_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3170_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3171_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3172_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3173_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3174_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3175_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3176_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3177_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3178_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3179_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3180_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3181_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3182_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3183_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3184_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3185_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3186_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3187_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3188_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3189_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3190_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3191_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3192_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3193_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3194_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3195_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3196_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3197_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3198_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3199_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3200_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3201_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3202_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3203_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3204_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3205_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3206_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3207_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3208_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3209_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3210_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3211_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3212_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3213_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3214_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3215_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3216_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3217_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3218_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3219_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3220_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3221_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3222_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3223_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3224_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3225_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3226_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3227_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3228_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3229_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3230_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3231_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3232_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3233_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3234_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3235_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3236_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3237_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3238_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3239_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3240_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3241_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3242_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3243_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3244_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3245_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3246_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3247_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3248_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3249_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3250_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3251_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3252_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3253_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3254_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3255_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3256_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3257_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3258_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3259_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3260_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3261_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3262_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3263_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3264_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3265_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3266_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3267_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3268_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3269_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3270_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3271_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3272_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3273_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3274_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3275_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3276_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3277_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3278_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3279_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3280_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3281_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3282_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3283_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3284_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3285_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3286_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3287_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3288_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3289_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3290_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3291_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3292_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3293_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3294_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3295_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3296_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3297_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3298_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3299_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3300_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3301_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3302_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3303_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3304_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3305_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3306_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3307_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3308_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3309_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3310_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3311_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3312_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3313_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3314_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3315_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3316_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3317_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3318_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3319_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3320_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3321_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3322_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3323_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3324_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3325_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3326_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3327_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3328_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3329_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3330_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3331_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3332_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3333_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3334_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3335_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3336_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3337_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3338_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3339_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3340_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3341_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3342_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3343_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3344_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3345_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3346_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3347_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3348_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3349_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3350_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3351_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3352_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3353_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3354_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3355_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3356_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3357_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3358_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3359_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3360_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3361_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3362_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3363_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3364_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3365_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3366_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3367_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3368_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3369_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3370_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3371_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3372_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3373_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3374_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3375_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3376_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3377_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3378_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3379_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3380_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3381_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3382_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3383_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3384_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3385_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3386_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3387_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3388_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3389_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3390_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3391_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3392_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3393_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3394_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3395_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3396_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3397_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3398_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3399_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3400_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3401_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3402_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3403_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3404_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3405_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3406_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3407_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3408_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3409_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3410_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3411_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3412_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3413_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3414_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3415_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3416_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3417_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3418_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3419_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3420_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3421_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3422_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3423_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3424_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3425_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3426_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3427_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3428_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3429_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3430_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3431_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3432_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3433_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3434_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3435_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3436_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3437_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3438_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3439_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3440_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3441_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3442_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3443_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3444_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3445_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3446_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3447_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3448_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3449_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3450_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3451_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3452_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3453_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3454_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3455_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3456_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3457_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3458_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3459_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3460_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3461_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3462_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3463_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3464_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3465_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3466_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3467_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3468_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3469_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3470_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3471_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3472_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3473_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3474_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3475_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3476_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3477_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3478_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3479_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3480_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3481_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3482_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3483_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3484_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3485_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3486_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3487_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3488_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3489_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3490_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3491_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3492_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3493_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3494_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3495_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3496_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3497_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3498_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3499_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3500_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3501_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3502_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3503_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3504_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3505_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3506_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3507_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3508_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3509_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3510_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3511_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3512_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3513_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3514_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3515_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3516_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3517_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3518_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3519_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3520_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3521_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3522_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3523_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3524_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3525_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3526_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3527_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3528_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3529_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3530_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3531_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3532_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3533_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3534_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3535_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3536_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3537_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3538_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3539_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3540_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3541_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3542_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3543_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3544_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3545_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3546_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3547_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3548_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3549_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3550_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3551_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3552_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3553_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3554_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3555_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3556_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3557_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3558_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3559_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3560_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3561_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3562_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3563_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3564_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3565_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3566_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3567_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3568_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3569_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3570_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3571_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3572_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3573_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3574_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3575_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3576_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3577_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3578_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3579_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3580_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3581_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3582_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3583_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3584_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3585_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3586_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3587_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3588_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3589_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3590_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3591_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3592_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3593_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3594_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3595_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3596_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3597_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3598_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3599_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3600_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3601_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3602_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3603_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3604_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3605_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3606_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3607_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3608_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3609_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3610_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3611_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3612_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3613_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3614_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3615_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3616_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3617_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3618_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3619_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3620_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3621_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3622_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3623_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3624_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3625_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3626_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3627_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3628_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3629_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3630_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3631_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3632_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3633_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3634_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3635_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3636_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3637_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3638_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3639_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3640_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3641_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3642_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3643_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3644_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3645_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3646_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3647_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3648_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3649_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3650_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3651_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3652_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3653_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3654_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3655_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3656_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3657_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3658_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3659_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3660_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3661_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3662_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3663_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3664_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3665_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3666_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3667_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3668_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3669_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3670_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3671_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3672_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3673_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3674_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3675_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3676_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3677_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3678_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3679_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3680_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3681_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3682_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3683_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3684_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3685_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3686_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3687_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3688_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3689_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3690_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3691_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3692_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3693_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3694_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3695_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3696_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3697_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3698_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3699_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3700_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3701_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3702_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3703_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3704_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3705_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3706_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3707_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3708_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3709_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3710_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3711_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3712_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3713_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3714_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3715_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3716_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3717_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3718_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3719_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3720_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3721_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3722_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3723_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3724_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3725_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3726_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3727_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3728_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3729_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3730_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3731_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3732_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3733_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3734_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3735_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3736_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3737_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3738_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3739_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3740_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3741_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3742_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3743_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3744_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3745_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3746_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3747_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3748_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3749_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3750_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3751_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3752_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3753_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3754_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3755_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3756_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3757_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3758_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3759_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3760_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3761_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3762_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3763_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3764_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3765_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3766_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3767_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3768_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3769_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3770_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3771_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3772_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3773_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3774_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3775_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3776_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3777_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3778_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3779_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3780_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3781_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3782_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3783_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3784_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3785_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3786_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3787_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3788_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3789_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3790_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3791_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3792_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3793_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3794_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3795_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3796_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3797_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3798_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3799_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3800_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3801_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3802_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3803_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3804_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3805_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3806_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3807_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3808_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3809_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3810_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3811_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3812_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3813_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3814_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3815_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3816_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3817_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3818_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3819_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3820_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3821_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3822_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3823_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3824_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3825_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3826_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3827_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3828_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3829_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3830_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3831_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3832_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3833_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3834_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3835_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3836_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3837_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3838_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3839_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3840_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3841_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3842_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3843_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3844_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3845_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3846_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3847_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3848_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3849_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3850_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3851_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3852_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3853_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3854_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3855_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3856_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3857_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3858_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3859_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3860_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3861_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3862_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3863_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3864_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3865_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3866_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3867_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3868_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3869_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3870_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3871_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3872_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3873_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3874_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3875_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3876_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3877_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3878_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3879_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3880_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3881_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3882_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3883_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3884_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3885_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3886_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3887_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3888_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3889_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3890_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3891_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3892_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3893_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3894_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3895_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3896_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3897_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3898_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3899_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3900_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3901_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3902_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3903_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3904_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3905_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3906_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3907_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3908_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3909_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3910_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3911_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3912_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3913_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3914_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3915_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3916_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3917_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3918_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3919_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3920_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3921_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3922_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3923_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3924_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3925_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3926_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3927_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3928_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3929_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3930_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3931_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3932_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3933_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3934_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3935_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3936_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3937_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3938_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3939_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3940_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3941_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3942_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3943_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3944_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3945_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3946_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3947_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3948_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3949_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3950_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3951_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3952_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3953_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3954_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3955_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3956_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3957_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3958_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3959_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3960_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3961_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3962_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3963_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3964_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3965_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3966_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3967_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3968_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3969_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3970_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3971_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3972_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3973_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3974_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3975_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3976_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3977_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3978_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3979_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3980_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3981_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3982_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3983_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3984_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3985_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3986_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3987_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3988_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3989_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3990_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3991_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3992_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3993_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3994_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3995_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3996_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3997_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3998_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint3999_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4000_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4001_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4002_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4003_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4004_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4005_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4006_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4007_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4008_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4009_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4010_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4011_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4012_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4013_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4014_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4015_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4016_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4017_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4018_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4019_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4020_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4021_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4022_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4023_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4024_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4025_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4026_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4027_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4028_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4029_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4030_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4031_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4032_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4033_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4034_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4035_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4036_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4037_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4038_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4039_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4040_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4041_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4042_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4043_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4044_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4045_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4046_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4047_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4048_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4049_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4050_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4051_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4052_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4053_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4054_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4055_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4056_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4057_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4058_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4059_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4060_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4061_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4062_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4063_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4064_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4065_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4066_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4067_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4068_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4069_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4070_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4071_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4072_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4073_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4074_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4075_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4076_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4077_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4078_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4079_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4080_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4081_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4082_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4083_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4084_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4085_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4086_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4087_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4088_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4089_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4090_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4091_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4092_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4093_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4094_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4095_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4096_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4097_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4098_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4099_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4100_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4101_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4102_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4103_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4104_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4105_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4106_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4107_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4108_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4109_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4110_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4111_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4112_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4113_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4114_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4115_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4116_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4117_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4118_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4119_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4120_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4121_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4122_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4123_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4124_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4125_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4126_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4127_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4128_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4129_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4130_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4131_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4132_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4133_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4134_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4135_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4136_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4137_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4138_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4139_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4140_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4141_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4142_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4143_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4144_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4145_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4146_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4147_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4148_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4149_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4150_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4151_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4152_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4153_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4154_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4155_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4156_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4157_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4158_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4159_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4160_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4161_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4162_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4163_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4164_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4165_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4166_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4167_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4168_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4169_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4170_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4171_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4172_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4173_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4174_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4175_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4176_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4177_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4178_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4179_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4180_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4181_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4182_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4183_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4184_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4185_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4186_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4187_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4188_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4189_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4190_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4191_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4192_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4193_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4194_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4195_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4196_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4197_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4198_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4199_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4200_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4201_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4202_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4203_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4204_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4205_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4206_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4207_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4208_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4209_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4210_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4211_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4212_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4213_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4214_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4215_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4216_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4217_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4218_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4219_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4220_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4221_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4222_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4223_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4224_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4225_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4226_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4227_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4228_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4229_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4230_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4231_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4232_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4233_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4234_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4235_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4236_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4237_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4238_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4239_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4240_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4241_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4242_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4243_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4244_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4245_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4246_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4247_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4248_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4249_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4250_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4251_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4252_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4253_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4254_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4255_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4256_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4257_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4258_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4259_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4260_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4261_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4262_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4263_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4264_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4265_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4266_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4267_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4268_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4269_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4270_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4271_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4272_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4273_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4274_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4275_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4276_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4277_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4278_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4279_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4280_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4281_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4282_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4283_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4284_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4285_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4286_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4287_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4288_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4289_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4290_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4291_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4292_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4293_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4294_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4295_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4296_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4297_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4298_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4299_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4300_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4301_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4302_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4303_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4304_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4305_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4306_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4307_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4308_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4309_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4310_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4311_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4312_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4313_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4314_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4315_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4316_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4317_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4318_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4319_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4320_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4321_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4322_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4323_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4324_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4325_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4326_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4327_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4328_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4329_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4330_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4331_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4332_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4333_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4334_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4335_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4336_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4337_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4338_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4339_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4340_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4341_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4342_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4343_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4344_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4345_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4346_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4347_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4348_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4349_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4350_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4351_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4352_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4353_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4354_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4355_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4356_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4357_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4358_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4359_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4360_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4361_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4362_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4363_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4364_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4365_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4366_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4367_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4368_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4369_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4370_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4371_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4372_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4373_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4374_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4375_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4376_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4377_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4378_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4379_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4380_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4381_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4382_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4383_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4384_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4385_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4386_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4387_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4388_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4389_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4390_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4391_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4392_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4393_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4394_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4395_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4396_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4397_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4398_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4399_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4400_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4401_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4402_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4403_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4404_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4405_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4406_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4407_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4408_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4409_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4410_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4411_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4412_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4413_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4414_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4415_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4416_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4417_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4418_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4419_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4420_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4421_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4422_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4423_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4424_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4425_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4426_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4427_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4428_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4429_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4430_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4431_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4432_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4433_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4434_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4435_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4436_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
        <linearGradient
          id='paint4437_linear_3150_15389'
          x1='491.544'
          y1='144.684'
          x2='491.544'
          y2='525.625'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='#AA59D8' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default WhyUsIcon;
